import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"max-width":"200px"},
  class: "text-truncate"
}
const _hoisted_2 = {
  key: 1,
  class: "text-red"
}

import {
    OnlineChatClientOpenWidgetTabActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "OnlineChatOpenWidgetTabBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatClientOpenWidgetTabActionDescription>()
    },
  setup(__props) {

let props = __props;

let tabDescr = computed(() => {
    let tabId = props.params.tabId;
    let tab = props.actionDescription.frontendParams.tabs.find(item => item.id == tabId);
    return tab ? tab.descr : null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(tabDescr))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_unref(tabDescr)), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, " Вкладка не выбрана "))
  ]))
}
}

})