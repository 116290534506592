import Tinymce from "tinymce/tinymce.min.js";
import {Settings} from "~/node_modules/@types/tinymce";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import TextInputWithVariablesHelper from './TextInputWithVariablesHelper';
import {Editor} from "tinymce";
import {
    ContentVariablesComponentExposeInterface
} from "~/cabinet/vue/client/sender/send/ContentVariablesComponentExposeInterface";
import ContentVariablesHelper from "~/cabinet/vue/client/sender/send/ContentVariablesHelper";
import {computed, ExtractPropTypes, getCurrentInstance, onBeforeUnmount, onMounted, PropType, watch} from "vue";
import StringHelper from "~/ts/library/StringHelper";

Tinymce.baseURL = "assets/scripts/tinymce";
let nbspRegexp = new RegExp(String.fromCharCode(160), "gi");

export function setTinymceBaseUrl(url: string) {
    Tinymce.baseURL = url;
}

export function useInputWithVariablesProps() {
    return {
        modelValue: {type: String},
        contentVariables: {
            type: Array as PropType<ContentVariableInterface[]>,
            default: () => {
                return [] as any;
            }
        },
        placeholder: {
            type: String
        },
        multiline: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean
        }
    }
}

export function useInputWithVariables(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useInputWithVariablesProps>>>,
    p: {
        isHtml: () => boolean,
        isBbCode: () => boolean,
        getPlugins: () => string[],
        getToolbar: () => string[],
        getSettings: () => Settings,
        setup: (editor: Editor) => void;
        getEditor: () => Editor,
        getContentVariablesComponent: () => ContentVariablesComponentExposeInterface
    }
) {
    let variableGroups = computed(() => {
        return ContentVariablesHelper.contentVariablesToGroups(props.contentVariables);
    });

    function normalizeValue(value: string) {
        value = value.replace(nbspRegexp, " ");
        value = value.replace(/\uFEFF/g, "");
        if (!props.multiline) {
            value = value.replace("\n", "");
        }

        value = value.length === 1 ? value.trim() : value;
        value = value.replace(/\n+$/g, '');
        return value;
    }


    function getText(): string | null {
        let editor = p.getEditor();
        if (editor) {
            let value: string;
            if (!p.isBbCode) {
                value = editor.getContent({format: 'text'});
            } else {
                value = editor.getContent();
                value = StringHelper.htmlToBbcode(value);
            }
            value = normalizeValue(value);
            return value;
        }
        return null;
    }

    function paste(text: string) {
        let editor = p.getEditor();
        if (editor) {
            editor.execCommand('mceInsertContent', false, text);
        }
    }


    let helper = computed(() => {
        return new TextInputWithVariablesHelper(variablesList.value, p.isHtml());
    });

    let variablesList = computed(() => {
        let variables: ContentVariableInterface[] = [];
        for (let group of variableGroups.value) {
            variables.push(...group.variables);
        }
        return variables;
    });

    let regexp = computed(() => {
        return helper.value.regexpHtml;
    });


    let dataLabelAttribute = computed(() => {
        return "data-label";
    });

    let instance = getCurrentInstance().proxy;

    let commonSettings = computed<Settings>(() => {
        return {
            language: "ru",
            toolbar: p.getToolbar(),
            plugins: ["variables,placeholder,paste", ...p.getPlugins()].join(","),
            skin: "oxide-dark",
            suffix: ".min",
            menubar: false,
            statusbar: false,
            convert_urls: false,
            forced_root_block: "p",
            placeholder: props.placeholder ? props.placeholder : "",
            fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 36px",
            variables_get_label: (id: string) => {
                return helper.value.getLabel(id, (result: string) => {
                    var noneditableSpans = instance.$el.querySelectorAll("[data-mce-content]");
                    for (let j = 0; j < noneditableSpans.length; j++) {
                        if (noneditableSpans[j].getAttribute("data-mce-content") === id) {
                            noneditableSpans[j].setAttribute(dataLabelAttribute.value, result);
                        }
                    }
                });
            },
            paste_postprocess: (a: any, b: { node: HTMLDivElement }) => {
                if (!props.multiline) {
                    if (b && b.node) {
                        b.node.innerText = b.node.textContent.split("\n").join(" ");
                    }
                }
            },
            setup: (editor: Editor) => {
                if (variableGroups.value.length) {
                    (editor as any).ui.registry.addMenuButton("PasteContentVariable", {
                        text: "Подставить значение",
                        fetch: (callback: (menu: IMenuItem[]) => void) => {
                            let menu: IMenuItem[] = [];
                            let count = variableGroups.value.length;
                            for (let i = 0; i < count; i++) {
                                menu.push({
                                    type: 'menuitem',
                                    text: variableGroups.value[i].descr,
                                    disabled: true
                                })
                                let group = variableGroups.value[i];
                                for (let item of group.variables) {
                                    menu.push({
                                        type: 'menuitem',
                                        text: item.descr,
                                        onAction: (_: any) => {
                                            p.getContentVariablesComponent()?.select(item.id);
                                        }
                                    });
                                }

                            }


                            callback(menu);

                        }

                    });
                }

                p.setup(editor);

                editor.on('keydown', (e) => {
                    if (e.keyCode == 13 && !props.multiline) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                    return true;
                });
            },
            variables_regexp: regexp.value,
            variables_noneditable_class: TextInputWithVariablesHelper.className
        } as any as Settings
    });

    onMounted(() => {
        let editor = p.getEditor();
        editor?.on("keyup", function () {
            if (!p.getEditor()?.getContent()?.length) {
                instance.$emit("update:modelValue", "");
            }
        });
    });

    onBeforeUnmount(() => {
        let tinymce = p.getEditor();
        if (tinymce) {
            //tinymce.remove();
            tinymce.destroy();
            if (tinymce.editorManager) {
                tinymce.editorManager.settings = undefined;
            }
        }
    })


    watch(computed(() => props.modelValue), newValue => {
        let editor = p.getEditor();
        if (editor) {
            if (typeof newValue != "string") {
                editor.setContent("");
            } else {
                if (p.isBbCode()) {
                    newValue = normalizeValue(newValue);
                    let html = StringHelper.bbcodeToHtml(newValue);
                    if (html != StringHelper.textToHtml(newValue)) {
                        let content = normalizeValue(editor.getContent());
                        if (html != content) {
                            if (StringHelper.htmlToBbcode(html) != normalizeValue(StringHelper.htmlToBbcode(content))) {
                                editor.setContent(html);
                            }
                        }
                    }
                }
            }
        }
    });

    return {
        dataLabelAttribute,
        commonSettings,
        variableGroups,
        paste,
        getSettings: () => p.getSettings(),
        helper,
        getText,
        variablesList,
        regexp
    };
}


interface IMenuItem {
    type: 'menuitem',
    text: string,
    disabled?: boolean,
    getSubmenuItems?: () => IMenuItem[],
    onAction?: (_: any) => void
}