import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import DateHelper from "~/ts/library/Date";
import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {computed} from "vue";
import {FILTER_TYPE_DATETIME} from "~/cabinet/vue/interface/filter/MyFilterTypes";
import MyDatePicker from "~/core-ui/vue/ui/MyDatePicker.vue";


const __default__ = {
    name: "MyFilterDate"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let model = _useModel<any>(__props, "modelValue");

let filterTypeId = computed(() => {
    return props.component.filter.type;
});

let type = computed(() => {
    if (filterTypeId.value == FILTER_TYPE_DATETIME) {
        return "datetime";
    }
    return "date";
});

let format = computed(() => {
    if (filterTypeId.value == FILTER_TYPE_DATETIME) {
        return "DD.MM.YYYY HH:mm:ss";
    }
    return "DD.MM.YYYY"
})

function onInput(value: Date | Date[]) {
    let result: any;
    if (value instanceof Date || typeof value == "string") {
        let helper = new DateHelper(value);
        result = type.value == "date" ? helper.toMysqlFormatDate() : helper.toMysqlFormat();
    } else {
        result = [];
        for (let i = 0; i < value.length; i++) {
            let helper = new DateHelper(value[i]);
            result.push(/*type.value == "daterange" ? helper.toMysqlFormatDate() :*/ helper.toMysqlFormat());
        }
    }
    model.value = result;
}

let isFirstInputOfRange = computed(() => {
    return props.components.length > 1 && props.index == 0;
})

let isSecondInputOfRange = computed(() => {
    return props.components.length > 1 && props.index == 1;
})

let defaultTime = computed(() => {
    let date = new Date();
    let hour = 0;
    let minute = 0;
    let seconds = 0;

    /*if (type.value == "datetimerange") {
        return ["00:00:00", "23:59:59"];
    } else */
    if (type.value == "datetime") {
        if (isSecondInputOfRange.value) {
            hour = 23;
            minute = 59;
            seconds = 59;
        }
    }
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(seconds);
    return date;
});


let defaultValue = computed(() => {
    let date = new DateHelper(defaultTime.value);
    if (isFirstInputOfRange.value) {
        date.dateObject.setDate(date.dateObject.getDate() - 7);
    }
    if (type.value == "date") {
        return date.toMysqlFormatDate();
    } else if (type.value == "datetime") {
        return date.toMysqlFormat();
    }
    return null;
})

let valueFormat = computed(() => {
    return (type.value == "datetime"/* || type.value == "datetimerange"*/) ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
});

let formatedValue = computed<string | null | (string | null)[]>(() => {
    let newValue: string | (string | null)[];
    if (Array.isArray(model.value)) {
        newValue = [];
        for (let i = 0; i < model.value.length; i++) {
            newValue.push(model.value[i]);
        }
    } else {
        newValue = model.value;
    }
    return newValue;
});

if (!formatedValue.value) {
    onInput(new Date(defaultValue.value));
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyDatePicker, {
    clearable: false,
    "default-time": _unref(defaultTime),
    format: _unref(format),
    placeholder: _ctx.component.filter.placeholder,
    type: _unref(type),
    "model-value": _unref(formatedValue) ?? _unref(defaultValue),
    "value-format": _unref(valueFormat),
    "onUpdate:modelValue": onInput
  }, null, 8, ["default-time", "format", "placeholder", "type", "model-value", "value-format"]))
}
}

})