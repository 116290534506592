import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon"
}

import ElCardSectionWithIconText from "~/cabinet/vue/interface/card/ElCardSectionWithIconText.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "ElCardSectionWithIcon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    label: {},
    icon: {},
    iconColor: {},
    value: {},
    noPadding: { type: Boolean },
    noIcon: { type: Boolean },
    noBorder: { type: Boolean },
    light: { type: Boolean },
    regular: { type: Boolean },
    bold: { type: Boolean },
    grayLabel: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ElCardSection, {
    class: _normalizeClass(['section', _ctx.noPadding ? 'without-padding' : '', _ctx.noIcon ? 'without-icon' : '']),
    "no-border": _ctx.noBorder
  }, {
    default: _withCtx(() => [
      (!_ctx.noIcon)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.$slots.icon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "icon")
                ]))
              : (_openBlock(), _createBlock(FontAwesomeIcon, {
                  key: 1,
                  bold: _ctx.bold,
                  color: _ctx.iconColor,
                  style: _normalizeStyle(_ctx.iconColor && _ctx.iconColor.substring(0, 1) === '#' ? {color: _ctx.iconColor } : null),
                  icon: _ctx.icon,
                  light: _ctx.light,
                  regular: _ctx.regular,
                  class: "icon",
                  square: ""
                }, null, 8, ["bold", "color", "style", "icon", "light", "regular"]))
          ], 64))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(ElCardSectionWithIconText, {
        class: "card-section-with-icon-text",
        "gray-label": _ctx.grayLabel
      }, {
        heading: _withCtx(() => [
          (_ctx.value != null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.value), 1)
              ], 64))
            : (_ctx.$slots.value)
              ? _renderSlot(_ctx.$slots, "value", { key: 1 })
              : _createCommentVNode("", true)
        ]),
        label: _withCtx(() => [
          (_ctx.label != null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ], 64))
            : (_ctx.$slots.label)
              ? _renderSlot(_ctx.$slots, "label", { key: 1 })
              : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["gray-label"]),
      _renderSlot(_ctx.$slots, "append")
    ]),
    _: 3
  }, 8, ["class", "no-border"]))
}
}

})