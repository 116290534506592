import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "aside-usluga-title text-truncate" }
const _hoisted_2 = {
  key: 0,
  class: "aside-usluga-description"
}

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "AsideSectionItem"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    description: {},
    chevron: { type: Boolean },
    title: {},
    icon: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", {
    class: "aside-usluga-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click')))
  }, [
    _createVNode(_component_el_container, { class: "container-valign-middle w-100" }, {
      default: _withCtx(() => [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
              key: 0,
              class: "aside-usluga-icon",
              icon: _ctx.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_row, { class: "w-100 el-container container-valign-middle h-auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: _ctx.chevron ? 20 : 24
            }, {
              default: _withCtx(() => [
                _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.title), 1),
                (_ctx.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.description), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["span"]),
            (_ctx.chevron)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 4,
                  class: "text-right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, {
                      class: "aside-usluga-chevron",
                      icon: "chevron-down"
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})