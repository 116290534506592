import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, isRef as _isRef, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }

import {AccountStore, INoticeChannel} from "~/cabinet/ts/store/AccountStore";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigIconFlexButton from "~/cabinet/vue/interface/button/BigIconFlexButton.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import AbstractEntity from "~/ts/library/AbstractEntity";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import {computed, ref} from "vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";


const __default__ = {
    name: "SubscribeInMessengers"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let channel = ref<INoticeChannel>();

let channels = computed(() => {
    let result = AccountStore.noticeChannels.value;
    if (!channel.value) {
        channel.value = result[0];
    }
    return result;
});

let request = computed(() => {
    return new ReactiveRequest(new LcabApiRequest({
        url: '/api/client/notifications/getNoticeSubscribeCode',
        p: {channelId: channel.value.id}
    }, Response))
});

class Response extends AbstractEntity {
    code: string
}

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!

  return (_unref(channels).length > 0)
    ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(ElCardHeader, { title: "Подписка в мессенджерах" }),
          _createVNode(ElCardSection, null, {
            default: _withCtx(() => [
              (_unref(channels).length > 1)
                ? (_openBlock(), _createBlock(BigIconFlexButtonWrapper, {
                    key: 0,
                    class: "mb-4"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(channels), (item) => {
                        return (_openBlock(), _createBlock(BigIconFlexButton, {
                          key: item.id,
                          icon: item.icon,
                          "icon-color": item.color,
                          descr: item.descr,
                          selectable: "",
                          selected: item === _unref(channel),
                          onClick: ($event: any) => (_isRef(channel) //@ts-ignore
 ? channel.value = item : channel = item)
                        }, null, 8, ["icon", "icon-color", "descr", "selected", "onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(), _createElementBlock("div", {
                key: _unref(channel).id
              }, [
                _createElementVNode("p", null, [
                  _cache[0] || (_cache[0] = _createTextVNode(" Для получения уведомлений откройте ")),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(channel).descr), 1),
                  _createElementVNode("a", {
                    href: _unref(channel).link,
                    target: "_blank"
                  }, "по этой ссылке", 8, _hoisted_2),
                  _cache[1] || (_cache[1] = _createTextVNode(" и отправьте сообщение, содержащее код: "))
                ]),
                _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
                  default: _withCtx(() => [
                    (_unref(request).data)
                      ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_unref(request).data.code), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["request"])
              ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})