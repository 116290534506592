import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


const __default__ = {
    name: "SenderClearForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {


let model = _useModel<boolean>(__props, "modelValue");


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_switch, {
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      class: "mr-2"
    }, null, 8, ["modelValue"]),
    _cache[1] || (_cache[1] = _createTextVNode(" Очищать форму после отправки "))
  ]))
}
}

})