import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }

import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {GlobalVarsActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "GlobalVarsBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<GlobalVarsActionDescription>()
    },
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.params.vars, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(item.key) + " =", 1),
        _createVNode(InputWithVariablesPreview, {
          text: item.value,
          variables: _ctx.contentVariables
        }, null, 8, ["text", "variables"])
      ]))
    }), 128))
  ]))
}
}

})