import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-red"
}

import {PhonebookActionWithGroupActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "PhonebookWithGroupSelectBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<PhonebookActionWithGroupActionDescription>()
    },
  setup(__props) {

let props = __props;

let groups = computed(() => {
    return props.actionDescription.frontendParams.groups
        .filter(option => props.params.groupIds.indexOf(option.value as number) > -1)
        .map(option => option.descr)
        .join(", ");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(props).params.isAllGroups)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(" Все группы ")
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_unref(groups).length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_unref(groups)), 1)
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_1, " Группы не заданы "))
        ], 64))
  ]))
}
}

})