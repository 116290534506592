import {Router} from "vue-router";
import {ref} from "vue";

let asideVisible = ref(false);
let defaultRouteName = ref("voice");
let appUrlPrefix = ref("/cabinet");
let router = ref<Router>();

export const LcabLayoutStore = {
    toggleAsideVisible: () => {
        asideVisible.value = !asideVisible.value
    },
    asideVisible,
    defaultRouteName,
    appUrlPrefix,
    router
}