import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

import {ITextFieldMixin} from "../Interfaces";
import OptionsConstructor from "~/cabinet/vue/interface/form/elements/constructor/OptionsConstructor.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";


const __default__ = {
    name: "TextElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    withDimension: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<ITextFieldMixin>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    placeholder: null,
    suggest: [],
    dimension: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Вспомогательный текст в поле" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).placeholder) = $event)),
          placeholder: "Введите текст"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "Саджесты" }, {
      default: _withCtx(() => [
        _createVNode(OptionsConstructor, {
          modelValue: _unref(model).suggest,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).suggest) = $event)),
          "hide-default": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (_ctx.withDimension)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Размерность"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _unref(model).dimension,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).dimension) = $event)),
              placeholder: "Необязательно"
            }, null, 8, ["modelValue"]),
            _createVNode(HelpBlock, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Будет показана клиенту справа от текстового поля")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})