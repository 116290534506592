import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, isRef as _isRef, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_3 = {
  key: 1,
  style: {"white-space":"normal","word-break":"break-word"}
}
const _hoisted_4 = { class: "button-descr" }
const _hoisted_5 = {
  key: 0,
  class: "text-muted font-size-small mt-1"
}
const _hoisted_6 = {
  key: 1,
  class: "mt-1 lh-primary"
}

import {computed, ref} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Dictionary from "~/ts/library/Dictionary";
import {BigIconFlexButtonExposeInterface} from "~/cabinet/vue/interface/button/Interface";
import {Icon} from "~/cabinet/vue/interface/icon/IconInterface";



const __default__ = {
    name: "BigIconFlexButton",
    inheritAttrs: false
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    descr: {},
    extraDescr: {},
    icon: {},
    iconColor: {},
    iconColorClass: {},
    buttonType: {},
    removable: { type: Boolean },
    iconSize: { default: '1em' },
    selectable: { type: Boolean },
    selected: { type: Boolean },
    buttonClass: {},
    disabled: { type: Boolean },
    shadow: { type: Boolean, default: false },
    minWidth: { default: '11em' },
    popperClass: {},
    popoverPlacement: {},
    showPopoverArrow: { type: Boolean, default: true },
    plain: { type: Boolean, default: true },
    paddingClass: { default: 'p-2' },
    popoverTrigger: { default: 'click' },
    badge: {}
  },
  emits: ["click", "remove"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let popoverModel = ref(false);

let iconStyle = computed(() => {
    let result: Dictionary<string> = {
        fontSize: props.iconSize
    };
    if (props.iconColor) {
        result.color = props.iconColor;
    }
    return result;
});

function closePopover() {
    popoverModel.value = false;
}

__expose<BigIconFlexButtonExposeInterface>({
    closePopover
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    disabled: !_ctx.$slots.popover,
    visible: _unref(popoverModel),
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(popoverModel) ? (popoverModel).value = $event : popoverModel = $event)),
    "popper-class": `${_ctx.popperClass} overflow-visible`,
    placement: _ctx.popoverPlacement,
    trigger: _ctx.popoverTrigger
  }, _createSlots({
    reference: _withCtx(() => [
      _createElementVNode("span", _mergeProps({
        class: `${_ctx.paddingClass} d-flex flex-column big-icon-flex-button`,
        style: {minWidth: _ctx.minWidth}
      }, _ctx.$attrs), [
        _createVNode(_component_el_button, {
          disabled: _ctx.disabled,
          class: _normalizeClass({
                        [`text-center d-flex ml-0 mr-0 w-100 h-100 position-relative  button ${_ctx.buttonClass}`]: true,
                        selectable: _ctx.selectable,
                        selected: _ctx.selected,
                        'with-shadow': _ctx.shadow
                    }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$nextTick(() => _ctx.$nextTick(() => _unref(emits)('click'))))),
          type: _ctx.buttonType,
          plain: _ctx.plain
        }, {
          default: _withCtx(() => [
            (_ctx.removable)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  class: "position-absolute",
                  type: "danger",
                  circle: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('remove'))),
                  size: "small",
                  style: {
                        top: '-.5em',
                        right: '-.8em'
                    }
                }, {
                  default: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, { icon: "times" })
                  ]),
                  _: 1
                })), [
                  [
                    _directive_on_native,
                    void 0,
                    "click",
                    { stop: true }
                  ]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              (_ctx.icon || _ctx.$slots.icon)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
                    (_ctx.badge)
                      ? (_openBlock(), _createBlock(_component_el_badge, {
                          key: 0,
                          class: "badge",
                          type: "danger",
                          value: _ctx.badge
                        }, null, 8, ["value"]))
                      : _createCommentVNode("", true),
                    (!_ctx.icon)
                      ? _renderSlot(_ctx.$slots, "icon", { key: 1 })
                      : (_openBlock(), _createBlock(FontAwesomeIcon, {
                          key: 2,
                          icon: _ctx.icon,
                          style: _normalizeStyle(_unref(iconStyle)),
                          light: "",
                          class: _normalizeClass(_ctx.iconColorClass ? `text-${_ctx.iconColorClass}` : null)
                        }, null, 8, ["icon", "style", "class"]))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.descr)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.descr), 1),
                    (_ctx.extraDescr || _ctx.$slots.extraDescr)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createTextVNode(_toDisplayString(_ctx.extraDescr) + " ", 1),
                          _renderSlot(_ctx.$slots, "extraDescr")
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.$slots.popover && _ctx.showPopoverArrow)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(FontAwesomeIcon, {
                            icon: "chevron-down",
                            class: "text-muted popover-chevron"
                          }),
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "popover-info text-muted d-none" }, " Клик для разворота ", -1))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 3
        }, 8, ["disabled", "class", "type", "plain"])
      ], 16)
    ]),
    _: 2
  }, [
    (_unref(popoverModel))
      ? {
          name: "default",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "popover")
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["disabled", "visible", "popper-class", "placement", "trigger"]))
}
}

})