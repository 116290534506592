import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {
    ToggleTagActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";
import Tags from "~/cabinet/vue/interface/data/Tags.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "ToggleTagActionBlockPreview"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<ToggleTagActionDescription>()
    },
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(props).params.tagsToAdd.length)
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, {
          key: 0,
          label: "Добавить тэги"
        }, {
          default: _withCtx(() => [
            _createVNode(Tags, {
              tags: _unref(props).params.tagsToAdd
            }, null, 8, ["tags"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(props).params.tagsToRemove.length)
      ? (_openBlock(), _createBlock(ActionBlockPreviewItem, {
          key: 1,
          label: "Удалить тэги"
        }, {
          default: _withCtx(() => [
            _createVNode(Tags, {
              tags: _unref(props).params.tagsToRemove
            }, null, 8, ["tags"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})