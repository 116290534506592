import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import ClickableArea from "~/cabinet/vue/interface/ClickableArea.vue";


const __default__ = {
    name: "ElCardSectionWithRoute"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    icon: {},
    routeName: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(ElCardSection, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: { name: _ctx.routeName },
        class: "link-no-underline"
      }, {
        default: _withCtx(() => [
          _createVNode(ClickableArea, { icon: _ctx.icon }, {
            text: _withCtx(() => [
              _renderSlot(_ctx.$slots, "text")
            ]),
            tip: _withCtx(() => [
              _renderSlot(_ctx.$slots, "tip")
            ]),
            _: 3
          }, 8, ["icon"])
        ]),
        _: 3
      }, 8, ["to"])
    ]),
    _: 3
  }))
}
}

})