import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "prism" }

import Dictionary from "~/ts/library/Dictionary";
import {HTML_BLOCK_CODE} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import PrismEditor from "~/cabinet/vue/interface/PrismEditor.vue";
import ICodeBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/code/ICodeBlockValues";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import {computed, PropType} from "vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "CodeDisplay"
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps(),
    inputStyle: {
        type: Object as PropType<Dictionary<any>>
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<ICodeBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let blockType = computed(() => {
    return htmlConstructorEditor.params.blockTypesDictionary[HTML_BLOCK_CODE];
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PrismEditor, {
      modelValue: _unref(model).code,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).code) = $event)),
      language: _unref(model).language,
      "content-variables": _unref(htmlConstructorEditor).params.contentVariables
    }, null, 8, ["modelValue", "language", "content-variables"])
  ]))
}
}

})