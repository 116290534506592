import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

import {computed, nextTick, ref} from 'vue';
import {IFilter, IFiltersData, IOperation} from "./Interfaces";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import MyFilterValuesItemInterface from "~/cabinet/vue/interface/filter/MyFilterValuesItemInterface";


const __default__ = {
    name: "MyFilterValues"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    canDeleteFilter: { type: Boolean },
    filter: {},
    operation: {},
    filtersData: {},
    showAddOperationButton: { type: Boolean }
  }, {
    "modelValue": {
    default: []
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["delete"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<any[]>(__props, "modelValue");

let valueItems = computed<any[]>(() => {
    if (MyFilterHelper.isSingleMultipleComponentSituation(props.filter, props.operation)) {
        return [model.value];
    } else {
        return model.value.length ? model.value : [undefined];
    }
});

let filters = computed<IFilter[]>(() => {
    return props.operation.filterInputs == null ? [props.filter] : props.operation.filterInputs;
});

function deleteValue(valueIndex: number) {
    if (valueItems.value.length === 1) {
        emits('delete');
    } else {
        spliceAndEmitInput(valueIndex);
    }
}

function spliceAndEmitInput(index: number, deleteCount: number = 1, value?: any) {
    let newValue = [...model.value];
    if (arguments.length === 3) {
        let options = props.filter.options ?? {};
        if (hasVisibleFilters.value && value == null && newValue.length == 1 && index == 0 && !options[""]) {
            //если нам передали пустую строку в качестве первого значения
            newValue = [];
        } else {
            newValue.splice(index, deleteCount, value);
        }
    } else {
        newValue.splice(index, deleteCount);
    }
    model.value = newValue;
}

function onInput(value: any, rowIndex: number) {
    if (!isSingleMultipleComponentSituation.value) {
        spliceAndEmitInput(rowIndex, 1, value);
    } else {
        model.value = value;
    }
}

let isSingleMultipleComponentSituation = computed(() => {
    return MyFilterHelper.isSingleMultipleComponentSituation(props.filter, props.operation);
});

let isAddValueButtonNeeded = computed(() => {
    let result = false;

    if (props.filter.multiple) {
        if (hasVisibleFilters.value) {
            if (!isSingleMultipleComponentSituation.value) {
                result = true;
            }
        }
    }
    return result;
});

let hasVisibleFilters = computed(() => {
    return MyFilterHelper.hasVisibleFilters(props.filter, props.operation);
});

let lastInput = ref<MyFilterValuesItemInterface[]>();

function addValue() {
    spliceAndEmitInput(model.value.length, 0, undefined);

    nextTick(() => {
        if (lastInput.value && lastInput.value[0]) {
            (lastInput.value[0]).focus();
        }
    });
}

return (_ctx: any,_cache: any) => {
  const _component_my_filter_values_item = _resolveComponent("my-filter-values-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(valueItems), (valueItem, valueIndex) => {
      return (_openBlock(), _createBlock(_component_my_filter_values_item, {
        key: valueIndex,
        "model-value": valueItem,
        filter: _ctx.filter,
        operation: _ctx.operation,
        ref_for: true,
        ref: (valueIndex === _unref(valueItems).length - 1) ? 'lastInput' : null,
        "filters-data": _ctx.filtersData,
        "can-add": valueIndex === _unref(valueItems).length - 1 && _unref(isAddValueButtonNeeded),
        "can-delete": _ctx.canDeleteFilter || _unref(valueItems).length > 1,
        "onUpdate:modelValue": ($event: any) => (onInput($event, valueIndex)),
        onDelete: ($event: any) => (deleteValue(valueIndex)),
        onAdd: _cache[0] || (_cache[0] = ($event: any) => (addValue()))
      }, null, 8, ["model-value", "filter", "operation", "filters-data", "can-add", "can-delete", "onUpdate:modelValue", "onDelete"]))
    }), 128))
  ]))
}
}

})