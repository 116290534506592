import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "vertical-section-selector" }
const _hoisted_2 = { class: "label" }

import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadgeDisplay.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {useSectionSelector} from "~/cabinet/vue/layout/lcab/Aside/useSectionSelector";
import {computed} from "vue";
import {useRoute} from "vue-router";


const __default__ = {
    name: "VerticalSectionSelector"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let route = useRoute();
let {sectionList, getPathForSection} = useSectionSelector(() => {
});

let activeSection = computed(() => {
    return sectionList.value.find(section => {
        return !!route.matched.find(item => item.name == section.routeName);
    });
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sectionList), (section) => {
      return (_openBlock(), _createElementBlock("li", {
        key: section.routeName
      }, [
        _createVNode(_component_router_link, {
          to: _unref(getPathForSection)(section),
          class: _normalizeClass(_unref(activeSection) === section ? 'selected' : null)
        }, {
          default: _withCtx(() => [
            _createVNode(MenuBadge, { "menu-item": section }, {
              default: _withCtx(() => [
                _createVNode(FontAwesomeIcon, {
                  icon: section.icon,
                  light: section !== _unref(activeSection),
                  bold: section === _unref(activeSection)
                }, null, 8, ["icon", "light", "bold"])
              ]),
              _: 2
            }, 1032, ["menu-item"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(section.descr), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"])
      ]))
    }), 128))
  ]))
}
}

})