import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import RegisterDateTimeSend from "~/cabinet/vue/client/sender/send/settings/RegisterDateTimeSend.vue";
import SenderSmooth from "~/cabinet/vue/client/sender/send/settings/SenderSmooth.vue";
import SenderTimeRange from "~/cabinet/vue/client/sender/send/settings/SenderTimeRange.vue";
import SenderReplyOnlineChat from "~/cabinet/vue/client/sender/send/settings/SenderReplyOnlineChat.vue";
import SenderMagicUrl from "~/cabinet/vue/client/sender/send/settings/SenderMagicUrl.vue";
import RegisterTags from "~/cabinet/vue/client/sender/send/settings/RegisterTags.vue";
import {SENDER_FORM_SETTINGS, SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import Dictionary from "~/ts/library/Dictionary";
import SenderMessageTimeout from "~/cabinet/vue/client/sender/send/settings/SenderMessageTimeout.vue";
import SenderClearForm from "~/cabinet/vue/client/sender/send/settings/SenderClearForm.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import MySelect from "~/cabinet/vue/interface/form/MySelect.vue";
import SenderCoupon from "~/cabinet/vue/client/sender/send/settings/SenderCoupon.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, defineAsyncComponent} from "vue";
import {
    SenderCreateFormParamsInterface,
    SenderFormSettingsModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {__} from "~/ts/library/Translate";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";


const __default__ = {
    name: "SenderFormSettings"
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    params: {},
    isReplyToChatAvailableByContent: { type: Boolean },
    isContactBookDataSource: { type: Boolean },
    selectedSource: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const SenderOnlineChatParamsConfig = useRawAsyncComponent(() => import(/* webpackChunkName: "sender-bot-config" */"~/cabinet/vue/client/sender/send/settings/SenderOnlineChatParamsConfig.vue"));


let props = __props;


let details = AccountStore.details;

let _modelValue = _useModel<SenderFormSettingsModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    dateTimeSend: null,
    smooth: null,
    timeRange: null,
    orgDetailId: details.value.length ? details.value[0].value : null,
    tags: null,
    replyToOnlineChat: null,
    magicUrl: null,
    messageTimeout: null,
    clearForm: false,
    destId: null,
    startBot: null,
    onlineChatParams: null
}));
if (model.value.startBot && !model.value.onlineChatParams) {
    model.value.onlineChatParams = model.value.startBot;
    delete model.value.startBot;
}


let formParams = computed(() => {
    return props.params.formParams;
});

let isSettingsVisible = computed(() => {
    for (let key of SENDER_FORM_SETTINGS) {
        if ((settings.value as any as Dictionary<boolean>)[key]) {
            return true;
        }
    }
    return false;
});

let settings = computed(() => {
    let p = formParams.value;
    return {
        dateTimeSend: p.dateTimeSend,
        smooth: p.smooth,
        timeRange: p.timeRange,
        messageTimeout: p.messageTimeout && props.params.isAvailableMessageTimeout,
        replyToOnlineChat: p.replyToOnlineChat && props.params.isAvailableReplyToChat,
        magicUrl: p.magicUrl && props.params.isAvailableMagicUrl,
        orgDetailId: p.orgDetailId && details.value.length > 1,
        tags: p.tags,
        destsSelect: p.destsSelect && props.params.dests,
        clearForm: p.clearForm,
        coupon: p.coupon
    };
});


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_unref(isSettingsVisible))
    ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            "label-position": "left",
            "label-width": "200px",
            class: "bordered"
          }, {
            default: _withCtx(() => [
              (_ctx.params.isAvailableStartBot)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(SenderOnlineChatParamsConfig), {
                        modelValue: _unref(model).onlineChatParams,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).onlineChatParams) = $event)),
                        "selected-source": _ctx.selectedSource,
                        "is-contact-book-data-source": _ctx.isContactBookDataSource,
                        params: _ctx.params
                      }, null, 8, ["modelValue", "selected-source", "is-contact-book-data-source", "params"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).dateTimeSend)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 1,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(RegisterDateTimeSend, {
                        modelValue: _unref(model).dateTimeSend,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).dateTimeSend) = $event)),
                        "usluga-id": _ctx.uslugaId
                      }, null, 8, ["modelValue", "usluga-id"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).smooth)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 2,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderSmooth, {
                        modelValue: _unref(model).smooth,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).smooth) = $event)),
                        class: "without-border",
                        "date-time-send": _unref(model).dateTimeSend,
                        "usluga-id": _ctx.uslugaId
                      }, null, 8, ["modelValue", "date-time-send", "usluga-id"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).timeRange)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 3,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderTimeRange, {
                        modelValue: _unref(model).timeRange,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).timeRange) = $event)),
                        "usluga-id": _ctx.uslugaId,
                        saveable: _unref(formParams).timeRangeSaveable
                      }, null, 8, ["modelValue", "usluga-id", "saveable"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).messageTimeout)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 4,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderMessageTimeout, {
                        "usluga-id": _ctx.uslugaId,
                        modelValue: _unref(model).messageTimeout,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).messageTimeout) = $event))
                      }, null, 8, ["usluga-id", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).replyToOnlineChat)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 5,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderReplyOnlineChat, {
                        modelValue: _unref(model).replyToOnlineChat,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).replyToOnlineChat) = $event)),
                        sites: _ctx.params.onlineChatSites,
                        available: _ctx.isReplyToChatAvailableByContent
                      }, null, 8, ["modelValue", "sites", "available"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).magicUrl)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 6,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderMagicUrl, {
                        modelValue: _unref(model).magicUrl,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(model).magicUrl) = $event)),
                        "usluga-id": _ctx.uslugaId
                      }, null, 8, ["modelValue", "usluga-id"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(formParams).coupon)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 7,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderCoupon, {
                        modelValue: _unref(model).coupon,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).coupon) = $event)),
                        params: _ctx.params
                      }, null, 8, ["modelValue", "params"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).orgDetailId)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 8,
                    label: _unref(__)('Реквизиты')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MySelect, {
                        modelValue: _unref(model).orgDetailId,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).orgDetailId) = $event)),
                        class: "w-100",
                        options: _unref(details)
                      }, null, 8, ["modelValue", "options"])
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : _createCommentVNode("", true),
              (_unref(settings).tags)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 9,
                    label: _unref(__)('Тэги')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(RegisterTags, {
                        modelValue: _unref(model).tags,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(model).tags) = $event)),
                        "usluga-id": _ctx.uslugaId
                      }, null, 8, ["modelValue", "usluga-id"])
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : _createCommentVNode("", true),
              (_unref(settings).destsSelect)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 10,
                    label: "Провайдер"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: _unref(model).destId,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(model).destId) = $event)),
                        class: "w-100",
                        clearable: "",
                        placeholder: "Будет выбран автоматически"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.params.dests, (dest) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: dest.id,
                              label: dest.descr,
                              value: dest.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_unref(settings).clearForm)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 11,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(SenderClearForm, {
                        modelValue: _unref(model).clearForm,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(model).clearForm) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})