import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "toolbar-btn toolbar-btn__external" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.prefixCls}-toolbar`)
  }, [
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__zoomin",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "zoomin" })
    ]),
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__zoomout",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "zoomout" })
    ]),
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__resize",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resize && _ctx.resize(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "resize" })
    ]),
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__rotate",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.rotateLeft && _ctx.rotateLeft(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "rotate-left" })
    ]),
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__rotate",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.rotateRight && _ctx.rotateRight(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "rotate-right" })
    ]),
    _createElementVNode("div", {
      class: "toolbar-btn toolbar-btn__rotate",
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.mirror && _ctx.mirror(...args)))
    }, [
      _createVNode(_component_svg_icon, { type: "mirror" })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: _ctx.src,
        target: "_blank",
        style: {"color":"inherit !important","text-decoration":"none !important"}
      }, [
        _createVNode(_component_svg_icon, { type: "external" })
      ], 8, _hoisted_2)
    ]),
    (_ctx.download)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "toolbar-btn toolbar-btn__download",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.download(_ctx.src)))
        }, [
          _createVNode(_component_svg_icon, { type: "download" })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}