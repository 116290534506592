import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "table w-100" }
const _hoisted_2 = { class: "w-50" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "pr-2" }
const _hoisted_5 = { key: 1 }

import Integration from "~/cabinet/ts/data/online/config/integration/Integration";
import {computed} from "vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import CoreUiAvatar from "~/core-ui/vue/chat/CoreUiAvatar.vue";

interface ResponseInterface {
    users: UserInterface[];
    operators: OperatorInterface[]
}

interface OperatorInterface {
    id: string;
    descr: string;
    avatar: string;
    isOnThisSite: boolean;
}

interface UserInterface {
    id: string | number;
    descr: string;
    operatorId?: string;
}

import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "CrmUsersDialog"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    integration: {},
    siteId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

let props = __props;


let visible = _useModel<boolean>(__props, "visible");

let request = computed(() => {
    return new ReactiveRequest<ResponseInterface>(new LcabApiRequest({
        url: `/api/online/${props.siteId}/config/${props.integration.auth.platformId}/${props.integration.auth.primaryId}/getUsersDialog`,
    }));
});

async function apply() {
    let operators: any = {};
    for (let user of request.value.data.users) {
        if (user.operatorId) {
            operators[user.operatorId] = user.id;
        }
    }
    let result = await LcabApiRequest.save({
        url: `/api/online/config/${props.integration.auth.platformId}/${props.integration.auth.primaryId}/saveUsersDialog`,
        p: {
            operators
        }
    });
    if (result.isSuccess) {
        visible.value = false;
    }
}

let operatorsByGroups = computed(() => {
    return {
        'Этот сайт': request.value.data?.operators.filter(operator => operator.isOnThisSite),
        'Другие сайты': request.value.data?.operators.filter(operator => !operator.isOnThisSite)
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(MyDialog, {
    visible: _unref(visible),
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    title: "Сопоставление операторов",
    "apply-disabled": !_unref(request).data || !_unref(request).data.users.length,
    onApply: _cache[1] || (_cache[1] = ($event: any) => (apply()))
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(ElCardSectionWithTable, null, {
                    default: _withCtx(() => [
                      _createElementVNode("table", _hoisted_1, [
                        _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Пользователь из интеграции"),
                            _createElementVNode("th", null, "Оператор")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_unref(request).data.users.length)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(request).data.users, (user) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: user.id
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(user.descr), 1),
                                  _createElementVNode("td", _hoisted_2, [
                                    _createVNode(_component_el_select, {
                                      modelValue: user.operatorId,
                                      "onUpdate:modelValue": ($event: any) => ((user.operatorId) = $event),
                                      class: "w-100",
                                      filterable: "",
                                      clearable: ""
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operatorsByGroups), (operators, key) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            (operators.length)
                                              ? (_openBlock(), _createBlock(_component_el_option_group, {
                                                  key: 0,
                                                  label: key
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operators, (operator) => {
                                                      return (_openBlock(), _createBlock(_component_el_option, {
                                                        key: operator.id,
                                                        value: operator.id,
                                                        label: operator.descr,
                                                        disabled: !!_unref(request).data.users.find(item => user.id != item.id && item.operatorId == operator.id)
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createElementVNode("div", _hoisted_3, [
                                                            _createElementVNode("div", _hoisted_4, [
                                                              _createVNode(CoreUiAvatar, {
                                                                src: operator.avatar,
                                                                size: "24px"
                                                              }, null, 8, ["src"])
                                                            ]),
                                                            _createElementVNode("div", null, _toDisplayString(operator.descr), 1)
                                                          ])
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["value", "label", "disabled"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["label"]))
                                              : _createCommentVNode("", true)
                                          ], 64))
                                        }), 256))
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ]))
                              }), 128))
                            : (_openBlock(), _createElementBlock("tr", _hoisted_5, _cache[2] || (_cache[2] = [
                                _createElementVNode("td", { class: "text-muted" }, " Не получилось загрузить пользователей из интеграции ", -1)
                              ])))
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }, 8, ["visible", "apply-disabled"]))
}
}

})