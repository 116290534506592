import { defineComponent as _defineComponent } from 'vue'

import {computed, onActivated, onBeforeUnmount, onDeactivated, onMounted, watch} from 'vue';
import StringHelper from "~/ts/library/StringHelper";
import {MyStyleCssValue} from "~/cabinet/vue/interface/MyStyleInterface";


const __default__ = {
    name: "MyStyle"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    id: {},
    css: {},
    cssString: { default: "" },
    rootSelector: {},
    prepend: { type: Boolean },
    important: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

onMounted(() => replaceStyles());
onActivated(() => replaceStyles());


let style = computed(() => {
    let result: string = props.cssString;
    for (let selector in props.css) {
        if (props.css.hasOwnProperty(selector)) {
            if (props.rootSelector) {
                result += `${props.rootSelector} ${selector.split(',').join(`, ${props.rootSelector} `)} {`;
            } else {
                result += `${selector} {`;
            }
            let properties = props.css[selector];
            for (let property in properties) {
                if (properties.hasOwnProperty(property)) {
                    result += `${StringHelper.toKebab(property)}: ${properties[property]} ${props.important ? '!important' : ''};`;
                }
            }
            result += '}';
        }
    }
    return result;
});

watch(style, () => replaceStyles());


function replaceStyles() {
    let css = document.getElementById(props.id);
    if (css == null) {
        css = document.createElement("style");
        css.id = props.id;
        let container = document.head;
        let firstChildren = container.children[0];
        if (props.prepend && firstChildren) {
            container.insertBefore(css, firstChildren);
        } else {
            container.appendChild(css);
        }

    }

    css.innerHTML = style.value;
}

function remove() {
    let css = document.getElementById(props.id);
    if (css) {
        css.remove();
    }
}

onBeforeUnmount(() => remove());
onDeactivated(() => remove());

return (_ctx: any,_cache: any) => {
  return null
}
}

})