import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IHrBlockValues, {IHrBlockStyles} from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/IHrBlockValues";
import HrDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/HrDisplay.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class HrBlockType extends AbstractBlockType<IHrBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(HrDisplay);
    }

    get formComponent(): RawComponent {
        return null;
    }


    hasLeftRightPadding(value: IHrBlockValues, styles: IHrBlockStyles): boolean {
        return !styles.bodyHrNoPadding;
    }


}
