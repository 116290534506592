import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "aside-usluga-dropdown w-100" }

import {ref} from "vue";
import AsideSectionItem from "./AsideSectionItem.vue";
import {useSectionSelector} from "~/cabinet/vue/layout/lcab/Aside/useSectionSelector";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "AsideSectionSelector"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let {
    currentSection,
    sectionList,
    select
} = useSectionSelector(() => toggleDisplayed(false));

let displayed = ref(false);

function toggleDisplayed(value: boolean = !displayed.value) {
    displayed.value = value;
}

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_col, { class: "aside-usluga-selector" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, null, {
              default: _withCtx(() => [
                _createVNode(AsideSectionItem, {
                  icon: _unref(currentSection) ? _unref(currentSection).icon : null,
                  title: _unref(currentSection) ? _unref(currentSection).descr : '404',
                  chevron: true,
                  description: _unref(__)('Выбрать другую услугу'),
                  class: _normalizeClass([_unref(displayed) ? 'list-displayed' : '']),
                  onClick: toggleDisplayed
                }, null, 8, ["icon", "title", "description", "class"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(['aside-usluga-dropdown-list', !_unref(displayed) ? 'hidden' : '']),
          style: _normalizeStyle({maxHeight: (_unref(sectionList).length * 100)+'px'})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sectionList), (section) => {
            return (_openBlock(), _createBlock(AsideSectionItem, {
              key: section.routeName,
              icon: section.icon,
              title: section.descr,
              onClick: ($event: any) => (_unref(select)(section))
            }, null, 8, ["icon", "title", "onClick"]))
          }), 128))
        ], 6)
      ])
    ]),
    _: 1
  }))
}
}

})