import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-filter-name"
}
const _hoisted_2 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_3 = {
  key: 0,
  class: "my-filter-value-buttonset"
}

import {computed} from 'vue';
import MyFilterOperationSelect from "./MyFilterOperationSelect.vue";
import MyFilterValues from "./MyFilterValues.vue";
import {IFilter, IFiltersData, IOperation} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import ArrayHelper from "~/ts/library/ArrayHelper";
import {FILTER_OPERATION_EQUAL} from "~/cabinet/vue/interface/filter/Operations";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";


const __default__ = {
    name: "MyFilterRow"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    canDeleteFilter: { type: Boolean },
    filter: {},
    filtersData: {},
    operation: {},
    selectedOperations: {},
    showAddOperationButton: { type: Boolean },
    showFilterDescr: { type: Boolean, default: true }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["operations-for-add", "operation-changed", "delete"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<any[]>(__props, "modelValue");

let showDeleteButton = computed(() => {
    return !hasVisibleFilters.value && props.canDeleteFilter;
});

let hasVisibleFilters = computed(() => {
    return MyFilterHelper.hasVisibleFilters(props.filter, props.operation);
});

function operationById(operationId: string): IOperation | null {
    return MyFilterHelper.getOperations(props.filtersData)[operationId];
}

let needDisplayOperations = computed(() => {
    let result = true;
    let operations = props.filter.allowedOperations;
    if (operations.length == 1 && operations[0] == FILTER_OPERATION_EQUAL) {
        result = false;
    }
    return result;
});


let notSelectedOperations = computed(() => {
    let result: string[] = [];
    let selected = props.selectedOperations;
    for (let i in props.filter.allowedOperations) {
        let operationId = props.filter.allowedOperations[i];
        if (selected.indexOf(operationId) == -1) {
            result.push(operationId);
        }
    }

    return result;
});

function getUsedOperationGroups(skipOperationId?: string) {
    let usedGroups = [];
    let unavailableOperations = props.selectedOperations;

    for (let i = 0; i < unavailableOperations.length; i++) {
        let operationId = unavailableOperations[i];
        if (operationId != skipOperationId) {
            let operation = operationById(operationId);
            for (let i = 0; i < operation.groups.length; i++) {
                let groupId = operation.groups[i];
                if (usedGroups.indexOf(groupId) == -1) {
                    usedGroups.push(groupId);
                }
            }
        }
    }
    return usedGroups;
}

function exludedOperationGroups(skipOperationId?: string) {
    let excludedGroups = [];
    let unavailableOperations = props.selectedOperations;

    for (let i = 0; i < unavailableOperations.length; i++) {
        let operationId = unavailableOperations[i];
        if (operationId != skipOperationId) {
            let operation = operationById(operationId);
            if (operation) {
                for (let i = 0; i < operation.excludedGroups.length; i++) {
                    let excludedGroupId = operation.excludedGroups[i];
                    if (excludedGroups.indexOf(excludedGroupId) == -1) {
                        excludedGroups.push(excludedGroupId);
                    }
                }
            }
        }
    }
    return excludedGroups;
}

function operationsAvailableFor(excludeOperationId?: string) {
    let excludedOperationGroups = exludedOperationGroups(excludeOperationId);
    let usedOperationGroups = getUsedOperationGroups(excludeOperationId);
    let result = [];
    let availableOperations = notSelectedOperations.value;

    for (let i = 0; i < availableOperations.length; i++) {
        let operationId = availableOperations[i];
        let operation = operationById(operationId);
        if (operation) {
            if (!ArrayHelper.intersect(operation.groups, excludedOperationGroups).length) {
                if (!ArrayHelper.intersect(operation.excludedGroups, usedOperationGroups).length) {
                    result.push(operationId);
                }
            }
        }
    }
    return result;
}

let operationsAvailableForAdd = computed(() => {
    let result = operationsAvailableFor();
    emits("operations-for-add", result);
    return result;
});

let operationsAvailableForChange = computed(() => {
    return operationsAvailableFor(props.operation.id);
});

let group = computed(() => props.filtersData.groups[props.filter.group]);


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showFilterDescr)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.filter.descrForPreview == _ctx.filter.descr && _ctx.filter.isShowGroupDescr && _unref(group))
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(group).descrArray.join(" / ")) + " / ", 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.filter.descrForPreview), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: _unref(needDisplayOperations) ? 24 : 0,
          md: _unref(needDisplayOperations) ? (_unref(hasVisibleFilters) ? 7 : 24) : 0,
          class: _normalizeClass([
                    'my-filter-operation-select-col',
                    _unref(showDeleteButton) ? 'can-delete' : '',
                    _unref(hasVisibleFilters) ? '' : 'p-0'
                ])
        }, {
          default: _withCtx(() => [
            _createVNode(MyFilterOperationSelect, {
              "model-value": _ctx.operation.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('operation-changed', $event))),
              filter: _ctx.filter,
              "operations-available-for-change": _unref(operationsAvailableForChange),
              "filters-data": _ctx.filtersData,
              "for-add": _unref(operationsAvailableForAdd)
            }, null, 8, ["model-value", "filter", "operations-available-for-change", "filters-data", "for-add"]),
            (_unref(showDeleteButton))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(MyIconButton, {
                    delete: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('delete')))
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["span", "md", "class"]),
        _withDirectives(_createVNode(_component_el_col, {
          md: _unref(needDisplayOperations) ? 17 : 24
        }, {
          default: _withCtx(() => [
            _createVNode(MyFilterValues, {
              "filters-data": _ctx.filtersData,
              modelValue: _unref(model),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
              filter: _ctx.filter,
              operation: _ctx.operation,
              "can-delete-filter": _ctx.canDeleteFilter,
              onDelete: _cache[3] || (_cache[3] = ($event: any) => (_unref(emits)('delete'))),
              "show-add-operation-button": _ctx.showAddOperationButton
            }, null, 8, ["filters-data", "modelValue", "filter", "operation", "can-delete-filter", "show-add-operation-button"])
          ]),
          _: 1
        }, 8, ["md"]), [
          [_vShow, _unref(hasVisibleFilters)]
        ])
      ]),
      _: 1
    })
  ]))
}
}

})