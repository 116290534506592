import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {useSenderStore} from "~/cabinet/ts/store/Sender";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import AbstractSourceCardSection from "~/cabinet/vue/client/sender/source/AbstractSourceCardSection.vue";
import {SenderSourceForListInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {PHONEBOOK_COLUMN_NAME_PHONE} from "~/cabinet/vue/client/phonebook/contacts/constants";
import {UnwrapNestedRefs} from "vue";
import {RouteRecordRaw} from "vue-router";
import {RawComponent} from "~/core-ui/ts/useRawComponent";

//export type VueComponent = AsyncVueComponent | Component;

export default class SenderUsluga extends Usluga {
    contentFormComponent: RawComponent;
    hideContentFormWhenHasNoSources: boolean = false;
    isRegionalTimeAvailable: boolean;

    recipientIdPhonebookField: string = PHONEBOOK_COLUMN_NAME_PHONE;

    sourceRouting?: {
        getRoutes: () => RouteRecordRaw;
        getMenu: () => MenuItem;
    };

    rules?: RawComponent;

    source?: {
        editable?: boolean;
        cardSection?: RawComponent | InstanceType<typeof AbstractSourceCardSection>;
        cardSectionDisplayCondition?: (source: SenderSourceForListInterface) => boolean,
        cardLicenseForm?: RawComponent | InstanceType<typeof AbstractSourceCardSection>;
        cardHeader?: RawComponent | InstanceType<typeof AbstractSourceCardSection>;
        listPrependInfo?: RawComponent;
        routing?: {
            getRoutes: () => RouteRecordRaw;
            getMenu: () => MenuItem;
        },
        formComponent?: RawComponent,
        formModelGetter?: (model: any) => any,
        title: string,
        dealersApprove?: boolean,
        dealersApproveComponent?: RawComponent
    };


    additionalConfigRoutes?: {
        menu: MenuItem[],
        routes: RouteRecordRaw[]
    };

    report: {
        recipientColumnWidth: number;
        recipientComponent?: RawComponent;
        sourceComponent?: RawComponent;
        contentComponent?: RawComponent;
        tasksReportExpandedInfo?: RawComponent;
        opsosCorrectAvailable?: boolean;
    };
    create: {
        topContentComponent?: RawComponent,
        hideReceiversByContentForm?: boolean,
        otherRecipientPlaceholder?: string;
        hideOtherRecipient?: boolean;

        confirm: {
            taskPreviewComponent: RawComponent;
            taskInfoComponent?: RawComponent;
            lg: number;
            md: number;
            priceDescr?: string
        },
    };

    store?: UnwrapNestedRefs<ReturnType<typeof useSenderStore>>

    get otherRecipientPlaceholder(): string {
        let result = this.create.otherRecipientPlaceholder;
        if (!result) {
            result = "Введите телефоны через Enter";
        }
        return result;
    }
}