import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import {computed, ref} from 'vue';
import {Operator} from "~/cabinet/ts/data/online/Operator";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import CoreUiAvatar from "~/core-ui/vue/chat/CoreUiAvatar.vue";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";



const __default__ = {
    name: "OperatorCabinetAvatar"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    operator: {},
    avatarUrl: {},
    size: { default: 30 }
  },
  emits: ["save"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let avatar = computed(() => {
    return props.operator ? props.operator.avatar : props.avatarUrl;
});


let dialogVisible = ref(false);

const editDialogComponent = useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/config/users/edit/EditUserDialog.vue'));

function openEditDialog() {
    if (props.operator && canConfig.value) {
        dialogVisible.value = true;
    }
}

let status = computed(() => {
    let operator = props.operator;
    if (operator) {
        let status = operator.status;
        if (status != 0) {
            if (operator.isUnavailableBecauseOfLicenseLimits) {
                return "limit";
            } else if (!operator.isWorkingNow) {
                return "weekend";
            }
        } else if (operator.hasActiveTokens) {
            return "away";
        }
        return status;
    }
    return null;
});

let canConfig = computed(() => {
    return AccountStore.access.value.checkAccessForOnlineConfig();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    onClick: openEditDialog,
    class: "operator-cabinet-avatar"
  }, [
    _createVNode(CoreUiAvatar, {
      "status-color-class": _unref(status) ? `status-${_unref(status)}` : undefined,
      src: _unref(avatar),
      size: `${_ctx.size}px`,
      "badge-tooltip": "Количество активных диалогов (диалог не завершён и открыт в приложении)"
    }, _createSlots({ _: 2 }, [
      (_ctx.operator && _ctx.operator.dialogCount)
        ? {
            name: "badge",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.operator.dialogCount), 1)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["status-color-class", "src", "size"]),
    (_unref(dialogVisible))
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(editDialogComponent)), {
          key: 0,
          operator: "",
          "user-id": _ctx.operator.id,
          visible: _unref(dialogVisible),
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(dialogVisible) ? (dialogVisible).value = $event : dialogVisible = $event)),
          onSave: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('save')))
        }, null, 40, ["user-id", "visible"]))
      : _createCommentVNode("", true)
  ]))
}
}

})