import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {computed, watch} from "vue";
import MyFilterValueComponent from "~/cabinet/vue/interface/filter/MyFilterValueComponent";
import {IFiltersData, IOperation} from "~/cabinet/vue/interface/filter/Interfaces";
import CombinedFilterValue from "~/cabinet/vue/interface/filter/types/CombinedFilterValue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "MyFilterCombined"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    component: {},
    operation: {},
    filtersData: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<CombinedFilterValue>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({}));

let parts = computed(() => {
    return props.component.filter.combined.filters;
});

let visibleParts = computed(() => {
    let result = [];
    for (let filter of parts.value) {
        if (!filter.parentFilterId || isValueFilled(filter.parentFilterId)) {
            result.push(filter);
        }
    }
    return result;
});

function isValueFilled(filterId: string) {
    return (model.value as any)[filterId] != null;
}

watch(model, () => {
    for (let filter of parts.value) {
        if (filter.parentFilterId != null) {
            if (!isValueFilled(filter.parentFilterId)) {
                setPartValue(filter.id, null);
            }
        }
    }
}, {deep: true});

function setPartValue(filterId: string, value?: any) {
    if (value == null) {
        delete model.value[filterId];
    } else {
        model.value[filterId] = value;
    }
}

function onInput(filterId: string, value: any) {
    setPartValue(filterId, value);
    for (let part of parts.value) {
        if (part.parentFilterId == filterId) {
            setPartValue(part.id, null);
        }
    }

}

return (_ctx: any,_cache: any) => {
  const _component_my_filter_values_item = _resolveComponent("my-filter-values-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_ctx.component.filter.combined)
    ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleParts), (filter) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              key: filter.id,
              md: 24 / _unref(visibleParts).length
            }, {
              default: _withCtx(() => [
                _createVNode(_component_my_filter_values_item, {
                  "model-value": _unref(model)[filter.id],
                  "onUpdate:modelValue": ($event: any) => (onInput(filter.id, $event)),
                  "can-add": false,
                  "can-delete": false,
                  operation: _ctx.operation,
                  filter: filter,
                  "combined-filter-value": _unref(model),
                  "filters-data": _ctx.filtersData
                }, null, 8, ["model-value", "onUpdate:modelValue", "operation", "filter", "combined-filter-value", "filters-data"])
              ]),
              _: 2
            }, 1032, ["md"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})