import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "lh-primary"
}
const _hoisted_2 = {
  key: 1,
  class: "w-100 position-relative"
}

import {
    ICheckboxField,
    IFieldWithOptions,
    IOption,
    IOptionGroup
} from "~/cabinet/vue/interface/form/elements/Interfaces";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import SelectElementOption from "~/cabinet/vue/interface/form/elements/SelectElementOption.vue";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, inject, onMounted, ref, watch} from "vue";
import {AbstractApiResult} from "~/core-ui/ts/request/AbstractApiResult";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {MyFormGetValuesInjection} from "~/cabinet/vue/interface/form/elements/FormHelper";
import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";


const __default__ = {
    name: "SelectElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<ICheckboxField | IFieldWithOptions>(),
    multiple: {
        type: Boolean
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

let lazyLoadResult = ref<AbstractApiResult<{
    options: IOption[],
    optgroups: IOptionGroup[]
}, any>>();

onMounted(() => {
    if (model.value == null && props.autoEmitValueWhenEmpty) {
        let value = null;
        if (props.multiple) {
            value = [];
        } else {
            if (props.field.options && props.field.options.length) {
                value = props.field.options[0].value;
            }
            if (props.field.optgroup) {
                for (let optgroup of props.field.optgroup) {
                    if (optgroup.options && optgroup.options.length) {
                        value = optgroup.options[0].value;
                        break;
                    }
                }
            }
        }

        model.value = value;
    }
});

function isEmpty(value: any) {
    return !value || (Array.isArray(value) && !value.length);
}

function onInput(value: any) {
    if (!props.autoEmitValueWhenEmpty) {
        if (props.multiple) {
            if (isEmpty(value) && isEmpty(model.value)) {
                return;
            }
        }
    }
    model.value = value/*, isEmpty(value), isEmpty(props.value)*/;
}

let allOptions = computed(() => {
    let result = [
        ...options.value,
    ];
    if (props.field.optgroup) {
        for (let optgroup of props.field.optgroup) {
            result.push(...optgroup.options);
        }
    }
    return result;
});

function getOptionByValue(value: any): IOption {
    return allOptions.value.find(option => option.value === value);
}

let selectedOption = computed(() => {
    let value = model.value;
    if (value) {
        return getOptionByValue(value);
    }
    return null;
});

let valueAsArray = computed(() => {
    let value = model.value;
    return Array.isArray(value) ? value : [value];
});

function getValueDescr(value: string) {
    let option = getOptionByValue(value);
    let result: any;
    if (option) {
        result = option.descr ? option.descr : option.value;
    } else {
        result = value;
    }
    return result;
}

let selectedOptionWithIcon = computed(() => {
    if (selectedOption.value && selectedOption.value.icon && !props.disallowOptionIcon) {
        return selectedOption.value;
    }
    return null;
});

let options = computed(() => {
    if (props.field.lazyLoad) {
        return lazyLoadResult.value?.data.options ?? [];
    } else {
        return props.field.options;
    }
});

let optgroups = computed(() => {
    if (props.field.lazyLoad) {
        return lazyLoadResult.value?.data.optgroups ?? [];
    } else {
        return props.field.optgroup;
    }
});

let getFormValues = inject(MyFormGetValuesInjection, () => ({} as any));

async function lazyLoadMake() {
    let lazyLoad = props.field.lazyLoad;
    if (lazyLoad) {
        lazyLoadResult.value = await LcabApiRequest.fetch({
            url: lazyLoad.url,
            p: {
                lazyLoadParams: lazyLoad.params,
                formValues: getFormValues()
            },
            silent: true
        });
        if (!hasOptionValue(model.value)) {
            model.value = allOptions.value.find(option => option.default)?.value;
        }
    }
}

let dependsOnValue = computed(() => {
    if (props.field.displayCondition) {
        return getFormValues()[props.field.displayCondition.fieldName];
    }
    return null;
});

function hasOptionValue(value: any) {
    let search = [...options.value];
    for (let optgroup of optgroups.value) {
        search.push(...optgroup.options);
    }
    return search.find(option => option.value === value);
}

let instanceId = Random.uid();

watch(dependsOnValue, async () => {
    try {
        await Delay.make(500, `${instanceId}-lazy-load`);
        lazyLoadMake();
    } catch (e) {
    }
});

lazyLoadMake();

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_ctx.readonly && _ctx.textReadonly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(model) != null)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(valueAsArray), (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (__props.multiple)
                  ? (_openBlock(), _createBlock(MyFilterPreviewTagTemplate, {
                      key: 0,
                      class: "mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(getValueDescr(item)), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(getValueDescr(item)), 1)
                    ], 64))
              ], 64))
            }), 256))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_unref(selectedOptionWithIcon))
          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
              key: 0,
              icon: _unref(selectedOptionWithIcon).icon,
              "icon-style": _unref(selectedOptionWithIcon).iconStyle,
              class: "el-select-option-icon",
              style: _normalizeStyle({
                color: _unref(selectedOptionWithIcon).iconColor ? _unref(selectedOptionWithIcon).iconColor : null
            })
            }, null, 8, ["icon", "icon-style", "style"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_select, _mergeProps(_ctx.$attrs, {
          "model-value": _unref(model),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onInput($event))),
          multiple: __props.multiple,
          placeholder: _ctx.field.placeholder,
          class: {
                'w-100': true,
                'with-icon': !!_unref(selectedOptionWithIcon)
            },
          disabled: _ctx.readonly,
          clearable: _ctx.clearable,
          filterable: ""
        }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optgroups), (optgroup) => {
              return (_openBlock(), _createBlock(_component_el_option_group, {
                key: optgroup.id,
                label: optgroup.descr
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optgroup.options, (option, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      value: option.value,
                      label: option.descr,
                      disabled: !!option.disabled
                    }, {
                      default: _withCtx(() => [
                        _createVNode(SelectElementOption, { option: option }, null, 8, ["option"])
                      ]),
                      _: 2
                    }, 1032, ["value", "label", "disabled"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (option, index) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: index,
                value: option.value,
                label: option.descr,
                disabled: !!option.disabled
              }, {
                default: _withCtx(() => [
                  _createVNode(SelectElementOption, { option: option }, null, 8, ["option"])
                ]),
                _: 2
              }, 1032, ["value", "label", "disabled"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["model-value", "multiple", "placeholder", "class", "disabled", "clearable"]),
        (_unref(props).field.lazyLoad && _unref(lazyLoadResult) && !_unref(lazyLoadResult).isSuccess)
          ? (_openBlock(), _createBlock(HelpBlock, {
              key: 1,
              danger: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(lazyLoadResult).descr), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
}
}

})