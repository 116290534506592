import Dictionary from "~/ts/library/Dictionary";
import {computed, ExtractPropTypes, inject, PropType} from "vue";
import Random from "~/ts/library/Random";
import {
    HtmlConstructorContentInject,
    HtmlConstructorEditorInject,
    ParentStructureInject
} from "~/cabinet/vue/interface/htmlConstructor/Constants";

export function useBlockDisplayProps<Styles = Dictionary<any>>() {
    return {
        editable: {
            type: Boolean
        },
        styles: {
            type: Object as PropType<Styles>,
            required: true
        }
    }
}

export function useBlockDisplay(props: Readonly<ExtractPropTypes<ReturnType<typeof useBlockDisplayProps>>>) {
    let htmlConstructorEditor = inject(HtmlConstructorEditorInject);
    let htmlConstructorContent = inject(HtmlConstructorContentInject);
    let parentStructure = inject(ParentStructureInject, null);

    let instanceId = "element_" + Random.uid();

    let styleId = instanceId + "_style";

    let parentStructureStyles = computed(() => {
        return parentStructure?.structureStyles;
    })

    return {
        htmlConstructorEditor,
        htmlConstructorContent,
        parentStructure,
        instanceId,
        styleId,
        parentStructureStyles
    }
}