import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import OperatorAvatarConfig from "~/cabinet/vue/client/online/config/operators/OperatorAvatarConfig.vue";



const __default__ = {
    name: "OperatorLoginInfo"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {


let _modelValue = _useModel<{
    fio: string,
    avatar: string
}>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    avatar: null,
    fio: null
}))



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "left",
    "label-width": "150px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Имя оператора" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _unref(model).fio,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).fio) = $event)),
            placeholder: "Например, Василий"
          }, null, 8, ["modelValue"]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-1 text-muted" }, "Ваше имя будет отображаться в чате и соцсетях", -1))
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Аватар" }, {
        default: _withCtx(() => [
          _createVNode(OperatorAvatarConfig, {
            modelValue: _unref(model).avatar,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).avatar) = $event)),
            "is-new": !!_unref(model).avatar
          }, null, 8, ["modelValue", "is-new"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})