import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    OnlineChatRequestRateActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";


const __default__ = {
    name: "OnlineChatRequestRateForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatRequestRateActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatRequestRateActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    customAnswerVariants: []
}));


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _unref(model),
    "label-position": "left",
    "label-width": "200px",
    class: "mt-4"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Свои варианты ответа" }, {
        default: _withCtx(() => [
          _createVNode(MyInputList, {
            modelValue: _unref(model).customAnswerVariants,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).customAnswerVariants) = $event)),
            "remove-last-available": "",
            sortable: ""
          }, {
            default: _withCtx(({index}) => [
              _createVNode(TextInputWithVariables, {
                modelValue: _unref(model).customAnswerVariants[index],
                "onUpdate:modelValue": ($event: any) => ((_unref(model).customAnswerVariants[index]) = $event),
                multiline: false,
                class: "lh-0"
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})