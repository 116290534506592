import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {ISecondsFilterValue} from "~/cabinet/vue/interface/filter/Interfaces";
import MyTimeSelector from "~/cabinet/vue/interface/form/MyTimeSelector.vue";
import IMyTimeSelectorValue from "~/cabinet/vue/interface/form/IMyTimeSelectorValue";
import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {computed, PropType, ref, watch} from "vue";


const __default__ = {
    name: "MyFilterSeconds"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let model = _useModel<ISecondsFilterValue>(__props, "modelValue");

let timeSelectorValue = ref<IMyTimeSelectorValue>();

let timeSelectorValueCompute = computed(() => {
    let result: IMyTimeSelectorValue = {
        value: 1,
        multiply: 1
    };
    if (model.value) {
        result.value = model.value.seconds;
        result.multiply = model.value.multiply
    }
    if (JSON.stringify(result) != JSON.stringify(timeSelectorValue.value)) {
        timeSelectorValue.value = result;
    }
    return result;
});

watch(timeSelectorValue, () => {
    onInput(timeSelectorValue.value);
}, {deep: true});

function processValue(value: IMyTimeSelectorValue): ISecondsFilterValue {
    return {
        seconds: value.value,
        multiply: value.multiply
    };
}

function onInput(value: IMyTimeSelectorValue) {
    model.value = processValue(value);
}

return (_ctx: any,_cache: any) => {
  return (_unref(timeSelectorValueCompute))
    ? (_openBlock(), _createBlock(MyTimeSelector, {
        key: 0,
        "full-width": "",
        "model-value": _unref(timeSelectorValue),
        "onUpdate:modelValue": onInput
      }, null, 8, ["model-value"]))
    : _createCommentVNode("", true)
}
}

})