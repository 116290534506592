import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {MassActionsSelectionModelInterface} from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";


const __default__ = {
    name: "MassActionsRowCheckbox"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    selectionModel: {},
    selectionLabel: {}
  },
  setup(__props: any) {

let props = __props;

function onUpdateModelValue(value: boolean) {
    if (!props.selectionModel.all) {
        toggleArrayElement(props.selectionModel.list, props.selectionLabel, value);
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createBlock(_component_el_checkbox, {
    class: "my-table-selection hide-label h-auto",
    value: _ctx.selectionLabel,
    "model-value": _ctx.selectionModel.all ? true : _ctx.selectionModel.list.includes(_ctx.selectionLabel),
    "onUpdate:modelValue": onUpdateModelValue,
    disabled: _ctx.selectionModel.all ? true : null
  }, null, 8, ["value", "model-value", "disabled"]))
}
}

})