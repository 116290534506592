import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";
import {computed} from "vue";


export const tableMaxHeight = computed(() => {
    return isMobileWindowSize.value ? null : 500;
});

export const expandColumnWidth = computed(() => {
    return isMobileWindowSize.value ? "18px" : "48px";
});

export const access = computed(() => AccountStore.access.value);
