import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"opacity":"0","pointer-events":"none","position":"absolute"} }

import {Settings} from "~/node_modules/@types/tinymce";
import ContentVariables from "~/cabinet/vue/client/sender/send/ContentVariables.vue";
import {Editor as EditorClass} from "tinymce";
import TextInputWithVariablesHelper from "~/cabinet/vue/interface/tinymce/TextInputWithVariablesHelper";
import {useInputWithVariables, useInputWithVariablesProps} from "~/cabinet/vue/interface/tinymce/useInputWithVariables";
import {computed, inject, onActivated, onDeactivated, ref} from "vue";
import {
    ContentVariablesComponentExposeInterface
} from "~/cabinet/vue/client/sender/send/ContentVariablesComponentExposeInterface";
import {FileManagerRootInjectionKey} from "~/cabinet/vue/interface/form/FileManagerRootInjection";


import TinyMceEditor from '@tinymce/tinymce-vue';


const __default__ = {
    name: "HtmlInputWithVariables",
    components: {
        TinyMceEditor: TinyMceEditor
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useInputWithVariablesProps(),
    inline: {
        type: Boolean,
        default: true
    },
    height: {type: Number},
    oldChatCss: {type: Boolean},
    likeTextarea: {type: Boolean},
    verifyHtml: {type: Boolean, default: true},
    allowStyleTag: {type: Boolean, default: false},
    editorClassList: {type: Array, default: () => [] as any[]},
    showPageBreakButton: {type: Boolean}
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let contentVariables = ref<ContentVariablesComponentExposeInterface>();
let editor = ref<any>();
let variableExample = ref<HTMLSpanElement>();
let active = ref(true);


let {dataLabelAttribute, commonSettings, variableGroups, paste, getSettings, helper} = useInputWithVariables(props, {
    setup: (editor: EditorClass) => {
        if (!props.inline) {
            let variableStyle = window.getComputedStyle(variableExample.value).cssText;

            editor.settings.content_style = `
                    .noneditable-variable { ${variableStyle}; width: auto; height: auto; line-height: 2; font-size:0; }
                    .noneditable-variable:before {
                        content: attr(${dataLabelAttribute.value});
                        font-size: 12px;
                        font-weight: bold;
                        background: #f0f2f5 !important;
                        color: #6e7c94 !important;
                        border-radius: 5px;
                        border-top: 1px solid #e6e8ed;
                        padding: 0 1px;
                        display: inline-block;
                        line-height: 1.5;
                    }
                `;
        }
    },
    getContentVariablesComponent: () => contentVariables.value,
    getEditor: () => editor.value?.getEditor(),
    getPlugins: () => ([
        'lists',
        'responsivefilemanager',
        'image',
        'table',
        'link',
        'code',
        'pagebreak'
    ]),
    getSettings: () => {
        let filemanagerRootInjection = inject(FileManagerRootInjectionKey, null);
        let result: Settings = {
            ...commonSettings.value,
            verify_html: props.verifyHtml,
            custom_elements: props.allowStyleTag ? "style" : "",
            extended_valid_elements: (props.allowStyleTag ? ",style[type],style" : ""),
            inline: props.inline,
            skin: "oxide-dark",
            external_plugins: {
                filemanager: "/filemanager/plugin.min.js"
            },
            external_filemanager_path: `/filemanager/`,
            filemanager_root: filemanagerRootInjection?.value,
            filemanager_title: "Файловый менеджер",
            resize: true,
            statusbar: true,
            link_list: props.contentVariables.map(item => ({
                title: item.descr,
                value: TextInputWithVariablesHelper.getAnchorByVariable(item.id)
            }))
        } as Settings;
        if (props.oldChatCss) {
            if (!result.content_css) {
                result.content_css = [];
            }
            (result.content_css as string[]).push('/cabinet/assets/css/cabinet/online/old-chat-css.css');

        }
        return result;
    },
    getToolbar: () => {
        let pageBreak = props.showPageBreakButton ? ' | pagebreak' : '';
        return [
            "undo redo | bold italic underline strikethrough fontsizeselect | h1 h2 h3 h4 | backcolor forecolor | removeformat ",
            `alignleft aligncenter alignright alignjustify | link unlink image | bullist numlist | table | code${pageBreak} | PasteContentVariable`
        ];
    },
    isHtml: () => true,
    isBbCode: () => false
});


onDeactivated(() => {
    active.value = false;
});

onActivated(() => {
    active.value = true;
});

let isHtml = computed(() => {
    return true;
});

let style = computed(() => {
    let result: any = {};
    if (props.height) {
        result.height = `${props.height}px`;
    }
    return result;
});

let variableClassName = computed(() => {
    return TextInputWithVariablesHelper.className;
});

let plugins = computed(() => {
    return [
        'lists',
        'responsivefilemanager',
        'image',
        'table',
        'link',
        'code',
        'pagebreak'
    ];
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
        zIndex0: true,
        'el-textarea': __props.likeTextarea
    })
  }, [
    _createVNode(ContentVariables, {
      hidden: "",
      ref_key: "contentVariables",
      ref: contentVariables,
      "variable-groups": _unref(variableGroups),
      onPaste: _unref(paste)
    }, null, 8, ["variable-groups", "onPaste"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        ref_key: "variableExample",
        ref: variableExample,
        class: _normalizeClass(_unref(variableClassName))
      }, null, 2)
    ]),
    (_unref(active))
      ? (_openBlock(), _createBlock(_unref(TinyMceEditor), {
          "model-value": _ctx.modelValue,
          style: _normalizeStyle(_unref(style)),
          init: _unref(getSettings)(),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('update:modelValue', $event))),
          key: _unref(helper).getId(),
          disabled: _ctx.disabled,
          ref_key: "editor",
          ref: editor,
          class: _normalizeClass(['tinymce-for-input-with-variables', __props.inline ? 'inline' : null, __props.likeTextarea ? 'el-textarea__inner' : false, ...__props.editorClassList]),
          spellcheck: "true"
        }, null, 8, ["model-value", "style", "init", "disabled", "class"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})