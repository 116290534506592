import {IRouteMeta, RouteAccess} from "~/core-ui/ts/router/Interface";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {RouteLocationNormalizedGeneric, RouteRecordRaw} from "vue-router";

export default class RouteAccessChecker {

    public static hasAccess(route: RouteLocationNormalizedGeneric): boolean {
        if (route.matched) {
            for (let item of route.matched) {
                if (!RouteAccessChecker.checkAccessByMeta(item.meta)) {
                    return false;
                }
            }
        }
        return true;
    }

    public static checkAccessByMeta(meta?: IRouteMeta) {
        let result = !meta || !meta.access || RouteAccessChecker.checkAccess(meta.access);
        if (result) {
            if (meta)
                if (meta && meta.redirectToChildren != null && meta.redirectToChildren.length) {
                    if (RouteAccessChecker.getRouteNameForRedirect(meta.redirectToChildren) == null) {
                        result = false;
                    }
                }
        }
        return result;
    }

    public static checkAccess(accessList: RouteAccess): boolean {
        if (typeof accessList == "function") {
            return accessList();
        }
        for (let accessId of accessList) {
            if (typeof accessId == "string") {
                if (!AccountStore.user.value?.access[accessId]) {
                    return false;
                }
            } else if (typeof accessId == "function") {
                if (!accessId()) {
                    return false;
                }
            }
        }
        return true;
    }

    public static getRouteNameForRedirect(routes: RouteRecordRaw[]): string | null {
        let name: string = null;
        for (let route of routes) {
            let meta: IRouteMeta = route.meta;
            if (RouteAccessChecker.checkAccessByMeta(meta)) {
                if (meta && meta.redirectToChildren) {
                    name = RouteAccessChecker.getRouteNameForRedirect(meta.redirectToChildren);
                } else {
                    name = route.name as string;
                }
                if (name) {
                    break;
                }
            }
        }
        return name;
    }
}