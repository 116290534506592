import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "pr-2" }
const _hoisted_3 = { class: "flex-fill" }
const _hoisted_4 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_5 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_6 = {
  key: 1,
  class: "text-muted mt-2"
}

import {
    DataProviderEntityFieldInterface, DataProviderEntityFieldModelInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionParamInterface";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {getSclon} from "~/ts/vuePlugins/NumbersPlugin";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntityField"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    field: {},
    model: {},
    contentVariables: {},
    showUpdateAllowed: { type: Boolean }
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tooltip, {
            disabled: !_ctx.field.isRequired,
            content: "Нельзя удалить обязательное поле"
          }, {
            default: _withCtx(() => [
              _createVNode(MyIconButton, {
                disabled: _ctx.field.isRequired,
                delete: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('remove')))
              }, null, 8, ["disabled"])
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.field.descr), 1)
      ])
    ]),
    _createElementVNode("td", null, [
      _createVNode(MyInputList, {
        modelValue: _unref(props).model.values,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(props).model.values) = $event)),
        "max-items": _ctx.field.isMultiple ? undefined : 1,
        "auto-append-new-input": false,
        "remove-last-available": false,
        "show-sortable-handler": false,
        sortable: "",
        class: "input-list"
      }, {
        default: _withCtx(({index}) => [
          _createElementVNode("div", null, [
            _createVNode(_component_el_form, {
              "label-position": "left",
              "label-width": "0"
            }, {
              default: _withCtx(() => [
                _createVNode(MyForm, {
                  form: _ctx.field.form,
                  "show-labels": false,
                  modelValue: _unref(props).model.values[index],
                  "onUpdate:modelValue": ($event: any) => ((_unref(props).model.values[index]) = $event),
                  "content-variables": _unref(props).contentVariables
                }, null, 8, ["form", "modelValue", "onUpdate:modelValue", "content-variables"])
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "max-items"]),
      (_ctx.showUpdateAllowed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.field.isImmutable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Значение передается только при создании. Обновить нельзя. "))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _unref(props).model.updateAllowed,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(props).model.updateAllowed) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass({
                                'text-muted': !_unref(props).model.updateAllowed
                            })
                      }, " Обновлять при пересохранении ", 2)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  (_ctx.field.isFilterable && _unref(props).model.updateAllowed)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: "Передавать новое значение только если поле в CRM сейчас не заполнено"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_checkbox, {
                            modelValue: _unref(props).model.updateOnlyEmpty,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(props).model.updateOnlyEmpty) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                class: _normalizeClass({
                                    'text-muted': !_unref(props).model.updateOnlyEmpty
                                })
                              }, " Только если пустое ", 2)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
          ]))
        : _createCommentVNode("", true),
      (_unref(props).field.maxLength > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createTextVNode(" Максимальная длина поля: " + _toDisplayString(_unref(props).field.maxLength) + " " + _toDisplayString(_unref(getSclon)(_unref(props).field.maxLength, "символ", "символа", "символов")) + ". ", 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _cache[5] || (_cache[5] = _createTextVNode(" Лишние символы будут обрезаны. "))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})