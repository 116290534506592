import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]

import {ref} from 'vue';
import FileManager from "~/cabinet/vue/interface/form/FileManager.vue";
import IHtmlConstructorImageLoadEventPayload
    from "~/cabinet/vue/interface/htmlConstructor/blocks/common/IHtmlConstructorImageLoadEventPayload";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "HtmlConstructorImage"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    removable: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["size"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let model = _useModel<string>(__props, "modelValue");

let img = ref<HTMLImageElement>();

function onImgLoad() {
    if (img.value) {
        let payload: IHtmlConstructorImageLoadEventPayload = {
            width: img.value.naturalWidth,
            height: img.value.naturalHeight
        };
        emits('size', payload);
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "Изображение" }, {
    default: _withCtx(() => [
      (_unref(model))
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            ref_key: "img",
            ref: img,
            src: _unref(model),
            class: "img",
            onLoad: onImgLoad
          }, null, 40, _hoisted_1))
        : _createCommentVNode("", true),
      _createVNode(FileManager, {
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        class: "mt-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            plain: "",
            type: "primary"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Выбрать ")
            ])),
            _: 1
          }),
          (_unref(model) && _ctx.removable)
            ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                link: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(FontAwesomeIcon, { icon: "times" })
                ]),
                _: 1
              })), [
                [
                  _directive_on_native,
                  () => {_isRef(model) //@ts-ignore
 ? model.value = null : model = null;},
                  "click",
                  { stop: true }
                ]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})