import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import {
    ToggleTagActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import TagSelector from "~/cabinet/vue/interface/form/tags/TagSelector.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "ToggleTagActionBlockForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<ToggleTagActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props

let model = _useModel<getActionDescriptionModelType<ToggleTagActionDescription>>(__props, "modelValue");

let frontendParams = props.actionDescription.frontendParams;


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Добавить перечисленные тэги" }, {
        default: _withCtx(() => [
          _createVNode(TagSelector, {
            "tag-type": _unref(frontendParams).tagTypeId,
            modelValue: _unref(model).tagsToAdd,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).tagsToAdd) = $event))
          }, null, 8, ["tag-type", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Удалить перечисленные тэги" }, {
        default: _withCtx(() => [
          _createVNode(TagSelector, {
            "tag-type": _unref(frontendParams).tagTypeId,
            modelValue: _unref(model).tagsToRemove,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).tagsToRemove) = $event))
          }, null, 8, ["tag-type", "modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})