import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "position-relative" }

import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import StructureBlockTypeHelper, {
    HTML_CONSTRUCTOR_STRUCTURE_MAX_COLUMNS
} from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureBlockTypeHelper";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "StructureForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props

let {} = useBlockForm(props);
let _modelValue = _useModel<IStructureBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let columnWidths = computed(() => {
    return StructureBlockTypeHelper.getColumnWidthsByValue(model.value.columns);
});

let columnsRadioButtons = computed(() => {
    let result = [];
    for (let i = 1; i <= HTML_CONSTRUCTOR_STRUCTURE_MAX_COLUMNS; i++) {
        result.push(i);
    }
    return result;
});

function onColumnsCountChange(count: number) {
    let width = 100 / count;
    let columnWidths = [];
    for (let i = 0; i < count; i++) {
        columnWidths.push(width);
    }
    model.value.columns = StructureBlockTypeHelper.getColumnsValue(columnWidths);
}

function onChangeSliderValue(index: number, newSliderValue: number) {
    const MIN_SIZE = 5;

    let _sliderValues = [...sliderValues.value];
    let previousValue = _sliderValues[index - 1];
    if (!previousValue) {
        previousValue = 0;
    }
    let minValue = previousValue + MIN_SIZE;

    let maxValue = _sliderValues[index + 1];
    if (!maxValue) {
        maxValue = 100;
    }
    maxValue -= MIN_SIZE;

    if (newSliderValue < minValue) {
        newSliderValue = minValue;
    }

    if (newSliderValue > maxValue) {
        newSliderValue = maxValue;
    }
    let _columnWidths = [...columnWidths.value];
    let currentWidth = _columnWidths[index];

    let newWidth: number;
    if (index > 0) {
        newWidth = newSliderValue - _sliderValues[index - 1];
    } else {
        newWidth = newSliderValue;
    }

    let delta = currentWidth - newWidth;

    _columnWidths[index] = newWidth;
    if (_columnWidths[index + 1] != null) {
        _columnWidths[index + 1] += delta;
    }
    model.value.columns = StructureBlockTypeHelper.getColumnsValue(_columnWidths);
}

let sliderValues = computed(() => {
    let sum = 0;
    let result = [];
    for (let i = 0; i < columnWidths.value.length - 1; i++) {
        sum += columnWidths.value[i];
        result.push(sum);
    }

    return result;
});

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Количество столбцов" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            "model-value": _unref(columnWidths).length,
            "onUpdate:modelValue": onColumnsCountChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columnsRadioButtons), (column) => {
                return (_openBlock(), _createBlock(_component_el_radio_button, {
                  key: column,
                  value: column
                }, null, 8, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["model-value"])
        ]),
        _: 1
      }),
      (_unref(sliderValues).length)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: `Ширина столбцов`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sliderValues), (value, index) => {
                  return (_openBlock(), _createBlock(_component_el_slider, {
                    class: _normalizeClass({
                        'position-absolute': index > 0,
                        'html-structure-column-slider': true
                    }),
                    key: index,
                    min: 1,
                    step: 1,
                    max: 100,
                    "model-value": value,
                    "show-tooltip": false,
                    "onUpdate:modelValue": ($event: any) => (onChangeSliderValue(index, $event))
                  }, null, 8, ["class", "model-value", "onUpdate:modelValue"]))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})