import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mt-2 text-muted font-size-mini"
}

import DateHelper from "~/ts/library/Date";
import {__} from "~/ts/library/Translate";
import {
    DateTimeSendValueInterface,
    SmoothSendValueInterface
} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import MyDateTimePicker from "~/core-ui/vue/ui/MyDateTimePicker.vue";


const __default__ = {
    name: "SenderSmooth"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    dateTimeSend: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<SmoothSendValueInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    enabled: 0,
    delay: 10,
    stop: (new DateHelper(new Date())).toMysqlFormat()
}));


let start = computed(() => {
    return props.dateTimeSend && props.dateTimeSend.enabled ? (new DateHelper(props.dateTimeSend.dateTime)).toLocaleDateTimeString() : __("сейчас");
});

let stop = computed(() => {
    return (new DateHelper(model.value.stop)).toLocaleDateTimeString();
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        modelValue: _unref(model).enabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
        class: "mr-2"
      }, null, 8, ["modelValue"]),
      _createTextVNode(" " + _toDisplayString(_unref(__)('Плавная отправка')), 1)
    ]),
    (_unref(model).enabled)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          class: "mt-1",
          gutter: 8
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { md: 12 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(__)('Дата окончания рассылки')) + " ", 1),
                _createVNode(MyDateTimePicker, {
                  "model-value": _unref(model).stop,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(model).stop = $event ? $event : _unref(model).stop)),
                  class: "w-100 el-input-group"
                }, null, 8, ["model-value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { md: 12 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(__)('Отправлять каждые')) + " ", 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_el_input, {
                    type: "number",
                    modelValue: _unref(model).delay,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).delay) = $event)),
                    modelModifiers: { number: true },
                    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_unref(model).delay = _unref(model).delay ? _unref(model).delay : 1))
                  }, {
                    append: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(__)('минут')), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(model).enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(__)("Отправка начнется %1$s и будет отправляться порциями раз в %2$s минут до %3$s", _unref(start), _unref(model).delay,
                    _unref(stop))), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})