import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { key: "selectAuth" }
const _hoisted_2 = { class: "mb-0" }

import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import AuthListByPlatformTypeResponse from "~/cabinet/ts/data/integrations/AuthListByPlatformTypeResponse";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import IntegrationAuthDialog from "~/cabinet/vue/client/integrations/IntegrationAuthDialog.vue";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigIconFlexButton from "~/cabinet/vue/interface/button/BigIconFlexButton.vue";
import IAuthCreatedPayload from "~/cabinet/vue/client/integrations/IAuthCreatedPayload";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {computed, ref} from "vue";
import AuthCard from "~/cabinet/vue/client/integrations/AuthCard.vue";


const __default__ = {
    name: "SelectIntegrationAuthDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    platformTypeId: {},
    platformId: {},
    allowedPlatformIds: {},
    forOnlineChat: { type: Boolean },
    visible: { type: Boolean }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["select", "reload", "update:visible"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let visible = _useModel<boolean>(__props, "visible");


let platform = ref<Platform>();

let newIntegrationDialog = ref<{
    parentAuth?: Auth,
    platform: Platform
}>();

let iconSize = ref("1.3em");

let authListRequest = computed(() => {
    return new ReactiveRequest(new LcabApiRequest({
        url: `/api/client/integrations/getAuthListByPlatformType`,
        p: {
            platformTypeId: props.platformTypeId,
            platformId: props.platformId,
            forOnlineChat: props.forOnlineChat,
            allowedPlatformIds: props.allowedPlatformIds
        }
    }, AuthListByPlatformTypeResponse));
});

let parentPlatformsToDisplay = computed<Platform[]>(() => {
    if (authListRequest.value.data) {
        let result = authListRequest.value.data.platforms.filter(platform => platform.isReadyToUse && !platform.parentId);
        if (result.length === 1 && props.platformId != null && platform.value == null) {
            platform.value = result[0];
        }
        return result;
    }
    return [];
});

let authOfSelectedPlatform = computed(() => {
    let result: Auth[] = [];
    if (authListRequest.value.isSuccess && platform.value) {
        result = authListRequest.value.data.authList.filter(auth => platform.value.id == auth.platformId)
    }
    return result;
});

function selectPlatform(selectedPlatform: Platform) {
    platform.value = selectedPlatform;
    if (!authOfSelectedPlatform.value.length) {
        showNewIntegrationDialog(selectedPlatform);
    }
}

function showNewIntegrationDialog(platform: Platform, parentAuth?: Auth) {
    newIntegrationDialog.value = {
        visible: true,
        parentAuth,
        platform
    };
}

function hideNewIntegrationDialog() {
    newIntegrationDialog.value = null;
}

async function onNewAuthCreated(payload: IAuthCreatedPayload) {
    await authListRequest.value.make()
    selectIntegration(payload.authId, payload.closeDialog);
}

function selectIntegration(id: string, closeDialog: boolean = true) {
    let auth = authListRequest.value.isSuccess ? authListRequest.value.data.authList.find(auth => auth.id == id) : null;
    if (auth) {
        let platforms = authListRequest.value.data.platforms;
        let platform = auth.getPlatform(platforms);
        if (platform) {
            if (platform.hasChildPlatform) {
                let childPlatform = platforms.find(item => item.parentId == platform.id);
                if (childPlatform) {
                    showNewIntegrationDialog(childPlatform, auth);
                } else {
                    LcabApiResult.showError("Не удалось найти платформу для групп");
                }
            } else {
                emits("select", auth);
                if (closeDialog) {
                    hideNewIntegrationDialog();
                    visible.value = false;
                }
            }
        } else {
            LcabApiResult.showError("Платформа не найдена");
        }
    } else {
        LcabApiResult.showError("Авторизация не найдена");
    }
}

async function removeAuth(authId: string, isChildFromNewAuth: boolean = false) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить эту интеграцию?");

        let result = await LcabApiRequest.fetch({
            url: `/api/client/integrations/${authId}/removeAuth`
        });
        if (result.isSuccess) {
            emits("reload");
            await authListRequest.value.make();
            if (isChildFromNewAuth && newIntegrationDialog.value) {
                let newAuth = authListRequest.value.data.authList.find(auth => auth.id == newIntegrationDialog.value.parentAuth.id);
                if (newAuth) {
                    showNewIntegrationDialog(newIntegrationDialog.value.platform, newAuth);
                    /*
                    result.showMessage();
                    return;

                     */
                }
            }

        }
        result.showMessage();
    } catch (e) {

    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(newIntegrationDialog) && _unref(newIntegrationDialog).visible)
      ? (_openBlock(), _createBlock(IntegrationAuthDialog, {
          key: 0,
          visible: _unref(newIntegrationDialog).visible,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newIntegrationDialog).visible) = $event)),
          platforms: _unref(authListRequest).isSuccess ? _unref(authListRequest).data.platforms : [_unref(newIntegrationDialog).platform],
          platform: _unref(newIntegrationDialog).platform,
          "platform-type-id": _ctx.platformTypeId,
          onAuthCreated: onNewAuthCreated,
          "parent-auth": _unref(newIntegrationDialog).parentAuth,
          onRemove: _cache[1] || (_cache[1] = ($event: any) => (removeAuth($event, true))),
          onSave: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('reload')))
        }, null, 8, ["visible", "platforms", "platform", "platform-type-id", "parent-auth"]))
      : (_openBlock(), _createBlock(_component_el_dialog, {
          key: 1,
          modelValue: _unref(visible),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          title: "Выберите интеграцию",
          width: "650px"
        }, {
          default: _withCtx(() => [
            _createVNode(ReactiveRequestLoader, { request: _unref(authListRequest) }, {
              default: _withCtx(() => [
                _createVNode(_Transition, {
                  name: "zoom",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (!_unref(platform))
                      ? (_openBlock(), _createBlock(BigIconFlexButtonWrapper, { key: "selectPlatform" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(parentPlatformsToDisplay), (platform) => {
                              return (_openBlock(), _createBlock(BigIconFlexButton, {
                                onClick: ($event: any) => (selectPlatform(platform)),
                                key: platform.id,
                                icon: platform.icon,
                                descr: platform.descr,
                                "icon-color": platform.color,
                                "icon-size": _unref(iconSize)
                              }, null, 8, ["onClick", "icon", "descr", "icon-color", "icon-size"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(platform).descr), 1),
                          (_unref(parentPlatformsToDisplay).length > 1)
                            ? (_openBlock(), _createBlock(_component_el_button, {
                                key: 0,
                                link: "",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_isRef(platform) //@ts-ignore
 ? platform.value = null : platform = null))
                              }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" ← Назад ")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(BigIconFlexButtonWrapper, { class: "mt-4" }, {
                            default: _withCtx(() => [
                              _createVNode(BigIconFlexButton, {
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (showNewIntegrationDialog(_unref(platform)))),
                                icon: "plus",
                                descr: "Новая интеграция",
                                "icon-size": _unref(iconSize)
                              }, null, 8, ["icon-size"])
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(authOfSelectedPlatform), (auth) => {
                            return (_openBlock(), _createBlock(AuthCard, {
                              key: auth.id,
                              auth: auth,
                              platform: _unref(platform),
                              "platform-type-id": _ctx.platformTypeId,
                              onReload: _cache[5] || (_cache[5] = ($event: any) => (_unref(emits)('reload'))),
                              onSelect: ($event: any) => (selectIntegration(auth.id)),
                              onRemove: ($event: any) => (removeAuth(auth.id))
                            }, null, 8, ["auth", "platform", "platform-type-id", "onSelect", "onRemove"]))
                          }), 128))
                        ]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["request"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
  ]))
}
}

})