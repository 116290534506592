import CoreUI from "~/core-ui/ts/CoreUI";
import App from "~/cabinet/vue/layout/Cabinet.vue";
import router from "../routes/Router";
import {IInitialAppData} from "~/cabinet/ts/store/AccountStore";
//import {Event} from '@sentry/types/dist';
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import SetInitialAppData from "~/cabinet/ts/initialize/SetInitialAppData";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import "~/cabinet/scss/index.scss";
import {ElAside, ElContainer, ElFooter, ElHeader, ElMain} from "element-plus";
import {setInitialUrl} from "~/cabinet/ts/InitialUrl";

setInitialUrl();

let meta: HTMLMetaElement = document.querySelector("meta[name='csrf-token']");
if (meta) {
    let csrfTokenFromMeta = meta.content;
    if (csrfTokenFromMeta && csrfTokenFromMeta.length) {
        CsrfToken.set(csrfTokenFromMeta);
    }
}

let container = document.getElementById("app").getElementsByTagName("App")[0];
if (container) {
    let initAttribute = container.getAttribute("data");
    if (initAttribute) {
        let initData: IInitialAppData = JSON.parse(initAttribute);
        SetInitialAppData(initData);
    }
}
let $router = router();


LcabLayoutStore.router.value = $router;
let coreUI = new CoreUI(App, $router);
/*
if (window.location.port != "8088") {
    coreUI.setupSentry("https://8b657de41b0c4f77bcb8a047276fa396@o384149.ingest.sentry.io/6168798", (event: Event) => {
        let organization = AccountStore.organization.value;
        if (organization) {
            event.user = {
                ...(event.user ? event.user : {}),
                username: organization.descr,
                id: organization.ocode,
            };

            event.tags = {
                ...(event.tags ? event.tags : {}),
                dealerId: organization.dealerId,
                isDealer: organization.isDealer ? "true" : "false"
            };
        }


        return event;
    });
}
*/


coreUI.createApp(() => {

}, app => {
    app
        .component("el-container", ElContainer)
        .component("el-main", ElMain)
        .component("el-header", ElHeader)
        .component("el-footer", ElFooter)
        .component("el-aside", ElAside);
});

export default () => {
};