import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import Wizard from "~/cabinet/vue/client/wizard/Wizard.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";


const __default__ = {
    name: "ChatWizard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    chatWizardStarted: { type: Boolean }
  },
  emits: ["update:chat-wizard-started"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

async function startChatWizard() {
    let result = await LcabApiRequest.fetch({
        url: `/api/client/wizard/startChatWizard`
    });
    if (!result.showMessageOnError()) {
        emits("update:chat-wizard-started", true);
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.chatWizardStarted)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          onClick: startChatWizard,
          type: "primary"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Создать чат ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(Wizard, { key: 1 }))
  ]))
}
}

})