import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "float-right" }


const __default__ = {
    name: "MyFormItemLabelLink"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  emits: ["click"],
  setup(__props, { emit: __emit }) {

let emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "link-dashed",
      href: "#",
      style: {"line-height":"1.5"},
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(emits)('click')), ["prevent"]))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})