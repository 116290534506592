import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IPhonebookContactColumn from "~/cabinet/vue/client/phonebook/contacts/interfaces";
import PhonebookContactTableColumnFormatter
    from "~/cabinet/vue/client/phonebook/contacts/PhonebookContactTableColumnFormatter";


const __default__ = {
    name: "PhonebookContactTableColumn"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    column: {}
  },
  setup(__props: any) {

let props = __props;

function formatter(row: any, column: any, cellValue: any) {
    return PhonebookContactTableColumnFormatter.format(props.column, cellValue);
}

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    key: _ctx.column.name,
    formatter: formatter,
    label: _ctx.column.descr,
    prop: _ctx.column.name,
    "class-name": "text-nowrap",
    "min-width": "160",
    sortable: "custom"
  }, null, 8, ["label", "prop"]))
}
}

})