import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_2 = {
  key: 1,
  class: "text-muted"
}

import {
    OnlineChatForwardClientToOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "OnlineChatForwardClientToOperatorBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatForwardClientToOperatorActionDescription>()
    },
  setup(__props) {

let props = __props;

let operatorTypes = computed(() => {
    return props.actionDescription.frontendParams.operatorTypes;
});

let operatorType = computed(() => {
    let option = operatorTypes.value.find(item => item.value == props.params.operatorsType);
    return option ? option.descr : null;
});


let operatorDescr = computed(() => {
    let result: string[] = [];
    for (let operator of props.params.operators) {
        let option = props.actionDescription.frontendParams.operators.find(
            option => option.value == operator
        );
        if (option) {
            operator = option.descr;
        }
        result.push(operator as string);
    }
    return result.join(", ");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ActionBlockPreviewItem, { label: "Оператор" }, {
      default: _withCtx(() => [
        (_unref(operatorDescr).length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(operatorDescr)) + " ", 1),
              (_unref(operatorType))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(operatorType)), 1))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, " Текст не задан "))
      ]),
      _: 1
    })
  ]))
}
}

})