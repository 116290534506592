import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import {__} from "~/ts/library/Translate";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "SenderReceiverFilters"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    contactbookDataSourceId: {},
    groupIds: {},
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let request = computed(() => {
    let dataSource = AccountStore.contactbookDataSourceList.value.find(item => item.id == props.contactbookDataSourceId);
    let groupIds = (dataSource?.isFieldsDependsOnGroupId ? [...props.groupIds] : []).sort();
    return new ReactiveRequest<{ filters?: IFiltersData }>(new LcabApiRequest({
        url: `/api/sender/receivers/${props.uslugaId}/${props.contactbookDataSourceId}/getFilters`,
        p: {
            groupIds
        }
    }));
})

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _unref(__)('Фильтры')
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data && _unref(request).data.filters)
            ? (_openBlock(), _createBlock(MyFilter, {
                key: 0,
                modelValue: _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                "filters-data": _unref(request).data.filters,
                "dropdown-align": "right"
              }, null, 8, ["modelValue", "filters-data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }, 8, ["label"]))
}
}

})