import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue"

import {AccountStore} from "~/cabinet/ts/store/AccountStore";


const __default__ = {
    name: "TarifPriceCurrencySelector"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {
    required: true
},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let model = _useModel<string>(__props, "modelValue");

let currencyArray = AccountStore.currencyArray;

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    class: "w-100"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currencyArray), (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: item.shortDescr,
          value: item.id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})