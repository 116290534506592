import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, mergeProps as _mergeProps, renderSlot as _renderSlot, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "mb-4 d-flex" }
const _hoisted_3 = { class: "lh-0 mb-3" }
const _hoisted_4 = { class: "d-flex align-items-center w-100" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex-fill" }
const _hoisted_7 = { class: "lh-primary mt-2" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_10 = { class: "text-right pl-4" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_13 = { class: "table m-0" }
const _hoisted_14 = { class: "text-right" }

import SelectIntegrationAuthDialog from "~/cabinet/vue/client/integrations/SelectIntegrationAuthDialog.vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import IntegrationListResponse from "~/cabinet/ts/data/online/config/integration/IntegrationListResponse";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import Integration from "~/cabinet/ts/data/online/config/integration/Integration";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import IntegrationAuthDialog from "~/cabinet/vue/client/integrations/IntegrationAuthDialog.vue";
import Dictionary from "~/ts/library/Dictionary";
import Platform, {INTEGRATION_PLATFORM_TYPE_CRM} from "~/cabinet/ts/data/integrations/Platform";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {computed, nextTick, ref, watch} from "vue";
import BigIconFlexButton from "~/cabinet/vue/interface/button/BigIconFlexButton.vue";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import StatusTag from "~/cabinet/vue/interface/StatusTag.vue";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import IntegrationState from "~/cabinet/vue/client/online/config/integrations/IntegrationState.vue";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import IIntegrationsComponent from "~/cabinet/vue/client/online/config/integrations/IIntegrationsComponent";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import getAuthById from "~/cabinet/vue/client/online/config/integrations/getAuthById";
import AdditionalAuthConfigDialog from "~/cabinet/vue/client/integrations/AdditionalAuthConfigDialog.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import UsersDialog from "~/cabinet/vue/client/online/config/integrations/UsersDialog.vue";


const __default__ = {
    name: "Integrations"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    platformTypeId: {},
    siteId: {},
    showAdditionalAuthConfigButton: { type: Boolean, default: true },
    showEmptyAsCard: { type: Boolean, default: true }
  },
  emits: ["reload", "new-auth"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let addIntegrationDialog = ref<{
    visible: boolean,
    platformId: string
}>();

let editIntegrationDialog = ref<{
    visible: boolean,
    integration: Integration
}>();

let editParentAuthDialog = ref<{
    visible: boolean;
    auth: Auth;
    platform: Platform;
}>();

let integrationStates = ref<Dictionary<{ descr: string, isCritical: boolean }>>();
let balance = ref<Dictionary<{ descr: string, sum: string }>>();
let channels = ref<Dictionary<{ descr: string, errorDescr?: string, id: string, isValidate: boolean }[]>>();

function showAddIntegrationDialog(platformId?: string) {
    addIntegrationDialog.value = {
        visible: true,
        platformId
    };
}

let integrationListRequest = computed(() => {
    return new ReactiveRequest<IntegrationListResponse>(new LcabApiRequest({
        url: `/api/client/online/integration/${props.siteId}/${props.platformTypeId}/getIntegrations`
    }, IntegrationListResponse));
});

async function onSelectNewAuth(auth: Auth) {
    //fadsa
    let result = await LcabApiRequest.save({
        url: `/api/client/online/integration/${props.siteId}/${auth.id}/saveIntegration`
    });
    if (result.isSuccess) {
        if (addIntegrationDialog.value) {
            addIntegrationDialog.value.visible = false;
        }

        emits("new-auth", auth);
        reload();
    }
}

function reload() {
    emits("reload");
    integrationListRequest.value.make();
}

let additionalAuthConfigDialogRef = ref<{
    visible: boolean,
    authId: string
}>();

async function editAdditionalChatConfig(integration: Integration) {
    additionalAuthConfigDialogRef.value = {
        authId: integration.auth.id,
        visible: true
    }
}

async function removeIntegration(integration: Integration) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить эту интеграцию?");
        let result = await LcabApiRequest.fetch({
            url: `/api/client/online/integration/${props.siteId}/${integration.id}/removeIntegration`
        });
        if (result.isSuccess) {
            try {
                await ElConfirm("Если вы не хотите использовать эту интеграцию в других своих сайтах, вы можете удалить ее полностью.", {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Буду использовать'
                });
                await removeAuth(integration.auth.id, true);
            } catch (e) {

            }
            reload();
        }
    } catch (e) {

    }
}

let integrationsList = computed(() => {
    let result: Dictionary<{ platform: Platform, integrations: Integration[] }> = {};
    let request = integrationListRequest.value;
    if (request.isSuccess) {
        let integrations = integrationListRequest.value.data.integrations;
        for (let integration of integrations) {
            let platformId = integration.platform.id;
            if (!result[platformId]) {
                result[platformId] = {
                    platform: integration.platform,
                    integrations: []
                };
            }
            result[platformId].integrations.push(integration);
        }
    }
    return ObjectHelper.arrayFromDictionary(result);
});

watch(integrationsList, async () => {
    integrationStates.value = null;
    balance.value = null;
    channels.value = null;
    if (integrationsList.value && integrationListRequest.value.data) {
        let idsList: string[] = [];

        for (let integration of integrationListRequest.value.data.integrations) {
            idsList.push(integration.auth.id);
            let parent = integration.auth.parentAuth;
            if (parent) {
                idsList.push(parent.id);
            }
        }
        let result = await LcabApiRequest.fetch({
            url: `/api/client/online/integration/getIntegrationState`,
            p: {
                idsList,
                siteId: props.siteId
            },
            silent: true
        });
        integrationStates.value = result.getData("validationResult");
        balance.value = result.getData("balanceResult");
        channels.value = result.getData("channelsResult");
    }
}, {deep: true})


function editIntegration(integration: Integration) {
    editIntegrationDialog.value = {
        integration,
        visible: true
    };
}

function isIntegrationWithCriticalError(integration: Integration) {
    return !!integrationStates.value?.[integration.auth.id]?.isCritical;
}

async function editParentAuth(authId: string) {
    let result = await getAuthById(authId);
    if (result.isSuccess) {
        editParentAuthDialog.value = {
            visible: true,
            auth: result.data.auth,
            platform: result.data.platform
        };
    }
}

async function removeAuth(authId: string, withoutConfirm: boolean = null) {
    try {
        if (!withoutConfirm) {
            await ElConfirm("Вы уверены, что хотите удалить эту интеграцию?");
        }
        let result = await LcabApiRequest.save({
            url: `/api/client/integrations/${authId}/removeAuth`
        });
        if (result.isSuccess) {
            reload();
        }
    } catch (e) {

    }
}

function openSelectAuthDialog(platform: Platform) {
    addIntegrationDialog.value = null;
    nextTick(() => {
        addIntegrationDialog.value = {
            visible: true,
            platformId: platform.id
        };
    });
}

let usersDialog = ref<{
    visible: boolean,
    integration: Integration
}>();

function openUsersDialog(integration: Integration) {
    usersDialog.value = {
        integration,
        visible: true
    }
}


__expose<IIntegrationsComponent>({
    reload
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, { class: "el-row-root" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            ((_unref(integrationListRequest).data && _unref(integrationListRequest).data.integrations.length > 0) || _ctx.platformTypeId == _unref(INTEGRATION_PLATFORM_TYPE_CRM))
              ? (_openBlock(), _createBlock(_component_el_card, {
                  key: 0,
                  class: _normalizeClass({
                        'sticky sticky-top': true,
                        'mb-5': !_unref(props).showEmptyAsCard
                    })
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (showAddIntegrationDialog(null)))
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Добавить интеграцию ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true),
            (_unref(addIntegrationDialog))
              ? (_openBlock(), _createBlock(SelectIntegrationAuthDialog, {
                  "platform-type-id": _ctx.platformTypeId,
                  visible: _unref(addIntegrationDialog).visible,
                  "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_unref(addIntegrationDialog).visible) = $event)),
                  "platform-id": _unref(addIntegrationDialog).platformId,
                  key: _unref(addIntegrationDialog).platformId,
                  "for-online-chat": "",
                  onSelect: onSelectNewAuth,
                  onReload: reload
                }, null, 8, ["platform-type-id", "visible", "platform-id"]))
              : _createCommentVNode("", true),
            _createVNode(ReactiveRequestLoader, { request: _unref(integrationListRequest) }, _createSlots({ _: 2 }, [
              (_unref(integrationListRequest).data)
                ? {
                    name: "default",
                    fn: _withCtx(() => [
                      (_unref(integrationListRequest).data.showOldCrmWarning)
                        ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(HelpBlock, { danger: "" }, {
                                default: _withCtx(() => [
                                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-weight-bold" }, "Внимание!", -1)),
                                  _cache[8] || (_cache[8] = _createTextVNode(" Удалите ")),
                                  _createElementVNode("a", {
                                    href: `/online#online?site=${_ctx.siteId}/site/api`,
                                    target: "_blank"
                                  }, "старые интеграции с CRM", 8, _hoisted_1),
                                  _cache[9] || (_cache[9] = _createTextVNode(" во избежание проблем с дублированием данных. "))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_unref(integrationsList).length)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showEmptyAsCard ? 'el-card' : 'div'), {
                            key: 1,
                            class: "mb-5"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.showEmptyAsCard)
                                ? (_openBlock(), _createBlock(ElCardHeader, {
                                    key: 0,
                                    title: "У Вас пока что не добавлено ни одной интеграции"
                                  }))
                                : _createCommentVNode("", true),
                              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.showEmptyAsCard ? ElCardSection : 'div'), null, {
                                default: _withCtx(() => [
                                  _cache[10] || (_cache[10] = _createElementVNode("p", null, "Вы можете добавить первую интеграцию прямо сейчас:", -1)),
                                  _createVNode(BigIconFlexButtonWrapper, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(integrationListRequest).data.platforms, (platform) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                          (platform.isReadyToUse && !platform.parentId)
                                            ? (_openBlock(), _createBlock(BigIconFlexButton, {
                                                key: 0,
                                                icon: platform.icon,
                                                descr: platform.descr,
                                                "icon-color": platform.color,
                                                onClick: ($event: any) => (openSelectAuthDialog(platform))
                                              }, null, 8, ["icon", "descr", "icon-color", "onClick"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 256))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            ]),
                            _: 1
                          }))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_unref(integrationsList), (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.platform.id,
                              style: {"margin-bottom":"4em"}
                            }, [
                              _createVNode(_component_el_button, {
                                class: "float-right d-none d-md-inline-block",
                                type: "primary",
                                plain: "",
                                onClick: ($event: any) => (showAddIntegrationDialog(item.platform.id))
                              }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode("Добавить интеграцию ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createElementVNode("h3", _hoisted_2, [
                                _createElementVNode("span", null, [
                                  _createVNode(FontAwesomeIcon, {
                                    class: "mr-1",
                                    icon: item.platform.icon,
                                    style: _normalizeStyle({
                                        color: item.platform.color
                                    })
                                  }, null, 8, ["icon", "style"]),
                                  _createTextVNode(" " + _toDisplayString(item.platform.descr), 1)
                                ]),
                                (item.platform.notGuaranteedText)
                                  ? (_openBlock(), _createBlock(_component_el_tooltip, { key: 0 }, {
                                      content: _withCtx(() => [
                                        _createElementVNode("div", null, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.platform.notGuaranteedText, (line, index) => {
                                            return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(line), 1))
                                          }), 128))
                                        ])
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(StatusTag, {
                                          class: "ml-2",
                                          descr: "Работа не гарантирована",
                                          color: "red"
                                        })
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.integrations, (integration) => {
                                return (_openBlock(), _createBlock(_component_el_card, {
                                  key: integration.id,
                                  class: "light-box-shadow"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(ElCardSection, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                          _createElementVNode("div", _hoisted_4, [
                                            (integration.auth.avatar)
                                              ? (_openBlock(), _createElementBlock("img", {
                                                  key: 0,
                                                  src: integration.auth.avatar,
                                                  class: "avatar mr-3"
                                                }, null, 8, _hoisted_5))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_6, [
                                              _createElementVNode("h5", {
                                                class: _normalizeClass(integration.auth.parentAuth ? 'mb-0' : null)
                                              }, [
                                                (_openBlock(), _createBlock(_resolveDynamicComponent(integration.auth.link ? 'a' : 'span'), {
                                                  href: integration.auth.link,
                                                  target: "_blank",
                                                  style: {"color":"inherit"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(integration.auth.descr), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["href"]))
                                              ], 2),
                                              _createElementVNode("div", _hoisted_7, [
                                                (integration.auth.parentAuth)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                                      _createElementVNode("div", null, [
                                                        _createTextVNode(" Владелец интеграции: " + _toDisplayString(integration.auth.parentAuth.descr) + " ", 1),
                                                        _createVNode(_component_el_tooltip, {
                                                          content: "Редактировать подключение",
                                                          class: "ml-1"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(MyIconButton, {
                                                              edit: "",
                                                              class: "p-0",
                                                              onClick: ($event: any) => (editParentAuth(integration.auth.parentAuth.id))
                                                            }, null, 8, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        (_unref(integrationStates) && _unref(integrationStates)[integration.auth.parentAuth.id])
                                                          ? (_openBlock(), _createBlock(IntegrationState, {
                                                              key: 0,
                                                              "is-critical": _unref(integrationStates)[integration.auth.parentAuth.id].isCritical,
                                                              descr: _unref(integrationStates)[integration.auth.parentAuth.id].descr,
                                                              platform: integration.auth.parentAuth.platform
                                                            }, null, 8, ["is-critical", "descr", "platform"]))
                                                          : _createCommentVNode("", true)
                                                      ])
                                                    ]))
                                                  : _createCommentVNode("", true),
                                                (_unref(channels) && _unref(channels)[integration.auth.id])
                                                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(channels)[integration.auth.id], (channel) => {
                                                      return (_openBlock(), _createElementBlock("div", {
                                                        key: channel.id
                                                      }, [
                                                        _createTextVNode(_toDisplayString(channel.descr) + ": ", 1),
                                                        (channel.isValidate)
                                                          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                                                              key: 0,
                                                              icon: "check",
                                                              color: "green"
                                                            }))
                                                          : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(channel.errorDescr), 1))
                                                      ]))
                                                    }), 128))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_10, [
                                              (_unref(integrationStates) == null)
                                                ? (_openBlock(), _createBlock(MyLoader, { key: 0 }))
                                                : (_unref(integrationStates)[integration.auth.id] != null)
                                                  ? (_openBlock(), _createBlock(IntegrationState, {
                                                      key: 1,
                                                      platform: item.platform,
                                                      descr: _unref(integrationStates)[integration.auth.id].descr,
                                                      "is-critical": _unref(integrationStates)[integration.auth.id].isCritical
                                                    }, null, 8, ["platform", "descr", "is-critical"]))
                                                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                                      (_unref(balance)[integration.auth.id])
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(balance)[integration.auth.id].descr) + ": " + _toDisplayString(_unref(balance)[integration.auth.id].sum), 1))
                                                        : _createCommentVNode("", true),
                                                      _createVNode(FontAwesomeIcon, {
                                                        class: "text-green",
                                                        icon: "check"
                                                      })
                                                    ]))
                                            ])
                                          ])
                                        ]),
                                        _createVNode(BigIconFlexButtonWrapper, { class: "buttons" }, {
                                          default: _withCtx(() => [
                                            _createVNode(BigIconFlexButton, {
                                              onClick: ($event: any) => (editIntegration(integration)),
                                              descr: item.platform.isAuthEditable ? (isIntegrationWithCriticalError(integration) ? 'Исправить подключение' : 'Редактировать подключение') : 'Параметры подключения',
                                              "button-type": isIntegrationWithCriticalError(integration) ? 'danger' : null,
                                              icon: item.platform.isAuthEditable ? 'pencil' : 'plug'
                                            }, null, 8, ["onClick", "descr", "button-type", "icon"]),
                                            (_ctx.showAdditionalAuthConfigButton && integration.auth.additionalConfigForm)
                                              ? (_openBlock(), _createBlock(BigIconFlexButton, {
                                                  key: 0,
                                                  onClick: ($event: any) => (editAdditionalChatConfig(integration)),
                                                  descr: "Дополнительные настройки",
                                                  icon: "cogs"
                                                }, null, 8, ["onClick"]))
                                              : _createCommentVNode("", true),
                                            (integration.platform.isPlatformWithUsers)
                                              ? (_openBlock(), _createBlock(BigIconFlexButton, {
                                                  key: 1,
                                                  descr: "Сопоставление операторов",
                                                  icon: "users",
                                                  onClick: ($event: any) => (openUsersDialog(integration))
                                                }, null, 8, ["onClick"]))
                                              : _createCommentVNode("", true),
                                            _renderSlot(_ctx.$slots, "default", _mergeProps({ ref_for: true }, {integration}, { type: "buttons" })),
                                            _createVNode(BigIconFlexButton, {
                                              onClick: ($event: any) => (removeIntegration(integration)),
                                              descr: "Удалить",
                                              icon: "times",
                                              "button-type": "danger"
                                            }, null, 8, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]))
                          }), 128)),
                      (_unref(integrationListRequest).data.unusedAuthList.length)
                        ? (_openBlock(), _createBlock(_component_el_card, { key: 3 }, {
                            default: _withCtx(() => [
                              _createVNode(ElCardHeader, { title: "Неиспользуемые интеграции" }),
                              _createVNode(ElCardSectionWithTable, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("table", _hoisted_13, [
                                    _createElementVNode("tbody", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(integrationListRequest).data.unusedAuthList, (auth) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          key: auth.id
                                        }, [
                                          _createElementVNode("td", null, [
                                            _createVNode(FontAwesomeIcon, {
                                              icon: _unref(integrationListRequest).data.platforms[auth.platformId].icon,
                                              style: _normalizeStyle({
                                                        color: _unref(integrationListRequest).data.platforms[auth.platformId].color
                                                    }),
                                              class: "mr-1"
                                            }, null, 8, ["icon", "style"]),
                                            _createTextVNode(" " + _toDisplayString(auth.descr), 1)
                                          ]),
                                          _createElementVNode("td", _hoisted_14, [
                                            _createVNode(_component_el_button, {
                                              onClick: ($event: any) => (onSelectNewAuth(auth))
                                            }, {
                                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                _createTextVNode(" Подключить к этому сайту ")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"]),
                                            _createVNode(_component_el_button, {
                                              type: "danger",
                                              plain: "",
                                              onClick: ($event: any) => (removeAuth(auth.id))
                                            }, {
                                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                                _createTextVNode(" Удалить навсегда ")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ])
                                        ]))
                                      }), 128))
                                    ])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _renderSlot(_ctx.$slots, "after-cards"),
                      (_unref(additionalAuthConfigDialogRef) && _unref(additionalAuthConfigDialogRef).visible)
                        ? (_openBlock(), _createBlock(AdditionalAuthConfigDialog, {
                            key: 4,
                            visible: _unref(additionalAuthConfigDialogRef).visible,
                            "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_unref(additionalAuthConfigDialogRef).visible) = $event)),
                            "auth-id": _unref(additionalAuthConfigDialogRef).authId
                          }, null, 8, ["visible", "auth-id"]))
                        : _createCommentVNode("", true)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["request"])
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    (_unref(editIntegrationDialog) && _unref(editIntegrationDialog).visible)
      ? (_openBlock(), _createBlock(IntegrationAuthDialog, {
          key: 0,
          platform: _unref(editIntegrationDialog).integration.platform,
          platforms: [_unref(editIntegrationDialog).integration.platform],
          visible: _unref(editIntegrationDialog).visible,
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_unref(editIntegrationDialog).visible) = $event)),
          value: _unref(editIntegrationDialog).integration.auth,
          "platform-type-id": _ctx.platformTypeId,
          onSave: reload
        }, null, 8, ["platform", "platforms", "visible", "value", "platform-type-id"]))
      : _createCommentVNode("", true),
    (_unref(editParentAuthDialog) && _unref(editParentAuthDialog).visible)
      ? (_openBlock(), _createBlock(IntegrationAuthDialog, {
          key: 1,
          platform: _unref(editParentAuthDialog).platform,
          platforms: [_unref(editParentAuthDialog).platform],
          visible: _unref(editParentAuthDialog).visible,
          "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_unref(editParentAuthDialog).visible) = $event)),
          value: _unref(editParentAuthDialog).auth,
          "platform-type-id": _ctx.platformTypeId,
          onAuthCreated: reload
        }, null, 8, ["platform", "platforms", "visible", "value", "platform-type-id"]))
      : _createCommentVNode("", true),
    (_unref(usersDialog) && _unref(usersDialog).visible)
      ? (_openBlock(), _createBlock(UsersDialog, {
          key: 2,
          visible: _unref(usersDialog).visible,
          "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_unref(usersDialog).visible) = $event)),
          integration: _unref(usersDialog).integration,
          "site-id": _ctx.siteId
        }, null, 8, ["visible", "integration", "site-id"]))
      : _createCommentVNode("", true)
  ]))
}
}

})