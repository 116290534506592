import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "table w-100" }

import {computed, ref} from 'vue';
import ISenderCreateResult from "~/cabinet/vue/client/sender/send/create/ISenderCreateResult";
import StringHelper from "~/ts/library/StringHelper";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import {__} from "~/ts/library/Translate";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import MessageRecipient from "~/cabinet/vue/client/sender/report/parts/MessageRecipient.vue";


const __default__ = {
    name: "RecipientErrorsSection"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    senderResult: {},
    isSuccess: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let recipientErrorCount = computed(() => {
    let result = 0;
    if (props.senderResult) {
        for (let item of props.senderResult.messageRecipientErrors) {
            result += item.count;
        }
    }
    return result;
});

let recipientErrorDescr = computed(() => {
    let count = recipientErrorCount.value;
    return StringHelper.getSclon(
        count,
        __("%d абонент отфильтрован", count),
        __("%d абонента отфильтровано", count),
        __("%d абонентов отфильтровано", count),
    );
});

let recipientDialog = ref<{
    visible: boolean,
    errorIndex: number
}>();

function openRecipientIdsDialog(errorIndex: number) {
    recipientDialog.value = {
        visible: true,
        errorIndex
    };
}

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_unref(recipientErrorCount))
    ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
        key: 0,
        "icon-color": _ctx.isSuccess ? 'red' : null,
        value: _unref(recipientErrorDescr),
        icon: "user-times",
        light: ""
      }, {
        label: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.senderResult.messageRecipientErrors, (item, index) => {
            return (_openBlock(), _createElementBlock("div", null, [
              (_ctx.senderResult.messageRecipientErrors.length > 1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(item.count) + " - ", 1)
                  ], 64))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(item.descr) + " (", 1),
              _createElementVNode("a", {
                textContent: `просмотреть`,
                class: "link-dashed",
                tabindex: "",
                onClick: ($event: any) => (openRecipientIdsDialog(index))
              }, null, 8, _hoisted_1),
              _cache[1] || (_cache[1] = _createTextVNode(") "))
            ]))
          }), 256)),
          (_unref(recipientDialog) && _unref(recipientDialog).visible)
            ? (_openBlock(), _createBlock(_component_el_dialog, {
                key: 0,
                class: "right",
                title: "Список получателей с ошибкой",
                modelValue: _unref(recipientDialog).visible,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(recipientDialog).visible) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, null, {
                    default: _withCtx(() => [
                      _createVNode(ElCardSectionWithTable, null, {
                        default: _withCtx(() => [
                          _createElementVNode("table", _hoisted_2, [
                            _createElementVNode("tbody", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.senderResult.messageRecipientErrors[_unref(recipientDialog).errorIndex].recipients, (recipient) => {
                                return (_openBlock(), _createElementBlock("tr", null, [
                                  _createElementVNode("td", null, [
                                    _createVNode(MessageRecipient, {
                                      "usluga-id": _ctx.senderResult.uslugaId,
                                      recipient: recipient
                                    }, null, 8, ["usluga-id", "recipient"])
                                  ])
                                ]))
                              }), 256))
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["icon-color", "value"]))
    : _createCommentVNode("", true)
}
}

})