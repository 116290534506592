import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "button" }

import IButtonBlockValues, {
    IButtonStyles
} from "~/cabinet/vue/interface/htmlConstructor/blocks/button/IButtonBlockValues";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "ButtonDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<IButtonStyles>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {parentStructureStyles, instanceId, styleId, htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<IButtonBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let css = computed(() => {
    let style = props.styles;
    let result: any = {
        'button': {
            background: style.bodyButtonBackground,
            color: style.bodyButtonColor,
            border: `${style.bodyButtonBorderWidth}px solid ${style.bodyButtonBorderColor}`,
            fontSize: `${style.bodyButtonSize}px`,
            borderRadius: `${style.bodyButtonBorderRadius}px`,
            fontFamily: parentStructureStyles.value ? parentStructureStyles.value.bodyTextFont : null
        }
    };
    if (style.bodyButtonFullWidth) {
        result.button = {
            ...result.button,
            display: 'block',
            width: '100%',
            textAlign: 'center'
        };
    }
    return result;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({textAlign: _unref(model).align}),
    id: _unref(instanceId)
  }, [
    _createVNode(MyStyle, {
      id: _unref(styleId),
      important: false,
      css: _unref(css),
      "root-selector": `#${_unref(instanceId)}`
    }, null, 8, ["id", "css", "root-selector"]),
    _createElementVNode("button", _hoisted_2, [
      _createVNode(InputWithVariablesPreview, {
        text: _unref(model).title,
        variables: _unref(htmlConstructorEditor).params.contentVariables
      }, null, 8, ["text", "variables"])
    ])
  ], 12, _hoisted_1))
}
}

})