import Random from "~/ts/library/Random";
import {Reactive, reactive} from "vue";
import AbstractApiRequest from "~/core-ui/ts/request/AbstractApiRequest";

interface StateInterface<T> {
    loaded: boolean,
    loading: boolean,
    data: T,
    code: number,
    descr: string
}

export default class ReactiveRequest<T> {
    protected _request: AbstractApiRequest<any, T>;

    private uid: string = undefined;
    private callback: (data: T) => void;
    private errorCallback: () => void;
    private showErrorMessage: boolean = true;
    private state: Reactive<StateInterface<T>>;

    public constructor(request: AbstractApiRequest<any, T>, autoStart: boolean = true) {
        this.state = reactive({
            loaded: false,
            loading: false,
            data: undefined,
            code: null,
            descr: null
        });
        this.reset();
        this._request = request;
        if (typeof this.requestConfig.silent != "boolean") {
            this.requestConfig.silent = true;
        }

        if (autoStart) {
            this.make();
        }
    }

    get code(): number {
        return this.state.code;
    }

    set code(value: number) {
        this.state.code = value;
    }

    get descr(): string {
        return this.state.descr;
    }

    set descr(value: string) {
        this.state.descr = value;
    }

    get data(): T {
        return this.state.data as any;
    }

    set data(data: T) {
        this.state.data = data as any;
    }

    get loading(): boolean {
        return this.state.loading;
    }

    get loaded(): boolean {
        return this.state.loaded;
    }

    public setShowErrorMessage(value: boolean) {
        this.showErrorMessage = value;
        return this;
    }

    get requestConfig() {
        return this._request.options;
    }

    setCallback(callback?: (data: T) => void) {
        this.callback = callback;
        return this;
    }

    setErrorCallback(callback?: () => void) {
        this.errorCallback = callback;
        return this;
    }

    private reset() {
        this.code = undefined;
        this.descr = undefined;
        this.state.data = undefined;
    }

    public get isLoading() {
        return this.state.loading;
    }

    public get isLoaded(): boolean {
        return this.state.loaded;
    }

    get isSuccess() {
        return this.code == 1;
    }

    getRequestParam(key: string) {
        return this.requestConfig.p ? this.requestConfig.p[key] : null;
    }

    addRequestParam(key: string, value: any) {
        if (!this.requestConfig.p) {
            this.requestConfig.p = {};
        }
        this.requestConfig.p[key] = value;
        //this.requestConfig.p[key] = value;
        return this;
    }

    protected processData(data: T) {

    }

    public make() {
        return new Promise<this>(resolve => {
            setTimeout(async () => {
                this.reset();
                let uid = this.uid = Random.uid();

                this.state.loaded = false;
                this.state.loading = true;
                try {
                    let result = await this._request.send();//new LcabApiRequest<T>(this.requestConfig, this.resultType)).send();
                    if (this.uid == uid) {
                        if (result.isSuccess) {
                            this.data = result.getData();
                            this.processData(this.data);
                            if (this.callback) {
                                this.callback(this.data);
                            }
                        } else {
                            if (this.showErrorMessage) {
                                result.showMessageOnError();
                            }
                            if (this.errorCallback) {
                                this.errorCallback();
                            }
                        }
                        this.code = result.code;
                        this.descr = result.descr;
                    }
                } catch (e) {
                    if (this.uid == uid && this.errorCallback) {
                        this.errorCallback();
                    }
                }
                if (this.uid == uid) {
                    this.state.loaded = true;
                    this.state.loading = false;
                }
                resolve(this);
            }, 1)
        });
    }

}
