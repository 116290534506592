import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "mt-1 lh-primary"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { style: {"white-space":"pre-line"} }
const _hoisted_5 = { key: 1 }

import {computed, ref} from 'vue';
import {IField} from "~/cabinet/vue/interface/form/elements/Interfaces";

import {FORM_ELEMENT_TYPE_DICTIONARY} from "~/cabinet/vue/interface/form/elements/FormElementCollection";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {FormElementExposeInterface} from "~/cabinet/vue/interface/form/elements/useFormElement";


const __default__ = {
    name: "FormElementFactory"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    field: {},
    preview: { type: Boolean },
    contentVariables: {},
    autoEmitValueWhenEmpty: { type: Boolean, default: true },
    textPreview: { type: Boolean },
    formModel: {},
    disallowOptionIcon: { type: Boolean },
    clearable: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["blur", "focus"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<any>(__props, "modelValue");

let formElement = ref<FormElementExposeInterface>();


let type = computed(() => {
    return FORM_ELEMENT_TYPE_DICTIONARY[props.field.type];
});

function focus() {
    formElement.value?.focus();
}

__expose<FormElementExposeInterface>({
    focus,
    field: props.field
});

return (_ctx: any,_cache: any) => {
  return (_unref(type) != null)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(type).displayComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(type).displayComponent), _mergeProps({
              key: 0,
              ref_key: "formElement",
              ref: formElement,
              field: _ctx.field,
              modelValue: _unref(model),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
              "form-model": _ctx.formModel,
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('blur', $event))),
              onFocus: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('focus')))
            }, _unref(type).displayComponentProps, {
              "content-variables": _ctx.contentVariables,
              readonly: _ctx.preview,
              clearable: _ctx.clearable,
              "text-readonly": _ctx.textPreview,
              "auto-emit-value-when-empty": _ctx.autoEmitValueWhenEmpty,
              "disallow-option-icon": _ctx.disallowOptionIcon
            }), null, 16, ["field", "modelValue", "form-model", "content-variables", "readonly", "clearable", "text-readonly", "auto-emit-value-when-empty", "disallow-option-icon"]))
          : _createCommentVNode("", true),
        (_ctx.field.htmlUnderInput)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                innerHTML: _ctx.field.htmlUnderInput
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.field.helpUnderInput)
          ? (_openBlock(), _createBlock(HelpBlock, { key: 2 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.field.helpUnderInput), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 512)), [
        [_vShow, !_unref(type).isHidden]
      ])
    : (_ctx.field.type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Тип " + _toDisplayString(_ctx.field.type) + " пока не реализован в FormElement ", 1))
      : _createCommentVNode("", true)
}
}

})