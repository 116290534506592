import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-nowrap" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

import {computed} from 'vue';
import ICurrency from "~/cabinet/ts/data/ICurrency";
import CurrencyService from "~/cabinet/ts/service/Currency";
import {IPrice} from "~/cabinet/ts/data/usluga/IPrice";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "Currency"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    price: {},
    currencyId: {},
    sum: {},
    graph: { type: Boolean },
    shortDescr: { type: Boolean },
    hideCurrency: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let currency = computed<ICurrency>(() => {
    return CurrencyService.getById(props.currencyId ? props.currencyId : props.price.currencyId);
});

let computedSum = computed(() => {
    return props.price ? props.price.sum : (typeof props.sum == 'string' ? parseFloat(props.sum) : props.sum);
});

let forceText = computed<boolean>(() => {
    return props.graph || props.shortDescr;
});

let formattedSum = computed(() => {
    if (computedSum.value != null) {
        if (typeof computedSum.value == "number" && !isNaN(computedSum.value)) {
            let sum = CurrencyService.formatPrice(computedSum.value, props.currencyId);
            let formatter = new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: sum % 1 === 0 ? undefined : (currency.value?.decimalPoint)
            });
            return formatter.format(sum);
        } else {
            return props.sum;
        }
    } else {
        return null;
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createTextVNode(_toDisplayString(_unref(formattedSum)) + " ", 1),
    (!_ctx.hideCurrency)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_unref(forceText) && _unref(currency).icon)
            ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                key: 0,
                icon: _unref(currency).icon
              }, null, 8, ["icon"]))
            : (_ctx.shortDescr)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(currency).shortDescr), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(currency).graph), 1))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})