import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = {
  key: 1,
  class: "text-muted"
}

import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {
    SendNotificationToClientOnlineChatOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {computed} from "vue";


const __default__ = {
    name: "SendNotificationToClientOnlineChatOperatorBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<SendNotificationToClientOnlineChatOperatorActionDescription>()
    },
  setup(__props) {

let props = __props;

let site = computed(() => {
    let result = props.params.dealerSiteId;
    let site = props.actionDescription.frontendParams.sites.find(site => site.id == result);
    if (site) {
        result = site.title;
    } else {
        result = "Чат не найден";
    }
    return result;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(site)), 1),
    (_unref(props).params.text)
      ? (_openBlock(), _createBlock(InputWithVariablesPreview, {
          key: 0,
          text: _unref(props).params.text,
          variables: _ctx.contentVariables
        }, null, 8, ["text", "variables"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, " Текст не задан "))
  ]))
}
}

})