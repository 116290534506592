import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "text-muted" }
const _hoisted_2 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_3 = ["href", "textContent"]
const _hoisted_4 = ["href"]

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import {
    OnlineChatRunExternalBotActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import Integrations from "~/cabinet/vue/client/online/config/integrations/Integrations.vue";
import BigIconFlexButton from "~/cabinet/vue/interface/button/BigIconFlexButton.vue";
import Integration from "~/cabinet/ts/data/online/config/integration/Integration";
import {
    useOnlineChatRunExternalBot
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotHelper";
import {computed, ref} from "vue";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import TextInputWithVariablesHelper from "~/cabinet/vue/interface/tinymce/TextInputWithVariablesHelper";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import WebhookSchemeDialog from "~/cabinet/vue/api/WebhookSchemeDialog.vue";
import DocumentationHelper from "~/cabinet/vue/api/DocumentationHelper";
import {
    RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION,
    RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE,
    RunExternalBotActionStartMode
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/Interface";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import MyTimeSelectorInSeconds from "~/cabinet/vue/interface/form/MyTimeSelectorInSeconds.vue";

interface ConfigOutputResponseInterface {
    model: {
        outputs: OutputInterface[]
    }
}

interface OutputInterface {
    id: string;
    descr: string;
}


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "OnlineChatRunExternalBotForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatRunExternalBotActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {


let props = __props;


let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatRunExternalBotActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    authPrimaryId: null,
    platformId: null,
    initialTextMessageForBot: TextInputWithVariablesHelper.getAnchorByVariable(
        props.actionDescription.frontendParams.lastClientMessageVarName
    ),
    askQuestionTextMessageForBot: "Задайте ваш вопрос",
    startMode: RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION as RunExternalBotActionStartMode,
    responseTime: 30 * 60
}));

let selectedAuth = computed(() => useOnlineChatRunExternalBot(model.value).selectedAuth);

let startModes: { id: RunExternalBotActionStartMode, descr: string, extraDescr: string }[] = [
    {
        id: RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION,
        descr: "Бот задает вопрос",
        extraDescr: "Во время запуска блока клиенту будет отправлено сообщение. Ответ клиента на это сообщение будет отправлен боту."
    },
    {
        id: RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE,
        descr: "Бот сразу получит сообщение",
        extraDescr: "Во время запуска блока, бот сразу получит сообщение с текстом, который вы укажете в настройках."
    }
];

let webhookSchemeDialogVisible = ref(false);

function apply(integration?: Integration) {
    model.value.authPrimaryId = integration?.auth.primaryId;
    model.value.platformId = integration?.auth.platformId;
}

function applyAuth(auth: Auth) {
    model.value.authPrimaryId = auth.primaryId;
    model.value.platformId = auth.platformId;
}

let outputConfigDialog = ref<{
    visible: boolean,
    authPrimaryId: string,
    response: ConfigOutputResponseInterface
}>();

function r(integration: Integration) {
    return integration;
}

function getNewOutput(): OutputInterface {
    return {
        id: null,
        descr: null
    }
}

async function openOutputConfigDialog(authPrimaryId: string) {
    let result = await LcabApiRequest.fetch<ConfigOutputResponseInterface>({
        url: `/api/client/integration/externalBot/${authPrimaryId}/getOutputConfigDialog`,
    });
    if (!result.showMessageOnError()) {
        outputConfigDialog.value = {
            visible: true,
            authPrimaryId,
            response: result.data
        }
    }
}

async function saveOutputConfig() {
    let result = await LcabApiRequest.save<ConfigOutputResponseInterface>({
        url: `/api/client/integration/externalBot/${outputConfigDialog.value.authPrimaryId}/saveOutputConfigDialog`,
        p: outputConfigDialog.value.response.model
    });
    if (result.isSuccess) {
        outputConfigDialog.value = null;
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(model).authPrimaryId)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_el_form_item, { label: "Интеграция" }, {
            default: _withCtx(() => [
              (_unref(selectedAuth).value && typeof _unref(selectedAuth).value.descr == 'string')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(selectedAuth).value.descr) + " ", 1),
                    (_unref(selectedAuth).value.platformDescr)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[12] || (_cache[12] = _createTextVNode(" / ")),
                          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(selectedAuth).value.platformDescr), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(selectedAuth).value ? "Интеграция не найдена! Возможно, она была удалена." : "Интеграция не выбрана"), 1)),
              _createElementVNode("div", null, [
                (_unref(selectedAuth).value && _unref(selectedAuth).value.id && _unref(selectedAuth).value.isCustomBot)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      plain: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (openOutputConfigDialog(_unref(selectedAuth).value.id)))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(FontAwesomeIcon, {
                          icon: "code-branch",
                          class: "mr-2"
                        })
                      ]),
                      default: _withCtx(() => [
                        _cache[13] || (_cache[13] = _createTextVNode(" Настройка выходов "))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_button, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (apply())),
                  plain: ""
                }, {
                  icon: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, {
                      icon: "exchange",
                      class: "mr-2"
                    })
                  ]),
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createTextVNode(" Сменить интеграцию "))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          (_unref(selectedAuth).value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_form_item, { label: "Способ запуска бота" }, {
                  default: _withCtx(() => [
                    _createVNode(BigIconFlexButtonWrapper, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(startModes), (type) => {
                          return (_openBlock(), _createBlock(BigRadioFlexButton, {
                            key: type.id,
                            modelValue: _unref(model).startMode,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).startMode) = $event)),
                            label: type.id,
                            descr: type.descr,
                            "extra-descr": type.extraDescr,
                            style: {"min-width":"50%"}
                          }, null, 8, ["modelValue", "label", "descr", "extra-descr"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_unref(model).startMode === _unref(RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE))
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      label: "Текст первого сообщения, которое будет отправлено боту"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(TextInputWithVariables, {
                          "content-variables": _ctx.contentVariables,
                          modelValue: _unref(model).initialTextMessageForBot,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).initialTextMessageForBot) = $event))
                        }, null, 8, ["content-variables", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_unref(model).startMode === _unref(RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION))
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      label: "Текст первого сообщения, которое отправит бот"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(TextInputWithVariables, {
                          "content-variables": _ctx.contentVariables,
                          modelValue: _unref(model).askQuestionTextMessageForBot,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).askQuestionTextMessageForBot) = $event))
                        }, null, 8, ["content-variables", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_form_item, { label: "Время, за которое бот должен прислать ответ" }, {
                  default: _withCtx(() => [
                    _createVNode(MyTimeSelectorInSeconds, {
                      modelValue: _unref(model).responseTime,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).responseTime) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" Если бот не пришлет какой-либо ответ в течение заданного времени, этот блок будет завершён с выходом \"Бот не ответил за отведенное время\". ")
                      ])),
                      _: 1
                    }),
                    _createVNode(HelpBlock, { warning: "" }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" Время принятия решения по данному блоку будет отодвигаться каждый раз, когда клиент отправляет новое сообщение. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_unref(selectedAuth).value.platformId == _unref(props).actionDescription.frontendParams.customBotPlatformId)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 2,
                      label: "Документация"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(HelpBlock, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, [
                              _cache[17] || (_cache[17] = _createElementVNode("span", { class: "d-inline-block" }, " Мы будем отправлять уведомление о новом сообщении для вашего бота на URL, который вы указали в настройках интеграции: ", -1)),
                              _createElementVNode("a", {
                                href: _unref(selectedAuth).value.customBotUrl,
                                textContent: _toDisplayString(_unref(selectedAuth).value.customBotUrl),
                                style: {"white-space":"nowrap"},
                                class: "text-truncate d-inline-block mw-100"
                              }, null, 8, _hoisted_3)
                            ]),
                            (_unref(props).actionDescription.frontendParams.webhook)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("p", null, [
                                    _createVNode(_component_el_button, {
                                      type: "primary",
                                      plain: "",
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(webhookSchemeDialogVisible) //@ts-ignore
 ? webhookSchemeDialogVisible.value = true : webhookSchemeDialogVisible = true))
                                    }, {
                                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                                        _createTextVNode(" Посмотреть формат уведомлений ")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  (_unref(webhookSchemeDialogVisible))
                                    ? (_openBlock(), _createBlock(WebhookSchemeDialog, {
                                        key: 0,
                                        visible: _unref(webhookSchemeDialogVisible),
                                        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (_isRef(webhookSchemeDialogVisible) ? (webhookSchemeDialogVisible).value = $event : webhookSchemeDialogVisible = $event)),
                                        "platform-id": _unref(props).actionDescription.frontendParams.webhook.platformId,
                                        "auth-primary-id": _unref(props).actionDescription.frontendParams.webhook.authPrimaryId,
                                        "webhook-id": _unref(props).actionDescription.frontendParams.webhook.webhookId,
                                        "data-only": "",
                                        "webhook-descr": "Формат уведомлений о новом сообщении"
                                      }, null, 8, ["visible", "platform-id", "auth-primary-id", "webhook-id"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(HelpBlock, null, {
                          default: _withCtx(() => [
                            _cache[20] || (_cache[20] = _createElementVNode("p", null, "Для того, чтобы отправить ответ клиенту, используйте token, который вы получили в webhook от нас.", -1)),
                            _createElementVNode("p", null, [
                              _createElementVNode("a", {
                                href: _unref(DocumentationHelper).getUrl(['customBot'], 'tag/CustomBot'),
                                target: "_blank"
                              }, [
                                _createVNode(_component_el_button, {
                                  type: "primary",
                                  plain: ""
                                }, {
                                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                                    _createTextVNode(" Посмотреть документацию по Rest API ")
                                  ])),
                                  _: 1
                                })
                              ], 8, _hoisted_4)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createBlock(Integrations, {
          key: 1,
          ref: "integrations",
          "platform-type-id": _unref(props).actionDescription.frontendParams.platformTypeId,
          "site-id": _unref(props).eventParamsByAuth.authId,
          onNewAuth: _cache[8] || (_cache[8] = ($event: any) => (applyAuth($event)))
        }, {
          default: _withCtx(({integration}) => [
            _createVNode(BigIconFlexButton, {
              onClick: ($event: any) => (apply(integration)),
              descr: "Выбрать бота",
              icon: "hand-pointer"
            }, null, 8, ["onClick"]),
            (r(integration).platform.isCustomExternalBotPlatform)
              ? (_openBlock(), _createBlock(BigIconFlexButton, {
                  key: 0,
                  onClick: ($event: any) => (openOutputConfigDialog(r(integration).auth.primaryId)),
                  descr: "Настройка выходов",
                  icon: "code-branch"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["platform-type-id", "site-id"])),
    (_unref(outputConfigDialog) && _unref(outputConfigDialog).visible)
      ? (_openBlock(), _createBlock(MyDialog, {
          key: 2,
          visible: _unref(outputConfigDialog).visible,
          "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_unref(outputConfigDialog).visible) = $event)),
          title: "Настройка выходов",
          dialog: {class: 'sticky-footer', width: '600px'},
          onApply: _cache[11] || (_cache[11] = ($event: any) => (saveOutputConfig()))
        }, {
          default: _withCtx(() => [
            _createVNode(MyInputList, {
              modelValue: _unref(outputConfigDialog).response.model.outputs,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(outputConfigDialog).response.model.outputs) = $event)),
              "remove-last-available": false,
              "new-item-value-getter": getNewOutput
            }, {
              default: _withCtx(({index}) => [
                _createVNode(_component_el_card, { class: "mb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(ElCardHeader, {
                      title: `Выход ${index + 1}`
                    }, null, 8, ["title"]),
                    _createVNode(ElCardSection, null, {
                      default: _withCtx(() => [
                        _cache[21] || (_cache[21] = _createElementVNode("div", null, " Код выхода: ", -1)),
                        _createVNode(_component_el_input, {
                          class: "mt-1",
                          modelValue: _unref(outputConfigDialog).response.model.outputs[index].id,
                          "onUpdate:modelValue": ($event: any) => ((_unref(outputConfigDialog).response.model.outputs[index].id) = $event),
                          placeholder: "Строка, которую вы будете отправлять в API-запросе для завершения бота"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "mt-3" }, " Название выхода: ", -1)),
                        _createVNode(_component_el_input, {
                          class: "mt-1",
                          modelValue: _unref(outputConfigDialog).response.model.outputs[index].descr,
                          "onUpdate:modelValue": ($event: any) => ((_unref(outputConfigDialog).response.model.outputs[index].descr) = $event),
                          placeholder: "Оно будет видно в настройке сценария"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(HelpBlock, null, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createElementVNode("p", null, "С помощью данной настройки вы можете добавить свои выходы из блока \"Сторонний бота\".", -1),
                _createElementVNode("p", null, "Укажите идентификатор и название выхода.", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible"]))
      : _createCommentVNode("", true)
  ]))
}
}

})