import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive } from "vue"

import {computed, shallowRef, watch, ref} from "vue";
import useActivated from "~/ts/vuePlugins/useActivated";
import Blank from "~/core-ui/vue/router/Blank.vue";
import {useRoute} from "vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";


const __default__ = {
    name: "MyRouterViewComponent"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    routeComponent: {},
    routeName: {},
    level: {}
  },
  setup(__props: any) {

let props = __props;

let componentToDisplay = shallowRef<any>();
let activated = useActivated();
let currentRouteName = ref(props.routeName);
let route = useRoute();
let parentRouteName = route.matched[props.level - 1]?.name;
let isKeepAlive = ref(false);

watch(computed(() => props.routeComponent), () => {
    //if (activated.value) {
        if (parentRouteName == route.matched[props.level - 1]?.name) {
            if (componentToDisplay.value !== props.routeComponent) {
                componentToDisplay.value = props.routeComponent;
                currentRouteName.value = props.routeName;
                isKeepAlive.value = (route.matched[props.level]?.meta as IRouteMeta)?.keepAlive !== false;
            }
        }
    /*} else {
        debugger;
    }*/
}, {
    immediate: true
});

return (_ctx: any,_cache: any) => {
  return (_unref(isKeepAlive))
    ? (_openBlock(), _createBlock(_KeepAlive, {
        key: 0,
        max: 10
      }, [
        (_unref(activated) && _unref(componentToDisplay) && _unref(currentRouteName))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(componentToDisplay)), { key: _unref(currentRouteName) }))
          : (_openBlock(), _createBlock(Blank, { key: 1 }))
      ], 1024))
    : (_unref(activated) && _unref(componentToDisplay) && _unref(currentRouteName))
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(componentToDisplay)), { key: 1 }))
      : _createCommentVNode("", true)
}
}

})