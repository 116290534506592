import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import TinyMceEditor from "@tinymce/tinymce-vue";
import {computed, inject, ref} from "vue";
import {IsRouteActivatedKey} from "~/core-ui/vue/router/constants";


const __default__ = {
    name: "MyTinyMceEditorWrapper",
    inheritAttrs: false
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let model = _useModel<string>(__props, "modelValue");
let isRouteActivated = inject(IsRouteActivatedKey, computed(() => true));
let editor = ref<any>();
__expose({
    getEditor: () => editor.value?.getEditor()
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isRouteActivated))
      ? (_openBlock(), _createBlock(_unref(TinyMceEditor), _mergeProps({ key: 0 }, _ctx.$attrs, {
          ref_key: "editor",
          ref: editor,
          "model-value": _unref(model),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event))
        }), null, 16, ["model-value"]))
      : _createCommentVNode("", true)
  ]))
}
}

})