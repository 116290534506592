import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  key: 0,
  class: "text-muted lh-1 font-size-small"
}

import {ISenderFormSite} from "~/cabinet/vue/client/sender/send/Interfaces";
import {SenderReplyOnlineChatModelInterface} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import ChatWizard from "~/cabinet/vue/client/sender/send/settings/ChatWizard.vue";
import {computed, ref, watch} from "vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "SenderReplyOnlineChat"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    sites: {},
    available: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let chatWizardStarted = ref(false);

let _modelValue = _useModel<SenderReplyOnlineChatModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    enabled: false,
    siteId: props.sites.length == 1 ? props.sites[0].id : null
}));

watch(computed(() => props.available), () => {
    if (!props.available) {
        model.value.enabled = false;
    }
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _unref(model).enabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
          disabled: !_ctx.available,
          class: "mr-2"
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_unref(__)('Получать ответ в Online-чат')) + " ", 1),
        (!_ctx.available)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Недоступно для выбранного имени отправителя "))
          : _createCommentVNode("", true)
      ])
    ]),
    (_unref(model).enabled)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          class: "mt-1",
          gutter: 10
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { md: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: "w-100",
                  placeholder: _ctx.sites.length ? _unref(__)('Выберите чат') : _unref(__)('У вас не создано ни одного Online-чата'),
                  modelValue: _unref(model).siteId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).siteId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sites, (site) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: site.id,
                        value: site.id,
                        label: site.descr
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            (!_ctx.sites.length && !_unref(chatWizardStarted))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  md: 12
                }, {
                  default: _withCtx(() => [
                    (!_ctx.sites.length)
                      ? (_openBlock(), _createBlock(ChatWizard, {
                          key: 0,
                          "chat-wizard-started": _unref(chatWizardStarted),
                          "onUpdate:chatWizardStarted": _cache[2] || (_cache[2] = ($event: any) => (_isRef(chatWizardStarted) ? (chatWizardStarted).value = $event : chatWizardStarted = $event))
                        }, null, 8, ["chat-wizard-started"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.sites.length && !_unref(chatWizardStarted))
              ? (_openBlock(), _createBlock(_component_el_col, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Если у вас нет своего сайта - не беда. Вы можете создать чат в любом случае, указав в качестве доменного имени что угодно. Это не помешает вам принимать ответы ваших клиентов с помощью интеграции. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})