import {ModelRef, WritableComputedRef} from "vue";
import {SenderFormReceiversInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";

export function useSenderReceiversComponent(
    _modelValue: ModelRef<SenderFormReceiversInterface>
): WritableComputedRef<SenderFormReceiversInterface> {
    return useObjectModel(_modelValue, () => ({
        contactbookDataSourceId: AccountStore.defaultContactbookDataSourceId.value,
        fromExcelFile: null,
        groups: [],
        contacts: [],
        filters: {},
        stopListEnabled: true,
        organizationFilters: {},
        otherRecipientsId: "",
        stopListGroupIds: [],
        limit: {
            enabled: false,
            first: 1,
            last: 50,
            random: false
        }
    }))
}