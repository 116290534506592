import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

import {computed, ref, watch} from 'vue';
import {__} from "~/ts/library/Translate";
import Dictionary from "~/ts/library/Dictionary";
import {ISenderMessageStatus} from "~/cabinet/ts/data/sender/ISenderMessageStatus";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import Delay from "~/ts/library/Delay";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyValues from "~/cabinet/vue/interface/data/MyValues.vue";
import MyValue from "~/cabinet/vue/interface/data/MyValue.vue";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";

interface IFastReportData {
    status: Dictionary<number>;
    hasAvailableNextStatus: boolean;
}

const __default__ = {
    name: "FastTaskReport"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    uslugaId: {},
    inProgress: { type: Boolean, default: true },
    sendId: {},
    error: {}
  },
  emits: ["update:in-progress"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let md = ref(4);
let span = ref(12);

let result = ref<IFastReportData>();

let fetchStatusStarted = ref(false);
let intervalDelay = ref(5000);


watch(computed(() => props.sendId), () => startFetchStatus());
watch(computed(() => props.inProgress), () => startFetchStatus());


async function startFetchStatus() {
    if (props.sendId && props.inProgress && !fetchStatusStarted.value) {
        fetchStatusStarted.value = true;
        await Delay.make(intervalDelay.value);
        let continueFetch = true;
        try {
            let fetchResult = await LcabApiRequest.fetch({
                url: "/api/sender/" + props.uslugaId + "/getFastTaskReport",
                p: {
                    sendId: props.sendId
                },
                silent: true,
                silentError: true
            });
            if (fetchResult.isSuccess) {
                result.value = fetchResult.getData();
                if (!result.value.hasAvailableNextStatus) {
                    continueFetch = false;
                    emits("update:in-progress", false);
                }
            }
        } catch (e) {
        }
        fetchStatusStarted.value = false;
        if (continueFetch) {
            startFetchStatus();
        }
    }
}

let isInProgress = computed(() => {
    return props.inProgress && (!result.value || result.value.hasAvailableNextStatus);
});

let sendStatus = computed<{ label: string, icon: string }>(() => {
    let result = {
        label: "",
        icon: ""
    };

    if (isInProgress.value) {
        result.label = __('Отчёт формируется');
    } else if (props.error) {
        result.label = __('Ошибка');
        result.icon = 'ban';
    } else {
        result.label = __('Рассылка завершена');
        result.icon = 'check';
    }
    return result;
});

let senderStore = SenderUslugi[props.uslugaId].store;

let statuses = computed<{ status: ISenderMessageStatus, value: number }[]>(() => {
    let statuses = [];
    if (result.value) {
        for (let status of senderStore.statusList) {
            if (result.value.status[status.id]) {
                statuses.push({
                    status,
                    value: result.value.status[status.id]
                });
            }
        }
    }
    return statuses;
});


return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(ElCardSection, null, {
        default: _withCtx(() => [
          _createVNode(MyValues, null, {
            default: _withCtx(() => [
              _createVNode(MyValue, {
                label: _unref(sendStatus).label,
                md: _unref(md),
                span: _unref(span)
              }, {
                default: _withCtx(() => [
                  (_unref(isInProgress))
                    ? (_openBlock(), _createBlock(MyLoader, { key: 0 }))
                    : (_openBlock(), _createBlock(FontAwesomeIcon, {
                        key: 1,
                        icon: _unref(sendStatus).icon
                      }, null, 8, ["icon"]))
                ]),
                _: 1
              }, 8, ["label", "md", "span"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statuses), (item) => {
                return (_openBlock(), _createBlock(MyValue, {
                  key: item.status.id,
                  label: item.status.descr,
                  md: 4,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.value), 1)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})