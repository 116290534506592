import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "or-divider" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = { class: "lh-33" }

import {
    ISenderFormContact, ISenderFormGroup,
    SenderFormParamsInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import SenderReceiversPreview from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiversPreview.vue";
import MyFlipContainer from "~/cabinet/vue/interface/transition/MyFlipContainer.vue";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import {ContactBookDataSourceInterface} from "~/cabinet/ts/store/AccountStore";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {
    SenderFormReceiversInterface
} from '../useSenderContentForm';
import {useSenderReceiversComponent} from "~/cabinet/vue/client/sender/send/receiverSelect/useSenderReceiversComponent";
import {computed, ref, watch} from "vue";
import SenderReceiverFilters from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverFilters.vue";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "SenderReceiversBlock"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    formParams: {},
    contacts: {},
    filters: {},
    groups: {},
    contactBookDataSourceList: {},
    stopGroups: {},
    stopListSaveable: { type: Boolean }
  }, {
    "isReceiversSelectVisible": { type: Boolean, ...{
    required: true
} },
    "isReceiversSelectVisibleModifiers": {},
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update:is-receivers-select-visible"], ["update:isReceiversSelectVisible", "update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let isReceiversSelectVisible = _useModel<boolean>(__props, "isReceiversSelectVisible");

let emits = __emit;

let _modelValue = _useModel<SenderFormReceiversInterface>(__props, "modelValue");
let model = useSenderReceiversComponent(_modelValue);


function checkDataSource() {
    if (props.contactBookDataSourceList.length > 0) {
        if (!props.contactBookDataSourceList.find(item => item.id == model.value.contactbookDataSourceId)) {
            model.value.contactbookDataSourceId = props.contactBookDataSourceList[0].id;
        }
    }
}

checkDataSource();

watch(computed(() => model.value.contactbookDataSourceId), () => {
    checkDataSource();
})

let isStopListChanged = ref(false);

let receiversSelected = computed(() => {
    return (model.value.contacts.length + model.value.groups.length) > 0;
});

function onStopListChange() {
    isStopListChanged.value = true;
}

async function saveStopList() {
    isStopListChanged.value = false;

    let result = await LcabApiRequest.fetch({
        url: `/api/sender/saveStopList`,
        p: {
            stopList: model.value.stopListGroupIds
        },
        silent: true
    });
    if (!result.isSuccess) {
        result.showMessage();
        isStopListChanged.value = true;
    }
}

let isSomeContactsSelected = computed(() => {
    return model.value.groups.length || model.value.contacts.length || model.value.otherRecipientsId.length;
});

let sortedGroups = computed(() => {
    return [...props.stopGroups].sort(SenderReceiverSelectHelper.groupSortMethod);
});

let usluga = computed(() => {
    return SenderUslugi[props.uslugaId];
});

let otherRecipientsPlaceholder = computed(() => {
    return usluga.value?.otherRecipientPlaceholder;
});

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(ElCardHeader, {
        title: _unref(__)('Получатели')
      }, null, 8, ["title"]),
      (_ctx.contactBookDataSourceList.length > 1)
        ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _unref(model).contactbookDataSourceId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).contactbookDataSourceId) = $event)),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactBookDataSourceList, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.id,
                      label: item.descr,
                      value: item.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(ElCardSection, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            class: "bordered",
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              (_unref(receiversSelected))
                ? (_openBlock(), _createBlock(SenderReceiverFilters, {
                    key: 0,
                    modelValue: _unref(model).filters,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).filters) = $event)),
                    "usluga-id": _ctx.uslugaId,
                    "group-ids": _unref(model).groups,
                    "contactbook-data-source-id": _unref(model).contactbookDataSourceId
                  }, null, 8, ["modelValue", "usluga-id", "group-ids", "contactbook-data-source-id"]))
                : _createCommentVNode("", true),
              (_ctx.formParams.receiversContacts || _ctx.formParams.receiversGroups)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_unref(receiversSelected))
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          class: "without-border"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(SenderReceiversPreview, {
                              "usluga-id": _ctx.uslugaId,
                              form: _ctx.formParams,
                              "show-groups": _ctx.formParams.receiversGroups,
                              "show-contacts": _ctx.formParams.receiversContacts,
                              recipients: _unref(model),
                              groups: _ctx.groups,
                              contacts: _ctx.contacts
                            }, null, 8, ["usluga-id", "form", "show-groups", "show-contacts", "recipients", "groups", "contacts"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_form_item, {
                      class: _normalizeClass(!_unref(receiversSelected) ? 'without-border' : null)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(MyFlipContainer, {
                          modelValue: _unref(isReceiversSelectVisible),
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(isReceiversSelectVisible) ? (isReceiversSelectVisible).value = $event : isReceiversSelectVisible = $event)),
                          vertical: ""
                        }, {
                          front: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", null, [
                                _createVNode(_component_el_button, {
                                  plain: _unref(receiversSelected),
                                  class: "w-100 d-block",
                                  round: "",
                                  type: "success",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('update:is-receivers-select-visible', true)))
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(FontAwesomeIcon, {
                                      icon: "user-plus",
                                      light: ""
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _createTextVNode(" " + _toDisplayString(_unref(receiversSelected) ? _unref(__)("Добавить ещё") : _unref(__)("Добавить получателей")), 1)
                                  ]),
                                  _: 1
                                }, 8, ["plain"])
                              ])
                            ])
                          ]),
                          back: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_el_button, {
                                class: "w-100 d-block",
                                disabled: "",
                                plain: "",
                                round: "",
                                style: {"cursor":"default"}
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(FontAwesomeIcon, {
                                    icon: "arrow-left",
                                    light: ""
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createTextVNode(" " + _toDisplayString(_unref(__)("Выберите получателей")), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["class"]),
                    (!_unref(receiversSelected) && _ctx.formParams.receiversOtherFromString)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 1,
                          class: "text-center without-border"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(__)('Или')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.formParams.receiversOtherFromString)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 2,
                    label: _unref(receiversSelected) ? _unref(__)('Другие получатели') : null,
                    class: _normalizeClass(_unref(receiversSelected) ? null : 'without-border')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _unref(model).otherRecipientsId,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).otherRecipientsId) = $event)),
                        type: "textarea",
                        autosize: { minRows: 1, maxRows: 10},
                        placeholder: _unref(otherRecipientsPlaceholder)
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label", "class"]))
                : _createCommentVNode("", true),
              (_unref(isSomeContactsSelected) && _ctx.formParams.receiversStop)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createVNode(_component_el_form_item, null, {
                      label: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: _unref(model).stopListEnabled,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).stopListEnabled) = $event)),
                          class: "mr-2"
                        }, null, 8, ["modelValue"]),
                        _createTextVNode(" " + _toDisplayString(_unref(__)('Стоп-лист')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          (_unref(model).stopListEnabled)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_el_select, {
                                  modelValue: _unref(model).stopListGroupIds,
                                  "onUpdate:modelValue": [
                                    _cache[6] || (_cache[6] = ($event: any) => ((_unref(model).stopListGroupIds) = $event)),
                                    onStopListChange
                                  ],
                                  class: "w-100",
                                  multiple: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedGroups), (group) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: group.id,
                                        label: group.descr,
                                        value: group.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(FontAwesomeIcon, {
                                            color: group.color ? group.color : 'muted',
                                            class: "mr-1",
                                            icon: "folder"
                                          }, null, 8, ["color"]),
                                          _createTextVNode(" " + _toDisplayString(group.descr), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]),
                                (_unref(isStopListChanged) && _ctx.stopListSaveable)
                                  ? (_openBlock(), _createBlock(_component_el_button, {
                                      key: 0,
                                      class: "mt-2 d-block w-100",
                                      round: "",
                                      onClick: saveStopList
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(__)("Запомнить стоп-лист")), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }),
                    (_ctx.formParams.receiversLimit)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          "label-width": "0px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_switch, {
                              modelValue: _unref(model).limit.enabled,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).limit.enabled) = $event)),
                              class: "mr-2"
                            }, null, 8, ["modelValue"]),
                            _cache[15] || (_cache[15] = _createTextVNode(" Лимит получателей ")),
                            (_unref(model).limit.enabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _cache[14] || (_cache[14] = _createElementVNode("p", { class: "lh-33" }, " Укажите порядковые номера первого и последнего получателей ", -1)),
                                  _createVNode(_component_el_row, { tag: "p" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_col, { span: 10 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_input_number, {
                                            modelValue: _unref(model).limit.first,
                                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).limit.first) = $event)),
                                            controls: false,
                                            min: 1,
                                            class: "w-100"
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, {
                                        span: 4,
                                        class: "text-center"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(FontAwesomeIcon, { icon: "ellipsis-h" })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_el_col, { span: 10 }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_input_number, {
                                            modelValue: _unref(model).limit.last,
                                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(model).limit.last) = $event)),
                                            controls: false,
                                            min: 1,
                                            class: "w-100"
                                          }, null, 8, ["modelValue"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("p", _hoisted_3, [
                                    _createVNode(_component_el_radio, {
                                      modelValue: _unref(model).limit.random,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(model).limit.random) = $event)),
                                      value: false
                                    }, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode(" Выбирать абонентов по порядку ")
                                      ])),
                                      _: 1
                                    }, 8, ["modelValue"]),
                                    _createVNode(_component_el_radio, {
                                      modelValue: _unref(model).limit.random,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(model).limit.random) = $event)),
                                      value: true
                                    }, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode(" Выбирать абонентов случайно ")
                                      ])),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})