import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "pl-2" }

import {computed, ref} from 'vue';
import MySelect from "~/cabinet/vue/interface/form/MySelect.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import TagInterface from "~/cabinet/vue/interface/form/tags/TagInterface";
import Random from "~/ts/library/Random";
import TagsConfigDialog from "~/cabinet/vue/interface/form/tags/TagsConfigDialog.vue";


const __default__ = {
    name: "TagSelector"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    tagType: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<string[]>(__props, "modelValue");

let configDialogVisible = ref(false);

let selectKey = ref<any>();

let remoteUrl = computed(() => {
    return `/api/ui/tag/${props.tagType}/getTags`;
});

function showConfigDialog() {
    configDialogVisible.value = true;
}

function changeSelectKey() {
    selectKey.value = Random.uid();
}
changeSelectKey();

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(MySelect, {
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      class: "w-100 flex-fill",
      multiple: "",
      filterable: "",
      "allow-create": "",
      "default-first-option": "",
      placeholder: "Выберите тэги",
      "remote-url": _unref(remoteUrl),
      key: _unref(selectKey)
    }, null, 8, ["modelValue", "remote-url"])),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, { onClick: showConfigDialog }, {
        default: _withCtx(() => [
          _createVNode(FontAwesomeIcon, { icon: "cogs" })
        ]),
        _: 1
      }),
      _createVNode(TagsConfigDialog, {
        visible: _unref(configDialogVisible),
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(configDialogVisible) ? (configDialogVisible).value = $event : configDialogVisible = $event)),
        "tag-type-id": _unref(props).tagType,
        onUpdate: _cache[2] || (_cache[2] = ($event: any) => (changeSelectKey()))
      }, null, 8, ["visible", "tag-type-id"])
    ])
  ]))
}
}

})