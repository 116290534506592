import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


const __default__ = {
    name: "MyFilterPreviewTagTemplate"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    editable: { type: Boolean },
    type: { default: "info" },
    effect: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createBlock(_component_el_tag, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('close'))),
    type: _ctx.type,
    size: "small",
    closable: _ctx.editable,
    class: _normalizeClass(['text-truncate', 'item', _ctx.editable ? 'closable' : '']),
    effect: _ctx.effect
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["type", "closable", "class", "effect"]))
}
}

})