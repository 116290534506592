import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {computed, nextTick, onMounted} from "vue";
import MyTimePicker from "~/core-ui/vue/ui/MyTimePicker.vue";


const __default__ = {
    name: "MyFilterTime"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps(),
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let model = _useModel<Date | Date[] | string | string[]>(__props, "modelValue");

function onInput(value: Date | Date[] | string | string[]) {
    model.value = value;
}

onMounted(() => {
    let time = getFormattedValue();
    nextTick(() => {
        onInput(time);
    });
});


function getFormattedValue() {
    let result = model.value;
    if (!model.value) {
        result = "00:00:00";
        if (props.index == 1) {
            result = "23:59:59";
        }
    }
    return result;
}

let formattedValue = computed(() => {
    return getFormattedValue();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyTimePicker, {
    "model-value": _unref(formattedValue),
    "onUpdate:modelValue": onInput,
    clearable: false,
    placeholder: _ctx.component.filter.placeholder
  }, null, 8, ["model-value", "placeholder"]))
}
}

})