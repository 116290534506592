import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, isRef as _isRef } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]

import {computed, ref} from 'vue';
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import AbstractUploadHelper from "~/ts/library/AbstractUploadHelper";
import {__} from "~/ts/library/Translate";
import {UploadInstance} from "element-plus";


const __default__ = {
    name: "UploadImage"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    action: {},
    crop: { type: Boolean },
    aspectRatio: {},
    minWidth: {},
    minHeight: {},
    maxHeight: {},
    maxWidth: {},
    previewWidth: { default: 178 },
    format: { default: "jpeg" }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<string>(__props, "modelValue");

let cropper = ref<any>();
let uploader = ref<UploadInstance>();

let previewSrc = ref<any>();
let showCropper = ref(false);

let previewImgStyle = computed(() => {
    let width = props.previewWidth;
    let height = props.aspectRatio ? width / props.aspectRatio : width;
    return {
        width: `${width}px`,
        height: `${height}px`,
        lineHeight: `${height}px`
    };
});

let previewImgSrc = computed(() => {
    if (model.value != null) {
        return isUrlInImgSrc.value ? url.value : `data:image/${props.format};base64,${model.value}`;
    }
    return null;
});

let url = computed(() => {
    if (model.value != null) {
        if (model.value.toLowerCase().indexOf("http") == 0) {
            return model.value;
        } else if (props.crop) {
            try {
                atob(model.value);
            } catch (e) {
                return AbstractUploadHelper.getUrlById(model.value);
            }
        } else {
            return AbstractUploadHelper.getUrlById(model.value);
        }
    }
    return null;
});

let isUrlInImgSrc = computed(() => !!url.value);

function handleAvatarSuccess(res: any) {
    let result = new LcabApiResult(res);
    if (result.isSuccess) {
        let id = result.getData("id");
        if (id) {
            model.value = id;
        }
    }
}

function beforeAvatarUpload() {
    return true;
}

function onSelectedFileChange(file: { raw: File }) {
    if (props.crop) {
        var reader = new FileReader();
        reader.readAsDataURL(file.raw.slice());
        reader.onloadend = () => {
            previewSrc.value = reader.result;
            if (previewSrc.value) {
                showCropper.value = true;
                if (cropper.value) {
                    cropper.value.replace(previewSrc.value);

                    cropper.value.initCrop();
                }
            }
        }
    } else {
        uploader.value.submit();
    }
}

function makeCrop() {
    let value = cropper.value.getCroppedCanvas({
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
        fillColor: props.format == 'png' ? undefined : "#ffffff"
    }).toDataURL(`image/${props.format}`);
    value = value.split(";base64,")[1];
    model.value = value;

    previewSrc.value = null;
    showCropper.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_upload, _mergeProps(_ctx.$attrs, {
      ref_key: "uploader",
      ref: uploader,
      accept: "image/*",
      class: "avatar-uploader",
      "auto-upload": false,
      action: _ctx.action,
      "show-file-list": false,
      "on-success": handleAvatarSuccess,
      "before-upload": beforeAvatarUpload,
      "on-change": onSelectedFileChange
    }), {
      default: _withCtx(() => [
        (_unref(previewImgSrc))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("img", {
                src: _unref(previewImgSrc),
                style: _normalizeStyle(_unref(previewImgStyle)),
                class: "avatar"
              }, null, 12, _hoisted_1),
              (_unref(isUrlInImgSrc))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("a", {
                      href: _unref(previewImgSrc),
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                      target: "_blank",
                      class: "link-no-underline"
                    }, [
                      _createVNode(_component_el_button, {
                        link: "",
                        class: "d-block w-100"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(__)("Скачать")), 1)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_3)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(FontAwesomeIcon, {
              key: 1,
              icon: "plus",
              light: "",
              class: "avatar-uploader-icon",
              style: _normalizeStyle(_unref(previewImgStyle))
            }, null, 8, ["style"]))
      ]),
      _: 1
    }, 16, ["action"]),
    _createVNode(MyDialog, {
      visible: _unref(showCropper),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(showCropper) ? (showCropper).value = $event : showCropper = $event)),
      dialog: {
                    class: 'left overflow',
                    appendToBody: true
                },
      title: _unref(__)('Изображение'),
      onApply: makeCrop,
      onCancel: _cache[2] || (_cache[2] = ($event: any) => (_isRef(previewSrc) //@ts-ignore
 ? previewSrc.value = null : previewSrc = null)),
      "button-text": _unref(__)('Обрезать изображение')
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(VueCropper), {
          ref_key: "cropper",
          ref: cropper,
          src: _unref(previewSrc),
          alt: "Source Image",
          guides: false,
          "view-mode": 2,
          "drag-mode": "move",
          "min-container-width": 250,
          "min-container-height": 180,
          background: true,
          "auto-crop-area": 1,
          "aspect-ratio": _ctx.aspectRatio
        }, null, 8, ["src", "aspect-ratio"])
      ]),
      _: 1
    }, 8, ["visible", "title", "button-text"])
  ]))
}
}

})