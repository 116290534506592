import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "sender-preview" }
const _hoisted_2 = { class: "mr-1" }

import {computed, nextTick, onMounted, ref, watch} from 'vue';
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";


const __default__ = {
    name: "SenderReceiverPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    descr: {},
    lineThrough: { type: Boolean },
    count: {}
  },
  emits: ["delete"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let countSpan = ref<HTMLElement>();

let paddingRight = ref<string>();

watch(computed(() => props.count), () => {
    nextTick(() => {
        updatePaddingRight();
    });
})

function updatePaddingRight() {
    let result: string;
    if (props.count != null) {
        let el = countSpan.value;
        if (el) {
            result = (el.offsetWidth + 7) + "px";
        }
    }
    paddingRight.value = result;
}

onMounted(() => updatePaddingRight());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyIconButton, {
      delete: "",
      class: "float-right ml-2 no-border",
      "near-text": "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('delete')))
    }),
    _createElementVNode("div", {
      style: _normalizeStyle({paddingRight: _unref(paddingRight)}),
      class: "text-truncate"
    }, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon")
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.lineThrough ? 'excluded' : '')
      }, _toDisplayString(_ctx.descr), 3),
      (_ctx.count != null)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            ref_key: "countSpan",
            ref: countSpan,
            class: "count"
          }, _toDisplayString(_ctx.count), 513))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}
}

})