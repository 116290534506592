import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "status-tag" }
const _hoisted_2 = {
  key: 0,
  class: "text-muted font-size-mini"
}

import {localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "StatusTag"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    descr: {},
    color: {},
    dateTime: {},
    customClass: {},
    effect: { default: 'plain' }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tag, {
      effect: _ctx.effect && _ctx.effect.length ? _ctx.effect : undefined,
      class: _normalizeClass([`text-${_ctx.color}`, `border-${_ctx.color}`, _ctx.customClass]),
      title: _ctx.descr,
      size: "large"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.descr), 1)
      ]),
      _: 1
    }, 8, ["effect", "class", "title"]),
    (_ctx.dateTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(localeDateTimeString)(_ctx.dateTime)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})