import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {IHiddenField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, watch} from "vue";


const __default__ = {
    name: "HiddenElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<IHiddenField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");


let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

function emitValue() {
    model.value = valueFromField.value;
}

let valueFromField = computed(() => props.field.value);

emitValue();

watch(valueFromField, () => emitValue());
watch(computed(() => props.field), () => emitValue(), {deep: true});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})