import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }


const __default__ = {
    name: "ElCardSectionWithIconText"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    grayLabel: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.heading)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_1, [
          _renderSlot(_ctx.$slots, "heading")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass({'gray-label': _ctx.grayLabel})
        }, [
          _renderSlot(_ctx.$slots, "label")
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})