import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import {
    CrmSendMessageActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";

interface ResponseInterface {
    form?: IForm,
    availableBbCodes: string[]
}


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "CrmSendMessageActionBlockForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<CrmSendMessageActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let model = _useModel<getActionDescriptionModelType<CrmSendMessageActionDescription>>(__props, "modelValue");

let frontendParams = props.actionDescription.frontendParams;

let request = computed(() => {
    return new ReactiveRequest<ResponseInterface>(new LcabApiRequest({
        url: `/api/events/dataProvider/getFrontendParamsForSendMessageAction`,
        p: {
            dataProviderPrimaryId: props.actionDescription.authPrimaryId,
            dataProviderPlatformId: props.actionDescription.platformId,
            isFromClient: props.actionDescription.frontendParams.isFromClient
        }
    }));
});


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(request).data.form)
              ? (_openBlock(), _createBlock(MyForm, {
                  key: 0,
                  form: _unref(request).data.form,
                  modelValue: _unref(model).additionalParams,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).additionalParams) = $event)),
                  "content-variables": _unref(props).contentVariables
                }, null, 8, ["form", "modelValue", "content-variables"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, { label: "Текст сообщения" }, {
              default: _withCtx(() => [
                _createVNode(TextInputWithVariables, {
                  "content-variables": _unref(props).contentVariables,
                  modelValue: _unref(model).text,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).text) = $event)),
                  bbcode: "",
                  "available-bbcode": _unref(request).data.availableBbCodes
                }, null, 8, ["content-variables", "modelValue", "available-bbcode"])
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})