import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef } from "vue"

import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import OpenApiSchemaViewer from "~/cabinet/vue/interface/OpenApiSchemaViewer.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {computed} from "vue";

interface RequestResultInterface {
    schema: any
}

const __default__ = {
    name: "WebhookSchemeDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    platformId: {},
    authPrimaryId: {},
    webhookId: {},
    webhookDescr: {},
    oldDataStyle: { type: Boolean, default: false },
    dataOnly: { type: Boolean }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

let props = __props;

let visible = _useModel<boolean>(__props, "visible");

let request = computed(() => {
    return new ReactiveRequest<RequestResultInterface>(new LcabApiRequest({
        url: `/api/cabinet/webhooks/${props.platformId}/${props.authPrimaryId}/${props.webhookId}/getScheme`,
        p: {
            oldDataStyle: props.oldDataStyle,
            dataOnly: props.dataOnly
        }
    }));
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyDialog, {
    dialog: {
            class: 'right overflow w-75 no-padding'
        },
    title: `Схема Webhook-а ${_ctx.webhookDescr}`,
    visible: _unref(visible),
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    "button-text": null
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createBlock(OpenApiSchemaViewer, {
                key: 0,
                schema: _unref(request).data.schema
              }, null, 8, ["schema"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }, 8, ["title", "visible"]))
}
}

})