import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {computed, ref} from 'vue';
import {ResizeEvent} from "~/cabinet/vue/interface/draggableResizable/ResizeEvent";
import DomHelper from "~/ts/library/DomHelper";


const __default__ = {
    name: "ResizeHelper"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    n: { type: Boolean },
    s: { type: Boolean },
    e: { type: Boolean },
    w: { type: Boolean }
  },
  emits: ["drag"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let active = ref(false);

let x = ref<number>();
let y = ref<number>();

let className = computed(() => {
    return {
        "resize-helper": true,
        "top-z-index": true,
        n: props.n,
        s: props.s,
        w: props.w,
        e: props.e,
        active: active.value
    };
});

function onDragStart(e: PointerEvent) {
    x.value = null;
    y.value = null;
    active.value = true;
    DomHelper.setPointerCapture(e.target as HTMLElement, e.pointerId != null ? e.pointerId : 1);
}

function onDragStop(e: PointerEvent) {
    active.value = false;
    DomHelper.releasePointerCapturer(e.target as HTMLElement, e.pointerId != null ? e.pointerId : 1);

}

function onDrag(e: MouseEvent) {
    if (active.value) {
        let newX = e.clientX;
        let newY = e.clientY;
        if (x.value != null) {
            let deltaX = (props.w || props.e) ? newX - x.value : 0;
            let deltaY = (props.n || props.s) ? newY - y.value : 0;
            let event: ResizeEvent = {
                deltaX: deltaX,
                deltaY: deltaY,
                w: props.w,
                e: props.e,
                n: props.n,
                s: props.s
            };
            emits("drag", event);
        }
        x.value = newX;
        y.value = newY;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(className)),
    onPointerdown: _withModifiers(onDragStart, ["stop"]),
    onPointerup: _withModifiers(onDragStop, ["stop"]),
    onPointermove: _withModifiers(onDrag, ["stop"])
  }, null, 34))
}
}

})