import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {IPagerContentFormValue} from "~/cabinet/vue/client/sender/pager/Interfaces";
import {
    USLUGA_ID_CASCADE,
    USLUGA_ID_MAILRU,
    USLUGA_ID_MESSENGER,
    USLUGA_ID_PAGER,
    USLUGA_ID_VIBER,
    USLUGA_ID_VOICE,
    USLUGA_ID_WHATSAPP
} from "~/cabinet/ts/Constant";
import {
    IVoiceContentFormValue,
    IVoiceTextContentFormValue,
    VOICE_CONTENT_TYPE_FILE,
    VOICE_CONTENT_TYPE_ORDER,
    VOICE_CONTENT_TYPE_TEXT
} from "~/cabinet/vue/client/sender/voice/Interfaces";
import {
    IViberContentFormValue,
    IViberTextContentFormValue,
    IViberTextImageButtonContentFormValue,
    IViberTextWithButtonContentFormValue,
    VIBER_CONTENT_TYPE_IMAGE,
    VIBER_CONTENT_TYPE_TEXT,
    VIBER_CONTENT_TYPE_TEXT_BUTTON,
    VIBER_CONTENT_TYPE_TEXT_IMAGE_BUTTON
} from "~/cabinet/vue/client/sender/viber/Interfaces";
import {IMailRuContentFormValue} from "~/cabinet/vue/client/sender/mailru/Interfaces";
import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {SenderActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    ICascadeContentFormValue
} from "~/cabinet/vue/client/events/action/types/Interface";
import {
    IMessengerContentFormValue,
    IMessengerTextContentFormValue,
    MESSENGER_CONTENT_TYPE_TEXT
} from "~/cabinet/vue/client/sender/messenger/Interfaces";
import {
    IWhatsappContentFormValue,
    IWhatsappTextContentFormValue,
    WHATSAPP_CONTENT_TYPE_TEXT
} from "~/cabinet/vue/client/sender/whatsapp/Interfaces";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed, ref} from "vue";


const __default__ = {
    name: "SenderBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<SenderActionDescription>()
    },
  setup(__props) {

let props = __props;

let cascadeSchemeDescr = ref<{
    id: string,
    descr: string,
    error: boolean
}>();

let errorText = ref(false);

let text = computed(() => {
    errorText.value = false;
    let uslugaId = props.actionDescription.frontendParams.uslugaId;

    let text = "Отправка сообщения";
    let content = props.params.senderFormParam.content;
    if (props.params.senderFormParam.isUseStandartContent && props.actionDescription.frontendParams.standartContentParams) {
        content = props.actionDescription.frontendParams.standartContentParams
    }
    if (content) {
        if (uslugaId == USLUGA_ID_PAGER) {
            let params: IPagerContentFormValue = content;
            text = params.data ? params.data.text : null;
        } else if (uslugaId == USLUGA_ID_VOICE) {
            let params: IVoiceContentFormValue = content;
            let type = params.type;
            if (type == VOICE_CONTENT_TYPE_TEXT) {
                let data = params.data as IVoiceTextContentFormValue;
                text = data ? data.text : null;
            } else if (type == VOICE_CONTENT_TYPE_FILE) {
                text = "Свой аудио-файл";
            } else if (type == VOICE_CONTENT_TYPE_ORDER) {
                text = "Заказанная озвучка";
            }
        } else if (uslugaId == USLUGA_ID_VIBER) {
            let params: IViberContentFormValue = content;
            let type = params.type;
            if (type == VIBER_CONTENT_TYPE_TEXT) {
                let data = params.data as IViberTextContentFormValue;
                text = data ? data.text.text : null;
            } else if (type == VIBER_CONTENT_TYPE_IMAGE) {
                text = "Изображение";
            } else if (type == VIBER_CONTENT_TYPE_TEXT_BUTTON) {
                let data = params.data as IViberTextWithButtonContentFormValue;
                text = data ? data.text.text : null;
            } else if (type == VIBER_CONTENT_TYPE_TEXT_IMAGE_BUTTON) {
                let data = params.data as IViberTextImageButtonContentFormValue;
                text = data ? data.text.text : null;
            }
        } else if (uslugaId == USLUGA_ID_MAILRU) {
            let params: IMailRuContentFormValue = content;
            text = params.data ? params.data.text : null;
        } else if (uslugaId == USLUGA_ID_MESSENGER) {
            let params: IMessengerContentFormValue = content;
            let type = params.type;
            if (type == MESSENGER_CONTENT_TYPE_TEXT) {
                let data = params.data as IMessengerTextContentFormValue;
                text = data ? data.text : null;
            }
            text = text?.toString();
        } else if (uslugaId == USLUGA_ID_WHATSAPP) {
            let params: IWhatsappContentFormValue = content;
            let type = params.type;
            if (type == WHATSAPP_CONTENT_TYPE_TEXT) {
                let data = params.data as IWhatsappTextContentFormValue;
                text = data ? data.text.text : null;
            }
        } else if (uslugaId == USLUGA_ID_CASCADE) {
            let params: ICascadeContentFormValue = content;
            if (params.schemeId) {
                if (cascadeSchemeDescr.value && cascadeSchemeDescr.value.id == params.schemeId) {
                    text = cascadeSchemeDescr.value.descr;
                    errorText.value = cascadeSchemeDescr.value.error;
                } else {
                    text = "Загрузка...";
                    (async (schemeId: string) => {
                        let result = await (new LcabApiRequest({
                            url: `/api/sender/cascade/getSchemeDescr/${schemeId}/${props.eventParamsByAuth.platform.id}/${props.eventParamsByAuth.authPrimaryId}`,
                            silent: true
                        })).send();
                        let descr = result.isSuccess ? result.descr : "Сценарий не найден!"
                        if (!result.isSuccess) {

                            errorText.value = true;
                        }
                        cascadeSchemeDescr.value = {
                            id: schemeId,
                            descr,
                            error: !result.isSuccess
                        };
                    })(params.schemeId);
                }
            } else {
                errorText.value = true;
                text = "Сценарий не выбрана!";
            }
        }
    } else {
        text = null;
    }

    if (!text) {
        errorText.value = true;
        text = "Сообщение не задано!";
    }

    if (text.length > 100) {
        text = text.substring(0, 200) + "...";
    }

    return text;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'text-danger': _unref(errorText)
        })
  }, [
    _createVNode(InputWithVariablesPreview, {
      text: _unref(text),
      variables: _ctx.contentVariables,
      bbcode: ""
    }, null, 8, ["text", "variables"])
  ], 2))
}
}

})