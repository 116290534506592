import {inject, PropType} from "vue";
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {HtmlConstructorEditorInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";

export function useContentDisplayProps<AdditionalEditorTypeParams>() {
    return {
        modelValue: {
            type: Object as PropType<IHtmlConstructorEditorModel<AdditionalEditorTypeParams>>
        }
    }
}

export function useContentDisplay() {
    let htmlConstructorEditor = inject(HtmlConstructorEditorInject);

    return {
        htmlConstructorEditor
    }
}