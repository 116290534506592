import AbstractEntity, {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import {computed, getCurrentInstance, ModelRef, Ref, ref, watch} from "vue";


export default function useObjectModel<T>(
    modelFromComponent: ModelRef<T>/* | Readonly<ExtractPropTypes<ReturnType<typeof propFunc<T>>>>*/,
    getDefaultValue?: () => T,
    initializeCallback?: (model: Ref<T>) => void,
    abstractEntityBuilder?: AbstractEntityBuilder<any>
) {
    let model: Ref<T> = ref<T>();

    if (!getDefaultValue) {
        getDefaultValue = () => undefined;
    }
    let instance = getCurrentInstance().proxy;

    function initializeModel() {
        let changed = true;
        if (modelFromComponent.value == null) {
            model.value = getDefaultValue();
        } else {
            let defaultValue = getDefaultValue();
            changed = false;
            for (let key in defaultValue) {
                if (defaultValue.hasOwnProperty(key) && !modelFromComponent.value.hasOwnProperty(key)) {
                    model.value = {...getDefaultValue() as any, ...modelFromComponent.value as any};
                    changed = true;
                    break;
                }
            }
            if (!changed) {
                model.value = modelFromComponent.value/* as T*/;
            }
        }

        if (abstractEntityBuilder && model.value && !(model.value instanceof (abstractEntityBuilder as typeof AbstractEntity))) {
            model.value = abstractEntityBuilder.new(model.value);
            changed = true;
        }

        if (changed) {
            modelFromComponent.value = model.value;
            //instance.$emit("input", model.value);
        }

        if (initializeCallback) {
            initializeCallback(model);
        }
    }

    initializeModel();

    watch(computed(() => modelFromComponent.value), () => {
        if (modelFromComponent.value != model.value) {
            initializeModel();
        }
    }, {deep: true});

    return computed<T>({
        get(): T {
            return model.value;
        },
        set(value: T) {
            model.value = value;
            modelFromComponent.value = value;
        }
    });
}