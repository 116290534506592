import {IRouteMeta, RouteAccess} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {__} from "~/ts/library/Translate";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import UslugaRouteBuilder from "~/cabinet/ts/routes/UslugaRouteBuilder";
import {ACCESS_TEST} from "~/cabinet/ts/data/organization/IUserAccess";
import Dictionary from "~/ts/library/Dictionary";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import UslugaMapper from "~/cabinet/ts/data/usluga/Uslugi";
import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import {DEFAULT_SENDER_FORM_ID} from "~/cabinet/vue/client/sender/send/Interfaces";
import {RouteLocationNamedRaw, RouteLocationRaw, RouteRecordRaw} from "vue-router";

// const SENDER_ROUTE_GROUPS_LINK = "groups";
//const SENDER_ROUTE_CONFIRM_LINK = "confirm";

const ROUTING_ROOT = "ROUTING_ROOT";
const ROUTING_SEND = "ROUTING_SEND";
const ROUTING_MESSENGER_LINK = "ROUTING_MESSENGER_LINK";

const ROUTING_SEND_CREATE = "ROUTING_SEND_CREATE";
const ROUTING_SEND_GRATS = "ROUTING_SEND_GRATS";
const ROUTING_SEND_SCHEDULER = "ROUTING_SEND_SCHEDULER";

//const ROUTING_SEND_CRON = "ROUTING_SEND_CRON";
const ROUTING_REPORT = "ROUTING_REPORT";
const ROUTING_REPORT_SENT = "ROUTING_REPORT_SENT";
const ROUTING_REPORT_WAIT = "ROUTING_REPORT_WAIT";
const ROUTING_REPORT_REPLY = "ROUTING_REPORT_REPLY";
const ROUTING_REPORT_SENT_DETAIL = "ROUTING_REPORT_SENT_DETAIL";
const ROUTING_REPORT_ALL_MESSAGES = "ROUTING_REPORT_ALL_MESSAGES";
const ROUTING_REPORT_UNSUBSRIBE = "ROUTING_REPORT_UNSUBSRIBE";
const ROUTING_REPORT_CHARTS = "ROUTING_REPORT_CHARTS";


export const CREATE_ROUTE_REPEAT_KEY = "repeat";
export const CREATE_ROUTE_REPEAT_TS_KEY = "t";

let initDataDone: Dictionary<boolean> = {};

export default class SenderRouteBuilder extends UslugaRouteBuilder {
    usluga: SenderUsluga;

    public static getServiceTemplateRouteName(uslugaId: string): string {
        return "SENDER_SERVICE_TEMPLATES_" + uslugaId;
    }

    public static initData(uslugaId: string) {
        if (!initDataDone[uslugaId]) {
            initDataDone[uslugaId] = true;
            (new LcabApiRequest({
                url: "/api/sender/" + uslugaId + "/initData"
            })).send().then(result => {
                if (result.isSuccess) {
                    SenderUslugi[uslugaId].store.setInitialUslugaData(result.getData());
                } else {
                    initDataDone[uslugaId] = false
                }
            }).catch(() => initDataDone[uslugaId] = false);
        }
    }

    public sendRoute?: RouteRecordRaw;
    public sendMenu?: MenuItem;

    public reportRoute?: RouteRecordRaw;
    public reportMenu?: MenuItem;

    public getReportRouteName(): string {
        return SenderRouteBuilder.getReportRouteNameByUsluga(this.usluga.id);
        //return this.getRouteName(ROUTING_REPORT);
    }

    public static getReportRouteNameByUsluga(uslugaId: string): string {
        return UslugaRouteBuilder.getRouteName(uslugaId, ROUTING_REPORT);
    }

    get sendAccess() {
        let uslugaId = this.usluga.id;
        let access: RouteAccess = [
            () => AccountStore.access.value.checkAccessForSenderSend(uslugaId)
        ];
        if (uslugaId == "email") {
            access.push(ACCESS_TEST);
        }
        return access;
    }

    makeRoute(): RouteRecordRaw {
        //let enterFirstTime = true;
        let uslugaId = this.usluga.id;

        this.sendRoute = {
            path: "send",
            name: this.getRouteName(ROUTING_SEND),
            meta: {
                access: this.sendAccess
            } as IRouteMeta,
            children: [
                this.getCreateRouteConfig(),
                {
                    path: "messengerLink",
                    name: this.getRouteName(ROUTING_MESSENGER_LINK),
                    component: () => import(/* webpackChunkName: "client-sender-messenger-link" */ '~/cabinet/vue/client/sender/messengerLink/MessengerLink.vue')
                }
            ]
        };

        this.sendMenu = (new MenuItem(this.getRouteName(ROUTING_SEND), "Отправить"))
            .setIcon("envelope")
            .addChildrenItem(new MenuItem(this.getSendCreateRouteName(), "Отправить сообщение"))
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_MESSENGER_LINK), "Ссылки на мессенджеры"));

        let sender = this.getSender();


        if (sender.rules) {
            let routeName = SenderRouteBuilder.getRulesRouteName(uslugaId);
            this.sendRoute.children.push({
                path: "rules",
                name: routeName,
                component: sender.rules
            });

            this.sendMenu.addChildrenItem(
                new MenuItem(routeName, "Правила сервиса")
            )
        }


        let result = {
            path: "/" + this.usluga.id,
            name: this.getRouteName(ROUTING_ROOT),
            beforeEnter: function (to, from, next) {
                SenderRouteBuilder.initData(uslugaId);
                next();
            },
            children: [
                this.sendRoute,
                this.getGratsRouteConfig(),
                this.getSchedulerRouteConfig()
            ],
            meta: {
                menu: [
                    this.sendMenu,
                    new MenuItem(this.getSendGratsRouteName(), "Поздравления и уведомления", "birthday-cake"),
                    new MenuItem(this.getSendSchedulerRouteName(), "Планировщик", "calendar-alt")

                ]
            } as IRouteMeta
        } as RouteRecordRaw;

        this.addReportRoutes(result);

        if (sender.source && sender.source.routing != null) {
            let routing = sender.source.routing;
            let routes = routing.getRoutes();
            if (!routes.meta) {
                routes.meta = {};
            }
            if (!routes.meta.access) {
                routes.meta.access = [];
            }
            ((routes.meta as IRouteMeta).access as any[]).push(
                () => AccountStore.access.value.isAdmin
            );
            result.children.push(routes);
            (result.meta as IRouteMeta).menu.push(routing.getMenu());
        }

        let moderateTemplatesRouteName = "SENDER_MODERATE_TEMPLATES_" + uslugaId;

        result.children.push({
            name: moderateTemplatesRouteName,
            path: "moderationTemplates",
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/moderation/ModerationTemplates.vue'),
            props: {
                uslugaId
            },
            meta: {
                access: [
                    "usluga_canAddModerateTemplate_" + uslugaId,
                    () => AccountStore.access.value.isAdmin
                ]
            } as IRouteMeta
        });
        (result.meta as IRouteMeta).menu.push(new MenuItem(moderateTemplatesRouteName, "Шаблоны модерации", "shield"));


        let serviceTemplatesRouteName = SenderRouteBuilder.getServiceTemplateRouteName(uslugaId);

        result.children.push({
            name: serviceTemplatesRouteName,
            path: "serviceTemplates",
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/service/ServiceTemplates.vue'),
            props: {
                uslugaId
            },
            meta: {
                access: [
                    "usluga_isAvailableMessageTemplateService_" + uslugaId,
                    () => AccountStore.access.value.isAdmin
                ]
            } as IRouteMeta
        });

        (result.meta as IRouteMeta).menu.push(new MenuItem(serviceTemplatesRouteName, "Шаблоны сервисных сообщений", "box-heart"));


        let configRouteName = "SENDER_CONFIG_" + uslugaId;
        let defaultConfigRouteName = configRouteName + "_default";

        let config: RouteRecordRaw = {
            name: configRouteName,
            path: "config",
            children: [
                {
                    name: defaultConfigRouteName,
                    path: "default",
                    props: {
                        uslugaId: this.usluga.id
                    },
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/ApiConfig.vue'),
                }
            ],
            meta: {
                access: [
                    () => AccountStore.access.value.isAdmin
                ]
            } as IRouteMeta
        };
        let configMenu = (new MenuItem(configRouteName, "Настройки", "cog"))
            .addChildrenItem(new MenuItem(defaultConfigRouteName, "Настройки по умолчанию"));
        let additionalRoute = this.usluga.additionalConfigRoutes;
        if (additionalRoute) {
            config.children.push(...additionalRoute.routes);
            for (let item of additionalRoute.menu) {
                configMenu.addChildrenItem(item);
            }
        }
        result.children.push(config);


        (result.meta as IRouteMeta).menu.push(configMenu);
        return result;
    }

    private addReportRoutes(result: RouteRecordRaw) {
        let reportRoutes: RouteRecordRaw[] = [
            this.getReportSentConfig(),
            this.getReportSentConfig(true),
            this.getReportAllMessagesConfig(),
            this.getReportSentDetailConfig(),
            this.getReportUnsubscribeConfig(),
            this.getReportChartConfig()
        ];
        this.reportRoute = {
            path: "report",
            name: this.getReportRouteName(),
            children: reportRoutes,
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForSenderReport(this.usluga.id)
                ]
            } as IRouteMeta
        };

        result.children.push(this.reportRoute);


        this.reportMenu = (new MenuItem(this.getReportRouteName(), __("Отчёты")))
            .setIcon("chart-bar")
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_SENT), __("Рассылки")))
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_WAIT), "Отложенные рассылки"))
            //.addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_WAIT), __("Отложенные Email")))
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_ALL_MESSAGES), __("Все сообщения")))
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_CHARTS), "Графики"))
            .addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_UNSUBSRIBE), __("Отписавшиеся от рассылок")));

        let sender = AccountStore.senderDictionary.value[this.usluga.id];
        if (sender && sender.sender.isAvailableReply) {
            reportRoutes.push(this.getReportReplyConfig());
            this.reportMenu.addChildrenItem(new MenuItem(this.getRouteName(ROUTING_REPORT_REPLY), __("Ответы")));
        }

        (result.meta as IRouteMeta).menu.push(this.reportMenu);
    }

    private getSender(): SenderUsluga | null {
        return UslugaMapper.find(this.usluga.id) as any;
    }

    public static getReplyRouteName(uslugaId: string): string {
        return UslugaRouteBuilder.getRouteName(uslugaId, ROUTING_REPORT_REPLY)
    }

    private getReportReplyConfig(): RouteRecordRaw {
        return {
            path: "reply",
            name: SenderRouteBuilder.getReplyRouteName(this.usluga.id),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/reply/SenderReportReply.vue'),
            props: {
                uslugaId: this.usluga.id
            }

        };
    }

    private getReportSentConfig(waiting: boolean = false): RouteRecordRaw {
        return {
            path: waiting ? "wait" : "sent",
            name: this.getRouteName(waiting ? ROUTING_REPORT_WAIT : ROUTING_REPORT_SENT),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/tasks/SenderReportTasks.vue'),
            props: {
                uslugaId: this.usluga.id,
                waiting,
                detailRouteName: this.getRouteName(ROUTING_REPORT_SENT_DETAIL)
            }
        };
    }

    private getReportSentDetailConfig(): RouteRecordRaw {
        return {
            path: "sent/detail/:id",
            name: this.getRouteName(ROUTING_REPORT_SENT_DETAIL),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/allMessages/AllMessagesTable.vue'),
            props: route => ({
                uslugaId: this.usluga.id,
                id: route.params.id,
                backButtonLocation: route.params.id ? {name: this.getRouteName(ROUTING_REPORT_SENT)} : null
            })
        };
    }

    private getReportAllMessagesConfig(): RouteRecordRaw {
        return {
            path: "allMessages",
            name: this.getRouteName(ROUTING_REPORT_ALL_MESSAGES),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/allMessages/AllMessagesTable.vue'),
            props: {
                uslugaId: this.usluga.id
            }
        };
    }

    private getReportUnsubscribeConfig(): RouteRecordRaw {
        return {
            path: "unsubscribe",
            name: this.getRouteName(ROUTING_REPORT_UNSUBSRIBE),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/unsubscribe/Unsubscribe.vue'),
            props: {
                uslugaId: this.usluga.id,
                detailRouteName: this.getRouteName(ROUTING_REPORT_SENT_DETAIL)
            }
        };
    }

    private getReportChartConfig(): RouteRecordRaw {
        return {
            path: "chart",
            name: this.getRouteName(ROUTING_REPORT_CHARTS),
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/chart/SenderChartReport.vue'),
            props: {
                uslugaId: this.usluga.id
            }
        };
    }

    public static getSendCreateRouteName(uslugaId: string) {
        return this.getRouteName(uslugaId, ROUTING_SEND_CREATE);
    }


    public static getRulesRouteName(uslugaId: string) {
        return this.getRouteName(uslugaId, ROUTING_SEND + "RULES");
    }

    protected getSendCreateRouteName() {
        return SenderRouteBuilder.getSendCreateRouteName(this.usluga.id);
    }


    private getCreateRouteConfig(): RouteRecordRaw {
        let component = () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/SenderFormRoute.vue');

        let routeName = this.getSendCreateRouteName();

        var meta: IRouteMeta = {
            keepAlive: true,
            access: [
                () => AccountStore.access.value.checkAccessForSenderSend(this.usluga.id)
            ]
        };
        return {
            path: "create",
            name: routeName,
            component: component,
            meta: meta,
            props: (route) => ({
                uslugaId: this.usluga.id,
                formId: DEFAULT_SENDER_FORM_ID,
                repeatRegisterId: route.query[CREATE_ROUTE_REPEAT_KEY],
                repeatTimeStamp: route.query[CREATE_ROUTE_REPEAT_TS_KEY],
                fixedTags: [__("Личный кабинет")]
            })
        };
    }


    protected getSendSchedulerRouteName() {
        return SenderRouteBuilder.getSendSchedulerRouteName(this.usluga.id);
    }

    public static getSendSchedulerRouteName(uslugaId: string) {
        return this.getRouteName(uslugaId, ROUTING_SEND_SCHEDULER);
    }

    private static getEditSchedulerRouteName(uslugaId: string) {
        return this.getSendSchedulerRouteName(uslugaId) + "_edit";
    }

    private getSchedulerRouteConfig(): RouteRecordRaw {
        let component = () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/scheduler/SchedulerRegisters.vue');

        let routeName = this.getSendSchedulerRouteName();

        var meta: IRouteMeta = {
            keepAlive: true,
            access: this.sendAccess
        };
        return {
            path: "scheduler",
            name: routeName,
            component: component,
            meta: meta,
            props: () => ({
                uslugaId: this.usluga.id
            }),
            children: [
                {
                    path: "edit/:id?",
                    name: SenderRouteBuilder.getEditSchedulerRouteName(this.usluga.id),
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/scheduler/EditScheduler.vue'),
                    props: (route) => ({
                        uslugaId: this.usluga.id,
                        id: route.params.id ? parseInt(route.params.id as string) : null
                    }),
                    meta: {
                        keepAlive: false
                    } as IRouteMeta
                }
            ]
        };
    }

    public static getEditSchedulerLocation(uslugaId: string, id?: number): RouteLocationNamedRaw {
        return {
            name: SenderRouteBuilder.getEditSchedulerRouteName(uslugaId),
            params: {
                id: id?.toString()
            }
        }
    }


    protected getSendGratsRouteName() {
        return SenderRouteBuilder.getSendGratsRouteName(this.usluga.id);
    }


    public static getSendGratsRouteName(uslugaId: string) {
        return this.getRouteName(uslugaId, ROUTING_SEND_GRATS);
    }

    private static getEditGratsRouteName(uslugaId: string) {
        return this.getSendGratsRouteName(uslugaId) + "_edit";
    }

    private getGratsRouteConfig(): RouteRecordRaw {
        let component = () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/grats/GratsRegisters.vue');

        let routeName = this.getSendGratsRouteName();

        var meta: IRouteMeta = {
            keepAlive: true,
            access: this.sendAccess
        };
        return {
            path: "grats",
            name: routeName,
            component: component,
            meta: meta,
            props: () => ({
                uslugaId: this.usluga.id
            }),
            children: [
                {
                    path: "edit/:id?",
                    name: SenderRouteBuilder.getEditGratsRouteName(this.usluga.id),
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/grats/EditGrats.vue'),
                    props: (route) => ({
                        uslugaId: this.usluga.id,
                        id: route.params.id ? parseInt(route.params.id as string) : null
                    }),
                    meta: {
                        keepAlive: false
                    } as IRouteMeta
                } as RouteRecordRaw
            ]
        };
    }

    public static getEditGratsLocation(uslugaId: string, id?: number): RouteLocationRaw {
        return {
            name: SenderRouteBuilder.getEditGratsRouteName(uslugaId),
            params: {
                id: id?.toString()
            }
        }
    }

}
