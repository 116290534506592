import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

import './assets/svg/iconfont'
import SvgIcon from './components/svg-icon.vue'
import Toolbar from './components/toolbar.vue'
import ImgLoading from './components/img-loading.vue'
import ImgOnError from './components/img-on-error.vue'
import ImgTitle from './components/img-title.vue'
import {prefixCls} from './constant'
import {isArray, isObject, isString, notEmpty, off, on} from './utils/index'
import {computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, PropType, ref, watch} from "vue";

interface Img {
    src?: string
    title?: string
}

type IndexChangeAction =
    | 'on-prev'
    | 'on-next'
    | 'on-prev-click'
    | 'on-next-click'
type IndexChangeActions = IndexChangeAction | IndexChangeAction[]


const __default__ = {
    name: "VueEasyLightbox"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    imgs: {
        type: [Array, String] as PropType<| string | Img | (Img | string)[]>,
        default: () => ''
    },
    visible: {type: Boolean, default: false},
    index: {type: Number, default: 0},
    escDisabled: {type: Boolean, default: false},
    moveDisabled: {type: Boolean, default: false},
    titleDisabled: {type: Boolean, default: false},
    loop: {type: Boolean, default: false},
    scrollDisabled: {type: Boolean, default: true},
    download: {
        type: Function as PropType<(url: string) => void>
    }
},
  setup(__props) {

function isImg(arg: Img): arg is Img {
    return isObject(arg) && isString(arg.src)
}


let props = __props;

let scale = ref(1);
let lastScale = ref(1);
let rotateDeg = ref(0);
let rotateY = ref(0);
let imgIndex = ref(0);
let top = ref(0);
let left = ref(0);
let lastX = ref(0);
let lastY = ref(0);
let isDraging = ref(false);
let loading = ref(false);
let loadError = ref(false);
let isTicking = ref(false);
let isGesturing = ref(false);
let wheeling = ref(false);
let lastBodyStyleOverflowY = ref('');
let imgBaseInfo = ref({
    width: 0,
    height: 0,
    maxScale: 1
});
let touches = ref<TouchList | []>([]);
let rafId = ref(0);

let imgList = computed(() => {
    if (isArray(props.imgs)) {
        return props.imgs
            .map((img) => {
                if (typeof img === 'string') {
                    return {src: img}
                } else if (isImg(img)) {
                    return img
                }
                return undefined
            })
            .filter(notEmpty)
    }
    if (isString(props.imgs)) {
        return [{src: props.imgs}]
    }
    return []
});

let visibleImgSrc = computed(() => {
    return imgList.value[imgIndex.value]?.src
});

let imgTitle = computed(() => {
    return imgList.value[imgIndex.value]?.title
});

let imgTotal = computed(() => {
    return imgList.value.length || 0
});

let imgWrapperStyle = computed(() => {

    return {
        transform: `translate(-50%, -50%) scale(${scale.value}) rotate(${rotateDeg.value}deg) rotateY(${rotateY.value}deg !important`,
        top: `calc(50% + ${top.value}px) !important`,
        left: `calc(50% + ${left.value}px) !important`,
        cursor: props.moveDisabled || loadError.value ? 'default !important' : 'move !important',
        transition: isDraging.value || isGesturing.value ? 'none !important' : ''
    }
});

function checkMoveable(button: number = 0) {
    if (props.moveDisabled) return false

    // mouse left btn click
    return button === 0
}

// mouse events handler
function handleMouseDown(e: MouseEvent) {
    if (!checkMoveable(e.button)) return
    lastX.value = e.clientX
    lastY.value = e.clientY
    isDraging.value = true
    e.stopPropagation()
}

function handleMouseUp(e: MouseEvent) {
    if (!checkMoveable(e.button)) return
    cancelAnimationFrame(rafId.value)
    isDraging.value = false
    isTicking.value = false
}

function handleMouseMove(e: MouseEvent) {
    if (!checkMoveable(e.button)) return
    if (isDraging.value && !isTicking.value) {
        isTicking.value = true
        rafId.value = requestAnimationFrame(() => {
            top.value = top.value - lastY.value + e.clientY
            left.value = left.value - lastX.value + e.clientX
            lastX.value = e.clientX
            lastY.value = e.clientY
            isTicking.value = false
        })
    }
    e.stopPropagation()
}

// touch events handler
function handleTouchStart(e: TouchEvent) {
    if (e.touches.length > 1) {
        isGesturing.value = true
        touches.value = e.touches
    } else {
        lastX.value = e.touches[0].clientX
        lastY.value = e.touches[0].clientY
        isDraging.value = true
    }
    e.stopPropagation()
}

function handleTouchMove(e: TouchEvent) {
    if (isTicking.value) return
    if (checkMoveable() && !isGesturing.value && isDraging.value) {
        isTicking.value = true
        rafId.value = requestAnimationFrame(() => {
            if (!e.touches[0]) return
            const _lastX = e.touches[0].clientX
            const _lastY = e.touches[0].clientY
            top.value = top.value - lastY.value + _lastY
            left.value = left.value - lastX.value + _lastX
            lastX.value = _lastX
            lastY.value = _lastY
            isTicking.value = false
        })
    } else if (
        isGesturing.value &&
        touches.value.length > 1 &&
        e.touches.length > 1
    ) {
        isTicking.value = true
        rafId.value = requestAnimationFrame(() => {
            const _scale =
                (getDistance(touches.value[0], touches.value[1]) -
                    getDistance(e.touches[0], e.touches[1])) /
                imgBaseInfo.value.width
            touches.value = e.touches;
            const newScale = scale.value - _scale * 1.3
            if (newScale > 0.5 && newScale < imgBaseInfo.value.maxScale * 1.5) {
                scale.value = newScale
            }
            isTicking.value = false
        })
    }
}

function handleTouchEnd() {
    cancelAnimationFrame(rafId.value)
    isDraging.value = false
    isGesturing.value = false
    isTicking.value = false
}

function handleDragStart(e: DragEvent) {
    e.preventDefault()
}

function onWheel(e: WheelEvent) {
    if (
        loadError.value ||
        loading.value ||
        isDraging.value ||
        isGesturing.value ||
        wheeling.value ||
        !props.scrollDisabled
    ) {
        return
    }

    wheeling.value = true

    setTimeout(() => {
        wheeling.value = false
    }, 80)

    if (e.deltaY < 0) {
        zoomIn()
    } else {
        zoomOut()
    }
}

// key press events handler
function handleKeyPress(e: KeyboardEvent) {
    if (!props.escDisabled && e.key === 'Escape' && props.visible) {
        closeDialog()
    }
    if (e.key === 'ArrowLeft') {
        onPrevClick()
    }
    if (e.key === 'ArrowRight') {
        onNextClick()
    }
}

// window resize
function handleWindowResize() {
    getImgSize()
}

// load event handler
function handleTestImgLoad() {
    loading.value = false
}

function handleRealImgLoad() {
    getImgSize()
}

function handleImgError(e: Event) {
    loading.value = false
    loadError.value = true
    instance.$emit('on-error', e)
}

let instance = getCurrentInstance().proxy;

// common methods
function getImgSize() {
    const imgElement = instance.$refs.realImg as HTMLImageElement | undefined
    if (imgElement) {
        const {width, height, naturalWidth} = imgElement
        imgBaseInfo.value.maxScale = naturalWidth / width
        imgBaseInfo.value.width = width
        imgBaseInfo.value.height = height
    }
}

function getDistance(p1: Touch, p2: Touch) {
    const x = p1.clientX - p2.clientX
    const y = p1.clientY - p2.clientY
    return Math.sqrt(x * x + y * y)
}

// action handler
function zoom(newScale: number) {
    if (Math.abs(1 - newScale) < 0.05) {
        newScale = 1
    } else if (Math.abs(imgBaseInfo.value.maxScale - newScale) < 0.05) {
        newScale = imgBaseInfo.value.maxScale
    }
    lastScale.value = scale.value
    scale.value = newScale
}

function zoomIn() {
    const newScale = scale.value + 0.12
    if (newScale < imgBaseInfo.value.maxScale * 3) {
        zoom(newScale)
    }
}

function zoomOut() {
    const newScale = scale.value - (scale.value < 0.7 ? 0.1 : 0.12)
    if (newScale > 0.1) {
        zoom(newScale)
    }
}

function rotateLeft() {
    rotateDeg.value -= 90
}

function mirror() {
    rotateY.value += 180;
}

function rotateRight() {
    rotateDeg.value += 90
}

function handleDblClick() {
    if (scale.value !== imgBaseInfo.value.maxScale) {
        lastScale.value = scale.value
        scale.value = imgBaseInfo.value.maxScale
    } else {
        scale.value = lastScale.value
    }
}

function resize() {
    scale.value = 1
    top.value = 0
    left.value = 0
}

function onNextClick() {
    const oldIndex = imgIndex.value
    const newIndex = props.loop
        ? (oldIndex + 1) % imgList.value.length
        : oldIndex + 1

    if (!props.loop && newIndex > imgList.value.length - 1) return

    setIndex(newIndex, ['on-next-click', 'on-next'])
}

function onPrevClick() {
    const oldIndex = imgIndex.value
    let newIndex = oldIndex - 1

    if (oldIndex === 0) {
        if (!props.loop) return
        newIndex = imgList.value.length - 1
    }

    setIndex(newIndex, ['on-prev-click', 'on-prev'])
}

function setIndex(newIndex: number, actions?: IndexChangeActions) {
    const oldIndex = imgIndex.value
    // reset style
    reset();
    // setIndex
    imgIndex.value = newIndex

    // handle same url
    if (imgList.value[imgIndex.value] === imgList.value[newIndex]) {
        nextTick(() => {
            loading.value = false
        })
    }

    // No emit event when hidden or same index
    if (!props.visible || oldIndex === newIndex) return
    if (actions) {
        if (isArray(actions)) {
            actions.forEach((action) => {
                instance.$emit(action, oldIndex, newIndex)
            })
        } else {
            instance.$emit(actions, oldIndex, newIndex)
        }
    }
    instance.$emit('on-index-change', oldIndex, newIndex)
}

function closeDialog() {
    instance.$emit('update:visible', false);
}

// reset
function reset() {
    scale.value = 1
    rotateDeg.value = 0
    top.value = 0
    left.value = 0
    isDraging.value = false
    loading.value = true
    loadError.value = false
}

function init() {
    reset()

    const length = imgList.value.length

    if (length === 0) {
        imgIndex.value = 0
        loading.value = false
        nextTick(() => {
            loadError.value = true
        })
        return
    }
    imgIndex.value =
        props.index >= length ? length - 1 : props.index < 0 ? 0 : props.index
}

// scrolling
function disableScrolling() {
    if (!document) return
    document.body.classList.add("easy-lightbox-disable-overflow");
}

function enableScrolling() {
    if (!document) return
    document.body.classList.remove("easy-lightbox-disable-overflow");
}

watch(computed(() => props.visible), (visible: boolean) => {
    if (visible) {
        init()
        nextTick(() => {
            on(instance.$refs.modal as Element, 'touchmove', (e: Event) => {
                e.preventDefault()
            })
            if (props.scrollDisabled) {
                disableScrolling()
            }
        })
    } else {
        if (props.scrollDisabled) {
            enableScrolling()
        }
    }
}, {immediate: true});

watch(computed(() => props.index), (newIndex: number) => {
    if (newIndex < 0 || newIndex >= imgList.value.length) {
        return
    }
    setIndex(newIndex)
});

// life cycle
onMounted(() => {
    on(document, 'keydown', handleKeyPress)
    on(window, 'resize', handleWindowResize)
});

onBeforeUnmount(() => {
    off(document, 'keydown', handleKeyPress);
    off(window, 'resize', handleWindowResize);
    enableScrolling();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: `${_unref(prefixCls)}-fade`
  }, {
    default: _withCtx(() => [
      (__props.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "modal",
            class: _normalizeClass([`${_unref(prefixCls)}-img-modal`, `${_unref(prefixCls)}-modal`]),
            onWheel: onWheel,
            onClick: _withModifiers(closeDialog, ["self"])
          }, [
            _createVNode(_Transition, {
              name: `${_unref(prefixCls)}-fade`,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_unref(loading))
                  ? _renderSlot(_ctx.$slots, "loading", { key: 0 }, () => [
                      _createVNode(ImgLoading)
                    ])
                  : (_unref(loadError))
                    ? _renderSlot(_ctx.$slots, "onerror", { key: 1 }, () => [
                        _createVNode(ImgOnError)
                      ])
                    : (!_unref(loading) && !_unref(loadError))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(`${_unref(prefixCls)}-img-wrapper`),
                          style: _normalizeStyle(_unref(imgWrapperStyle))
                        }, [
                          _createElementVNode("img", {
                            ref: "realImg",
                            class: _normalizeClass(`${_unref(prefixCls)}-img`),
                            src: _unref(visibleImgSrc),
                            draggable: "false",
                            onDblclick: _cache[0] || (_cache[0] = ($event: any) => (handleDblClick())),
                            onDragstart: _cache[1] || (_cache[1] = ($event: any) => (handleDragStart($event))),
                            onLoad: handleRealImgLoad,
                            onMousedown: _cache[2] || (_cache[2] = ($event: any) => (handleMouseDown($event))),
                            onMousemove: _cache[3] || (_cache[3] = ($event: any) => (handleMouseMove($event))),
                            onMouseup: _cache[4] || (_cache[4] = ($event: any) => (handleMouseUp($event))),
                            onTouchend: _cache[5] || (_cache[5] = ($event: any) => (handleTouchEnd())),
                            onTouchmove: _cache[6] || (_cache[6] = ($event: any) => (handleTouchMove($event))),
                            onTouchstart: _cache[7] || (_cache[7] = ($event: any) => (handleTouchStart($event)))
                          }, null, 42, _hoisted_1)
                        ], 6))
                      : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["name"]),
            _createElementVNode("img", {
              src: _unref(visibleImgSrc),
              style: {"display":"none"},
              onError: handleImgError,
              onLoad: handleTestImgLoad
            }, null, 40, _hoisted_2),
            _createElementVNode("div", {
              class: _normalizeClass(`${_unref(prefixCls)}-btns-wrapper`)
            }, [
              _renderSlot(_ctx.$slots, "prev-btn", { prev: onPrevClick }, () => [
                (_unref(imgList).length > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([{ disable: !__props.loop && _unref(imgIndex) <= 0 }, "btn__prev"]),
                      onClick: onPrevClick
                    }, [
                      _createVNode(SvgIcon, { type: "prev" })
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _renderSlot(_ctx.$slots, "next-btn", { next: onNextClick }, () => [
                (_unref(imgList).length > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass([{ disable: !__props.loop && _unref(imgIndex) >= _unref(imgList).length - 1 }, "btn__next"]),
                      onClick: onNextClick
                    }, [
                      _createVNode(SvgIcon, { type: "next" })
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _renderSlot(_ctx.$slots, "close-btn", { close: closeDialog }, () => [
                _createElementVNode("div", {
                  class: "btn__close",
                  onClick: closeDialog
                }, [
                  _createVNode(SvgIcon, { type: "close" })
                ])
              ]),
              (_unref(imgTitle) && !__props.titleDisabled && !_unref(loading) && !_unref(loadError))
                ? _renderSlot(_ctx.$slots, "title", { key: 0 }, () => [
                    _createVNode(ImgTitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(imgTitle)), 1)
                      ]),
                      _: 1
                    })
                  ])
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "toolbar", {
                toolbarMethods: {
                        zoomIn: zoomIn,
                        zoomOut: zoomOut,
                        rotate: rotateLeft,
                        rotateLeft: rotateLeft,
                        rotateRight: rotateRight,
                        resize: resize
                      }
              }, () => [
                _createVNode(Toolbar, {
                  prefixCls: _unref(prefixCls),
                  resize: resize,
                  mirror: mirror,
                  rotateLeft: rotateLeft,
                  rotateRight: rotateRight,
                  zoomIn: zoomIn,
                  zoomOut: zoomOut,
                  src: _unref(visibleImgSrc),
                  download: __props.download
                }, null, 8, ["prefixCls", "src", "download"])
              ])
            ], 2)
          ], 34))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name"]))
}
}

})