import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex min-width-0 pb-1 w-100 mw-100 counter-row align-items-center" }
const _hoisted_3 = {
  class: "flex-fill",
  style: {"word-break":"break-word"}
}
const _hoisted_4 = { class: "text-right pl-3 lh-1" }
const _hoisted_5 = { class: "d-flex min-width-0 pb-1 w-100 mw-100 counter-row" }
const _hoisted_6 = { class: "text-right pl-3" }

import SystemStateInterface from "~/cabinet/vue/layout/lcab/SystemStateInterface";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";


const __default__ = {
    name: "SystemStateCounters"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    systemState: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, {
      class: "el-row-root",
      gutter: 20
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.systemState.queues, (item, key) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            key: item.descr,
            md: 12
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, null, {
                default: _withCtx(() => [
                  _createVNode(ElCardHeader, {
                    title: item.descr
                  }, null, 8, ["title"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.groups, (group) => {
                    return (_openBlock(), _createBlock(ElCardSection, {
                      key: group.className,
                      class: "pt-2 pb-2"
                    }, {
                      default: _withCtx(() => [
                        (group.count > 1)
                          ? (_openBlock(), _createElementBlock("h6", _hoisted_1, _toDisplayString(group.defaultName), 1))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.values, (value, key) => {
                          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                              (key.startsWith(group.defaultName+'_'))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(key.substr(group.defaultName.length + 1)), 1)
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(key), 1)
                                  ], 64)),
                              (typeof group.rps[key]  === 'number')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                    _createTextVNode(" (" + _toDisplayString(group.rps[key]) + "/sec) ", 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                              (value > group.criticalCount[key])
                                ? (_openBlock(), _createBlock(_component_el_tag, {
                                    key: 0,
                                    type: "danger",
                                    style: {"transform":"translateX(10px)"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(value), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(value), 1)
                                  ], 64))
                            ])
                          ]))
                        }), 256))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024),
              (key===1)
                ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(ElCardSection, { class: "pt-2 pb-2" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-fill" }, " Клиентов онлайн ", -1)),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.systemState.currentOnline), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})