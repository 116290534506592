import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

import {
    OnlineChatClientOpenWidgetTabActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import Dictionary from "~/ts/library/Dictionary";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "OnlineChatOpenWidgetTabBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatClientOpenWidgetTabActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatClientOpenWidgetTabActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    tabId: null,
    tabShowTypeId: props.actionDescription.frontendParams.showTypes[0].id,
    formValues: {}
}));

let tabsByType = computed(() => {
    let result: Dictionary<{ id: string, descr: string }[]> = {};
    for (let tab of props.actionDescription.frontendParams.tabs) {
        if (!model.value.tabId) {
            model.value.tabId = tab.id;
        }
        if (!result[tab.type]) {
            result[tab.type] = [];
        }
        result[tab.type].push(tab);
    }
    return result;
});

let selectedTab = computed(() => {
    return props.actionDescription.frontendParams.tabs.find(tab => tab.id == model.value.tabId);
});


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, {
      label: "Вкладка",
      rules: _unref(requiredRule)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          modelValue: _unref(model).tabId,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).tabId) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_unref(model).formValues = {}))
          ],
          placeholder: "Выберите вкладку",
          class: "w-100"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabsByType), (tabs, tabType) => {
              return (_openBlock(), _createBlock(_component_el_option_group, {
                key: tabType,
                label: tabType
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs, (tab) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: tab.id,
                      label: tab.descr,
                      value: tab.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_el_form_item, { label: "Способ отображения" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          class: "w-100",
          modelValue: _unref(model).tabShowTypeId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).tabShowTypeId) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).actionDescription.frontendParams.showTypes, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                value: item.id,
                label: item.descr
              }, null, 8, ["value", "label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(HelpBlock, null, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Эта опция имеет значение только для клиентов из виджета. Для интеграций всегда будет отправляться ссылка на открытие вкладки. ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(selectedTab) && _unref(selectedTab).form)
      ? (_openBlock(), _createBlock(MyForm, {
          key: 0,
          form: _unref(selectedTab).form,
          modelValue: _unref(model).formValues,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).formValues) = $event))
        }, null, 8, ["form", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})