import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "online-chat-html-content pb-5 pt-5" }
const _hoisted_2 = { class: "inner" }

import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";

interface IOnlineChatHtmlConstructorAdditionalParams {
}

const __default__ = {
    name: "OnlineChatHtmlContentDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {


_useModel<IHtmlConstructorEditorModel<IOnlineChatHtmlConstructorAdditionalParams>>(__props, "modelValue");


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})