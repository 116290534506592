import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";
import Events from "~/ts/library/Events";
import {OAuthResultInterface} from "~/core-ui/vue/ui/oauth/Interface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "OAuthButton"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    authorizationUrl: {}
  },
  emits: ["auth"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let waiting = false;
addDisposableEvent(Events.addEventListener("message", window, function (e: MessageEvent) {
    if (waiting) {
        let data = e.data;
        if (typeof data == "string") {
            try {
                data = JSON.parse(data);
                if (data && typeof data.code == "string" && typeof data.state == "string") {
                    waiting = false;
                    emits("auth", {
                        code: data.code,
                        state: data.state
                    });
                }
            } catch (e) {
            }
        }
    }
}))

function openOAuth() {
    waiting = true;
    window.open(props.authorizationUrl, "_blank", "left=5,top=0,width=700,height=600");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (openOAuth()))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})