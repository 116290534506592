import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {IFieldWithOptionsMixin} from "~/cabinet/vue/interface/form/elements/Interfaces";
import OptionsConstructor from "~/cabinet/vue/interface/form/elements/constructor/OptionsConstructor.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "ElementWithOptionsConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    multiple: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<IFieldWithOptionsMixin>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    options: [{
        value: null,
        descr: null,
        default: false,
        icon: null,
        iconColor: "#3498db"
    }],
    optgroup: [],
    placeholder: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Вспомогательный текст в поле" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).placeholder) = $event)),
          placeholder: "Введите текст"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_form_item, { label: "Варианты" }, {
      default: _withCtx(() => [
        _createVNode(OptionsConstructor, {
          modelValue: _unref(model).options,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).options) = $event)),
          multiple: _ctx.multiple
        }, null, 8, ["modelValue", "multiple"])
      ]),
      _: 1
    })
  ]))
}
}

})