import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

import {
    useFilterWithOptionsComponent,
    useFilterWithOptionsComponentProps
} from "~/cabinet/vue/interface/filter/types/useFilterWithOptionsComponent";
import {computed} from "vue";
import Random from "~/ts/library/Random";


const __default__ = {
    name: "MyFilterSelect"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterWithOptionsComponentProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let nullValue = Random.uid() + 'fakeNullValue';

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let {options, optionGroups, optionsByGroups, loading} = useFilterWithOptionsComponent(props);

let isMultiple = computed(() => {
    return (
        props.component.filter.multiple
        ||
        (props.components.length === 1 && props.component.rootFilter.multiple)
    );
});

let remoteUrl = computed(() => {
    return props.component.filter.autoComplete;
});

function toLocalFormatValue(value: any) {
    if (isMultiple.value && Array.isArray(value)) {
        value = [...value];
        for (let i = 0; i < value.length; i++) {
            value[i] = optionValueToLocalFormat(value[i]);
        }
    }
    return value;
}

function optionValueToLocalFormat(value: any) {
    if (isMultiple.value && value == null) {
        return nullValue;
    }
    return value;
}

function fromLocalFormatValue(value: any) {
    if (isMultiple.value && Array.isArray(value)) {
        value = [...value];
        for (let i = 0; i < value.length; i++) {
            let item = value[i];
            if (item === nullValue) {
                value[i] = null;
            }
        }
    }
    return value;
}


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    "model-value": toLocalFormatValue(_unref(model)),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = fromLocalFormatValue($event) : model = fromLocalFormatValue($event))),
    multiple: _unref(isMultiple) ? true : undefined,
    filterable: "",
    placeholder: _ctx.component.filter.placeholder,
    remote: _unref(remoteUrl) ? true : null,
    loading: _unref(loading)
  }, {
    default: _withCtx(() => [
      (_unref(optionGroups).length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(optionsByGroups), (optionsList, groupDescr) => {
            return (_openBlock(), _createBlock(_component_el_option_group, { label: groupDescr }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsList, (option) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: option.value,
                    value: optionValueToLocalFormat(option.value),
                    label: option.previewDescr ? option.previewDescr : option.descr
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.descr), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "label"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 256))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(options), (option) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: `option_${option.value}`,
              value: optionValueToLocalFormat(option.value),
              label: option.previewDescr ? option.previewDescr : option.descr
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option.descr), 1)
              ]),
              _: 2
            }, 1032, ["value", "label"]))
          }), 128))
    ]),
    _: 1
  }, 8, ["model-value", "multiple", "placeholder", "remote", "loading"]))
}
}

})