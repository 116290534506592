import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import {computed, ref} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import CabinetAnalyticCountersSenderInstance from "~/cabinet/ts/service/CabinetAnalyticCountersSender";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";

interface RegistrationFormDataInterface {
    form: IForm,
    codeword?: string,
    rules?: string
}


const __default__ = {
    name: "RegFormDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    initialEmail: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["done"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;


let visible = _useModel<boolean>(__props, "visible");

let rulesDisplayed = ref(false);
let regFormModel = ref<Dictionary<string>>({});


let regFormRequest = computed(() => {
    return (new ReactiveRequest<RegistrationFormDataInterface>(new LcabApiRequest({
        url: "/api/auth/getRegistrationForm"
    }))).setErrorCallback(() => {
        visible.value = false;
    }).setCallback(data => {
        if (data.codeword) {
            regFormModel.value.promocode = data.codeword;
            let item = data.form.sections[0].elements.find(item => item.name === "promocode");
            if (item) {
                item.readonly = true;
            }
        }
        if (props.initialEmail && !regFormModel.value.email) {
            regFormModel.value.email = props.initialEmail;
        }
        CabinetAnalyticCountersSenderInstance.reachGoal("lcab_reg_form_display");
    });
});


async function makeRegistration() {
    if (regFormModel.value.acceptRules || !regFormRequest.value.data.rules) {
        let result = await LcabApiRequest.fetch({
            url: "/api/auth/makeRegistration",
            p: {
                form: regFormModel.value
            }
        });
        if (!result.showMessageOnError()) {
            document.dispatchEvent(new CustomEvent('account-registered'));

            emits("done", {
                login: result.getData("login"),
                password: result.getData("password")
            });
            visible.value = false;
            result.showMessage();
            if (result.getData("reload")) {
                window.location.href = LcabLayoutStore.appUrlPrefix.value;
            }
        }
    } else {
        LcabApiResult.showError("Требуется подтвердить согласие с правилами сервиса");
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_unref(visible))
    ? (_openBlock(), _createBlock(MyDialog, {
        key: 0,
        dialog: {modal: false},
        visible: _unref(visible),
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        "button-text": "Зарегистрироваться",
        title: "Регистрация",
        "cancel-button-text": "Вернуться к авторизации",
        onApply: makeRegistration,
        class: "reg-dialog"
      }, {
        default: _withCtx(() => [
          _createVNode(ReactiveRequestLoader, { request: _unref(regFormRequest) }, {
            default: _withCtx(() => [
              (_unref(regFormRequest).data)
                ? (_openBlock(), _createBlock(_component_el_form, {
                    key: 0,
                    model: _unref(regFormModel),
                    "label-position": "left",
                    "label-width": "190px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyForm, {
                        modelValue: _unref(regFormModel),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(regFormModel) ? (regFormModel).value = $event : regFormModel = $event)),
                        form: _unref(regFormRequest).data.form
                      }, null, 8, ["modelValue", "form"]),
                      (_unref(regFormRequest).data.rules)
                        ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: _unref(regFormModel).acceptRules,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(regFormModel).acceptRules) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _cache[5] || (_cache[5] = _createTextVNode(" Я согласен с ")),
                                  _createElementVNode("a", {
                                    class: "link-dashed",
                                    tabindex: "",
                                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_isRef(rulesDisplayed) //@ts-ignore
 ? rulesDisplayed.value = true : rulesDisplayed = true), ["stop","prevent"]))
                                  }, "правилами сервиса")
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              (_unref(rulesDisplayed))
                                ? (_openBlock(), _createBlock(_component_el_dialog, {
                                    key: 0,
                                    modelValue: _unref(rulesDisplayed),
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(rulesDisplayed) ? (rulesDisplayed).value = $event : rulesDisplayed = $event)),
                                    class: "left",
                                    title: "Правила сервиса"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: _unref(regFormRequest).data.rules
                                      }, null, 8, _hoisted_1)
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["model"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["request"])
        ]),
        _: 1
      }, 8, ["visible"]))
    : _createCommentVNode("", true)
}
}

})