import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

import {SenderMagicUrlModelInterface} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import HelpIcon from "~/cabinet/vue/interface/form/HelpIcon.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SenderMagicUrl',
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<SenderMagicUrlModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    enabled: false
}));

let expire = computed(() => {
    return Math.floor(SenderUslugi[props.uslugaId].store.magicUrlExpire / 86400);
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        modelValue: _unref(model).enabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
        class: "mr-2"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", null, [
      _cache[2] || (_cache[2] = _createTextVNode(" Сокращать ссылки ")),
      _createVNode(HelpIcon, null, {
        default: _withCtx(() => [
          _createElementVNode("p", null, "Ссылка будет работоспособна в течение " + _toDisplayString(_unref(expire)) + " дней.", 1),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Также, благодаря сокращению ссылок, в отчёте будет доступна информация по кликам.", -1))
        ]),
        _: 1
      })
    ])
  ]))
}
}

})