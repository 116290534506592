import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef } from "vue"

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {
    HTML_BLOCK_ALIGN_CENTER,
    HTML_BLOCK_ALIGN_LEFT, HTML_BLOCK_ALIGN_RIGHT,
    HtmlBlockAlign
} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "Align"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {
    default: HTML_BLOCK_ALIGN_LEFT
},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let values: { id: HtmlBlockAlign, icon: string }[] = [
    {id: HTML_BLOCK_ALIGN_LEFT, icon: "align-left"},
    {id: HTML_BLOCK_ALIGN_CENTER, icon: "align-center"},
    {id: HTML_BLOCK_ALIGN_RIGHT, icon: "align-right"}
];

let model = _useModel<HtmlBlockAlign>(__props, "modelValue");


return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "Выравнивание" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_radio_group, {
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values), (item) => {
            return (_openBlock(), _createBlock(_component_el_radio_button, {
              key: item.id,
              value: item.id
            }, {
              default: _withCtx(() => [
                _createVNode(FontAwesomeIcon, {
                  icon: item.icon
                }, null, 8, ["icon"])
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})