import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  style: {"max-width":"200px"},
  class: "text-truncate"
}
const _hoisted_2 = {
  key: 0,
  class: "font-weight-bold"
}
const _hoisted_3 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_4 = {
  key: 0,
  class: "font-weight-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "text-danger"
}

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";
import {DiscountCouponActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "DiscountCouponBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<DiscountCouponActionDescription>()
    },
  setup(__props) {

let props = __props;

let usluga = computed(() => props.actionDescription.frontendParams.uslugi.find(usluga => usluga.id == props.params.uslugaId));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" Услуга: ")),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        (_unref(usluga))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(usluga).descr), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, " Услуга не задана "))
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createTextVNode(" Купон:")),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        (_unref(props).params.form && _unref(props).params.form.descr)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(props).params.form.descr), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, " Название не задано "))
      ])
    ])
  ]))
}
}

})