import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"300px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "text-nowrap" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "text-nowrap" }
const _hoisted_9 = { class: "mt-1 font-italic d-block" }
const _hoisted_10 = { key: 3 }

import {computed, ref} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import MobileCountryHeader from "~/cabinet/vue/dealer/config/uslugi/tarif/voice/MobileCountryHeader.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";

interface RecipientInfoResponseInterface {
    recipientId: string;
    type: "mobile" | "email" | "unknown";
    activationCode?: {
        id: string,
        dateTime: string
    };
    blackList?: {
        uslugaId: string,
        recipientId: string,
        dateTime: string,
        commentText: string
    };
    mobile: {
        opsos?: {
            id: string,
            descr: string
        },
        country?: {
            id: string,
            descr: string
        },
        district?: {
            id: string,
            descr: string
        },
        mnp?: {
            dateTime: string,
            opsos: {
                id: string,
                descr: string
            }
        }
    };
}

import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "RecipientInfo"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


let recipientIdForSubmit = ref<string>();
let inputModel = ref<string>();
let uid = ref<number>();


let request = computed(() => {
    if (recipientIdForSubmit.value) {
        return new ReactiveRequest<RecipientInfoResponseInterface>(new LcabApiRequest({
            url: `/api/superdealer/recipientInfo/getInfo`,
            p: {
                recipientId: recipientIdForSubmit.value,
                t: uid.value
            }
        }));
    }
    return null;
});

let response = computed<RecipientInfoResponseInterface>(() => {
    return request.value?.data;
});

function submit() {
    uid.value = Date.now();
    recipientIdForSubmit.value = inputModel.value;
}

async function allowRegistration(id: string) {
    try {
        await ElConfirm("Вы уверены, что хотите разрешить повторную регистрацию?");
        let result = await LcabApiRequest.save({
            url: `/api/superdealer/recipientInfo/${id}/allowRegistration`,
        });
        if (result.isSuccess) {
            submit();
        }
    } catch (e) {
    }
}

async function deleteFromBlackList(uslugaId: string, recipientId: string) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить получателя из стоп-листа?");
        let result = await LcabApiRequest.save({
            url: `/api/dealer/config/usluga/blackList/${uslugaId}/remove`,
            p: {
                recipientId
            }
        });
        if (result.isSuccess) {
            submit();
        }
    } catch (e) {
    }
}



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_input, {
      modelValue: _unref(inputModel),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputModel) ? (inputModel).value = $event : inputModel = $event)),
      placeholder: "Телефон или Email"
    }, null, 8, ["modelValue"]), [
      [
        _directive_on_native,
        submit,
        "keyup",
        { enter: true }
      ]
    ]),
    _createVNode(_component_el_button, {
      class: "d-block w-100 mt-2",
      type: "primary",
      onClick: submit
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" Получить информацию ")
      ])),
      _: 1
    }),
    (_unref(request))
      ? (_openBlock(), _createBlock(ReactiveRequestLoader, {
          key: 0,
          request: _unref(request),
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            (_unref(response))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(response).recipientId)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_unref(response).type === 'mobile')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_unref(response).mobile.country)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                                    _createVNode(MobileCountryHeader, {
                                      id: _unref(response).mobile.country.id,
                                      descr: `${_unref(response).mobile.country.descr}${_unref(response).mobile.district ? `, ${_unref(response).mobile.district.descr}` : ''}`
                                    }, null, 8, ["id", "descr"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(response).mobile.opsos)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                                    _createVNode(MobileCountryHeader, {
                                      id: _unref(response).mobile.opsos.id,
                                      descr: _unref(response).mobile.opsos.descr
                                    }, null, 8, ["id", "descr"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(response).mobile.mnp)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                                    _createTextVNode(" Перешёл от оператора " + _toDisplayString(_unref(response).mobile.mnp.opsos.descr) + " ", 1),
                                    _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(localeDateTimeString)(_unref(response).mobile.mnp.dateTime)), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ], 64))
                          : _createCommentVNode("", true),
                        (_unref(response).activationCode)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                              _createTextVNode(" Была регистрация " + _toDisplayString(_unref(localeDateTimeString)(_unref(response).activationCode.dateTime)) + ". ", 1),
                              _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("a", {
                                tabindex: "",
                                class: "link-dashed",
                                textContent: 'Разрешить повторно',
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (allowRegistration(_unref(response).activationCode.id)))
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (_unref(response).blackList)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                              _cache[5] || (_cache[5] = _createTextVNode(" В черном списке с ")),
                              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(localeDateTimeString)(_unref(response).blackList.dateTime)) + ".", 1),
                              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(response).blackList.commentText), 1),
                              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("a", {
                                tabindex: "",
                                class: "link-dashed text-danger",
                                textContent: 'Удалить из черного списка',
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (deleteFromBlackList(_unref(response).blackList.uslugaId, _unref(response).blackList.recipientId)))
                              })
                            ]))
                          : (_unref(response).type === 'email')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_10, "Не в черном списке"))
                            : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createBlock(HelpBlock, {
                        key: 1,
                        danger: ""
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Неверный формат телефона или почты ")
                        ])),
                        _: 1
                      }))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["request"]))
      : _createCommentVNode("", true)
  ]))
}
}

})