import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, isRef as _isRef, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-table-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "my-table-pagination-wrapper"
}

import {computed, getCurrentInstance, nextTick, onMounted, PropType, ref, watch} from 'vue';
import WindowHelper from "~/ts/library/WindowHelper";
import MyRemoteDataTableInterface, {
    MyRemoteDataTableSelection
} from "~/cabinet/vue/interface/data/MyRemoteDataTableInterface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {
    IDataTableSort,
    MassActionsHeadCheckboxExposeInterface
} from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import Dictionary from "~/ts/library/Dictionary";
import MyPagination from "~/cabinet/vue/interface/pagination/MyPagination.vue";
import MassActionsRowCheckbox from "~/cabinet/vue/interface/data/MassActionsRowCheckbox.vue";
import MassActionsHeadCheckbox from "~/cabinet/vue/interface/data/MassActionsHeadCheckbox.vue";
import {USE_REMOTE_DATA_EMITS, useRemoteData, useRemoteDataProps} from "~/cabinet/vue/interface/data/useRemoteData";
import {TableInstance} from "element-plus";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";


import ElTableDraggable from 'element-ui-el-table-draggable/dist/SortableElTable.esm.js';

const __default__ = {
    name: "MyRemoteDataTable",
    components: {
        ElTableDraggable
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useRemoteDataProps(),
    rowKey: {
        type: String,
        required: true
    },
    expandByRowClick: {type: Boolean},
    collapseRowOnReload: {type: Boolean},
    withSelection: {type: Boolean},
    scrollToTop: {type: Boolean, default: true},
    sort: {type: Object as PropType<IDataTableSort>},
    draggable: {type: Boolean},
    draggableHandler: {type: String},
    fitToWindowSize: {type: Boolean},
    resizable: {type: Boolean},
    border: {type: Boolean},
    expandRowKeys: {type: Array},
    height: {},
    maxHeight: {},
    stripe: {},
    size: {},
    fit: {},
    showHeader: {},
    highlightCurrentRow: {},
    currentRowKey: {},
    rowClassName: {},
    rowStyle: {},
    cellClassName: {},
    cellStyle: {},
    headerRowClassName: {},
    headerRowStyle: {},
    headerCellClassName: {},
    headerCellStyle: {},
    emptyText: {},
    defaultExpandAll: {},
    defaultSort: {},
    tooltipEffect: {},
    showSummary: {},
    sumText: {},
    summaryMethod: {},
    spanMethod: {},
    selectOnIndeterminate: {},
    reserveSelection: {type: Boolean},
}, {
    "modelValue": {
    default: []
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["items-change", "selection-changed", "draggable-stop", "row-click", "cell-click", "header-click", "sort-change", "header-dragend", "mass-actions-command", "update:items", "update:params", "load-start", "result", "update:result", "update:data", "load", "error", "items", "fetch-result"], ["update:modelValue"]),
  setup(__props, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<(string | number)[]>(__props, "modelValue");

let instance = getCurrentInstance().proxy;

let {
    exposed, reload, request, computedItems, isLoading, fetchResult
} = useRemoteData(props, {
    onReloadStart: () => {
        ignoreSelectionChange.value = true;
    },

    onReloadStop: () => {
        restoreSelection();
        ignoreSelectionChange.value = false;
    },
    computedDataParams: computed<Dictionary<any>>(() => {
        let result: Dictionary<any> = props.dataParams ? {...props.dataParams} : {};
        if (props.sort) {
            result.sort = props.sort;
        }
        return result;
    }),
    makeScrollToTop: async () => {
        if (props.scrollToTop) {
            await WindowHelper.scrollToTop(props.maxHeight ? tableBodyWrapper.value : null);
        }
    }
})


let massActionsHeadCheckbox = ref<MassActionsHeadCheckboxExposeInterface>();

let maxTableHeight = ref<number>();

function onSortChange(payload: IDataTableSort) {
    if (props.sort) {
        if (payload.prop == props.sort.prop) {
            payload.order = props.sort.order == "ascending" ? "descending" : "ascending";
        }
    }
    emits('sort-change', payload);
}

let headerCellClassNameComputed = computed(() => {
    return (payload: { row: any, column: any, rowIndex: number, columnIndex: number }) => {
        let inProps = props.headerCellClassName;
        let result = "";
        if (typeof inProps == "string") {
            result += inProps + " ";
        } else if (typeof inProps == "function") {
            result += inProps(payload) + " ";
        }
        if (props.sort) {
            if (payload.column.property === props.sort.prop) {
                result += props.sort.order;
            }
        }
        return result;
    }
});

function setHeightStyle() {
    nextTick(async () => {
        let newMaxTableHeight: number = null;
        if (props.fitToWindowSize) {
            let element: HTMLElement = instance.$el as any;
            if (element) {
                let body: HTMLElement = element.querySelector(".el-table__body-wrapper");
                if (body) {
                    let pagination: HTMLElement = element.querySelector(".my-table-pagination-wrapper");
                    let height = window.innerHeight - 30 - body.offsetTop; /* - element.getBoundingClientRect().top*/
                    if (pagination) {
                        height -= pagination.offsetHeight;
                    }
                    //height -= 30;
                    if (height > 100) {
                        newMaxTableHeight = height;
                    }
                }
            }
        }
        maxTableHeight.value = newMaxTableHeight;
    });

}

let ignoreSelectionChange = ref(false);

let maxHeight = ref<number>();
let selectionModel = ref<{
    list: any[],
    all: boolean
}>({
    list: [],
    all: false
});

watch(computedItems, items => {
    if (props.collapseRowOnReload) {
        nextTick(() => {
            for (let row of items) {
                table.value.toggleRowExpansion(row, false);
            }
        });

    }
    setHeightStyle();
    emits('items-change', items);
});


watch(model, () => restoreSelection(), {deep: true});


onMounted(() => {
    setHeightStyle();
    addDisposableEvent(WindowHelper.onResize(() => {
        setHeightStyle();
    }));
    restoreSelection();
});

let table = ref<TableInstance>();

let tableBodyWrapper = computed<HTMLElement | null>(() => {
    return table.value?.$el.getElementsByClassName("el-table__body-wrapper")[0] as HTMLElement;
});

function restoreSelection() {
    for (let item of computedItems.value) {
        let id = getItemId(item);
        let selected = model.value.indexOf(id) > -1;
        table.value?.toggleRowSelection(item, selected);
    }
}

function mapId(array: any[]): any[] {
    return array.map(value => getItemId(value));
}

function getItemId(item: any) {
    if (props.rowKey != null) {
        return item[props.rowKey];
    } else {
        throw new Error("Не задан row-key");
    }
}


function toggleSelection(item: any, flag?: boolean): boolean {
    let itemId = getItemId(item);
    let changed = false;
    let index = model.value.indexOf(itemId);
    let hasInValue = index > -1;
    if (flag == null) {
        flag = !hasInValue;
    }
    if (flag && !hasInValue) {
        changed = true;
        emitSelection([...model.value, itemId]);
    } else if (!flag && hasInValue) {
        changed = true;
        let value = [...model.value];
        value.splice(index, 1);
        emitSelection(value);
    }
    return changed;
}

watch(selectionModel, () => {
    emits("selection-changed", {
        ...selectionModel.value,
        count: massActionsHeadCheckbox.value.selectionCount.value
    } as MyRemoteDataTableSelection);
}, {deep: true})

function emitSelection(values: any[]) {
    model.value = values;
}

let itemsDictionary = computed(() => {
    return computedItems.value && props.rowKey ? ObjectHelper.dictionaryFromArray(computedItems.value, props.rowKey) : {};
});

function onSelectionChanged(newSelection: any[]) {
    if (!ignoreSelectionChange.value) {
        let value = [...model.value];
        let newSelectionIdList = mapId(newSelection);
        value = value.filter(id => newSelectionIdList.indexOf(id) > -1 || itemsDictionary.value[id] == null);
        value.push(...newSelectionIdList.filter(id => value.indexOf(id) === -1));
        emitSelection(value);
    }
}

function onRowClick(row: any) {
    if (props.expandByRowClick) {
        table.value.toggleRowExpansion(row);
    }
    emits("row-click", row);
}

function onCellClick(row: any, column: any, cell: HTMLTableCellElement, event: Event) {
    emits('cell-click', row, column, cell, event);
}

function onSortEnd() {
    emits("draggable-stop", computedItems.value.map(item => item[props.rowKey]));
}

function onHeaderDragEnd(newWidth: number, oldWidth: number, column: any, event: MouseEvent) {
    emits("header-dragend", newWidth, oldWidth, column, event);
}


__expose<MyRemoteDataTableInterface>({
    ...exposed,
    toggleSelection
})

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(__props.draggable ? _unref(ElTableDraggable) : 'div'), {
      onDrop: onSortEnd,
      handle: __props.draggableHandler
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, _mergeProps({
          ref_key: "table",
          ref: table,
          class: _unref(computedItems).length ? null : 'empty'
        }, _ctx.$props, {
          data: _unref(computedItems),
          "header-cell-class-name": _unref(headerCellClassNameComputed),
          "expand-row-keys": __props.expandRowKeys,
          "row-key": __props.rowKey ? __props.rowKey : 'id',
          "max-height": _unref(maxTableHeight),
          onSelectionChange: onSelectionChanged,
          onRowClick: onRowClick,
          onCellClick: onCellClick,
          onHeaderClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('header-click', $event))),
          onSortChange: onSortChange,
          onHeaderDragend: onHeaderDragEnd
        }), {
          default: _withCtx(() => [
            (__props.withSelection)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 0,
                  width: '85px'
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(MassActionsRowCheckbox, {
                      class: "my-table-selection hide-label",
                      "selection-label": row[__props.rowKey],
                      "selection-model": _unref(selectionModel)
                    }, null, 8, ["selection-label", "selection-model"])
                  ]),
                  header: _withCtx(() => [
                    _createVNode(MassActionsHeadCheckbox, {
                      modelValue: _unref(selectionModel),
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectionModel) ? (selectionModel).value = $event : selectionModel = $event)),
                      ref_key: "massActionsHeadCheckbox",
                      ref: massActionsHeadCheckbox,
                      "all-items-count": _unref(fetchResult) ? _unref(fetchResult).count : (_unref(computedItems) ? _unref(computedItems).length : 0),
                      onCommand: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('mass-actions-command', $event)))
                    }, {
                      "selection-dropdown": _withCtx((props) => [
                        _renderSlot(_ctx.$slots, "selection-dropdown", _normalizeProps(_guardReactiveProps(props)))
                      ]),
                      _: 3
                    }, 8, ["modelValue", "all-items-count"])
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 16, ["class", "data", "header-cell-class-name", "expand-row-keys", "row-key", "max-height"])), [
          [_directive_loading, _unref(isLoading)]
        ])
      ]),
      _: 3
    }, 40, ["handle"])),
    (_unref(fetchResult) && _unref(fetchResult).onPage != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(MyPagination, {
            class: "mt-2 mb-2",
            "fetch-result": _unref(fetchResult),
            onCurrentChange: _cache[3] || (_cache[3] = ($event: any) => (_unref(reload)($event))),
            onSizeChange: _cache[4] || (_cache[4] = ($event: any) => (_unref(reload)(0, $event))),
            "scroll-to-top": false
          }, null, 8, ["fetch-result"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "append")
  ]))
}
}

})