import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyLoader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    big: { type: Boolean },
    withoutStyle: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;


let fontSize = computed(() => {
    return props.big ? "3em" : "1em";
});

let style = computed(() => {
    return props.big ? {
        margin: "3em 0",
        minHeight: "6em",
    } : {
        margin: ".5em 0",
        minHeight: "2em"
    };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FontAwesomeIcon, {
    icon: "circle-notch",
    light: "",
    class: "fa-spin text-primary",
    style: _normalizeStyle(_ctx.withoutStyle ? undefined : {fontSize: _unref(fontSize)})
  }, null, 8, ["style"]))
}
}

})