import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {RawComponent} from "~/core-ui/ts/useRawComponent";

export default abstract class AbstractHtmlConstructorType {
    abstract get contentDisplayComponent(): RawComponent;

    abstract getDefaultBlockLists(): IHtmlConstructorBlock<any>[];

    get isStyleEditableInContentEditor(): boolean {
        return true;
    }
}