import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {__} from "~/ts/library/Translate";
import StringHelper from "~/ts/library/StringHelper";
import {SenderMessageTimeoutValueInterface} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import HelpIcon from "~/cabinet/vue/interface/form/HelpIcon.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "SenderMessageTimeout"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



let vprList = computed(() => {
    let result = [];
    for (let i = 1; i <= 12; i++) {
        result.push({
            value: i * 3600,
            label: __(StringHelper.getSclon(i, __("%d час"), __("%d часа"), __("%d часов")), i)
        })
    }
    return result;
});

let _modelValue = _useModel<SenderMessageTimeoutValueInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    enabled: false,
    timeout: vprList.value[vprList.value.length - 1].value
}));

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        modelValue: _unref(model).enabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
        class: "mr-2"
      }, null, 8, ["modelValue"]),
      _cache[3] || (_cache[3] = _createTextVNode(" Время жизни сообщения ")),
      _createVNode(HelpIcon, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Если в течение заданного времени сообщение не удастся доставить, оно пометится недоставленным ")
        ])),
        _: 1
      })
    ]),
    (_unref(model).enabled)
      ? (_openBlock(), _createBlock(_component_el_select, {
          key: 0,
          modelValue: _unref(model).timeout,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).timeout) = $event)),
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vprList), (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})