import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IH1BlockValues, {IH1Style} from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/IH1BlockValues";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "H1Display"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<IH1Style>(),
    tag: {
        type: String,
        default: "h1"
    },
    placeholder: {
        type: String,
        default: "Заголовок"
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {htmlConstructorEditor} = useBlockDisplay(props);
let _modelValue = _useModel<IH1BlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TextInputWithVariables, {
    modelValue: _unref(model).title,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).title) = $event)),
    placeholder: __props.placeholder,
    style: _normalizeStyle({
            textAlign: _unref(model).align,
            fontSize: `${_ctx.styles.bodyH1Size}px`,
            fontFamily: _ctx.styles.bodyH1Font,
            color: _ctx.styles.bodyH1Color,
            fontWeight: _ctx.styles.bodyH1Weight
        }),
    inline: "",
    "editor-class-list": ['pt-1', 'pb-1'],
    "content-variables": _unref(htmlConstructorEditor).params.contentVariables
  }, null, 8, ["modelValue", "placeholder", "style", "content-variables"]))
}
}

})