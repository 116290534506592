import UslugaRouteBuilder from "~/cabinet/ts/routes/UslugaRouteBuilder";
import {IBeforeLeftMenuDropdown, IRouteMeta} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {__} from "~/ts/library/Translate";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";
import {
    INTEGRATION_PLATFORM_TYPE_ANALYTIC,
    INTEGRATION_PLATFORM_TYPE_CRM
} from "~/cabinet/ts/data/integrations/Platform";
import {
    EDIT_OFFLINE_TAB_ID,
    EDIT_ONLINE_TAB_ID,
    EDIT_REVIEWS_TAB_ID
} from "~/cabinet/vue/client/online/config/tabs/useOnlineTabForm";
import Dictionary from "~/ts/library/Dictionary";
import {DESIGN_QUERY_SHOW_ALL_SETTINGS, DESIGN_QUERY_TAB} from "~/cabinet/vue/client/online/config/design/Constants";
import {PARAM_AUTH, PARAM_PLATFORM} from "~/cabinet/ts/routes/EventsRouteBuilder";
import {ONLINE_CHAT_PLATFORM_ID} from "~/cabinet/ts/Constant";
import {CLIENT_EVENTS_ROUTES_BUILDER} from "~/cabinet/ts/routes/ClientEventsRoutesBuilder";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME} from "~/cabinet/ts/routes/PublicRoutesBuilder";
import {ONLINE_CHAT_DISCOUNT_COUPON_ROUTE_NAME, SITES_LICENSE_LIST} from "~/cabinet/ts/routes/Constants";
import DashboardRouteBuilder from "~/cabinet/ts/routes/DashboardRouteBuilder";
import {RouteLocationNamedRaw, RouteRecordRaw} from "vue-router";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const ROOT_ROUTE_NAME = "OnlineChat";
const SITE_ROUTE_NAME = ROOT_ROUTE_NAME + "_Site";

const SITE_DASHBOARD_ROUTE_NAME = SITE_ROUTE_NAME + "_Dashboard";
const SITE_REPORTS_ROUTE_NAME = SITE_ROUTE_NAME + "_Reports";
const SITE_DIALOGS_ROUTE_NAME = SITE_ROUTE_NAME + "_Dialogs";
const SITE_CONFIG_ROUTE_NAME = SITE_ROUTE_NAME + "_Config";
const SITE_CONFIG_DESIGN_ROUTE_NAME = SITE_CONFIG_ROUTE_NAME + "_Design";
const SITES_ROUTE_NAME = ROOT_ROUTE_NAME + "_Sites";
const COMMON_REPORTS = `${ROOT_ROUTE_NAME}_COMMON_REPORTS`;
const COMMON_CONFIG = `${ROOT_ROUTE_NAME}_COMMON_CONFIG`;
const COMMON_CONFIG_OPERATOR_STATUS = `${COMMON_CONFIG}_OPERATOR_STATUS`;
const COMMON_CONFIG_STICKER_PACK = `${COMMON_CONFIG}_STICKER_PACK`;
const COMMON_CONFIG_TRANSLATE_USER_LANGUAGE = `${COMMON_CONFIG}_TRANSLATE_USER_LANGUAGE`;


const COMMON_REPORTS_DASHBOARD_ROUTE_NAME = COMMON_REPORTS + "_Dashboard";
const COMMON_REPORTS_OPERATORS = `${COMMON_REPORTS}_OPERATORS`;
const COMMON_REPORTS_ACTIVITY = `${COMMON_REPORTS}_ACTIVITY`;
const COMMON_REPORTS_FORM = `${COMMON_REPORTS}_FORM`;
const COMMON_REPORTS_RATES = `${COMMON_REPORTS}_RATES`;
const COMMON_REPORTS_QUESTION_CATEGORIES = `${COMMON_REPORTS}_QUESTION_CATEGORIES`;
const COMMON_REPORTS_TAGS = `${COMMON_REPORTS}_TAGS`;

const COMMON_REPORTS_MESSAGE_TEMPLATES = `${COMMON_REPORTS}_MESSAGE_TEMPLATES`;


const COMMON_REPORTS_OPERATORS_WORKLOAD = `${COMMON_REPORTS}_OPERATORS_WORKLOAD`
const COMMON_REPORTS_OPERATORS_WORKTIME = `${COMMON_REPORTS}_OPERATORS_WORKTIME`


//const SITE_API_ROUTE_NAME = SITE_ROUTE_NAME + "_API";
//const SITE_API_TOKENS_ROUTE_NAME = SITE_API_ROUTE_NAME + "_TOKENS";
//const SITE_API_WEBHOOKS_ROUTE_NAME = SITE_API_ROUTE_NAME + "_WEBHOOKS";

const REPORT_CLIENTS_LIST = `${SITE_REPORTS_ROUTE_NAME}_CLIENTS_LIST`;
const REPORT_BLOCKED_CLIENTS_LIST = `${SITE_REPORTS_ROUTE_NAME}_BLOCKED_CLIENTS_LIST`;

const REPORT_ACTIVITY = `${SITE_REPORTS_ROUTE_NAME}_ACTIVITY`;
const REPORT_DIALOGS = `${SITE_REPORTS_ROUTE_NAME}_DIALOGS`;
const REPORT_CITY = `${SITE_REPORTS_ROUTE_NAME}_CITY`;
const REPORT_REFERER = `${SITE_REPORTS_ROUTE_NAME}_REFERER`;
const REPORT_TARGET = `${SITE_REPORTS_ROUTE_NAME}_TARGET`;
const REPORT_QUESTION_CATEGORIES = `${SITE_REPORTS_ROUTE_NAME}_QUESTION_CATEGORIES`;
const REPORT_TAGS = `${SITE_REPORTS_ROUTE_NAME}_TAGS`;
const REPORT_CHANNELS = `${SITE_REPORTS_ROUTE_NAME}_CHANNELS`;
const REPORT_MESSAGE_TEMPLATES = `${SITE_REPORTS_ROUTE_NAME}_MESSAGE_TEMPLATES`;

const REPORT_OPERATORS_CHATS = `${SITE_REPORTS_ROUTE_NAME}_OPERATORS_CHATS`;
const REPORT_SITE_PAGES = `${SITE_REPORTS_ROUTE_NAME}_SITE_PAGES`;
const REPORT_OPERATORS_WORKTIME = `${SITE_REPORTS_ROUTE_NAME}_OPERATORS_WORKTIME`;
const REPORT_OPERATORS_WORKLOAD = `${SITE_REPORTS_ROUTE_NAME}_OPERATORS_WORKLOAD`;
const REPORT_RATES = `${SITE_REPORTS_ROUTE_NAME}_RATES`;
const REPORT_FORMS = `${SITE_REPORTS_ROUTE_NAME}_FORMS`;
const REPORT_BOTS = `${SITE_REPORTS_ROUTE_NAME}_BOTS`;

const REPORT_PAYMENTS = `${SITE_REPORTS_ROUTE_NAME}_PAYMENTS`;

const LICENSE = `${SITE_ROUTE_NAME}_LICENSE`;
const API = `${SITE_ROUTE_NAME}_API`;
const REST_API = `${API}_REST_API`;
const WEBHOOKS = `${API}_WEBHOOKS`;


const DIALOGS_ALL = `${SITE_DIALOGS_ROUTE_NAME}_ALL`;
const DIALOGS_SKIPPED = `${SITE_DIALOGS_ROUTE_NAME}_SKIPPED`;
const DIALOGS_NOT_FINISHED_OFFLINE = `${SITE_DIALOGS_ROUTE_NAME}_NOT_FINISHED_OFFLINE`;

const CONFIG_LOADING_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_LOADING`;
const CONFIG_ONLINE_TAB_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_ONLINE_TAB`;
const CONFIG_OFFLINE_TAB_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_OFFLINE_TAB`;
const CONFIG_REVIEWS_TAB_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_REVIEWS_TAB`;
export const CONFIG_ADDITIONAL_TABS_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_ADDITIONAL_TABS`;
const CONFIG_AUTO_ACTIONS = `${SITE_CONFIG_ROUTE_NAME}_AUTO_ACTIONS`;
const CONFIG_MULTI_CONTACTS = `${SITE_CONFIG_ROUTE_NAME}_MULTI_CONTACTS`;
const CONFIG_MESSAGE_TEMPLATES = `${SITE_CONFIG_ROUTE_NAME}_MESSAGE_TEMPLATES`;
const CONFIG_QUESTION_CATEGORIES = `${SITE_CONFIG_ROUTE_NAME}_QUESTION_CATEGORIES`;
const CONFIG_SENDERS = `${SITE_CONFIG_ROUTE_NAME}_SENDERS`;
const CONFIG_BANNED_WORDS = `${SITE_CONFIG_ROUTE_NAME}_BANNED_WORDS`;
const CONFIG_EULA = `${SITE_CONFIG_ROUTE_NAME}_EULA`;
const CONFIG_TIMEZONE = `${SITE_CONFIG_ROUTE_NAME}_TIMEZONE`;
const CONFIG_KNOWLEDGE_BASE = `${SITE_CONFIG_ROUTE_NAME}_KNOWLEDGE_BASE`;

const CONFIG_FILE_STORAGE = `${SITE_CONFIG_ROUTE_NAME}_FILE_STORAGE`;
const CONFIG_JAVASCRIPT_API = `${SITE_CONFIG_ROUTE_NAME}_JAVASCRIPT_API`;

const CONFIG_BOT = `${SITE_CONFIG_ROUTE_NAME}_BOT`;
const CONFIG_FIELDS = `${SITE_CONFIG_ROUTE_NAME}_FIELDS`;
const CONFIG_TAGS = `${SITE_CONFIG_ROUTE_NAME}_TAGS`;


const CONFIG_SETUP = `${SITE_CONFIG_ROUTE_NAME}_SETUP`;
const CONFIG_OPERATORS = `${SITE_CONFIG_ROUTE_NAME}_OPERATORS`;
const CONFIG_OPERATOR_GROUPS = `${SITE_CONFIG_ROUTE_NAME}_OPERATOR_GROUPS`;
const CONFIG_DIALOG = `${SITE_CONFIG_ROUTE_NAME}_DIALOG`;

const CONFIG_EDIT_ADDITIONAL_TAB_ROUTE_NAME = `${SITE_CONFIG_ROUTE_NAME}_EDIT_ADDITIONAL_TAB`;
const PARAM_SITE_ID = "siteId";

const INTEGRATION_ROUTE_NAME = `${SITE_ROUTE_NAME}_INTEGRATION`;


const ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME = `${SITE_ROUTE_NAME}_ANALYTIC_COUTNERS_INTEGRATION`;
//const YANDEX_METRIKA_ROUTE_NAME = `${ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME}_YANDEX`;
//const GOOGLE_ANALYTICS_ROUTE_NAME = `${ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME}_GOOGLE`;

export function getUserTranslateRoute(): RouteLocationNamedRaw {
    return {
        name: COMMON_CONFIG_TRANSLATE_USER_LANGUAGE
    }
}

export function getEditAdditionalTabRoute(siteId: string, tabId: string): RouteLocationNamedRaw {
    return {
        name: CONFIG_EDIT_ADDITIONAL_TAB_ROUTE_NAME,
        params: {
            [PARAM_SITE_ID]: siteId,
            tabId
        }
    };
}

export function getEditDesignRoute(siteId: string, tab?: string, showAllSettings?: boolean): RouteLocationNamedRaw {
    let query: Dictionary<string> = {};
    if (tab) {
        query[DESIGN_QUERY_TAB] = tab;
    }
    if (showAllSettings != null) {
        query[DESIGN_QUERY_SHOW_ALL_SETTINGS] = showAllSettings ? '1' : '0';
    }
    return {
        name: SITE_CONFIG_DESIGN_ROUTE_NAME,
        params: {
            [PARAM_SITE_ID]: siteId
        },
        query
    };
}


export function getMessengerIntegrationRoute(siteId: string): RouteLocationNamedRaw {
    return {
        name: MESSENGERS_INTEGRATION_LIST_ROUTE_NAME,
        params: {
            [PARAM_SITE_ID]: siteId
        }
    };
}

export function getCrmIntegrationRoute(siteId: string): RouteLocationNamedRaw {
    return {
        name: CRM_INTEGRATION_LIST_ROUTE_NAME,
        params: {
            [PARAM_SITE_ID]: siteId
        }
    };
}

export function getAnalyticIntegrationRoute(siteId: string): RouteLocationNamedRaw {
    return {
        name: ANALYTIC_INTEGRATION_LIST_ROUTE_NAME,
        params: {
            [PARAM_SITE_ID]: siteId
        }
    };
}


const MESSENGERS_INTEGRATION_LIST_ROUTE_NAME = SITE_ROUTE_NAME + "_MESSENGERS_LIST";

const CRM_INTEGRATION_LIST_ROUTE_NAME = SITE_ROUTE_NAME + "_CRM_LIST";
const ANALYTIC_INTEGRATION_LIST_ROUTE_NAME = SITE_ROUTE_NAME + "_ANALYTIC_LIST";
const COPILOT_INTEGRATION_LIST_ROUTE_NAME = SITE_ROUTE_NAME + "_COPILOT_LIST";
const TELEPHONY_INTEGRATION_LIST_ROUTE_NAME = SITE_ROUTE_NAME + "_TELEPHONY_LIST";


//const CONFIG_EVENTS_ROUTE_NAME = SITE_ROUTE_NAME + "_EVENTS";

//let SITE_CONFIG_LOADING_ROUTE_NAME = SITE_CONFIG_ROUTE_NAME + "_Loading";

let dashboardMenu = DashboardRouteBuilder.getMenu(SITE_DASHBOARD_ROUTE_NAME);
let commonDashboardMenu = DashboardRouteBuilder.getMenu(COMMON_REPORTS_DASHBOARD_ROUTE_NAME);


export default class OnlineChatRouteBuilder extends UslugaRouteBuilder {
    private oldTemplate: string;


    public setOldTemplate(template: string) {
        this.oldTemplate = template;
        return this;
    }

    public static getRootRouteLocation(): RouteLocationNamedRaw {
        return {name: ROOT_ROUTE_NAME};
    }

    public static getSiteRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, SITE_REPORTS_ROUTE_NAME);
    }

    public static getDialogsRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, DIALOGS_ALL);
    }

    public static getDialogConfigRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, CONFIG_DIALOG);
    }

    public static getRatesRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, REPORT_RATES);
    }

    public static getSiteLicenseRouteLocation(siteId?: string): RouteLocationNamedRaw {
        if (siteId) {
            return this.getSiteRouteLocationByName(siteId, LICENSE);
        } else {
            return {
                name: SITES_LICENSE_LIST
            };
        }
    }

    public static getClientListRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, REPORT_CLIENTS_LIST);
    }

    public static getOfflineQuestionsRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, DIALOGS_NOT_FINISHED_OFFLINE);
    }

    public static getConfigRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, SITE_CONFIG_ROUTE_NAME);
    }

    public static getIntegrationsRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, INTEGRATION_ROUTE_NAME);
    }

    public static getApiRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, API);
    }


    public static getBotConfigRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, CONFIG_BOT);
    }

    public static getConfigSetupRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, CONFIG_SETUP);
    }


    public static getOperatorsConfigRouteLocation(siteId: string): RouteLocationNamedRaw {
        return this.getSiteRouteLocationByName(siteId, CONFIG_OPERATORS);
    }

    private static getSiteRouteLocationByName(siteId: string, name: string): RouteLocationNamedRaw {
        return {name, params: {[PARAM_SITE_ID]: siteId}};
    }

    private siteListDropdown: IBeforeLeftMenuDropdown = {
        items: null,
        back: {
            name: ROOT_ROUTE_NAME
        }
    };

    private async fetchSiteListForDropdown(force: boolean = false) {
        if (!this.siteListDropdown.items || force) {
            let result = await (new LcabApiRequest({
                url: "/api/online/sites/getListForDropdown"
            })).send();
            if (result.isSuccess) {
                let sites: {
                    id: string,
                    title: string,
                    enabled: boolean
                }[] = (new LcabApiFetchListResult(result)).items;
                if (!this.siteListDropdown.items) {
                    this.siteListDropdown.items = [];
                }
                sites = sites.sort((a, b) => {
                    if (a.enabled === b.enabled) {
                        return a.title < b.title ? -1 : 1;
                    }
                    return a.enabled ? -1 : 1;
                });
                let items = this.siteListDropdown.items;
                items.splice(0, items.length);
                for (let site of sites) {
                    items.push(
                        (new MenuItem(SITE_ROUTE_NAME, site.title))
                            .addRouteParam(PARAM_SITE_ID, site.id)
                            .setBadge(
                                site.enabled ? null : (new MenuBadge(`site-list-dropdown-${site.id}`, undefined, "Откл"))
                                    .setType("info")
                            )
                            .setReplaceRouteNameByCurrent({
                                [CONFIG_EDIT_ADDITIONAL_TAB_ROUTE_NAME]: CONFIG_ADDITIONAL_TABS_ROUTE_NAME
                            })
                    );
                }
            }
        }
    }

    public static getEventsRawLocation(siteId: string): RouteLocationNamedRaw {
        return {
            name: CLIENT_EVENTS_ROUTES_BUILDER.authRouteName,
            params: {
                [PARAM_PLATFORM]: ONLINE_CHAT_PLATFORM_ID,
                [PARAM_AUTH]: siteId
            }
        }
    }

    protected makeRoute(): RouteRecordRaw {

        let lastSiteId: string;


        let reportMenu = (new MenuItem(SITE_REPORTS_ROUTE_NAME, "Отчёты", "chart-pie"))
            .addChildrenItem(dashboardMenu)
            .addChildrenItem(new MenuItem(REPORT_ACTIVITY, "Посещаемость"))
            .addChildrenItem(new MenuItem(REPORT_OPERATORS_CHATS, "Отчёты по операторам"))
            .addChildrenItem(new MenuItem(REPORT_OPERATORS_WORKTIME, "Рабочее время"))
            .addChildrenItem(new MenuItem(REPORT_OPERATORS_WORKLOAD, "Загруженность по часам"))
            .addChildrenItem(
                (new MenuItem(REPORT_RATES, "Отзывы"))
                    .setBadge(new MenuBadge("online_reviews", () => ({siteId: lastSiteId})))
            )
            .addChildrenItem(new MenuItem(REPORT_CLIENTS_LIST, "Список посетителей"))
            .addChildrenItem(new MenuItem(REPORT_BOTS, "Боты"))
            .addChildrenItem(new MenuItem(REPORT_BLOCKED_CLIENTS_LIST, "Заблокированные посетители"))
            .addChildrenItem(new MenuItem(REPORT_FORMS, "Формы"))
            .addChildrenItem(new MenuItem(REPORT_SITE_PAGES, "Страницы сайта"))
            .addChildrenItem(new MenuItem(REPORT_CITY, "География"))
            .addChildrenItem(new MenuItem(REPORT_REFERER, "Ссылающиеся сайты"))
            .addChildrenItem(new MenuItem(REPORT_PAYMENTS, "Приём платежей"))
            //.addChildrenItem((new MenuItemHref(() => getOldCabinetUrl("reports/actions"), "Автодействия")))
            .addChildrenItem(new MenuItem(REPORT_TARGET, "Целевые посетители"))
            .addChildrenItem(new MenuItem(REPORT_QUESTION_CATEGORIES, "Категории вопросов"))
            .addChildrenItem(new MenuItem(REPORT_TAGS, "Тэги"))
            .addChildrenItem(new MenuItem(REPORT_CHANNELS, "Каналы связи"))
            .addChildrenItem(new MenuItem(REPORT_MESSAGE_TEMPLATES, "Шаблоны сообщений"));

        let configMenu = (new MenuItem(SITE_CONFIG_ROUTE_NAME, __("Настройки"), "cog"))
            .addChildrenItem(new MenuItem(CONFIG_SETUP, "Установка"))
            .addChildrenItem(new MenuItem(CONFIG_LOADING_ROUTE_NAME, "Загрузка чата"))
            .addChildrenItem(new MenuItem(SITE_CONFIG_DESIGN_ROUTE_NAME, __("Внешний вид")))
            .addChildrenItem(new MenuItem(CONFIG_OPERATORS, "Операторы"))
            .addChildrenItem(new MenuItem(CONFIG_OPERATOR_GROUPS, "Отделы"))
            .addChildrenItem(new MenuItem(CONFIG_DIALOG, "Диалоги"))
            .addChildrenItem(new MenuItem(CONFIG_ONLINE_TAB_ROUTE_NAME, "Online-режим виджета"))
            .addChildrenItem(new MenuItem(CONFIG_OFFLINE_TAB_ROUTE_NAME, "Offline-режим виджета"))
            .addChildrenItem(new MenuItem(CONFIG_REVIEWS_TAB_ROUTE_NAME, "Отзывы"))
            .addChildrenItem(new MenuItem(CONFIG_ADDITIONAL_TABS_ROUTE_NAME, "Дополнительные вкладки"))
            .addChildrenItem(new MenuItem(CONFIG_AUTO_ACTIONS, "Автодействия"))
            .addChildrenItem((new MenuItem(CONFIG_BOT, "Боты")).setBadge(
                (new MenuBadge("onlineChatBots", {}, null))
                    .setIcon("fire-alt", "#e74c3c")
            ))
            .addChildrenItem(new MenuItem(CONFIG_FIELDS, "Поля"))
            .addChildrenItem(new MenuItem(CONFIG_TAGS, "Тэги"))
            .addChildrenItem(new MenuItem(CONFIG_MULTI_CONTACTS, "Мульти-контакты"))
            .addChildrenItem(new MenuItem(CONFIG_MESSAGE_TEMPLATES, "Шаблоны ответа"))
            .addChildrenItem(new MenuItem(CONFIG_QUESTION_CATEGORIES, "Категории вопросов"))
            .addChildrenItem(new MenuItem(CONFIG_SENDERS, "Рассылки"))
            .addChildrenItem(new MenuItem(CONFIG_BANNED_WORDS, "Стоп-слова"))
            //.addChildrenItem(new MenuItem(CONFIG_EVENTS_ROUTE_NAME, "События"))
            .addChildrenItem(
                (new MenuItem(CLIENT_EVENTS_ROUTES_BUILDER.authRouteName, "События"))
                    .addRouteParam(PARAM_PLATFORM, ONLINE_CHAT_PLATFORM_ID)
                    .addRouteParam(PARAM_AUTH, () => lastSiteId)
            )

            .addChildrenItem(new MenuItem(CONFIG_KNOWLEDGE_BASE, "База знаний").setBadge(
                (new MenuBadge("onlineChatKnowledgeBase", {}, null))
                    .setIcon("fire-alt", "#e74c3c")
            ))
            .addChildrenItem(new MenuItem(CONFIG_EULA, "Персональные данные"))
            .addChildrenItem(new MenuItem(CONFIG_TIMEZONE, "Часовой пояс"))
            .addChildrenItem(new MenuItem(CONFIG_FILE_STORAGE, "Файловое хранилище"))
            .addChildrenItem(new MenuItem(CONFIG_JAVASCRIPT_API, "Javascript API"))
        ;

        let dialogMenu = (new MenuItem(SITE_DIALOGS_ROUTE_NAME, "Чаты", "comments"))
            .addChildrenItem(new MenuItem(REPORT_DIALOGS, "Диалоги"))
            .addChildrenItem(new MenuItem(DIALOGS_ALL, "Все чаты"))
            .addChildrenItem(
                (new MenuItem(DIALOGS_NOT_FINISHED_OFFLINE, "Незавершённые Offline-вопросы"))
                    .setBadge(new MenuBadge("online_haveNoAnswerClients", () => ({siteId: lastSiteId})))
            )
            .addChildrenItem(new MenuItem(DIALOGS_SKIPPED, "Пропущенные чаты"));

        return {
            path: "/online",
            name: ROOT_ROUTE_NAME,
            component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/OnlineRoot.vue'),
            children: [
                {
                    name: SITES_ROUTE_NAME,
                    path: "sites",
                    component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/sites/Sites.vue')
                },
                {
                    name: SITES_LICENSE_LIST,
                    path: "licenseList",
                    component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/license/LicenseList.vue'),
                    meta: {
                        access: [
                            () => AccountStore.access.value.checkAccessForOnlineConfigLicense()
                        ]
                    } as IRouteMeta
                },
                {
                    name: COMMON_REPORTS,
                    path: "reports",
                    children: [
                        DashboardRouteBuilder.getRouteConfig(COMMON_REPORTS_DASHBOARD_ROUTE_NAME, () => `onlineChatCommon`),
                        {
                            name: COMMON_REPORTS_OPERATORS,
                            path: "operatorsChats",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorChatReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_OPERATORS_WORKLOAD,
                            path: "operatorsWorkload",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorWorkLoadReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_OPERATORS_WORKTIME,
                            path: "operatorsWorktime",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorWorkTimeReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_ACTIVITY,
                            path: "activity",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/activity/Activity.vue')
                        },
                        {
                            name: COMMON_REPORTS_FORM,
                            path: "forms",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/forms/FormsReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_RATES,
                            path: "rates",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/rates/RatesReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_QUESTION_CATEGORIES,
                            path: "questionCategories",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/questionCategories/QuestionCategories.vue')
                        },
                        {
                            name: COMMON_REPORTS_TAGS,
                            path: "tags",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/tags/TagsReport.vue')
                        },
                        {
                            name: COMMON_REPORTS_MESSAGE_TEMPLATES,
                            path: "messageTemplates",
                            component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                            props: () => ({
                                reportId: "onlineChatMessageTemplateUsage"
                            })
                        }
                    ]
                },
                {
                    name: COMMON_CONFIG,
                    path: "config",
                    children: [
                        {
                            name: COMMON_CONFIG_OPERATOR_STATUS,
                            path: "customOperatorStatus",
                            component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/customOperatorStatus/CustomOperatorsStatusConfig.vue')
                        },
                        {
                            name: COMMON_CONFIG_STICKER_PACK,
                            path: "stickers",
                            component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/stickers/Stickers.vue')
                        },
                        {
                            name: COMMON_CONFIG_TRANSLATE_USER_LANGUAGE,
                            path: "userLanguage",
                            component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/userLanguage/UserLanguage.vue')
                        }
                    ],
                    meta: {
                        access: [
                            () => AccountStore.access.value.isAdmin
                        ]
                    } as IRouteMeta
                },
                {
                    name: ONLINE_CHAT_DISCOUNT_COUPON_ROUTE_NAME,
                    path: "discountCoupon",
                    component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/discountCoupon/OnlineDiscountCouponPage.vue'),
                    meta: {
                        access: [
                            () => !!AccountStore.data.value?.onlineDiscountCoupon
                        ]
                    } as IRouteMeta
                },
                {
                    name: SITE_ROUTE_NAME,
                    path: "site/:" + PARAM_SITE_ID,
                    beforeEnter: async (to, from, next) => {
                        lastSiteId = to.params[PARAM_SITE_ID] as string;
                        await this.fetchSiteListForDropdown(true);
                        next();
                    },
                    props: (route) => ({
                        siteId: route.params[PARAM_SITE_ID]
                    }),
                    component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/sites/Site.vue'),
                    children: [
                        {
                            name: SITE_REPORTS_ROUTE_NAME,
                            path: "reports",
                            children: [
                                DashboardRouteBuilder.getRouteConfig(SITE_DASHBOARD_ROUTE_NAME, () => `onlineChat_${lastSiteId}`),
                                {
                                    name: REPORT_ACTIVITY,
                                    path: "activity",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/activity/Activity.vue')
                                },
                                {
                                    name: REPORT_CITY,
                                    path: "city",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/city/City.vue')
                                },
                                {
                                    name: REPORT_REFERER,
                                    path: "referer",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/referer/Referer.vue')
                                },
                                {
                                    name: REPORT_TARGET,
                                    path: "targetClients",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/targetClients/TargetClients.vue')
                                },
                                {
                                    name: REPORT_QUESTION_CATEGORIES,
                                    path: "questionCategories",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/questionCategories/QuestionCategories.vue')
                                },
                                {
                                    name: REPORT_TAGS,
                                    path: "tags",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/tags/TagsReport.vue')
                                },
                                {
                                    name: REPORT_CHANNELS,
                                    path: "channels",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/channels/Channels.vue')
                                },
                                {
                                    name: REPORT_MESSAGE_TEMPLATES,
                                    path: "messageTemplates",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/interface/CommonSummaryReport.vue'),
                                    props: (route) => ({
                                        reportId: "onlineChatMessageTemplateUsage",
                                        params: {
                                            siteId: route.params[PARAM_SITE_ID]
                                        }
                                    })
                                },
                                {
                                    name: REPORT_OPERATORS_CHATS,
                                    path: "operatorsChats",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorChatReport.vue')
                                },
                                {
                                    name: REPORT_OPERATORS_WORKTIME,
                                    path: "workTime",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorWorkTimeReport.vue')
                                },
                                {
                                    name: REPORT_OPERATORS_WORKLOAD,
                                    path: "workload",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/operators/OperatorWorkLoadReport.vue')
                                },
                                {
                                    name: REPORT_CLIENTS_LIST,
                                    path: "clients",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/clients/ClientsListReport.vue')
                                },
                                {
                                    name: REPORT_BLOCKED_CLIENTS_LIST,
                                    path: "blockedClients",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/clients/ClientsListReport.vue'),
                                    props: {
                                        blockedReport: true
                                    }
                                },
                                {
                                    name: REPORT_RATES,
                                    path: "rates",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/rates/RatesReport.vue')
                                },
                                {
                                    name: REPORT_BOTS,
                                    path: "bots",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/bots/BotsReport.vue')
                                },
                                {
                                    name: REPORT_SITE_PAGES,
                                    path: "sitePages",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/sitePages/SitePagesReport.vue')
                                },
                                {
                                    name: REPORT_FORMS,
                                    path: "forms",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/forms/FormsReport.vue')
                                },
                                {
                                    name: REPORT_PAYMENTS,
                                    path: "payments",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/payments/PaymentsReport.vue')
                                }

                            ]
                        },
                        {
                            name: SITE_DIALOGS_ROUTE_NAME,
                            path: "dialogs",
                            children: [
                                {
                                    name: REPORT_DIALOGS,
                                    path: "dialogs",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/dialogs/DialogsReport.vue')
                                },
                                {
                                    name: DIALOGS_ALL,
                                    path: "all",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/chats/AllDialogsReport.vue')
                                },
                                {
                                    name: DIALOGS_SKIPPED,
                                    path: "skipped",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/chats/SkippedDialogsReport.vue')
                                },
                                {
                                    name: DIALOGS_NOT_FINISHED_OFFLINE,
                                    path: "notFinishedOffline",
                                    component: () => import(/* webpackChunkName: "client-online-report" */ '~/cabinet/vue/client/online/reports/chats/NotFinishedOfflineDialogsReport.vue')
                                }
                            ]
                        },
                        {
                            name: SITE_CONFIG_ROUTE_NAME,
                            path: "config",
                            children: [
                                {
                                    name: CONFIG_SETUP,
                                    path: "setup",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/setup/Setup.vue')
                                },
                                {
                                    name: SITE_CONFIG_DESIGN_ROUTE_NAME,
                                    path: "design",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/design/Design.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigDesign()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_LOADING_ROUTE_NAME,
                                    path: "loading",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/loading/LoadingConfig.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigLoading()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_ONLINE_TAB_ROUTE_NAME,
                                    path: "online",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/TabEdit.vue'),
                                    props: {
                                        tabId: EDIT_ONLINE_TAB_ID
                                    },
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigWidgetOnlineMode()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_OFFLINE_TAB_ROUTE_NAME,
                                    path: "offline",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/TabEdit.vue'),
                                    props: {
                                        tabId: EDIT_OFFLINE_TAB_ID
                                    },
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigWidgetOfflineMode()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_REVIEWS_TAB_ROUTE_NAME,
                                    path: "reviews",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/TabEdit.vue'),
                                    props: {
                                        tabId: EDIT_REVIEWS_TAB_ID
                                    },
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigRate()
                                    } as IRouteMeta
                                },

                                {
                                    name: CONFIG_ADDITIONAL_TABS_ROUTE_NAME,
                                    path: "additionalTabs",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/AdditionalTabsList.vue'),
                                    props: {
                                        routeName: CONFIG_ADDITIONAL_TABS_ROUTE_NAME
                                    },
                                    children: [
                                        {
                                            name: CONFIG_EDIT_ADDITIONAL_TAB_ROUTE_NAME,
                                            path: "edit/:tabId",
                                            component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/TabEdit.vue'),
                                            props: route => ({
                                                tabId: route.params.tabId
                                            })
                                        }
                                    ],
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigWidgetTabs()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_AUTO_ACTIONS,
                                    path: "autoActions",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/autoActions/AutoActions.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigAutoActions()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_KNOWLEDGE_BASE,
                                    path: "knowledgeBase",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/knowledgeBase/KnowledgeBase.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigKnowledgeBase()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_MESSAGE_TEMPLATES,
                                    path: "messageTemplates",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/messageTemplates/MessageTemplates.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigMessageTemplate()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_QUESTION_CATEGORIES,
                                    path: "questionCategories",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/questionCategories/QuestionCategories.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigQuestionCategory()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_MULTI_CONTACTS,
                                    path: "multiContacts",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/multiContacts/MultiContacts.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigMultiContact()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_OPERATORS,
                                    path: "operators",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/operators/Operators.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigOperator()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_OPERATOR_GROUPS,
                                    path: "operatorGroups",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/operators/OperatorGroups.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigOperatorGroup()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_BANNED_WORDS,
                                    path: "bannedWords",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/bannedWords/BannedWords.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigBannedWords()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_EULA,
                                    path: "eula",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/eula/Eula.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigEula()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_TIMEZONE,
                                    path: "timezone",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/timeZone/TimeZone.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigTimezone()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_FILE_STORAGE,
                                    path: "fileStorage",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/fileStorage/FileStorage.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigFileshare()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_JAVASCRIPT_API,
                                    path: "javascriptApi",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/javascriptApi/JavascriptApi.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigJavascript()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_FIELDS,
                                    path: "fields",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tabs/fields/FieldsListConfig.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigFields()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_TAGS,
                                    path: "tags",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/tags/TagsListConfig.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigTags()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_BOT,
                                    path: "bot",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/bot/BotRegisterList.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigBots()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_DIALOG,
                                    path: "dialogs",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/dialogs/Dialogs.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigDialog()
                                    } as IRouteMeta
                                },
                                {
                                    name: CONFIG_SENDERS,
                                    path: "senders",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/senders/Senders.vue'),
                                    meta: {
                                        access: () => AccountStore.access.value.checkAccessForOnlineConfigSender()
                                    } as IRouteMeta
                                }
                            ],
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForOnlineConfig()
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "license",
                            name: LICENSE,
                            component: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/license/License.vue'),
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForOnlineConfigLicense()
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "integrations",
                            name: INTEGRATION_ROUTE_NAME,
                            children: [
                                {
                                    name: MESSENGERS_INTEGRATION_LIST_ROUTE_NAME,
                                    path: "messengers",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/integrations/MessengerIntegrations.vue')
                                },
                                {
                                    name: CRM_INTEGRATION_LIST_ROUTE_NAME,
                                    path: "crm",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/integrations/DataProviderIntegrations.vue'),
                                    props: {
                                        platformTypeId: INTEGRATION_PLATFORM_TYPE_CRM
                                    }
                                },
                                {
                                    name: ANALYTIC_INTEGRATION_LIST_ROUTE_NAME,
                                    path: "analytic",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/integrations/DataProviderIntegrations.vue'),
                                    props: {
                                        platformTypeId: INTEGRATION_PLATFORM_TYPE_ANALYTIC
                                    }
                                },
                                {
                                    name: TELEPHONY_INTEGRATION_LIST_ROUTE_NAME,
                                    path: "telephony",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/integrations/TelephonyIntegrations.vue')
                                },
                                {
                                    name: COPILOT_INTEGRATION_LIST_ROUTE_NAME,
                                    path: "copilot",
                                    component: () => import(/* webpackChunkName: "client-online-config" */ '~/cabinet/vue/client/online/config/integrations/CopilotIntegrations.vue'),
                                    meta: {
                                        access: () => AccountStore.isSandbox.value
                                    } as IRouteMeta
                                },
                                {
                                    path: "counters",
                                    name: ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME,
                                    component: () => import(/* webpackChunkName: "client-online-counters" */ '~/cabinet/vue/client/online/counters/Counters.vue'),
                                    /*
                                    children: [
                                        {
                                            name: YANDEX_METRIKA_ROUTE_NAME,
                                            path: "yandex",
                                            component: () => import(/* webpackChunkName: "client-online-counters" * / '~/cabinet/vue/client/online/counters/YandexMetrika.vue'),
                                        },
                                        {
                                            name: GOOGLE_ANALYTICS_ROUTE_NAME,
                                            path: "google",
                                            component: () => import(/* webpackChunkName: "client-online-counters" * / '~/cabinet/vue/client/online/counters/GoogleAnalytics.vue'),
                                        }
                                    ]*/
                                },
                            ],
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForOnlineConfig()
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "api",
                            name: API,
                            meta: {
                                access: () => AccountStore.access.value.isAdmin
                            } as IRouteMeta,
                            children: [
                                {
                                    name: REST_API,
                                    path: "rest",
                                    component: () => import(/* webpackChunkName: "client-api" */ '~/cabinet/vue/api/ApiTokens.vue'),
                                    props: {
                                        typeId: "online"
                                    }
                                },
                                {
                                    name: WEBHOOKS,
                                    path: "webhooks",
                                    component: () => import(/* webpackChunkName: "client-api" */ '~/cabinet/vue/api/Webhooks.vue'),
                                    props: {
                                        platformId: "onlineChat"
                                    }
                                }
                            ]
                        }
                    ],
                    meta: {
                        keepAlive: false,
                        beforeLeftMenuDropdown: this.siteListDropdown,
                        beforeEach: (to, _from) => {
                            lastSiteId = to.params[PARAM_SITE_ID] as string;
                        },
                        menu: [
                            dialogMenu,
                            reportMenu,
                            (new MenuItem(INTEGRATION_ROUTE_NAME, "Интеграции"))
                                .setIcon("chart-network")
                                .addChildrenItem(
                                    new MenuItem(MESSENGERS_INTEGRATION_LIST_ROUTE_NAME, "Каналы связи")
                                )
                                .addChildrenItem(new MenuItem(CRM_INTEGRATION_LIST_ROUTE_NAME, "CRM"))
                                .addChildrenItem(new MenuItem(ANALYTIC_INTEGRATION_LIST_ROUTE_NAME, "Сквозная аналитика"))
                                .addChildrenItem(
                                    (new MenuItem(TELEPHONY_INTEGRATION_LIST_ROUTE_NAME, "Телефония"))
                                        .setBadge(
                                            (new MenuBadge("telephony", {}, "Beta"))
                                        )
                                )
                                .addChildrenItem(
                                    (new MenuItem(COPILOT_INTEGRATION_LIST_ROUTE_NAME, "Copilot"))
                                        .setBadge(
                                            (new MenuBadge("copilot", {}, "Beta"))
                                        )
                                )
                                /*
                                .addChildrenItem(
                                    (new MenuItemHref(() => getOldCabinetUrl("api"), "CRM (старая версия)"))
                                        .setTargetBlank(true)
                                )

                                 */
                                .addChildrenItem(
                                    new MenuItem(ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME, 'Счётчики для сайта')
                                ),
                            configMenu,
                            (new MenuItem(LICENSE, "Лицензия")).setIcon("ruble-sign"),
                            (new MenuItem(API, "API онлайн чата"))
                                .setIcon("code")
                                .addChildrenItem(
                                    new MenuItem(REST_API, 'Rest API')
                                )
                                .addChildrenItem(
                                    new MenuItem(WEBHOOKS, 'Webhook-и')
                                )
                                .addChildrenItem(
                                    (new MenuItem(PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME, 'Javascript API виджета'))
                                        .setTargetBlank(true)
                                ),
                            /*
                            (new MenuItem(ANALYTIC_COUNTERS_INTEGRATION_ROUTE_NAME, 'Интеграция со счётчиками'))
                                .setIcon("chart-bar")
                                .addChildrenItem(
                                    new MenuItem(
                                        YANDEX_METRIKA_ROUTE_NAME,
                                        'Яндекс.Метрика'
                                    )
                                )
                                .addChildrenItem(
                                    new MenuItem(
                                        GOOGLE_ANALYTICS_ROUTE_NAME,
                                        'Google Analytics'
                                    )
                                )*/
                        ]
                    } as IRouteMeta
                }
            ],
            meta: {
                afterLeftMenu: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/online/discountCoupon/DiscountCouponInLeftMenu.vue')),
                menu: [
                    new MenuItem(SITES_ROUTE_NAME, "Ваши сайты", "globe"),
                    new MenuItem(SITES_LICENSE_LIST, "Лицензии", "ruble-sign"),
                    (new MenuItem(COMMON_REPORTS, "Отчёты", "pie-chart"))
                        .addChildrenItem(commonDashboardMenu)
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_OPERATORS, "Отчёты по операторам"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_OPERATORS_WORKLOAD, "Загруженность по часам"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_OPERATORS_WORKTIME, "Рабочее время"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_ACTIVITY, "Посещаемость"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_FORM, "Формы"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_RATES, "Отзывы"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_QUESTION_CATEGORIES, "Категории вопросов"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_TAGS, "Тэги"))
                        .addChildrenItem(new MenuItem(COMMON_REPORTS_MESSAGE_TEMPLATES, "Шаблоны сообщений")),
                    (new MenuItem(COMMON_CONFIG, "Настройки", "gears"))
                        .addChildrenItem(new MenuItem(COMMON_CONFIG_OPERATOR_STATUS, "Свои статусы операторов"))
                        .addChildrenItem(new MenuItem(COMMON_CONFIG_STICKER_PACK, "Стикеры"))
                        .addChildrenItem(new MenuItem(COMMON_CONFIG_TRANSLATE_USER_LANGUAGE, "Свои переводы интерфейса"))
                ],
                redirectToChildren: [{
                    name: SITES_ROUTE_NAME,
                    path: undefined
                }]
            } as any as IRouteMeta
        }
    }

}