import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-filter-value-buttonset"
}
const _hoisted_2 = { key: 1 }

import {computed, onMounted, ref} from 'vue';
import {IFilter, IFiltersData, IOperation, IValueComponent} from "./Interfaces";
import MyFilterValueComponent from "~/cabinet/vue/interface/filter/MyFilterValueComponent";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import MyFilterValuesItemInterface from "~/cabinet/vue/interface/filter/MyFilterValuesItemInterface";
import CombinedFilterValue from "~/cabinet/vue/interface/filter/types/CombinedFilterValue";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import Delay from "~/ts/library/Delay";
import {iconLight} from "~/ts/vuePlugins/IconPlugin";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";
import {__} from "~/ts/library/Translate";


import MyFilterText from "./types/MyFilterText.vue";
import MyFilterSelect from "./types/MyFilterSelect.vue";
import MyFilterDate from "./types/MyFilterDate.vue";
import MyFilterOrganizationLocation from "./types/MyFilterOrganizationLocation.vue";
import MyFilterCombined from "~/cabinet/vue/interface/filter/types/MyFilterCombined.vue";
import MyFilterHidden from "~/cabinet/vue/interface/filter/types/MyFilterHidden.vue";
import MyFilterSeconds from "~/cabinet/vue/interface/filter/types/MyFilterSeconds.vue";
import MyFilterTimeRange from "~/cabinet/vue/interface/filter/types/MyFilterTimeRange.vue";
import MyFilterTime from "~/cabinet/vue/interface/filter/types/MyFilterTime.vue";

const __default__ = {
    name: "MyFilterValuesItem",
    components: {
        MyFilterText, MyFilterSelect, MyFilterDate,
        MyFilterOrganizationLocation, MyFilterCombined, MyFilterHidden,
        MyFilterSeconds, MyFilterTimeRange, MyFilterTime
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    filter: {},
    operation: {},
    canDelete: { type: Boolean, default: false },
    canAdd: { type: Boolean, default: false },
    combinedFilterValue: {},
    filtersData: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["add", "delete"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<any>(__props, "modelValue");

let valueForInvisibleFilter = ref(true);

onMounted(() => {
    if (!hasVisibleFilters.value) {
        if (model.value != valueForInvisibleFilter.value) {
            onInput(valueForInvisibleFilter.value, 0);
        }
    }
});

let localValue = computed(() => {
    if (isSingleValue.value) {
        return model.value;
    } else {
        return model.value ? model.value : [];
    }
});

let components = computed<IValueComponent[]>(() => {
    let result = [];
    for (let i = 0; i < filters.value.length; i++) {
        result.push(getMyValueComponent(filters.value[i]));
    }
    return result;
});

let filters = computed<IFilter[]>(() => {
    return props.operation.filterInputs == null ? [props.filter] : props.operation.filterInputs;
});

let isSingleValue = computed(() => {
    return filters.value.length == 1 || isSingleMultipleComponentSituation.value;
});

function getMyValueComponent(filter: IFilter): MyFilterValueComponent {
    return new MyFilterValueComponent(filter, props.operation, props.filter);
}

let isSingleMultipleComponentSituation = computed(() => {
    return MyFilterHelper.isSingleMultipleComponentSituation(props.filter, props.operation);
});

let showButtonset = computed(() => {
    return props.canAdd || props.canDelete;
});

async function onInput(value: any, index: number) {
    if (Array.isArray(localValue.value)) {
        if (localValue.value.length < index + 1) {
            await Delay.make((index * 50) + 1);
        }
    }

    let newValue = value;
    if (hasVisibleFilters.value) {
        if (!isSingleValue.value) {
            newValue = [...localValue.value];
            newValue.splice(index, 1, value);
        }
    } else {
        newValue = null;
    }

    model.value = newValue;
}

let hasVisibleFilters = computed(() => {
    return MyFilterHelper.hasVisibleFilters(props.filter, props.operation);
});


let componentRef = ref<any[]>();

function focus() {
    let components = componentRef.value;

    if (components && components[0]) {
        if (typeof components[0].focus == "function") {
            components[0].focus();
        }
    }
}

__expose<MyFilterValuesItemInterface>({
    focus
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['my-filter-value', _ctx.canDelete ? 'can-delete' : '', _ctx.canAdd && !_unref(isMobileWindowSize) ? 'can-add' : '', !_unref(showButtonset) ? 'unremovable' : ''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(components), (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(['w-' + Math.ceil(100 / _unref(components).length), 'my-filter-value-input-wrapper'])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
          class: "w-100",
          component: item,
          components: _unref(components),
          operation: _ctx.operation,
          "model-value": _unref(isSingleValue) ? _unref(localValue) : _unref(localValue)[index],
          "combined-filter-value": _ctx.combinedFilterValue,
          "filters-data": _ctx.filtersData,
          index: index,
          "onUpdate:modelValue": ($event: any) => (onInput($event, index)),
          ref_for: true,
          ref_key: "componentRef",
          ref: componentRef
        }, null, 8, ["component", "components", "operation", "model-value", "combined-filter-value", "filters-data", "index", "onUpdate:modelValue"]))
      ], 2))
    }), 128)),
    (_unref(showButtonset))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.canAdd)
            ? (_openBlock(), _createBlock(MyIconButton, {
                key: 0,
                class: "my-filter-add-value-button",
                icon: _unref(iconLight)('plus'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('add')))
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createVNode(MyIconButton, {
            delete: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('delete'))),
            style: { marginLeft: '3px' }
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.canAdd && _unref(isMobileWindowSize))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.canAdd)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                link: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('add')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(__)('или')) + "... ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})