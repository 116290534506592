import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "screen-dynamic device-preview-part" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "device-preview-header" }
const _hoisted_4 = { class: "screen-button device-preview-part" }
const _hoisted_5 = { class: "monitor-statement-1 device-preview-part" }
const _hoisted_6 = { class: "monitor-statement-2 desktop device-preview-part" }

import {computed, nextTick, onMounted, ref, watch} from 'vue';
import WindowHelper from "~/ts/library/WindowHelper";
import {
    DEVICE_DESKTOP,
    DEVICE_MOBILE,
    DEVICE_TABLET,
    DeviceId,
} from "~/ts/library/Device";


const __default__ = {
    name: "DevicePreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    type: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;


let isMounted = ref(false);

onMounted(() => {
    isMounted.value = true;
    calculateHeight();
});

let isMobileDevice = computed(() => {
    return [DEVICE_MOBILE, DEVICE_TABLET].indexOf(props.type) > -1;
});

let outer = ref<HTMLElement>();
let height = ref<string>();

watch(computed(() => props.type), () => calculateHeight());

function calculateHeight() {
    nextTick(() => {
        if (WindowHelper.getSize().width && outer.value && props.type) {
            let outerWidth = outer.value.offsetWidth;
            let d = props.type == DEVICE_DESKTOP ? (3 / 4) : 1.5625;
            height.value = parseInt((outerWidth * d as any)) + "px";
        } else {
            height.value = null;
        }
    });
}

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['device-preview', _ctx.type])
  }, [
    _createElementVNode("div", {
      class: "screen-outer-border device-preview-part",
      ref_key: "outer",
      ref: outer
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
        [_vShow, _unref(isMobileDevice)]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({height: _unref(isMounted) ? _unref(height) : null}),
        class: "screen-inner-border device-preview-part"
      }, [
        (_ctx.$slots.header)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header")
              ])
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 4)), [
        [_directive_loading, _ctx.loading]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
        [_vShow, _unref(isMobileDevice)]
      ])
    ], 512),
    _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
      [_vShow, !_unref(isMobileDevice)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
      [_vShow, !_unref(isMobileDevice)]
    ])
  ], 2))
}
}

})