import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import Dictionary from "~/ts/library/Dictionary";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import Delay from "~/ts/library/Delay";
import {reactive} from "vue";
import CabinetAnalyticCountersSenderInstance from "~/cabinet/ts/service/CabinetAnalyticCountersSender";

export const BADGE_STICKERS = "stickers";

interface Item {
    count: number,
    badge: MenuBadge
}

let values: any = reactive({});

export default class BadgeManager {
    //private static interval: Timeout;

    private static list: Dictionary<Item> = {};

    //private static values: Dictionary<any> = {};


    public static getKey(badge: MenuBadge) {
        let result = badge.id;
        if (badge.params) {
            result += "_" + JSON.stringify(badge.params);
        }
        return result;
    }

    public static async add(badge: MenuBadge) {
        let key = this.getKey(badge);
        let item = this.list[key];
        if (!item) {
            item = {
                count: 1,
                badge: badge
            };
            this.list[key] = item;
            try {
                await Delay.make(100, "BadgeManagerAdd");
                this.fetch();
            } catch (e) {

            }
        } else {
            item.count++;
        }
    }

    public static removeByKey(key: string) {
        let item = this.list[key];

        if (item) {
            if (item.count == 1) {
                delete this.list[key];
            } else {
                item.count--;
            }
        }
    }

    public static remove(badge: MenuBadge) {
        let key = this.getKey(badge);
        this.removeByKey(key);
    }

    public static async fetch() {
        let list = [];
        for (let key in this.list) {
            if (this.list.hasOwnProperty(key)) {
                let badge = this.list[key].badge;
                list.push({
                    key: key,
                    id: badge.id,
                    params: badge.params
                });
            }
        }
        if (list.length) {
            let result = await (new LcabApiRequest({
                url: "/api/badge/getBadges",
                p: {
                    list: list,
                    getAnalyticCountersQueue: CabinetAnalyticCountersSenderInstance.isAvailable()
                },
                silent: true,
                silentError: true
            })).send();
            if (result.isSuccess) {
                let newValues = result.getData("values");

                for (let key in values) {
                    if (!newValues.hasOwnProperty(key)) {
                        values[key] = null;
                    }
                }
                for (let key in newValues) {
                    if (newValues.hasOwnProperty(key)) {
                        //if (value) {
                        values[key] = newValues[key];
                        //}
                    }
                }

                let analyticCountersQueue: {
                    cc: number,
                    event: string,
                    sum?: number,
                    currencyId?: string
                }[] = newValues.analyticCountersQueue;
                if (Array.isArray(analyticCountersQueue)) {
                    (async () => {
                        let sentList = [];
                        for (let item of analyticCountersQueue) {
                            try {
                                await CabinetAnalyticCountersSenderInstance.reachGoal(item.event, item.sum, item.currencyId);
                                sentList.push(item);
                            } catch (e) {

                            }
                        }
                        if (sentList.length) {
                            (new LcabApiRequest({
                                url: "/api/badge/sentAnalyticCountersList",
                                p: {
                                    list: sentList
                                },
                                silent: true,
                                silentError: true
                            })).send();
                        }
                    })();
                }
            }
        }
    }

    public static getValue(badge: MenuBadge) {
        let key = this.getKey(badge);
        if (values[key] === undefined) {
            values[key] = null;
        }
        return values[this.getKey(badge)];
    }

    public static async setInterval(delay: number) {
        try {
            await Delay.make(delay, "BadgeManagerInterval");
            try {
                await this.fetch()
            } catch (e) {

            }
            this.setInterval(delay);
        } catch (e) {

        }
    }
}

