import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-red"
}

import {computed, inject} from "vue";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import IBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/IBlockValues";
import {HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {HtmlConstructorEditorInject, ParentStructureInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "HtmlConstructorBlock"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    multiColumn: { type: Boolean, default: false }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["remove-block", "replace"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let htmlConstructorEditor = inject(HtmlConstructorEditorInject);
let parentStructure = inject(ParentStructureInject, null);

let _modelValue = _useModel<IHtmlConstructorBlock<IBlockValues>>(__props, "modelValue");


let type = computed(() => {
    return htmlConstructorEditor.params.blockTypesDictionary[_modelValue.value.type];
});

function getDefaultValue(): IHtmlConstructorBlock<any> {
    let result: IHtmlConstructorBlock<any> = {
        values: {},
        type: HTML_BLOCK_TYPE_TEXT
    };
    if (type.value) {
        result.values = ObjectHelper.jsonClone(type.value.defaultValue) as IBlockValues
    }
    return result;
}

let model = useObjectModel(_modelValue, getDefaultValue, model => {
    let defaultValue: any = getDefaultValue().values;
    for (let key in defaultValue) {
        if (defaultValue.hasOwnProperty(key)) {
            if (!model.value.values.hasOwnProperty(key)) {
                model.value.values[key] = defaultValue[key];
            }
        }
    }
});

let padding = computed(() => {
    if (type.value?.hasLeftRightPadding(model.value, style.value) && parentStructure && parentStructure.structureStyles) {
        let padding = parentStructure.structureStyles.padding;
        if (props.multiColumn) {
            padding /= 2;
        }
        return `0 ${padding}px`;
    }
    return null;
});

let style = computed(() => {
    let values = model.value.values;
    let result = values.style && values.selfStyle ? values.style : htmlConstructorEditor.model.value.style;
    return result ? result : {};
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            block: true,
            'with-padding': !_unref(type) || !_unref(type).isBlockWithoutPadding
        }),
    style: _normalizeStyle({
            padding: _unref(padding)
        })
  }, [
    (_unref(type))
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(type).displayComponent), {
          key: 0,
          class: "content",
          styles: _unref(style),
          modelValue: _unref(model).values,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).values) = $event)),
          onRemoveBlock: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('remove-block'))),
          onReplace: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('replace', $event)))
        }, null, 40, ["styles", "modelValue"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_1, "Предпросмотр недоступен")),
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})