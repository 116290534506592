import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IColorPickerField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";


const __default__ = {
    name: "ColorPickerElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<IColorPickerField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

return (_ctx: any,_cache: any) => {
  const _component_my_color_picker = _resolveComponent("my-color-picker")!

  return (_openBlock(), _createBlock(_component_my_color_picker, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    disabled: _ctx.readonly
  }, null, 8, ["modelValue", "disabled"]))
}
}

})