import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]

import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import StructureBlockTypeHelper
    from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureBlockTypeHelper";
import HtmlConstructorBlocksList from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorBlocksList.vue";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, provide} from "vue";
import {ParentStructureInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "StructureDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<any>(),
    blocksListEditable: {
        type: Boolean,
        default: true
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {parentStructure, styleId, instanceId} = useBlockDisplay(props);
let _modelValue = _useModel<IStructureBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let structureStyles = computed(() => {
    let result = parentStructure.structureStyles;
    if (result) {
        result = {...result, ...props.styles};
    }
    return result;
});

let columns = computed(() => {
    return StructureBlockTypeHelper.getColumnsByModel(model.value);
});

let css = computed(() => {
    let style = structureStyles.value;
    if (style) {
        let paddingLeft: string, paddingRight: string;
        if (multiColumn.value) {
            paddingLeft = paddingRight = `${style.padding / 2}px`;
        }
        return {
            '': {
                backgroundColor: style.bodyBackground,
                backgroundImage: style.bodyBackgroundImage ? `url('${style.bodyBackgroundImage}')` : null,
                backgroundRepeat: style.bodyBackgroundRepeat,
                paddingRight,
                paddingLeft
            }
        };
    } else {
        return null;
    }
});

let multiColumn = computed(() => {
    return columns.value.length > 1;
});

provide(ParentStructureInject, {
    get structureStyles() {
        return structureStyles.value;
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: _unref(instanceId),
    class: "d-flex"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (column) => {
      return (_openBlock(), _createElementBlock("div", {
        key: column.index,
        style: _normalizeStyle({
                    width: `${column.width}%`,
                })
      }, [
        _createVNode(HtmlConstructorBlocksList, {
          modelValue: column.blocks,
          "onUpdate:modelValue": ($event: any) => ((column.blocks) = $event),
          "blocks-list-editable": __props.blocksListEditable,
          "first-column": column.index === 0,
          "last-column": column.index === _unref(columns).length - 1,
          "multi-column": _unref(multiColumn)
        }, null, 8, ["modelValue", "onUpdate:modelValue", "blocks-list-editable", "first-column", "last-column", "multi-column"])
      ], 4))
    }), 128)),
    (_unref(css))
      ? (_openBlock(), _createBlock(MyStyle, {
          key: 0,
          id: _unref(styleId),
          "root-selector": `#${_unref(instanceId)}`,
          important: false,
          css: _unref(css)
        }, null, 8, ["id", "root-selector", "css"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})