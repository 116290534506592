import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
    PhonebookActionWithGroupActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "PhonebookWithGroupSelectForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<PhonebookActionWithGroupActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<PhonebookActionWithGroupActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    groupIds: [],
    groups: [],
    isAllGroups: false
}));

let frontendParams = computed(() => props.actionDescription.frontendParams);

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "left",
    "label-width": "200px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Все группы" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: _unref(model).isAllGroups,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).isAllGroups) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      (!_unref(model).isAllGroups)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "Выберите конкретные группы",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _unref(model).groupIds,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).groupIds) = $event)),
                multiple: "",
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).groups, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      value: item.value,
                      label: item.descr
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})