import {reactive} from "vue";

export interface IReactiveObject {
    set: (key: string, value: any) => void;
    remove: (key: string) => void;
    has: (key: string) => boolean;
    get: (key: string) => any;
    keys: () => string[];
}

class Null {

}

export default class ReactiveObject implements IReactiveObject {
    private constructor() {
    }

    public set(key: string, value: any) {
        if (value === undefined || value === null) {
            value = new Null();
        }
        (this as any)[key] = value;
    }

    public remove (key: string) {
        delete (this as any)[key];
    }

    public get(key: string) {
        let result = (this as any)[key];
        if (result instanceof Null) {
            result = null;
        }
        return result;
    }


    public has (key: string) {
        return this.hasOwnProperty(key);
    }


    public keys () {
        let keys = [];
        for (let key in this) {
            if (this.hasOwnProperty(key)) {
                keys.push(key);
            }
        }
        return keys;
    };

    public static make(obj: any): ReactiveObject {
        let result = reactive(new ReactiveObject());
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                result.set(key, obj[key]);
            }
        }
        return result;
        /*
        let tmpVm = new Vue({data: {obj}});
        let result = tmpVm.obj;
        result.set = (key: string, value: any) => {
            Vue.set(result, key, value)
        };
        result.remove = (key: string) => {
            Vue.delete(result, key);
        };
        result.get = (key: string) => result[key];
        result.has = (key: string) => result.hasOwnProperty(key);
        result.keys = () => {
            let keys = [];
            for (let key in result) {
                if (result.has(key)) {
                    keys.push(key);
                }
            }
            return keys;
        };
        return result;

         */
    }
}