import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

import IContentsBlockValues from "./IContentsBlockValues";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "ContentsBlockDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockDisplay(props);

let _modelValue = _useModel<IContentsBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(FontAwesomeIcon, {
        icon: "list",
        style: {"font-size":"2em"}
      })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "mt-1" }, "Содержание", -1))
  ]))
}
}

})