import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_2 = { class: "flex-fill" }
const _hoisted_3 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_4 = {
  key: 1,
  class: "lh-primary",
  style: {"white-space":"pre-wrap"}
}

import {IInfoField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, onMounted} from "vue";
import CopyToClipboardButton from "~/cabinet/vue/interface/CopyToClipboardButton.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import StringHelper from "~/ts/library/StringHelper";


const __default__ = {
    name: "InfoElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<IInfoField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");


let field = props.field;

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

let computedInfo = computed(() => {
    let result = props.field.info;
    if (typeof result == "string") {
        if (props.formModel) {
            result = StringHelper.replaceVariables(result, props.formModel);
        }
    }
    return result;
});

onMounted(() => {
    model.value = true;
});


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_unref(field).showLikeInput)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            disabled: "",
            "model-value": _unref(computedInfo)
          }, null, 8, ["model-value"])
        ]),
        (_unref(field).showCopyButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(CopyToClipboardButton, { content: _unref(computedInfo) }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, null, {
                    default: _withCtx(() => [
                      _createVNode(FontAwesomeIcon, { icon: "copy" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["content"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(computedInfo)), 1))
}
}

})