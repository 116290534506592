import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import SavedBlockDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/SavedBlockDisplay.vue";
import SavedBlockForm from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/SavedBlockForm.vue";
import ISavedBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/ISavedBlockValues";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class SavedBlockType extends AbstractBlockType<ISavedBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(SavedBlockDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(SavedBlockForm);
    }

    get isShowEditButton(): boolean {
        return false;
    }

    hasLeftRightPadding(value: ISavedBlockValues): boolean {
        return false;
    }

    async getNewBlockForList(): Promise<IHtmlConstructorBlock<ISavedBlockValues>> {
        let result = await super.getNewBlockForList();
        result.values.displayStartupDialog = true;
        return result;
    }

    get isSaveable(): boolean {
        return false;
    }

}
