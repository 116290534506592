import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

import SideMenu from "./Aside.vue";
import LcabHeader from "./Header.vue";
import MyRouterView from "~/core-ui/vue/router/MyRouterView.vue";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import MenuItemHref from "~/cabinet/vue/interface/menu/MenuItemHref";
import MenuItemDelimiter from "~/cabinet/vue/interface/menu/MenuItemDelimiter";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import LcabMenuDropdown from "~/cabinet/vue/interface/menu/LcabMenuDropdown.vue";
import LcabMenu from "~/cabinet/vue/interface/menu/LcabMenu.vue";
import RejectedDetailsAlertCard from "~/cabinet/vue/client/documents/details/RejectedDetailsAlertCard.vue";
import ContractInfoCard from "~/cabinet/vue/client/documents/contract/ContractInfoCard.vue";
import {computed} from "vue";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";
import BlogPopup from "~/cabinet/vue/client/blog/BlogPopup.vue";
import {useRoute} from "vue-router";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";


import {EVENT_COMPUTE_LAYOUT, EventManagerInstance} from "~/cabinet/ts/Constant";

const __default__ = {
    name: "Layout"
}

function computeMinHeight(restore?: boolean) {
    let container = document.getElementById("lcab-layout-container");
    if (container) {
        container.style.minHeight = restore ? null : document.documentElement.scrollHeight + "px";
    }
}

EventManagerInstance.addEventListener(EVENT_COMPUTE_LAYOUT, computeMinHeight);


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let dealerCss = computed(() => AccountStore.data.value.dealerCabinetConfig.css);


function toggleAside() {
    LcabLayoutStore.toggleAsideVisible();
}

function onLcabMenuSelect(payload: { item: MenuItem }) {
    if (payload && payload.item && payload.item.children.length == 0 && LcabLayoutStore.asideVisible.value) {
        LcabLayoutStore.toggleAsideVisible();
    }
}

let route = useRoute();

let meta = computed<IRouteMeta>(() => {
    let $routes = route.matched.filter(record => !!(record.meta as IRouteMeta).menu);
    return $routes.length ? ($routes[$routes.length - 1].meta as IRouteMeta) : null;
});


let menuItems = computed<MenuItem[]>(() => {
    let $routes = route.matched.filter(record => !!(record.meta as IRouteMeta).menu);
    return $routes.length ? ($routes[$routes.length - 1].meta as IRouteMeta).menu as MenuItem[] : null;
});

let dealerLeftMenuItems = computed<MenuItem[]>(() => {
    let items = AccountStore.leftMenu.value;
    if (items.length) {
        return items.map(item => {
            if (item.descr.length) {
                return new MenuItemHref(item.href, item.descr);
            } else {
                return new MenuItemDelimiter();
            }
        });
    } else {
        return null;
    }
});

let beforeLeftMenuDropdown = computed(() => {
    let $routes = route.matched.filter(record => !!(record.meta as IRouteMeta).beforeLeftMenuDropdown);
    return $routes.length ? ($routes[$routes.length - 1].meta as IRouteMeta).beforeLeftMenuDropdown : null;
});

let beforeLeftMenu = computed(() => {
    let $routes = route.matched.filter(record => !!(record.meta as IRouteMeta).beforeLeftMenu);
    return $routes.length ? ($routes[$routes.length - 1].meta as IRouteMeta).beforeLeftMenu : null;
});

let afterLeftMenu = computed(() => {
    let $routes = route.matched.filter(record => !!(record.meta as IRouteMeta).afterLeftMenu);
    return $routes.length ? ($routes[$routes.length - 1].meta as IRouteMeta).afterLeftMenu : null;
});

let footer = computed(() => {
    return AccountStore.data.value.htmlContent.footer;
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    id: "lcab-layout-container",
    class: "lcabLayout h-100",
    direction: "vertical"
  }, {
    default: _withCtx(() => [
      _createVNode(LcabHeader),
      _createVNode(_component_el_container, { direction: "horizontal" }, {
        default: _withCtx(() => [
          _createVNode(SideMenu, null, {
            "left-menu": _withCtx(() => [
              _createVNode(_component_router_view, { name: "beforeLeftMenu" }),
              (_unref(beforeLeftMenu))
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(beforeLeftMenu)), { key: 0 }))
                : _createCommentVNode("", true),
              (_unref(beforeLeftMenuDropdown))
                ? (_openBlock(), _createBlock(LcabMenuDropdown, {
                    key: 1,
                    menu: _unref(beforeLeftMenuDropdown),
                    "max-width": "13em"
                  }, null, 8, ["menu"]))
                : _createCommentVNode("", true),
              (_unref(menuItems))
                ? (_openBlock(), _createBlock(LcabMenu, {
                    key: 2,
                    items: _unref(menuItems),
                    class: "side-menu",
                    onSelect: onLcabMenuSelect
                  }, null, 8, ["items"]))
                : _createCommentVNode("", true),
              (_unref(dealerLeftMenuItems))
                ? (_openBlock(), _createBlock(LcabMenu, {
                    key: 3,
                    items: _unref(dealerLeftMenuItems),
                    class: "side-menu",
                    onSelect: onLcabMenuSelect
                  }, null, 8, ["items"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view, { name: "afterLeftMenu" }),
              (_unref(afterLeftMenu))
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(afterLeftMenu)), { key: 4 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: "aside-overlay",
            onClick: toggleAside
          }),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, { class: "cabinet-controller" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, { class: "el-row-root" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, null, {
                        default: _withCtx(() => [
                          _createVNode(RejectedDetailsAlertCard),
                          _createVNode(ContractInfoCard)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(MyRouterView),
                  _createVNode(BlogPopup),
                  (!_unref(isMobileWindowSize))
                    ? (_openBlock(), _createBlock(_component_el_backtop, {
                        key: 0,
                        style: {"left":"28px !important","right":"auto !important","bottom":"28px !important"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, { icon: "chevron-up" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_footer, { class: "footer overflow-hidden" }, {
                default: _withCtx(() => [
                  (_unref(footer))
                    ? (_openBlock(), _createBlock(CabinetHtmlify, {
                        key: 0,
                        class: "text-left pb-4 footer-inner",
                        value: _unref(footer),
                        escape: false
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "clear" }, null, -1)),
                  _createVNode(MyStyle, {
                    id: "dealer-styles",
                    "css-string": _unref(dealerCss)
                  }, null, 8, ["css-string"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})