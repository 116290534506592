import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "font-weight-bold" }

import {computed} from 'vue';
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import NeedContractInfoInterface from './NeedContractInfoInterface';
import getClientDocumentUrl from "~/cabinet/vue/client/documents/getClientDocumentUrl";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import ScanFormItem from "~/cabinet/vue/client/documents/ScanFormItem.vue";
import {CONTRACT_DOCUMENT_TYPE_ID} from "~/cabinet/vue/dealer/documents/Interface";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "NeedInfoCard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    needInfo: {}
  },
  emits: ["reload"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let documentTypeId = computed(() => {
    return CONTRACT_DOCUMENT_TYPE_ID;
});

let downloadUrl = computed(() => {
    if (props.needInfo.contract) {
        return getClientDocumentUrl('contract', props.needInfo.contract.id);
    }
    return null;
});

async function requestContract(detailsId: string) {
    if (props.needInfo.uslugaId) {
        let result = await LcabApiRequest.save({
            url: `/api/documents/contracts/${detailsId}/requestContract`,
            p: {uslugi: [props.needInfo.uslugaId]}
        });
        if (result.isSuccess) {
            reload();
        }
    }
}

function reload() {
    emits("reload");
}


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      (_ctx.needInfo.contract)
        ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
            key: 0,
            icon: "clock",
            "icon-color": "yellow",
            "gray-label": false
          }, {
            value: _withCtx(() => [
              _cache[1] || (_cache[1] = _createTextVNode(" Договор в процессе заключения № ")),
              _createElementVNode("a", {
                target: "_blank",
                href: _unref(downloadUrl)
              }, _toDisplayString(_ctx.needInfo.contract.numberDescr), 9, _hoisted_1),
              _createTextVNode(" для " + _toDisplayString(_ctx.needInfo.details.descr), 1)
            ]),
            label: _withCtx(() => [
              _createVNode(_component_el_form, {
                "label-position": "left",
                "label-width": "200px",
                size: "small"
              }, {
                default: _withCtx(() => [
                  (_ctx.needInfo.contract.status)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "Статус договора"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.needInfo.contract.status.descr) + " ", 1),
                          (_ctx.needInfo.contract.status.dateTime)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_unref(localeDateTimeString)(_ctx.needInfo.contract.status.dateTime)), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.needInfo.addressPost)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: "Адрес отправки"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.needInfo.addressPost), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.needInfo.contract.uslugi.length)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        label: "Услуги в договоре"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.needInfo.contract.uslugi, (usluga, key) => {
                            return (_openBlock(), _createBlock(MyFilterPreviewTagTemplate, {
                              key: key,
                              class: "mr-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(usluga), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.needInfo.contract.isNeedContractScan)
                    ? (_openBlock(), _createBlock(ScanFormItem, {
                        key: 3,
                        label: "Скан договора",
                        "document-type-id": _unref(documentTypeId),
                        "document-id": _ctx.needInfo.contract.id,
                        "document-download-url": _unref(downloadUrl),
                        scan: _ctx.needInfo.contract.scan,
                        onReload: reload
                      }, null, 8, ["document-type-id", "document-id", "document-download-url", "scan"]))
                    : _createCommentVNode("", true),
                  (_ctx.needInfo.warning)
                    ? (_openBlock(), _createBlock(_component_el_form_item, { key: 4 }, {
                        label: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("span", { class: "text-red" }, "Внимание", -1)
                        ])),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.needInfo.warning), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.needInfo.contractRequest)
          ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
              key: 1,
              icon: "clock",
              "icon-color": "yellow",
              value: `Заявка на договор для ${_ctx.needInfo.details.descr}`,
              "gray-label": false
            }, {
              label: _withCtx(() => [
                _createVNode(_component_el_form, {
                  "label-position": "left",
                  "label-width": "200px",
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    (_ctx.needInfo.contractRequest.status)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          label: "Статус заявки"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.needInfo.contractRequest.status.descr), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.needInfo.contractRequest.uslugi.length)
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 1,
                          label: "Услуги в договоре"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.needInfo.contractRequest.uslugi.join(", ")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.needInfo.warning)
                      ? (_openBlock(), _createBlock(_component_el_form_item, { key: 2 }, {
                          label: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("span", { class: "text-red" }, "Внимание", -1)
                          ])),
                          default: _withCtx(() => [
                            _createTextVNode(" " + _toDisplayString(_ctx.needInfo.warning), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]))
          : (_openBlock(), _createBlock(ElCardSectionWithIcon, {
              key: 2,
              icon: "check",
              "icon-color": "orange",
              value: `Подать заявку на договор`,
              "gray-label": false
            }, {
              label: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (requestContract(_ctx.needInfo.details.id)), ["prevent","stop"])),
                  href: "#",
                  class: "link-dashed"
                }, "Подать заявку"),
                _cache[4] || (_cache[4] = _createTextVNode(" на реквизиты ")),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.needInfo.details.descr), 1)
              ]),
              _: 1
            }))
    ]),
    _: 1
  }))
}
}

})