import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import IAttentionTextBlockValues, {
    IAttentionTextBlockParamsForEditor
} from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/IAttentionTextBlockValues";
import AttentionTextDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/AttentionTextDisplay.vue";
import AttentionTextForm from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/AttentionTextForm.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class AttentionTextBlockType extends AbstractBlockType<IAttentionTextBlockValues, IAttentionTextBlockParamsForEditor> {
    get displayComponent(): RawComponent {
        return useRawComponent(AttentionTextDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(AttentionTextForm);
    }

    get isBlockWithoutPadding(): boolean {
        return true;
    }

    get toolbarPosition(): HtmlConstructorBlockToolbarPosition {
        return HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM;
    }
}
