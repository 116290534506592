import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "header-buttons d-flex" }
const _hoisted_2 = { class: "d-sm-none square" }
const _hoisted_3 = { class: "w-auto d-none d-md-block" }
const _hoisted_4 = { class: "text-left aside-header-dropdown p-0 d-flex" }
const _hoisted_5 = {
  key: 0,
  class: "float-right text-right w-auto",
  style: {"max-width":"30%"}
}
const _hoisted_6 = {
  key: 1,
  class: "square float-right"
}
const _hoisted_7 = { class: "square float-right" }
const _hoisted_8 = { class: "square float-right" }
const _hoisted_9 = { class: "el-dialog__title" }
const _hoisted_10 = {
  key: 3,
  class: "square float-right hidden-xs-only"
}
const _hoisted_11 = {
  key: 4,
  class: "square float-right hidden-xs-only"
}
const _hoisted_12 = {
  key: 5,
  class: "square float-right"
}
const _hoisted_13 = { class: "d-none" }
const _hoisted_14 = {
  key: 6,
  class: "square float-right"
}
const _hoisted_15 = { class: "square float-right" }
const _hoisted_16 = {
  key: 0,
  class: "table table-hover text-left",
  style: {"word-break":"break-word"}
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "text-placeholder font-size-small" }
const _hoisted_19 = {
  class: "mb-2 mt-1",
  style: {"font-weight":"500"}
}
const _hoisted_20 = {
  key: 1,
  class: "text-center text-placeholder pt-2 pb-2"
}
const _hoisted_21 = {
  key: 0,
  class: "text-center"
}

import {computed, onMounted, ref} from 'vue';
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import AccountButton from "~/cabinet/vue/layout/lcab/Header/AccountButton.vue";
import HeaderIconButton from "~/cabinet/vue/layout/lcab/Header/HeaderIconButton.vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {API_CALLBACK_UNREADED_MESSAGES_COUNT} from "~/chat/ts/service/api/methods/ApiCallback";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveFilterableListRequest from "~/core-ui/ts/request/ReactiveFilterableListRequest";
import CabinetNotification from "~/cabinet/vue/client/notifications/CabinetNotification";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";
import ClientRoutesBuilder, {
    CLIENT_SUPPORT_ROUTE,
    DOC_ROUTE,
    NOTIFICATIONS_ROUTE_NAME,
    NOTIFICATIONS_SUBSCRIBE_ROUTE_NAME
} from "~/cabinet/ts/routes/ClientRoutesBuilder";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import BadgeManager, {BADGE_STICKERS} from "~/cabinet/vue/interface/menu/BadgeManager";
import SystemStateInterface from "~/cabinet/vue/layout/lcab/SystemStateInterface";
import SystemStateCounters from "~/cabinet/vue/layout/lcab/SystemStateCounters.vue";
import RecipientInfo from "~/cabinet/vue/layout/lcab/RecipientInfo.vue";
import {isMobileWindowSize, localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";
import {RouteLocationNamedRaw, RouteLocationRaw} from "vue-router";
import {__} from "~/ts/library/Translate";



const __default__ = {
    name: "Header"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let notificationPopoverVisible = ref(false);
let recipientInfoPopoverVisible = ref(false);
let systemStateDialogVisible = ref(false);


function toggleAside() {
    LcabLayoutStore.toggleAsideVisible();
}

let onlineChatBadge = ref<number>();

let userAccess = AccountStore.access;

let isSuperDealer = AccountStore.isSuperDealer;

let isCanNotifications = computed(() => {
    return userAccess.value.checkAccessForNotifications();
});

let isCanLcabDocuments = computed(() => {
    return userAccess.value.checkAccessForLcabDocuments();
});

let isCanLcabNews = computed(() => {
    return userAccess.value.checkAccessForViewNews();
});

let isCanPm = computed(() => {
    return userAccess.value.checkAccessForPm();
});

let supportBadge = computed(() => {
    return new MenuBadge("cabinetSupport");
});

let stickersBadge = computed(() => {
    return new MenuBadge(BADGE_STICKERS);
});

let notificationBadge = computed(() => {
    return new MenuBadge("cabinetNotifications");
});

let documentsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: DOC_ROUTE
    };
});

let blogLocation = computed(() => ClientRoutesBuilder.getBlogsLocation());


let phpstanLocation: RouteLocationRaw = {
    path: "/dealer/dev/phpstan"
};


let supportLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: CLIENT_SUPPORT_ROUTE
    };
});

let notificationsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: NOTIFICATIONS_ROUTE_NAME
    };
});

let notificationsSubscribeLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: NOTIFICATIONS_SUBSCRIBE_ROUTE_NAME
    }
});

let noticeChannels = AccountStore.noticeChannels;

function onOnlineChatClick(): any {
    let meTalk = getMeTalk();
    if (meTalk) {
        meTalk("openSupport");
    }
}

function getMeTalk() {
    let myWindow = window as any;
    return myWindow.MeTalk || myWindow.TalkMe || myWindow.Verbox || myWindow.OnlineChat;
}


function reloadNotifications() {
    notificationsRequest.value.make();
}

let notificationsRequest = computed(() => {
    let result = new ReactiveFilterableListRequest(new LcabApiRequest({
        url: `/api/client/notifications/getNotificationsForTopMenu`
    }, CabinetNotification));
    result.make(0, 5);
    return result;
});

function markNotificationAsReaded(item: CabinetNotification, event: MouseEvent) {
    if (!item.isReaded) {
        event.stopPropagation();
        item.isReaded = true;
        (new LcabApiRequest({
            url: `/api/client/notifications/markAsReaded/${item.id}`,
            silent: true
        })).send();
    }
}

onMounted(() => {
    let meTalk = getMeTalk();

    if (meTalk) {
        meTalk("hideTrigger");
        meTalk("setCallback", API_CALLBACK_UNREADED_MESSAGES_COUNT, function (value: number) {
            onlineChatBadge.value = value ? value : null;
        });
        if (AccountStore.user.value) {
            let organization = AccountStore.organization.value;
            meTalk("setClientInfo", {
                lcabUserId: AccountStore.user.value.id,
                ocode: organization.ocode,
                descr: `${organization.descr}, ${organization.ocode}`
            });
        }
    }
});

let systemState = computed<SystemStateInterface>(() => {
    return BadgeManager.getValue(new MenuBadge("systemState"));
});

let phpstanErrors = computed<string | number>(() => {
    return BadgeManager.getValue(new MenuBadge("phpstan")) ?? 0;
})

let isSystemInWarningState = computed(() => {
    if (systemState.value) {
        for (let item of systemState.value.queues) {
            for (let group of item.groups) {
                for (let key in group.values) {
                    if (group.values.hasOwnProperty(key)) {
                        if (group.values[key] > group.criticalCount[key]) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
});


return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_header = _resolveComponent("el-header")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(_component_el_header, {
    height: "auto",
    style: {"z-index":"3"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(HeaderIconButton, {
            icon: "bars",
            "icon-light": "",
            "icon-color": "default",
            onClick: toggleAside
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(AccountButton)
          ])
        ]),
        (_unref(isMobileWindowSize))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(AccountButton, { class: "d-block h-100" })
            ]))
          : _createCommentVNode("", true),
        _cache[9] || (_cache[9] = _createElementVNode("div", {
          class: "flex-fill",
          style: {"visibility":"hidden"}
        }, null, -1)),
        (_unref(isSuperDealer))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_el_popover, {
                visible: _unref(recipientInfoPopoverVisible),
                "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(recipientInfoPopoverVisible) ? (recipientInfoPopoverVisible).value = $event : recipientInfoPopoverVisible = $event))
              }, {
                reference: _withCtx(() => [
                  _createVNode(HeaderIconButton, { icon: "info-circle" })
                ]),
                default: _withCtx(() => [
                  _createVNode(RecipientInfo)
                ]),
                _: 1
              }, 8, ["visible"])
            ]))
          : _createCommentVNode("", true),
        (_unref(isSuperDealer) && _unref(userAccess).isAdmin)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("div", _hoisted_7, [
                (_unref(phpstanErrors) !== 0)
                  ? (_openBlock(), _createBlock(HeaderIconButton, {
                      key: 0,
                      to: _unref(phpstanLocation),
                      icon: "bug",
                      "icon-color": "danger",
                      "icon-light": "",
                      badge: _unref(phpstanErrors)
                    }, null, 8, ["to", "badge"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(HeaderIconButton, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(systemStateDialogVisible) //@ts-ignore
 ? systemStateDialogVisible.value = true : systemStateDialogVisible = true)),
                  icon: _unref(isSystemInWarningState) ? 'traffic-light-stop' : 'traffic-light-go',
                  "icon-color": _unref(isSystemInWarningState) ? 'danger' : 'success',
                  "icon-light": ""
                }, null, 8, ["icon", "icon-color"]),
                (_unref(systemStateDialogVisible))
                  ? (_openBlock(), _createBlock(_component_el_dialog, {
                      key: 0,
                      modelValue: _unref(systemStateDialogVisible),
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(systemStateDialogVisible) ? (systemStateDialogVisible).value = $event : systemStateDialogVisible = $event)),
                      title: "Состояние системы",
                      class: "right w-75 full-height-body overflow body-background"
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, [
                          _createVNode(FontAwesomeIcon, {
                            icon: _unref(isSystemInWarningState) ? 'traffic-light-stop' : 'traffic-light-go',
                            color: _unref(isSystemInWarningState) ? 'danger' : 'success',
                            light: "",
                            class: "mr-2"
                          }, null, 8, ["icon", "color"]),
                          _cache[5] || (_cache[5] = _createTextVNode(" Состояние системы "))
                        ])
                      ]),
                      default: _withCtx(() => [
                        (_unref(systemState))
                          ? (_openBlock(), _createBlock(SystemStateCounters, {
                              key: 0,
                              "system-state": _unref(systemState)
                            }, null, 8, ["system-state"]))
                          : (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Идёт загрузка... ")
                              ])),
                              _: 1
                            }))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_unref(isCanLcabNews))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(HeaderIconButton, {
                to: _unref(blogLocation),
                icon: "newspaper",
                label: "Блог",
                badge: null
              }, null, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (_unref(isCanLcabDocuments))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(HeaderIconButton, {
                to: _unref(documentsLocation),
                icon: "file",
                label: "Документы",
                badge: null
              }, null, 8, ["to"])
            ]))
          : _createCommentVNode("", true),
        (getMeTalk())
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(HeaderIconButton, {
                icon: "comments",
                badge: _unref(onlineChatBadge),
                label: "Написать в чат",
                onClick: onOnlineChatClick
              }, null, 8, ["badge"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(HeaderIconButton, {
            id: "stickers-in-header",
            icon: "life-ring",
            badge: _unref(stickersBadge)
          }, null, 8, ["badge"])
        ]),
        (_unref(isCanPm))
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(HeaderIconButton, {
                to: _unref(supportLocation),
                icon: "life-ring",
                label: _unref(__)('Техподдержка'),
                badge: _unref(supportBadge)
              }, null, 8, ["to", "label", "badge"])
            ]))
          : _createCommentVNode("", true),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
          (_unref(isCanNotifications))
            ? (_openBlock(), _createBlock(_component_el_popover, {
                key: 0,
                "popper-class": "pl-0 pr-0 pb-0",
                visible: _unref(notificationPopoverVisible),
                "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(notificationPopoverVisible) ? (notificationPopoverVisible).value = $event : notificationPopoverVisible = $event))
              }, {
                reference: _withCtx(() => [
                  _createVNode(HeaderIconButton, {
                    icon: "bell",
                    "badge-type": "warning",
                    label: _unref(__)('Уведомления'),
                    badge: _unref(notificationBadge)
                  }, null, 8, ["label", "badge"])
                ]),
                default: _withCtx(() => [
                  _createVNode(ReactiveRequestLoader, {
                    style: {"width":"400px","max-width":"100%"},
                    class: "text-center",
                    request: _unref(notificationsRequest)
                  }, {
                    default: _withCtx(() => [
                      (_unref(notificationsRequest).data)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_unref(notificationsRequest).data.items.length)
                              ? (_openBlock(), _createElementBlock("table", _hoisted_16, [
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationsRequest).data.items, (item) => {
                                      return (_openBlock(), _createElementBlock("tr", {
                                        key: item.id,
                                        style: _normalizeStyle({cursor: !item.isReaded ? 'pointer' : null}),
                                        onClick: ($event: any) => (markNotificationAsReaded(item, $event))
                                      }, [
                                        _createElementVNode("td", null, [
                                          _createElementVNode("div", _hoisted_18, [
                                            _createTextVNode(_toDisplayString(_unref(localeDateTimeString)(item.dateTime)) + " ", 1),
                                            (!item.isReaded)
                                              ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                                                  key: 0,
                                                  bold: "",
                                                  icon: "circle",
                                                  class: "text-primary float-right",
                                                  style: {"font-size":".5em","line-height":"2"}
                                                }))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _createElementVNode("div", _hoisted_19, _toDisplayString(item.title), 1),
                                          _createVNode(CabinetHtmlify, {
                                            value: item.text,
                                            links: "",
                                            tag: "div"
                                          }, null, 8, ["value"])
                                        ])
                                      ], 12, _hoisted_17))
                                    }), 128))
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_20, " Нет уведомлений "))
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["request"]),
                  _createElementVNode("div", {
                    class: "pl-2 pr-2 pt-3 pb-3 sticky -bottom bg-white",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_isRef(notificationPopoverVisible) //@ts-ignore
 ? notificationPopoverVisible.value = false : notificationPopoverVisible = false))
                  }, [
                    _createVNode(_component_router_link, {
                      to: _unref(notificationsLocation),
                      custom: ""
                    }, {
                      default: _withCtx(({navigate}) => [
                        _createVNode(_component_el_button, {
                          onClick: navigate,
                          round: "",
                          plain: "",
                          class: "d-block mb-2 w-100"
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" Перейти к уведомлениям ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (_unref(noticeChannels).length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createVNode(_component_router_link, {
                            to: _unref(notificationsSubscribeLocation),
                            custom: ""
                          }, {
                            default: _withCtx(({navigate}) => [
                              _createVNode(_component_el_button, {
                                class: "d-block w-100 mb-2",
                                link: "",
                                onClick: navigate
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Получать в мессенджеры ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 1
                          }, 8, ["to"]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(noticeChannels), (channel) => {
                            return (_openBlock(), _createBlock(FontAwesomeIcon, {
                              key: channel.id,
                              icon: channel.icon,
                              style: _normalizeStyle({color: channel.color, fontSize: '1.3em', width: '1.7em'})
                            }, null, 8, ["icon", "style"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["visible"]))
            : _createCommentVNode("", true)
        ])), [
          [_directive_on_native, reloadNotifications, "click"]
        ])
      ])
    ]),
    _: 1
  }))
}
}

})