import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ITextUnderImageBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/ITextUnderImageBlockValues";
import TextUnderImageDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/TextUnderImageDisplay.vue";
import TextUnderImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/TextUnderImageForm.vue";
import {Component} from "vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class TextUnderImageBlockType extends AbstractBlockType<ITextUnderImageBlockValues, any> {


    get displayComponent(): RawComponent {
        return useRawComponent(TextUnderImageDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(TextUnderImageForm);
    }

    hasLeftRightPadding(value: ITextUnderImageBlockValues): boolean {
        return false;
    }


}
