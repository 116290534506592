import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IImageBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/image/IImageBlockValues";
import ImageDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageDisplay.vue";
import ImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageForm.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class ImageBlockType extends AbstractBlockType<IImageBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(ImageDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(ImageForm);
    }


}
