import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import {IField, IForm, IFormSection} from "~/cabinet/vue/interface/form/elements/Interfaces";
import MyFormSectionConstructor from "~/cabinet/vue/interface/form/elements/constructor/MyFormSectionConstructor.vue";
import {FORM_ELEMENT_TYPE_LIST} from "~/cabinet/vue/interface/form/elements/FormElementCollection";
import FormHelper from "~/cabinet/vue/interface/form/elements/FormHelper";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, provide} from "vue";
import {MY_FORM_CONSTRUCTOR_ALL_ELEMENTS_INJECT} from "~/cabinet/vue/interface/form/elements/constructor/Interface";


const __default__ = {
    name: "MyFormConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    singleSection: { type: Boolean },
    elementNameRandomize: { type: Boolean },
    elementNameSameAsDescr: { type: Boolean },
    showDisplayConditionConfig: { type: Boolean },
    includeElementType: {},
    excludeElementType: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let _modelValue = _useModel<IForm>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => {
    let sections: IFormSection[] = [];
    if (props.singleSection) {
        sections.push(null);
    }
    return {
        sections
    };
});

provide(MY_FORM_CONSTRUCTOR_ALL_ELEMENTS_INJECT, computed(() => {
    let result: IField[] = [];
    for (let section of model.value.sections) {
        result.push(...section.elements);
    }
    return result;
}))


let duplicateElementNames = computed(() => {
    return FormHelper.getDuplicateElementNames(model.value);
});

let types = computed(() => {
    let result = FORM_ELEMENT_TYPE_LIST.filter(item => {
        if (props.includeElementType && props.includeElementType.indexOf(item.id) == -1) {
            return false;
        }
        if (props.excludeElementType && props.excludeElementType.indexOf(item.id) != -1) {
            return false;
        }
        return true;
    });
    if (props.includeElementType) {
        result = result.sort((item1, item2) => {
            let index1 = props.includeElementType.find(id => id == item1.id);
            let index2 = props.includeElementType.find(id => id == item2.id);
            return index1 > index2 ? 1 : -1;
        });
    }
    return result;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(model).sections, (section, index) => {
      return (_openBlock(), _createBlock(MyFormSectionConstructor, {
        key: index,
        modelValue: _unref(model).sections[index],
        "onUpdate:modelValue": ($event: any) => ((_unref(model).sections[index]) = $event),
        "duplicate-element-names": _unref(duplicateElementNames),
        "element-name-same-as-descr": _ctx.elementNameSameAsDescr,
        "element-name-randomize": _ctx.elementNameRandomize,
        "show-display-condition-config": _ctx.showDisplayConditionConfig,
        "element-types": _unref(types)
      }, null, 8, ["modelValue", "onUpdate:modelValue", "duplicate-element-names", "element-name-same-as-descr", "element-name-randomize", "show-display-condition-config", "element-types"]))
    }), 128))
  ]))
}
}

})