import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

import {IOption} from "~/cabinet/vue/interface/form/elements/Interfaces";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "SelectElementOption"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    option: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.option.icon)
      ? (_openBlock(), _createBlock(FontAwesomeIcon, {
          key: 0,
          icon: _ctx.option.icon,
          "icon-style": _ctx.option.iconStyle,
          class: "mr-1",
          style: _normalizeStyle({
                color: _ctx.option.iconColor ? _ctx.option.iconColor : null
            })
        }, null, 8, ["icon", "icon-style", "style"]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.option.descr), 1)
  ]))
}
}

})