import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import ContentEditableHelper from "~/chat/vue/forms/ContentEditableHelper";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";


const __default__ = {
    name: "ContentEditable"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    tag: { default: "div" },
    modelValue: {},
    html: { type: Boolean },
    multiline: { type: Boolean },
    placeholder: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = ref<string>();

function onKeyDown(e: KeyboardEvent) {
    if (!props.multiline && !props.html) {
        e.preventDefault();
        return false;
    }
    return true;
}

watch(computed(() => props.modelValue), () => {
    let content = getContent();
    if (content != props.modelValue) {
        setModel();
    }
})

function setModel() {
    model.value = props.modelValue;
}

let instance = getCurrentInstance().proxy;

function getContent(): string {
    let el: HTMLElement = instance.$el as HTMLElement;
    return props.html ? el.innerHTML : ContentEditableHelper.getElementText(el, props.multiline)
}

let observer: MutationObserver = null;

onMounted(() => {
    observer = (new MutationObserver(() => {
        emitInput();
    }));

    observer.observe(instance.$el, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true
    });
});

function onPaste(e: ClipboardEvent) {
    if (!props.html) {
        if (e.clipboardData) {
            e.preventDefault();

            let text = e.clipboardData.getData('text/plain');
            if (!props.multiline) {
                text = text.split("\n").join(" ");
            }
            if (text) {
                window.document.execCommand('insertText', false, text);
            }
        }
    }
}

function emitInput() {
    emits("update:modelValue", getContent());
}

setModel();


return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return _withDirectives((_openBlock(), _createBlock(CabinetHtmlify, {
    class: _normalizeClass({
            root: true,
            'show-placeholder': _ctx.placeholder && (!_ctx.modelValue || !_ctx.modelValue.length)
        }),
    escape: !_ctx.html,
    nl2br: !_ctx.html && _ctx.multiline,
    placeholder: _ctx.placeholder,
    tag: _ctx.tag,
    value: _unref(model),
    contenteditable: ""
  }, null, 8, ["class", "escape", "nl2br", "placeholder", "tag", "value"])), [
    [
      _directive_on_native,
      onKeyDown,
      "keydown",
      { enter: true }
    ],
    [_directive_on_native, (event) => onPaste(event), "paste"]
  ])
}
}

})