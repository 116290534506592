import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute-full-size p-4"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "flex-fill" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ml-4" }
const _hoisted_6 = ["src"]

import {computed} from 'vue';
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import DevicePreview from "~/cabinet/vue/interface/device/DevicePreview.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";

interface PreviewResponseInterface {
    html: string;
}

const __default__ = {
    name: "HtmlConstructorPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    value: {},
    typeId: {},
    contentVariables: {},
    base: {}
  },
  setup(__props: any) {

let props = __props;

let request = computed(() => {
    return new ReactiveRequest<PreviewResponseInterface>(new LcabApiRequest({
        url: `/api/htmlConstructor/${props.typeId}/getPreview`,
        p: {
            model: props.value,
            contentVariables: props.contentVariables,
            base: props.base
        }
    }));
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(DevicePreview, { type: "desktop" }, {
                  default: _withCtx(() => [
                    _createElementVNode("iframe", {
                      src: _unref(request).data.html
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(DevicePreview, { type: "mobile" }, {
                  default: _withCtx(() => [
                    _createElementVNode("iframe", {
                      src: _unref(request).data.html
                    }, null, 8, _hoisted_6)
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})