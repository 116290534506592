import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]

import {
    HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE,
    HTML_CONSTRUCTOR_HEADER_TYPE_TEXT,
    IHeaderBlockStyles,
    IHeaderBlockValues
} from "~/cabinet/vue/interface/htmlConstructor/blocks/header/IHeaderBlockValues";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "HeaderDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<IHeaderBlockStyles>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props

let {parentStructureStyles, instanceId, styleId, htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<IHeaderBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let isText = computed(() => {
    return model.value.headerType == HTML_CONSTRUCTOR_HEADER_TYPE_TEXT;
});

let isImage = computed(() => {
    return model.value.headerType == HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE;
});

let css = computed(() => {
    let style = props.styles;
    if (style && parentStructureStyles.value) {
        return {
            '': {
                backgroundColor: style.headerBackground,
                backgroundImage: style.headerBackgroundImage ? `url('${style.headerBackgroundImage}')` : null,
                backgroundRepeat: style.headerBackgroundRepeat,
                padding: isImage.value ? null : `${parentStructureStyles.value.padding * 2}px ${parentStructureStyles.value.padding}px`
            },
            h1: {
                color: style.headerH1Color,
                fontFamily: style.headerH1Font,
                fontSize: `${style.headerH1Size}px`,
                fontWeight: style.headerH1FontWeight
            },
            h2: {
                color: style.headerH2Color,
                fontFamily: style.headerH2Font,
                fontSize: `${style.headerH2Size}px`,
                fontWeight: style.headerH2FontWeight
            }
        }
    } else {
        return null;
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", { id: _unref(instanceId) }, [
    (_unref(isText))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle({textAlign: _unref(model).align})
        }, [
          _createElementVNode("h1", null, [
            _createVNode(TextInputWithVariables, {
              modelValue: _unref(model).h1,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).h1) = $event)),
              "content-variables": _unref(htmlConstructorEditor).params.contentVariables,
              inline: "",
              placeholder: "Заголовок"
            }, null, 8, ["modelValue", "content-variables"])
          ]),
          _createElementVNode("h2", null, [
            _createVNode(TextInputWithVariables, {
              modelValue: _unref(model).h2,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).h2) = $event)),
              "content-variables": _unref(htmlConstructorEditor).params.contentVariables,
              inline: "",
              placeholder: "Слоган"
            }, null, 8, ["modelValue", "content-variables"])
          ])
        ], 4))
      : (_unref(isImage))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _unref(model).src,
              class: "w-100"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
    _createVNode(MyStyle, {
      id: _unref(styleId),
      css: _unref(css),
      important: false,
      "root-selector": `#${_unref(instanceId)}`
    }, null, 8, ["id", "css", "root-selector"])
  ], 8, _hoisted_1))
}
}

})