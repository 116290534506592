import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";


const __default__ = {
    name: "ReactiveRequestLoader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    request: {},
    bigLoader: { type: Boolean, default: true },
    showLoader: { type: Boolean, default: true },
    loaderClass: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.request.isLoading && _ctx.showLoader)
      ? (_openBlock(), _createBlock(MyLoader, {
          key: 0,
          big: _ctx.bigLoader,
          class: _normalizeClass(_ctx.loaderClass)
        }, null, 8, ["big", "class"]))
      : (_ctx.request.isSuccess && _ctx.request)
        ? _renderSlot(_ctx.$slots, "default", _normalizeProps(_mergeProps({ key: 1 }, {
                request: _ctx.request
            })))
        : _createCommentVNode("", true)
  ]))
}
}

})