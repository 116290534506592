import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    SendNotificationToClientOnlineChatOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {computed} from "vue";


const __default__ = {
    name: "SendNotificationToClientOnlineChatOperatorForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<SendNotificationToClientOnlineChatOperatorActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<SendNotificationToClientOnlineChatOperatorActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    text: null,
    dealerSiteId: null
}));

let frontendParams = computed(() => props.actionDescription.frontendParams);

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "left",
    "label-width": "200px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Ваш онлайн чат" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _unref(model).dealerSiteId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).dealerSiteId) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).sites, (site) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: site.id,
                  value: site.id,
                  label: site.title
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Текст уведомления" }, {
        default: _withCtx(() => [
          _createVNode(TextInputWithVariables, {
            placeholder: "Введите текст уведомления",
            modelValue: _unref(model).text,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).text) = $event)),
            "content-variables": _ctx.contentVariables
          }, null, 8, ["modelValue", "content-variables"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})