import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  key: 1,
  class: "text-muted no-selected"
}

import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import IBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/IBlockValues";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, inject} from "vue";
import {HtmlConstructorEditorInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";



const __default__ = {
    name: "HtmlConstructorBlockProperties"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let _modelValue = _useModel<IHtmlConstructorBlock<IBlockValues>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => null)

let htmlConstructorEditor = inject(HtmlConstructorEditorInject);

let type = computed<AbstractBlockType<any, any>>(() => {
    return htmlConstructorEditor.params.blockTypesDictionary[model.value.type];
});


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_unref(model) && _unref(type))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, [
          _createVNode(FontAwesomeIcon, {
            icon: _unref(type).icon,
            class: "mr-2"
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_unref(type).descr), 1)
        ]),
        (_unref(type).formComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(type).formComponent), {
              key: 0,
              "block-type": _unref(type),
              modelValue: _unref(model).values,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).values) = $event)),
              class: "mb-4"
            }, null, 8, ["block-type", "modelValue"]))
          : _createCommentVNode("", true),
        (_unref(type).styleForm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "mb-4" }, null, -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_switch, {
                  modelValue: _unref(model).values.selfStyle,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).values.selfStyle) = $event))
                }, null, 8, ["modelValue"]),
                _cache[3] || (_cache[3] = _createTextVNode(" Индивидуальный стиль "))
              ]),
              (_unref(model).values.selfStyle)
                ? (_openBlock(), _createBlock(_component_el_form, {
                    key: 0,
                    "label-position": "left",
                    "label-width": "130px",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyForm, {
                        modelValue: _unref(model).values.style,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).values.style) = $event)),
                        form: _unref(type).styleForm
                      }, null, 8, ["modelValue", "form"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, " Кликните по элементу в редакторе, чтобы редактировать его параметры "))
}
}

})