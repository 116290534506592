import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  class: "el-upload-dragger pt-0",
  style: {"min-height":"0"}
}
const _hoisted_2 = {
  key: 1,
  class: "el-upload__text"
}
const _hoisted_3 = {
  key: 2,
  class: "el-upload__tip"
}

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "ClickableArea"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    icon: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "el-upload el-upload--text",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
              key: 0,
              icon: _ctx.icon,
              light: ""
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (_ctx.$slots.text)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "text")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.tip)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "tip")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})