import StringHelper from "~/ts/library/StringHelper";

export function numberWithSpaces(number: string | number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getSclon(value: number, i: string, r: string, rm: string): string {
    return StringHelper.getSclon(parseInt(value as any), i, r, rm);
}



