import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "absolute-full-size overlay"
}

import IImageBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/image/IImageBlockValues";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, inject} from "vue";
import {ImagesDontResizableInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";


import DraggableResizable from "~/cabinet/vue/interface/draggableResizable/DraggableResizable.vue";

const __default__ = {
    name: "ImageDisplay",
    components: {
        DraggableResizable
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

let props = __props;

let emits  = __emit;

let {htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<IImageBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let imagesDontResizable = inject(ImagesDontResizableInject, false);

let resizable = computed(() => {
    return !imagesDontResizable && !model.value.width100Percent && !model.value.widthAuto;
});

let imageStyle = computed(() => {
    if (model.value.width100Percent) {
        return {
            width: '100%',
            height: 'auto'
        };
    } else if (model.value.widthAuto) {
        return {
            width: 'auto',
            height: 'auto'
        };
    } else if (resizable.value) {
        return {
            width: `${model.value.width}px`,
            height: `${model.value.height}px`,
        }
    }
    return null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
            textAlign: _unref(model).align
        })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(resizable) ? DraggableResizable : 'div'), {
      draggable: false,
      "max-width": _unref(htmlConstructorEditor).params.type.maxContentWidth,
      width: _unref(model).width,
      "onUpdate:width": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).width) = $event)),
      height: _unref(model).height,
      "onUpdate:height": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).height) = $event)),
      resizable: _unref(resizable),
      ratio: _unref(model).fullWidth / _unref(model).fullHeight,
      "min-width": 1,
      class: "position-relative d-inline-block image-wrapper cursor-pointer",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('click'))),
      style: _normalizeStyle({
                width: _unref(model).width100Percent ? '100%' : (_unref(model).widthAuto ? 'auto' : null)
            })
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _unref(model).src,
          style: _normalizeStyle(_unref(imageStyle))
        }, null, 12, _hoisted_1),
        (_unref(resizable))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "expand top left" }, null, -1),
              _createElementVNode("div", { class: "expand top right" }, null, -1),
              _createElementVNode("div", { class: "expand bottom left" }, null, -1),
              _createElementVNode("div", { class: "expand bottom right" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 40, ["max-width", "width", "height", "resizable", "ratio", "style"]))
  ], 4))
}
}

})