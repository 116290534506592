import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {AbstractDataProviderActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "AbtractDataProviderActionBlockPreview"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<AbstractDataProviderActionDescription<any>>()
    },
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ActionBlockPreviewItem, { label: "Интеграция" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref(props).actionDescription.frontendParams.authDescr), 1)
      ]),
      _: 1
    })
  ]))
}
}

})