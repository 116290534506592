import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]

import {computed, inject, nextTick, ref} from 'vue';
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import HtmlConstructorBlock from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorBlock.vue";
import {
    HTML_CONSTRUCTOR_DRAG_GROUP,
    HtmlConstructorContentInject,
    HtmlConstructorEditorInject,
    ParentStructureInject
} from './Constants';
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Random from "~/ts/library/Random";
import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_TOP,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import ISavedBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/ISavedBlockValues";
import {HTML_BLOCK_SAVED} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {useSortable} from "@vueuse/integrations";
import {SortableEvent} from "sortablejs";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import {moveArrayElement} from "@vueuse/integrations/useSortable";


import Draggable from "~/node_modules/vuedraggable/src/vuedraggable.js";

const __default__ = {
    name: "HtmlConstructorBlockList",
    components: {Draggable}
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    blocksListEditable: { type: Boolean, default: true },
    blockStyle: {},
    multiColumn: { type: Boolean, default: false }
  }, {
    "modelValue": {
    default: []
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<IHtmlConstructorBlock<any>[]>(__props, "modelValue");

const HTML_CONSTRUCTOR_BLOCK_LIST_KEY = 'htmlConstructorBlockList';


let sortable = ref<HTMLDivElement>();

let htmlConstructorEditor = inject(HtmlConstructorEditorInject);
let htmlConstructorContent = inject(HtmlConstructorContentInject);
let parentStructure = inject(ParentStructureInject, null);

let drag = ref(false);

let instanceId = computed(() => {
    return `blockList_${Random.uid()}`;
});

let css = computed(() => {
    if (parentStructure?.structureStyles) {
        let selector = `.${instanceBlockClass.value} + .${instanceBlockClass.value}`;
        return {
            [selector]: {
                marginTop: `${parentStructure.structureStyles.blocksPaddingTop}px`
            }
        }
    } else {
        return null;
    }
});

let instanceBlockClass = computed(() => {
    return `${instanceId.value}_block`;
});

function getBlockKey(block: IHtmlConstructorBlock<any>) {
    if (!block.key) {
        block.key = `block_${Random.uid()}`;
    }
    return block.key;
}

let editable = computed(() => {
    return htmlConstructorContent.editable && props.blocksListEditable;
});

function onDrop(e: { data: { blockType: AbstractBlockType<any, any> } }) {
    let blockType = e.data.blockType;
    model.value.push({
        type: blockType.id,
        values: null
    });
}

const group = HTML_CONSTRUCTOR_DRAG_GROUP;




function selectBlock(block: IHtmlConstructorBlock<any>) {
    htmlConstructorEditor.selectedBlock.value = block;
}

function getType(block: IHtmlConstructorBlock<any>): AbstractBlockType<any, any> {
    return htmlConstructorEditor.params.blockTypesDictionary[block.type];
}

function isShowEditButton(block: IHtmlConstructorBlock<any>): boolean {
    let type = getType(block);
    return !!(type && type.formComponent && type.isShowEditButton);
}

function getToolbarPosition(block: IHtmlConstructorBlock<any>): HtmlConstructorBlockToolbarPosition {
    let type = getType(block);
    return type ? type.toolbarPosition : HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_TOP;
}

function isSelected(block: IHtmlConstructorBlock<any>): boolean {
    return block === htmlConstructorEditor.selectedBlock.value;
}

async function removeBlock(index: number) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить этот блок?");
        makeRemove(index);
    } catch (e) {

    }
}

function makeRemove(index: number) {
    model.value.splice(index, 1);
}

function replaceBlock(index: number, block: IHtmlConstructorBlock<any>) {
    model.value.splice(index, 1, block);
}

function isSaveable(block: IHtmlConstructorBlock<any>): boolean {
    let type = getType(block);
    if (type) {
        return type.isSaveable;
    }
    return false;
}

async function saveBlock(block: IHtmlConstructorBlock<any>, key: number) {
    let savedBlock = await htmlConstructorEditor.saveBlock(block);
    if (savedBlock) {
        try {
            await ElConfirm("Заменить исходный блок ссылкой на сохранённый блок? Это может быть полезно для того, чтобы в будущем, редактируя этот блок в одном месте, он менялся сразу во всех.")
            let block: IHtmlConstructorBlock<ISavedBlockValues> = {
                type: HTML_BLOCK_SAVED,
                values: {
                    blockId: savedBlock.id,
                    selfStyle: false,
                    style: null
                }
            };
            model.value.splice(key, 1, block)
        } catch (e) {

        }
    }
}

/*
    :list="model"
            :group="{ name: group, put: [group], revertClone: true }"
            @change="onChange"
            handle=".toolbar-button-move"
            :class="{
                draggable: true,
                'h-100': true
            }"
            :animation="200"
            ghost-class="sortable-ghost"
            -->
 */

useSortable(sortable, model, {
    group: {
        name: group,
        put: [
            group
        ],
        revertClone: true
    },
    onAdd: async (e: SortableEvent) => {
        e.item.remove();
        nextTick(async () => {
            let item: any = e.item;
            let newBlock = null;
            let prevList = item[HTML_CONSTRUCTOR_BLOCK_LIST_KEY];
            if (prevList) {
                newBlock = prevList[e.oldIndex];
            }
            let newIndex = e.newIndex;

            if (!newBlock) {
                let type = htmlConstructorEditor.params.blockTypes.find(type => type.id === item.getAttribute("data-id"));
                if (type instanceof AbstractBlockType) {
                    try {
                        newBlock = await type.getNewBlockForList();
                    } catch (e) {
                        model.value.splice(newIndex, 0);
                        return;
                    }
                }
            } else {
                toggleArrayElement(prevList, newBlock, false);
                delete item[HTML_CONSTRUCTOR_BLOCK_LIST_KEY];
            }
            if (newBlock) {
                model.value.splice(newIndex, 0, newBlock);
            }
        });
    },
    onStart: (e) => {
        (e.item as any)[HTML_CONSTRUCTOR_BLOCK_LIST_KEY] = model.value;
    },
    onUpdate: e => {
        moveArrayElement(model.value, e.oldIndex, e.newIndex);
    },

    handle: ".toolbar-button-move",
    animation: 200,
    ghostClass: "sortable-ghost"
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'position-relative': true,
            'h-100': true,
            empty: !_unref(model).length && _unref(editable)
        }),
    id: _unref(instanceId)
  }, [
    _createElementVNode("div", {
      ref_key: "sortable",
      ref: sortable,
      class: "draggable h-100"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(model), (block, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: getBlockKey(block),
          class: _normalizeClass({
                    selected: isSelected(block),
                    block: true,
                    [_unref(instanceBlockClass)]: true
                })
        }, [
          _withDirectives((_openBlock(), _createBlock(HtmlConstructorBlock, {
            modelValue: _unref(model)[key],
            "onUpdate:modelValue": ($event: any) => ((_unref(model)[key]) = $event),
            class: _normalizeClass({
                        selected: isSelected(block)
                    }),
            style: _normalizeStyle(_ctx.blockStyle),
            "multi-column": _ctx.multiColumn,
            onRemoveBlock: ($event: any) => (makeRemove(key)),
            onReplace: ($event: any) => (replaceBlock(key, $event))
          }, {
            default: _withCtx(() => [
              (_unref(editable) && !_unref(drag) && isSelected(block))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['toolbar', getToolbarPosition(block), 'html-editor-ui'])
                  }, [
                    (isShowEditButton(block))
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          link: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, { icon: "pencil" })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_tooltip, { content: "Перетащите блок, зажав левую кнопку мыши" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          link: "",
                          class: "toolbar-button-move"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, { icon: "arrows" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (isSaveable(_unref(model)[key]))
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 1,
                          content: "Сохранить блок, для того чтобы переиспользовать его в других шаблонах"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              onClick: ($event: any) => (saveBlock(_unref(model)[key], key)),
                              link: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(FontAwesomeIcon, { icon: "save" })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_tooltip, { content: "Удалить блок" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          link: "",
                          onClick: ($event: any) => (removeBlock(key))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, { icon: "trash" })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "class", "style", "multi-column", "onRemoveBlock", "onReplace"])), [
            [
              _directive_on_native,
              () => selectBlock(block),
              "click",
              { stop: true }
            ]
          ])
        ], 2))
      }), 128))
    ], 512),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "placeholder absolute-full-size align-items-center html-editor-ui" }, [
      _createElementVNode("div", { class: "w-100 text-center text-muted" }, "Перетащите сюда новые блоки")
    ], -1)),
    (_unref(css))
      ? (_openBlock(), _createBlock(MyStyle, {
          key: 0,
          id: `${_unref(instanceId)}_style`,
          "root-selector": `#${_unref(instanceId)}`,
          important: false,
          css: _unref(css)
        }, null, 8, ["id", "root-selector", "css"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})