import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue"

import {computed} from 'vue';
import {IFilter, IFiltersData, IOperation} from "./Interfaces";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";


const __default__ = {
    name: "MyFilterOperationSelect"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    filter: {},
    filtersData: {},
    operationsAvailableForChange: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let model = _useModel<string>(__props, "modelValue");

let operations = computed(() => {
    let operations = MyFilterHelper.getOperations(props.filtersData);
    return props.filter.allowedOperations.map(operationId => {
        return operations[operationId] ? operations[operationId] : null;
    });
});

function operationById(operationId: string): IOperation | null {
    return MyFilterHelper.getOperations(props.filtersData)[operationId];
}

function isDisabled(operation: IOperation): boolean | null {
    return props.operationsAvailableForChange.indexOf(operation.id) == -1 ? true : null;
}

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    class: "w-100"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operations), (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: _ctx.filter.multiple ? item.multipleDescr : item.descr,
          value: item.id,
          disabled: isDisabled(item)
        }, null, 8, ["label", "value", "disabled"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})