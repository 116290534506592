import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-muted mb-3" }
const _hoisted_2 = { class: "mt-2" }

import {onMounted, ref} from 'vue';
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {ReactiveListEntityInterface} from "~/core-ui/ts/request/AbstractReactiveListEntity";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";
import {BlogArticleInterface, CATEGORIES_ALL_ALIAS} from "~/cabinet/vue/client/blog/BlogArticleInterface";
import ClientRoutesBuilder from "~/cabinet/ts/routes/ClientRoutesBuilder";
import BlogRepostButton from "~/cabinet/vue/client/blog/BlogRepostButton.vue";
import {localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "BlogPopup"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let articleList = ref<BlogArticleInterface[]>();
let visible = ref(false);

async function fetch() {
    let result = await (new LcabApiRequest<ReactiveListEntityInterface<BlogArticleInterface>>({
        url: "/api/blog/getArticlesForPopup",
        silent: true,
        silentError: true
    })).send();
    let data = result.data;
    articleList.value = result.isSuccess && data.items.length ? data.items : null;
    visible.value = !!articleList.value;
}

onMounted(() => fetch());
setTimeout(() => {
    if (!visible.value) {
        fetch();
    }
}, 1000 * 60 * 5)

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_unref(articleList) && _unref(articleList).length)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: "Последние статьи",
        width: "800px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(articleList), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "news"
            }, [
              _createElementVNode("h4", null, [
                _createVNode(_component_router_link, {
                  to: _unref(ClientRoutesBuilder).getBlogArticleLocation(_unref(CATEGORIES_ALL_ALIAS), item.id),
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(localeDateTimeString)(item.dateTime)), 1),
              _createVNode(CabinetHtmlify, {
                value: item.text,
                escape: false
              }, null, 8, ["value"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(BlogRepostButton, { article: item }, null, 8, ["article"])
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})