import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, isRef as _isRef, mergeProps as _mergeProps } from "vue"

import {computed, ref} from 'vue';
import {IMySelectOptgroup, IMySelectOption} from "~/cabinet/vue/interface/form/MySelectInterface";
import Dictionary from "~/ts/library/Dictionary";
import Random from "~/ts/library/Random";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";


const __default__ = {
    name: "MySelect"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    options: {},
    optgroup: {},
    remoteUrl: {},
    multiple: { type: Boolean },
    disabled: { type: Boolean },
    valueKey: {},
    size: {},
    clearable: { type: Boolean },
    collapseTags: {},
    multipleLimit: {},
    name: {},
    autoComplete: {},
    placeholder: {},
    filterable: { type: Boolean },
    allowCreate: { type: Boolean },
    filterMethod: {},
    loadingText: {},
    noMatchText: {},
    noDataText: {},
    popperClass: {},
    reserveKeyword: {},
    defaultFirstOption: { type: Boolean },
    popperAppendToBody: {},
    automaticDropdown: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let tagsCache = ref<Dictionary<{ options: IMySelectOption[], optgroup: IMySelectOptgroup[] }>>({});
let remoteOptions = ref<IMySelectOption[]>();
let remoteOptgroup = ref<IMySelectOptgroup[]>();
let loading = ref(false);
let loadingId = ref<string>();
let openedOnce = ref(false);

let remote = computed(() => {
    return !!props.remoteUrl;
});

async function remoteMethod(query: string) {
    let url = props.remoteUrl;
    if (url) {
        let _remoteOptions: IMySelectOption[];
        let _remoteOptgroup: IMySelectOptgroup[];
        let cacheKey = url + "/" + query;
        let cache = tagsCache.value[cacheKey];
        if (cache) {
            _remoteOptions = cache.options;
            _remoteOptgroup = cache.optgroup;
        } else {
            loading.value = true;
            let rand = Random.uid();
            loadingId.value = rand;
            let fetch = await (new LcabApiRequest({
                url: url,
                p: {
                    query: query
                },
                silent: true
            })).send();
            if (loadingId.value == rand) {
                loading.value = false;

                if (fetch.isSuccess) {
                    _remoteOptions = fetch.getData("options", []);
                    _remoteOptgroup = fetch.getData("optgroup", []);
                    tagsCache.value[cacheKey] = {
                        optgroup: _remoteOptgroup,
                        options: _remoteOptions
                    };
                }
            } else {
                return;
            }
        }

        remoteOptions.value = _remoteOptions;
        remoteOptgroup.value = _remoteOptgroup;
    }
}

let isRemoteFilled = computed(() => {
    return !!remoteOptions.value || !!remoteOptgroup.value;
});

let computedOptions = computed(() => {
    return isRemoteFilled.value ? remoteOptions.value : props.options;
});

let computedOptgroup = computed(() => {
    return isRemoteFilled.value ? remoteOptgroup.value : props.optgroup;
});

function onVisibleChange(value: boolean) {
    if (value) {
        if (!openedOnce.value) {
            openedOnce.value = true;
            if (props.remoteUrl && props.options == null && props.optgroup == null) {
                remoteMethod('');
            }
        }
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, _mergeProps(_ctx.$props, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    remote: _unref(remote),
    "remote-method": remoteMethod,
    onVisibleChange: _cache[1] || (_cache[1] = ($event: any) => (onVisibleChange($event)))
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedOptions), (option) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          value: option.value,
          label: option.descr,
          key: option.value
        }, null, 8, ["value", "label"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedOptgroup), (item) => {
        return (_openBlock(), _createBlock(_component_el_option_group, {
          key: item.id,
          lable: item.descr
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                value: option.value,
                label: option.descr,
                key: option.value
              }, null, 8, ["value", "label"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["lable"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["modelValue", "remote"]))
}
}

})