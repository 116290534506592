import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_AGENT} from "~/cabinet/ts/Constant";
import {
    AgentCommonTarifPriceParam,
    AgentUslugaTarifPriceParam
} from "~/cabinet/vue/dealer/config/uslugi/tarif/agent/AgentTarifPrice";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const Agent: Usluga = Usluga.new({
    id: USLUGA_ID_AGENT,
    dealer: {
        config: {
            tarifComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-agent" */ '~/cabinet/vue/dealer/config/uslugi/tarif/agent/AgentTarifList.vue'))
        }
    },
    client: {},
    rawTarifPriceToAbstractTarifPrice: (tarifCurrencyId: string, price: any, path: any[]) => {
        if (path[0] == "common") {
            return AgentCommonTarifPriceParam.new(price);
        } else if (path[0] == "uslugi") {
            return AgentUslugaTarifPriceParam.new(price);
        }
        return null;
    }
});

export default Agent;
