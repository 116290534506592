import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_CALL_PASSWORD} from "~/cabinet/ts/Constant";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const CallPassword: Usluga = Usluga.new({
    id: USLUGA_ID_CALL_PASSWORD,
    dealer: {
        config: {
            tarifComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-callpassword" */ '~/cabinet/vue/dealer/config/uslugi/tarif/callPassword/CallPasswordTarifList.vue'))
        }
    },
    client: {
        tarifComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "client-callpassword" */ '~/cabinet/vue/client/tarif/CallPasswordTarifs.vue'))
    }
});

export default CallPassword;
