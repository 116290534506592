import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup } from "vue"

import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import { FilterActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import Random from "~/ts/library/Random";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "FilterBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<FilterActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<getActionDescriptionModelType<FilterActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    filters: [
        getNewItem()
    ]
}))

let filters = computed(() => {
    return props.actionDescription.getFilters(props.contentVariables);
});

function getNewItem() {
    return {
        outputId: Random.uid(),
        filterValues: {},
        descr: null as string
    };
}

function sort(item: any, direction: number) {
    let list = model.value.filters;
    let index = list.indexOf(item);
    if (index > -1) {
        list.splice(index, 1);
        index += direction;
        list.splice(index, 0, item);
    }
}

function addVariant() {
    model.value.filters.push(getNewItem());
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: addVariant
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Добавить еще один вариант ")
      ])),
      _: 1
    }),
    _createVNode(_TransitionGroup, {
      name: "list",
      tag: "span"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(model).filters, (item, key) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            key: item.outputId,
            class: "mt-4"
          }, {
            default: _withCtx(() => [
              _createVNode(ElCardHeader, {
                title: `Вариант ${key + 1}`,
                "button-col-span": 12
              }, {
                default: _withCtx(() => [
                  (key !== _unref(model).filters.length - 1)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        onClick: ($event: any) => (sort(item, 1)),
                        link: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, {
                            icon: "chevron-down",
                            light: ""
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (key !== 0)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 1,
                        onClick: ($event: any) => (sort(item, -1)),
                        link: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, {
                            icon: "chevron-up",
                            light: ""
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_unref(model).filters.length > 1)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 2,
                        link: "",
                        onClick: ($event: any) => (_unref(model).filters.splice(key, 1))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, { icon: "times" })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["title"]),
              _createVNode(ElCardSection, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(model).filters[key].descr,
                    "onUpdate:modelValue": ($event: any) => ((_unref(model).filters[key].descr) = $event),
                    placeholder: "Название для фильтров, будет видно в схеме. Необязательно для заполнения."
                  }, {
                    prepend: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Название")
                    ])),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(MyFilter, {
                    modelValue: _unref(model).filters[key].filterValues,
                    "onUpdate:modelValue": ($event: any) => ((_unref(model).filters[key].filterValues) = $event),
                    class: "mt-2",
                    "filters-data": _unref(filters)
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "filters-data"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})