import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "variables-preview" }

import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import TextInputWithVariablesHelper from './TextInputWithVariablesHelper';
import Dictionary from "~/ts/library/Dictionary";
import StringHelper from "~/ts/library/StringHelper";


const __default__ = {
    name: "InputWithVariablesPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    text: {},
    variables: {},
    bbcode: { type: Boolean }
  },
  setup(__props: any) {

const PREVIEW_START = "%variable-preview-start%";
const PREVIEW_STOP = "%variable-preview-stop%";


let props = __props;

let html = ref<string>();

let parts = ref<any[]>([]);
let instanceCache = ref<Dictionary<string>>({});


onMounted(() => {
    updateText();
});

watch(computed(() => props.text), () => updateText());
watch(computed(() => props.variables), () => updateText(), {deep: true});

let instance = getCurrentInstance().proxy;

function updateText() {
    let text = props.text;
    text = text.replace(helper.value.regexp, match => {
        let isInCache = instanceCache.value[match];
        let result: string;
        if (isInCache) {
            result = instanceCache.value[match];
        } else {
            result = helper.value.getLabel(match, (finalString: string) => {
                instanceCache.value[match] = finalString;
                if (!isInCache) {
                    updateText();
                }
            });
        }

        return PREVIEW_START + result + PREVIEW_STOP;
    });

    if (props.bbcode) {
        text = StringHelper.bbcodeToHtml(text);
    } else {
        text = StringHelper.htmlEntities(text);
    }
    text = text.split(PREVIEW_START).join("<span class='noneditable-variable-preview'>").split(PREVIEW_STOP).join("</span>");
    if (html.value != text) {
        html.value = text;
        instance.$el.innerHTML = text;
    }
}

let helper = computed(() => {
    return new TextInputWithVariablesHelper(props.variables ? props.variables : []);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1))
}
}

})