import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import {computed, ref} from 'vue';
import {__} from "~/ts/library/Translate";
import {ReactiveListEntityInterface} from "~/core-ui/ts/request/AbstractReactiveListEntity";
import ReactiveFilterableListRequest from "~/core-ui/ts/request/ReactiveFilterableListRequest";
import WindowHelper from "~/ts/library/WindowHelper";


const __default__ = {
    name: "MyPagination"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    fetchResult: {},
    pageSizes: { default: () => [30, 50, 100, 200, 300, 500] },
    request: {},
    scrollToTop: { type: Boolean, default: true }
  },
  emits: ["size-change", "current-change"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let displayCountRef = ref<Element>();

let result = computed(() => {
    return props.request ? props.request.data : props.fetchResult;
});

let displayCount = computed(() => {
    let onPage = result.value.onPage;
    let count = result.value.count;

    let _result = onPage > count ? count : onPage;
    let displayCount = "<a href='' onclick='return false;'>" + _result + "</a>";
    let str = __('Показано %1$s записей из %2$s', displayCount, count);
    let ref = displayCountRef.value;
    if (ref) {
        ref.innerHTML = str;
    }
    return str;
});


let layout = computed(() => {
    let layout = "slot";
    if (result.value.onPage < result.value.count) {
        layout += ", ->, pager";
    }
    return layout;
});

function onCurrentChange(page: number) {
    page--;
    emits('current-change', page);
    if (props.request) {
        props.request.make(page);
        if (props.scrollToTop) {
            WindowHelper.scrollTo(0, null, 150);
        }
    }
}

function onSizeChange(onPage: number) {
    emits('size-change', onPage);
    if (props.request) {
        props.request.make(0, onPage)
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(_component_el_pagination, {
    class: "my-pagination",
    total: _unref(result).count,
    "page-size": _unref(result).onPage,
    "page-sizes": _ctx.pageSizes,
    "current-page": _unref(result).page + 1,
    layout: _unref(layout),
    onCurrentChange: onCurrentChange,
    onSizeChange: onSizeChange,
    background: ""
  }, {
    default: _withCtx(() => [
      (!_unref(result).onPageDisabled)
        ? (_openBlock(), _createBlock(_component_el_dropdown, {
            key: 0,
            class: "my-pagination-total",
            trigger: "click",
            placement: "bottom-start",
            onCommand: _cache[0] || (_cache[0] = ($event: any) => (onSizeChange(parseInt($event))))
          }, {
            dropdown: _withCtx(() => [
              _createVNode(_component_el_dropdown_menu, { style: {"overflow":"hidden"} }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageSizes, (size) => {
                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: size,
                      command: size
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(__)('%s на страницу', size)), 1)
                      ]),
                      _: 2
                    }, 1032, ["command"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                ref_key: "displayCountRef",
                ref: displayCountRef,
                class: "my-pagination-right",
                innerHTML: _unref(displayCount)
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["total", "page-size", "page-sizes", "current-page", "layout"]))
}
}

})