import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-muted item"
}

import {computed, getCurrentInstance, onMounted, onUpdated, ref, watch} from 'vue';
import {
    FiltersModel,
    IFilterPreset,
    IFiltersData,
    RemoveFilterEventPayloadInterface
} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import {__} from "~/ts/library/Translate";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import MyFilterPreviewOrGroup from "~/cabinet/vue/interface/filter/MyFilterPreviewOrGroup.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyFilterPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    filtersData: {},
    value: { default: () => ({}) },
    emptyFiltersString: {},
    editable: { type: Boolean, default: true },
    withSearch: { type: Boolean },
    search: {},
    hideAddButton: { type: Boolean },
    placeholder: { default: __("Добавить фильтры") },
    maxWidth: {}
  },
  emits: ["remove-all-filters", "remove-filter", "click", "update:search", "search-enter"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let computedValue = ref<FiltersModel>();

let style = ref<any>();

function updateStyles() {
    let newStyle = {
        paddingLeft: getPadding('before'),
        paddingRight: getPadding('after')
    };
    if (JSON.stringify(newStyle) != JSON.stringify(style.value)) {
        style.value = newStyle;
    }
}

onUpdated(() => updateStyles());

onMounted(() => {
    computeValue();
    updateStyles();
});


let instance = getCurrentInstance().proxy;

function getPadding(side: string) {
    let result = 0;
    let slot = instance.$refs ? instance.$refs['button-slot-' + side] : null;
    if (slot) {
        result = (slot as HTMLElement).offsetWidth + 17;
    }
    return result ? result + "px" : null;
}


let isEmpty = computed(() => MyFilterHelper.isEmptyModel(computedValue.value));

let presetJson = computed<{ preset: IFilterPreset, json: string }[]>(() => {
    let result = [];
    if (props.filtersData.preset) {
        let items = props.filtersData.preset.items;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            result.push({
                preset: item,
                json: JSON.stringify(item.value)
            });
        }
    }
    return result;
});

let preset = computed(() => {
    let result: IFilterPreset;
    let presets = presetJson.value;
    if (presets.length) {
        let json = JSON.stringify(computedValue.value);
        for (let i = 0; i < presets.length; i++) {
            if (presets[i].json == json) {
                result = presets[i].preset;
                break;
            }
        }
    }
    return result;
});

let orGroups = computed(() => Array.isArray(computedValue.value) ? computedValue.value : [computedValue.value]);

function computeValue() {
    computedValue.value = MyFilterHelper.normalizeValue(props.value, props.filtersData, props.editable);
}

watch(computed(() => props.value), () => computeValue(), {deep: true})


return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['my-filter-preview', _unref(isEmpty) ? 'my-filter-preview-empty' : '', _ctx.withSearch ? 'with-search' : '']),
    style: _normalizeStyle(_unref(style))
  }, [
    (_ctx.$slots['preview-before'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "my-filter-button-slot before",
          ref: "button-slot-before",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "preview-before")
        ], 512))
      : _createCommentVNode("", true),
    (!_unref(isEmpty))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_unref(preset) != null)
            ? (_openBlock(), _createBlock(MyFilterPreviewTagTemplate, {
                key: 0,
                onClose: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('remove-all-filters'))),
                editable: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(preset).descr), 1)
                ]),
                _: 1
              }))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(orGroups), (orGroup, orGroupIndex) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (orGroupIndex > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(__)("Или".toLowerCase())), 1))
                    : _createCommentVNode("", true),
                  _createVNode(MyFilterPreviewOrGroup, {
                    class: "min-width-0",
                    editable: _ctx.editable,
                    value: orGroup,
                    "filters-data": _ctx.filtersData,
                    "or-group-index": orGroupIndex,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('click'))),
                    onRemoveFilter: _cache[3] || (_cache[3] = ($event: any) => (_unref(emits)('remove-filter', $event)))
                  }, null, 8, ["editable", "value", "filters-data", "or-group-index"])
                ], 64))
              }), 256))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (!_ctx.withSearch)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "my-filter-placeholder",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(emits)('click')))
              }, [
                _createVNode(_component_el_tag, {
                  type: "info",
                  size: "small",
                  class: "item"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
                  ]),
                  _: 1
                })
              ]))
            : _withDirectives((_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                class: "search-input",
                "model-value": _ctx.search,
                placeholder: _ctx.placeholder,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_unref(emits)('update:search', $event)))
              }, null, 8, ["model-value", "placeholder"])), [
                [
                  _directive_on_native,
                  () => _unref(emits)('search-enter'),
                  "keyup",
                  { enter: true }
                ],
                [_directive_on_native, () => _unref(emits)('click'), "click"]
              ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.$slots['preview-after'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "my-filter-button-slot after",
          ref: "button-slot-after",
          onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "preview-after")
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.editable && _unref(isEmpty) && !_ctx.filtersData.isStatic && !_ctx.hideAddButton)
      ? (_openBlock(), _createElementBlock("span", {
          key: 4,
          class: "my-filter-add-button",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(emits)('click')))
        }, [
          _createVNode(_component_el_button, { type: "primary" }, {
            default: _withCtx(() => [
              _createVNode(FontAwesomeIcon, {
                icon: "plus",
                light: ""
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 6))
}
}

})