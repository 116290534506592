import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {computed, onMounted, Ref, ref, watch} from "vue";
import MyFilterOrganizationLocationHelper, {
    IMyFilterOrganizationLocationByValueResult,
    IMyFilterOrganizationLocationItem,
    IMyFilterOrganizationLocationValue
} from "~/cabinet/vue/interface/filter/types/Location/MyFilterOrganizationLocationHelper";
import MyFilterOrganizationLocationSelect
    from "~/cabinet/vue/interface/filter/types/Location/MyFilterOrganizationLocationSelect.vue";
import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import Dictionary from "~/ts/library/Dictionary";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "MyFilterOrganizationLocation"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps(),
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let inputs = ["city", "district", "country"];




let model = _useModel<IMyFilterOrganizationLocationValue>(__props, "modelValue");

let localValue = ref<{
    country?: string
    district?: string,
    city?: string
}>({
    country: null,
    district: null,
    city: null
})
let countryList = ref<IMyFilterOrganizationLocationItem[]>([]);
let districtList = ref<IMyFilterOrganizationLocationItem[]>([]);
let cityList = ref<IMyFilterOrganizationLocationItem[]>([]);
let prevValue = ref<IMyFilterOrganizationLocationValue>();

let lists: Dictionary<Ref<IMyFilterOrganizationLocationItem[]>> = {
    "cityList": cityList,
    "countryList": countryList,
    "districtList": districtList
};

watch(model, newValue => {
    initValue(newValue);
});

onMounted(() => {
    initValue(model.value);
    initCountryList();
});

function initValue(newValue?: IMyFilterOrganizationLocationValue) {
    if (JSON.stringify(newValue) != JSON.stringify(prevValue.value)) {
        setPrevValue(Object.assign({}, newValue));
        if (newValue) {
            MyFilterOrganizationLocationHelper.fetchByValue(newValue).then(replaceLocalValue)
        } else {
            replaceLocalValue({} as IMyFilterOrganizationLocationByValueResult);
        }
    }
}

function setPrevValue(value: any) {
    prevValue.value = value;
}

function replaceLocalValue(param: IMyFilterOrganizationLocationByValueResult) {
    for (let key in localValue.value) {
        localValue.value[key] = (param as any)[key];
    }
    initDistrictList();
    initCityList();
}

function onInput(type: string, value: any) {
    localValue.value[type] = value;
    let setToNull = true;
    let result: IMyFilterOrganizationLocationValue;
    let localValueCopy: any = localValue.value;

    for (let i in inputs) {
        let key = inputs[i];
        if (type != key) {
            if (setToNull) {
                localValueCopy[key] = null;
            }
        } else {
            localValueCopy[type] = value;
            setToNull = false;
        }
        if (!result && localValueCopy[key]) {
            result = {essenceID: localValueCopy[key], essenceType: key + 'ID'};
        }
    }
    if (type == "country") {
        initDistrictList();
    } else if (type == "district") {
        initCityList();
    }

    setPrevValue(result);
    model.value = result;
}

function initList(type: string, listKey: string, parentId?: string) {
    lists[listKey].value = [];
    if (parentId && parentId.length) {
        MyFilterOrganizationLocationHelper.fetchList(type, parentId).then(result => {
            lists[listKey].value = result;
        });
    }
}

function initDistrictList() {
    initList("district", "districtList", localValue.value.country);
}

function initCityList() {
    initList("city", "cityList", localValue.value.district);
}

function initCountryList() {
    initList("country", "countryList", "make");
}


let span = computed(() => {
    if (localValue.value.district != null && localValue.value.district.length) {
        return 8;
    } else if (localValue.value.country != null && localValue.value.country.length) {
        return 12;
    } else {
        return 24;
    }
});


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 2 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { md: _unref(span) }, {
        default: _withCtx(() => [
          _createVNode(MyFilterOrganizationLocationSelect, {
            list: _unref(countryList),
            placeholder: _unref(__)('Любая страна'),
            "model-value": _unref(localValue).country,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onInput('country', $event)))
          }, null, 8, ["list", "placeholder", "model-value"])
        ]),
        _: 1
      }, 8, ["md"]),
      (_unref(localValue).country)
        ? (_openBlock(), _createBlock(_component_el_col, {
            key: 0,
            md: _unref(span)
          }, {
            default: _withCtx(() => [
              _createVNode(MyFilterOrganizationLocationSelect, {
                list: _unref(districtList),
                placeholder: _unref(__)('Любой регион'),
                "model-value": _unref(localValue).district,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (onInput('district', $event)))
              }, null, 8, ["list", "placeholder", "model-value"])
            ]),
            _: 1
          }, 8, ["md"]))
        : _createCommentVNode("", true),
      (_unref(localValue).district)
        ? (_openBlock(), _createBlock(_component_el_col, {
            key: 1,
            md: _unref(span)
          }, {
            default: _withCtx(() => [
              _createVNode(MyFilterOrganizationLocationSelect, {
                list: _unref(cityList),
                placeholder: _unref(__)('Любой город'),
                "model-value": _unref(localValue).city,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (onInput('city', $event)))
              }, null, 8, ["list", "placeholder", "model-value"])
            ]),
            _: 1
          }, 8, ["md"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})