import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  style: {"max-width":"200px"},
  class: "text-truncate"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import WebhookLogDialog from "~/cabinet/vue/api/WebhookLogDialog.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {ref} from "vue";
import {WebhookActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "WebhookBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<WebhookActionDescription>()
    },
  setup(__props) {

let props = __props;

let historyDialogVisible = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(props).params.descr)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(props).params.descr), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, _toDisplayString(_unref(props).params.webhook.url), 1),
    (_unref(props).params.logId)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _withDirectives((_openBlock(), _createBlock(_component_el_button, { link: "" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" История запросов ")
            ])),
            _: 1
          })), [
            [
              _directive_on_native,
              () => { _isRef(historyDialogVisible) //@ts-ignore
 ? historyDialogVisible.value = true : historyDialogVisible =  true; },
              "click",
              { stop: true }
            ]
          ]),
          (_unref(historyDialogVisible))
            ? (_openBlock(), _createBlock(WebhookLogDialog, {
                key: 0,
                visible: _unref(historyDialogVisible),
                "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(historyDialogVisible) ? (historyDialogVisible).value = $event : historyDialogVisible = $event)),
                "webhook-id": _unref(props).params.logId
              }, null, 8, ["visible", "webhook-id"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})