import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "ReceiverGroupIcon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    group: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FontAwesomeIcon, {
    icon: "folder",
    light: !_ctx.group.color || !_ctx.group.color.length,
    regular: _ctx.group.color && !!_ctx.group.color.length,
    color: _ctx.group.color && _ctx.group.color.length ? _ctx.group.color : 'muted'
  }, null, 8, ["light", "regular", "color"]))
}
}

})