import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-3 pt-2" }
const _hoisted_2 = { class: "flex-fill" }

import Dictionary from "~/ts/library/Dictionary";
import IAttentionTextBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/IAttentionTextBlockValues";
import TextDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/text/TextDisplay.vue";
import AttentionTextBlockType
    from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/AttentionTextBlockType";
import {HTML_BLOCK_TYPE_ATTENTION_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import {computed, PropType} from "vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "AttentionTextDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps(),
    inputStyle: {
        type: Object as PropType<Dictionary<any>>
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let _modelValue = _useModel<IAttentionTextBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let {htmlConstructorEditor} = useBlockDisplay(props);

let blockType = computed<AttentionTextBlockType>(() => {
    return htmlConstructorEditor.params.blockTypesDictionary[HTML_BLOCK_TYPE_ATTENTION_TEXT];
});

let attentionType = computed(() => {
    return blockType.value.paramsForEditor.attentionTypes.find(item => item.id == model.value.attentionType);
});

let color = computed(() => {
    return attentionType.value ? attentionType.value.color : null;
});

let icon = computed(() => {
    return attentionType.value ? attentionType.value.icon : null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "container d-flex",
    style: _normalizeStyle({
            borderLeftColor: _unref(color)
        })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(FontAwesomeIcon, {
        icon: _unref(icon),
        light: "",
        style: _normalizeStyle({
                    color: _unref(color),
                    fontSize: '2em'
                })
      }, null, 8, ["icon", "style"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TextDisplay, {
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        editable: _ctx.editable,
        styles: _ctx.styles
      }, null, 8, ["modelValue", "editable", "styles"])
    ])
  ], 4))
}
}

})