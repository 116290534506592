import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import MyFormConstructor from "~/cabinet/vue/interface/form/elements/constructor/MyFormConstructor.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_INFO,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_TEXTAREA
} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {ref, watch} from "vue";
import { CallActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "CallBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<CallActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<CallActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    form: null
}));

let previewModel = ref<any>();

watch(model, () => {
    previewModel.value = null;
}, {deep: true});

const types = [
    FIELD_TYPE_SELECT, FIELD_TYPE_CHECKBOX, FIELD_TYPE_TEXT, FIELD_TYPE_TEXTAREA, FIELD_TYPE_INFO
];

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, { class: "without-overflow" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { label: "Настройка анкеты для звонка" }, {
          default: _withCtx(() => [
            _createVNode(MyFormConstructor, {
              modelValue: _unref(model).form,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).form) = $event)),
              "single-section": "",
              "include-element-type": types,
              "element-name-same-as-descr": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (_unref(model).form)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "Предпросмотр анкеты"
            }, {
              default: _withCtx(() => [
                _createVNode(MyForm, {
                  form: _unref(model).form,
                  modelValue: _unref(previewModel),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(previewModel) ? (previewModel).value = $event : previewModel = $event))
                }, null, 8, ["form", "modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})