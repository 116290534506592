import SenderRouteBuilder from "~/cabinet/ts/routes/SenderRouteBuilder";
import {
    SMS_USLUGI_PLATFORM_ID,
    USLUGA_ID_AGENT,
    USLUGA_ID_CALL_GATE,
    USLUGA_ID_CALL_PASSWORD,
    USLUGA_ID_CASCADE,
    USLUGA_ID_EMAIL,
    USLUGA_ID_MAILRU,
    USLUGA_ID_MESSENGER,
    USLUGA_ID_ONLINE,
    USLUGA_ID_PAGER,
    USLUGA_ID_VIBER,
    USLUGA_ID_VOICE,
    USLUGA_ID_WHATSAPP
} from "~/cabinet/ts/Constant";
import {IBeforeLeftMenuDropdown, IRouteMeta} from "~/core-ui/ts/router/Interface";
import {__} from "~/ts/library/Translate";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import Section from "~/cabinet/vue/layout/lcab/Aside/Section";
import {ACCESS_CLIENT_STICKERS, ACCESS_NO_USLUGI} from "~/cabinet/ts/data/organization/IUserAccess";
import OnlineChatRouteBuilder from "~/cabinet/ts/routes/OnlineChatRouteBuilder";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {BADGE_STICKERS} from "~/cabinet/vue/interface/menu/BadgeManager";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import Dictionary from "~/ts/library/Dictionary";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import Uslugi from "~/cabinet/ts/data/usluga/Uslugi";
import UslugaMapper from "~/cabinet/ts/data/usluga/Uslugi";
import {CLIENT_EVENTS_ROUTES_BUILDER} from "~/cabinet/ts/routes/ClientEventsRoutesBuilder";
import CallPasswordRouteBuilder from "~/cabinet/ts/routes/CallPasswordRouteBuilder";
import {
    CLIENT_SENDERS_ROUTE_NAME,
    PHONEBOOK_CONTACTS_ROUTE,
    PHONEBOOK_DATA_SOURCE_ROUTE
} from "~/cabinet/ts/routes/Constants";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import {ClientPayRoutesBuilder} from "~/cabinet/ts/routes/ClientPayRoutesBuilder";
import AgentRouteBuilder from "~/cabinet/ts/routes/AgentRouteBuilder";
import DealerPage from "~/cabinet/vue/client/DealerPage.vue";
import CallGateRouteBuilder from "./CallGateRouteBuilder";
import {RouteLocationNamedRaw, RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import {ref} from "vue";

const CLIENT_DEALER_PAGES_ROUTE_NAME = "CLIENT_DEALER_PAGES";
const CLIENT_DEALER_PAGE_ROUTE_NAME = "CLIENT_DEALER_PAGE";

export const CLIENT_START_ROUTE_NAME = "CLIENT_START";
export const CLIENT_API_TOKENS_ROUTE_NAME = "CLIENT_API_TOKENS";
export const CLIENT_API_WEBHOOKS = "CLIENT_API_WEBHOOKS";
export const CLIENT_TARIFS = "CLIENT_TARIFS";
export const CLIENT_TARIFS_USLUGA = "CLIENT_TARIFS_USLUGA";
export const NOTIFICATIONS_ROUTE_NAME = "CLIENT_NOTIFICATIONS";
export const NOTIFICATIONS_LIST_ROUTE_NAME = NOTIFICATIONS_ROUTE_NAME + "_LIST";
export const NOTIFICATIONS_SUBSCRIBE_ROUTE_NAME = NOTIFICATIONS_ROUTE_NAME + "_SUBSCRIBE";

export const CONFIG_ROUTE_NAME = "CLIENT_CONFIG";
export const CONFIG_USERS_ROUTE_NAME = CONFIG_ROUTE_NAME + "_USERS";
export const CONFIG_USERS_NOTIFICATIONS = CONFIG_ROUTE_NAME + "_NOTIFICATIONS";
export const CONFIG_CHANGE_PASSWORD_ROUTE_NAME = CONFIG_ROUTE_NAME + "_CHANGE_PASSWORD";
export const CONFIG_OAUTH_ROUTE_NAME = CONFIG_ROUTE_NAME + "_OAUTH";

export const CONFIG_SECURITY = CONFIG_ROUTE_NAME + "_SECURITY";

export const AUTH_LOG_REPORT = "AUTH_LOG_REPORT";

export const FIN_REPORT_PAYMENTS = "FIN_REPORT_PAYMENTS";
export const FIN_REPORT_BALANCE_MOVE = "FIN_REPORT_BALANCE_MOVE";
export const FIN_REPORT_USLUGI_TASKS = "FIN_REPORT_USLUGI_TASKS";

const BLOG_ROUTE = "CLIENT_BLOG";
const BLOG_CATEGORY_ROUTE = `${BLOG_ROUTE}_CATEGORY`;
const BLOG_ARTICLE_ROUTE = `${BLOG_ROUTE}_ARTICLE`;

const PROMOCODES_ROUTE = "CLIENT_PROMOCODES";
const PROMOCODES_ACTIONS_ROUTE = `${PROMOCODES_ROUTE}_ACTIONS`;
const PROMOCODES_MANAGERS_ROUTE = `${PROMOCODES_ROUTE}_MANAGERS`;
const PROMOCODES_COUPON_REPORT_ROUTE = `${PROMOCODES_ROUTE}_COUPON_REPORT`;


const PHONEBOOK_GROUP_ROUTE_NAME = "PHONEBOOK_GROUP";
const PHONEBOOK_GROUPS_ROUTE = `${PHONEBOOK_CONTACTS_ROUTE}_groups`;
const PHONEBOOK_STATISTIC_ROUTE = `${PHONEBOOK_CONTACTS_ROUTE}_statistic`;
const PHONEBOOK_SEARCH_ROUTE = `${PHONEBOOK_CONTACTS_ROUTE}_search`;
const PHONEBOOK_TRASH_ROUTE = `${PHONEBOOK_CONTACTS_ROUTE}_trash`;
const PHONEBOOK_TRASH_GROUP_ROUTE_NAME = `${PHONEBOOK_TRASH_ROUTE}_group`;
const PHONEBOOK_CLEAR = `${PHONEBOOK_CONTACTS_ROUTE}_clear`;
const PHONEBOOK_CLEAR_UNDELIVERED = `${PHONEBOOK_CLEAR}_undelivered`;
const PHONEBOOK_CLEAR_DUPLICATE = `${PHONEBOOK_CLEAR}_duplicate`;
const PHONEBOOK_CLEAR_REMOVE_GROUPS = `${PHONEBOOK_CLEAR}_remove_groups`;
const PHONEBOOK_STOP_LIST = `${PHONEBOOK_CONTACTS_ROUTE}_stop`;
const PHONEBOOK_STOP_LIST_USLUGA = `${PHONEBOOK_STOP_LIST}_usluga`;
const PHONEBOOK_EXPORT_GROUPS = `PHONEBOOK_EXPORT_GROUPS`;
const PHONEBOOK_CONFIG = `PHONEBOOK_config`;
const PHONEBOOK_CONFIG_COLUMNS = `${PHONEBOOK_CONFIG}_columns`;


export const DOC_ROUTE = "CLIENT_DOC";
export const DOC_DETAILS_ROUTE = DOC_ROUTE + "_DETAILS";
const DOC_ACT_ROUTE = DOC_ROUTE + "_ACT";
const DOC_REC_ACT_ROUTE = DOC_ROUTE + "_REC_ACT";
const DOC_BILL_ROUTE = DOC_ROUTE + "_BILL";
export const DOC_CONTRACTS_ROUTE = DOC_ROUTE + "_CONTRACTS";
export const CLIENT_SUPPORT_ROUTE = "CLIENT_SUPPORT"
const CLIENT_SUPPORT_TICKET_ROUTE_NAME = CLIENT_SUPPORT_ROUTE + "_TICKET";


export default class ClientRoutesBuilder {
    public make(collapseSenders?: boolean): RouteRecordRaw[] {
        let result = [
            {
                name: "CLIENT_ROOT_ROUTE",
                path: "",
                meta: {
                    client: true
                } as IRouteMeta,
                children: [
                    this.getStart(),
                    this.getContacts(),
                    ...this.getSenders(collapseSenders),
                    this.getOnline(),
                    //...(onlineChatProject ? [this.getOnline()] : []),

                    //...(onlineChatProject ? [] : [this.getOnline()]),

                    this.getCallPassword(),
                    this.getCallGate(),
                    //this.getReceive(),
                    //this.getPcount(),
                    this.getEvents(),
                    this.getStickers(),
                    this.getAgent(),
                    this.getBlog(),
                    this.getTranslate(),
                    this.getApi(),
                    this.getTarifs(),
                    ClientPayRoutesBuilder.getPay(),
                    this.getDoc(),
                    this.getSupport(),
                    this.getNotifications(),
                    this.getConfig(),
                    this.getAuthLogReports(),
                    this.getDealerRequest(),
                    this.getDealerPages(),
                    ...this.getFinReports()
                ] as RouteRecordRaw[]
            },
        ] as RouteRecordRaw[];

        result[0].children.sort((a, b) => {
            let configSections = AccountStore.dealerCabinetConfig.value.menu.sections;
            let meta1 = a.meta as IRouteMeta;
            let meta2 = b.meta as IRouteMeta;
            let weight1 = result[0].children.indexOf(a);
            let weight2 = result[0].children.indexOf(b);
            if (meta1.section && meta2.section) {
                let section1 = configSections[meta1.section.routeName];
                let section2 = configSections[meta2.section.routeName];

                if (section1 && section2) {
                    weight1 = section1.weight;
                    weight2 = section2.weight;
                }
            }
            if (weight1 > weight2) {
                return 1;
            } else if (weight1 < weight2) {
                return -1;
            }
            return 0;
        });

        RouteHelper.setDefaultRouteComponents(result[0]);
        return result;
    }

    getAuthLogReports(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: AUTH_LOG_REPORT,
            path: "reports/authLog",
            component: () => import(/* webpackChunkName: "client-reports" */ '~/cabinet/vue/client/reports/AuthLogReport.vue'),
            meta: {
                access: () => AccountStore.access.value.isAdmin
            } as IRouteMeta
        };
        (Section.createForRoute(result, 'Последние заходы в личный кабинет', 'user')).setVisibleInMenu(false);
        return result;
    }

    getDealerRequest(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: "clientDealerRequest",
            path: "dealerRequest",
            component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/dealerRequest/DealerRequest.vue'),
            meta: {
                access: () => !AccountStore.isDealer.value
            } as IRouteMeta
        };
        (Section.createForRoute(result, 'Заявка на дилерство', 'user')).setVisibleInMenu(false);
        return result;
    }

    getFinReports(): RouteRecordRaw[] {
        let payments: RouteRecordRaw = {
            name: FIN_REPORT_PAYMENTS,
            path: "reports/fin/payments",
            component: () => import(/* webpackChunkName: "client-reports" */ '~/cabinet/vue/client/reports/fin/ClientPaymentsList.vue'),
            meta: {
                access: () => AccountStore.isNewDoc.value
            } as IRouteMeta
        };
        let balanceMoves: RouteRecordRaw = {
            name: FIN_REPORT_BALANCE_MOVE,
            path: "reports/fin/balanceMove",
            component: () => import(/* webpackChunkName: "client-reports" */ '~/cabinet/vue/client/reports/fin/ClientBalanceMoveList.vue'),
            meta: {
                access: () => AccountStore.isNewDoc.value
            } as IRouteMeta
        };
        let uslugiTasks: RouteRecordRaw = {
            name: FIN_REPORT_USLUGI_TASKS,
            path: "reports/fin/uslugiTasks",
            component: () => import(/* webpackChunkName: "client-reports" */ '~/cabinet/vue/client/reports/fin/ClientUslugiTasks.vue'),
            meta: {
                access: () => AccountStore.isNewDoc.value
            } as IRouteMeta
        };
        (Section.createForRoute(payments, 'Оплаты', 'line-chart')).setVisibleInMenu(false);
        (Section.createForRoute(balanceMoves, 'Движение денежных средств', 'line-chart')).setVisibleInMenu(false);
        (Section.createForRoute(uslugiTasks, 'Оказанные услуги', 'line-chart')).setVisibleInMenu(false);


        return [
            payments,
            balanceMoves,
            uslugiTasks
        ];
    }

    public getSenders(collapse?: boolean): RouteRecordRaw[] {
        let childrenDictionary: Dictionary<RouteRecordRaw> = {
            [USLUGA_ID_PAGER]: this.getPager(),
            [USLUGA_ID_VOICE]: this.getVoice(),
            [USLUGA_ID_VIBER]: this.getViber(),
            [USLUGA_ID_WHATSAPP]: this.getWhatsapp(),
            [USLUGA_ID_MESSENGER]: this.getMessenger(),
            [USLUGA_ID_CASCADE]: this.getCascade(),
            [USLUGA_ID_EMAIL]: this.getEmail(),
            [USLUGA_ID_MAILRU]: this.getMailRu(),
        };
        let children: RouteRecordRaw[] = [];
        for (let sender of UslugaMapper.senderByWeight()) {
            let route = childrenDictionary[sender.id];
            if (route) {
                children.push(route);
            }
        }

        children.push(this.getPromocodes());


        if (typeof collapse != "boolean") {
            collapse = AccountStore.dealerCabinetConfig.value.menu.collapseSenders;
        }
        if (collapse) {
            for (let item of children) {
                item.beforeEnter = (to, from, next) => {
                    (item.meta as IRouteMeta).beforeLeftMenuDropdown = {
                        back: {
                            name: CLIENT_SENDERS_ROUTE_NAME
                        },
                        items: this
                            .getSenders(false)
                            .filter(route => RouteAccessChecker.checkAccessByMeta(route.meta))
                            .filter(route => !!(route.meta as IRouteMeta).section)
                            .map(route => {
                                let section = (route.meta as IRouteMeta).section;
                                return new MenuItem(section.routeName, section.descr);
                            })
                    }
                    next();
                };
            }

            let route: RouteRecordRaw = {
                name: CLIENT_SENDERS_ROUTE_NAME,
                path: "senders",
                children,
                component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/SenderSelect.vue'),
                meta: {
                    access: [
                        () => {
                            for (let item of children) {
                                if (item.meta) {
                                    if (RouteAccessChecker.checkAccessByMeta(item.meta)) {
                                        return true;
                                    }
                                }
                            }
                            return false;
                        }
                    ]
                } as IRouteMeta
            };
            Section.createForRoute(route, "Рассылки", "envelope");
            return [route];
        } else {
            return children;
        }
    }

    private getStart(): RouteRecordRaw {
        let route: RouteRecordRaw = {
            path: "start",
            name: CLIENT_START_ROUTE_NAME,

            meta: {
                access: [
                    ACCESS_NO_USLUGI
                ]
            } as IRouteMeta,

            component: {
                template: `
                <div>Старт</div>
                `
            }
        };
        Section.createForRoute(route, __("Старт"), "home");
        return route;
    }

    private getDealerPages(): RouteRecordRaw {
        return {
            path: "page",
            name: CLIENT_DEALER_PAGES_ROUTE_NAME,
            meta: {} as IRouteMeta,
            children: [
                {
                    path: ":id",
                    name: CLIENT_DEALER_PAGE_ROUTE_NAME,
                    component: DealerPage,
                    props: p => ({
                        address: p.params.id
                    })
                }
            ]
        };
    }

    private getVoice(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_VOICE])).make();
    }

    private getViber(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_VIBER])).make();
    }

    private getWhatsapp(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_WHATSAPP])).make();
    }

    private getMessenger(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_MESSENGER])).make();
    }

    private getCascade(): RouteRecordRaw {
        let builder = (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_CASCADE]));
        let result = builder.make();
        let schemeRouteName = "cascade_scheme_report";
        builder.reportMenu.addChildrenItem(new MenuItem(schemeRouteName, "Схемы"));

        builder.reportRoute.children.push({
            name: schemeRouteName,
            path: "scheme",
            component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/cascade/report/schemeReport/CascadeSchemeReport.vue')
        });

        return result;
    }

    private getEmail(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_EMAIL])).make();
    }


    private getMailRu(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_MAILRU])).make();
    }


    private getPager(): RouteRecordRaw {
        return (new SenderRouteBuilder(this, SenderUslugi[USLUGA_ID_PAGER])).make();
    }

    public static getPhonebookGroupLocation(dataSourceId: string, groupId: number | string): RouteLocationNamedRaw {
        return {
            name: PHONEBOOK_GROUP_ROUTE_NAME,
            params: {
                dataSourceId,
                groupId: groupId.toString()
            }
        };
    }

    public static getPhonebookTrashGroupLocation(groupId: number): RouteLocationNamedRaw {
        return {
            name: PHONEBOOK_TRASH_GROUP_ROUTE_NAME,
            params: {
                groupId: groupId.toString()
            }
        };
    }

    public static getPhonebookTrashLocation(): RouteLocationNamedRaw {
        return {
            name: PHONEBOOK_TRASH_ROUTE
        }
    }

    private static getSenderUslugaStopListName(uslugaId: string) {
        return `${PHONEBOOK_STOP_LIST_USLUGA}_${uslugaId}`;
    }

    private getAgent(): RouteRecordRaw {
        return (new AgentRouteBuilder(this, Uslugi.find(USLUGA_ID_AGENT))).make();
    }

    public static getBlogCategoryLocation(categoryAlias: string): RouteLocationNamedRaw {
        return {
            name: BLOG_CATEGORY_ROUTE,
            params: {
                categoryAlias
            }
        };
    }

    public static getBlogArticleLocation(categoryAlias: string, articleId: number): RouteLocationNamedRaw {
        return {
            name: BLOG_ARTICLE_ROUTE,
            params: {
                categoryAlias,
                articleId: articleId.toString()
            }
        };
    }

    public static getBlogsLocation(): RouteLocationNamedRaw {
        return {
            name: BLOG_ROUTE
        }
    }

    public static isBlogCategorySelected(alias: string, route: RouteLocationNormalized): boolean {
        return !!route.matched.find(item => item.name == BLOG_CATEGORY_ROUTE && route.params.categoryAlias == alias);
    }


    private getBlog(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: BLOG_ROUTE,
            path: "/blog",
            component: () => import(/* webpackChunkName: "client-blog" */ '~/cabinet/vue/client/blog/BlogCategories.vue'),
            children: [
                {
                    path: ":categoryAlias",
                    name: BLOG_CATEGORY_ROUTE,
                    component: () => import(/* webpackChunkName: "client-blog" */ '~/cabinet/vue/client/blog/BlogArticleList.vue'),
                    props: p => ({
                        categoryAlias: p.params.categoryAlias
                    }),
                    children: [
                        {
                            path: ":articleId",
                            name: BLOG_ARTICLE_ROUTE,
                            component: () => import(/* webpackChunkName: "client-blog" */ '~/cabinet/vue/client/blog/BlogArticle.vue'),
                            props: p => ({
                                articleId: p.params.articleId,
                                categoryAlias: p.params.categoryAlias
                            })
                        }
                    ]
                }
            ],
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForViewNews()
                ]
            } as IRouteMeta
        };

        Section.createForRoute(result, "Блог", "newspaper")
            .setVisibleInMenu(false);

        return result;
    }

    private getTranslate(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: "translate",
            path: "/translate",
            children: [
                {
                    path: "strings",
                    name: "translate_strings",
                    component: () => import(/* webpackChunkName: "client-translate" */ '~/cabinet/vue/client/translate/strings/TranslateStringListWrapper.vue'),
                    meta: {
                        access: () => AccountStore.isSuperDealer.value
                    } as IRouteMeta
                },
                {
                    path: "tasks",
                    name: "translate_tasks",
                    component: () => import(/* webpackChunkName: "client-translate" */ '~/cabinet/vue/client/translate/tasks/TranslateTaskList.vue')
                }
            ],
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForViewNews()
                ]
            } as IRouteMeta
        };

        Section.createForRoute(result, "Переводы", "language")
            .setVisibleInMenu(false);

        return result;
    }

    private getPromocodes(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: PROMOCODES_ROUTE,
            path: "/promocodes",
            children: [
                {
                    path: "actions",
                    name: PROMOCODES_ACTIONS_ROUTE,
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/coupon/registers/RegisterListWrapper.vue')
                },
                {
                    path: "managers",
                    name: PROMOCODES_MANAGERS_ROUTE,
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/coupon/managers/ManagersList.vue')
                },
                {
                    path: "report/coupon",
                    name: PROMOCODES_COUPON_REPORT_ROUTE,
                    component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/coupon/report/CouponReport.vue')
                }
            ],
            meta: {
                menu: [
                    new MenuItem(PROMOCODES_ACTIONS_ROUTE, "Список акций", "tag"),
                    new MenuItem(PROMOCODES_MANAGERS_ROUTE, "Менеджеры", "users"),
                    new MenuItem(PROMOCODES_COUPON_REPORT_ROUTE, "Отправленные коды", "comment-check")
                ],
                access: [
                    () => AccountStore.access.value.checkAccessForLcabCoupon()
                ]
            } as IRouteMeta
        };

        Section.createForRoute(result, "Промо-акции", "tag");

        return result;
    }

    public static getContactBookDataSourceGroupsLocation(dataSourceId: string): RouteLocationNamedRaw {
        return {
            name: PHONEBOOK_GROUPS_ROUTE,
            params: {
                dataSourceId
            }
        }
    }

    private static contactsLeftMenu = ref<MenuItem[]>([]);

    public static updateContactsLeftMenu(dataSourceId: string): boolean {
        let stopMenu = new MenuItem(PHONEBOOK_STOP_LIST, "Стоп-лист", "ban");
        for (let senderUsluga of Uslugi.senderByWeight()) {
            stopMenu.addChildrenItem(
                new MenuItem(this.getSenderUslugaStopListName(senderUsluga.id), senderUsluga.descr)
            );
        }

        let dataSource = AccountStore.data.value.contactBookDataSourceList.find(item => item.id == dataSourceId);
        if (dataSource) {
            let leftMenu = ClientRoutesBuilder.contactsLeftMenu.value;
            leftMenu.splice(0, leftMenu.length);
            leftMenu.push(
                new MenuItem(PHONEBOOK_GROUPS_ROUTE, "Группы", "users"),
                new MenuItem(PHONEBOOK_SEARCH_ROUTE, "Поиск", "search"),
            );
            if (dataSource.isCabinetContactbook) {
                leftMenu.push(...[
                    new MenuItem(PHONEBOOK_STATISTIC_ROUTE, "Статистика", "pie-chart"),
                    new MenuItem(PHONEBOOK_TRASH_ROUTE, "Корзина", "trash"),
                    (new MenuItem(PHONEBOOK_CLEAR, "Очистка базы", "eraser"))
                        .addChildrenItem(
                            new MenuItem(PHONEBOOK_CLEAR_UNDELIVERED, "Не получающие сообщения")
                        )
                        .addChildrenItem(
                            new MenuItem(PHONEBOOK_CLEAR_DUPLICATE, "Повторяющиеся контакты")
                        )
                        .addChildrenItem(
                            new MenuItem(PHONEBOOK_CLEAR_REMOVE_GROUPS, "Удалить группы")
                        ),
                    stopMenu
                ]);
            }

            leftMenu.push(
                new MenuItem(PHONEBOOK_EXPORT_GROUPS, "Выгрузка в Excel", "download")
            );
            if (dataSource.isCabinetContactbook) {
                leftMenu.push(...[
                    (new MenuItem(PHONEBOOK_CONFIG, "Настройки", "cog"))
                        .addChildrenItem(
                            new MenuItem(PHONEBOOK_CONFIG_COLUMNS, "Поля контактов")
                        )
                ]);
            } else {
                leftMenu.push(...[stopMenu]);
            }
        }
        return !!dataSource;
    }

    private getContacts(): RouteRecordRaw {
        let dataSourceList = AccountStore.contactbookDataSourceList.value;

        let leftMenuDropdown: IBeforeLeftMenuDropdown = {
            back: {
                name: PHONEBOOK_CONTACTS_ROUTE
            },
            items: dataSourceList.map(dataSource => {
                return (new MenuItem(PHONEBOOK_DATA_SOURCE_ROUTE, dataSource.descr))
                    .addRouteParam("dataSourceId", dataSource.id);
            }),
            hideWhenItemsLessThan2: true
        }

        function getDataSource(id: string) {
            return dataSourceList.find(item => item.id == id);
        }


        let result: RouteRecordRaw = {
            name: PHONEBOOK_CONTACTS_ROUTE,
            path: "/contacts",
            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/SelectDataSource.vue'),
            beforeEnter: (to, from, next) => {
                if (to.name == PHONEBOOK_CONTACTS_ROUTE) {
                    if (dataSourceList.length == 1) {
                        return next(ClientRoutesBuilder.getContactBookDataSourceGroupsLocation(dataSourceList[0].id));
                    }
                }
                next();
            },
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForContactbookSection()
                ]
            } as IRouteMeta,
            children: [
                {
                    name: PHONEBOOK_DATA_SOURCE_ROUTE,
                    path: ":dataSourceId",
                    beforeEnter: (to, from, next) => {
                        if (ClientRoutesBuilder.updateContactsLeftMenu(to.params.dataSourceId as string)) {
                            next();
                        } else {
                            next({
                                name: PHONEBOOK_CONTACTS_ROUTE
                            });
                        }
                    },
                    children: [
                        {
                            path: "groups",
                            name: PHONEBOOK_GROUPS_ROUTE,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/groups/ContactGroupsList.vue'),
                            props: route => ({
                                dataSource: getDataSource(route.params.dataSourceId as string)
                            })
                        },
                        {
                            path: "groups/:groupId",
                            name: PHONEBOOK_GROUP_ROUTE_NAME,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/groups/Group.vue'),
                            props: route => ({
                                groupId: route.params.groupId,
                                dataSource: getDataSource(route.params.dataSourceId as string)
                            }),
                            meta: {
                                keepAlive: false
                            } as IRouteMeta
                        },
                        {
                            path: "statistic",
                            name: PHONEBOOK_STATISTIC_ROUTE,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/statistic/PhonebookStatistic.vue')
                        },
                        {
                            path: "search",
                            name: PHONEBOOK_SEARCH_ROUTE,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/search/PhonebookSearch.vue'),
                            props: route => ({
                                dataSource: getDataSource(route.params.dataSourceId as string)
                            })
                        },
                        {
                            path: "trash",
                            name: PHONEBOOK_TRASH_ROUTE,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/trash/TrashGroupsList.vue'),
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForContactbookDeleteContact() && AccountStore.access.value.checkAccessForContactbookOtherUsersGroup() && AccountStore.access.value.checkAccessForContactbookAddContact()
                                ]
                            } as IRouteMeta
                        },
                        {
                            path: "trash/:groupId",
                            name: PHONEBOOK_TRASH_GROUP_ROUTE_NAME,
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/trash/TrashGroup.vue'),
                            props: (p) => ({
                                groupId: parseInt(p.params.groupId as string)
                            }),
                            meta: {
                                keepAlive: false
                            } as IRouteMeta
                        },
                        {
                            path: "clear",
                            name: PHONEBOOK_CLEAR,
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForContactbookClear()
                                ]
                            } as IRouteMeta,
                            children: [
                                {
                                    path: "undelivered",
                                    name: PHONEBOOK_CLEAR_UNDELIVERED,
                                    component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/clear/Undelivered.vue'),
                                },
                                {
                                    path: "duplicate",
                                    name: PHONEBOOK_CLEAR_DUPLICATE,
                                    component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/clear/Duplicate.vue')
                                },
                                {
                                    path: "removeGroups",
                                    name: PHONEBOOK_CLEAR_REMOVE_GROUPS,
                                    component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/clear/RemoveGroups.vue')

                                }
                            ]
                        },
                        {
                            path: "stop",
                            name: PHONEBOOK_STOP_LIST,
                            children: [
                                ...(Uslugi.senderByWeight().map(senderUsluga => ({
                                    path: senderUsluga.id,
                                    name: ClientRoutesBuilder.getSenderUslugaStopListName(senderUsluga.id),
                                    component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/stop/StopListUsluga.vue'),
                                    props: {
                                        uslugaId: senderUsluga.id
                                    },
                                    meta: {
                                        access: [
                                            senderUsluga.activeForSelfAccessId,
                                            () => AccountStore.access.value.checkAccessForUslugaSection(senderUsluga.id)
                                        ]
                                    } as IRouteMeta

                                })))
                            ]
                        },
                        {
                            path: "export",
                            name: PHONEBOOK_EXPORT_GROUPS,
                            meta: {
                                access: [
                                    () => AccountStore.access.value.checkAccessForContactbookExportContacts()
                                ]
                            } as IRouteMeta,
                            props: route => ({
                                dataSource: getDataSource(route.params.dataSourceId as string)
                            }),
                            component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/export/ExportGroups.vue')
                        },
                        {
                            path: "config",
                            name: PHONEBOOK_CONFIG,
                            meta: {
                                access: [
                                    () => AccountStore.access.value.isAdmin
                                ]
                            } as IRouteMeta,
                            children: [
                                {
                                    path: "columns",
                                    name: PHONEBOOK_CONFIG_COLUMNS,
                                    component: () => import(/* webpackChunkName: "client-phonebook" */ '~/cabinet/vue/client/phonebook/config/PhonebookColumnsConfig.vue')

                                }

                            ]
                        }

                    ],
                    meta: {
                        menu: ClientRoutesBuilder.contactsLeftMenu.value,
                        beforeLeftMenuDropdown: leftMenuDropdown
                    } as IRouteMeta
                }
            ]
        };

        Section.createForRoute(result, "Контакты", "users");

        return result;
    }

    private getStickers(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            name: "CLIENT_STICKERS",
            path: "stickers",
            component: () => import(/* webpackChunkName: "client-stickers" */ '~/cabinet/vue/client/marketing/sticker/StickersPage.vue'),
            meta: {
                access: [
                    ACCESS_CLIENT_STICKERS,
                    () => AccountStore.access.value.checkAccessForLcabStickers()
                ]
            } as IRouteMeta
        };
        Section.createForRoute(result, "Стикеры", "star")
            .setBadge(new MenuBadge(BADGE_STICKERS));
        return result;
    }

    private getApi(): RouteRecordRaw {

        let result: RouteRecordRaw = {
            name: "CLIENT_API",
            path: "integration",
            children: [
                {
                    name: CLIENT_API_TOKENS_ROUTE_NAME,
                    path: "restApiTokens",
                    component: () => import(/* webpackChunkName: "client-api" */ '~/cabinet/vue/api/ApiTokens.vue')
                },
                {
                    name: "CLIENT_API_WEBHOOKS",
                    path: "webhooks",
                    component: () => import(/* webpackChunkName: "client-api" */ '~/cabinet/vue/api/Webhooks.vue')
                }
            ],
            meta: {
                access: [
                    () => AccountStore.access.value.isAdmin
                ],
                menu: [
                    new MenuItem(CLIENT_API_TOKENS_ROUTE_NAME, "Rest API", "code"),
                    new MenuItem(CLIENT_API_WEBHOOKS, "Webhook-и", "project-diagram")
                ]
            } as IRouteMeta
        };
        Section.createForRoute(result, "API", "code");
        return result;
    }

    private getOnline(): RouteRecordRaw {
        return (new OnlineChatRouteBuilder(this, Uslugi.find(USLUGA_ID_ONLINE), "Мои сайты"))
            .setOldTemplate(ClientRoutesBuilder.oldLcabTemplate("Онлайн чат", "/online"))
            .make();
    }

    private getCallPassword(): RouteRecordRaw {
        return (new CallPasswordRouteBuilder(this, Uslugi.find(USLUGA_ID_CALL_PASSWORD))).make();
    }

    private getCallGate(): RouteRecordRaw {
        return (new CallGateRouteBuilder(this, Uslugi.find(USLUGA_ID_CALL_GATE))).make();
    }

    private getEvents(): RouteRecordRaw {


        let result = CLIENT_EVENTS_ROUTES_BUILDER
            .addExcludedPlatformId(SMS_USLUGI_PLATFORM_ID)
            .makeRoute();
        Section.createForRoute(result, "События", "bullhorn");

        return result;
    }

    public static oldLcabTemplate(title: string, url: string, text: string = null): string {
        return `
                <el-card>
                    <h5 class="mb-4">${title}</h5>
                    <div>
                        <p>${text ? text : "Этот раздел пока что доступен только в старой версии личного кабинета."}</p>
                        <div>
                            <a href="${url}">
                                <el-button type="primary" round>Перейти</el-button>
                            </a>
                        </div>
                    </div>
                </el-card>
            `;
    }

    private getNotifications(): RouteRecordRaw {
        return {
            path: "notifications",
            name: NOTIFICATIONS_ROUTE_NAME,
            children: [
                {
                    path: "list",
                    name: NOTIFICATIONS_LIST_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "client-notifications" */ '~/cabinet/vue/client/notifications/Notifications.vue')
                },
                {
                    path: "subscribe",
                    name: NOTIFICATIONS_SUBSCRIBE_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "client-notifications" */ '~/cabinet/vue/client/notifications/SubscribeInMessengers.vue'),
                    meta: {
                        access: () => {
                            return AccountStore.noticeChannels.value.length > 0;
                        }
                    } as IRouteMeta
                }
            ],
            meta: {
                menu: [
                    new MenuItem(NOTIFICATIONS_LIST_ROUTE_NAME, "Уведомления"),
                    new MenuItem(NOTIFICATIONS_SUBSCRIBE_ROUTE_NAME, "Получать в мессенджеры")
                ],
                access: [
                    () => AccountStore.access.value.checkAccessForNotifications()
                ]
            } as IRouteMeta
        }
    }

    private getConfig(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            path: "config",
            name: CONFIG_ROUTE_NAME,
            children: [
                {
                    path: "users",
                    name: CONFIG_USERS_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/users/UsersList.vue'),
                    meta: {
                        access: () => AccountStore.access.value.isAdmin
                    } as IRouteMeta
                },
                {
                    path: "notifications",
                    name: CONFIG_USERS_NOTIFICATIONS,
                    component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/notifications/Notifications.vue'),
                    meta: {
                        access: () => AccountStore.access.value.isAdmin
                    } as IRouteMeta
                },
                {
                    path: "security",
                    name: CONFIG_SECURITY,
                    component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/security/Security.vue'),
                    meta: {
                        access: () => AccountStore.access.value.isAdmin
                    } as IRouteMeta
                },
                {
                    path: "changePassword",
                    name: CONFIG_CHANGE_PASSWORD_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/changePassword/ChangePassword.vue'),
                    meta: {
                        access: () => AccountStore.access.value.checkAccessForChangePassword()
                    } as IRouteMeta
                },
                {
                    path: "oauth",
                    name: CONFIG_OAUTH_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "client-config" */ '~/cabinet/vue/client/config/oauth/OAuthConfig.vue'),
                    meta: {
                        access: () => AccountStore.access.value.checkAccessForChangePassword() && AccountStore.dealerCabinetConfig.value.oAuthProviders.length > 0
                    } as IRouteMeta
                }
            ],
            meta: {
                menu: [
                    new MenuItem(CONFIG_USERS_ROUTE_NAME, "Пользователи кабинета", "users"),
                    new MenuItem(CONFIG_CHANGE_PASSWORD_ROUTE_NAME, "Смена пароля", "key"),
                    new MenuItem(CONFIG_OAUTH_ROUTE_NAME, "Авторизация через сервисы", "id-card"),
                    new MenuItem(CONFIG_USERS_NOTIFICATIONS, "Уведомления", "bullhorn"),
                    new MenuItem(CONFIG_SECURITY, "Безопасность", "lock")
                ]
            } as IRouteMeta
        };
        Section.createForRoute(result, "Настройки кабинета", "cog")
            .setVisibleInMenu(false);
        return result;
    }

    public static getSupportTicketLocation(ticketId: string): RouteLocationNamedRaw {
        return {
            name: CLIENT_SUPPORT_TICKET_ROUTE_NAME,
            params: {
                ticketId
            }
        }
    }

    private getSupport(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            path: "support",
            name: CLIENT_SUPPORT_ROUTE,
            component: () => import(/* webpackChunkName: "client-support" */ '~/cabinet/vue/client/support/ClientTicketList.vue'),
            children: [
                {
                    name: CLIENT_SUPPORT_TICKET_ROUTE_NAME,
                    path: "ticket/:ticketId",
                    component: () => import(/* webpackChunkName: "client-support" */ '~/cabinet/vue/client/support/ClientTicket.vue'),
                    props: p => p.params
                }
            ]
        };
        Section.createForRoute(result, 'Техподдержка', 'life-ring')
            .setVisibleInMenu(false);
        return result;
    }

    private getDoc(): RouteRecordRaw {
        let result: RouteRecordRaw = {
            path: "documents",
            name: DOC_ROUTE,
            children: [
                {
                    path: "details",
                    name: DOC_DETAILS_ROUTE,
                    component: () => import(/* webpackChunkName: "client-documents" */ '~/cabinet/vue/client/documents/details/DetailsList.vue'),
                },
                {
                    path: "acts",
                    name: DOC_ACT_ROUTE,
                    component: () => import(/* webpackChunkName: "client-documents" */ '~/cabinet/vue/client/documents/act/ActList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta
                },
                {
                    path: "recActs",
                    name: DOC_REC_ACT_ROUTE,
                    component: () => import(/* webpackChunkName: "client-documents" */ '~/cabinet/vue/client/documents/recAct/RecActList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta
                },
                {
                    path: "bills",
                    name: DOC_BILL_ROUTE,
                    component: () => import(/* webpackChunkName: "client-documents" */ '~/cabinet/vue/client/documents/bill/BillList.vue'),
                    meta: {
                        access: () => AccountStore.isNewDoc.value
                    } as IRouteMeta
                },
                {
                    path: "contracts",
                    name: DOC_CONTRACTS_ROUTE,
                    component: () => import(/* webpackChunkName: "client-documents" */ '~/cabinet/vue/client/documents/contract/ContractList.vue'),
                }
            ],
            meta: {
                menu: [
                    new MenuItem(DOC_DETAILS_ROUTE, "Реквизиты", "file-alt"),
                    new MenuItem(DOC_ACT_ROUTE, "Акты", "file-alt"),
                    new MenuItem(DOC_BILL_ROUTE, "Счета", "file-alt"),
                    new MenuItem(DOC_CONTRACTS_ROUTE, "Договоры", "file-alt"),
                    new MenuItem(DOC_REC_ACT_ROUTE, "Акты сверок", "file-alt")
                ],
                access: () => AccountStore.access.value.checkAccessForLcabDocuments()
            } as IRouteMeta
        };
        Section.createForRoute(result, "Документы", "file")
            .setVisibleInMenu(false);
        return result;
    }

    private getTarifs(): RouteRecordRaw {
        let menu: MenuItem[] = [];
        let childrens: RouteRecordRaw[] = [];
        let uslugi = Uslugi
            .byWeight()
            .filter(usluga => usluga.client.tarifComponent);
        for (let usluga of uslugi) {
            let name = CLIENT_TARIFS_USLUGA + "_" + usluga.id;
            menu.push(new MenuItem(name, usluga.descr, usluga.icon));
            childrens.push({
                name,
                path: usluga.id,
                component: usluga.client.tarifComponent as any,
                props: {
                    uslugaId: usluga.id
                },
                meta: {
                    access: [
                        usluga.activeForSelfAccessId,
                        () => AccountStore.access.value.checkAccessForUslugaSection(usluga.id)
                    ]
                } as IRouteMeta
            })
        }

        return {
            path: "tarifs",
            name: CLIENT_TARIFS,
            children: childrens,
            meta: {
                menu
            } as IRouteMeta
        };
    }

}
