import Dictionary from "~/ts/library/Dictionary";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import ReactiveFilterableListRequest from "~/core-ui/ts/request/ReactiveFilterableListRequest";
import {computed, ComputedRef, ExtractPropTypes, getCurrentInstance, nextTick, PropType, ref, watch} from "vue";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";
import {ReactiveListEntityInterface} from "~/core-ui/ts/request/AbstractReactiveListEntity";
import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import WindowHelper from "~/ts/library/WindowHelper";
import ReactiveFilterableRequest from "~/core-ui/ts/request/ReactiveFilterableRequest";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import MyRemoteDataInterface from "~/cabinet/vue/interface/data/MyRemoteDataInterface";

export type USE_REMOTE_DATA_EMITS = {
    (e: "update:items", payload: any): void,
    (e: "update:params", payload: any): void,
    (e: "load-start"): void,
    (e: "result", payload: any): void,
    (e: "update:result", payload: any): void,
    (e: "update:data", payload: any): void,
    (e: "load", payload: any): void,
    (e: "error", payload: any): void,
    (e: "items", payload: any): void,
    (e: "fetch-result", payload: any): void
};


export function useRemoteData(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useRemoteDataProps>>>,
    params?: {
        onReloadStart?: () => void,
        onReloadStop?: () => void,
        onCreated?: () => void,
        computedDataParams?: ComputedRef,
        makeScrollToTop?: () => Promise<void>
    }
) {
    //TODO: надо проверять это на работоспособность
    /*
    defineEmits<{
        (e: "update:params", payload: any): void,
        (e: "load-start"): void,
        (e: "result", payload: any): void,
        (e: "update:result", payload: any): void,
        (e: "update:data", payload: any): void,
        (e: "load", payload: any): void,
        (e: "error", payload: any): void,
        (e: "items", payload: any): void,
        (e: "fetch-result", payload: any): void
    }>();*/


    /*
    let instance.$emit = defineinstance.$emit<{
        (e: "update:params", params: any): void,
        (e: "load-start"): void,
        (e: "result", result: LcabApiResult<any>): void,
        (e: "update:result", result: LcabApiResult<any>): void,
        (e: "load", result: LcabApiFetchListResult<any>): void,
        (e: "error", result: LcabApiFetchListResult<any>): void,
        (e: "items", value: any[]): void,
        (e: "update:items", value: any[]): void,
        (e: "fetch-result", value: LcabApiFetchListResult<any>): void,
        (e: "update:result", value: LcabApiResult<any>): void,
        (e: "update:data", value: any): void
    }>();*/
    let instance = getCurrentInstance().proxy;
    let deprecatedLoading = ref(false);
    let instanceId = ref<string>();
    let requestId = ref<string>();

    let lastUsedParamsJson = ref<string>();
    let deprecatedFetchResult = ref<LcabApiFetchListResult<any>>();

    let isLoading = computed(() => {
        return props.request ? props.request.isLoading : deprecatedLoading.value;
    });

    let fetchResult = computed<ReactiveListEntityInterface<any>>(() => {
        if (props.request) {
            return props.request.data;
        } else if (deprecatedFetchResult.value) {
            return deprecatedFetchResult.value;
        }
        return null;
    });

    watch(computed(() => props.dataUrl), () => {
        reloadOnNextTick();
    });

    watch(computed(() => props.request), () => {
        reloadOnNextTick();
    });

    async function reloadOnNextTick() {
        try {
            await Delay.make(50, instanceId.value, true);
            reload(0);
        } catch (e) {

        }
    }

    watch(computed(() => props.paramsUrl), () => {
        loadParams()
    });

    async function loadParams() {
        instance.$emit("update:params", {});
        if (props.paramsUrl) {
            let result = await LcabApiRequest.fetch({
                url: props.paramsUrl,
                silent: props.silent
            });
            if (!result.showMessageOnError()) {
                instance.$emit("update:params", result.getData());
            }
        }
    }


    async function makeScrollToTop() {
        if (params?.makeScrollToTop) {
            await params.makeScrollToTop();
        } else {
            if (props.scrollToTop) {
                await WindowHelper.scrollToTop(null);
            }
        }
    }


    let computedItems = computed<any[]>(() => {
        if (props.dataUrl || props.request) {
            return fetchResult.value ? fetchResult.value.items : [];
        } else {
            return props.items;
        }
    });

    let computedDataParams = computed(() => {
        return params?.computedDataParams ? params.computedDataParams.value : props.dataParams;
    });

    watch(computedDataParams, (newParams) => {
        let json = JSON.stringify(newParams);
        if (json != lastUsedParamsJson.value) {
            lastUsedParamsJson.value = json;
            reloadOnNextTick();
        }
    }, {deep: true});

    let isDisabled = computed(() => {
        if (props.disabled) {
            return true;
        }
        if (props.request && props.request instanceof ReactiveFilterableRequest) {
            return props.request.disabled;
        }
        return false;
    });


    async function reload(page?: number, onPage?: number) {
        if (!isDisabled.value) {
            if (props.request) {
                props.request.make(page, onPage);
            } else if (props.dataUrl) {
                let currentRequestId = requestId.value = Random.uid();

                if (page == null) {
                    page = fetchResult.value ? fetchResult.value.page : 0;
                }
                lastUsedParamsJson.value = JSON.stringify(computedDataParams.value);
                await makeScrollToTop();
                if (params?.onReloadStart) {
                    params?.onReloadStart();
                }

                onPage = onPage ? onPage : (fetchResult.value ? fetchResult.value.onPage : 30);
                let startTime: number;
                if (props.minimumLoadTime) {
                    startTime = (new Date()).getTime();
                }
                deprecatedLoading.value = true;
                instance.$emit("load-start");
                let result = await LcabApiRequest.fetchList({
                    url: props.dataUrl,
                    p: Object.assign({}, computedDataParams.value, {
                        page: page,
                        onPage: onPage
                    }),
                    vue: instance,
                    silentError: !props.showRequestError
                }, props.dataType);

                if (currentRequestId == requestId.value) {
                    instance.$emit("result", result.originalResult);
                    instance.$emit("update:result", result.originalResult);

                    if (props.showError) {
                        result.showMessageOnError();
                    }
                    if (result.isSuccess) {
                        if (props.minimumLoadTime) {
                            let delta = (new Date()).getTime() - startTime;
                            if (delta < props.minimumLoadTime) {
                                await Delay.make(props.minimumLoadTime - delta);
                            }
                        }
                        deprecatedFetchResult.value = result;
                        nextTick(() => {
                            instance.$emit("load", result);
                            if (params?.onReloadStop) {
                                params?.onReloadStop();
                            }

                            deprecatedLoading.value = false;
                        });
                    } else {
                        deprecatedLoading.value = false;
                        instance.$emit("error", result);
                    }
                }
            }
        }
    }

    watch(computedItems, () => {
        instance.$emit("items", computedItems.value);
        instance.$emit("update:items", computedItems.value);
    });

    watch(deprecatedFetchResult, () => {
        instance.$emit("fetch-result", deprecatedFetchResult.value);
        instance.$emit("update:result", deprecatedFetchResult.value?.originalResult);
        instance.$emit("update:data", deprecatedFetchResult.value?.originalResult.getData());
    });

    instanceId.value = Random.uid();
    reloadOnNextTick();
    if (params?.onCreated) {
        params?.onCreated();
    }
    loadParams();

    return {
        fetchResult,
        isLoading,
        computedItems,
        reload,
        exposed: {
            reload
        } as MyRemoteDataInterface,
        request: computed(() => props.request)
    }
}


export function useRemoteDataProps<Item, Params>() {
    return {
        rowKey: {type: String},
        dataUrl: {type: String},
        items: {type: Array as PropType<any[]>},
        data: {type: Object as PropType<Dictionary<any>>},
        result: {type: Object as PropType<LcabApiResult<any>>},
        dataParams: {
            type: Object as PropType<Dictionary<any>>,
            default: () => ({})
        },
        disabled: {
            type: Boolean
        },
        minimumLoadTime: {
            type: Number
        },
        paramsUrl: {
            type: String
        },
        params: {
            type: Object as PropType<Params>
        },
        silent: {
            type: Boolean
        },
        showError: {
            type: Boolean,
            default: true
        },
        showRequestError: {
            type: Boolean,
            default: true
        },
        scrollToTop: {
            type: Boolean,
            default: false
        },
        dataType: {
            type: Function as PropType<AbstractEntityBuilder<any>>
        },
        request: {
            type: Object as PropType<ReactiveFilterableListRequest<any, any> | ReactiveRequest<any>>
        }
    }
}
