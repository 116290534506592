import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mt-1 text-muted font-size-small"
}
const _hoisted_3 = {
  key: 1,
  class: "text-red"
}
const _hoisted_4 = {
  key: 1,
  class: "text-red"
}
const _hoisted_5 = {
  key: 2,
  class: "text-muted"
}

import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {
    OnlineChatMessageToOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "OnlineChatMessageToOperatorBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatMessageToOperatorActionDescription>()
    },
  setup(__props) {

let props = __props;

let operators = computed(() => {
    return props.actionDescription.frontendParams.operators
        .filter(option => props.params.operators.indexOf(option.value) > -1)
        .map(option => option.descr)
        .join(", ");
});

let messageType = computed(() => {
    return props.actionDescription.frontendParams.messageTypes
        .find(option => props.params.messageTypeId == option.id);
});

let operatorsCount = computed(() => {
    return props.actionDescription.frontendParams.operatorsCount.find(item => item.value == props.params.operatorsCount);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ActionBlockPreviewItem, { label: "Получатели" }, {
      default: _withCtx(() => [
        (_unref(operators).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createTextVNode(_toDisplayString(_unref(operators)) + " ", 1),
              (_unref(operatorsCount))
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(operatorsCount).descr), 1))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, " Не заданы "))
      ]),
      _: 1
    }),
    _createVNode(ActionBlockPreviewItem, { label: "Тип сообщения" }, {
      default: _withCtx(() => [
        (_unref(messageType))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(messageType).descr), 1)
            ], 64))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, " Не задан "))
      ]),
      _: 1
    }),
    _createVNode(ActionBlockPreviewItem, { label: "Текст" }, {
      default: _withCtx(() => [
        (_ctx.params.isUseStandartContent && _ctx.actionDescription.frontendParams.isStandartContentAvailable)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" Стандартный текст сообщения ")
            ], 64))
          : (_ctx.params.text)
            ? (_openBlock(), _createBlock(InputWithVariablesPreview, {
                key: 1,
                text: _ctx.params.text,
                variables: _ctx.contentVariables
              }, null, 8, ["text", "variables"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, " Текст не задан "))
      ]),
      _: 1
    })
  ]))
}
}

})