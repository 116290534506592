import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {NoteActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "NoteBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<NoteActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<NoteActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    text: ""
}))

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Заметка" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _unref(model).text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).text) = $event)),
            type: "textarea",
            autosize: {minRows: 4, maxRows:20},
            placeholder: "Введите текст заметки"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})