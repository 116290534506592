import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AuthForm from "~/cabinet/vue/auth/AuthForm.vue";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import {computed, onBeforeUnmount, onMounted} from "vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";





const __default__ = {
    name: "AuthLayout"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let dealerCss = computed(() => AccountStore.data.value.dealerCabinetConfig.css);

onMounted(() => {
    document.body.classList.add("auth");
});

onBeforeUnmount(() => {
    document.body.classList.remove("auth");
});


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "container-valign-middle" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_main, { style: {"overflow":"visible"} }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            align: "middle",
            style: {"position":"initial"},
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 24,
                lg: {span: 8, offset: 8},
                md: {span: 12, offset: 6}
              }, {
                default: _withCtx(() => [
                  _createVNode(AuthForm, { class: "text-left" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(MyStyle, {
        id: "dealer-styles",
        "css-string": _unref(dealerCss)
      }, null, 8, ["css-string"])
    ]),
    _: 1
  }))
}
}

})