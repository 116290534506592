import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import Random from "~/ts/library/Random";
import {onMounted, onUnmounted} from "vue";
import InlineChatWidgetExposeInterface from "~/cabinet/vue/interface/inlineWidget/InlineChatWidgetExposeInterface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "InlineChatWidget"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    height: { default: 550 }
  },
  setup(__props: any, { expose: __expose }) {

let props = __props;

let id = `InlineChat_${Random.uid(10, 10)}`;

onMounted(() => {
    (function c(d, w, m) {
        (window as any).supportAPIMethod = m;
        var s = d.createElement('script');
        s.id = 'supportScript';
        var id = '593adecd804fc4e32e7e865d659f2356';
        s.src = '/support/support.js?h=' + id;
        w[m] = w[m] ? w[m] : function () {
            (w[m].q = w[m].q ? w[m].q : []).push(arguments);
        };
        (d.head ? d.head : d.body).appendChild(s);
    })(document, window as any, id);
});

function getObject(): any {
    return (window as any)[id]
}

__expose<InlineChatWidgetExposeInterface>({
    sendMessageToClient: text => {
        let object = getObject();
        if (object) {
            object('receiveMessage', {
                text,
                allowOfflineOperator: true
            });
        }
    },
    sendCommentToOperator: text => {
        let object = getObject();
        if (object) {
            let once = false;
            object("listenOperatorsStatus", function (onlineOperatorIds: string[]) {
                if (onlineOperatorIds.length > 0 && !once) {
                    once = true;
                    let object = getObject();

                    object("sendMessage", {
                        text,
                        messageTypeId: "comment"
                    });
                }
            });
        }
    }
});

onUnmounted(() => {
    let object = getObject();
    if (object) {
        object("destroy");
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: `${_unref(id)}-container`,
    style: _normalizeStyle({
            height: `${_unref(props).height}px`,
            width: '100%'
        })
  }, null, 12, _hoisted_1))
}
}

})