import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ElCardSectionWithRoute from "~/cabinet/vue/interface/card/ElCardSectionWithRoute.vue";


const __default__ = {
    name: "NeedCreateSource"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    icon: {},
    routeName: {}
  },
  setup(__props: any) {

let props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ElCardSectionWithRoute, {
    "route-name": _ctx.routeName,
    icon: _ctx.icon
  }, {
    text: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode("Перед созданием рассылки необходимо заказать имя отправителя")
    ])),
    tip: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode("Кликните,чтобы перейти к заказу")
    ])),
    _: 1
  }, 8, ["route-name", "icon"]))
}
}

})