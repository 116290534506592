import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "text-red"
}

import {
    EventAsActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {computed} from "vue";


const __default__ = {
    name: "EventAsActionBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<EventAsActionDescription>()
    },
  setup(__props) {

let props = __props;

let errorDescr = computed(() => {
    if (props.params.platformId && props.params.eventDescriptionId) {
        if (props.params.eventIdentifiers && props.params.eventIdentifiers.length) {
            if (selectedEvent.value) {
                return null;
            } else {
                return `Событие не найдено (возможно, оно устарело)`;
            }
        } else {
            return `Не выбраны критерии, по которым события должны совпасть`;
        }
    } else {
        return `Событие не выбрано`;
    }
});

let selectedEvent = computed(() => {
    let p = props.params;
    return props.actionDescription.frontendParams.events
        .find(item => p.platformId == item.platformId && p.authPrimaryId == item.authPrimaryId && p.eventDescriptionId == item.eventDescriptionId);
});

let needToBeEqual = computed(() => {
    if (selectedEvent.value) {
        return selectedEvent.value.eventIdentifiers.filter(item => props.params.eventIdentifiers.indexOf(item.id) > -1);
    }
    return [];
});


return (_ctx: any,_cache: any) => {
  return (_unref(errorDescr))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(errorDescr)), 1))
    : (_unref(selectedEvent))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_unref(selectedEvent).descr) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mt-2" }, "Должно совпасть:", -1)),
          (_unref(needToBeEqual).length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(needToBeEqual), (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "text-left"
                  }, [
                    _createVNode(FontAwesomeIcon, {
                      class: "text-primary",
                      icon: "check"
                    }),
                    _createTextVNode(" " + _toDisplayString(item.descr), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, "Не выбрано "))
        ]))
      : _createCommentVNode("", true)
}
}

})