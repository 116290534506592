import {ElMessageBoxOptions, ElMessageBox} from "element-plus";
import {__} from "~/ts/library/Translate";

export default function ElConfirm(message: string, options?: ElMessageBoxOptions) {
    if (!options) {
        options = {};
    }
    options = {
        ...{
            title: __("Требуется подтверждение"),
            cancelButtonText: __("Отмена")
        },
        ...options
    };
    return ElMessageBox.confirm(message, options);
}