import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lh-primary" }
const _hoisted_2 = { class: "table mb-2" }

import TarifPriceInput from "~/cabinet/vue/dealer/config/uslugi/tarif/common/TarifPriceInput.vue";
import {
    usePagerPriceProps
} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/usePagerPrice";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {computed} from "vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ScalePagerTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerTarifPrice";
import TarifPriceCurrencySelector
    from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/TarifPriceCurrencySelector.vue";


const __default__ = {
    name: "PagerScalePriceEdit"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...usePagerPriceProps<ScalePagerTarifPrice>()
},
  setup(__props) {

let props = __props;

function onMinCountChange(from: number | string, to: number) {
    let value = props.modelValue.prices[from];
    deleteValue(from);
    props.modelValue.prices[to.toString()] = value;
}

function deleteValue(from: number | string) {
    delete props.modelValue.prices[from.toString()];
}

function addStep() {
    let maxValue = -1;
    for (let key in props.modelValue.prices) {
        if (props.modelValue.prices.hasOwnProperty(key)) {
            maxValue = parseInt(key);
        }
    }
    props.modelValue.prices[(maxValue + 1).toString()] =0;
}

let count = computed(() => {
    return ObjectHelper.arrayFromDictionary(props.modelValue.prices).length;
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th"),
          _createElementVNode("th", { width: "50%" }, "Количество СМС в месяц"),
          _createElementVNode("th", { width: "50%" }, "Цена 1 СМС")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).modelValue.prices, (price, minCount, index) => {
          return (_openBlock(), _createElementBlock("tr", { key: index }, [
            _createElementVNode("td", null, [
              _createVNode(MyIconButton, {
                delete: "",
                disabled: _unref(count) < 2,
                onClick: ($event: any) => (deleteValue(minCount))
              }, null, 8, ["disabled", "onClick"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_el_input, {
                "model-value": minCount,
                type: "number",
                "onUpdate:modelValue": ($event: any) => (onMinCountChange(minCount, $event))
              }, null, 8, ["model-value", "onUpdate:modelValue"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(TarifPriceInput, {
                modelValue: _unref(props).modelValue.prices[minCount],
                "onUpdate:modelValue": ($event: any) => ((_unref(props).modelValue.prices[minCount]) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_el_button, {
      class: "d-block w-100",
      plain: "",
      type: "primary",
      onClick: addStep
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Добавить шаг ")
      ])),
      _: 1
    }),
    _createVNode(TarifPriceCurrencySelector, {
      modelValue: _unref(props).modelValue.currencyId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(props).modelValue.currencyId) = $event)),
      class: "mt-2"
    }, null, 8, ["modelValue"])
  ]))
}
}

})