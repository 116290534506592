import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    OnlineChatClientRedirectActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "OnlineChatRedirectBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatClientRedirectActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatClientRedirectActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    url: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, {
      rules: _unref(requiredRule),
      label: "URL"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).url,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).url) = $event)),
          placeholder: "Например: http://mysite.com"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["rules"])
  ]))
}
}

})