import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {computed} from "vue";
import {Icon} from "~/cabinet/vue/interface/icon/IconInterface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "OperatorApplicationPlatformIcon"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    platform: {},
    withColor: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let icon = computed<Icon>(() => ({
    fontAwesomeIcon: props.platform.icon,
    color: props.withColor ? props.platform.iconColor : undefined,
    imgIcon: props.platform.iconImg
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FontAwesomeIcon, { icon: _unref(icon) }, null, 8, ["icon"]))
}
}

})