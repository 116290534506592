import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

import {ref} from 'vue';
import MyRemoteData from "~/cabinet/vue/interface/data/MyRemoteData.vue";
import {SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import MyRemoteDataInterface from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";


const __default__ = {
    name: "RejectedSourceWarning"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    uslugaId: {}
  },
  setup(__props: any) {

let props = __props;

let data = ref<SenderSourceInterface[]>();
let remote = ref<MyRemoteDataInterface>();

function reload() {
    remote.value.reload();
}

async function removeSource(cc: number) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить это имя отправителя?");
        let result = await LcabApiRequest.save({
            url: `/api/sender/source/${props.uslugaId}/removeSource/${cc}`
        });
        if (result.isSuccess) {
            reload();
        }
    } catch (e) {

    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_col, null, {
    default: _withCtx(() => [
      _createVNode(MyRemoteData, {
        "data-url": `/api/sender/${_ctx.uslugaId}/getRejectedSourceList`,
        silent: "",
        items: _unref(data),
        "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => (_isRef(data) ? (data).value = $event : data = $event)),
        ref_key: "remote",
        ref: remote
      }, {
        default: _withCtx(({items}) => [
          _createVNode(_component_el_card, null, {
            default: _withCtx(() => [
              _createVNode(ElCardHeader, null, {
                title: _withCtx(() => [
                  _createVNode(FontAwesomeIcon, {
                    color: "red",
                    icon: "exclamation-triangle",
                    class: "mr-1"
                  }),
                  _cache[1] || (_cache[1] = _createTextVNode(" Отклоненные имена отправителя "))
                ]),
                _: 1
              }),
              _createVNode(ElCardSection, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: item.cc
                    }, [
                      _createVNode(MyIconButton, {
                        delete: "",
                        onClick: ($event: any) => (removeSource(item.cc))
                      }, null, 8, ["onClick"]),
                      _createTextVNode(" " + _toDisplayString(item.descr) + " ", 1),
                      (item.status)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(" - " + _toDisplayString(item.status.descr) + ". ", 1),
                            (item.status.comment)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(item.status.comment), 1)
                                ], 64))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["data-url", "items"])
    ]),
    _: 1
  }, 512)), [
    [_vShow, _unref(data) && _unref(data).length > 0]
  ])
}
}

})