import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";


const __default__ = {
    name: "DealerPage"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    address: {}
  },
  setup(__props: any) {

let props = __props;

let request = computed(() => {
    return new ReactiveRequest<{
        descr: string,
        content: string
    }>(new LcabApiRequest({
        url: "/api/pages/getPage",
        p: {
            address: props.address
        }
    }));
})

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(ElCardHeader, {
                title: _unref(request).data.descr
              }, null, 8, ["title"]),
              _createVNode(ElCardSection, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: _unref(request).data.content
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})