import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview" }
const _hoisted_2 = { class: "label" }


const __default__ = {
    name: "ActionBlockPreviewItem"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    label: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _renderSlot(_ctx.$slots, "label")
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})