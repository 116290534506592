import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ITextFloatImageBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/ITextFloatImageBlockValues";
import TextFloatImageDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/TextFloatImageDisplay.vue";
import TextFloatImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/TextFloatImageForm.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class TextFloatImageBlockType extends AbstractBlockType<ITextFloatImageBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(TextFloatImageDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(TextFloatImageForm);
    }

    get isAllowedInStructure(): boolean {
        return false;
    }
}
