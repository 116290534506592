import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }


const __default__ = {
    name: "BigIconFlexButtonWrapper"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    margin: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({'text-center d-flex flex-wrap flex-fill h-100': true, 'm-n2': _ctx.margin })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})