import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {computed} from 'vue';


const __default__ = {
    name: "ElCardSection"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    padding: { type: Boolean, default: true },
    noBorder: { type: Boolean },
    noBackground: { type: Boolean },
    lightBackground: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let className = computed(() => {
    return [
        'el-card-section',
        props.padding ? '' : 'el-card-section-without-padding',
        props.noBorder ? 'el-card-section-without-border' : "",
        props.lightBackground ? 'el-card-section-light-background' : '',
        props.noBackground ? 'el-card-section-no-background' : ''
    ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(className))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})