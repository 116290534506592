import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_ONLINE} from "~/cabinet/ts/Constant";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const Online: Usluga = Usluga.new({
    id: USLUGA_ID_ONLINE,
    badgeId: "onlineUslugaSection",
    dealer: {
        config: {
            tarifComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "dealer-online" */ '~/cabinet/vue/dealer/config/uslugi/tarif/online/OnlineTarifList.vue'))
        }
    },
    client: {
        tarifComponent: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/tarif/OnlineTarifs.vue'))
    }
});

export default Online;

export const ONLINE_LICENSE_TYPE_PACK = "pack";
export const ONLINE_LICENSE_TYPE_PERIOD = "period";