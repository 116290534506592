import Dictionary from "~/ts/library/Dictionary";
import {IOperator, IOperatorGroup} from "~/chat/ts/data/IOperator";
import {AutoActionFilterType, IActionBlock} from "~/chat/ts/data/AutoActions";
import {IWidgetTabParams, TAB_TYPE_CHAT, TAB_TYPE_REVIEWS} from "~/chat/ts/data/ITab";
import {IField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {AutoActionPreparedFilter} from "~/chat/ts/service/autoActions/Filters";
import {BackgroundColor} from "~/core-ui/vue/ui/gradient-picker/Interface";

export const CONTACTS_AFTER_START = "afterStart";
export const CONTACTS_BEFORE_START = "beforeStart";
export const COLLECT_CONTACTS_DISABLED = "none";


export const TRIGGER_MENU_TYPE_ROUND = "round";
export const TRIGGER_MENU_TYPE_DROPDOWN = "dropdown";
export const TRIGGER_MENU_TYPE_MOBILE = "mobile";

export const INITIAL_WIDGET_POSITION_NEAR_TRIGGER = "nearTrigger";
export const INITIAL_WIDGET_POSITION_ON_TRIGGER = "onTrigger";
export const INITIAL_WIDGET_POSITION_AT_WINDOW_CENTER = "windowCenter";


export const WIDGET_MIN_WIDTH = 275;
export const WIDGET_MIN_HEIGHT = 350;

export const PM_FORM_ACTION_AFTER_SEND_GO_TO_CHAT = "goToChat";
export const PM_FORM_ACTION_AFTER_SEND_SHOW_MESSAGE = "showMessage";

export type RateDisplayTypeId = "inChatHistory" | "afterHeader";
export const RATE_DISPLAY_TYPE_IN_CHAT_HISTORY = "inChatHistory";
export const RATE_DISPLAY_TYPE_AFTER_HEADER = "afterHeader";

export default interface ISiteParams {
    id: string;
    domain: string;
    orgID: string;
    operators: Dictionary<IOperator>;
    operatorsGroups: Dictionary<IOperatorGroup>;
    defaultOperatorId?: string;
    tabs: IWidgetTabParams[];
    paymentSystemTypes: Dictionary<string>;
    paymentSystems: Dictionary<IPaymentSystem>;
    searchEngines: Dictionary<ISearchEngine>;
    free: boolean;
    isStartListenOnOpenWidget: boolean;
    filterTypes: Dictionary<AutoActionFilterType>;
    icons: SvgIconsDictionary;
    integrations: {
        ga?: {
            store_id: string
        },
        roistat?: 1 | 0
    };

    //codeInserted: number;


    //z: string;


    //host: string;


    params: {
        userLanguageOverrideStrings?: Dictionary<string>,
        bot: {
            name: string,
            avatar?: string,
            isAvatarEnabled: boolean,
            subDescr: string
        },
        actionBlocks: IActionBlock[],
        //clientDataKeys: Dictionary<IClientDataKey>,
        copyright: {
            img: {
                dark: string,
                light: string
            },
            url: string,
            show: boolean
        },
        designParams: ISiteDesignParams,
        lang: string,//ru,
        leadGen: {
            startDialogFormConfig: StartDialogFormConfigInterface,
            usePhoneNumbersMask: boolean,
            mode: "pmForm" | "simulation" | "hide" | "html",
            pmForm: {
                text?: string,//"<p>Оставьте свой вопрос в этой форме и мы обязательно ответим!</p>",
                questionTextareaPlaceholder: string,//"Введите Ваш вопрос",
                buttonUnderChatHistory?: string,
                upload: boolean,
                textAfterSend: string,
                actionAfterSend?: "goToChat" | "showMessage",
                isAlwaysShowHistoryFirst: boolean
            },
            keepOnline: {
                isMessageInWidgetHistoryAfterSendEnabled: boolean
            },
            questionCategories: {
                list: string[],
                ask: boolean,
                save: boolean,
                textBeforeForm?: string
            },
            tabTitle: string,//"Задать вопрос"
            yourOfflineHTML?: string,
            replaceTabId?: string,
            selectOperatorGroup: boolean,
            sendOnlyToVisibleOperatorGroups: boolean,
            attachClientToGroupUntilHaveNoAnswerFinished: boolean
        },
        counters: {
            facebookPixel: {
                enabled: boolean
            },
            yandexMetrika: {
                enabled: boolean
            },
            googleAnalytics: {
                enabled: boolean
            }
        },
        newMessageNotification: {
            autoCloseNotify: number,
            desktop: NewMesageNotificationDeviceParams,
            maxLength: number
            mobile: NewMesageNotificationDeviceParams
            //pulsateTitle: "1"
            sound: string,//"newMessage"
            volume: number
        },
        api: {
            disabled: boolean;
            blackListedMethods: string[]
        },
        allowedFileExtensions?: string[],
        online: {
            startDialogFormConfig: StartDialogFormConfigInterface,
            showStartButton: boolean,
            startButtonText: string,
            startButtonMessageText: string
        },
        operators: {
            allowChange: boolean,
            balance: {
                smart: {
                    descr: string,//"Консультанты Онлайн",
                    subDescr?: string,
                    avatar: string,//"/uploads/avatars/593adecd804fc4e32e7e865d659f2356/faf0daea97f35ee0134afea76961526a.png"
                    isBase64: boolean
                }
                type: "smart" | "random" | "all",
                isOperatorSelectAvailable: boolean
            },
            permanentAttachClientToOperators: boolean,
            typingNotifications: boolean
        },
        //paymentSystem: Dictionary<any>, //дубль, есть вышеб
        rate: {
            listInWidget: {
                displayName: boolean,
                displayCity: boolean,
                displayDate: boolean,
                displayRateValue: boolean
            },
            loadInTab: 1 | 0,
            collectContacts: CollectContactFields,//{phone: 1, email: 1}
            needComment: 1,
            hideCommentField: boolean,
            values: 2 | 5,
            upload: true,
            adminCommentTitle?: string,
            placeholder: string,
            requestRate: {
                enabled: boolean
                afterMessagesCount: number,
                messagesCountType: "operator" | "client",
                displayType: RateDisplayTypeId
            },
            leaveRateButtonText: string,
            isDefaultValueDisallowed: boolean
        },
        siteChatOptions: {
            upload: boolean,
            widgetMenu: {
                list: IMenuItem[]
            },
            widgetMenuInChat: {
                enabled: boolean,
                title?: string
            },
            cobrowse: {
                enabled: boolean,
                controlEnabled: boolean,
                needConfirmation: boolean,
                confirmationText: string
            },
            canViewInvisibleOperatorTTL: number,//10800
            emoji: 0 | 1,
            exportByClientAllowed: 0 | 1,
            forgetHistory: number,
            hideOperatorTyping: 0 | 1,
            showTimeForAllMessages: boolean,
            loadLimits: {
                type: "none" | "exclude" | "some",
                url: string[]
            };
            isFillMessengerLinkContext: boolean;
            removeSimilarMessages: 1 | 0
            eula: {
                isEnabled: boolean,
                enabledByDefault: boolean,
                type: "checkbox" | "headerInfo",
                buttonTooltip?: string,
                checkboxText: string
            },
            timeout: {
                desktop?: number,
                mobile?: number
            },
            mobileBackHandler: boolean,
            desktopSyncOpenState: boolean
        },
        trigger: {
            desktop: ITriggerParams,
            //dontHideOnMobileTouch: 1 | 0,
            menu: ITriggerMenu,
            mobile: ITriggerParams,
            tablet: ITriggerParams,
        },
        media?: {
            voiceMessages: {
                enabled: boolean
            }
        },
        stickerPacks?: WidgetStickerPackInterface[];
    };

    formElements: Dictionary<IOnlineClientField>;
    //targetParams: Dictionary<string[]>;
}

export interface IOnlineClientField extends IField, IOnlineClientFieldMixin {
}

export interface IOnlineClientFieldParams {
    sendChangedValueToClient: boolean,
    alwaysVisibleInApplication: boolean,
    visibleInOperatorApplication: boolean,
    contactbookColumnId: string,
    contactbookTwoWaySync: boolean,
    fixedContactbookColumnId: string,
    autoFill: IOnlineClientFieldAutoFill[];
    autoLinks: IOnlineClientFieldAutoLink[];
    isInClientDisplayDescrFields: boolean;
    copyValueForMessengerClientFromSiteClient?: boolean
}

export interface IOnlineClientFieldAutoLink {
    url: string,
    descr: string
}


export interface IOnlineClientFieldMixin {
    params: IOnlineClientFieldParams
}

export interface WidgetStickerPackInterface {
    id: string;
    descr: string;
    stickers: WidgetStickerInterface[];
}

export interface WidgetStickerInterface {
    id: string;
    url: string;
    emoji: string;
}


export interface ISiteDesignParams {
    css?: string,
    zIndex: number,
    letterSpacing: number,
    widget: {
        minWidth: number,
        minHeight: number,
        header: {
            paddingTopBottom: number
        }
    },
    draggable: boolean,
    resizable: boolean,
    initialWidgetPosition: "nearTrigger" | "onTrigger" | "windowCenter",
    //advanced: string
    //availableFor: string, //"classic flat blocks"
    badgeBackground: string,
    badgeColor: string,
    buttonSaveBg: string,
    buttonSaveColor: string,
    clientMessageBackground: string,
    clientMessageColor: string,
    fontSize: string,//14px
    displayCopyright: boolean
    //font: string,//"Arial,Helvetica,sans-serif"
    fontFamily: string, //'Montserrat', sans-serif
    //id: number,
    linkColor: string,//"#3cb8b4"
    //linkHoverColor: string,//"#3cb8b4"
    operatorMessageBackground: string,//"#2ecc71"
    operatorMessageColor: string,//"#ffffff"
    botMessageBackground: string,//"#2ecc71"
    botMessageColor: string,//"#ffffff"
    triggerBackground: BackgroundColor,//"#800d7b"
    triggerColor: string,//"#ffffff"
    trigger: {
        popup: {
            borderColor: string,
            background: string,
            textColor: string,
            close: {
                color: string,
                background: string
            },
            hoverMenuItem: {
                background: string,
                color: string
            }
        }
    },
    vkLikeNotification: {
        background: string,
        textColor: string,
        desktop: {
            side1: {
                side: "left" | "right",
                offset: number
            },
            side2: {
                side: "top" | "bottom",
                offset: number
            }
        },
    },
    //triggerOffset: number,//-999999
    //triggerTextShadow: string,//"#061d3c"
    border: {
        color: string,
        enabled: boolean,
        width: number
    },
    boxShadow: {
        enabled: boolean,
        width: number,
        color: string
    },
    headerBackground: BackgroundColor,
    headerColor: string,
    headerTiles: string,
    operatorAvatarBorder: boolean,
    displayOperatorGroupDescr: boolean,
    borderRadius: number,
    messageBorderRadius: number,
    content: {
        border: {
            top: IContentTopBottomEdge,
            bottom: IContentEdge,
            left: IContentEdge,
            right: IContentEdge
        },
        margin: number
    },
    input: {
        borderColor: string,
        borderColorHover: string,
        placeholderColor: string,
        backgroundColor: string,
        //backgroundColorHover: string
    },
    dialog: {
        history: {
            displayOperatorAvatar: boolean,
            displayOperatorName: boolean,
        },
        border: {
            bottom: IContentTopBottomEdge
        },
        textarea: {
            background: string,
            textColor: string,
            controlsColor: string,
            sendButtonColor: string | false,
            sendMessageButtonColor: string | false,
            alwaysShowSendButton: boolean,
            padding: {
                leftRight: number,
                topBottom: number
            }
            border: {
                color: string,
                width: number,
                radius: number
            },
            margin: {
                top: number,
                bottom: number,
                left: number,
                right: number
            }
        }
    }
}


export interface IContentEdge {
    color: string,
    width: number,
}

export interface IContentTopBottomEdge extends IContentEdge {
    round: boolean
}

export type CollectContactFieldState = 0 | 1 | 2;
export const COLLECT_CONTACT_FIELD_DISABLED = 0;
export const COLLECT_CONTACT_FIELD_ENABLED = 1;
export const COLLECT_CONTACT_FIELD_REQUIRED = 2;

export interface IPaymentSystem {
    descr: string;
    descript: string;
}

export interface ITriggerMenu {
    //displayType: "dropdown" | "round",
    //dropdown: ITriggerMenuTypeOptions,
    //enabled: boolean,
    //round: ITriggerMenuTypeOptions,
    list: IMenuItem[],
    devices: {
        desktop: ITriggerMenuDevice,
        mobile: ITriggerMenuDevice,
        tablet: ITriggerMenuDevice
    }
}

export type ITriggerMenuTypeId = "dropdown" | "round" | "mobile" | false;

export interface ITriggerMenuDevice {
    displayType: ITriggerMenuTypeId,
    [TRIGGER_MENU_TYPE_DROPDOWN]: ITriggerMenuTypeOptions,
    [TRIGGER_MENU_TYPE_ROUND]: ITriggerMenuTypeOptions,
    [TRIGGER_MENU_TYPE_MOBILE]: ITriggerMenuTypeOptions,
    enabled: boolean,
    useTextColorForIcons?: boolean
}

export type CollectContactFields = Dictionary<CollectContactFieldState>;

export const ICON_REGULAR = "regular";
export const ICON_LIGHT = "light";
export const ICON_SOLID = "solid";
export const ICON_IMG = "img";

export type IconStyle = typeof ICON_REGULAR | typeof ICON_LIGHT | typeof ICON_SOLID | typeof ICON_IMG;

export type SvgIcon = {
    width: number,
    height: number,
    content: string
}

export type SvgIconsDictionary = Dictionary<{
    [ICON_REGULAR]?: SvgIcon,
    [ICON_SOLID]?: SvgIcon,
    [ICON_LIGHT]?: SvgIcon,
}>;

export interface ISearchEngine {
    host: string,
    key: string
}

export const WIDGET_STYLE_BLOCKS = "blocks";
export const WIDGET_STYLE_FLAT = "flat";
export const WIDGET_STYLE_CLASSIC = "classic";

export const WIDGET_ONLINE_MODE = "online";
export const WIDGET_ONLINE_BOT_MODE = "onlineBot";
export const WIDGET_OFFLINE_MODE_FORM = "pmForm";
export const WIDGET_OFFLINE_MODE_KEEP_ONLINE = "keepOnline";
export const WIDGET_OFFLINE_MODE_BOT = "bot";
export const WIDGET_OFFLINE_MODE_SIMULATION = "simulation";
export const WIDGET_OFFLINE_MODE_HIDE = "hide";
export const WIDGET_OFFLINE_MODE_HTML = "html";
export const WIDGET_OFFLINE_MODE_REPLACE_TAB = "replaceTab";

/*
interface ISimulationParams {
    afterContacts: string,//"Спасибо Вам! Я постараюсь связаться с Вами как можно скорее."
    alreadyHaveContacts: string,//"Извините, сейчас я не могу Вам ответить. У нас уже есть Ваши контактные данные - [email] [phone]↵Мы обязательно свяжемся с Вами!"
    askContacts: string,//"Извините, сейчас я не могу Вам ответить. Можете оставить мне свой номер телефона или Email, я свяжусь с Вами чуть позже!"
    checkContacts: 1 | 0,
    delay: number,
    typingTime: number,
    waitForTyping: number,
}
 */

export const MESSAGE_NOTIFICATION_BUBBLE = "bubble";
export const MESSAGE_NOTIFICATION_VK = "notification";
export const MESSAGE_NOTIFICATION_OPEN = "open";
export const MESSAGE_NOTIFICATION_NONE = "none";

export type NewMesageNotificationAction = "bubble" | "notification" | "open" | "none";

export interface NewMesageNotificationDeviceParams {
    action: NewMesageNotificationAction,
    showMessageButtons: boolean
}


export const ONLINE_CONTACTS_MODE_AFTER = "afterStart";
export const ONLINE_CONTACTS_MODE_BEFORE = "beforeStart";
export const ONLINE_CONTACTS_MODE_NONE = "none";

export const OPERATOR_BALANCE_SMART = "smart";
export const OPERATOR_BALANCE_RANDOM = "random";
export const OPERATOR_BALANCE_ALL = "all";

export interface IOperatorBalanceType {
    /** @deprecated похоже пора увольнять? Везде true */
    canUseCommonOperatorParams: boolean,
    selectRandomOperator: boolean,
    canUseDefaultOperator: boolean,
    canSendTypingNotification: boolean,
    dontSelectOperatorOnFirstMessage: boolean,
    canForwardToNextOperator: boolean
}

export const OperatorBalanceTypes: Dictionary<IOperatorBalanceType> = {
    [OPERATOR_BALANCE_RANDOM]: {
        selectRandomOperator: true,
        canUseDefaultOperator: true,
        canSendTypingNotification: true,
        canUseCommonOperatorParams: true,
        dontSelectOperatorOnFirstMessage: false,
        canForwardToNextOperator: true
    },
    [OPERATOR_BALANCE_SMART]: {
        canUseCommonOperatorParams: true,
        selectRandomOperator: false,
        canUseDefaultOperator: false,
        canSendTypingNotification: false,
        dontSelectOperatorOnFirstMessage: true,
        canForwardToNextOperator: true
    },
    [OPERATOR_BALANCE_ALL]: {
        canUseCommonOperatorParams: true,
        dontSelectOperatorOnFirstMessage: true,
        selectRandomOperator: false,
        canUseDefaultOperator: false,
        canSendTypingNotification: false,
        canForwardToNextOperator: false
    }
};

export const RATE_SYSTEM_2 = 2;
export const RATE_SYSTEM_5 = 5;
export const RATE_VALUE_GOOD = "good";
export const RATE_VALUE_BAD = "bad";


export const WIDGET_LOAD_LIMIT_NONE = "none";
export const WIDGET_LOAD_LIMIT_EXCLUDE = "exclude";
export const WIDGET_LOAD_LIMIT_SOME = "some";

export const TRIGGER_MENU_SHOW_MOUSEOVER = "mouseover";
export const TRIGGER_MENU_SHOW_CLICK = "click";
export const TRIGGER_MENU_HIDE_MOUSEOUT = "mouseout";
export const TRIGGER_MENU_HIDE_CLICK = "click";


export interface ITriggerMenuTypeOptions {
    show: "mouseover" | "click",
    hide: "mouseout" | "click"
}


/*
interface IRateDisplayConfig {
    background: string,
    color: string
}

 */

export interface ITriggerIcon {
    name: string,//comments
    class: string,//regular
}

export const TRIGGER_SIDE_TOP = "top";
export const TRIGGER_SIDE_BOTTOM = "bottom";
export const TRIGGER_SIDE_LEFT = "left";
export const TRIGGER_SIDE_RIGHT = "right";
export const TRIGGER_SIDE_CENTER = "center";

export const TRIGGER_TYPE_ROUND = "round";
export const TRIGGER_TYPE_CLASSIC = "classic";
export const TRIGGER_TYPE_IMAGE = "image";
export const TRIGGER_TYPE_HIDDEN = "hidden";
export const TRIGGER_TYPE_DISABLE = "disable";

export const TRIGGER_MENU_ITEM_TYPE_CHAT = TAB_TYPE_CHAT;
export const TRIGGER_MENU_ITEM_TYPE_TAB = "tab";
export const TRIGGER_MENU_ITEM_TYPE_REVIEWS = TAB_TYPE_REVIEWS;
export const TRIGGER_MENU_ITEM_TYPE_VKONTAKTE = "vkontakte";
export const TRIGGER_MENU_ITEM_TYPE_FACEBOOK = "facebook";
export const TRIGGER_MENU_ITEM_TYPE_TELEGRAM = "telegram";
export const TRIGGER_MENU_ITEM_TYPE_VIBER = "viber";
export const TRIGGER_MENU_ITEM_TYPE_WHATSAPP = "whatsapp";
export const TRIGGER_MENU_ITEM_TYPE_INSTAGRAM = "instagram";
export const TRIGGER_MENU_ITEM_TYPE_ODNOKLASSNIKI = "ok";
export const TRIGGER_MENU_ITEM_TYPE_URL = "url";
export const TRIGGER_MENU_ITEM_TYPE_JAVASCRIPT = "javascript";
export const TRIGGER_MENU_ITEM_TYPE_CHAT_BOT = "chatBot";

export type ITriggerSide = "bottom" | "left" | "right" | "top" | "center";

export interface ITriggerOffset {
    side: ITriggerSide,
    value: number
}

export interface IMenuItem {
    color1: string,//"#bc1aa6"
    color2: string,//"#ffffff"
    descr: string,//"Интересные статьи"
    subDescr?: string,
    appearanceFromTab: boolean,
    iconName: string,//"bomb"
    iconStyle: IconStyle,
    id: string,//"5q3d1rzof2g75p4"
    params: Dictionary<any>,//{link: "http://verbox.ru/knowledge_base"}
    type: "url" | "javascript" | "chat" | "tab" | "reviews" | "vkontakte" | "facebook" | "telegram" | "viber" | "whatsapp" | "instagram" | "ok" | "chatBot",
    descrRelativeToMode?: {
        online: string,
        offline: string
    },
    countersAction?: string,
    onClick?: string,
    /** @deprecated */
    devices?: Dictionary<0 | 1>,
    filters: any
}


export const TRIGGER_OFFSET_SIDE_PRIMARY = "primary";
export const TRIGGER_OFFSET_SIDE_SECONDARY = "secondary";

export interface ITriggerParams {
    //borderType: "top"
    borderWidth: number,//"4"
    disallowAvatarOnRoundTrigger: number,//"1"
    roundTriggerAnimation: string,//"1"
    font: string,//"Arial, Helvetica, sans-serif"
    fontSize: number,//"16"
    hideOnScrollEnabled: 0 | 1,
    icon: {
        offline: ITriggerIcon,
        online: ITriggerIcon
    }
    offlineImage: string,//"/uploads/tinymce/593adecd804fc4e32e7e865d659f2356/yH.gif"
    offlineImageHeight: number//"194"
    offlineImageWidth: number//"200"
    offlineText: string,//"Задать вопрос"
    offset: {
        [TRIGGER_OFFSET_SIDE_PRIMARY]: ITriggerOffset,
        [TRIGGER_OFFSET_SIDE_SECONDARY]: ITriggerOffset
    }
    onlineImage: string,//"/uploads/tinymce/593adecd804fc4e32e7e865d659f2356/yH.gif"
    onlineImageHeight: number,//"194"
    onlineImageWidth: number,//"200"
    onlineText: string,//"Задать вопрос"
    radius: number,//"4"
    roundTextEnabled: 1 | 0,
    isIconHidden: boolean,
    roundTriggerSize: number,//70
    triggerType: "round" | "classic" | "image" | "hidden" | "disable"
}


export const FIELD_AUTO_FILL_SOURCE_TYPE_INPUT = "input";
export const FIELD_AUTO_FILL_SOURCE_TYPE_CLICK = "click";
export const FIELD_AUTO_FILL_SOURCE_TYPE_ELEMENT = "element";
export type FIELD_AUTO_FILL_SOURCE_TYPE = "input" | "click" | "element";

export interface IOnlineClientAutoFillInputParams {
    selector: string;
}

export interface IOnlineClientAutoFillElementParams {
    selector: string;
    from: OnlineClientAutoFillElementParamsFrom;
    attributeName?: string;
    fixedText: string
    clearIfElementNotExist?: boolean
}

export type OnlineClientAutoFillElementParamsFrom = "textContent" | "attribute" | "fixedText";

export const FIELD_AUTO_FILL_FROM_TEXT_CONTENT = "textContent";
export const FIELD_AUTO_FILL_FROM_ATTRIBUTE = "attribute";
export const FIELD_AUTO_FILL_FROM_FIXED_TEXT = "fixedText";

export interface IOnlineClientAutoFillClickParams extends IOnlineClientAutoFillElementParams {

}

export interface IOnlineClientAutoFillInputParams {
    selector: string
}


export interface IOnlineClientFieldAutoFill {
    filters: any;
    preparedFilters?: AutoActionPreparedFilter[]
    source: {
        type: FIELD_AUTO_FILL_SOURCE_TYPE,
        params: IOnlineClientAutoFillInputParams | IOnlineClientAutoFillClickParams | IOnlineClientAutoFillElementParams
    }
}

export interface StartDialogFormConfigInterface {
    collectContacts: CollectContactFields,//{phone: 1, email: 1}
    collectContactsDisplayAllSteps: boolean,
    collectContactsBeforeStartMessage: string, //"<p>Ваш вопрос готов к// отправке оператору!</p>↵<p>Для повышения качества нашего обслуживания, просим Вас представиться и оставить контакты для обратной связи:</p>"
    collectContactsMode: "afterStart" | "beforeStart" | "none",
    afterStart: {
        title?: string,
        showAgain: {
            enabled: boolean,
            text: string
        }
    }
}