import {App} from "vue";

import MyFilterValuesItem from "~/cabinet/vue/interface/filter/MyFilterValuesItem.vue";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

export default class InitMyComponents
{
    public static make(app: App) {
        app
            .component("my-filter-values-item", MyFilterValuesItem)
            .component("chart", useRawAsyncComponent(
                () => import(/* webpackChunkName: "highcharts" */ '~/cabinet/vue/interface/charts/Chart.vue')
            ));
    }
}