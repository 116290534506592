import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "float-md-left" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4 mt-md-0"
}

import {__} from "~/ts/library/Translate";
import {IMyDialog} from "~/cabinet/vue/interface/dialog/IMyDialog";
import {FormInstance} from "element-plus";
import {computed, nextTick, watch, ref} from "vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "MyDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    title: { default: __("Редактирование") },
    buttonText: { default: __('Продолжить') },
    form: {},
    showStandartButtons: { type: Boolean, default: true },
    dialog: {},
    applyDisabled: { type: Boolean },
    cancelButtonText: { default: __("Отмена") },
    mobileFullWidthButton: { type: Boolean },
    closeOnPressEscape: { type: Boolean },
    labelPosition: {},
    labelWidth: {},
    width: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["apply", "cancel"], ["update:visible"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let isVisible = _useModel<boolean>(__props, "visible");
function close() {
    isVisible.value = false;
}
let formComponent = ref<FormInstance>();

watch(isVisible, () => {
    nextTick(() => formComponent.value?.clearValidate())
});

let formProps = computed(() => {
    let result = typeof props.form == "object" ? props.form : {};
    if (props.labelPosition) {
        result.labelPosition = props.labelPosition;
    }
    if (props.labelWidth) {
        result.labelWidth = props.labelWidth;
    }
    return ObjectHelper.hasKeys(result) ? result : null;
});

async function apply() {
    try {
        if (isValidateNeeded.value) {
            if (formComponent.value) {
                if (!(await formComponent.value.validate())) {
                    return;
                }
            }
        }
        emits("apply");
    } catch (e) {

    }
}

function resetValidate() {
    formComponent.value?.clearValidate();
}

function validate() {
    formComponent.value?.validate();
}

let isValidateNeeded = computed(() => {
    return typeof props.form == "object" && props.form.model != null;
});


function cancel() {
    close();
    emits('cancel');
}

__expose<IMyDialog>({
    validate,
    resetValidate
});

return (_ctx: any,_cache: any) => {
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    title: _ctx.title,
    modelValue: _unref(isVisible),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
    "close-on-press-escape": _ctx.closeOnPressEscape
  }, {
            width: _ctx.width,
            ...(_ctx.dialog ? _ctx.dialog : {}),
        }), {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "footer")
      ]),
      (_ctx.showStandartButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_button, {
              onClick: cancel,
              class: _normalizeClass(_ctx.mobileFullWidthButton ? _unref(mobileFullWidthButtonClass) : null)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.buttonText)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  disabled: _ctx.applyDisabled,
                  type: "primary",
                  class: _normalizeClass(_ctx.mobileFullWidthButton ? _unref(mobileFullWidthButtonClass) : null),
                  onClick: apply
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "class"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_unref(formProps))
        ? (_openBlock(), _createBlock(_component_el_form, _mergeProps({
            key: 0,
            ref_key: "formComponent",
            ref: formComponent
          }, _unref(formProps)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 16))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 16, ["title", "modelValue", "close-on-press-escape"]))
}
}

})