import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Align from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Align.vue";
import Href from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Href.vue";
import IH1BlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/IH1BlockValues";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "H1Form"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockForm(props);

let _modelValue = _useModel<IH1BlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(Href, {
        modelValue: _unref(model).href,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).href) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(Align, {
        modelValue: _unref(model).align,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).align) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})