import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

import {computed, inject, nextTick, ref, watch} from 'vue';
import ResizeHelper from "~/cabinet/vue/interface/draggableResizable/ResizeHelper.vue";
import WindowHelper from "~/ts/library/WindowHelper";
import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";
import {ResizeEvent} from "~/cabinet/vue/interface/draggableResizable/ResizeEvent";
import DomHelper from "~/ts/library/DomHelper";
import Events from "~/ts/library/Events";
import PointerEventContainer from "~/cabinet/vue/interface/draggableResizable/PointerEventContainer.vue";
import Dictionary from "~/ts/library/Dictionary";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";
import {FlowChartScaleValueInjectionKey} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";


type attr = "top" | "left" | "width" | "height";


const __default__ = {
    name: "DraggableResizable"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    left: { default: 0 },
    top: { default: 0 },
    width: { default: 200 },
    height: { default: 200 },
    autoHeight: { type: Boolean },
    minWidth: {},
    minHeight: {},
    maxWidth: {},
    maxHeight: {},
    ratio: {},
    scaleWhenDrag: { type: Boolean, default: true },
    fixed: { type: Boolean },
    dragHandler: {},
    resizable: { type: Boolean, default: true },
    draggable: { type: Boolean, default: true },
    minLeft: { default: 0 },
    minTop: { default: 0 },
    autoSize: { type: Boolean },
    allowOutOfBound: { type: Boolean },
    boundTo: {},
    handlers: { default: () => (['n', 's', 'w', 'e', 'ne', 'nw', 'se', 'sw']) }
  },
  emits: ["click", "mouseenter", "mouseleave", "update:top", "update:left", "update:width", "update:height", "drag-manualy", "drag-active-change"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let scale = inject(FlowChartScaleValueInjectionKey, null);
let bottom = ref<number>();
let right = ref<number>();

let dragActive = ref(false);

let windowResizing = ref(false);

let boundToSize = computed<{ height: number, width: number }>(() => {
    if (props.boundTo) {
        return {
            width: props.boundTo.offsetWidth,
            height: props.boundTo.offsetHeight
        }
    } else {
        return WindowHelper.getSize();
    }
});

let stickToBottom = computed(() => {
    return props.top + props.height >= boundToSize.value.height;
});

let stickToTop = computed(() => {
    return bottom.value ? boundToSize.value.height - props.height - bottom.value <= 0 : props.top == 0;
});

let resizeHandlers = computed(() => {
    let result: any[] = [];
    for (let handler of props.handlers) {
        let item: Dictionary<boolean> = {};
        for (let i = 0; i < handler.length; i++) {
            item[handler[i]] = true;
        }
        result.push(item);
    }
    return result;
});

let isResizeDisabled = computed(() => {
    return dragActive.value || windowResizing.value;
});

let style = computed(() => {
    let draggable = props.draggable;
    let result: {
        top: string,
        bottom: string,
        left: string,
        right: string,
        width?: string,
        height?: string
    } = {
        top: draggable ? (bottom.value != null ? null : `${computeTop()}px !important`) : null,
        bottom: draggable ? (bottom.value != null ? `${bottom.value}px !important` : null) : null,
        left: draggable ? (right.value != null ? null : `${computeLeft()}px !important`) : null,
        right: draggable ? (right.value != null ? `${right.value}px !important` : null) : null
    };
    if (!props.autoSize) {
        result.width = `${computeWidth()}px !important`;

        if (!props.autoHeight) {
            result.height = `${computeHeight()}px !important`;
        }
    }
    return result;
});

let maxLeft = computed(() => {
    if (props.allowOutOfBound) {
        return Number.MAX_SAFE_INTEGER;
    }
    let delta = boundToSize.value.width - props.width;
    return delta > 0 ? delta : 0;
});

let maxTop = computed(() => {
    if (props.allowOutOfBound) {
        return Number.MAX_SAFE_INTEGER;
    }
    let delta = boundToSize.value.height - props.height;
    return (delta > 0 ? delta : 0);
});


let maxWidthComputed = computed(() => {
    let result = !isResizeDisabled.value ? boundToSize.value.width - (isResizeDisabled.value ? (right.value != null ? right.value : props.left) : 0) : props.maxWidth;
    if (props.maxWidth && props.maxWidth < result) {
        result = props.maxWidth;
    }
    return result;
});

let minWidthComputed = computed(() => {
    let result = props.minWidth;
    return maxWidthComputed.value < result ? maxWidthComputed.value : result;
});

let maxHeightComputed = computed(() => {
    let result = !isResizeDisabled.value ? boundToSize.value.height - (isResizeDisabled.value ? (bottom.value != null ? bottom.value : props.top) : 0) : props.maxHeight;
    if (props.maxHeight && props.maxHeight < result) {
        result = props.maxHeight;
    }
    return result;
});

let minHeightComputed = computed(() => {
    let result = props.minHeight;
    return maxHeightComputed.value < result ? maxHeightComputed.value : result;
});


function computeLeft(left?: number) {
    return getValueByMinMax(left == null ? props.left : left, props.minLeft, maxLeft.value);
}

function computeTop(top?: number) {
    return getValueByMinMax(top == null ? props.top : top, props.minTop, maxTop.value);
}

function computeWidth(width?: number) {
    return getValueByMinMax(width == null ? props.width : width, minWidthComputed.value, maxWidthComputed.value);
}

function computeHeight(height?: number) {
    return getValueByMinMax(height == null ? props.height : height, minHeightComputed.value, maxHeightComputed.value);
}

function getValueByMinMax(value: number, min?: number, max?: number): number {
    if (min != null && value < min) {
        value = min;
    } else if (max != null && value > max) {
        value = max;
    }
    return value;
}

let values = computed<Dictionary<number>>(() => ({
    left: props.left,
    top: props.top,
    width: props.width,
    height: props.height
}));

function setValue(attribute: attr, value: number, emit: boolean = true) {
    let newValue: number;
    if (attribute == "left") {
        newValue = computeLeft(value);
    } else if (attribute == "top") {
        newValue = computeTop(value);
    } else if (attribute == "width") {
        newValue = computeWidth(value);
    } else if (attribute == "height") {
        newValue = computeHeight(value);
    }
    if (newValue != values.value[attribute] && emit) {
        emits(`update:${attribute}` as 'update:top', newValue);
    }

    return newValue;
}

function setValueByDelta(attribute: attr, delta: number, emit: boolean = true) {
    return setValue(attribute, values.value[attribute] + delta, emit);
}

function drag(deltaX: number, deltaY: number) {
    let scaleValue = scale?.value ?? 1;
    setValueByDelta("left", deltaX / scaleValue);
    setValueByDelta("top", deltaY / scaleValue);
}


function resize(deltaWidth: number, deltaHeight: number) {
    if (props.ratio) {
        if (Math.abs(deltaWidth) > Math.abs(deltaHeight)) {
            let width = setValueByDelta("width", deltaWidth, false);
            let height = setValue("height", width / props.ratio);
            setValue("width", height * props.ratio);
        } else {
            let height = setValueByDelta("height", deltaHeight, false);
            let width = setValue("width", height * props.ratio);
            setValue("height", width / props.ratio);
        }
    } else {
        if (deltaWidth) {
            setValueByDelta("width", deltaWidth);
        }
        if (deltaHeight) {
            setValueByDelta("height", deltaHeight);
        }
    }
}

let x = ref<number>();
let y = ref<number>();

function onDragStart(e: PointerEvent) {
    if (props.draggable) {
        var isHandler = !props.dragHandler;
        var el = e.target as HTMLElement;
        if (el) {
            if (!isHandler) {
                while (el != document.body && el) {
                    if (DomHelper.isMatchedSelector(el, props.dragHandler)) {
                        isHandler = true;
                        break;
                    }
                    el = el.parentElement;
                }
            }
            if (isHandler) {
                x.value = null;
                y.value = null;
                dragActive.value = true;
            }
        }
    }
}

function onDragStop() {
    let windowSize = boundToSize.value;
    if (bottom.value != null) {
        setValue("top", windowSize.height - (bottom.value + props.height));
        bottom.value = null;
    }
    if (right.value != null) {
        setValue("left", windowSize.width - (right.value + props.width));
        right.value = null;
    }

    dragActive.value = false;
}

function onDrag(e: PointerEvent) {
    if (dragActive.value) {
        let newX = e.clientX;
        let newY = e.clientY;
        if (x.value?.toString() != null) {
            drag(newX - x.value, newY - y.value);
        }
        x.value = newX;
        y.value = newY;
        emits("drag-manualy");
    }
}

function onResize(e: ResizeEvent) {
    if (e.n) {
        if (bottom.value == null) {
            bottom.value = boundToSize.value.height - props.height - props.top;
        }
    }
    if (e.w) {
        if (right.value == null) {
            right.value = boundToSize.value.width - props.width - props.left;
        }
    }

    if (e.n) {
        e.deltaY *= -1;
    }
    if (e.w) {
        e.deltaX *= -1;
    }

    if (e.deltaX || e.deltaY) {
        resize(e.deltaX, e.deltaY);
    }
}

watch(computed(() => dragActive.value), () => {
    emits("drag-active-change", dragActive.value);
});

let onSizeChange = computed(() => {
    return props.height || props.width || props.ratio;
});

watch(onSizeChange, () => {
    nextTick(() => {
        resize(0, 0);
    });
});

let delayName = Random.uid();
addDisposableEvent(
    Events.addEventListener("resize", window, async () => {
        windowResizing.value = true;


        try {
            await Delay.make(100, delayName);
            windowResizing.value = false;
            drag(0, 0);
            resize(0, 0);
        } catch (e) {

        }
    })
);

drag(0, 0);
resize(0, 0);

return (_ctx: any,_cache: any) => {
  const _directive_on_native = _resolveDirective("on-native")!

  return _withDirectives((_openBlock(), _createBlock(PointerEventContainer, {
    class: _normalizeClass({
            draggable: _ctx.draggable,
            'drag-active' : !!_unref(dragActive),
            'scale-when-drag': _ctx.scaleWhenDrag,
            'fixed': !!_ctx.fixed,
            'stick-to-bottom': _unref(stickToBottom),
            'stick-to-top': _unref(stickToTop)
        }),
    style: _normalizeStyle(_unref(style)),
    onDragStart: onDragStart,
    onDrag: onDrag,
    onDragStop: onDragStop,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.resizable)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(resizeHandlers), (props, index) => {
            return (_openBlock(), _createBlock(ResizeHelper, _mergeProps({
              key: index,
              ref_for: true
            }, props, { onDrag: onResize }), null, 16))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "style"])), [
    [_directive_on_native, ($event) => _unref(emits)('mouseenter', $event), "mouseenter"],
    [_directive_on_native, ($event) => _unref(emits)('mouseleave', $event), "mouseleave"]
  ])
}
}

})