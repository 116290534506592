import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "link-dashed",
  tabindex: ""
}
const _hoisted_3 = { class: "table valign-top table-striped" }
const _hoisted_4 = { key: 1 }

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";

import {DataProviderEntitySaveActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    DataProviderEntityFieldInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionParamInterface";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed, ref} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import DataProviderEntityField
    from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntityField.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import Dictionary from "~/ts/library/Dictionary";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IFlowChartContentVariable} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";


interface ResponseInterface {
    fields: DataProviderEntityFieldInterface[];
    filters: IFiltersData;
    isUpdateAllowed: boolean;
}

import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntitySaveActionTabPane"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DataProviderEntitySaveActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let model = _useModel<getActionDescriptionModelType<DataProviderEntitySaveActionDescription>>(__props, "modelValue");

let frontendParams = props.actionDescription.frontendParams;

const SEARCH_MODE_LAST_SAVED_IN_THIS_BLOCK = "lastSavedInThisBlock";
const SEARCH_MODE_LAST_SAVED_EVERYWHERE = "lastSavedEverywhere";


let request = computed(() => {
    return new ReactiveRequest<ResponseInterface>(new LcabApiRequest({
        url: `/api/events/dataProvider/getFrontendParamsForSaveEntityAction`,
        p: {
            dataProviderPrimaryId: props.actionDescription.authPrimaryId,
            dataProviderPlatformId: props.actionDescription.platformId,
            entityClassId: props.actionDescription.frontendParams.entityClassId,
            eventDescriptionId: props.eventParamsByAuth.eventId,
            eventPlatformId: props.eventParamsByAuth.platform.id,
            eventAuthId: props.eventParamsByAuth.authId
        }
    }));
});

let fieldsWithDefaultValuesAvailableToAdd = computed(() => {
    return request.value.data?.fields
        .filter(field =>
            (field.form.sections[0]?.elements[0]?.defaultValue || field.isRequired)
            &&
            !model.value.fields.find(item => item.id == field.id)
        );
});

function addDefaultFields() {
    for (let field of fieldsWithDefaultValuesAvailableToAdd.value) {
        model.value.fields.push({
            updateAllowed: field.isUpdateAllowedByDefault,
            id: field.id,
            values: [undefined],
            updateOnlyEmpty: false
        });
    }
}

let contentVariablesForField = computed(() => {
    let platform = props.actionDescription.platformDescr;
    let result: IFlowChartContentVariable[] = [
        {
            id: frontendParams.currentDataProviderEntityFieldValueContentVariable,
            descr: `Текущее значение поля в ${platform}`,
            groupDescr: `Значения из ${platform}`
        },
        ...props.contentVariables,
    ];
    return result;
})

request.value.setCallback(() => {
    if (!model.value.fields.length) {
        addDefaultFields();
    }
});

let fields = computed(() => request.value.data?.fields ?? []);

let fieldsAvailableForAdd = computed<DataProviderEntityFieldInterface[]>(() => {
    return fields.value.filter(field => !model.value.fields.find(item => item.id == field.id));
});

let fieldAvailableForAddByGroupDescr = computed<Dictionary<DataProviderEntityFieldInterface[]>>(() => {
    let result: Dictionary<DataProviderEntityFieldInterface[]> = {};
    for (let field of fieldsAvailableForAdd.value) {
        let key = field.groupDescr ?? "";
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(field);
    }
    return result;
});

let selectedFoundMode = computed(() => {
    return frontendParams.actionModes.found.find(item => item.id == model.value.foundActionMode);
});

function getFieldById(id: string) {
    return request.value.data?.fields.find(item => item.id === id);
}

function addField(field: DataProviderEntityFieldInterface) {
    model.value.fields.push({
        id: field.id,
        values: [],
        updateAllowed: field.isUpdateAllowedByDefault,
        updateOnlyEmpty: false
    });
}


let searchByIdModes: Dictionary<string> = {
    [SEARCH_MODE_LAST_SAVED_EVERYWHERE]: 'в любом блоке',
    [SEARCH_MODE_LAST_SAVED_IN_THIS_BLOCK]: 'в этом блоке'
};


let enabledSearchByIdMode = computed(() => {
    for (let id of ObjectHelper.getKeys(searchByIdModes)) {
        if (model.value.searchModes.includes(id)) {
            return id;
        }
    }
    return null;
});

let searchByIdLastEnabledMode = ref(enabledSearchByIdMode.value ?? SEARCH_MODE_LAST_SAVED_EVERYWHERE);


function setSearchByIdType(newValue?: string) {
    for (let id of ObjectHelper.getKeys(searchByIdModes)) {
        toggleArrayElement(model.value.searchModes, id, false);
    }
    if (newValue) {
        searchByIdLastEnabledMode.value = newValue;
        toggleArrayElement(model.value.searchModes, newValue, true)
    }
}

function onSearchByIdInput(value: boolean) {
    if (!value) {
        setSearchByIdType(null);
    } else {
        setSearchByIdType(searchByIdLastEnabledMode.value)
    }
}

let searchTypes = computed(() => frontendParams.searchModes);
let otherSearchTypes = computed(() => searchTypes.value.filter(item => !searchByIdModes.hasOwnProperty(item.id)));
let searchByIdSearchTypes = computed(() => searchTypes.value.filter(item => searchByIdModes.hasOwnProperty(item.id)));

let isSearchByIdAvailable = computed(() => {
    return searchByIdSearchTypes.value.length > 0;
});


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "el-row-root"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  (_unref(searchTypes).length)
                    ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(ElCardHeader, { title: "Режим сохранения" }),
                          _createVNode(ElCardSection, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_switch, {
                                "model-value": !_unref(model).isAlwaysCreate,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(model).isAlwaysCreate = !$event)),
                                class: "mr-2"
                              }, null, 8, ["model-value"]),
                              _cache[9] || (_cache[9] = _createTextVNode(" Защита от дублей "))
                            ]),
                            _: 1
                          }),
                          (!_unref(model).isAlwaysCreate)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _cache[12] || (_cache[12] = _createElementVNode("p", null, "Как искать ранее созданную сущность", -1)),
                                    (_unref(isSearchByIdAvailable))
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                                          _createVNode(_component_el_switch, {
                                            "model-value": !!_unref(enabledSearchByIdMode),
                                            "onUpdate:modelValue": onSearchByIdInput,
                                            class: "mr-2"
                                          }, null, 8, ["model-value"]),
                                          _cache[10] || (_cache[10] = _createTextVNode(" Искать последнюю сохраненную ")),
                                          (!!_unref(enabledSearchByIdMode))
                                            ? (_openBlock(), _createBlock(_component_el_dropdown, {
                                                key: 0,
                                                trigger: "click",
                                                onCommand: _cache[1] || (_cache[1] = ($event: any) => (setSearchByIdType($event)))
                                              }, {
                                                dropdown: _withCtx(() => [
                                                  _createVNode(_component_el_dropdown_menu, null, {
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchByIdSearchTypes), (searchType) => {
                                                        return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                          key: searchType.id,
                                                          command: searchType.id
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_unref(searchByIdModes)[searchType.id]), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["command"]))
                                                      }), 128))
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                default: _withCtx(() => [
                                                  _createElementVNode("a", _hoisted_2, _toDisplayString(_unref(searchByIdModes)[_unref(searchByIdLastEnabledMode)]), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                _createTextVNode(_toDisplayString(_unref(searchByIdModes)[_unref(searchByIdLastEnabledMode)]), 1)
                                              ], 64)),
                                          _cache[11] || (_cache[11] = _createTextVNode(" сущность "))
                                        ]))
                                      : _createCommentVNode("", true),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(otherSearchTypes), (otherSearchType) => {
                                      return (_openBlock(), _createElementBlock("p", {
                                        key: otherSearchType.id
                                      }, [
                                        _createVNode(_component_el_switch, {
                                          "model-value": _unref(model).searchModes.includes(otherSearchType.id),
                                          "onUpdate:modelValue": ($event: any) => (_unref(toggleArrayElement)(_unref(model).searchModes, otherSearchType.id, $event)),
                                          class: "mr-2"
                                        }, null, 8, ["model-value", "onUpdate:modelValue"]),
                                        _createTextVNode(" " + _toDisplayString(otherSearchType.descr), 1)
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                (_unref(request).data.filters)
                                  ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(HelpBlock, null, {
                                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                                            _createElementVNode("p", null, " Вы можете добавить фильтры, которым должна соответствовать найденная сущность. ", -1)
                                          ])),
                                          _: 1
                                        }),
                                        _createVNode(MyInputList, {
                                          modelValue: _unref(model).updateFilters,
                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).updateFilters) = $event)),
                                          "remove-last-available": "",
                                          sortable: false,
                                          "new-item-value-getter": () => ({}),
                                          "add-button-text": "Добавить фильтры"
                                        }, {
                                          default: _withCtx(({index}) => [
                                            _createVNode(MyFilter, {
                                              modelValue: _unref(model).updateFilters[index],
                                              "onUpdate:modelValue": ($event: any) => ((_unref(model).updateFilters[index]) = $event),
                                              "filters-data": _unref(request).data.filters,
                                              placeholder: "Укажите фильтры, которым должна соответствовать обновляемая сущность"
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "filters-data"])
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(ElCardSection, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, { label: "Если нашли сущность" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_select, {
                                          modelValue: _unref(model).foundActionMode,
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).foundActionMode) = $event)),
                                          class: "w-100"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).actionModes.found, (mode) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: mode.id,
                                                value: mode.id,
                                                label: mode.descr
                                              }, null, 8, ["value", "label"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_el_form_item, { label: "Если не нашли сущность" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_select, {
                                          modelValue: _unref(model).notFoundActionMode,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).notFoundActionMode) = $event)),
                                          class: "w-100"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(frontendParams).actionModes.notFound, (mode) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: mode.id,
                                                value: mode.id,
                                                label: mode.descr
                                              }, null, 8, ["value", "label"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_unref(request).data.fields && _unref(request).data.fields.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(ElCardSectionWithTable, null, {
                            default: _withCtx(() => [
                              _createElementVNode("table", _hoisted_3, [
                                _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                                  _createElementVNode("tr", null, [
                                    _createElementVNode("th", { style: {"width":"13em"} }, "Поле"),
                                    _createElementVNode("th", { style: {"min-width":"12em"} }, "Значение")
                                  ])
                                ], -1)),
                                _createElementVNode("tbody", null, [
                                  (_unref(model).fields.length)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(model).fields, (item, key) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                          (getFieldById(item.id))
                                            ? (_openBlock(), _createBlock(DataProviderEntityField, {
                                                key: item.id,
                                                field: getFieldById(item.id),
                                                model: item,
                                                "content-variables": _unref(contentVariablesForField),
                                                "show-update-allowed": _unref(selectedFoundMode) && _unref(selectedFoundMode).isUpdate,
                                                onRemove: ($event: any) => (_unref(model).fields.splice(key, 1))
                                              }, null, 8, ["field", "model", "content-variables", "show-update-allowed", "onRemove"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 256))
                                    : (_openBlock(), _createElementBlock("tr", _hoisted_4, _cache[14] || (_cache[14] = [
                                        _createElementVNode("td", {
                                          class: "text-center",
                                          colspan: "2"
                                        }, [
                                          _createTextVNode(" Пока что не добавлено ни одного поля. Нажмите на кнопку "),
                                          _createElementVNode("span", { class: "font-weight-bold" }, "Добавить поле")
                                        ], -1)
                                      ])))
                                ])
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(ElCardSection, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown, {
                                onCommand: _cache[7] || (_cache[7] = ($event: any) => (addField($event))),
                                trigger: "click",
                                placement: "top"
                              }, {
                                dropdown: _withCtx(() => [
                                  _createVNode(_component_el_dropdown_menu, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fieldAvailableForAddByGroupDescr), (fields, groupDescr) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                          groupDescr
                                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                key: 0,
                                                disabled: ""
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(groupDescr), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (field) => {
                                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                              key: field.id,
                                              command: field,
                                              onClick: _cache[5] || (_cache[5] = () => {})
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(field.descr), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"]))
                                          }), 128))
                                        ], 64))
                                      }), 256))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    type: "primary",
                                    disabled: !_unref(fieldsAvailableForAdd).length
                                  }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode(" Добавить поле ")
                                    ])),
                                    _: 1
                                  }, 8, ["disabled"]),
                                  (_unref(fieldsWithDefaultValuesAvailableToAdd).length)
                                    ? (_openBlock(), _createBlock(_component_el_button, {
                                        key: 0,
                                        onClick: _cache[6] || (_cache[6] = ($event: any) => (addDefaultFields()))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Добавить предложенные нами значения (" + _toDisplayString(_unref(fieldsWithDefaultValuesAvailableToAdd).length) + ") ", 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(HelpBlock, {
                        key: 2,
                        danger: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Не удалось получить список доступных полей из " + _toDisplayString(_unref(frontendParams).authDescr), 1)
                        ]),
                        _: 1
                      })),
                  (_unref(frontendParams).additionalConfigForm)
                    ? (_openBlock(), _createBlock(_component_el_card, { key: 3 }, {
                        default: _withCtx(() => [
                          _createVNode(ElCardHeader, { title: "Дополнительные настройки" }),
                          _createVNode(ElCardSection, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form, {
                                "label-position": "left",
                                "label-width": "180px"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(MyForm, {
                                    form: _unref(frontendParams).additionalConfigForm,
                                    modelValue: _unref(model).additionalConfigFormValues,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).additionalConfigFormValues) = $event)),
                                    "content-variables": _unref(frontendParams).contentVariableMapperForAdditionalForm
                                  }, null, 8, ["form", "modelValue", "content-variables"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})