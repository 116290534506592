import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-100" }

import Wizard from "~/cabinet/vue/client/wizard/Wizard.vue";
import VueEasyLightbox from "~/core-ui/vue/vue-easy-lightbox/VueEasyLightbox.vue";
import {provide, ref} from "vue";
import {LightboxInjection} from "~/core-ui/ts/LightboxInjection";
import {ElConfigProvider} from 'element-plus'
import ru from 'element-plus/dist/locale/ru';
import 'dayjs/locale/ru'



const __default__ = {
    name: "App"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    data: {}
  },
  setup(__props: any) {

let props = __props;

let lightboxSrc = ref<string>();
provide(LightboxInjection, src => lightboxSrc.value = src);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(ElConfigProvider), { locale: _unref(ru) }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view, { class: "h-100" }),
        _createVNode(Wizard),
        (_unref(lightboxSrc))
          ? (_openBlock(), _createBlock(VueEasyLightbox, {
              key: 0,
              imgs: [_unref(lightboxSrc)],
              visible: "",
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(lightboxSrc) //@ts-ignore
 ? lightboxSrc.value = null : lightboxSrc = null))
            }, null, 8, ["imgs"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})