import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lcab-menu" }
const _hoisted_2 = {
  key: 0,
  class: "delimiter"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "descr" }
const _hoisted_6 = ["onClick", "onKeydown"]
const _hoisted_7 = ["href", "target"]

import {computed} from 'vue';
import MenuItem, {MenuChildrenListItem, SelectMenuItemEventPayloadInterface} from "./MenuItem";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadgeDisplay.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {RouteLocationRaw, useRoute, useRouter} from "vue-router";


const __default__ = {
    name: "LcabMenu"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    items: {},
    level: { default: 0 }
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let route = useRoute();

function isCurrentRoute(item: MenuItem): boolean {
    return item.isMatchedToRoute(route);
}

function getDescrClassForItem(item: MenuItem) {
    return item.isMuted ? "text-muted" : null;
}

let parsedItems = computed<MenuItem[]>(() => {
    if (props.items) {
        let result: MenuItem[] = [];
        for (let item of props.items) {
            if (typeof item == "function") {
                result.push(...item());
            } else {
                result.push(item);
            }
        }
        return result;
    } else {
        return null;
    }
});

let router = useRouter();

let filteredItems = computed(() => {
    return parsedItems.value ? parsedItems.value.filter(item => {
        let result = true;
        let name = item.routeName;
        if (name) {
            result = false;
            let path = getPath(item);
            let resolve = router.resolve(path);
            if (resolve) {
                result = RouteAccessChecker.hasAccess(resolve);
            }
        }
        return result;
    }) : null;
});

function getPath(item: MenuItem): RouteLocationRaw {
    return item.getRouterLocation(route);
}

function getKey(item: MenuItem): string {
    return JSON.stringify(getPath(item));
}

function emitClick(item: MenuItem) {
    let payload: SelectMenuItemEventPayloadInterface = {
        item: item,
        level: props.level
    };
    emits('select', payload);
}

function onLiClick(item: MenuItem, navigate: any, $event: Event) {
    emitClick(item);
    if (!item.isTargetBlank()) {
        navigate($event);
    } else {
        $event.stopPropagation();
    }
}

return (_ctx: any,_cache: any) => {
  const _component_lcab_menu = _resolveComponent("lcab-menu", true)!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredItems), (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.isDelimiter)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2))
          : (item.isTitle)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, _toDisplayString(item.descr), 1))
            : (item.isHref)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("a", {
                    href: item.href,
                    target: "_blank"
                  }, [
                    (item.icon)
                      ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                          key: 0,
                          icon: item.icon
                        }, null, 8, ["icon"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(item.descr), 1)
                  ], 8, _hoisted_4)
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: getKey(item),
                  to: getPath(item),
                  custom: ""
                }, {
                  default: _withCtx(({navigate, isActive, isExactActive, href}) => [
                    _createElementVNode("li", {
                      class: _normalizeClass({
                        'exact-selected': isExactActive,
                        'selected': isActive,
                        'collapsable': item.children.length > 1,
                        'last-level': item.children.length === 0
                    }),
                      onClick: ($event: any) => (onLiClick(item, navigate, $event)),
                      onKeydown: _withKeys(navigate, ["enter"])
                    }, [
                      _createElementVNode("a", {
                        href: href,
                        target: item.isTargetBlank() ? '_blank' : undefined,
                        class: "d-flex"
                      }, [
                        (item.icon)
                          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                              key: 0,
                              class: _normalizeClass(getDescrClassForItem(item)),
                              icon: item.icon
                            }, null, 8, ["class", "icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(['descr flex-fill', getDescrClassForItem(item)])
                        }, _toDisplayString(item.descr), 3),
                        _createElementVNode("div", null, [
                          _createVNode(MenuBadge, {
                            "menu-item": item,
                            tag: ""
                          }, null, 8, ["menu-item"])
                        ])
                      ], 8, _hoisted_7),
                      (item.children.length)
                        ? (_openBlock(), _createBlock(_component_lcab_menu, {
                            key: 0,
                            class: _normalizeClass({
                                displayed: isCurrentRoute(item),
                                'lcab-menu-inner': true
                            }),
                            items: item.children,
                            level: _ctx.level + 1,
                            onSelect: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('select', $event)))
                          }, null, 8, ["class", "items", "level"]))
                        : _createCommentVNode("", true)
                    ], 42, _hoisted_6)
                  ]),
                  _: 2
                }, 1032, ["to"]))
      ], 64))
    }), 256))
  ]))
}
}

})