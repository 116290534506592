import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed} from 'vue';
import MySelect from "~/cabinet/vue/interface/form/MySelect.vue";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "RegisterTags"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<string[]>(__props, "modelValue");

let remoteUrl = computed(() => {
    return "/api/sender/" + props.uslugaId + "/getTagsForSendForm";
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MySelect, {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    class: "w-100",
    multiple: "",
    filterable: "",
    "allow-create": "",
    "default-first-option": "",
    placeholder: _unref(__)('Укажите тэги для этой рассылки'),
    "remote-url": _unref(remoteUrl)
  }, null, 8, ["modelValue", "placeholder", "remote-url"]))
}
}

})