import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_2 = { class: "mt-4" }

import {ref, computed, provide, getCurrentInstance, nextTick, inject, watch} from "vue";
import MyFlipContainer from "~/cabinet/vue/interface/transition/MyFlipContainer.vue";
import FastTaskReport from "~/cabinet/vue/client/sender/send/create/FastTaskReport.vue";
import SenderReceiversBlock from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiversBlock.vue";
import SenderFormSettings from "~/cabinet/vue/client/sender/send/SenderFormSettings.vue";
import SenderReceiversForm from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiversForm.vue";
import MyRemoteData from "~/cabinet/vue/interface/data/MyRemoteData.vue";
import {
    ISenderFormContact,
    ISenderFormGroup,
    senderFormEditableInject, SenderSourceInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import {AsyncVueComponent} from "~/ts/vuePlugins/Interface";
import SenderCreateConfirmation
    from "~/cabinet/vue/client/sender/send/create/confirmation/SenderCreateConfirmation.vue";
import RejectedSourceWarning from "~/cabinet/vue/client/sender/send/create/RejectedSourceWarning.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import TemplatesDialog from "~/cabinet/vue/client/sender/send/TemplatesDialog.vue";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import UslugaAccessCard from "~/cabinet/vue/client/UslugaAccessCard.vue";
import Random from "~/ts/library/Random";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import SenderRouteBuilder from "~/cabinet/ts/routes/SenderRouteBuilder";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import NeedCreateSource from "~/cabinet/vue/client/sender/send/NeedCreateSource.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import {
    SenderContentFormModelInterface,
    SenderCreateFormParamsInterface,
    SenderFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {CascadeContentFormEventInject} from "~/cabinet/vue/client/events/action/types/Interface";
import Dictionary from "~/ts/library/Dictionary";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import {AccountStore, ContactBookDataSourceInterface} from "~/cabinet/ts/store/AccountStore";
import {CONTACTBOOK_DATA_SOURCE_ID} from "~/cabinet/ts/data/contactbook/constant";
import {RouteLocationNamedRaw} from "vue-router";
import {mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";
import {__} from "~/ts/library/Translate";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "SenderForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    formId: {},
    standartContentParams: {},
    repeatRegisterId: {},
    repeatTimeStamp: {},
    rightColumnSpan: {},
    additionalContentVariables: {},
    isForYourOrgManagers: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let senderFormEditable = ref(true);
provide(senderFormEditableInject, senderFormEditable);
let cascadeContentFormEvent = inject(CascadeContentFormEventInject, null);


let replaceStopAfterInit = ref(false);
let groups = ref<ISenderFormGroup[]>([]);
let formInstanceId = ref<string>();


function contentFormInput(value: any) {
    if (!isStandartContentUsed.value) {
        model.value.content = value;
    }
}


let isStandartContentAvailable = computed(() => {
    return props.standartContentParams != null;
});

let isStandartContentUsed = computed(() => {
    let result = model.value.isUseStandartContent && isStandartContentAvailable.value;
    senderFormEditable.value = !result;
    newFormInstanceId();
    return result;
});


let receiversCanBeHiddenByContentForm = computed(() => {
    return !!SenderUslugi[props.uslugaId].create.hideReceiversByContentForm;
});

let showSettings = ref(true);

let _modelValue = _useModel<SenderFormModelInterface<SenderContentFormModelInterface>>(__props, "modelValue");

let model = useObjectModel(_modelValue, () => {
    if (!_modelValue.value) {
        replaceStopAfterInit.value = true;
    }
    return {
        settings: null,
        recipients: null,
        content: null,
        isUseStandartContent: isStandartContentAvailable.value && (!_modelValue.value || !_modelValue.value.content)
    };
}, () => {
    SenderRouteBuilder.initData(props.uslugaId);
    if (receiversCanBeHiddenByContentForm.value) {
        showSettings.value = false;
    }
    newFormInstanceId();
});

function editStandartContent() {
    model.value.isUseStandartContent = false;
    model.value.content = ObjectHelper.jsonClone(props.standartContentParams);
}

function newFormInstanceId() {
    formInstanceId.value = Random.uid();
}

let privateParamsNotForDirectUsage = ref<SenderCreateFormParamsInterface>();

let isCouponSelected = computed(() => {
    let coupon = model.value?.settings?.coupon;
    return !!(
        privateParamsNotForDirectUsage.value.formParams.coupon
        && coupon?.enabled
        && coupon?.registerId
    );
});

let contentVariablesByRecipients = ref<Dictionary<ContentVariableInterface[]>>({});

let contactBookDataSourceList = ref<ContactBookDataSourceInterface[]>([]);

let params = computed<SenderCreateFormParamsInterface>({
    get: () => {
        let result = ObjectHelper.jsonClone(privateParamsNotForDirectUsage.value);
        if (result) {
            contactBookDataSourceList.value = AccountStore.contactbookDataSourceList.value.filter(item => {
                let allowed = result.formParams.allowedContactbookDataSourceIds;
                return allowed.length == 0 || allowed.includes(item.id);
            })

            let additionalContentVariables = [...(props.additionalContentVariables ?? [])];
            let contentVariables = result.formParams.contentVariables;

            if (isCouponSelected.value) {
                additionalContentVariables.push({
                    descr: "Промокод",
                    id: privateParamsNotForDirectUsage.value.couponCodeContentVariable,
                    groupDescr: "Промо-акции"
                });
            }

            if (additionalContentVariables) {
                contentVariables.push(...additionalContentVariables);
            }
            /*
            if (contactbookDataSourceId) {
                let type = result.recipientTypes.find(type => type.id == recipientTypeId);
                if (type) {
                    result.contentVariables.push(...type.contentVariables);
                }
            }*/

            let recipients = model.value.recipients;
            let dataSourceId = recipients?.contactbookDataSourceId;
            if (dataSourceId) {
                let dataSource = contactBookDataSourceList.value.find(item => item.id == dataSourceId);
                if (dataSource) {
                    let groupIds: string[] = [];
                    if (dataSource.isFieldsDependsOnGroupId) {
                        if (recipients.groups) {
                            groupIds.push(...recipients.groups);
                        }
                        if (recipients.contacts) {
                            let contactGroups = selectedContactsStore.value
                                .filter(contact => recipients.contacts.includes(contact.id))
                                .map(contact => contact.groupId);
                            for (let groupId of contactGroups) {
                                toggleArrayElement(groupIds, groupId, true);
                            }
                        }
                        groupIds.sort();
                    }
                    let key = `${recipients.contactbookDataSourceId}|${groupIds.join("|")}`;
                    if (!contentVariablesByRecipients.value.hasOwnProperty(key)) {
                        contentVariablesByRecipients.value[key] = [];
                        (async function () {
                            let result = await LcabApiRequest.fetch({
                                silent: true,
                                url: `/api/sender/common/${dataSourceId}/getContentVariables`,
                                p: {
                                    groupIds
                                },
                                silentError: true
                            });
                            if (result.isSuccess) {
                                contentVariablesByRecipients.value[key] = result.getData("contentVariables");
                            }
                        })();
                    }
                    for (let variable of contentVariablesByRecipients.value[key]) {
                        if (!contentVariables.find(item => item.id == variable.id)) {
                            contentVariables.push(variable);
                        }
                    }

                }
                //this.appendContentVariablesOfDataSource(recipients.contactbookDataSourceId, recipients.groups, recipients.contacts)
            }

            //result.formParams = SenderHelper.extendFormParams(result.formParams, model.value.recipients ?? {}, additionalContentVariables);
        }
        return result;
    },
    set: (params: SenderCreateFormParamsInterface) => {
        privateParamsNotForDirectUsage.value = params;
    }
});


let isReplyToChatAvailableByContent = ref(true);


let isTemplatesDialogVisible = ref(false);

let selectedContactsStore = ref<ISenderFormContact[]>([]);

watch(computed(() => model.value.recipients?.contactbookDataSourceId), (value, oldValue) => {
    if (oldValue) {
        if (model.value.recipients) {
            let filters = model.value.recipients.filters;
            if (filters) {
                for (let key in filters) {
                    if (filters.hasOwnProperty(key)) {
                        delete filters[key];
                    }
                }
            }
            model.value.recipients.groups.splice(0, model.value.recipients.groups.length);
            model.value.recipients.contacts.splice(0, model.value.recipients.contacts.length);
            selectedContactsStore.value.splice(0, selectedContactsStore.value.length);
        }
    }
})

let showPhonebookGroups = ref(false);
let showConfirm = ref(false);
let showFastReport = ref(false);
let sendInProgress = ref(false);
let sendId = ref<string>();
let sendError = ref<string>();

function validate() {
    showConfirm.value = true;
}

let formParams = computed(() => {
    return params.value?.formParams;
});

let showReceivers = computed(() => {
    let result = false;
    let params = formParams.value;
    if (params) {
        result = params.receiversContacts || params.receiversFilters || params.receiversGroups || params.receiversLimit || params.receiversOtherFromString || params.receiversStop;
    }
    return result;
});

let rulesRouterLocation = computed<RouteLocationNamedRaw>(() => {
    if (senderConfig.value.rules) {
        return {
            name: SenderRouteBuilder.getRulesRouteName(props.uslugaId)
        };
    }
    return null;
});


let isRightColumnVisible = computed(() => {
    let instance = getCurrentInstance().proxy;

    return (showReceivers.value || instance.$slots.prependRightColumn || instance.$slots.appendRightColumn) && rightColumnSpanComputed.value > 0 && !isSettingsMustBeHidden.value;
});

let rightColumnSpanComputed = computed(() => {
    return props.rightColumnSpan != null ? props.rightColumnSpan : formParams.value.rightColumnSpan;
});


let getFetchParamsDataParams = computed(() => {
    return {
        repeatRegisterId: props.repeatRegisterId,
        ts: props.repeatTimeStamp,
        cascadeContentFormEvent: cascadeContentFormEvent?.value
    };
});


function clearForm() {
    newFormInstanceId();
    model.value = {
        settings: ObjectHelper.jsonClone(model.value.settings),
        content: undefined,
        isUseStandartContent: undefined,
        recipients: undefined
    }
}


async function send() {
    showFastReport.value = true;
    sendInProgress.value = true;
    sendId.value = null;
    sendError.value = null;

    let modelClone = ObjectHelper.jsonClone(model.value);
    if (model.value.settings.clearForm && formParams.value.clearForm) {
        clearForm();
    }

    LcabApiResult.showSuccess("Началось формирование рассылки")

    try {
        let result = await LcabApiRequest.fetch({
            silent: true,
            url: `/api/sender/${props.uslugaId}/${props.formId}/sendCreateForm`,
            p: modelClone
        });
        if (result.isSuccess) {
            sendId.value = result.getData("sendId");
            return;
        } else {
            sendError.value = result.descr;
        }
    } catch (e) {
        sendError.value = "Ошибка выполнения запроса";
    }
    sendInProgress.value = false;
}

let senderConfig = computed(() => {
    return SenderUslugi[props.uslugaId];
});

let contentFormComponent = computed(() => {
    return senderConfig.value.contentFormComponent as AsyncVueComponent;
});

let topContentComponent = computed<any>(() => {
    return senderConfig.value.create.topContentComponent;
});

function onParamsLoad() {
    //await Delay.make(3000);

    if (model.value.recipients) {
        if (replaceStopAfterInit.value) {
            replaceStopAfterInit.value = false;
            model.value.recipients.stopListGroupIds = [...params.value.defaultStopListGroupIds];
        }
    }

    if (params.value) {
        if (params.value.repeatModel) {
            if (params.value.repeatModel.recipients?.contactbookDataSourceId) {
                model.value.recipients = params.value.repeatModel.recipients;
            }
            setTimeout(() => model.value = params.value.repeatModel, 1);
        }


        nextTick(() => {
            if (model.value.recipients) {
                loadContacts();
            }
        });
    }
}

async function loadContacts() {
    let recipients = model.value.recipients;
    if (recipients) {

        if (recipients.contacts) {
            let contacts = recipients.contacts;
            if (contacts.length) {
                let result = await LcabApiRequest.fetchList({
                    url: `/api/sender/helper/${recipients.contactbookDataSourceId}/getContactsList`,
                    silent: true,
                    p: {
                        contactIds: contacts,
                        uslugaId: props.uslugaId
                    }
                });
                if (result.isSuccess) {
                    selectedContactsStore.value.push(...result.items);
                }
            }
        }
    }
}

function onTemplateApply() {
    loadContacts();
}


function onTemplatesDialogModel(newModel: SenderFormModelInterface<SenderContentFormModelInterface>) {
    model.value = newModel;
}

function showTemplatesDialog() {
    isTemplatesDialogVisible.value = true;
}

let usluga = computed(() => {
    return SenderUslugi[props.uslugaId];
});

let isSettingsMustBeHidden = computed(() => {
    let result = false;
    if (usluga.value.hideContentFormWhenHasNoSources && params.value.contentFormParams.sources.length == 0) {
        result = true;
    }
    showSettings.value = !result;
    return result;
});

let sourceRouteName = computed(() => {
    let source = usluga.value.source;
    return source ? source.routing.getRoutes().name as string : null;
});

let selectedSource = computed<SenderSourceInterface>(() => {
    let sourceId = model.value?.content?.source;
    return params.value?.contentFormParams.sources.find(source => source.id == sourceId);
});

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(MyRemoteData, {
    "data-url": `/api/sender/${_ctx.uslugaId}/getParamsForSendForm/${_ctx.formId}`,
    "data-params": _unref(getFetchParamsDataParams),
    "onUpdate:data": _cache[16] || (_cache[16] = ($event: any) => (_isRef(params) //@ts-ignore
 ? params.value = $event : params = $event)),
    onLoad: onParamsLoad,
    key: _ctx.uslugaId
  }, {
    default: _withCtx(() => [
      (!_unref(params))
        ? (_openBlock(), _createBlock(MyLoader, {
            key: 0,
            big: ""
          }))
        : (_openBlock(), _createBlock(MyFlipContainer, {
            key: 1,
            modelValue: _unref(showConfirm),
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_isRef(showConfirm) ? (showConfirm).value = $event : showConfirm = $event))
          }, {
            front: _withCtx(() => [
              _createVNode(_component_el_row, {
                class: _normalizeClass({'el-row-root': true, 'flex-row-reverse': _unref(showSettings) || !_unref(receiversCanBeHiddenByContentForm)})
              }, {
                default: _withCtx(() => [
                  (_unref(formParams).showTopInfoBlocks)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _renderSlot(_ctx.$slots, "prepend"),
                        (!_unref(params).uslugaAccess.success)
                          ? (_openBlock(), _createBlock(_component_el_col, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(UslugaAccessCard, {
                                  access: _unref(params).uslugaAccess
                                }, null, 8, ["access"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_unref(topContentComponent))
                          ? (_openBlock(), _createBlock(_component_el_col, { key: 1 }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(topContentComponent))))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(RejectedSourceWarning, { "usluga-id": _ctx.uslugaId }, null, 8, ["usluga-id"])
                      ], 64))
                    : _createCommentVNode("", true),
                  (_unref(showFastReport))
                    ? (_openBlock(), _createBlock(_component_el_col, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(FastTaskReport, {
                            "usluga-id": _ctx.uslugaId,
                            "in-progress": _unref(sendInProgress),
                            "onUpdate:inProgress": _cache[0] || (_cache[0] = ($event: any) => (_isRef(sendInProgress) ? (sendInProgress).value = $event : sendInProgress = $event)),
                            "send-id": _unref(sendId),
                            error: _unref(sendError)
                          }, null, 8, ["usluga-id", "in-progress", "send-id", "error"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_unref(isRightColumnVisible) && (_unref(showSettings) || !_unref(receiversCanBeHiddenByContentForm)))
                    ? (_openBlock(), _createBlock(_component_el_col, {
                        key: 2,
                        class: _normalizeClass(['d-md-block', _unref(showPhonebookGroups) ? 'd-none' : '']),
                        md: _unref(rightColumnSpanComputed)
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "prependRightColumn"),
                          (_unref(showReceivers))
                            ? (_openBlock(), _createBlock(SenderReceiversBlock, {
                                key: 0,
                                filters: _unref(params).filters,
                                "form-params": _unref(formParams),
                                "is-receivers-select-visible": _unref(showPhonebookGroups),
                                "onUpdate:isReceiversSelectVisible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(showPhonebookGroups) ? (showPhonebookGroups).value = $event : showPhonebookGroups = $event)),
                                "contact-book-data-source-list": _unref(contactBookDataSourceList),
                                "usluga-id": _ctx.uslugaId,
                                groups: _unref(groups),
                                "stop-groups": _unref(params) ? _unref(params).contactbookGroups : [],
                                contacts: _unref(selectedContactsStore),
                                modelValue: _unref(model).recipients,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).recipients) = $event)),
                                "stop-list-saveable": ""
                              }, null, 8, ["filters", "form-params", "is-receivers-select-visible", "contact-book-data-source-list", "usluga-id", "groups", "stop-groups", "contacts", "modelValue"]))
                            : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, "appendRightColumn"),
                          (_unref(rulesRouterLocation))
                            ? (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(ElCardHeader, { title: "Внимание, важно" }),
                                  _createVNode(ElCardSection, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_router_link, { to: _unref(rulesRouterLocation) }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_button, {
                                            class: "w-100",
                                            round: ""
                                          }, {
                                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                                              _createTextVNode("Правила сервиса")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["to"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 3
                      }, 8, ["class", "md"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_col, {
                    md: _unref(isRightColumnVisible) ? (24 - _unref(rightColumnSpanComputed)) : 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyFlipContainer, {
                        modelValue: _unref(showPhonebookGroups),
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(showPhonebookGroups) ? (showPhonebookGroups).value = $event : showPhonebookGroups = $event))
                      }, {
                        front: _withCtx(() => [
                          (_unref(isStandartContentAvailable))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(BigIconFlexButtonWrapper, null, {
                                  default: _withCtx(() => [
                                    _createVNode(BigRadioFlexButton, {
                                      label: true,
                                      descr: "Стандартный контент",
                                      "extra-descr": "Будет отправлен стандартный контент, подготовленный нами",
                                      modelValue: _unref(model).isUseStandartContent,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).isUseStandartContent) = $event))
                                    }, null, 8, ["modelValue"]),
                                    _createVNode(BigRadioFlexButton, {
                                      label: false,
                                      descr: "Свой контент",
                                      "extra-descr": "Задайте контент сообщения самостоятельно",
                                      modelValue: _unref(model).isUseStandartContent,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).isUseStandartContent) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (_unref(isStandartContentUsed))
                            ? (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createVNode(ElCardSectionWithIcon, {
                                    icon: "comment-dots",
                                    value: "Будет отправлен стандартный контент, подготовленный нами",
                                    label: "Если вы хотите задать свой контент, выберите «Свой контент»",
                                    "icon-color": "primary"
                                  }, {
                                    append: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_2, [
                                        _createVNode(_component_el_button, {
                                          round: "",
                                          type: "success",
                                          plain: "",
                                          onClick: editStandartContent
                                        }, {
                                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                                            _createTextVNode(" Отредактировать стандартный контент ")
                                          ])),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_card, null, {
                            default: _withCtx(() => [
                              (!_unref(isSettingsMustBeHidden))
                                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(contentFormComponent)), {
                                    class: _normalizeClass({
                                            'standart-content-form': _unref(isStandartContentUsed)
                                        }),
                                    key: _unref(formInstanceId),
                                    "usluga-id": _ctx.uslugaId,
                                    "send-form-params": _unref(params),
                                    "contactbook-data-source-id": _unref(model).recipients ? _unref(model).recipients.contactbookDataSourceId : undefined,
                                    "on-templates-button-click": showTemplatesDialog,
                                    "is-for-your-org-managers": _ctx.isForYourOrgManagers,
                                    "model-value": _unref(isStandartContentUsed) ? _ctx.standartContentParams : _unref(model).content,
                                    "onUpdate:modelValue": contentFormInput,
                                    "settings-model": _unref(model).settings,
                                    onToggleSettings: _cache[5] || (_cache[5] = ($event: any) => (_isRef(showSettings) //@ts-ignore
 ? showSettings.value = $event : showSettings = $event)),
                                    onToggleReplyToOnlineChat: _cache[6] || (_cache[6] = ($event: any) => (_isRef(isReplyToChatAvailableByContent) //@ts-ignore
 ? isReplyToChatAvailableByContent.value = $event : isReplyToChatAvailableByContent = $event))
                                  }, null, 40, ["class", "usluga-id", "send-form-params", "contactbook-data-source-id", "is-for-your-org-managers", "model-value", "settings-model"]))
                                : (_openBlock(), _createBlock(NeedCreateSource, {
                                    key: 1,
                                    icon: _unref(usluga).icon,
                                    "route-name": _unref(sourceRouteName)
                                  }, null, 8, ["icon", "route-name"])),
                              _renderSlot(_ctx.$slots, "after-content-form"),
                              (_unref(showSettings))
                                ? (_openBlock(), _createBlock(SenderFormSettings, {
                                    key: 2,
                                    modelValue: _unref(model).settings,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).settings) = $event)),
                                    "usluga-id": _ctx.uslugaId,
                                    params: _unref(params),
                                    "selected-source": _unref(selectedSource),
                                    "is-reply-to-chat-available-by-content": _unref(isReplyToChatAvailableByContent),
                                    "is-contact-book-data-source": !!(_unref(model).recipients && _unref(model).recipients.contactbookDataSourceId == _unref(CONTACTBOOK_DATA_SOURCE_ID))
                                  }, null, 8, ["modelValue", "usluga-id", "params", "selected-source", "is-reply-to-chat-available-by-content", "is-contact-book-data-source"]))
                                : _createCommentVNode("", true),
                              (_unref(showSettings))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                    (_unref(formParams).standartButtons)
                                      ? (_openBlock(), _createBlock(ElCardSection, {
                                          key: 0,
                                          class: "sticky -bottom tabs-header-background"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_row, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_col, { md: 12 }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_button, {
                                                      class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                                                      round: "",
                                                      onClick: showTemplatesDialog
                                                    }, {
                                                      icon: _withCtx(() => [
                                                        _createVNode(FontAwesomeIcon, {
                                                          icon: "star",
                                                          light: ""
                                                        })
                                                      ]),
                                                      default: _withCtx(() => [
                                                        _cache[19] || (_cache[19] = _createTextVNode(" Шаблоны "))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["class"]),
                                                    _createVNode(TemplatesDialog, {
                                                      "usluga-id": _ctx.uslugaId,
                                                      "form-id": _ctx.formId,
                                                      model: _unref(model),
                                                      visible: _unref(isTemplatesDialogVisible),
                                                      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(isTemplatesDialogVisible) ? (isTemplatesDialogVisible).value = $event : isTemplatesDialogVisible = $event)),
                                                      "onUpdate:model": onTemplatesDialogModel,
                                                      onApply: onTemplateApply
                                                    }, null, 8, ["usluga-id", "form-id", "model", "visible"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_el_col, {
                                                  class: "text-right",
                                                  md: 12
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_el_button, {
                                                      onClick: validate,
                                                      class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                                                      round: "",
                                                      type: "primary"
                                                    }, {
                                                      icon: _withCtx(() => [
                                                        _createVNode(FontAwesomeIcon, {
                                                          icon: "envelope",
                                                          light: ""
                                                        })
                                                      ]),
                                                      default: _withCtx(() => [
                                                        _createTextVNode(" " + _toDisplayString(_unref(__)('Отправить сообщение')), 1)
                                                      ]),
                                                      _: 1
                                                    }, 8, ["class"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _renderSlot(_ctx.$slots, "buttons", { key: 1 })
                                  ], 64))
                                : _createCommentVNode("", true)
                            ]),
                            _: 3
                          })
                        ]),
                        back: _withCtx(() => [
                          _createElementVNode("div", null, [
                            (_unref(showReceivers))
                              ? (_openBlock(), _createBlock(SenderReceiversForm, {
                                  key: 0,
                                  modelValue: _unref(model).recipients,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(model).recipients) = $event)),
                                  groups: _unref(groups),
                                  "onUpdate:groups": _cache[10] || (_cache[10] = ($event: any) => (_isRef(groups) ? (groups).value = $event : groups = $event)),
                                  contacts: _unref(selectedContactsStore),
                                  "usluga-id": _ctx.uslugaId,
                                  visible: _unref(showPhonebookGroups),
                                  onClose: _cache[11] || (_cache[11] = ($event: any) => (_isRef(showPhonebookGroups) //@ts-ignore
 ? showPhonebookGroups.value = false : showPhonebookGroups = false))
                                }, null, 8, ["modelValue", "groups", "contacts", "usluga-id", "visible"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 3
                      }, 8, ["modelValue"])
                    ]),
                    _: 3
                  }, 8, ["md"])
                ]),
                _: 3
              }, 8, ["class"])
            ]),
            back: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_Transition, null, {
                  default: _withCtx(() => [
                    (_unref(showConfirm))
                      ? (_openBlock(), _createBlock(SenderCreateConfirmation, {
                          key: 0,
                          class: "for-flip-item",
                          modelValue: _unref(model),
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                          "form-id": _ctx.formId,
                          "usluga-id": _ctx.uslugaId,
                          visible: _unref(showConfirm),
                          "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => (_isRef(showConfirm) ? (showConfirm).value = $event : showConfirm = $event)),
                          onSend: send
                        }, null, 8, ["modelValue", "form-id", "usluga-id", "visible"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 3
          }, 8, ["modelValue"]))
    ]),
    _: 3
  }, 8, ["data-url", "data-params"]))
}
}

})