import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {onMounted, ref} from "vue";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import getAuthById from "~/cabinet/vue/client/online/config/integrations/getAuthById";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "AdditionalAuthConfigDialog"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    authId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

let props = __props;

let visible = _useModel<boolean>(__props, "visible");

let additionalAuthConfigDialog = ref<{
    auth: Auth
}>();

async function openAdditionalAuthConfigFormDialog() {
    let result = await getAuthById(props.authId);
    if (!result.showMessageOnError()) {
        if (result.data.auth.additionalConfigForm) {
            additionalAuthConfigDialog.value = {
                auth: result.data.auth
            }
            return;
        }
    }
    visible.value = false;
}

onMounted(() => openAdditionalAuthConfigFormDialog());

async function saveAdditionalConfig() {
    let result = await LcabApiRequest.save({
        url: `/api/client/integration/saveAdditionalAuthConfigForm/${props.authId}`,
        p: {
            form: additionalAuthConfigDialog.value.auth.additionalConfigModel
        }
    });
    if (result.isSuccess) {
        additionalAuthConfigDialog.value = null;
        visible.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  return (_unref(visible) && _unref(additionalAuthConfigDialog))
    ? (_openBlock(), _createBlock(MyDialog, {
        key: 0,
        visible: _unref(visible),
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: `Дополнительные настройки интеграции с ${_unref(additionalAuthConfigDialog).auth.descr}`,
        form: {
                    labelWidth: '220px',
                    labelPosition: 'left'
                },
        "button-text": "Сохранить",
        onApply: saveAdditionalConfig
      }, {
        default: _withCtx(() => [
          _createVNode(MyForm, {
            form: _unref(additionalAuthConfigDialog).auth.additionalConfigForm,
            modelValue: _unref(additionalAuthConfigDialog).auth.additionalConfigModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(additionalAuthConfigDialog).auth.additionalConfigModel) = $event))
          }, null, 8, ["form", "modelValue"])
        ]),
        _: 1
      }, 8, ["visible", "title"]))
    : _createCommentVNode("", true)
}
}

})