import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["src"]

import {ICabinetCaptchaField} from './Interfaces';
import {
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {computed, ref} from "vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {CaptchaExposeInterface} from "~/cabinet/vue/interface/form/elements/CaptchaExposeInterface";


const __default__ = {
    name: "CabinetCaptchaElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<ICabinetCaptchaField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");


let formElement = useFormElement(model, props, {
    focus: () => {
    }
});

let append = ref<string>(`?r=${Date.now()}`);

function refreshCaptcha() {
    append.value = `?r=${Date.now()}`;
}

let src = computed(() => LcabApiRequest.getUrl(`/api/clientOrGuest/captcha${append.value}`));

__expose<CaptchaExposeInterface>({
    ...formElement.expose,
    refreshCaptcha
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      placeholder: _ctx.field.placeholder,
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      class: "flex-fill mr-2"
    }, null, 8, ["placeholder", "modelValue"]),
    _createElementVNode("img", {
      src: _unref(src),
      class: "mr-2"
    }, null, 8, _hoisted_2),
    _createVNode(_component_el_button, { onClick: refreshCaptcha }, {
      default: _withCtx(() => [
        _createVNode(FontAwesomeIcon, { icon: "refresh" })
      ]),
      _: 1
    })
  ]))
}
}

})