import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

import {computed, ref} from 'vue';
import {
    ContentVariableGroupInterface,
    ContentVariableInterface
} from "~/cabinet/vue/interface/ContentVariableInterface";
import MyFilterTabPane from "~/cabinet/vue/interface/filter/MyFilterTabPane.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import Dictionary from "~/ts/library/Dictionary";
import {ContentVariablesComponentExposeInterface} from './ContentVariablesComponentExposeInterface';
import ContentVariablesHelper from "~/cabinet/vue/client/sender/send/ContentVariablesHelper";
import ElPrompt from "~/core-ui/vue/ui/ElPrompt";
import {MessageBoxInputData} from "element-plus";
import {__} from "~/ts/library/Translate";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "ContentVariables"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    variableGroups: {},
    hidden: { type: Boolean },
    contentVariables: {}
  },
  emits: ["paste"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit

let computedVariableGroups = computed(() => {
    let result: ContentVariableGroupInterface[] = [];
    if (props.variableGroups) {
        result = props.variableGroups;
    } else if (props.contentVariables) {
        result = ContentVariablesHelper.contentVariablesToGroups(props.contentVariables);
    }
    result = ObjectHelper.jsonClone(result);
    for (let group of result) {
        group.variables = group.variables.filter(variable => !variable.isHidden);
    }
    result = result.filter(group => group.variables.length > 0);
    return result;
});

let contentVariableDialog = ref<{
    visible: boolean,
    contentVariable: ContentVariableInterface,
    value: any
}>();

let allVariables = computed(() => {
    let result: Dictionary<ContentVariableInterface> = {};
    for (let group of computedVariableGroups.value) {
        for (let variable of group.variables) {
            result[variable.id] = variable;
        }
    }
    return result;
});

function select(text: string) {
    paste(text);
}

async function paste(text: string) {
    if (!text) {
        try {
            let result: MessageBoxInputData = await ElPrompt("Введите обращение в мужском роде (например - \"уважаемый\"). Дальше мы все сделаем сами!", "Введите обращение") as any;
            if (result.value && result.value.length) {
                text = result.value;
            } else {
                return;
            }
        } catch (e) {
            return;
        }
    } else {
        let variable = allVariables.value[text];
        if (variable && variable.params) {
            contentVariableDialog.value = {
                visible: true,
                contentVariable: variable,
                value: {}
            };
            return;
        }
    }
    makePaste(text);
}

function makePaste(text: string) {
    emits('paste', `[${text}]`);
}

function onContentVariableDialogApply() {
    let text = contentVariableDialog.value.contentVariable.id;
    let value = contentVariableDialog.value.value;
    if (ObjectHelper.hasKeys(value)) {
        text += `(variable-params)${JSON.stringify(value)}(/variable-params)`;
    }
    makePaste(text);
    contentVariableDialog.value = null;
}

__expose<ContentVariablesComponentExposeInterface>({
    select
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(computedVariableGroups).length)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hidden ? 'span' : 'el-dropdown'), _mergeProps({
          key: 0,
          onCommand: paste,
          trigger: "click"
        }, _ctx.$attrs), _createSlots({
          default: _withCtx(() => [
            (_ctx.$slots.default && !_ctx.hidden)
              ? _renderSlot(_ctx.$slots, "default", { key: 0 })
              : (!_ctx.hidden)
                ? (_openBlock(), _createBlock(_component_el_button, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(__)("Подставить")) + " ", 1),
                      _createVNode(FontAwesomeIcon, {
                        class: "ml-1",
                        icon: "caret-down"
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (!_ctx.hidden)
            ? {
                name: "dropdown",
                fn: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, { style: {"overflow-x":"hidden"} }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedVariableGroups), (group) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          _createVNode(_component_el_dropdown_item, { disabled: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(group.descr), 1)
                            ]),
                            _: 2
                          }, 1024),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.variables, (variable) => {
                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: variable.id,
                              command: variable.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(variable.descr), 1)
                              ]),
                              _: 2
                            }, 1032, ["command"]))
                          }), 128))
                        ], 64))
                      }), 256))
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1040))
      : _createCommentVNode("", true),
    (_unref(contentVariableDialog) && _unref(contentVariableDialog).visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 1,
          modelValue: _unref(contentVariableDialog).visible,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(contentVariableDialog).visible) = $event)),
          title: "Параметры подстановки",
          "append-to-body": ""
        }, {
          default: _withCtx(() => [
            _createVNode(MyFilterTabPane, {
              "filters-data": _unref(contentVariableDialog).contentVariable.params,
              modelValue: _unref(contentVariableDialog).value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_unref(contentVariableDialog).value) = $event)),
                onContentVariableDialogApply
              ],
              "add-button-text": "Добавить параметр",
              "empty-filters-string": "Дополнительные параметры не заданы"
            }, null, 8, ["filters-data", "modelValue"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})