import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "MyDateTimePicker"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    type: { default: "datetime" },
    format: { default: "DD.MM.YYYY HH:mm:ss" },
    valueFormat: { default: "YYYY-MM-DD HH:mm:ss" },
    placeholder: {},
    defaultTime: {},
    clearable: { type: Boolean },
    disabledDate: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


let props = __props;

let model = _useModel<string>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps(_unref(props), {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
  }), null, 16, ["modelValue"]))
}
}

})