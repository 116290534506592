import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"max-width":"200px"},
  class: "text-truncate"
}
const _hoisted_2 = {
  key: 1,
  class: "text-red"
}

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {OnlineChatClientRedirectActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "OnlineChatRedirectBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatClientRedirectActionDescription>()
    },
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.params.url)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.params.url), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, " Ссылка не задана "))
  ]))
}
}

})