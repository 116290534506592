import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "el-dropdown-link text-nowrap" }

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "HeaderDropdownButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    placement: {},
    showCaret: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    class: "header-dropdown-wrapper d-flex h-100 align-items-center",
    trigger: "click",
    placement: _ctx.placement
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, { class: "header-dropdown" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "dropdown")
        ]),
        _: 3
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showCaret)
          ? (_openBlock(), _createBlock(FontAwesomeIcon, {
              key: 0,
              icon: "chevron-down",
              light: "",
              class: "caret text-muted"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(['header-dropdown-text', _ctx.showCaret ? 'with-caret' : ''])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ])
    ]),
    _: 3
  }, 8, ["placement"]))
}
}

})