import {Component, defineAsyncComponent, markRaw, Raw} from "vue";

export type RawComponent = Raw<Component>;

export function useRawComponent(component: Component): RawComponent {
    return markRaw(component);
}

export function useRawAsyncComponent(promise: any): RawComponent {
    return useRawComponent(defineAsyncComponent(promise));
}