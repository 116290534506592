function getNow() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
}

function getYesterday() {
    let now = getNow();
    now.setDate(now.getDate() - 1);
    return now;
}

export function dateTimeFromToday(date: Date) {
    return date <= getYesterday();
}