import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-truncate" }
const _hoisted_2 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_3 = ["onClick"]

import {computed, ref, watch} from 'vue';
import {ISenderFormContact, ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import {IRemoteDataTable} from "~/cabinet/vue/interface/ElemeInterface";
import ReceiverGroupIcon from "~/cabinet/vue/client/sender/send/receiverSelect/ReceiverGroupIcon.vue";
import ElCardSectionSearch from "~/cabinet/vue/interface/card/ElCardSectionSearch.vue";
import Delay from "~/ts/library/Delay";
import ContactsTable from "~/cabinet/vue/client/sender/send/receiverSelect/ContactsTable.vue";
import MyRemoteDataTable from "~/cabinet/vue/interface/data/MyRemoteDataTable.vue";
import LcabWindowHelper from "~/ts/library/LcabWindowHelper";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import {tableMaxHeight} from "~/ts/vuePlugins/CabinetUiHelpersMixin";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "SenderReceiverSelectGroups"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    contactbookDataSourceId: {},
    groups: { default: () => [] as ISenderFormGroup[] },
    selectedContacts: {},
    contacts: {},
    uslugaId: {},
    isChanged: { type: Boolean },
    visible: { type: Boolean }
  }, {
    "modelValue": {
    required: true
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["close", "update:groups", "open-group", "update:selectedContacts", "contacts-change"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const SEARCH_GROUPS = "groups";
const SEARCH_CONTACTS = "contacts";

let props = __props;

let emits = __emit;

let model = _useModel<number[]>(__props, "modelValue");


let search = ref("");
let searchType = ref("groups");
let searchPreparedValue = ref("");

function reloadGroups() {
    groupsTable.value.reload();
}

watch(computed(() => props.visible), newValue => {
    if (!newValue) {
        searchType.value = SEARCH_GROUPS;
        search.value = "";
    }
})

watch(search, async () => {
    if (searchType.value == SEARCH_GROUPS) {
        try {
            await Delay.make(200, "searchInReceivers");
            setSearchPreparedValue();
        } catch (e) {

        }
    }
})


function onSearchEnter() {
    setSearchPreparedValue();
}

function setSearchPreparedValue() {
    searchPreparedValue.value = search.value.trim().toLowerCase();
}

function isGroupSelected(group: ISenderFormGroup): boolean {
    return model.value.includes(group.id);
}

let groupsTable = ref<IRemoteDataTable>();

let searchPrependSpan = computed(() => {
    return search.value.length ? (LcabWindowHelper.isMobile() ? 8 : 5) : 0;
});

let showSearchButton = computed(() => {
    return !!(searchPrependSpan.value && searchType.value == SEARCH_CONTACTS);
});

function getRowClassName(item: { row: ISenderFormGroup }) {
    return filteredGroups.value.indexOf(item.row) === -1 ? "d-none" : "";
}

let filteredGroups = computed(() => {
    let search = searchPreparedValue.value;
    if (search.length && searchType.value == SEARCH_GROUPS) {
        return props.groups.filter(group => group.descr.toLocaleLowerCase().indexOf(search) > -1).map(group => group);
    } else {
        return props.groups;
    }
});

let showGroupsTable = computed(() => {
    return !(search.value.length && searchType.value == SEARCH_CONTACTS);
});

function groupSortMethod(a: ISenderFormGroup, b: ISenderFormGroup) {
    return SenderReceiverSelectHelper.groupSortMethod(a, b);
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(ElCardHeader, {
        title: _unref(__)('Группы'),
        "button-col-span": 16
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            round: "",
            onClick: reloadGroups
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Обновить ")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: _ctx.isChanged ? 'primary' : null,
            round: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isChanged ? _unref(__)('Применить') : _unref(__)('Отменить')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(ElCardSectionSearch, {
        modelValue: _unref(search),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
        onEnter: onSearchEnter,
        placeholder: _unref(__)('Поиск во всех группах'),
        "prepend-span": _unref(searchPrependSpan),
        "show-button": _unref(showSearchButton)
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _unref(searchType),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchType) ? (searchType).value = $event : searchType = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                value: "groups",
                label: "По группам"
              }),
              _createVNode(_component_el_option, {
                value: "contacts",
                label: "По контактам"
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "prepend-span", "show-button"]),
      _withDirectives(_createVNode(MyRemoteDataTable, {
        "data-url": `/api/sender/${_ctx.uslugaId}/${_ctx.contactbookDataSourceId}/getGroupsList`,
        ref_key: "groupsTable",
        ref: groupsTable,
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        onSelection: _cache[5] || (_cache[5] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event)),
        "max-height": _unref(tableMaxHeight),
        "row-key": "id",
        "row-class-name": getRowClassName,
        "onUpdate:items": _cache[6] || (_cache[6] = ($event: any) => (_unref(emits)('update:groups', $event))),
        "default-sort": {prop: 'descr', order: 'ascending'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            type: "selection",
            width: "50"
          }),
          _createVNode(_component_el_table_column, {
            label: _unref(__)('Название группы'),
            sortable: "",
            prop: "descr",
            "sort-method": groupSortMethod,
            "class-name": "text-truncate"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(ReceiverGroupIcon, {
                  class: "mr-1",
                  group: row
                }, null, 8, ["group"]),
                _createElementVNode("span", {
                  onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                }, [
                  (!row.count)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(row.descr), 1))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        onClick: _withModifiers(($event: any) => (_unref(emits)('open-group', row)), ["prevent"]),
                        href: "#"
                      }, _toDisplayString(row.descr), 9, _hoisted_3))
                ])
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            width: "80",
            property: "count",
            align: "right"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", {
                class: _normalizeClass([isGroupSelected(row) ? '' : 'text-muted'])
              }, _toDisplayString(row.count), 3)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-url", "modelValue", "max-height"]), [
        [_vShow, _unref(showGroupsTable)]
      ]),
      (!_unref(showGroupsTable))
        ? (_openBlock(), _createBlock(ContactsTable, {
            key: 0,
            "contactbook-data-source-id": _ctx.contactbookDataSourceId,
            contacts: _ctx.contacts,
            uslugaId: _ctx.uslugaId,
            "model-value": _ctx.selectedContacts,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_unref(emits)('update:selectedContacts', $event))),
            params: {search: _unref(searchPreparedValue), searchType: _unref(searchType)},
            disabled: _unref(showGroupsTable),
            groups: _ctx.groups,
            "show-group": "",
            onSelection: _cache[8] || (_cache[8] = ($event: any) => (_unref(emits)('contacts-change', $event)))
          }, null, 8, ["contactbook-data-source-id", "contacts", "uslugaId", "model-value", "params", "disabled", "groups"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})