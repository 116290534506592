import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(`${_ctx.prefixCls}-icon icon`),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", {
      "xlink:href": `#chat-lightbox-icon-${_ctx.type}`
    }, null, 8, _hoisted_1)
  ], 2))
}