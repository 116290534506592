import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-1" }
const _hoisted_2 = { class: "mt-1" }

import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {GlobalVarsActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {IGlobalVarsBlockParamVar} from "~/cabinet/vue/client/events/action/types/Interface";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "GlobalVarsBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<GlobalVarsActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<GlobalVarsActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    vars: [getNewValue()]
}))

function getNewValue(): IGlobalVarsBlockParamVar {
    return {key: "", value: "", type: null};
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, {
      "label-width": "0",
      rules: _unref(requiredRule)
    }, {
      default: _withCtx(() => [
        _createVNode(MyInputList, {
          modelValue: _unref(model).vars,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).vars) = $event)),
          "remove-last-available": false,
          "new-item-value-getter": getNewValue
        }, {
          default: _withCtx(({index}) => [
            _createVNode(_component_el_card, { class: "mb-3" }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("div", null, " Название переменной: ", -1)),
                _createVNode(_component_el_input, {
                  class: "mt-1",
                  modelValue: _unref(model).vars[index].key,
                  "onUpdate:modelValue": ($event: any) => ((_unref(model).vars[index].key) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mt-3" }, " Новое значение переменной: ", -1)),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_el_select, {
                    modelValue: _unref(model).vars[index].type,
                    "onUpdate:modelValue": ($event: any) => ((_unref(model).vars[index].type) = $event),
                    class: "w-100"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        value: null,
                        label: "Задать новое значение вручную"
                      }),
                      _createVNode(_component_el_option, {
                        value: "math",
                        label: "Результат математического выражения"
                      })
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(TextInputWithVariables, {
                    modelValue: _unref(model).vars[index].value,
                    "onUpdate:modelValue": ($event: any) => ((_unref(model).vars[index].value) = $event),
                    "content-variables": _ctx.contentVariables,
                    multiline: false,
                    placeholder: !_unref(model).vars[index].type ? 'Если оставить пустым, то старое значение будет затёрто' : null
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables", "placeholder"]),
                  (_unref(model).vars[index].type == 'math')
                    ? (_openBlock(), _createBlock(HelpBlock, { key: 0 }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("p", null, "Например: 1 + 1", -1),
                          _createElementVNode("p", null, "Вы можете использовать математические операции + - * и /", -1),
                          _createElementVNode("p", null, "Вам доступна подстановка переменных. Для избежания ошибок подставляйте только цифры.", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["rules"])
  ]))
}
}

})