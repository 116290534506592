import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "side-menu overflow-auto" }
const _hoisted_2 = { class: "flex-fill content-wrapper position-relative overflow-auto" }
const _hoisted_3 = { class: "properties flex-column h-100" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = {
  key: 0,
  class: "preview-width-container"
}
const _hoisted_6 = { class: "pr-2" }

import {
    IHtmlConstructorBlock,
    IHtmlConstructorEditorModel,
    IHtmlConstructorParams
} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import HtmlConstructorBlockType from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorBlockType.vue";
import HtmlConstructorContent from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorContent.vue";
import HtmlConstructorBlockProperties from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorBlockProperties.vue";
import IHtmlConstructorEditor from "~/cabinet/vue/interface/htmlConstructor/IHtmlConstructorEditor";
import HtmlConstructorStyles from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorStyles.vue";
import HtmlConstructorStyleTemplates from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorStyleTemplates.vue";
import ISavedBlock from "~/cabinet/vue/interface/htmlConstructor/ISavedBlock";
import Lock from "~/ts/library/Lock";
import Random from "~/ts/library/Random";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, onMounted, provide, ref, watch} from "vue";
import ElPrompt from "~/core-ui/vue/ui/ElPrompt";
import {
    HtmlConstructorContentInject,
    HtmlConstructorEditorInject
} from "~/cabinet/vue/interface/htmlConstructor/Constants";
import {MessageBoxInputData} from "element-plus";


const __default__ = {
    name: "HtmlConstructorEditor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    params: {},
    preview: { type: Boolean },
    previewWidth: {},
    firstTabLabel: {},
    hideStyleEdit: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {

let props = __props;

provide(HtmlConstructorContentInject, {
    editable: true
})

let _modelValue = _useModel<IHtmlConstructorEditorModel<any>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    blocks: props.params.typeObject.getDefaultBlockLists(),
    style: null,
    additionalTypeParams: null
}));

let selectedBlock = ref<IHtmlConstructorBlock<any>>();
let selectedBlockKey = ref<string>();
let contentWidth = ref<number>();

watch(selectedBlock, () => {
    if (selectedBlock.value) {
        propertiesTab.value = "element";
        selectedBlockKey.value = Random.uid();
    }
});

let showTabs = computed(() => {
    return (props.params.typeObject.isStyleEditableInContentEditor && !props.hideStyleEdit) || props.firstTabLabel;
});

/*
let style = computed(() => {
    return model.value.style;
});
*/

const firstTabId = "firstTab";

onMounted(() => {
    contentWidth.value = props.params.type.defaultContentWidth;
    if (props.firstTabLabel) {
        propertiesTab.value = firstTabId;
    }
});

let minContentWidth = computed(() => {
    return props.params.type.minContentWidth;
});

let maxContentWidth = computed(() => {
    return props.params.type.maxContentWidth;
});


let propertiesTab = ref("element");

let blockTypes = computed(() => {
    return props.params.blockTypes;
});

let savedBlocks = ref<ISavedBlock[]>();


async function getSavedBlocks(forceReload: boolean = false, showLoad: boolean = false): Promise<void> {
    if (savedBlocks.value == null || forceReload) {
        savedBlocks.value = null;
        let locker = (new Lock("getHtmlConstructorSaveBlocks"));
        await locker.lock(true);
        if (savedBlocks.value == null) {
            try {
                let result = await LcabApiRequest.fetch({
                    url: `/api/htmlConstructor/${props.params.type.id}/getSavedBlocks`,
                    p: {
                        constructorId: props.params.constructorId
                    },
                    silent: !showLoad
                });
                if (savedBlocks.value) {
                    savedBlocks.value.splice(0, savedBlocks.value.length);
                } else {
                    savedBlocks.value = [];
                }
                if (!result.showMessageOnError()) {
                    savedBlocks.value.push(...result.getData("list"));
                }
            } catch (e) {

            }
        }
        locker.unlock();
    }
}

function removeSavedBlock(block: ISavedBlock): void {
    if (savedBlocks.value) {
        let index = savedBlocks.value.findIndex(item => block.id == item.id);
        if (index > -1) {
            savedBlocks.value.splice(index, 1);
        }
    }
}

function replaceSavedBlock(block: ISavedBlock): void {
    if (savedBlocks.value) {
        let index = savedBlocks.value.findIndex(item => block.id == item.id);
        if (index > -1) {
            savedBlocks.value.splice(index, 1, block);
        } else {
            savedBlocks.value.push(block);
        }
    }
}

async function saveBlock(block: IHtmlConstructorBlock<any>, id?: string, descr?: string): Promise<ISavedBlock | null> {
    try {
        let result: MessageBoxInputData = await ElPrompt("Введите название для сохраняемого блока", {
            title: "Сохранение блока",
            inputValue: descr
        }) as any;
        if (result.value.length) {
            let saveResult = await LcabApiRequest.save({
                url: `/api/htmlConstructor/${props.params.type.id}/saveBlock`,
                p: {
                    id,
                    descr: result.value,
                    block,
                    constructorId: props.params.constructorId
                }
            });
            if (saveResult.isSuccess) {
                let savedBlock = saveResult.getData("block");
                replaceSavedBlock(saveResult.getData("block"));
                return savedBlock;
            }
        }
    } catch (e) {
    }
    return null;
}

let exposeObject: IHtmlConstructorEditor = {
    savedBlocks,
    replaceSavedBlock,
    model,
    getSavedBlocks,
    params: props.params,
    removeSavedBlock,
    saveBlock,
    selectedBlock
}

__expose<IHtmlConstructorEditor>(exposeObject);
provide(HtmlConstructorEditorInject, exposeObject);



return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['h-100', 'd-flex', 'dialog-content', _ctx.preview ? 'preview' : null])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blockTypes), (type) => {
        return (_openBlock(), _createBlock(HtmlConstructorBlockType, {
          key: type.id,
          class: "side-menu-item",
          type: type
        }, null, 8, ["type"]))
      }), 128))
    ], 512), [
      [_vShow, !_ctx.preview]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(HtmlConstructorContent, {
        class: "content",
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        params: _ctx.params,
        editable: !_ctx.preview,
        width: _ctx.previewWidth && _ctx.preview ? _ctx.previewWidth : _unref(contentWidth)
      }, null, 8, ["modelValue", "params", "editable", "width"]), [
        [_directive_on_native, ()  => {_isRef(selectedBlock) //@ts-ignore
 ? selectedBlock.value = null : selectedBlock = null;}, "click"]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "flex-fill overflow-auto",
        style: _normalizeStyle({
                    paddingTop: _unref(showTabs) ? null : '0px'
                })
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_radio_group, {
            modelValue: _unref(propertiesTab),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(propertiesTab) ? (propertiesTab).value = $event : propertiesTab = $event))
          }, {
            default: _withCtx(() => [
              (_ctx.firstTabLabel)
                ? (_openBlock(), _createBlock(_component_el_radio_button, {
                    key: 0,
                    value: firstTabId
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.firstTabLabel), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_el_radio_button, { value: "element" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Элемент")
                ])),
                _: 1
              }),
              (_ctx.params.typeObject.isStyleEditableInContentEditor && !_ctx.hideStyleEdit)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_el_radio_button, { value: "style" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Стиль")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_radio_button, { value: "theme" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Темы")
                      ])),
                      _: 1
                    })
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ], 512), [
          [_vShow, _unref(showTabs)]
        ]),
        _createVNode(_component_el_tabs, {
          modelValue: _unref(propertiesTab),
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(propertiesTab) ? (propertiesTab).value = $event : propertiesTab = $event)),
          class: "el-tabs-hide-header"
        }, {
          default: _withCtx(() => [
            (_ctx.firstTabLabel)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  name: firstTabId
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "first-tab")
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_tab_pane, { name: "element" }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(HtmlConstructorBlockProperties, {
                  modelValue: _unref(selectedBlock),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(selectedBlock) ? (selectedBlock).value = $event : selectedBlock = $event)),
                  key: _unref(selectedBlockKey)
                }, null, 8, ["modelValue"]))
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, { name: "style" }, {
              default: _withCtx(() => [
                _createVNode(HtmlConstructorStyles, {
                  modelValue: _unref(model).style,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).style) = $event)),
                  "constructor-params": _ctx.params
                }, null, 8, ["modelValue", "constructor-params"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_tab_pane, {
              name: "theme",
              lazy: ""
            }, {
              default: _withCtx(() => [
                _createVNode(HtmlConstructorStyleTemplates, {
                  modelValue: _unref(model).style,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).style) = $event)),
                  type: _ctx.params.type
                }, null, 8, ["modelValue", "type"])
              ]),
              _: 1
            })
          ]),
          _: 3
        }, 8, ["modelValue"])
      ], 4),
      (_unref(minContentWidth) < _unref(maxContentWidth))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "mt-1" }, "Ширина контента в предпросмотре:", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_slider, {
                modelValue: _unref(contentWidth),
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(contentWidth) ? (contentWidth).value = $event : contentWidth = $event)),
                min: _unref(minContentWidth),
                max: _unref(maxContentWidth)
              }, null, 8, ["modelValue", "min", "max"])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, !_ctx.preview]
    ])
  ], 2))
}
}

})