import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed} from "vue";
import {iconLight} from "~/ts/vuePlugins/IconPlugin";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyIconButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    icon: {},
    nearText: { type: Boolean },
    disabled: { type: Boolean },
    add: { type: Boolean },
    delete: { type: Boolean },
    edit: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let computedIcon = computed(() => {
    let icon: string;
    if (props.add) {
        icon = 'plus'
    } else if (props.delete) {
        icon = 'times'
    } else if (props.edit) {
        icon = 'pencil'
    }
    return icon ? iconLight(icon) : props.icon;
});


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    class: _normalizeClass(['no-border', _ctx.nearText ? 'round-icon-button-near-text' : '']),
    disabled: _ctx.disabled,
    link: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click')))
  }, {
    default: _withCtx(() => [
      _createVNode(FontAwesomeIcon, { icon: _unref(computedIcon) }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["class", "disabled"]))
}
}

})