import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {FilterComponentExpose, useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {computed, ref} from "vue";
import {FILTER_TYPE_FLOAT, FILTER_TYPE_INTEGER} from "~/cabinet/vue/interface/filter/MyFilterTypes";
import {InputInstance} from "element-plus";


const __default__ = {
    name: "MyFilterText"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps(),
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<string|number>(__props, "modelValue");

let input = ref<InputInstance>();

function focus() {
    input.value?.focus();
}

let autoCompleteUrl = computed(() => {
    return props.component.filter.autoComplete;
})

let filterTypeId = computed(() => props.component.filter.type);

let type = computed(() => {
    if ([FILTER_TYPE_FLOAT, FILTER_TYPE_INTEGER].includes(filterTypeId.value)) {
        return "number";
    }
    return "text";
});

function processValue(value: string): any {
    if ([FILTER_TYPE_FLOAT, FILTER_TYPE_INTEGER].includes(filterTypeId.value)) {
        if (value == null || !value.length) {
            return null;
        } else {
            let result = filterTypeId.value == FILTER_TYPE_FLOAT ? parseFloat(value) : parseInt(value);
            if (isNaN(result)) {
                return null;
            }
            return result;
        }
    }
    return value;
}

async function fetchSuggestions(queryString: string, callback: (data: any[]) => void) {
    let result = await LcabApiRequest.fetch({
        url: autoCompleteUrl.value,
        p: {
            term: queryString
        },
        silent: true
    });
    let data: any[] = [];
    if (result.isSuccess) {
        data = (result.getParam("result") as string[]).map(item => ({
            value: (item as any).value
        }));
    }
    callback(data);
}

__expose<FilterComponentExpose>({
    focus
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(autoCompleteUrl))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_unref(type) === 'text' && _ctx.filtersData && _ctx.filtersData.contentVariables)
            ? (_openBlock(), _createBlock(TextInputWithVariables, {
                key: 0,
                placeholder: _ctx.component.filter.placeholder,
                "model-value": _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = processValue($event) : model = processValue($event))),
                ref_key: "input",
                ref: input,
                "content-variables": _ctx.filtersData.contentVariables,
                multiline: false,
                "auto-focus": ""
              }, null, 8, ["placeholder", "model-value", "content-variables"]))
            : (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                "model-value": _unref(model),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = processValue($event) : model = processValue($event))),
                placeholder: _ctx.component.filter.placeholder,
                autofocus: true,
                ref_key: "input",
                ref: input,
                type: _unref(type)
              }, null, 8, ["model-value", "placeholder", "type"]))
        ], 64))
      : (_openBlock(), _createBlock(_component_el_autocomplete, {
          key: 1,
          "model-value": _unref(model),
          class: "w-100",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = processValue($event) : model = processValue($event))),
          placeholder: _ctx.component.filter.placeholder,
          autofocus: true,
          "trigger-on-focus": false,
          ref_key: "input",
          ref: input,
          "fetch-suggestions": fetchSuggestions
        }, null, 8, ["model-value", "placeholder"]))
  ]))
}
}

})