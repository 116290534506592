import Dictionary from "~/ts/library/Dictionary";
import {reactive} from "vue";

let sprintf = require("sprintf-js").sprintf;
let strings: Dictionary<string> = reactive({});
export const __ = function (str: string, ...variables: any[]): string {
    return __sprintf(str, ...variables);
};

export const __sprintf = function (str: string, ...variables: any[]): string {
    if (typeof str == "string") {
        if (!strings.hasOwnProperty(str)) {
            strings[str] = null;
        }
        if (strings[str] != null) {
            str = strings[str];
            arguments[0] = str;
        }
        if (variables[0] != null && Array.isArray(variables[0])) {
            variables = variables[0];
            variables.unshift(str);
        }
        if (variables.length) {
            return sprintf.apply(null, arguments);
        } else {
            return arguments[0];
        }
    } else {
        return str;
    }
};

export default class Translate {
    public static setStrings(newStrings: Dictionary<string>) {
        for (let key in strings) {
            if (strings.hasOwnProperty(key)) {
                if (!newStrings.hasOwnProperty(key)) {
                    strings[key] = null;
                }
            }
        }
        for (let key in newStrings) {
            if (newStrings.hasOwnProperty(key)) {
                strings[key] = newStrings[key];
            }
        }
    }
}