import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import ICodeBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/code/ICodeBlockValues";
import CodeBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeBlockType";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "CodeForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps<CodeBlockType>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockForm(props);

let _modelValue = _useModel<ICodeBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Язык" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _unref(model).language,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).language) = $event)),
            class: "w-100"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blockType.paramsForEditor.languages, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.id,
                  value: item.id,
                  label: item.descr
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})