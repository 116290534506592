import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Currency from "~/cabinet/vue/interface/Currency.vue";
import {useTarifPriceProps} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/useTarifPrice";
import {SingleTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";


const __default__ = {
    name: "SinglePriceTableCell"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTarifPriceProps<SingleTarifPrice>()
},
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(Currency, {
      "currency-id": _unref(props).modelValue.currencyId,
      sum: _unref(props).modelValue.sum,
      "short-descr": ""
    }, null, 8, ["currency-id", "sum"])
  ]))
}
}

})