import {reactive} from "vue";

export const STATIC_SERVER = "https://static.me-talk.ru";
export const INTERNATIONAL_LCAB_HOST = "admin.verbox.me";
export const INTERNATIONAL_STATIC_SERVER = "https://static.site-chat.me";

export const DEFAULT_OPERATOR_APP_HOST = "operator.me-talk.ru";
export const INTERNATIONAL_OPERATOR_APP_HOST = "operator.site-chat.me";

let reactiveStorage = reactive({
    isInternationalHostEnabled: false
});

export function toggleInternationHost(value: boolean) {
    reactiveStorage.isInternationalHostEnabled = value;
}

export function isInternationalHostEnabled(): boolean {
    return reactiveStorage.isInternationalHostEnabled;
}

export function internationalUrlIfNeeded(url: string): string {
    if (isInternationalHostEnabled()) {
        url = url
            .replace(DEFAULT_OPERATOR_APP_HOST, INTERNATIONAL_OPERATOR_APP_HOST)
            .replace("operator.apibcknd.com", INTERNATIONAL_OPERATOR_APP_HOST)
            .replace(STATIC_SERVER, INTERNATIONAL_STATIC_SERVER)
            .replace("https://me-talk.ru/API/assets/", `https://${INTERNATIONAL_OPERATOR_APP_HOST}/API/assets/`)
            .replace("//download.me-talk.ru/", "//download.site-chat.me/")
            .replace("//pic.me-talk.ru/", "//pic.site-chat.me/");
    }
    return url;
}