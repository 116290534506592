import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-fill" }
const _hoisted_2 = {
  key: 0,
  class: "p-3 mt-2"
}

import {computed} from 'vue';
import AsideSectionSelector from "./Aside/AsideSectionSelector.vue";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import VerticalSectionSelector from "~/cabinet/vue/layout/lcab/Aside/VerticalSectionSelector.vue";
import CabinetHtmlify from "~/cabinet/vue/interface/CabinetHtmlify.vue";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "Aside"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let asideClass = computed(() => {
    return {
        visible: LcabLayoutStore.asideVisible.value
    };
});

let uslugiList = AccountStore.availableUslugiList;
let leftMenuHtmlContent = computed(() => {
    return AccountStore.data.value.htmlContent.leftMenu;
});

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_aside = _resolveComponent("el-aside")!

  return (_openBlock(), _createBlock(_component_el_aside, {
    class: _normalizeClass(_unref(asideClass)),
    width: "auto",
    id: "lcab-aside"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, { class: "is-vertical h-100" }, {
        default: _withCtx(() => [
          (_unref(isMobileWindowSize))
            ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                class: "aside-header"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, null, {
                    default: _withCtx(() => [
                      _createVNode(AsideSectionSelector)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              (!_unref(isMobileWindowSize))
                ? (_openBlock(), _createBlock(VerticalSectionSelector, { key: 0 }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "before-left-menu"),
                _renderSlot(_ctx.$slots, "left-menu"),
                _renderSlot(_ctx.$slots, "after-left-menu"),
                (_unref(leftMenuHtmlContent))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(CabinetHtmlify, {
                        value: _unref(leftMenuHtmlContent),
                        escape: false
                      }, null, 8, ["value"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})