import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-table-wrapper" }

import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";

const __default__ = {
    name: "ElCardSectionWithTable"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ElCardSection, { class: "el-card-section-with-table" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }))
}
}

})