import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    DiscountCouponFormInterface
} from "~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCouponFormInterface";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";

interface Usluga {
    id: string;
    descr: string;
    form: IForm;
}


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DiscountCouponForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    isCommon: { type: Boolean },
    id: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


let props = __props;

let _modelValue = _useModel<DiscountCouponFormInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => {
    return {
        form: null,
        uslugaId: null
    }
});

let selectedUsluga = computed(() => {
    return request.value.data?.uslugi.find(item => item.id == model.value?.uslugaId);
});

let request = computed(() => {
    return new ReactiveRequest<{
        uslugi: Usluga[]
    }>(new LcabApiRequest({
        url: `/api/dealer/config/marketing/discountCoupon/getFormData`,
        p: {
            isCommon: props.isCommon
        }
    }));
});

let isNew = computed(() => !props.id)


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(ReactiveRequestLoader, { request: _unref(request) }, {
    default: _withCtx(() => [
      (_unref(request).data)
        ? (_openBlock(), _createBlock(_component_el_form, {
            key: 0,
            "label-width": "180px",
            "label-position": "left"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Услуга" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _unref(model).uslugaId,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).uslugaId) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_unref(model).form = null))
                    ],
                    class: "w-100",
                    disabled: !_unref(isNew)
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(request).data.uslugi, (usluga) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: usluga.id,
                          value: usluga.id,
                          label: usluga.descr
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              (_unref(selectedUsluga))
                ? (_openBlock(), _createBlock(MyForm, {
                    key: 0,
                    form: _unref(selectedUsluga).form,
                    modelValue: _unref(model).form,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).form) = $event)),
                    "editable-only-update-allowed": !_unref(isNew)
                  }, null, 8, ["form", "modelValue", "editable-only-update-allowed"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["request"]))
}
}

})