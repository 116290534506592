import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import AbstractEntity from "~/ts/library/AbstractEntity";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import Prism from "~/cabinet/vue/interface/Prism.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import {computed} from "vue";
import {localeDateTimeString} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "WebhookLogDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    webhookId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

let props = __props;

let visible = _useModel<boolean>(__props, "visible");

let historyDialogRequest = computed(() => {
    return new ReactiveRequest(new LcabApiRequest({
        url: `/api/cabinet/webhooks/getHistory`,
        p: {
            id: props.webhookId
        }
    }, HistoryResponse));
});


class HistoryResponse extends AbstractEntity {
    logs: {
        id: number,
        dt: string
        requestBody: string,
        responseCode: number,
        responseDescr?: string,
        responseBody: string,
        responseStatusDescr?: string
    }[];
    limit: number;
}

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_unref(visible))
    ? (_openBlock(), _createBlock(MyDialog, {
        key: 0,
        visible: _unref(visible),
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: "История запросов",
        dialog: {
            class: `overflow right full-height-body`,
            width: `1000px`
        },
        "button-text": "",
        "cancel-button-text": "Закрыть"
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(historyDialogRequest).make()))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Обновить ")
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(ReactiveRequestLoader, { request: _unref(historyDialogRequest) }, {
            default: _withCtx(() => [
              (_unref(historyDialogRequest).data)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_unref(historyDialogRequest).data.logs.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(HelpBlock, null, {
                            default: _withCtx(() => [
                              _cache[2] || (_cache[2] = _createTextVNode(" Отображаются последние ")),
                              _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(historyDialogRequest).data.limit), 1),
                              _cache[3] || (_cache[3] = _createTextVNode(" запросов. "))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_row, { class: "el-row-root mt-4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(historyDialogRequest).data.logs, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_card, {
                                      key: item.id
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(ElCardHeader, { "button-col-span": 12 }, {
                                          title: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_2, [
                                              _createVNode(FontAwesomeIcon, {
                                                bold: "",
                                                icon: "circle",
                                                class: _normalizeClass((item.responseCode === 200 ? 'text-success' : 'text-danger') + ' mr-2')
                                              }, null, 8, ["class"]),
                                              _createTextVNode(" Код ответа: " + _toDisplayString(item.responseCode) + " ", 1),
                                              (item.responseStatusDescr)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createTextVNode(" (" + _toDisplayString(item.responseStatusDescr) + ") ", 1)
                                                  ], 64))
                                                : _createCommentVNode("", true)
                                            ])
                                          ]),
                                          default: _withCtx(() => [
                                            _createTextVNode(" " + _toDisplayString(_unref(localeDateTimeString)(item.dt, true)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        (item.responseCode !== 200 && item.responseStatusDescr != item.responseDescr)
                                          ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" Описание ошибки: " + _toDisplayString(item.responseDescr), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true),
                                        _createVNode(ElCardSection, null, {
                                          default: _withCtx(() => [
                                            _cache[4] || (_cache[4] = _createElementVNode("p", null, "Тело запроса", -1)),
                                            (item.requestBody.length < 100000)
                                              ? (_openBlock(), _createBlock(Prism, {
                                                  key: 0,
                                                  code: item.requestBody,
                                                  language: "javascript",
                                                  class: "pre"
                                                }, null, 8, ["code"]))
                                              : (_openBlock(), _createElementBlock("pre", _hoisted_3, _toDisplayString(item.requestBody), 1))
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(ElCardSection, null, {
                                          default: _withCtx(() => [
                                            _cache[5] || (_cache[5] = _createElementVNode("p", null, "Тело ответа", -1)),
                                            (item.responseBody.length < 100000)
                                              ? (_openBlock(), _createBlock(Prism, {
                                                  key: 0,
                                                  language: "html",
                                                  code: item.responseBody,
                                                  class: "pre"
                                                }, null, 8, ["code"]))
                                              : (_openBlock(), _createElementBlock("pre", _hoisted_4, _toDisplayString(item.responseBody), 1))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(" Запросы не найдены ")
                        ], 64))
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["request"])
        ]),
        _: 1
      }, 8, ["visible"]))
    : _createCommentVNode("", true)
}
}

})