import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import {SenderTimeRangeModelInterface} from './Interfaces';
import HelpIcon from "~/cabinet/vue/interface/form/HelpIcon.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {computed, ref, watch} from "vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {__} from "~/ts/library/Translate";
import MyTimePicker from "~/core-ui/vue/ui/MyTimePicker.vue";


const __default__ = {
    name: "SenderTimeRange"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    saveable: { type: Boolean, default: false }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let changed = ref(false);

let defaultTimeRange = computed(() => {
    let store = SenderUslugi[props.uslugaId].store;
    return store.timeRange;
});

function getDefaultValue() {
    let result = {
        enabled: false,
        range: ["10:00:00", "19:00:00"]
    };
    if (defaultTimeRange.value) {
        if (!defaultTimeRange.value.isAnyTime) {
            result.enabled = true;
            result.range = defaultTimeRange.value.range;
        }
    }
    return result;
}

let _modelValue = _useModel<SenderTimeRangeModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => getDefaultValue());

watch(defaultTimeRange, () => {
    let result = getDefaultValue();
    model.value.enabled = result.enabled;
    model.value.range = result.range;
});

watch(model, () => {
    changed.value = JSON.stringify(model.value) != JSON.stringify(getDefaultValue());
}, {deep: true});

function remember() {
    changed.value = false;
    LcabApiRequest.fetch({
        url: `/api/sender/${props.uslugaId}/saveDefaultTimeRange`,
        p: {
            timeRange: model.value
        },
        silentError: true,
        silent: true
    });
}


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        modelValue: _unref(model).enabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
        class: "mr-2"
      }, null, 8, ["modelValue"]),
      _cache[5] || (_cache[5] = _createTextVNode(" Разрешенное время отправки ")),
      (_ctx.saveable && _unref(changed))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[2] || (_cache[2] = _createTextVNode(" (")),
            _createElementVNode("a", {
              href: "#",
              class: "link-dashed",
              onClick: _withModifiers(remember, ["prevent"])
            }, "запомнить"),
            _cache[3] || (_cache[3] = _createTextVNode(") "))
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(HelpIcon, null, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Вы можете включить эту настройку для того, чтобы избежать отправки сообщения в нежелательное время (например - ночью) ")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { lg: 12 }, {
          default: _withCtx(() => [
            (_unref(model).enabled)
              ? (_openBlock(), _createBlock(MyTimePicker, {
                  key: 0,
                  "is-range": "",
                  class: "mt-2",
                  modelValue: _unref(model).range,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).range) = $event)),
                  "start-placeholder": _unref(__)('От'),
                  "end-placeholder": _unref(__)('До'),
                  clearable: false
                }, null, 8, ["modelValue", "start-placeholder", "end-placeholder"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})