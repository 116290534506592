import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ITextUnderImageBlockValues, {
    ITextUnderImageBlockColumn
} from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/ITextUnderImageBlockValues";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import StructureBlockTypeHelper
    from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureBlockTypeHelper";
import StructureDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureDisplay.vue";
import {HTML_BLOCK_TYPE_IMAGE, HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, provide} from "vue";
import {ImagesDontResizableInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "TextUnderImageDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockDisplay(props);

let _modelValue = _useModel<ITextUnderImageBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

provide(ImagesDontResizableInject, true);

function getBlocks(column: ITextUnderImageBlockColumn): IHtmlConstructorBlock<any>[] {
    return [
        {
            type: HTML_BLOCK_TYPE_IMAGE,
            values: column
        },
        {
            type: HTML_BLOCK_TYPE_TEXT,
            values: column
        }
    ];
}

let structure = computed<IStructureBlockValues>(() => {
    let columns = [];
    let blockLists: IHtmlConstructorBlock<any>[][] = [];
    for (let column of model.value.columns) {
        columns.push(100 / columnIndexes.value.length);
        blockLists.push(getBlocks(column));
    }
    return {
        columns: StructureBlockTypeHelper.getColumnsValue(columns),
        blockLists: blockLists,
        style: null,
        selfStyle: null
    }
});

let columnIndexes = computed(() => {
    return model.value.columns.map((_value, index) => index);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(StructureDisplay, {
    modelValue: _unref(structure),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(structure) ? (structure).value = $event : structure = $event)),
    "blocks-list-editable": false,
    styles: _ctx.styles
  }, null, 8, ["modelValue", "styles"]))
}
}

})