import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "open-api-schema-viewer" }

import {computed, getCurrentInstance, onMounted, watch} from 'vue';
import {EventManagerInstance} from "~/cabinet/ts/Constant";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";



let firstTime = true;
let loaded = false;
const EVENT_ID = "redocLoaded";

const __default__ = {
    name: "OpenApiSchemaViewer"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    schema: {}
  },
  emits: ["init"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

function drawIfAvailable() {
    if (!loaded) {
        addDisposableEvent(EventManagerInstance.once(EVENT_ID, () => redraw()));
        if (firstTime) {
            firstTime = false;
            let script = document.createElement('script');
            script.src = '/cabinet/assets/scripts/redoc.js';
            script.async = false; // optionally
            script.onload = () => {
                loaded = true;
                EventManagerInstance.emit(EVENT_ID);
            };
            document.head.appendChild(script);
        }
    } else {
        redraw();
    }
}

onMounted(() => drawIfAvailable());

let specification = computed(() => {
    return props.schema;
});

watch(specification, () => drawIfAvailable(), {deep: true});

let instance = getCurrentInstance().proxy;

function redraw() {
    var Redoc = (window as any).Redoc;

    Redoc.init(specification.value, {
        noAutoAuth: true,
        expandResponses: '200',
        requiredPropsFirst: true,
        pathInMiddlePanel: false,
        hideDownloadButton: true,
        jsonSampleExpandLevel: 10,
        expandSingleSchemaField: true
    }, instance.$el, () => {
        emits("init");
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})