import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import {computed} from 'vue';
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import AbstractEntity from "~/ts/library/AbstractEntity";
import {DOC_DETAILS_ROUTE} from "~/cabinet/ts/routes/ClientRoutesBuilder";
import NeedContractInfoInterface from "~/cabinet/vue/client/documents/contract/NeedContractInfoInterface";
import NeedInfoCard from "~/cabinet/vue/client/documents/contract/NeedInfoCard.vue";
import {RouteLocationNamedRaw, RouteLocationRaw, useRoute} from "vue-router";


const __default__ = {
    name: "ContractInfoCard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    allUslugi: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let route = useRoute();

let uslugaId = computed(() => {
    let item = route.matched.find(item => item.meta && (item.meta as IRouteMeta).uslugaId);
    if (item) {
        return (item.meta as IRouteMeta).uslugaId;
    }
    return null;
});

let request = computed(() => {
    if (uslugaId.value || props.allUslugi) {
        return new ReactiveRequest(new LcabApiRequest({
            url: `/api/documents/contracts/getInfo`,
            p: {
                uslugaId: uslugaId.value
            }
        }, GetInfoResponse));
    }
    return null;
});

let detailsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: DOC_DETAILS_ROUTE
    };
});


class GetInfoResponse extends AbstractEntity {
    isNeedDetails?: boolean;
    infoList?: NeedContractInfoInterface[]
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_unref(request) && _unref(request).data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(request).data.isNeedDetails)
          ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(ElCardSectionWithIcon, {
                  "gray-label": false,
                  icon: "file-alt",
                  "icon-color": "red",
                  value: "Необходимо заполнить реквизиты для заключения договора"
                }, {
                  label: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createTextVNode(" Перейдите в раздел ")),
                    _createVNode(_component_router_link, {
                      to: _unref(detailsLocation),
                      textContent: 'Документы'
                    }, null, 8, ["to"]),
                    _cache[2] || (_cache[2] = _createTextVNode(" и заполните реквизиты вашей организации. "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_unref(request).data.infoList)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(request).data.infoList, (card, key) => {
              return (_openBlock(), _createBlock(NeedInfoCard, {
                key: key,
                "need-info": card,
                onReload: _cache[0] || (_cache[0] = ($event: any) => (_unref(request).make()))
              }, null, 8, ["need-info"]))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})