import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_2 = {
  key: 1,
  class: "text-danger"
}

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {
    OnlineChatRunExternalBotActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    useOnlineChatRunExternalBot
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotHelper";
import ActionBlockPreviewItem from "~/cabinet/vue/client/events/action/types/ActionBlockPreviewItem.vue";
import {computed, nextTick, onUpdated, watch} from "vue";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION,
    RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/Interface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "OnlineChatRunExternalBotBlockPreview"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<OnlineChatRunExternalBotActionDescription>()
    },
  emits: ["redraw"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;


let selectedAuth = computed(() => {
    return useOnlineChatRunExternalBot(props.params).selectedAuth;
});

let isAuthConfigured = computed(() => selectedAuth.value.value && typeof selectedAuth.value.value.descr == 'string');

watch(isAuthConfigured, () => {
    nextTick(() => emits('redraw'));
});

onUpdated(() => {
    emits('redraw');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ActionBlockPreviewItem, { label: "Интеграция" }, {
      default: _withCtx(() => [
        (_unref(isAuthConfigured))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(selectedAuth).value.descr) + " / ", 1),
              (_unref(selectedAuth).value.platformDescr)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(selectedAuth).value.platformDescr), 1))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_unref(selectedAuth).value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Удалена ")
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Не выбрана ")
                  ], 64))
            ]))
      ]),
      _: 1
    }),
    (_unref(isAuthConfigured))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(ActionBlockPreviewItem, { label: "Способ запуска" }, {
            default: _withCtx(() => [
              (_unref(props).params.startMode === _unref(RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Бот сразу получает вопрос ")
                  ], 64))
                : (_unref(props).params.startMode === _unref(RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" Бот сначала задает вопрос ")
                    ], 64))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(ActionBlockPreviewItem, { label: "Стартовое сообщение" }, {
            default: _withCtx(() => [
              _createVNode(TextInputWithVariables, {
                "content-variables": _ctx.contentVariables,
                disabled: "",
                "model-value": _unref(props).params.startMode === _unref(RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE) ? _unref(props).params.initialTextMessageForBot : _unref(props).params.askQuestionTextMessageForBot
              }, null, 8, ["content-variables", "model-value"])
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})