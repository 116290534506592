import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icon" }

import {ref} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {
    HTML_CONSTRUCTOR_DRAG_GROUP,
} from "~/cabinet/vue/interface/htmlConstructor/Constants";
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {useSortable} from "@vueuse/integrations";


import Draggable from "~/node_modules/vuedraggable/src/vuedraggable.js";

const __default__ = {
    name: "HtmlConstructorBlockType",
    components: {
        Draggable
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    type: {}
  },
  setup(__props: any) {

let props = __props;

let button = ref<HTMLDivElement>();


const group = HTML_CONSTRUCTOR_DRAG_GROUP;

let list = ref([props.type]);
useSortable(button, list, {
    group: {
        name: group,
        put: false,
        pull: 'clone',
        revertClone: true
    }
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "button",
    ref: button
  }, [
    _createVNode(_component_el_button, {
      "data-id": _ctx.type.id,
      class: "d-block ml-0 mr-0 w-100 text-center h-auto"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(FontAwesomeIcon, {
              icon: _ctx.type.icon
            }, null, 8, ["icon"])
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.type.descr), 1)
        ])
      ]),
      _: 1
    }, 8, ["data-id"])
  ], 512))
}
}

})