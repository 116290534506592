import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import ITextUnderImageBlockValues, {
    ITextUnderImageBlockColumn
} from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/ITextUnderImageBlockValues";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {HTML_BLOCK_TYPE_IMAGE_UNDER_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {ref} from "vue";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";


const __default__ = {
    name: "ImageUnderTextForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {htmlConstructorEditor} = useBlockForm(props);

let _modelValue = _useModel<ITextUnderImageBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);


let removed = ref<ITextUnderImageBlockColumn[]>([]);


function getDefaultColumn() {
    let defaultValue: ITextUnderImageBlockValues = htmlConstructorEditor.params
        .blockTypesDictionary[HTML_BLOCK_TYPE_IMAGE_UNDER_TEXT]
        .defaultValue;
    return ObjectHelper.jsonClone(defaultValue.columns[0]);
}

function getNewColumn(): ITextUnderImageBlockColumn {
    if (removed.value.length) {
        return removed.value.splice(0, 1)[0];
    } else {
        return getDefaultColumn();
    }
}

function onColumnsChange(count: number) {
    let columns = model.value.columns;
    let currentCount = columns.length;
    if (currentCount > count) {
        let defaultColumn = JSON.stringify(getDefaultColumn());
        let _removed = columns.splice(count, currentCount - count);
        for (let column of _removed) {
            if (JSON.stringify(column) != defaultColumn) {
                removed.value.push(column);
            }
        }

    } else if (currentCount < count) {
        while (columns.length < count) {
            columns.push(getNewColumn());
        }
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Количество столбцов" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            "model-value": _unref(model).columns.length,
            "onUpdate:modelValue": onColumnsChange
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([2, 3, 4], (item) => {
                return _createVNode(_component_el_radio_button, {
                  key: item,
                  value: item
                }, null, 8, ["value"])
              }), 64))
            ]),
            _: 1
          }, 8, ["model-value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})