import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-filter-tab-pane-filters" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "text-muted mt-2 mb-2"
}
const _hoisted_4 = {
  key: 2,
  class: "my-filter-tab-pane-add-filter"
}
const _hoisted_5 = {
  key: 0,
  class: "mt-2 mb-2"
}
const _hoisted_6 = ["textContent"]

import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue';
import {
    FilterModel,
    FiltersModel,
    IFilter,
    IFilterPreset,
    IFiltersData,
    IOperation
} from "./Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import {useLayoutUpdate} from "~/ts/vuePlugins/LayoutUpdateMixin";
import {__} from "~/ts/library/Translate";
import MyFilterRows from "~/cabinet/vue/interface/filter/MyFilterRows.vue";
import MyFilterAdd from "~/cabinet/vue/interface/filter/MyFilterAdd.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElPrompt from "~/core-ui/vue/ui/ElPrompt";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import ArrayHelper from "~/ts/library/ArrayHelper";
import {MessageBoxInputData} from "element-plus";
import {mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyFilterTabPane"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    filtersData: {},
    emptyFiltersString: { default: __("Фильтры не заданы") },
    showButtons: { type: Boolean, default: true },
    preset: {},
    addButtonText: {},
    realTimeInput: { type: Boolean },
    showFilterDescr: { type: Boolean, default: true },
    isRoot: { type: Boolean, default: true }
  }, {
    "modelValue": {
    default: {}
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<FiltersModel>(__props, "modelValue");

useLayoutUpdate();

let localValue = ref<FiltersModel>(
    makeLocalValue(model.value)
);

let localSingleValue = computed<FilterModel>(() => Array.isArray(localValue.value) ? {} : localValue.value);

let operationsAvailableForAdd = ref<Dictionary<string[]>>({});


watch(model, newValue => {
    if (JSON.stringify(newValue) != JSON.stringify(localValue.value)) {
        localValue.value = makeLocalValue(newValue);
    }
})

watch(localValue, () => {
    if (props.realTimeInput) {
        applyFilters();
    }
}, {deep: true});


onMounted(() => {
    if (props.realTimeInput) {
        applyFilters();
    }
});

function applyFilters() {
    model.value = ObjectHelper.jsonClone(localValue.value);
}

function makeLocalValue(value: any) {
    return MyFilterHelper.normalizeValue(ObjectHelper.jsonClone(value), props.filtersData);
}

function getAvailableOperations(filter: IFilter): string[] {
    let result = filter.allowedOperations;
    let availableOperations = operationsAvailableForAdd.value[filter.id];

    if (localSingleValue.value[filter.id]) {
        if (availableOperations == null) {
            operationsAvailableForAdd.value[filter.id] = [];
            availableOperations = operationsAvailableForAdd.value[filter.id];
        }
        result = availableOperations;
    }
    return result;
}

function isFilterAvailableToAdd(filter: IFilter): boolean {
    return getAvailableOperations(filter).length > 0;
}

function isOperationUsed(filterId: string, operationId: string) {
    return localSingleValue.value[filterId] && localSingleValue.value[filterId][operationId] != null;
}

function filterById(filterId: string): IFilter | null {
    return props.filtersData.filters[filterId] ? props.filtersData.filters[filterId] : null;
}

function addFilter(filter: IFilter, operationId?: string) {
    if (localSingleValue.value[filter.id] == null) {
        localSingleValue.value[filter.id] = {};
    }

    operationId = operationId ? operationId : getFirstUnusedOperationId(filter);

    localSingleValue.value[filter.id][operationId]  = getDefaultValueArray(filter);
}

function deleteOperation(filterId: string, operationId: string) {
    delete localSingleValue.value[filterId][operationId];
    if (!localSingleValue.value[filterId]) {
        delete operationsAvailableForAdd.value[filterId];
    }
}

function getDefaultValueArray(filter?: IFilter): any[] {
    let result = [];
    if (filter && Array.isArray(filter.sortedOptions) && filter.sortedOptions[0]) {
        if (!filter.multiple) {
            result.push(filter.sortedOptions[0].value);
        }
    }
    return result;
}

function operationById(operationId: string): IOperation | null {
    return MyFilterHelper.getOperations(props.filtersData)[operationId];
}

function isDropValuesNeeded(toOperation: IOperation, fromOperation: IOperation): boolean {
    return (
        JSON.stringify(toOperation.filterInputs) != JSON.stringify(fromOperation.filterInputs)
        ||
        (JSON.stringify(toOperation.params) != JSON.stringify(fromOperation.params))
    );
}

function operationChanged(filterId: string, to: string, from: string) {
    let operationsList = localSingleValue.value[filterId];

    let toOperation = operationById(to);
    let fromOperation = operationById(from);
    if (toOperation && fromOperation) {
        if (isDropValuesNeeded(toOperation, fromOperation)) {
            //если набор инпутов отличается, надо дропнуть значение
            localSingleValue.value[filterId][from] = getDefaultValueArray(filterById(filterId));
        }
        let newOperationList: Dictionary<string[]> = {};
        for (let operationId in operationsList) {
            if (operationsList.hasOwnProperty(operationId)) {
                newOperationList[operationId == from ? to : operationId] = operationsList[operationId];
            }
        }
        localSingleValue.value[filterId] =newOperationList;
    }
}

let isEmpty = computed(() => {
    let result = true;
    for (let filterId in localSingleValue.value) {
        if (localSingleValue.value.hasOwnProperty(filterId)) {
            let filter = filterById(filterId);
            if (filter) {
                result = false;
                break;
            }
        }
    }
    return result;
});

let instance = getCurrentInstance().proxy;

async function savePreset() {
    try {
        let {value} = (await ElPrompt(__("Введите название фильтра"), __("Добавление фильтров"), {
            confirmButtonText: __("Продолжить"),
            cancelButtonText: __("Отмена"),
            inputValue: props.preset ? props.preset.descr : null,
            inputValidator: (value?: string) => {
                return value != null && value.length > 0;
            },
            inputErrorMessage: __("Введите название фильтра")
        })) as MessageBoxInputData;

        let result = await LcabApiRequest.save({
            url: "/api/ui/filter/savePreset",
            p: {
                cc: props.preset ? props.preset.cc : null,
                descr: value,
                value: localSingleValue.value,
                filterId: props.filtersData.preset.id
            },
            vue: instance
        });
        if (result.isSuccess) {
            props.filtersData.preset = result.getData("preset");
        } else {
            result.showMessage();
        }
    } catch (e) {

    }

}

function getFirstUnusedOperationId(filter: IFilter): string | null {
    let operations = operationsAvailableForAdd.value[filter.id];
    if (operations == null || !operations.length) {
        operations = filter.allowedOperations;
    }
    for (let i = 0; i < operations.length; i++) {
        let operationId = operations[i];
        if (!isOperationUsed(filter.id, operationId)) {
            return operationId;
        }
    }
    return null;
}

let filtersAvailableToAdd = computed(() => {
    let result: IFilter[] = [];
    let filters = props.filtersData.filters;
    for (let filterId in filters) {
        if (filters.hasOwnProperty(filterId)) {
            let filter = filters[filterId];
            if (isFilterAvailableToAdd(filter)) {
                result.push(filter);
            }
        }
    }
    return result;
});

let isOrModeEnabled = computed(() => Array.isArray(localValue.value));

let isOrAvailable = computed(() => {
    let result = false;
    if (props.filtersData.isOrAvailable) {
        if (props.isRoot) {
            result = Array.isArray(localValue.value) || ObjectHelper.hasKeys(localValue.value);
        }
    }
    return result;
})

function addOrSection() {
    if (!Array.isArray(localValue.value)) {
        localValue.value = [localValue.value];
    }
    localValue.value.push({});
}

async function removeOrGroup(key: number) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить эту группу фильтров?");
        if (Array.isArray(localValue.value)) {
            localValue.value.splice(key, 1);
            if (localValue.value.length == 1) {
                localValue.value = localValue.value[0];
            }
        }
    } catch (e) {
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_my_filter_tab_pane = _resolveComponent("my-filter-tab-pane", true)!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_unref(isOrModeEnabled))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_unref(isEmpty))
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Object.keys(_unref(localValue)), (filterId) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: filterId }, [
                    (filterById(filterId))
                      ? (_openBlock(), _createBlock(MyFilterRows, {
                          key: 0,
                          filter: filterById(filterId),
                          "filters-data": _ctx.filtersData,
                          "show-filter-descr": _ctx.showFilterDescr,
                          modelValue: _unref(localValue)[filterId],
                          "onUpdate:modelValue": ($event: any) => ((_unref(localValue)[filterId]) = $event),
                          onOperationsForAdd: ($event: any) => (_unref(operationsAvailableForAdd)[filterId] = $event),
                          onOperationChanged: ($event: any) => (operationChanged(filterId, $event.newOperation, $event.oldOperation)),
                          onAddOperation: ($event: any) => (addFilter(filterById(filterId), $event)),
                          onDeleteOperation: ($event: any) => (deleteOperation(filterId, $event)),
                          onDeleteFilter: ($event: any) => (delete _unref(localValue)[filterId])
                        }, null, 8, ["filter", "filters-data", "show-filter-descr", "modelValue", "onUpdate:modelValue", "onOperationsForAdd", "onOperationChanged", "onAddOperation", "onDeleteOperation", "onDeleteFilter"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.emptyFiltersString), 1))
          ], 64))
        : (Array.isArray(_unref(localValue)))
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(ArrayHelper).keys(_unref(localValue)), (key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: "mb-2"
              }, [
                (key > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(__)('Или').toLowerCase()), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_card, { class: "transparent-dashed-card p-3 mb-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_my_filter_tab_pane, _mergeProps({ ref_for: true }, _unref(props), {
                      "is-root": false,
                      modelValue: _unref(localValue)[key],
                      "onUpdate:modelValue": ($event: any) => ((_unref(localValue)[key]) = $event),
                      "real-time-input": ""
                    }), {
                      "after-add-button": _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "danger",
                          plain: "",
                          round: "",
                          onClick: ($event: any) => (removeOrGroup(key))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, { icon: "times" })
                          ]),
                          default: _withCtx(() => [
                            _cache[1] || (_cache[1] = _createTextVNode(" Удалить фильтры "))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          : _createCommentVNode("", true),
      (_ctx.showButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_unref(isOrAvailable))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("a", {
                    class: "link-dashed",
                    textContent: _toDisplayString(`${_unref(__)('Или')}...`),
                    tabindex: "",
                    onClick: addOrSection
                  }, null, 8, _hoisted_6)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              (_unref(filtersAvailableToAdd).length > 0 && !_unref(isOrModeEnabled))
                ? (_openBlock(), _createBlock(MyFilterAdd, {
                    key: 0,
                    "available-filters": _unref(filtersAvailableToAdd),
                    "filters-data": _ctx.filtersData,
                    onAddFilter: _cache[0] || (_cache[0] = ($event: any) => (addFilter($event))),
                    "button-text": _ctx.addButtonText,
                    class: "mr-1"
                  }, null, 8, ["available-filters", "filters-data", "button-text"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "after-add-button"),
              (_ctx.isRoot)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (!_ctx.realTimeInput)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                          type: "primary",
                          round: "",
                          onClick: applyFilters
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(FontAwesomeIcon, { icon: "check" })
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode(" " + _toDisplayString(_unref(__)("Применить")), 1)
                          ]),
                          _: 1
                        }, 8, ["class"]))
                      : _createCommentVNode("", true),
                    (!_unref(isEmpty) && _ctx.filtersData.preset)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 1,
                          class: _normalizeClass('float-md-right ' + _unref(mobileFullWidthButtonClass)),
                          plain: "",
                          round: "",
                          onClick: savePreset
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode(" Сохранить ")
                          ])),
                          _: 1
                        }, 8, ["class"]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})