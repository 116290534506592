import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"


const __default__ = {
    name: "MyValue"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    color: {},
    label: {},
    valueTag: { default: 'h4' },
    xl: {},
    lg: {},
    md: {},
    sm: {},
    xs: {},
    span: {},
    fullHeightLabel: { type: Boolean },
    uppercase: { type: Boolean, default: true },
    fontWeightNormal: { type: Boolean },
    right: { type: Boolean },
    uppercaseLabel: { type: Boolean, default: true },
    clickable: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _directive_on_native = _resolveDirective("on-native")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_col, _mergeProps(_ctx.$props, {
    class: {
            'text-center': true,
            'my-value': true,
            'clickable': !!_ctx.clickable,
            'pl-1 pr-1': true,
            'right': _ctx.right
        }
  }), {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.valueTag), {
        class: _normalizeClass(['value', _ctx.uppercase ? 'text-uppercase' : null, _ctx.color ? 'text-' + _ctx.color : '', _ctx.fontWeightNormal ? 'font-weight-normal' : null])
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class"])),
      (_ctx.label != null || _ctx.$slots.label)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({
                'label text-muted font-size-micro break-word': true,
                'h-100': _ctx.fullHeightLabel,
                'text-uppercase': _ctx.uppercaseLabel
            })
          }, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            _renderSlot(_ctx.$slots, "label")
          ], 2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "after-label")
    ]),
    _: 3
  }, 16, ["class"])), [
    [
      _directive_on_native,
      () => _unref(emits)('click'),
      "click",
      {
        stop: true,
        prevent: true
      }
    ]
  ])
}
}

})