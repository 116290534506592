import ButtonDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/button/ButtonDisplay.vue";
import ButtonForm from "~/cabinet/vue/interface/htmlConstructor/blocks/button/ButtonForm.vue";
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IButtonBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/button/IButtonBlockValues";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class ButtonBlockType extends AbstractBlockType<IButtonBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(ButtonDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(ButtonForm);
    }




}
