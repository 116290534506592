import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {computed} from "vue";

interface ArticleRequestInterface {
    html: string;
    title: string;
    baseHref: string
}

const __default__ = {
    name: "PreviewKbaseArticle"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    knowledgeBaseRegisterId: {},
    knowledgeBaseArticleHistoryId: {},
    asDialog: { type: Boolean, default: true },
    ignoredBlocks: { default: () => ([
        "contents"
    ]) }
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: ["update:visible"],
  setup(__props: any) {

let props = __props;

let visible = _useModel<boolean>(__props, "visible");

let request = computed(() => {
    return new ReactiveRequest<ArticleRequestInterface>(new LcabApiRequest({
        url: `/api/ui/knowledgeBase/getArticle/${props.knowledgeBaseRegisterId}/${props.knowledgeBaseArticleHistoryId}`,
        p: {
            ignoredBlocks: props.ignoredBlocks
        }
    }));
});

let articleHtml = computed(() => {
    let result: string;
    if (request.value.data) {
        result = request.value.data.html;
        let baseHref = request.value.data.baseHref;
        if (baseHref) {
            let div = document.createElement("div");
            div.innerHTML = result;
            let links = div.querySelectorAll("a[href^='/']");
            for (let i = 0; i < links.length; i++) {
                let link = links[i];
                link.setAttribute("href", baseHref + link.getAttribute("href"));
            }

            links = div.querySelectorAll("a[href]");
            for (let i = 0; i < links.length; i++) {
                links[i].setAttribute("target", "_blank");
            }
            result = div.innerHTML;
        }
    }
    return result;
})


return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.asDialog)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: _unref(request).data ? _unref(request).data.title : 'Идет загрузка...',
        class: "right"
      }, {
        default: _withCtx(() => [
          _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
            default: _withCtx(() => [
              (_unref(request).data)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    innerHTML: _unref(articleHtml)
                  }, null, 8, _hoisted_1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["request"])
        ]),
        _: 1
      }, 8, ["modelValue", "title"]))
    : (_openBlock(), _createBlock(ReactiveRequestLoader, {
        key: 1,
        request: _unref(request)
      }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _unref(articleHtml)
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"]))
}
}

})