import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import {reactive} from "vue";

let isFullyLoaded = false;

let observable = reactive({count: 0});

export default class ApiCallback {
    private static getEventName(event: string) {
        return "apiCallback/" + event;
    }

    public static hasListeners(event: string) {
        return observable.count && ChatEventManagerInstance.hasListeners(this.getEventName(event));
    }

    public static setCallback(event: string, callback: (payload?: any) => void, once: boolean = false) {
        let eventName = ApiCallback.getEventName(event);
        observable.count++;

        if (event == API_CALLBACK_FULLY_LOADED && isFullyLoaded) {
            callback();
            if (once) {
                return;
            }
        }

        if (once) {
            ChatEventManagerInstance.once(eventName, callback);
        } else {
            ChatEventManagerInstance.addEventListener(eventName, callback);
        }
    }


    public static emit(event: string, payload?: any) {
        if (event == API_CALLBACK_FULLY_LOADED) {
            isFullyLoaded = true;
        }
        ChatEventManagerInstance.emit(this.getEventName(event), payload);
    }
}

export const API_CALLBACK_ADDITIONAL_FORM_SENDED = "clientMakeSubscribe";
export const API_CALLBACK_GET_CONTACTS = "getContacts";
export const API_CALLBACK_CONTACTS_UPDATED = "contactsUpdated";
export const API_CALLBACK_OPEN_MENU_LINK = "openMenuLink";
export const API_CALLBACK_UNREADED_MESSAGES_COUNT = "unreadedMessagesCountUpdated";
export const API_CALLBACK_FULLY_LOADED = "fullyLoaded";
export const API_CALLBACK_OPEN_SUPPORT = "openSupport";
export const API_CALLBACK_CLOSE_SUPPORT = "closeSupport";
export const API_CALLBACK_CLIENT_SEND_MESSAGE = "clientSendMessage";
export const API_CALLBACK_OPERATOR_SEND_MESSAGE = "operatorSendMessage";
export const API_CALLBACK_SEND_RATE = "sendRate";

export interface IApiCallbackClientSendMessagePayload {
    text: string,
    //TODO: убрать
    /** @deprecated */
    operator?: string,
    operatorId?: string,
    firstMessage: boolean
}