import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


const __default__ = {
    name: "MyTransitionSlideX"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    mode: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "transition-slide-x",
    tag: "span",
    mode: _ctx.mode
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["mode"]))
}
}

})