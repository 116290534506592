import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import {
    IField,
    IForm,
    MyFormExposeInterface,
    MyFormSectionExposeInterface
} from "~/cabinet/vue/interface/form/elements/Interfaces";
import MyFormSection from "./MyFormSection.vue";
import FormHelper, {MyFormGetValuesInjection} from "~/cabinet/vue/interface/form/elements/FormHelper";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, getCurrentInstance, provide} from "vue";


import {defineComponent} from "vue";

const __default__ = defineComponent({
    name: "MyForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    form: {},
    preview: { type: Boolean, default: false },
    editableOnlyUpdateAllowed: { type: Boolean, default: false },
    contentVariables: {},
    returnWithoutDisabledOptionalElements: { type: Boolean },
    textPreview: { type: Boolean },
    showLabels: { type: Boolean, default: true },
    withoutBorder: { type: Boolean },
    clearable: { type: Boolean },
    autoEmitValueWhenEmpty: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["blur"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let _modelValue = _useModel<any>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({}));


let duplicateElementNames = computed(() => {
    return FormHelper.getDuplicateElementNames(props.form);
})

let allElements = computed<IField[]>(() => {
    let result = [];
    for (let section of props.form.sections) {
        result.push(...section.elements);
    }
    return result;
});

let instance = getCurrentInstance().proxy;


__expose<MyFormExposeInterface>({
    formToString: () => {
        let result = [];
        let key = 0;
        for (let section of props.form.sections) {
            let ref = (instance.$refs[`section_${key}`] as any)[0] as any as MyFormSectionExposeInterface;
            let value = ref.sectionToString();
            if (value.length) {
                if (section.descr) {
                    result.push(section.descr);
                }
                result.push(value);
                result.push("");
            }
            key++;
        }
        return result.join("\n");
    }
});

provide(MyFormGetValuesInjection, () => {
    return model.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
        'my-form': true,
        'hide-labels': !_ctx.showLabels
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.sections, (section, index) => {
      return (_openBlock(), _createBlock(MyFormSection, {
        ref_for: true,
        ref: `section_${index}`,
        key: index,
        "model-value": _unref(model),
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('blur', $event))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event)),
        descr: section.descr,
        elements: section.elements,
        "all-form-elements": _unref(allElements),
        "exclude-element-names": _unref(duplicateElementNames),
        preview: _ctx.preview,
        "text-preview": _ctx.textPreview,
        "content-variables": _ctx.contentVariables,
        "return-without-disabled-optional-elements": _ctx.returnWithoutDisabledOptionalElements,
        "editable-only-update-allowed": _ctx.editableOnlyUpdateAllowed,
        "without-border": _ctx.withoutBorder,
        clearable: _ctx.clearable,
        "auto-emit-value-when-empty": _ctx.autoEmitValueWhenEmpty
      }, null, 8, ["model-value", "descr", "elements", "all-form-elements", "exclude-element-names", "preview", "text-preview", "content-variables", "return-without-disabled-optional-elements", "editable-only-update-allowed", "without-border", "clearable", "auto-emit-value-when-empty"]))
    }), 128))
  ], 2))
}
}

})