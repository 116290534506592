import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import H1Display from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1Display.vue";
import H1Form from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1Form.vue";
import IH1BlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/IH1BlockValues";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class H1BlockType extends AbstractBlockType<IH1BlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(H1Display);
    }

    get formComponent(): RawComponent {
        return useRawComponent(H1Form);
    }

    get isBlockWithoutPadding(): boolean {
        return true;
    }


}
