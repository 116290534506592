import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"word-break":"break-word"}
}

import {
    useSenderMessageContentProps
} from "~/cabinet/vue/client/sender/report/parts/useSenderMessageContent";
import {computed} from "vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";


const __default__ = {
    name: "MessageContent"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useSenderMessageContentProps()
},
  setup(__props) {

let props = __props;

let contentComponent = computed(() => {
    return props.uslugaId ? SenderUslugi[props.uslugaId].report.contentComponent : null;
})

return (_ctx: any,_cache: any) => {
  return (!_unref(contentComponent))
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(typeof _ctx.message.content.preview == "string" ? _ctx.message.content.preview : _ctx.message.content.data), 1))
    : (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(contentComponent)), _normalizeProps(_mergeProps({ key: 1 }, _ctx.$props)), null, 16))
}
}

})