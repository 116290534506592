import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }

import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {
    OnlineChatForwardClientToOperatorActionDescription
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "OnlineChatForwardClientToOperatorForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<OnlineChatForwardClientToOperatorActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let operatorTypes = computed(() => {
    return props.actionDescription.frontendParams.operatorTypes;
});

let _modelValue = _useModel<getActionDescriptionModelType<OnlineChatForwardClientToOperatorActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    operatorsType: operatorTypes.value[0].value as string,
    operators: [],
    notifyToOperatorText: null,
    notifyToClientText: null,
    notifyToClientEnabled: true
}));

let operators = computed(() => {
    return props.actionDescription.frontendParams.operators;
});

let defaultForwardTextForClient = computed(() => {
    return props.actionDescription.frontendParams.defaultForwardTextForClient;
});

let contentVariablesForClientText = computed(() => {
    return props.actionDescription.frontendParams.forwardMessageContentVariables;
});


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "top",
    "label-width": "200px",
    model: _unref(model)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Оператор",
        rules: _unref(requiredRule),
        prop: "operators"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_select, {
              modelValue: _unref(model).operators,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).operators) = $event)),
              multiple: "",
              class: "w-100",
              placeholder: "Выберите оператора"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operators), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    value: item.value,
                    label: item.descr
                  }, null, 8, ["value", "label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          (_unref(model).operators.length > 1)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: _unref(model).operatorsType,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).operatorsType) = $event)),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operatorTypes), (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      value: item.value,
                      label: item.descr
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["rules"]),
      _createVNode(_component_el_form_item, { label: "Текст уведомления для оператора" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _unref(model).notifyToOperatorText,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).notifyToOperatorText) = $event)),
            placeholder: "Чат-бот переключил меня на вас"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Текст уведомления для клиента" }, {
        label: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_el_switch, {
              modelValue: _unref(model).notifyToClientEnabled,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).notifyToClientEnabled) = $event)),
              class: "mr-1"
            }, null, 8, ["modelValue"]),
            _cache[5] || (_cache[5] = _createTextVNode(" Текст уведомления для клиента "))
          ])
        ]),
        default: _withCtx(() => [
          (_unref(model).notifyToClientEnabled)
            ? (_openBlock(), _createBlock(TextInputWithVariables, {
                key: 0,
                modelValue: _unref(model).notifyToClientText,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).notifyToClientText) = $event)),
                "content-variables": _unref(contentVariablesForClientText),
                inline: false,
                placeholder: _unref(defaultForwardTextForClient),
                bbcode: ""
              }, null, 8, ["modelValue", "content-variables", "placeholder"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})