import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "header-button-label hidden-md-and-down ml-2"
}

import {computed} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadgeDisplay.vue";
import MenuBadgeObject from "~/cabinet/vue/interface/menu/MenuBadge";
import {RouteLocationRaw} from "vue-router";


const __default__ = {
    name: "HeaderIconButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    href: {},
    icon: {},
    iconColor: {},
    iconLight: { type: Boolean },
    badge: {},
    badgeType: {},
    label: {},
    to: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let isMenuBadgeObject = computed(() => {
    return props.badge instanceof MenuBadgeObject;
});


return (_ctx: any,_cache: any) => {
  const _component_el_badge = _resolveComponent("el-badge")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'a'), {
    href: _ctx.href,
    to: _ctx.to,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('click'))),
    style: {"text-decoration":"none"}
  }, {
    default: _withCtx(() => [
      (_unref(isMenuBadgeObject))
        ? (_openBlock(), _createBlock(MenuBadge, {
            key: 0,
            "menu-badge": _ctx.badge,
            "badge-type": _ctx.badgeType
          }, {
            default: _withCtx(() => [
              _createVNode(FontAwesomeIcon, {
                class: _normalizeClass(`header-button-icon text-${_ctx.iconColor}`),
                icon: _ctx.icon,
                light: _ctx.iconLight,
                bold: ""
              }, null, 8, ["class", "icon", "light"])
            ]),
            _: 1
          }, 8, ["menu-badge", "badge-type"]))
        : (_openBlock(), _createBlock(_component_el_badge, {
            key: 1,
            class: "header-button-badge",
            value: _ctx.badge,
            hidden: !_ctx.badge,
            type: _ctx.badgeType
          }, {
            default: _withCtx(() => [
              _createVNode(FontAwesomeIcon, {
                class: _normalizeClass(`header-button-icon text-${_ctx.iconColor}`),
                icon: _ctx.icon,
                light: _ctx.iconLight,
                bold: ""
              }, null, 8, ["class", "icon", "light"])
            ]),
            _: 1
          }, 8, ["value", "hidden", "type"])),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["href", "to"]))
}
}

})