import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";

const __default__ = {
    name: "HelpIcon",
    inheritAttrs: false
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, null, {
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default"),
        _renderSlot(_ctx.$slots, "content")
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(FontAwesomeIcon, _mergeProps(_ctx.$attrs, {
        color: "primary",
        icon: "question-circle"
      }), null, 16)
    ]),
    _: 3
  }))
}
}

})