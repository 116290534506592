import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 d-flex big-radio-flex-button" }
const _hoisted_2 = { class: "lh-primary" }

import ElCardSectionWithIconText from "~/cabinet/vue/interface/card/ElCardSectionWithIconText.vue";


const __default__ = {
    name: "BigRadioFlexButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    label: {},
    descr: {},
    extraDescr: {},
    disabled: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



let model = _useModel<any>(__props, "modelValue")


return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_radio, {
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      value: _ctx.label,
      border: "",
      class: _normalizeClass(['h-auto', 'd-flex', 'flex-grow-1', 'text-wrap', 'text-left', 'm-0']),
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(ElCardSectionWithIconText, null, _createSlots({
            heading: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.descr) + " ", 1),
              _renderSlot(_ctx.$slots, "descr")
            ]),
            _: 2
          }, [
            (_ctx.extraDescr || _ctx.$slots['extra-descr'])
              ? {
                  name: "label",
                  fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.extraDescr) + " ", 1),
                    _renderSlot(_ctx.$slots, "append-label"),
                    _renderSlot(_ctx.$slots, "extra-descr")
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1024)
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "value", "disabled"])
  ]))
}
}

})