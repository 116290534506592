import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

import MyFilterPreviewTag from "~/cabinet/vue/interface/filter/MyFilterPreviewTag.vue";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import {
    FilterModel,
    IFilter,
    IFiltersData,
    IOperation,
    RemoveFilterEventPayloadInterface
} from "~/cabinet/vue/interface/filter/Interfaces";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "MyFilterPreviewOrGroup"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    value: {},
    filtersData: {},
    editable: { type: Boolean },
    orGroupIndex: {}
  },
  emits: ["remove-all-filters", "remove-filter", "click", "update:search", "search-enter"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

function isFilterNeedToDisplay(filterId: string, operationId: string, values: any[]): boolean {
    let result = false;
    if (values.length > 0) {
        let filter = filterById(filterId);
        if (filter) {
            let operation = operationById(operationId);
            if (operation) {
                result = true;
                if (filter.combined && (!operation.filterInputs || operation.filterInputs.length > 0)) {
                    //Добавляю чтобы скрыть пустые значения combinedFilter
                    result = false;
                    for (let value of values) {
                        if (typeof value == "object" && ObjectHelper.hasKeys(value)) {
                            result = true;
                            break;
                        }
                    }
                }
            }
        }
    }
    return result;
}


function getWarningText(filterId: string, operationId: string, values: any[]): string {
    if (values.length > 0) {
        let filter = filterById(filterId);
        if (filter) {
            let operation = operationById(operationId);
            if (!operation) {
                return `${filter.descr}: неизвестная операция "${operationId}"`;
            }
        } else {
            return `Неизвестный фильтр "${filterId}"`;
        }
    }
    return null;
}

function filterById(filterId: string): IFilter | null {
    return props.filtersData.filters[filterId] ? props.filtersData.filters[filterId] : null;
}

function operationById(operationId: string): IOperation | null {
    return MyFilterHelper.getOperations(props.filtersData)[operationId];
}

function isRemovable(filter: IFilter) {
    let value = props.value[filter.id];
    return JSON.stringify(value) != JSON.stringify(filter.defaultValues);
}



return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (operationsList, filterId) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(operationsList, (values, operationId) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (isFilterNeedToDisplay(filterId, operationId, values) && operationById(operationId) != null)
              ? _withDirectives((_openBlock(), _createBlock(MyFilterPreviewTag, {
                  key: filterId + '_' + operationId,
                  values: values,
                  operation: operationById(operationId),
                  filter: filterById(filterId),
                  onClose: ($event: any) => (_unref(emits)('remove-filter', {filterId: filterId, operationId: operationId, orGroupIndex: _ctx.orGroupIndex })),
                  "filters-data": _ctx.filtersData,
                  editable: _ctx.editable && isRemovable(filterById(filterId))
                }, null, 8, ["values", "operation", "filter", "onClose", "filters-data", "editable"])), [
                  [_directive_on_native, () => _unref(emits)('click'), "click"]
                ])
              : (getWarningText(filterId, operationId, values))
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 1,
                    content: getWarningText(filterId, operationId, values)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyFilterPreviewTagTemplate, { type: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(getWarningText(filterId, operationId, values)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ], 64))
    }), 256))
  ]))
}
}

})