import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ISavedBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/ISavedBlockValues";
import ContentsBlockDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/ContentsBlockDisplay.vue";
import ContentsBlockForm from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/ContentsBlockForm.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class ContentsBlockType extends AbstractBlockType<ISavedBlockValues, any> {

    get displayComponent(): RawComponent {
        return useRawComponent(ContentsBlockDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(ContentsBlockForm);
    }

}
