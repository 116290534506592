import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import IHrBlockValues, {IHrBlockStyles} from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/IHrBlockValues";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "HrDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<IHrBlockStyles>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockDisplay(props);

let _modelValue = _useModel<IHrBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("hr", {
      style: _normalizeStyle({
                borderColor: _ctx.styles.bodyHrColor,
                borderWidth: `${_ctx.styles.bodyHrWidth}px`
            })
    }, null, 4)
  ]))
}
}

})