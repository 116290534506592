import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex h-100 align-items-center"
}
const _hoisted_2 = { class: "w-100 text-center text-muted" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 text-center"
}

import {computed, ref, watch} from 'vue';
import {IHtmlConstructorEditorModel, IHtmlConstructorParams} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import HtmlConstructorEditor from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorEditor.vue";
import HtmlConstructorParamsBuilder from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorParamsBuilder";
import ObjectHelper from "~/ts/library/ObjectHelper";
import LongContentPreview from "~/cabinet/vue/interface/LongContentPreview.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import IHtmlConstructorEditor from "~/cabinet/vue/interface/htmlConstructor/IHtmlConstructorEditor";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import HtmlConstructorPreview from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorPreview.vue";
import HtmlConstructorHelper from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorHelper";


const __default__ = {
    name: "HtmlConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    type: {},
    constructorId: {},
    contentVariables: {},
    editable: { type: Boolean, default: true },
    showEditDialog: { type: Boolean },
    firstTabLabel: {},
    applyButton: { default: "Продолжить" },
    previewBase: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["apply", "close"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<IHtmlConstructorEditorModel<any>>(__props, "modelValue");

let editor = ref<IHtmlConstructorEditor>();
let params = ref<IHtmlConstructorParams>();
let showPreviewDialog = ref(false);

let editDialog = ref<{
    visible: boolean,
    model: IHtmlConstructorEditorModel<any>
}>();

watch(computed(() => props.type), () => fetchParams());

async function fetchParams() {
    params.value = null;
    params.value = await HtmlConstructorParamsBuilder.fetchParams(props.type, props.contentVariables, props.constructorId);
}

watch(computed(() => {
    return [props.contentVariables, props.constructorId, props.type];
}), () => {
    fetchParams();
}, {deep: true});

function edit() {
    editDialog.value = {
        visible: true,
        model: ObjectHelper.jsonClone(model.value)
    };
}

let isEditDialogVisible = computed(() => {
    return editDialog.value && editDialog.value.visible;
});

watch(isEditDialogVisible, () => {
    if (!isEditDialogVisible.value) {
        emits("close");
    }
});

function saveBlock() {
    if (editor.value) {
        let block = HtmlConstructorHelper.wrapBlocksToStructureBlock(editDialog.value.model.blocks);
        editor.value.saveBlock(block);
    }
}


function closeDialog() {
    editDialog.value = null;
}

function onApply() {
    model.value = ObjectHelper.jsonClone(editDialog.value.model);
    if (!props.showEditDialog) {
        closeDialog();
    } else {
        emits("apply");
    }
}

fetchParams();
if (props.showEditDialog) {
    edit();
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(params))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            onClick: edit,
            class: "cursor-pointer"
          }, [
            _createVNode(LongContentPreview, {
              padding: false,
              height: _ctx.editable ? undefined : '100%'
            }, {
              default: _withCtx(() => [
                (_unref(model) && !_unref(editDialog))
                  ? (_openBlock(), _createBlock(HtmlConstructorEditor, {
                      key: 0,
                      "model-value": _unref(model),
                      params: _unref(params),
                      preview: ""
                    }, null, 8, ["model-value", "params"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_el_button, {
                          round: "",
                          plain: "",
                          type: "primary"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode("Открыть конструктор ")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
              ]),
              _: 1
            }, 8, ["height"]),
            (_unref(model) && _ctx.editable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_el_button, {
                    round: "",
                    plain: "",
                    type: "primary"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Открыть конструктор ")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          (_unref(editDialog) && _unref(editDialog).visible)
            ? (_openBlock(), _createBlock(_component_el_dialog, {
                key: 0,
                modelValue: _unref(editDialog).visible,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(editDialog).visible) = $event)),
                fullscreen: "",
                class: "full-height-body no-padding relative-body without-header",
                "show-close": false
              }, {
                footer: _withCtx(() => [
                  _createVNode(_component_el_row, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { md: 12 }, {
                        default: _withCtx(() => [
                          (!_unref(showPreviewDialog))
                            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                key: 0,
                                content: "Сохранить все текущие блоки для последующего использования в других шаблонах с помощью вставки сохраненных блоков"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    type: "primary",
                                    plain: "",
                                    onClick: saveBlock
                                  }, {
                                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                                      _createTextVNode(" Сохранить весь контент как блок ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_el_button, {
                            type: "primary",
                            plain: "",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(showPreviewDialog) //@ts-ignore
 ? showPreviewDialog.value = !_unref(showPreviewDialog) : showPreviewDialog = !_unref(showPreviewDialog)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(showPreviewDialog) ? 'Вернуться к редактированию' : 'Просмотр'), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (!_unref(showPreviewDialog))
                        ? (_openBlock(), _createBlock(_component_el_col, {
                            key: 0,
                            md: 12,
                            class: "text-md-right"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, { onClick: closeDialog }, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode(" Закрыть ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_el_button, {
                                type: "primary",
                                onClick: onApply
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.applyButton), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_unref(showPreviewDialog))
                    ? (_openBlock(), _createBlock(HtmlConstructorPreview, {
                        key: 0,
                        value: _unref(editDialog).model,
                        "type-id": _ctx.type,
                        "content-variables": _ctx.contentVariables,
                        base: _ctx.previewBase
                      }, null, 8, ["value", "type-id", "content-variables", "base"]))
                    : _createCommentVNode("", true),
                  _createVNode(HtmlConstructorEditor, {
                    modelValue: _unref(editDialog).model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(editDialog).model) = $event)),
                    ref_key: "editor",
                    ref: editor,
                    onApply: _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event)),
                    params: _unref(params),
                    "first-tab-label": _ctx.firstTabLabel,
                    style: _normalizeStyle({display: _unref(showPreviewDialog) ? 'none !important' : null})
                  }, {
                    "first-tab": _withCtx(() => [
                      _renderSlot(_ctx.$slots, "first-tab")
                    ]),
                    _: 3
                  }, 8, ["modelValue", "params", "first-tab-label", "style"])
                ]),
                _: 3
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createBlock(MyLoader, {
          key: 1,
          big: ""
        }))
  ]))
}
}

})