import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {HTML_BLOCK_TYPE_STRUCTURE} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {IHtmlConstructorParams} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "HtmlConstructorStyle"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    constructorParams: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let _modelValue = _useModel<any>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => null);

let blockTypes = computed(() => {
    return props.constructorParams.blockTypes;
});

let constructorType = computed(() => {
    return props.constructorParams.type;
});

let blockTypesWithStyle = computed(() => {
    return blockTypes.value
        .filter(type => !!type.styleForm)
        .sort((a, b) => {
            if (a.id == HTML_BLOCK_TYPE_STRUCTURE) {
                return -1;
            } else if (b.id == HTML_BLOCK_TYPE_STRUCTURE) {
                return 1;
            }
            return 0;
        });
});

let styleForms = computed(() => {
    let result: { icon?: string, descr: string, form: IForm }[] = [];
    if (constructorType.value.style.form) {
        result.push({
            icon: null,
            descr: constructorType.value.style.descr,
            form: constructorType.value.style.form
        });
    }
    for (let blockType of blockTypesWithStyle.value) {
        result.push({
            icon: blockType.iconForCommonStyles,
            descr: blockType.descrForCommonStyles,
            form: blockType.styleForm
        });
    }
    return result;
});


return (_ctx: any,_cache: any) => {
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(styleForms), (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mb-4"
      }, [
        _createElementVNode("h5", null, [
          (item.icon)
            ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                key: 0,
                icon: item.icon,
                class: "mr-2"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.descr), 1)
        ]),
        _createVNode(_component_el_form, {
          "label-position": "left",
          "label-width": "130px",
          size: "small",
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createVNode(MyForm, {
              modelValue: _unref(model),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
              form: item.form
            }, null, 8, ["modelValue", "form"])
          ]),
          _: 2
        }, 1024),
        (index < _unref(styleForms).length - 1)
          ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})