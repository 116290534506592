import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {NoteActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "DelayBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<NoteActionDescription>()
    },
  setup(__props) {

let props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _toDisplayString(_unref(props).params.text), 1))
}
}

})