import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: "done" }
const _hoisted_2 = { key: "wait" }

import {getCurrentInstance, ref} from 'vue';
import {copyToClipboard} from "~/ts/library/CopyToClipboard";
import Delay from "~/ts/library/Delay";
import {__} from "~/ts/library/Translate";
import {ElNotification} from 'element-plus';



const __default__ = {
    name: "CopyToClipboardButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    content: {}
  },
  setup(__props: any) {

let props = __props;
let copied = ref(false);

let instance = getCurrentInstance().proxy;

async function onClick() {
    let content = props.content;
    if (typeof content == "function") {
        content = content();
    }
    if (typeof content == "object") {
        content = JSON.stringify(content, null, 4);
    }
    if (instance.$slots.default) {
        navigator.clipboard.writeText(content).then(() => {
            ElNotification.success({
                title: __('Скопировано в буфер обмена'),
                duration: 2000,
                message: null
            });
        }, () => {
            ElNotification.error({
                title: __('Не удалось скопировать в буфер обмена'),
                duration: 2000,
                message: null
            });
        });
    } else {
        copyToClipboard(content);
        copied.value = true;
        try {
            await Delay.make(1000, "copyToClibpoardDone", true);
            copied.value = false;
        } catch (e) {

        }
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        onClick: onClick
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        type: "success",
        onClick: onClick
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: "zoom",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_unref(copied))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(__)("Скопировано")), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(__)("Скопировать в буфер")), 1))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}
}

})