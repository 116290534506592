import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IInfoFieldMixin} from "../Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "InfoElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let _modelValue = _useModel<IInfoFieldMixin>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    info: null
}));

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "Информационный текст" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        modelValue: _unref(model).info,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).info) = $event)),
        placeholder: "Введите текст"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})