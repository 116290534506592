import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-form-section"
}
const _hoisted_2 = {
  key: 0,
  class: "my-form-section-subtitle"
}

import {computed, getCurrentInstance, ref} from "vue";
import Dictionary from "~/ts/library/Dictionary";
import {IField, MyFormSectionExposeInterface} from "~/cabinet/vue/interface/form/elements/Interfaces";
import FormElementFactory from "~/cabinet/vue/interface/form/elements/FormElementFactory.vue";
import IFormChangeEventPayload from "~/cabinet/vue/interface/form/elements/IFormChangeEventPayload";
import {FORM_ELEMENT_TYPE_DICTIONARY} from "~/cabinet/vue/interface/form/elements/FormElementCollection";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "MyFormSection"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    elements: {},
    allFormElements: {},
    descr: {},
    excludeElementNames: { default: () => [] as string[] },
    preview: { type: Boolean },
    contentVariables: {},
    returnWithoutDisabledOptionalElements: { type: Boolean },
    editableOnlyUpdateAllowed: { type: Boolean, default: false },
    textPreview: { type: Boolean },
    withoutBorder: { type: Boolean },
    clearable: { type: Boolean },
    autoEmitValueWhenEmpty: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["change", "blur"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;


let emits = __emit

let _modelValue = _useModel<any>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => {
    let result: Dictionary<any> = {};
    if (!props.returnWithoutDisabledOptionalElements) {
        for (let field of props.elements) {
            result[field.name] = field.defaultValue;
        }
    }
    return result;
});

function isPreview(field: IField) {
    return props.preview || (props.editableOnlyUpdateAllowed && field.updateDisabled);
}

function emitChange(field: IField, value: any) {
    let payload: IFormChangeEventPayload = {
        field, value
    };
    emits("change", payload);
}

let displayedFields = computed(() => {
    return props.elements.filter(field => {
        let result = field && field.name != null && props.excludeElementNames.indexOf(field.name) == -1;
        if (result) {
            result = isFieldPassDisplayConditions(field);
        }
        return result;
    });
});

let mountedFields = computed(() => {
    return displayedFields.value.filter(field => isMustBeMounted(field));
})

function isFieldPassDisplayConditions(field: IField): boolean {
    let condition = field.displayCondition;
    let result = !condition;
    if (condition) {
        let modelValues = model.value[condition.fieldName];
        if (!Array.isArray(modelValues)) {
            modelValues = [modelValues];
        }
        for (let modelValue of modelValues) {
            if (condition.notNull) {
                if (typeof modelValue == "string") {
                    result = modelValue.trim().length > 0;
                } else {
                    result = modelValue != null;
                }
            } else {
                result = condition.values.includes(modelValue);
            }
            if (result) {
                let parentField = (props.allFormElements ? props.allFormElements : props.elements)
                    .find(field => field.name == condition.fieldName);
                if (parentField) {
                    result = isFieldPassDisplayConditions(parentField);
                } else {
                    result = false;
                }
                break;
            }
        }
    }
    return result;
}


function isHidden(field: IField): boolean {
    let type = FORM_ELEMENT_TYPE_DICTIONARY[field.type];
    return type && type.isHidden;
}


function isMustBeMounted(field: IField): boolean {
    return !(isPreview(field) && field.optional && (!model.value || model.value[field.name] == null));
}


let enabledOptionalElement = ref<Dictionary<boolean>>({});

function isOptionalElementOn(field: IField) {
    let name = field.name;
    return model.value[name] != null || enabledOptionalElement.value[name];
}

function onOptionalSwitch(field: IField, value: boolean) {
    enabledOptionalElement.value[field.name] = value;
    if (value || !props.returnWithoutDisabledOptionalElements) {
        model.value[field.name] = null;
    } else {
        delete model.value[field.name];
    }
}

let instance = getCurrentInstance().proxy;

function sectionToString(): string {
    let result: string[] = [];
    let fields = instance.$refs.field;
    if (Array.isArray(fields)) {
        let field: any;
        for (field of fields as any) {
            let value = field.$el.textContent;
            if (typeof value == "string") {
                value = value.trim();
                if (value.length) {
                    result.push(`${field.field.descr}: ${field.$el.textContent.trim()}`);
                }
            }
        }
    }
    return result.join("\n");
}

function isShowLabel(field: IField): boolean {
    return !!field.descr || (field.optional && !isPreview(field));
}

function getSlotName(field: IField): string {
    return isShowLabel(field) ? 'default' : 'label';
}

__expose<MyFormSectionExposeInterface>({
    sectionToString
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_unref(mountedFields).length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.descr)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.descr), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mountedFields), (field, i) => {
          return _withDirectives((_openBlock(), _createBlock(_component_el_form_item, {
            key: field.name,
            "label-width": isShowLabel(field) ? null : '100%',
            prop: field.name,
            rules: field.required && !_ctx.preview ? _unref(requiredRule) : null,
            class: _normalizeClass({
                [`form-item-${field.type}`]: true,
                'without-border': _unref(props).withoutBorder
            })
          }, _createSlots({
            [getSlotName(field)]: _withCtx(() => [
              (!field.optional || isOptionalElementOn(field))
                ? (_openBlock(), _createBlock(FormElementFactory, {
                    key: 0,
                    class: "w-100",
                    ref_for: true,
                    ref: "field",
                    preview: isPreview(field),
                    "text-preview": _ctx.textPreview,
                    field: field,
                    modelValue: _unref(model)[_unref(mountedFields)[i].name],
                    "onUpdate:modelValue": [($event: any) => ((_unref(model)[_unref(mountedFields)[i].name]) = $event), ($event: any) => (emitChange(_unref(mountedFields)[i], $event))],
                    onBlur: ($event: any) => (_unref(emits)('blur', _unref(mountedFields)[i].name)),
                    "content-variables": _ctx.contentVariables,
                    "form-model": _unref(model),
                    "auto-emit-value-when-empty": _ctx.autoEmitValueWhenEmpty,
                    clearable: _ctx.clearable
                  }, null, 8, ["preview", "text-preview", "field", "modelValue", "onUpdate:modelValue", "onBlur", "content-variables", "form-model", "auto-emit-value-when-empty", "clearable"]))
                : (_openBlock(), _createBlock(_component_el_input, {
                    key: 1,
                    style: {"visibility":"hidden"}
                  }))
            ]),
            _: 2
          }, [
            (isShowLabel(field))
              ? {
                  name: "label",
                  fn: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (field.optional && !isPreview(field))
                        ? (_openBlock(), _createBlock(_component_el_switch, {
                            key: "enabled",
                            class: "mr-2",
                            "model-value": isOptionalElementOn(field),
                            "onUpdate:modelValue": ($event: any) => (onOptionalSwitch(field, $event))
                          }, null, 8, ["model-value", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        class: _normalizeClass({'font-weight-bold': field.descrFontWeightBold})
                      }, _toDisplayString(field.descr), 3)
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["label-width", "prop", "rules", "class"])), [
            [_vShow, !isHidden(field)]
          ])
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})