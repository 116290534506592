import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Platform from "~/cabinet/ts/data/integrations/Platform";


const __default__ = {
    name: "IntegrationState"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    isCritical: { type: Boolean },
    descr: {},
    platform: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['lh-33', _ctx.isCritical ? 'text-red' : 'text-orange'])
  }, [
    _createVNode(FontAwesomeIcon, {
      icon: "exclamation-triangle",
      class: "mr-1"
    }),
    _createTextVNode(" " + _toDisplayString(_ctx.descr) + " ", 1),
    (_ctx.isCritical && _ctx.platform.isAuthEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Попробуйте исправить подключение "))
      : _createCommentVNode("", true)
  ], 2))
}
}

})