import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.prefixCls}-on-error`)
  }, [
    _createVNode(_component_svg_icon, {
      clas: "icon",
      type: "img-broken"
    })
  ], 2))
}