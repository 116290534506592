import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import AbstractEntity from "~/ts/library/AbstractEntity";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";
import {USE_REMOTE_DATA_EMITS, useRemoteData, useRemoteDataProps} from "~/cabinet/vue/interface/data/useRemoteData";
import {computed} from "vue";
import MyRemoteDataInterface from "~/cabinet/vue/interface/data/MyRemoteDataInterface";



const __default__ = {
    name: "MyRemoteData"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useRemoteDataProps(),
    showLoading: {
        type: Boolean,
        default: false
    }
},
  emits: ["update:items", "update:params", "load-start", "result", "update:result", "update:data", "load", "error", "items", "fetch-result"],
  setup(__props, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit

let {fetchResult, computedItems, isLoading, exposed} = useRemoteData(props);

let computedFetchData = computed(() => {
    if (fetchResult.value) {
        return fetchResult.value instanceof AbstractEntity ? fetchResult.value : (fetchResult.value as LcabApiFetchListResult<any>).originalResult.getData();
    } else {
        return null;
    }
});

__expose<MyRemoteDataInterface>(exposed);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.showLoading && _unref(isLoading))
      ? (_openBlock(), _createBlock(MyLoader, {
          key: 0,
          big: ""
        }))
      : _createCommentVNode("", true),
    (!__props.showLoading || !_unref(isLoading))
      ? _renderSlot(_ctx.$slots, "default", _normalizeProps(_mergeProps({ key: 1 }, {items: _unref(computedItems), data: _unref(computedFetchData), isLoading: _unref(isLoading)})))
      : _createCommentVNode("", true)
  ]))
}
}

})