import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef } from "vue"

import {computed, ref, watch} from 'vue';
import PhonebookContactTableColumn from "~/cabinet/vue/client/phonebook/contacts/PhonebookContactTableColumn.vue";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import {ISenderFormContact, ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import MyRemoteDataTableInterface from "~/cabinet/vue/interface/data/MyRemoteDataTableInterface";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyRemoteDataTable from "~/cabinet/vue/interface/data/MyRemoteDataTable.vue";
import IPhonebookContactColumn from "~/cabinet/vue/client/phonebook/contacts/interfaces";
import {IDataTableSort} from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import {tableMaxHeight} from "~/ts/vuePlugins/CabinetUiHelpersMixin";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "ContactsTable"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    contactbookDataSourceId: {},
    disabled: { type: Boolean },
    params: {},
    uslugaId: {},
    groups: {},
    contacts: {},
    showGroup: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update:modelValue", "selection"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;


let model = _useModel<number[]>(__props, "modelValue");

let items = ref<ISenderFormContact[]>();
let sort = ref<IDataTableSort>({
    prop: "f",
    order: "ascending"
});

let contactsColumns = ref<IPhonebookContactColumn[]>([]);
let contactsTable = ref<MyRemoteDataTableInterface>();

let groupsDictionary = computed(() => {
    return ObjectHelper.dictionaryFromArray(props.groups ? props.groups : []);
});

function onContactsLoad(result: LcabApiFetchListResult<any>) {
    let sortedColumns = SenderReceiverSelectHelper.getSortedContactColumns(result);
    if (JSON.stringify(sortedColumns) != JSON.stringify(contactsColumns.value)) {
        contactsColumns.value = sortedColumns;
    }
}


let dataParams = computed(() => {
    let result: Dictionary<any> = props.params ? {...props.params} : {};

    return result;
});

function toggleSelection(contact: ISenderFormContact, value?: boolean) {
    if (isContactSelectable(contact)) {
        contactsTable.value.toggleSelection(contact, value);
    }
}

let contactsDictionary = computed(() => {
    return ObjectHelper.dictionaryFromArray(props.contacts, "id");
});

watch(model, () => {
    for (let contactId of model.value) {
        if (contactsDictionary.value[contactId] == null) {
            let contact = items.value?.find(contact => contact.id == contactId);
            if (contact) {
                props.contacts.push(contact);
            }
        }
    }
    if (props.contacts.length) {
        for (let i = props.contacts.length - 1; i--; i >= 0) {
            let contact = props.contacts[i];
            if (contact) {
                if (model.value.indexOf(contact.id) == -1) {
                    props.contacts.splice(i, 1);
                }
            }
        }
    }
}, {
    deep: true
})

function onSortChange(e: { order?: "descending" | "ascending", prop: string }) {
    if (e.order) {
        sort.value = {
            order: e.order,
            prop: e.prop
        };
    } else {
        sort.value = null;
    }

}

function isContactSelectable(contact: ISenderFormContact) {
    return SenderReceiverSelectHelper.isContactAvailableToSelect(props.uslugaId, contact);
}


return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(MyRemoteDataTable, {
    ref_key: "contactsTable",
    ref: contactsTable,
    "max-height": _unref(tableMaxHeight),
    "data-url": `/api/sender/helper/${_ctx.contactbookDataSourceId}/getContactsList`,
    "data-params": {..._ctx.params, uslugaId: _ctx.uslugaId },
    sort: _unref(sort),
    "default-sort": _unref(sort),
    items: _unref(items),
    "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => (_isRef(items) ? (items).value = $event : items = $event)),
    modelValue: _unref(model),
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('update:modelValue', $event)))
    ],
    disabled: _ctx.disabled,
    "minimum-load-time": 200,
    "row-key": "id",
    onLoad: onContactsLoad,
    onSelection: _cache[3] || (_cache[3] = ($event: any) => (_unref(emits)('selection', $event))),
    onRowClick: _cache[4] || (_cache[4] = ($event: any) => (toggleSelection($event))),
    onSortChange: onSortChange
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        type: "selection",
        fixed: "",
        selectable: isContactSelectable
      }),
      (_ctx.showGroup)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            label: _unref(__)('Группа'),
            width: "160px"
          }, {
            default: _withCtx(({row}) => [
              _createTextVNode(_toDisplayString(_unref(groupsDictionary)[row.groupId] ? _unref(groupsDictionary)[row.groupId].descr : row.groupId), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactsColumns), (column, index) => {
        return (_openBlock(), _createBlock(PhonebookContactTableColumn, {
          key: column.name + '_' + index,
          column: column
        }, null, 8, ["column"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["max-height", "data-url", "data-params", "sort", "default-sort", "items", "modelValue", "disabled"]))
}
}

})