import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

import {IImageField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";


const __default__ = {
    name: "ImageElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useFormElementProps<IImageField>()
},
  setup(__props, { expose: __expose }) {

let props = __props;

let formElement = useFormElement(props);

__expose<FormElementExposeInterface>(formElement.expose);

return (_ctx: any,_cache: any) => {
  return (_ctx.field.src)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.field.src,
        style: {"max-width":"100%"}
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})