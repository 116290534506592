import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {PropType} from "vue";


const __default__ = {
    name: "MyFilterSeconds"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFilterComponentProps(),
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let model = _useModel<string[]>(__props, "modelValue");


return (_ctx: any,_cache: any) => {
  const _component_el_time_picker = _resolveComponent("el-time-picker")!

  return (_openBlock(), _createBlock(_component_el_time_picker, {
    "is-range": "",
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    "value-format": "HH:mm:ss",
    clearable: false,
    "picker-options": {
            format: 'HH:mm:ss'
        }
  }, null, 8, ["modelValue"]))
}
}

})