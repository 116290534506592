import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "pr-0" }
const _hoisted_5 = { class: "w-100" }

import {computed, nextTick, onMounted, onUpdated, ref, watch} from 'vue';
import {
    FilterModel,
    FiltersModel,
    IAddFilterValuePayload,
    IFilter, IFilterPreset,
    IFiltersData,
    RemoveFilterEventPayloadInterface
} from "./Interfaces";
import MyFilterTabPane from "./MyFilterTabPane.vue";
import Random from "~/ts/library/Random";
import WindowHelper from "~/ts/library/WindowHelper";
import Dictionary from "~/ts/library/Dictionary";
import {useLayoutUpdate} from "~/ts/vuePlugins/LayoutUpdateMixin";
import {EVENT_COMPUTE_LAYOUT, EventManagerInstance} from "~/cabinet/ts/Constant";
import MyFilterPreview from "~/cabinet/vue/interface/filter/MyFilterPreview.vue";
import {__} from "~/ts/library/Translate";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyFilterInterface from "~/cabinet/vue/interface/filter/MyFilterInterface";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import {LCAB_LAYOUT_BREAKPOINT} from "~/ts/library/LcabWindowHelper";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Draggable from "vuedraggable";
import Delay from "~/ts/library/Delay";


const __default__ = {
    name: "MyFilter"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    id: {},
    withSearch: { type: Boolean },
    filtersData: { default: () => ({
        filters: {},
        groups: {},
        operations: {},
        contentVariables: null,
        isStatic: null,
        preset: null,
        isOrAvailable: false
    }) },
    dropdownAlign: { default: "left" },
    uid: { default: () => Random.uid(10, 20) },
    hideAddButton: { type: Boolean },
    search: {},
    placeholder: { default: __("Добавить фильтры") },
    addFilterButtonText: { default: "Добавить фильтр" },
    dropdownMaxWidth: {},
    border: { type: Boolean, default: true }
  }, {
    "modelValue": {
    default: () => ({})
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["search-enter", "focus", "blur", "update:search"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<FiltersModel>(__props, "modelValue");

useLayoutUpdate();


let isFocused = ref(false);
let isFocusedOnce = ref(false);
let dropdownStyle = ref<Dictionary<string>>({});
let localValue = ref<FiltersModel>(
    ObjectHelper.jsonClone(normalizeValue(model.value))
);

function filterById(filterId: string): IFilter | null {
    return props.filtersData.filters[filterId];
}

let wrapperClass = computed(() => {
    return {
        'my-filter-wrapper': true,
        'is-focused': isFocused.value,
        ['my-filter-align-' + props.dropdownAlign]: true,
        'borderless': !props.border
    };
});

let tabPosition = computed(() => {
    return WindowHelper.getSize().width > LCAB_LAYOUT_BREAKPOINT ? "left" : "top";
});

let normalizedValue = computed(() => {
    return normalizeValue(model.value);
});

function normalizeValue(value: any) {
    let result = MyFilterHelper.normalizeValue(value, props.filtersData);
    if (JSON.stringify(result) != JSON.stringify(value)) {
        nextTick(() => {
            emitInput(ObjectHelper.jsonClone(localValue.value));
        });
    }
    return result;
}

onUpdated(() => {
    if (!isFocused.value) {
        nextTick(() => {
            EventManagerInstance.emit(EVENT_COMPUTE_LAYOUT, true)
        });
    }
});

function addFilterValue(payload: IAddFilterValuePayload) {
    let filter = filterById(payload.id);
    if (filter) {
        if (filter.allowedOperations.indexOf(payload.operation) > -1) {
            let value = ObjectHelper.jsonClone(localValue.value);
            if (!Array.isArray(value)) {
                if (!value[filter.id]) {
                    value[filter.id] = {};
                }
                if (value[filter.id][payload.operation] == null) {
                    value[filter.id][payload.operation] = [];
                }
                let operation = value[filter.id][payload.operation];
                if (!filter.multiple && operation.length) {
                    operation.splice(0, operation.length);
                }
                if (operation.indexOf(payload.value) == -1) {
                    operation.push(payload.value);
                }

                let option = payload.option;
                if (option && filter.options[option.value] == null) {
                    filter.options[option.value] = option;
                }

                onApply(value);
            }
        } else {
            console.log("Операция недоступна для этого фильтра", payload)
        }
    } else {
        console.warn("Фильтр не найден", payload);
    }
}

function r(row: any): IFilterPreset {
    return row;
}

onMounted(() => {
    computeDropdownStyle();
});

watch(model, newValue => {
    newValue = normalizeValue(newValue);
    localValue.value = ObjectHelper.jsonClone(newValue);
});

function onApply(value: any) {
    emitInput(value);
    setFocused(false);
}

function onSearchEnter() {
    emits('search-enter');
    setFocused(false);
}


function getPresetIndexByCc(cc: number): number | null {
    let result: number;
    let items = props.filtersData.preset.items;
    for (let i = 0; i < items.length; i++) {
        if (items[i].cc == cc) {
            result = i;
            break;
        }
    }
    return result;
}

async function removePreset(cc: number) {
    try {
        await ElConfirm(
            __("Вы точно хотите удалить этот фильтр?"),
            {
                title: __("Требуется подтверждение")
            }
        );
        let result = await LcabApiRequest.save({
            url: "/api/ui/filter/deletePreset",
            p: {
                cc: cc
            }
        });
        if (result.isSuccess) {
            let index = getPresetIndexByCc(cc);
            if (index != null) {
                props.filtersData.preset.items.splice(index, 1);
            }
        }
    } catch (e) {

    }
}

function removeAllFilters() {
    emitInput({});
}

function removeFilter(event: RemoveFilterEventPayloadInterface) {
    let value = ObjectHelper.jsonClone(model.value);
    if (!Array.isArray(value)) {
        value = removeFilterFromModel(event, value);
    } else {
        if (value[event.orGroupIndex]) {
            value[event.orGroupIndex] = removeFilterFromModel(event, value[event.orGroupIndex]);
            value = MyFilterHelper.normalizeValue(value, props.filtersData);
        }
    }
    emitInput(value);
}

function removeFilterFromModel(event: RemoveFilterEventPayloadInterface, model: FilterModel): FilterModel {
    delete model[event.filterId][event.operationId];
    if (!ObjectHelper.hasKeys(model[event.filterId])) {
        delete model[event.filterId];
    }
    return MyFilterHelper.normalizeValue(model, props.filtersData) as FilterModel;
}


function setFocused(value?: boolean) {
    computeDropdownStyle();
    let flag = value == null ? !isFocused.value : value;

    if (flag) {
        isFocusedOnce.value = true;
    }
    if (flag) {
        emits("focus");
    } else {
        emits("blur");
    }
    isFocused.value = flag;
}

function emitInput(value: FiltersModel) {
    model.value = value;
}

function initDefaultValue() {
    if (!ObjectHelper.hasKeys(model.value)) {
        let result: Dictionary<any> = {};
        for (let filterId in props.filtersData.filters) {
            if (props.filtersData.filters.hasOwnProperty(filterId)) {
                let filter: IFilter = props.filtersData.filters[filterId];
                if (ObjectHelper.hasKeys(filter.value)) {
                    result[filterId] = ObjectHelper.jsonClone(filter.value);
                }
            }
        }
        if (ObjectHelper.hasKeys(result)) {
            emitInput(result);
        }
    }
}

watch(computed(() => props.filtersData), () => initDefaultValue(), {deep: true})

let wrapper = ref<HTMLElement>();

function computeDropdownStyle() {
    if (wrapper.value) {
        let maxWidthValue = props.dropdownMaxWidth;
        if (!maxWidthValue) {
            let rect = wrapper.value.getBoundingClientRect();
            let windowSize = WindowHelper.getSize();
            let maxWidth: number;
            if (props.dropdownAlign == "right") {
                maxWidth = rect.right;
                let lcabAside = document.getElementById("lcab-aside");
                if (lcabAside) {
                    let asideWidth = parseInt(window.getComputedStyle(lcabAside).width);
                    if (!isNaN(asideWidth)) {
                        maxWidth -= asideWidth;
                    }
                }
            } else {
                maxWidth = windowSize.width - rect.left;
            }

            maxWidth -= 30;
            maxWidthValue = maxWidth + "px";
        }

        dropdownStyle.value = {
            maxWidth: maxWidthValue
        };
    }
}

async function makeSortedList() {
    try {
        await Delay.make(3000, "makeFieldSortedList", true);
        await LcabApiRequest.fetch({
            url: `/api/ui/filter/orderPreset`,
            p: {
                idList: props.filtersData.preset.items.map(item => item.cc)
            },
            silent: true
        });
    } catch (e) {

    }

}

initDefaultValue();

__expose<MyFilterInterface>({
    get isFocused() {
        return isFocused.value;
    },
    addFilterValue
})

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(wrapperClass)),
    ref_key: "wrapper",
    ref: wrapper,
    id: 'my-filter-wrapper-' + _ctx.uid,
    onClick: _cache[7] || (_cache[7] = ($event: any) => (setFocused()))
  }, [
    _withDirectives((_openBlock(), _createBlock(MyFilterPreview, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (setFocused())),
      "filters-data": _ctx.filtersData,
      value: _unref(normalizedValue),
      placeholder: _ctx.placeholder,
      "hide-add-button": _ctx.hideAddButton,
      onRemoveAllFilters: removeAllFilters,
      onRemoveFilter: removeFilter,
      "with-search": _ctx.withSearch,
      search: _ctx.search,
      onSearchEnter: onSearchEnter,
      "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('update:search', $event)))
    }, _createSlots({ _: 2 }, [
      (_ctx.$slots['preview-after'])
        ? {
            name: "preview-after",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "preview-after")
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.$slots['preview-before'])
        ? {
            name: "preview-before",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "preview-before")
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["filters-data", "value", "placeholder", "hide-add-button", "with-search", "search"])), [
      [
        _directive_on_native,
        void 0,
        "click",
        { stop: true }
      ]
    ]),
    (_unref(isFocusedOnce))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "my-filter-dropdown",
          onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
          id: 'my-filter-dropdown-' + _ctx.uid,
          style: _normalizeStyle(_unref(dropdownStyle))
        }, [
          _createVNode(_component_el_card, null, {
            default: _withCtx(() => [
              (_ctx.filtersData.preset)
                ? (_openBlock(), _createBlock(_component_el_tabs, {
                    key: 0,
                    "tab-position": "left",
                    class: "filter-tabs",
                    onTabRemove: removePreset
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tab_pane, {
                        lazy: "",
                        label: _unref(__)('Новые фильтры')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(MyFilterTabPane, {
                            "filters-data": _ctx.filtersData,
                            "add-button-text": _ctx.addFilterButtonText,
                            "model-value": _unref(localValue),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (onApply($event)))
                          }, null, 8, ["filters-data", "add-button-text", "model-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      (_ctx.filtersData.preset)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersData.preset.items, (preset, index) => {
                              return (_openBlock(), _createBlock(_component_el_tab_pane, {
                                key: `${preset.cc}_${index}`,
                                lazy: "",
                                name: preset.cc.toString(),
                                label: preset.descr,
                                closable: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(MyFilterTabPane, {
                                    "filters-data": _ctx.filtersData,
                                    "model-value": preset.value,
                                    "add-button-text": _ctx.addFilterButtonText,
                                    preset: preset,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (onApply($event)))
                                  }, null, 8, ["filters-data", "model-value", "add-button-text", "preset"])
                                ]),
                                _: 2
                              }, 1032, ["name", "label"]))
                            }), 128)),
                            (_ctx.filtersData.preset.items.length > 1)
                              ? (_openBlock(), _createBlock(_component_el_tab_pane, { key: 0 }, {
                                  label: _withCtx(() => [
                                    _createVNode(FontAwesomeIcon, {
                                      icon: "cog",
                                      class: "mr-1"
                                    }),
                                    _cache[8] || (_cache[8] = _createTextVNode(" Сортировка фильтров "))
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[9] || (_cache[9] = _createElementVNode("p", null, "Перетащите фильтры мышкой, чтобы отсортировать их", -1)),
                                    _createElementVNode("table", _hoisted_3, [
                                      _createVNode(_unref(Draggable), {
                                        modelValue: _ctx.filtersData.preset.items,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filtersData.preset.items) = $event)),
                                        "item-key": "cc",
                                        tag: "tbody",
                                        class: "cursor-move",
                                        animation: 200,
                                        onSort: makeSortedList
                                      }, {
                                        item: _withCtx(({element}) => [
                                          _createElementVNode("tr", null, [
                                            _createElementVNode("td", _hoisted_4, [
                                              _createVNode(FontAwesomeIcon, { icon: "ellipsis-v" })
                                            ]),
                                            _createElementVNode("td", _hoisted_5, _toDisplayString(r(element).descr), 1)
                                          ])
                                        ]),
                                        _: 1
                                      }, 8, ["modelValue"])
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(ElCardSection, {
                    key: 1,
                    class: "m-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(MyFilterTabPane, {
                        lazy: "",
                        "filters-data": _ctx.filtersData,
                        "model-value": _unref(localValue),
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (onApply($event))),
                        "add-button-text": _ctx.addFilterButtonText
                      }, null, 8, ["filters-data", "model-value", "add-button-text"])
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ], 12, _hoisted_2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})