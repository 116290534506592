import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "mt-3" }

import OpenApiSchemaViewer from "~/cabinet/vue/interface/OpenApiSchemaViewer.vue";
import HelpIcon from "~/cabinet/vue/interface/form/HelpIcon.vue";
import {WebhookActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import WebhookConfig from "~/cabinet/vue/interface/webhook/WebhookConfig.vue";
import {getEmptyWebhookConfigModel} from "~/cabinet/vue/interface/webhook/WebhookConfigInterface";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";


const __default__ = {
    name: "WebhookBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<WebhookActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let _modelValue = _useModel<getActionDescriptionModelType<WebhookActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    webhook: getEmptyWebhookConfigModel(),
    globalVariables: [],
    descr: null
}));

let frontendParams = computed(() => props.actionDescription.frontendParams);

function addVariable() {
    if (!model.value.globalVariables) {
        model.value.globalVariables = [];
    }
    model.value.globalVariables.push({
        name: null,
        path: []
    });
}

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Название" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _unref(model).descr,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).descr) = $event)),
          placeholder: "Необязательно"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(WebhookConfig, {
      value: _unref(model).webhook,
      "content-variables": _ctx.contentVariables
    }, null, 8, ["value", "content-variables"]),
    (_ctx.isInScheme)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "Сохранять ответ в глобальных переменных"
        }, {
          default: _withCtx(() => [
            (_unref(model).globalVariables && _unref(model).globalVariables.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(model).globalVariables, (variable, key) => {
                    return (_openBlock(), _createBlock(_component_el_card, {
                      key: key,
                      class: "mb-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-1" }, "Название переменной", -1)),
                                _createVNode(_component_el_input, {
                                  modelValue: variable.name,
                                  "onUpdate:modelValue": ($event: any) => ((variable.name) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                  _cache[3] || (_cache[3] = _createTextVNode(" JSON-путь ")),
                                  _createVNode(HelpIcon, null, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createElementVNode("p", null, " Например, переменная в JSON находится по пути ['response']['result']['id'] - добавьте три значения: response, result и id. ", -1),
                                      _createElementVNode("p", null, " Оставьте это поле пустым, если вы хотите сохранить в переменную весь ответ сервера. ", -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _createVNode(_component_el_select, {
                                  modelValue: variable.path,
                                  "onUpdate:modelValue": ($event: any) => ((variable.path) = $event),
                                  class: "w-100",
                                  filterable: "",
                                  multiple: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "Укажите путь до значения, разделяя ключи с помощью Enter"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_el_button, {
                            type: "danger",
                            plain: "",
                            onClick: ($event: any) => (_unref(toggleArrayElement)(_unref(model).globalVariables, variable, false))
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" Удалить переменную ")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "primary",
                plain: "",
                onClick: addVariable
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Добавить переменную ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.eventParamsByAuth.webhookData)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 1,
          "label-width": "0"
        }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "mt-4 mb-2" }, "Описание отправляемого в POST запросе JSON-объекта", -1)),
            _createVNode(OpenApiSchemaViewer, {
              schema: _ctx.eventParamsByAuth.webhookData
            }, null, 8, ["schema"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})