import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {IHeaderBlockValues} from "~/cabinet/vue/interface/htmlConstructor/blocks/header/IHeaderBlockValues";
import HeaderDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/header/HeaderDisplay.vue";
import HeaderForm from "~/cabinet/vue/interface/htmlConstructor/blocks/header/HeaderForm.vue";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class HeaderBlockType extends AbstractBlockType<IHeaderBlockValues, any> {


    get displayComponent(): RawComponent {
        return useRawComponent(HeaderDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(HeaderForm);
    }




    get isBlockWithoutPadding(): boolean {
        return true;
    }

    hasLeftRightPadding(value: IHeaderBlockValues): boolean {
        return false;
    }
}