import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


const __default__ = {
    name: "LongContentPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    height: { default: '300px' },
    bordered: { type: Boolean, default: true },
    padding: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            root: true,
            bordered: _ctx.bordered,
            padding: _ctx.padding
        }),
    style: _normalizeStyle({
            height: _ctx.height
        })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})