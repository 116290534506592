import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IAbstractOnlineChatMessagePinParam} from "~/cabinet/vue/client/events/action/types/Interface";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "OnlineChatMessageFormPinParams"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    value: {}
  },
  setup(__props: any) {

let props = __props

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "Закрепление" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.value.pinMessage,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.pinMessage) = $event)),
          class: "mr-2"
        }, null, 8, ["modelValue"]),
        _cache[4] || (_cache[4] = _createTextVNode(" Закрепить сообщение для операторов "))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.value.unpinOtherMessages,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.unpinOtherMessages) = $event)),
          class: "mr-2"
        }, null, 8, ["modelValue"]),
        _cache[5] || (_cache[5] = _createTextVNode(" Открепить другие сообщения для операторов "))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.value.pinMessageForClient,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.pinMessageForClient) = $event)),
          class: "mr-2"
        }, null, 8, ["modelValue"]),
        _cache[6] || (_cache[6] = _createTextVNode(" Закрепить сообщение для клиента "))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.value.unpinOtherMessagesForClient,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.unpinOtherMessagesForClient) = $event)),
          class: "mr-2"
        }, null, 8, ["modelValue"]),
        _cache[7] || (_cache[7] = _createTextVNode(" Открепить другие сообщения для клиента "))
      ])
    ]),
    _: 1
  }))
}
}

})