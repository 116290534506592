import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

import Delay from "~/ts/library/Delay";
import Random from "~/ts/library/Random";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";
import {InputInstance} from "element-plus";
import {ref} from "vue";
import MyDatePicker from "~/core-ui/vue/ui/MyDatePicker.vue";


const __default__ = {
    name: "DateElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let input = ref<InputInstance>();
let focused = ref(false);
let instanceId = ref(Random.uid());

let formElement = useFormElement(model, props, {
    focus: () => input.value.focus()
})

async function onFocus() {
    Delay.override(instanceId.value);
    focused.value = true;
}

async function onBlur() {
    try {
        await Delay.make(300, instanceId.value, true);
        focused.value = false;
    } catch (e) {

    }
}

__expose<FormElementExposeInterface>(formElement.expose);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({focused: _unref(focused)})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(MyDatePicker, _mergeProps(_ctx.$attrs, {
        class: "flex-fill",
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        placeholder: _ctx.field.placeholder,
        readonly: _ctx.field.readonly,
        disabled: _ctx.readonly,
        ref_key: "input",
        ref: input,
        onFocus: onFocus,
        onBlur: onBlur,
        autocomplete: "new-password"
      }), null, 16, ["modelValue", "placeholder", "readonly", "disabled"])
    ])
  ], 2))
}
}

})