import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import 'prismjs'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-sql'
import 'prismjs/themes/prism.css'
import VuePrismComponent from "vue-prism-component/dist/vue-prism-component.common";
import "./PrismAdditionals";


const __default__ = {
    name: "Prism"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    language: {},
    code: {},
    preWrap: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VuePrismComponent), {
    class: _normalizeClass({prism: true, 'pre-wrap': _ctx.preWrap}),
    language: _ctx.language,
    code: _ctx.code
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.code), 1)
    ]),
    _: 1
  }, 8, ["class", "language", "code"]))
}
}

})