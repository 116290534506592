import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_2 = {
  key: 1,
  class: "text-muted"
}

import InputWithVariablesPreview from "~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue";
import {useActionBlockPreviewProps} from "~/cabinet/vue/client/events/action/types/useActionBlockPreview";
import {CabinetNotificationActionDescription} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "CabinetNotificationBlockPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
        ...useActionBlockPreviewProps<CabinetNotificationActionDescription>()
    },
  setup(__props) {

let props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" Заголовок: ")),
      (_ctx.params.subject)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.params.subject), 1)
          ], 64))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, " Не задан "))
    ]),
    (_ctx.params.text)
      ? (_openBlock(), _createBlock(InputWithVariablesPreview, {
          key: 0,
          text: _ctx.params.text,
          variables: _ctx.contentVariables
        }, null, 8, ["text", "variables"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, " Текст не задан "))
  ]))
}
}

})