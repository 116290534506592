import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

import {computed} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyDropdownLinkItem"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    to: {},
    href: {},
    icon: {},
    color: {},
    divided: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let colorClass = computed(() => {
    return props.color ? 'text-' + props.color : '';
});

function onClick(e: MouseEvent) {
    if (!props.to && !props.href) {
        e.preventDefault();
    }
    emits("click");
}

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : 'a'), {
    href: _ctx.href,
    to: _ctx.to,
    onClick: onClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_dropdown_item, {
        class: _normalizeClass(_unref(colorClass)),
        divided: _ctx.divided
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                key: 0,
                class: _normalizeClass(['icon', _unref(colorClass)]),
                icon: _ctx.icon
              }, null, 8, ["class", "icon"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class", "divided"])
    ]),
    _: 3
  }, 8, ["href", "to"]))
}
}

})