import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table table-hover"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-right" }

import ObjectHelper from "~/ts/library/ObjectHelper";
import MessageContent from "~/cabinet/vue/client/sender/report/parts/MessageContent.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {SenderFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {computed, ref} from "vue";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {useSortable} from "@vueuse/integrations";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import {ReactiveListEntityInterface} from "~/core-ui/ts/request/AbstractReactiveListEntity";


interface IRow {
    id: number,
    descr: string
    model: SenderFormModelInterface<any>
}

interface IModel {
    isNew: boolean,
    id: number,
    descr: string
}

const __default__ = {
    name: "TemplatesDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    formId: {},
    model: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update:model", "apply"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let items = ref<IRow[]>([]);

let request = computed(() => (new ReactiveRequest<ReactiveListEntityInterface<IRow>>(new LcabApiRequest({
    url: `/api/sender/templates/${props.uslugaId}/${props.formId}/getTemplateList`
}))).setCallback(data => {
    items.value.splice(0, items.value.length, ...data.items);
    setTimeout(() => {
        sortable.start();
    }, 10);
}));

let visible = _useModel<boolean>(__props, "visible");

let tableTbody = ref<HTMLElement>();
let sortable = useSortable(tableTbody, items, {
    onEnd: () => {
        setTimeout(() => {
            LcabApiRequest.fetch({
                url: `/api/sender/templates/${props.uslugaId}/saveTemplatesOrder`,
                silent: true,
                p: {
                    idList: items.value.map(item => item.id)
                }
            });
        }, 1000);
    }
});

let saveTemplateModel = ref<IModel>({
    isNew: true,
    id: null,
    descr: null
});
let saveDialogVisible = ref(false);
let useDialogVisible = ref(false);
let selectedTemplate = ref<IRow>();
const useTypes: { descr: string, keys: string[], type?: string }[] = [
    {
        descr: "Применить шаблон целиком",
        keys: ["content", "recipients", "settings"],
    },
    {
        descr: "Применить сообщение и получателей",
        keys: ["content", "recipients"]
    },
    {
        descr: "Применить сообщение и настройки",
        keys: ["content", "settings"]
    },
    {
        descr: "Применить только сообщение",
        keys: ["content"],
        type: "primary"
    }
];
let lastUsedTemplateId = ref<number>();

function useTemplate(keys: string[]) {
    let model = ObjectHelper.jsonClone(props.model);
    for (let key of keys) {
        (model as any)[key] = (ObjectHelper.jsonClone(selectedTemplate.value.model) as any)[key];
    }
    emits("update:model", model);
    setLastUsedTemplateId(selectedTemplate.value.id);
    visible.value = false;
    useDialogVisible.value = false;
    emits("apply");
}


function onRowClick(row: IRow) {
    selectedTemplate.value = row;
    useDialogVisible.value = true;

}

async function saveTemplate() {
    let result = await LcabApiRequest.save({
        url: `/api/sender/templates/${props.uslugaId}/saveTemplate`,
        p: {
            ...saveTemplateModel.value,
            model: props.model
        }
    });
    if (result.isSuccess) {
        saveDialogVisible.value = false;
        reload();
        setLastUsedTemplateId(result.getData("id"));
    }
}

async function deleteTemplate(row: IRow) {
    try {
        await ElConfirm(`Вы уверены, что хотите удалить шаблон "${row.descr}"?`);
        let result = await LcabApiRequest.save({
            url: `/api/sender/templates/${props.uslugaId}/removeTemplate/${row.id}`
        });
        if (result.isSuccess) {
            reload();
        }
    } catch (e) {

    }
}

function setLastUsedTemplateId(id: number) {
    lastUsedTemplateId.value = id;
    saveTemplateModel.value.isNew = false;
    saveTemplateModel.value.id = id;
}

function reload() {
    request.value.make();
}


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(MyDialog, {
    dialog: {
            class: 'overflow right no-padding w-75',
            appendToBody: true
        },
    visible: _unref(visible),
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    "button-text": "Сохранить текущую форму в шаблон",
    "mobile-full-width-button": "",
    title: "Шаблоны",
    onApply: _cache[6] || (_cache[6] = ($event: any) => (_isRef(saveDialogVisible) //@ts-ignore
 ? saveDialogVisible.value = true : saveDialogVisible = true))
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
                _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", { style: {"width":"180px"} }, "Название шаблона"),
                    _createElementVNode("th", null, "Контент"),
                    _createElementVNode("th", { style: {"width":"50px"} })
                  ])
                ], -1)),
                _createElementVNode("tbody", {
                  ref_key: "tableTbody",
                  ref: tableTbody
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (row) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: "cursor-pointer",
                      key: row.id,
                      onClick: ($event: any) => (onRowClick(row))
                    }, [
                      _createElementVNode("td", null, _toDisplayString(row.descr), 1),
                      _createElementVNode("td", null, [
                        _createVNode(MessageContent, {
                          "usluga-id": _ctx.uslugaId,
                          message: row.message,
                          "in-reports": true
                        }, null, 8, ["usluga-id", "message"])
                      ]),
                      _createElementVNode("td", _hoisted_3, [
                        _withDirectives(_createVNode(MyIconButton, {
                          delete: "",
                          onClick: ($event: any) => (deleteTemplate(row))
                        }, null, 8, ["onClick"]), [
                          [
                            _directive_on_native,
                            void 0,
                            "click",
                            { stop: true }
                          ]
                        ])
                      ])
                    ], 8, _hoisted_2))
                  }), 128))
                ], 512)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"]),
      _createVNode(MyDialog, {
        dialog: {
                    appendToBody: true
                },
        form: {
                    model: _unref(saveTemplateModel)
                },
        visible: _unref(saveDialogVisible),
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(saveDialogVisible) ? (saveDialogVisible).value = $event : saveDialogVisible = $event)),
        title: "Сохранение шаблона",
        onApply: saveTemplate
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_switch, {
                modelValue: _unref(saveTemplateModel).isNew,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(saveTemplateModel).isNew) = $event)),
                "active-text": "Создать новый шаблон",
                "inactive-text": "Обновить старый шаблон"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          (!_unref(saveTemplateModel).isNew)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                rules: _unref(requiredRule),
                prop: "id"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _unref(saveTemplateModel).id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(saveTemplateModel).id) = $event)),
                    class: "w-100",
                    "default-first-option": "",
                    filterable: "",
                    placeholder: "Выберите шаблон"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (row) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: row.id,
                          label: row.descr,
                          value: row.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"]))
            : (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                rules: _unref(requiredRule),
                prop: "descr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _unref(saveTemplateModel).descr,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(saveTemplateModel).descr) = $event)),
                    placeholder: "Введите название шаблона"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["rules"]))
        ]),
        _: 1
      }, 8, ["form", "visible"]),
      _createVNode(_component_el_dialog, {
        modelValue: _unref(useDialogVisible),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(useDialogVisible) ? (useDialogVisible).value = $event : useDialogVisible = $event)),
        "append-to-body": "",
        title: "Использовать шаблон",
        width: "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(useTypes, (item) => {
                return _createVNode(_component_el_col, {
                  key: item.descr,
                  class: "mb-3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: item.type,
                      class: "w-100",
                      round: "",
                      onClick: ($event: any) => (useTemplate(item.keys))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.descr), 1)
                      ]),
                      _: 2
                    }, 1032, ["type", "onClick"])
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})