import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-muted font-weight-normal"
}

import {roundCountryImage} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "MobileCountryHeader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    id: {},
    descr: {},
    extraDescr: {},
    showImg: { type: Boolean, default: true },
    padding: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({padding: _ctx.padding}),
    style: _normalizeStyle({
        backgroundImage: _ctx.showImg ? `url('${_unref(roundCountryImage)(_ctx.id)}')` : null
    })
  }, [
    (_ctx.descr.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.descr), 1)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode("   ")
        ], 64)),
    (_ctx.extraDescr)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.extraDescr), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "after-descr")
  ], 6))
}
}

})