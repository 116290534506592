import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["href"]

import {ref} from 'vue';
import {AbstractDocumentScanInterface} from "~/cabinet/vue/dealer/documents/Interface";
import ScanDialog from "~/cabinet/vue/client/documents/ScanDialog.vue";


const __default__ = {
    name: "ScanFormItem"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    label: {},
    scan: {},
    documentDownloadUrl: {},
    documentId: {},
    documentTypeId: {}
  },
  emits: ["reload"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let dialog = ref<{ visible: boolean }>();

function showScanDialog() {
    dialog.value = {
        visible: true,
    };
}

function reload() {
    emits("reload");
}


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: _ctx.label }, {
      default: _withCtx(() => [
        (_ctx.scan)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.scan.status.isModerate)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[2] || (_cache[2] = _createTextVNode(" Загруженные вами сканы ")),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-orange" }, "находятся на проверке", -1)),
                    _cache[4] || (_cache[4] = _createTextVNode(". "))
                  ], 64))
                : (_ctx.scan.status.isSuccess)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _cache[5] || (_cache[5] = _createTextVNode(" Загруженные вами сканы ")),
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-success" }, "успешно прошли проверку", -1)),
                      _cache[7] || (_cache[7] = _createTextVNode(". "))
                    ], 64))
                  : (_ctx.scan.status.isReject)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _cache[8] || (_cache[8] = _createTextVNode(" Загруженные вами сканы ")),
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-danger" }, "не прошли проверку", -1)),
                        _cache[10] || (_cache[10] = _createTextVNode(". ")),
                        _createElementVNode("div", null, "Причина: " + _toDisplayString(_ctx.scan.status.errorDescr), 1)
                      ], 64))
                    : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (!_ctx.scan || _ctx.scan.status.isReject)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[11] || (_cache[11] = _createTextVNode(" Вы можете ")),
              _createElementVNode("a", {
                href: _ctx.documentDownloadUrl,
                target: "_blank"
              }, "скачать документ", 8, _hoisted_2),
              _cache[12] || (_cache[12] = _createTextVNode(" и ")),
              _createElementVNode("a", {
                class: "link-dashed",
                tabindex: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showScanDialog()))
              }, "загрузить скан"),
              _cache[13] || (_cache[13] = _createTextVNode(" подписанного экземпляра документа. ")),
              (_unref(dialog) && _unref(dialog).visible)
                ? (_openBlock(), _createBlock(ScanDialog, {
                    key: 0,
                    visible: _unref(dialog).visible,
                    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_unref(dialog).visible) = $event)),
                    "document-type-id": _ctx.documentTypeId,
                    "document-id": _ctx.documentId,
                    onReload: reload
                  }, null, 8, ["visible", "document-type-id", "document-id"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}
}

})