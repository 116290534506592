import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {DiscountCouponActionDescription} from "~/cabinet/ts/data/events/ActionDescription";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import DiscountCouponForm from "~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCouponForm.vue";


const __default__ = {
    name: "DiscountCouponBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DiscountCouponActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let frontendParams = computed(() => props.actionDescription.frontendParams);


let _modelValue = _useModel<getActionDescriptionModelType<DiscountCouponActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    uslugaId: frontendParams.value.uslugi[0]?.id,
    form: undefined
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(DiscountCouponForm, {
      "is-common": false,
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
    }, null, 8, ["modelValue"])
  ]))
}
}

})