import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import {useSenderMessageRecipientProps} from "~/cabinet/vue/client/sender/report/parts/useSenderMessageRecipient";
import {computed} from "vue";


const __default__ = {
    name: "MessageRecipient"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useSenderMessageRecipientProps(),
    uslugaId: {
        type: String,
        required: true
    }
},
  emits: ["click"],
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit

let recipientComponent = computed(() => {
    return SenderUslugi[props.uslugaId].report.recipientComponent;
});

return (_ctx: any,_cache: any) => {
  return (!_unref(recipientComponent))
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.clickable)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.recipient.id,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(emits)('click')), ["prevent"]))
            }, _toDisplayString(_ctx.recipient.descr), 9, _hoisted_2))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.recipient.descr), 1)
            ], 64))
      ]))
    : (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(recipientComponent)), {
        key: 1,
        clickable: _ctx.clickable,
        recipient: _ctx.recipient,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('click')))
      }, null, 8, ["clickable", "recipient"]))
}
}

})