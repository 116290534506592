import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-right" }

import {IField, IFormSection} from "~/cabinet/vue/interface/form/elements/Interfaces";
import Draggable from "~/node_modules/vuedraggable/src/vuedraggable.js";
import MyFormElementConstructor from "~/cabinet/vue/interface/form/elements/constructor/MyFormElementConstructor.vue";
import {IFormElementType} from "~/cabinet/vue/interface/form/elements/FormElementCollection";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import Random from "~/ts/library/Random";

interface IFieldSortable {
    field: IField,
    index: number,
    id: string
}

const __default__ = {
    name: "MyFormSectionConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    elementTypes: {},
    elementNameSameAsDescr: { type: Boolean },
    showDisplayConditionConfig: { type: Boolean },
    elementNameRandomize: { type: Boolean },
    duplicateElementNames: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let _modelValue = _useModel<IFormSection>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    elements: []
}));

let sortableModel = computed<IFieldSortable[]>({
    get: () => {
        return model.value.elements.map((item, index) => {
            return {
                id: Random.uid(),
                index: index,
                field: item
            }
        });
    },
    set: (newModelValue) => {
        model.value.elements = newModelValue.map(item => item.field);
    }
});

async function removeElement(index: number) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить этот элемент?");
        model.value.elements.splice(index, 1);
    } catch (e) {

    }
}

function r(field: any): IFieldSortable {
    return field;
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Draggable), {
      modelValue: _unref(sortableModel),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(sortableModel) ? (sortableModel).value = $event : sortableModel = $event)),
      "item-key": "id",
      animation: 200
    }, {
      item: _withCtx(({element}) => [
        _createElementVNode("div", null, [
          _createVNode(MyFormElementConstructor, {
            modelValue: r(element).field,
            "onUpdate:modelValue": ($event: any) => ((r(element).field) = $event),
            "element-types": _ctx.elementTypes,
            "element-name-same-as-descr": _ctx.elementNameSameAsDescr,
            "element-name-randomize": _ctx.elementNameRandomize,
            "show-display-condition-config": _ctx.showDisplayConditionConfig,
            "duplicate-name-error": r(element).field && _ctx.duplicateElementNames.indexOf(r(element).field.name) > -1
          }, {
            "form-end": _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_button, {
                      plain: "",
                      type: "danger",
                      onClick: ($event: any) => (removeElement(r(element).index))
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Удалить элемент ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "element-types", "element-name-same-as-descr", "element-name-randomize", "show-display-condition-config", "duplicate-name-error"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(model).elements.push(null)))
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" Добавить элемент ")
      ])),
      _: 1
    })
  ]))
}
}

})