import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, isRef as _isRef, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mb-5"
}
const _hoisted_2 = ["innerHTML"]

import {computed, onMounted, ref} from 'vue';
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import AbstractEntity, {AbstractEntityFields, Discriminator} from "~/ts/library/AbstractEntity";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import OperatorLoginInfo from "~/cabinet/vue/client/wizard/online/OperatorLoginInfo.vue";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterTabPane from "~/cabinet/vue/interface/filter/MyFilterTabPane.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import SubscribeInMessengers from "~/cabinet/vue/client/notifications/SubscribeInMessengers.vue";
import Device from "~/ts/library/Device";
import InlineChatWidget from "~/cabinet/vue/interface/inlineWidget/InlineChatWidget.vue";
import InlineChatWidgetExposeInterface from "~/cabinet/vue/interface/inlineWidget/InlineChatWidgetExposeInterface";
import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";



const __default__ = {
    name: "Wizard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let formModel = ref<any>();
let filtersModel = ref<any>();

let orgId = computed(() => {
    return AccountStore.organization.value?.id;
});

let chat = ref<InlineChatWidgetExposeInterface>();

function clearFormModel() {
    formModel.value = null;
    filtersModel.value = undefined;
}

let wizardsRequest = computed(() => {
    clearFormModel();
    if (orgId.value) {
        return new ReactiveRequest(new LcabApiRequest({
            url: `/api/client/wizard/getList`,
            silent: true
        }, WizardListResponse));
    } else {
        return null;
    }
});

let currentWizard = computed<WizardParams>(() => {
    return wizardsRequest.value?.data?.wizards[0];
});

let currentStep = computed(() => {
    return currentWizard.value?.steps[currentWizard.value?.currentStep];
});

function skipStep() {
    nextStep(true);
}

let showChat = computed(() => {
    return !AccountStore.data.value.isFromOrgCard && AccountStore.isSuperDealerClient.value && Device.desktop() && currentWizard.value?.id === "online_1";
});


async function nextStep(skip = false) {
    let formModelLocal = formModel.value;
    if (!formModelLocal) {
        formModelLocal = {};
    }
    if (filtersModel.value) {
        for (let key in filtersModel.value) {
            if (filtersModel.value.hasOwnProperty(key)) {
                if (filtersModel.value[key]["="] && filtersModel.value[key]["="].length > 0) {
                    formModelLocal[key] = filtersModel.value[key]["="][0];
                }
            }
        }
    }
    let result = await LcabApiRequest.fetch({
        url: `/api/client/wizard/${skip ? 'skipStep' : 'nextStep'}`,
        p: {
            wizardId: currentWizard.value.id,
            formModel: formModelLocal
        }
    });
    if (!result.showMessageOnError()) {
        let yandexMetrikaEventId = currentStep.value.yandexMetrikaEventId;
        if (typeof yandexMetrikaEventId == "string") {

        }

        let finish = result.getData("finish");
        if (finish) {
            let result = await wizardsRequest.value.make();
            if (result.data.wizards.length === 0) {
                window.location.reload();
            }
        } else {
            let wizards = result.getData("wizards");
            if (wizards) {
                wizardsRequest.value.data = WizardListResponse.new({
                    wizards
                });
            }
            clearFormModel();
            //currentWizard.value.currentStep++;
        }
    }
}

let infoComponents = computed(() => {
    return {
        onlineOperatorLoginInfo: OperatorLoginInfo,
        onlineChatApplicationDownload: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/wizard/online/ApplicationDownload.vue')),
        messengerNoticeSubscribe: SubscribeInMessengers,
        onlineCreateSite: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/wizard/online/CreateSite.vue')),
        onlineChatIntegration: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/wizard/online/CreateIntegration.vue')),
        onlineChatSiteCode: useRawAsyncComponent(() => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/wizard/online/SiteCode.vue')),
    }
});

onMounted(() => {
    setTimeout(function () {
        if (chat.value && currentWizard.value?.currentStep === 0) {
            chat.value.sendMessageToClient(`Благодарим Вас за регистрацию в нашем сервисе!

Эта форма поможет Вам произвести первоначальные настройки.

Если у Вас появятся какие-то вопросы, то не стесняйтесь обратиться к нам. Мы с радостью Вам поможем!`);

            //chat.value.sendCommentToOperator("Зарегистрировался новый клиент, и сейчас он находится в визарде. Ты можешь подключиться к нему и как-то помочь.")
        }
    }, 500);

})

class WizardListResponse extends AbstractEntity {
    wizards: WizardParams[]

    protected getFields(): AbstractEntityFields {
        return {
            wizards: WizardParams
        };
    }
}

class WizardParams extends AbstractEntity {
    id: string;
    steps: WizardStep[];
    currentStep: number;

    protected getFields(): AbstractEntityFields {
        return {
            steps: WizardStep
        };
    }
}

class WizardStep extends AbstractEntity {
    descr: string;
    content: (WizardStepContentForm | WizardStepContentComponent)[]
    isCanBeSkipped: boolean;
    yandexMetrikaEventId?: string;

    protected getFields(): AbstractEntityFields {
        return {
            content: WizardStepContent
        };
    }
}

class WizardStepContent extends AbstractEntity {
    type: "form" | "component" | "html" | "filters";
    params: Dictionary<any>;

    protected getDiscriminator(): Discriminator {
        return new Discriminator("type", {
            form: WizardStepContentForm,
            component: WizardStepContentComponent,
            html: WizardStepContentHtml
        });
    }
}

class WizardStepContentForm extends WizardStepContent {
    params: {
        form: IForm
    }
}

class WizardStepContentComponent extends WizardStepContent {
    params: {
        id: string
    }
}

class WizardStepContentFilters extends WizardStepContent {
    params: {
        filters: IFiltersData
    }
}

class WizardStepContentHtml extends WizardStepContent {
    params: {
        html: string
    }
}




return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_unref(currentStep))
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        "model-value": true,
        class: "without-header sticky-footer",
        width: _unref(showChat) ? '1100px' : undefined
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 12,
                class: "text-left"
              }, {
                default: _withCtx(() => [
                  (_unref(currentStep).isCanBeSkipped)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        class: "text-muted",
                        tabindex: "",
                        link: "",
                        onClick: skipStep
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Пропустить этот шаг ")
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createTextVNode("   "))
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 12,
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (nextStep(false)))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Продолжить ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: _unref(showChat) ? 16 : 24
              }, {
                default: _withCtx(() => [
                  (_unref(currentWizard).steps > 1)
                    ? (_openBlock(), _createBlock(_component_el_steps, {
                        key: 0,
                        active: _unref(currentWizard).currentStep,
                        "align-center": "",
                        class: "mb-5"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentWizard).steps, (step, index) => {
                            return (_openBlock(), _createBlock(_component_el_step, {
                              key: index,
                              title: step.descr
                            }, null, 8, ["title"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["active"]))
                    : (_unref(currentStep).descr.length > 0)
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_unref(currentStep).descr), 1))
                      : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentStep).content, (content, contentIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: contentIndex,
                      class: "step-content"
                    }, [
                      (content.type === 'form')
                        ? (_openBlock(), _createBlock(_component_el_form, {
                            key: 0,
                            "label-position": "left",
                            "label-width": "180px"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(MyForm, {
                                modelValue: _unref(formModel),
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(formModel) ? (formModel).value = $event : formModel = $event)),
                                form: content.params.form
                              }, null, 8, ["modelValue", "form"])
                            ]),
                            _: 2
                          }, 1024))
                        : (content.type === 'component' && _unref(infoComponents)[content.params.id])
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(infoComponents)[content.params.id]), _mergeProps({
                              key: 1,
                              modelValue: _unref(formModel),
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(formModel) ? (formModel).value = $event : formModel = $event)),
                              ref_for: true
                            }, content.params), null, 16, ["modelValue"]))
                          : (content.type === 'html')
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 2,
                                innerHTML: content.params.html
                              }, null, 8, _hoisted_2))
                            : (content.type === 'filters')
                              ? (_openBlock(), _createBlock(MyFilterTabPane, {
                                  key: 3,
                                  "filters-data": content.params.filters,
                                  modelValue: _unref(filtersModel),
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(filtersModel) ? (filtersModel).value = $event : filtersModel = $event)),
                                  "show-buttons": false,
                                  "real-time-input": ""
                                }, null, 8, ["filters-data", "modelValue"]))
                              : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["span"]),
              (_unref(showChat))
                ? (_openBlock(), _createBlock(_component_el_col, {
                    key: 0,
                    span: 8,
                    class: "pl-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(InlineChatWidget, {
                        ref_key: "chat",
                        ref: chat,
                        height: 550
                      }, null, 512)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["width"]))
    : _createCommentVNode("", true)
}
}

})