import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "d-flex header" }
const _hoisted_3 = { class: "button" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_5 = {
  key: 0,
  class: "title button"
}
const _hoisted_6 = ["onClick"]

import {computed, ref} from 'vue';
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {IBeforeLeftMenuDropdown} from "~/core-ui/ts/router/Interface";
import MenuBadgeDisplay from "~/cabinet/vue/interface/menu/MenuBadgeDisplay.vue";
import {useRoute, useRouter} from "vue-router";
import {useMyRouter} from "~/core-ui/ts/router/useMyRouter";


const __default__ = {
    name: "LcabMenuDropdown"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    menu: {},
    maxWidth: {},
    configDisabled: { type: Boolean },
    backIsListening: { type: Boolean },
    configIsListening: { type: Boolean }
  },
  emits: ["back", "config", "config-dropdown-command"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let opened = ref(false);

let route = useRoute();

let currentMenuItem = computed(() => {
    let items = props.menu.items;
    let result = items.find(item => item.selected);
    if (!result) {
        result = props.menu.items.find(
            item => item.isMatchedToRoute(route)
        );
    }
    return result;
});

function openDropdownMenu() {
    if (menuLength.value > 1) {
        opened.value = !opened.value;
    }
}

let menuLength = computed(() => {
    return props.menu.items.filter(item => item.isSelectable).length;
});

let selectedDescr = computed(() => {
    return currentMenuItem.value ? currentMenuItem.value.descr : "Неизвестно";
});

let router = useRouter();

async function onItemSelect(item: MenuItem) {
    try {
        opened.value = false;
        if (item.onClick) {
            item.onClick();
        } else {
            await useMyRouter().push(item.getRouterLocation(route));
        }
    } catch (e) {

    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_unref(menuLength) > 1 || !_ctx.menu.hideWhenItemsLessThan2)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
            'side-menu-dropdown': true,
            opened: _unref(opened)
        })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.menu.back || _ctx.backIsListening)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menu.back ? 'router-link' : 'div'), {
                  key: 0,
                  to: _ctx.menu.back,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('back'))),
                  class: "button back"
                }, {
                  default: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, {
                      light: "",
                      icon: "reply"
                    })
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true),
            (!_ctx.configDisabled)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.configIsListening)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('config'))),
                        class: "button"
                      }, [
                        _createVNode(FontAwesomeIcon, {
                          light: "",
                          icon: "cog"
                        })
                      ]))
                    : (_ctx.$slots.dropdown)
                      ? (_openBlock(), _createBlock(_component_el_dropdown, {
                          key: 1,
                          trigger: "click",
                          placement: "bottom-start",
                          onCommand: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('config-dropdown-command', $event)))
                        }, {
                          dropdown: _withCtx(() => [
                            _createVNode(_component_el_dropdown_menu, null, {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "dropdown")
                              ]),
                              _: 3
                            })
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(FontAwesomeIcon, {
                                light: "",
                                icon: "cog"
                              })
                            ])
                          ]),
                          _: 3
                        }))
                      : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(['button d-flex flex-fill', _unref(menuLength) < 2 ? 'unclickable' : null]),
              onClick: openDropdownMenu
            }, [
              _createElementVNode("div", {
                class: "flex-fill pr-2 text-truncate",
                style: _normalizeStyle({maxWidth: _ctx.maxWidth})
              }, [
                (_unref(currentMenuItem) && _unref(currentMenuItem).icon)
                  ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                      key: 0,
                      icon: _unref(currentMenuItem).icon,
                      class: _normalizeClass([`text-${_unref(currentMenuItem).iconColor}`, "mr-1"]),
                      "icon-style": _unref(currentMenuItem).iconColor ? 'solid' : 'regular'
                    }, null, 8, ["icon", "class", "icon-style"]))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_unref(selectedDescr)), 1)
              ], 4),
              (_unref(menuLength) > 1)
                ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                    key: 0,
                    class: "chevron",
                    icon: _unref(opened) ? 'chevron-up' : 'chevron-down'
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          (_unref(opened) && _unref(menuLength) > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.items, (item) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (item.isTitle)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.descr), 1))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: ($event: any) => (onItemSelect(item)),
                          class: _normalizeClass({
                            button: true,
                            selected: item === _unref(currentMenuItem)
                        })
                        }, [
                          (item.icon)
                            ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                                key: 0,
                                icon: item.icon,
                                class: _normalizeClass([`text-${item.iconColor}`, "mr-1"]),
                                "icon-style": item.iconColor ? 'solid' : 'regular'
                              }, null, 8, ["icon", "class", "icon-style"]))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(item.descr) + " ", 1),
                          (item.badge)
                            ? (_openBlock(), _createBlock(MenuBadgeDisplay, {
                                key: 1,
                                "menu-badge": item.badge
                              }, null, 8, ["menu-badge"]))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_6))
                  ], 64))
                }), 256))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})