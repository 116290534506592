import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-2" }

import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import {computed, provide} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import HtmlInputWithVariables from "~/cabinet/vue/interface/tinymce/HtmlInputWithVariables.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import BigIconFlexButtonWrapper from "~/cabinet/vue/interface/button/BigIconFlexButtonWrapper.vue";
import BigRadioFlexButton from "~/cabinet/vue/interface/button/BigRadioFlexButton.vue";
import HtmlConstructor from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructor.vue";
import {
    HTML_CONSTRUCTOR_NEWS_TYPE_ID
} from "~/cabinet/vue/interface/htmlConstructor/constructorType/ConstructorTypeList";
import {
    ARTICLE_CONTENT_TYPE_CONSTRUCTOR,
    ARTICLE_CONTENT_TYPE_HTML,
    ArticleContentTypeId, BlogArticleCategoryInterface,
    BlogArticleInterface
} from "~/cabinet/vue/dealer/blog/BlogArticleInterface";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import FileManager from "~/cabinet/vue/interface/form/FileManager.vue";
import {FileManagerRootInjectionKey} from "~/cabinet/vue/interface/form/FileManagerRootInjection";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";
import MyDateTimePicker from "~/core-ui/vue/ui/MyDateTimePicker.vue";

interface ArticleEditResponseInterface {
    article: BlogArticleInterface,
    domainsList: string[],
    dealerNetType: {
        id: number,
        descr: string
    }[],
    clientTypeList: {
        id: string,
        descr: string
    }[],
    categories: BlogArticleCategoryInterface[];
}

const __default__ = {
    name: "ArticleEditDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    id: {},
    copyFromId: {},
    repostId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["reload"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let visible = _useModel<boolean>(__props, "visible");

let request = computed(() => {
    let result = new ReactiveRequest(new LcabApiRequest<ArticleEditResponseInterface>({
        url: `/api/dealer/blog/getEditArticleDialog`,
        p: {
            id: props.id,
            copyFromId: props.copyFromId,
            repostId: props.repostId
        }
    }));
    result.setErrorCallback(() => {
        visible.value = false;
    });
    return result;
});

provide(FileManagerRootInjectionKey, computed(() => `blog/article/${request.value.data?.article.fileManagerId}`));

let availableUslugiList = AccountStore.availableUslugiList;

let response = computed<ArticleEditResponseInterface>(() => request.value.data);

let articleContentTypes: {
    id: ArticleContentTypeId,
    descr: string
}[] = [
    {
        id: ARTICLE_CONTENT_TYPE_HTML,
        descr: "Простой редактор"
    },
    {
        id: ARTICLE_CONTENT_TYPE_CONSTRUCTOR,
        descr: "Конструктор"
    }
];

async function saveArticle() {
    let result = await LcabApiRequest.save({
        url: `/api/dealer/blog/saveArticle`,
        p: response.value.article
    });
    if (result.isSuccess) {
        emits("reload");
        visible.value = false;
    }
}

function onConstructorInput(value: any) {
    response.value.article.content.constructor = value;
}


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(MyDialog, {
    "apply-disabled": !_unref(request).data,
    dialog: {
            width: '900px'
        },
    form: {
            model: _unref(response) ? _unref(response).article : {},
            labelPosition: 'left',
            labelWidth: '180px'
        },
    visible: _unref(visible),
    "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    title: _ctx.id ? 'Редактирование статьи' : 'Создание статьи',
    onApply: saveArticle
  }, {
    default: _withCtx(() => [
      _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
        default: _withCtx(() => [
          (_unref(request).data)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_form_item, {
                  rules: _unref(requiredRule),
                  label: "Заголовок",
                  props: "title"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(response).article.title,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(response).article.title) = $event)),
                      placeholder: "Введите заголовок статьи"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_el_switch, {
                        modelValue: _unref(response).article.isPopupEnabled,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(response).article.isPopupEnabled) = $event)),
                        class: "mr-1"
                      }, null, 8, ["modelValue"]),
                      _cache[19] || (_cache[19] = _createTextVNode(" Показывать пользователям всплывающее окно со статьей при входе в кабинет. "))
                    ]),
                    (_unref(AccountStore).isSuperDealer.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_el_switch, {
                            modelValue: _unref(response).article.isOnlyInCabinet,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(response).article.isOnlyInCabinet) = $event)),
                            class: "mr-1"
                          }, null, 8, ["modelValue"]),
                          _cache[20] || (_cache[20] = _createTextVNode(" Показывать статью только в личном кабинете "))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createVNode(_component_el_form_item, { label: "Адрес статьи" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(response).article.alias,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(response).article.alias) = $event)),
                      placeholder: "Введите название статьи для адресной строки статьи"
                    }, null, 8, ["modelValue"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[21] || (_cache[21] = [
                        _createElementVNode("p", null, "Можете оставить пустым, тогда системе сгенерирует его сама.", -1),
                        _createElementVNode("p", null, "Адрес может содержать только латинские символы, цифры и дефис.", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Категория статьи" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _unref(response).article.categoryId,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(response).article.categoryId) = $event)),
                      class: "w-100",
                      placeholder: "Без категории"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(response).categories, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.descr,
                            value: item.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "SEO: Title" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(response).article.seoTitle,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(response).article.seoTitle) = $event)),
                      placeholder: "Введите заголовок страницы для SEO"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "SEO: Description" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(response).article.seoDescription,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(response).article.seoDescription) = $event)),
                      placeholder: "Введите description страницы для SEO"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Обложка статьи" }, {
                  default: _withCtx(() => [
                    (_unref(response).article.coverImage)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          ref: "img",
                          src: _unref(response).article.coverImage,
                          class: "coverImage mb-3"
                        }, null, 8, _hoisted_3))
                      : _createCommentVNode("", true),
                    _createVNode(FileManager, {
                      modelValue: _unref(response).article.coverImage,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(response).article.coverImage) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          plain: "",
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(response).article.coverImage ? 'Выбрать другую' : 'Выбрать'), 1)
                          ]),
                          _: 1
                        }),
                        (_unref(response).article.coverImage)
                          ? (_openBlock(), _createBlock(_component_el_button, {
                              key: 0,
                              link: "",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(response).article.coverImage = null))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(FontAwesomeIcon, { icon: "times" })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, {
                  rules: _unref(requiredRule),
                  label: "Текст статьи",
                  props: "text"
                }, {
                  default: _withCtx(() => [
                    _createVNode(BigIconFlexButtonWrapper, { class: "mb-4" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(articleContentTypes), (type, key) => {
                          return (_openBlock(), _createBlock(BigRadioFlexButton, {
                            key: key,
                            modelValue: _unref(response).article.content.typeId,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(response).article.content.typeId) = $event)),
                            descr: type.descr,
                            label: type.id,
                            disabled: !!_unref(response).article.id
                          }, null, 8, ["modelValue", "descr", "label", "disabled"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    (_unref(response).article.content.typeId == _unref(ARTICLE_CONTENT_TYPE_HTML))
                      ? (_openBlock(), _createBlock(HtmlInputWithVariables, {
                          key: 0,
                          modelValue: _unref(response).article.content.html,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(response).article.content.html) = $event)),
                          height: 400,
                          inline: false
                        }, null, 8, ["modelValue"]))
                      : (_unref(response).article.content.typeId == _unref(ARTICLE_CONTENT_TYPE_CONSTRUCTOR))
                        ? (_openBlock(), _createBlock(HtmlConstructor, {
                            key: 1,
                            type: _unref(HTML_CONSTRUCTOR_NEWS_TYPE_ID),
                            modelValue: _unref(response).article.content.constructor,
                            "onUpdate:modelValue": [
                              _cache[11] || (_cache[11] = ($event: any) => ((_unref(response).article.content.constructor) = $event)),
                              onConstructorInput
                            ]
                          }, null, 8, ["type", "modelValue"]))
                        : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["rules"]),
                _createVNode(_component_el_form_item, { label: "Получатели статьи" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_select, {
                        modelValue: _unref(response).article.dealerNetTypeId,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(response).article.dealerNetTypeId) = $event)),
                        class: "w-100"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(response).dealerNetType, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.id,
                              label: item.descr,
                              value: item.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_select, {
                        modelValue: _unref(response).article.clientTypeId,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(response).article.clientTypeId) = $event)),
                        class: "w-100"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(response).clientTypeList, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.id,
                              label: item.descr,
                              value: item.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Услуги" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _unref(response).article.uslugi,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(response).article.uslugi) = $event)),
                      class: "w-100",
                      multiple: "",
                      placeholder: "Если не выбрано ни одной, то доступно всем"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableUslugiList), (usluga) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: usluga.id,
                            label: usluga.descr,
                            value: usluga.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode(" Вы можете разрешить просмотр статьи только тем клиентам, у кого доступны определенные услуги ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Домен" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _unref(response).article.dealerDomains,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(response).article.dealerDomains) = $event)),
                      class: "w-100",
                      multiple: "",
                      placeholder: "Если не выбрано ни одного, то доступно всем"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(response).domainsList, (domain) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: domain,
                            value: domain
                          }, null, 8, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(HelpBlock, null, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode(" Вы можете разрешить просмотр статьи только тем клиентам, кто работает на определенных доменах ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, { label: "Отложенная публикация" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(response).article.dateTimePublishEnabled,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(response).article.dateTimePublishEnabled) = $event))
                    }, null, 8, ["modelValue"]),
                    (_unref(response).article.dateTimePublishEnabled)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(MyDateTimePicker, {
                            modelValue: _unref(response).article.dateTimePublish,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(response).article.dateTimePublish) = $event))
                          }, null, 8, ["modelValue"]),
                          _createVNode(HelpBlock, null, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode(" Время - местное для нашей организации ")
                            ])),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["request"])
    ]),
    _: 1
  }, 8, ["apply-disabled", "form", "visible", "title"]))
}
}

})