import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-red" }

import {computed} from 'vue';
import MobileCountryHeader from "~/cabinet/vue/dealer/config/uslugi/tarif/voice/MobileCountryHeader.vue";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import ISenderCreateResult from "~/cabinet/vue/client/sender/send/create/ISenderCreateResult";
import StringHelper from "~/ts/library/StringHelper";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "AnotherSourcesSection"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    showSourceWarningBlock: { type: Boolean },
    senderResult: {},
    isSuccess: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let senderReportWithAnotherSource = computed(() => {
    if (props.senderResult && props.senderResult.sendReport) {
        return props.senderResult.sendReport.filter(item => item.sourceId != null);
    }
    return [];
});

let anotherSourceOperator = computed(() => {
    return StringHelper.getSclon(senderReportWithAnotherSource.value.length, "%d оператор", "%d оператора", "%d операторов");
});


return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.showSourceWarningBlock && _unref(senderReportWithAnotherSource).length > 0)
    ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
        key: 0,
        "icon-color": _ctx.isSuccess ? 'red' : null,
        value: _unref(__)(_unref(anotherSourceOperator), _unref(senderReportWithAnotherSource).length),
        icon: "signature",
        label: "Другие имена отправителя",
        light: ""
      }, {
        append: _withCtx(() => [
          _createVNode(_component_el_table, {
            data: _unref(senderReportWithAnotherSource),
            "show-header": false,
            class: "mt-2 no-last-tr-border"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, null, {
                default: _withCtx(({row}) => [
                  (row.iconId)
                    ? (_openBlock(), _createBlock(MobileCountryHeader, {
                        key: 0,
                        descr: row.descr,
                        id: row.iconId
                      }, null, 8, ["descr", "id"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(row.descr), 1)
                      ], 64))
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, null, {
                default: _withCtx(({row}) => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(row.sourceId), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }, 8, ["icon-color", "value"]))
    : _createCommentVNode("", true)
}
}

})