import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "pr-2" }
const _hoisted_3 = { class: "flex-fill d-flex min-width-0" }
const _hoisted_4 = { class: "w-50 pr-2" }
const _hoisted_5 = { class: "w-50 pr-2" }

import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import {AttachmentsConfigModelInterface} from "~/cabinet/vue/interface/AttachmentsConfigInterface";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import AbstractUploadHelper from "~/ts/library/AbstractUploadHelper";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";


const __default__ = {
    name: "AttachmentsConfig"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    modelValue: {},
    uploadHelper: {},
    contentVariables: {}
  },
  setup(__props: any) {

let props = __props;

const uploadAction = props.uploadHelper
    .setLongStore()
    .setTag("attachmentsConfig")
    .getUrl();

function addAttachment() {
    props.modelValue.push({
        url: null,
        filename: null,
        size: null
    });
}

async function deleteAttachment(attachment: AttachmentsConfigModelInterface) {
    try {
        if (attachment.url) {
            await ElConfirm("Вы уверены, что хотите удалить это вложение?");
        }
        toggleArrayElement(props.modelValue, attachment, false);
    } catch (e) {

    }
}

function getUploadHandler(attachment: AttachmentsConfigModelInterface) {
    return props.uploadHelper.getHandler((idsList, files) => {
        if (files[0]) {
            attachment.url = files[0].url;
            attachment.filename = files[0].filename;
            attachment.size = files[0].size;
        }
    })
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (attachment, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mb-3"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_upload, {
              class: "d-inline-block",
              action: _unref(uploadAction),
              multiple: false,
              "on-change": getUploadHandler(attachment),
              "show-file-list": false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, null, {
                  default: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, { icon: "upload" })
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["action", "on-change"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.contentVariables)
                ? (_openBlock(), _createBlock(TextInputWithVariables, {
                    key: 0,
                    modelValue: attachment.url,
                    "onUpdate:modelValue": ($event: any) => ((attachment.url) = $event),
                    placeholder: "Ссылка на файл",
                    class: "w-100",
                    "content-variables": _ctx.contentVariables,
                    multiline: false
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables"]))
                : (_openBlock(), _createBlock(_component_el_input, {
                    key: 1,
                    modelValue: attachment.url,
                    "onUpdate:modelValue": ($event: any) => ((attachment.url) = $event),
                    placeholder: "Ссылка на файл",
                    class: "w-100"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.contentVariables)
                ? (_openBlock(), _createBlock(TextInputWithVariables, {
                    key: 0,
                    modelValue: attachment.filename,
                    "onUpdate:modelValue": ($event: any) => ((attachment.filename) = $event),
                    placeholder: "Название файла",
                    class: "w-100",
                    "content-variables": _ctx.contentVariables,
                    multiline: false
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "content-variables"]))
                : (_openBlock(), _createBlock(_component_el_input, {
                    key: 1,
                    modelValue: attachment.filename,
                    "onUpdate:modelValue": ($event: any) => ((attachment.filename) = $event),
                    placeholder: "Название файла",
                    class: "w-100"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(MyIconButton, {
              delete: "",
              onClick: ($event: any) => (deleteAttachment(attachment))
            }, null, 8, ["onClick"])
          ])
        ])
      ]))
    }), 128)),
    _createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (addAttachment()))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("Добавить вложения")
      ])),
      _: 1
    })
  ]))
}
}

})