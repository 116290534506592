import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ISenderFormContact, ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import MyFlipContainer from "~/cabinet/vue/interface/transition/MyFlipContainer.vue";
import SenderReceiverSelectGroups from "./SenderReceiverSelectGroups.vue";
import SenderReceiverSelectContacts from "./SenderReceiverSelectContacts.vue";
import WindowHelper from "~/ts/library/WindowHelper";
import Delay from "~/ts/library/Delay";
import {computed, ref, watch} from "vue";
import {SenderFormReceiversInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {useSenderReceiversComponent} from "~/cabinet/vue/client/sender/send/receiverSelect/useSenderReceiversComponent";


const __default__ = {
    name: "SenderReceiversForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    contacts: {},
    visible: { type: Boolean },
    groups: { default: (): ISenderFormGroup[] => [] }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["close", "update:groups"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let _modelValue = _useModel<SenderFormReceiversInterface>(__props, "modelValue");
let model = useSenderReceiversComponent(_modelValue);
let isChanged = ref(false);
let currentGroup = ref<ISenderFormGroup>();

watch(model, () => isChanged.value = true, {deep: true})

watch(computed(() => props.visible), async () => {
    isChanged.value = false;
    await Delay.make(1000);
    isChanged.value = false;
});


async function closeGroup() {
    await WindowHelper.scrollToTop();
    currentGroup.value = null;
}

let contactsDisplayed = computed(() => {
    return !!currentGroup.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyFlipContainer, { "model-value": _unref(contactsDisplayed) }, {
    front: _withCtx(() => [
      _createVNode(SenderReceiverSelectGroups, {
        modelValue: _unref(model).groups,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).groups) = $event)),
        "contactbook-data-source-id": _unref(model).contactbookDataSourceId,
        "selected-contacts": _unref(model).contacts,
        "onUpdate:selectedContacts": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).contacts) = $event)),
        contacts: _ctx.contacts,
        groups: _ctx.groups,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_unref(emits)('close'))),
        "usluga-id": _ctx.uslugaId,
        ref: "groups",
        onOpenGroup: _cache[3] || (_cache[3] = ($event: any) => (_isRef(currentGroup) //@ts-ignore
 ? currentGroup.value = $event : currentGroup = $event)),
        "onUpdate:groups": _cache[4] || (_cache[4] = ($event: any) => (_unref(emits)('update:groups', $event))),
        onContactsChange: _cache[5] || (_cache[5] = ($event: any) => (_unref(model).contacts = $event)),
        visible: _ctx.visible,
        "is-changed": _unref(isChanged)
      }, null, 8, ["modelValue", "contactbook-data-source-id", "selected-contacts", "contacts", "groups", "usluga-id", "visible", "is-changed"])
    ]),
    back: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(SenderReceiverSelectContacts, {
          visible: _unref(contactsDisplayed),
          "contactbook-data-source-id": _unref(model).contactbookDataSourceId,
          group: _unref(currentGroup),
          contacts: _ctx.contacts,
          modelValue: _unref(model).contacts,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(model).contacts) = $event)),
          uslugaId: _ctx.uslugaId,
          onCloseGroup: _cache[7] || (_cache[7] = ($event: any) => (closeGroup())),
          onChanged: _cache[8] || (_cache[8] = ($event: any) => (_isRef(isChanged) //@ts-ignore
 ? isChanged.value = true : isChanged = true))
        }, null, 8, ["visible", "contactbook-data-source-id", "group", "contacts", "modelValue", "uslugaId"])
      ])
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})