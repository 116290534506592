import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "w-50 pr-1" }
const _hoisted_3 = { class: "w-50 pl-1" }

import TarifPriceInput from "~/cabinet/vue/dealer/config/uslugi/tarif/common/TarifPriceInput.vue";
import {useTarifPriceProps} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/useTarifPrice";
import {
    SingleTarifPrice
} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";
import TarifPriceCurrencySelector
    from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/TarifPriceCurrencySelector.vue";


const __default__ = {
    name: "SinglePriceEdit"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTarifPriceProps<SingleTarifPrice>()
},
  setup(__props) {

let props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TarifPriceInput, {
        modelValue: _unref(props).modelValue.sum,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(props).modelValue.sum) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TarifPriceCurrencySelector, {
        modelValue: _unref(props).modelValue.currencyId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(props).modelValue.currencyId) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}
}

})