import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import ISenderCreateResult from "~/cabinet/vue/client/sender/send/create/ISenderCreateResult";
import SenderCreateConfirmationInfo
    from "~/cabinet/vue/client/sender/send/create/confirmation/SenderCreateConfirmationInfo.vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import MyRemoteData from "~/cabinet/vue/interface/data/MyRemoteData.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {SenderFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {computed, ref} from "vue";


const __default__ = {
    name: "SenderCreateConfirmation"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    formId: {},
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["send"], ["update:modelValue", "update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let model = _useModel<SenderFormModelInterface<any>>(__props, "modelValue")

let emits = __emit;

let visible = _useModel<boolean>(__props, "visible");

let result = ref<LcabApiResult<any>>();
let allowRepeat = ref(false);

let isSuccess = computed(() => result.value?.isSuccess);

async function onResult(newResult: LcabApiResult<any>) {
    if (newResult.code == -854) {
        try {
            await ElConfirm(newResult.descr);
            allowRepeat.value = true;
        } catch (e) {
            back();
            return;
        }
    } else {
        result.value = newResult;
    }
}

let valueForConfirm = computed(() => {
    return {...model.value, allowRepeat: allowRepeat.value};
});

function back() {
    visible.value = false;
}

async function send() {
    back();
    emits("send");
}

let isLoadingNow = computed(() => {
    return result.value == null;
});


let senderResult = computed<ISenderCreateResult>(() => {
    return result.value?.getData();
});


let previewComponent = computed(() => {
    return confirmConfig.value.taskPreviewComponent;
});

let infoComponent = computed(() => {
    let result = confirmConfig.value.taskInfoComponent;
    return result && !isLoadingNow.value ? result : SenderCreateConfirmationInfo;
});

let previewLargeSpan = computed(() => {
    return confirmConfig.value.lg;
});

let previewMiddleSpan = computed(() => {
    return confirmConfig.value.md;
});


let confirmConfig = computed(() => {
    return SenderUslugi[props.uslugaId].create.confirm;
});

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(MyRemoteData, {
    "data-params": _unref(valueForConfirm),
    "data-url": `/api/sender/${_ctx.uslugaId}/${_ctx.formId}/validateCreateForm`,
    "show-error": false,
    silent: "",
    onResult: onResult,
    onLoadStart: _cache[1] || (_cache[1] = ($event: any) => (_isRef(result) //@ts-ignore
 ? result.value = null : result = null))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { class: "el-row-root flex-row-reverse" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            lg: 24 - _unref(previewLargeSpan),
            md: 24 - _unref(previewMiddleSpan)
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(infoComponent)), {
                modelValue: _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                back: back,
                result: _unref(result),
                send: send,
                "usluga-id": _ctx.uslugaId
              }, null, 8, ["modelValue", "result", "usluga-id"]))
            ]),
            _: 1
          }, 8, ["lg", "md"]),
          _createVNode(_component_el_col, {
            lg: _unref(previewLargeSpan),
            md: _unref(previewMiddleSpan)
          }, {
            default: _withCtx(() => [
              (_unref(previewComponent))
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(previewComponent)), {
                    key: 0,
                    loading: _unref(isLoadingNow),
                    "sender-result": _unref(isSuccess) ? _unref(senderResult) : null,
                    "usluga-id": _ctx.uslugaId
                  }, null, 8, ["loading", "sender-result", "usluga-id"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["lg", "md"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data-params", "data-url"]))
}
}

})