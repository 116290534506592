import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "d-inline-block",
  style: {"width":"5em"}
}

import TarifPriceInput from "~/cabinet/vue/dealer/config/uslugi/tarif/common/TarifPriceInput.vue";
import {usePagerPriceProps} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/priceTypes/usePagerPrice";
import {PartPagerTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerTarifPrice";
import TarifPriceCurrencySelector
    from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/TarifPriceCurrencySelector.vue";


const __default__ = {
    name: "PagerPartPriceEdit"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...usePagerPriceProps<PartPagerTarifPrice>()
},
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceParts, (part, index) => {
      return (_openBlock(), _createBlock(TarifPriceInput, {
        key: index,
        modelValue: _unref(props).modelValue.prices[part.newKey],
        "onUpdate:modelValue": ($event: any) => ((_unref(props).modelValue.prices[part.newKey]) = $event)
      }, {
        prepend: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(part.descr), 1)
        ]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue"]))
    }), 128)),
    _createVNode(TarifPriceCurrencySelector, {
      modelValue: _unref(props).modelValue.currencyId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(props).modelValue.currencyId) = $event)),
      class: "mt-2"
    }, null, 8, ["modelValue"])
  ]))
}
}

})