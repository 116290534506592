import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots } from "vue"

import {computed} from 'vue';
import MobileCountryHeader from "~/cabinet/vue/dealer/config/uslugi/tarif/voice/MobileCountryHeader.vue";
import Currency from "~/cabinet/vue/interface/Currency.vue";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import ISenderCreateResult from "~/cabinet/vue/client/sender/send/create/ISenderCreateResult";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "PriceSection"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    senderResult: {},
    isSuccess: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let isCountDescrExist = computed(() => {
    return props.senderResult?.sendReport?.find(item => item.countDescr != null);
});

let priceDescr = computed(() => {
    let priceDescr = SenderUslugi[props.senderResult.uslugaId].create.confirm.priceDescr;
    if (!priceDescr) {
        priceDescr = __("Стоимость рассылки");
    }
    return priceDescr;
});

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.senderResult && _ctx.senderResult.price)
    ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
        key: 0,
        "icon-color": _ctx.isSuccess ? 'green' : null,
        label: _unref(priceDescr),
        icon: "badge-dollar",
        light: ""
      }, _createSlots({
        value: _withCtx(() => [
          _createVNode(Currency, {
            price: _ctx.senderResult.price
          }, null, 8, ["price"])
        ]),
        _: 2
      }, [
        (_ctx.senderResult.sendReport && _ctx.senderResult.sendReport.length)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createVNode(_component_el_table, {
                  data: _ctx.senderResult.sendReport,
                  "show-header": false,
                  class: "mt-2 no-last-tr-border",
                  "max-height": "400px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      "min-width": "200px",
                      prop: "descr"
                    }, {
                      default: _withCtx(({row}) => [
                        (row.iconId)
                          ? (_openBlock(), _createBlock(MobileCountryHeader, {
                              key: 0,
                              descr: row.descr,
                              id: row.iconId
                            }, null, 8, ["descr", "id"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(row.descr), 1)
                            ], 64))
                      ]),
                      _: 1
                    }),
                    (_unref(isCountDescrExist))
                      ? (_openBlock(), _createBlock(_component_el_table_column, { key: 0 }, {
                          default: _withCtx(({row}) => [
                            _createTextVNode(_toDisplayString(row.count) + " " + _toDisplayString(row.countDescr), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_table_column, null, {
                      default: _withCtx(({row}) => [
                        _createTextVNode(_toDisplayString(row.recipientsCount) + " аб. ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, { "class-name": "text-right" }, {
                      default: _withCtx(({row}) => [
                        (row.price)
                          ? (_openBlock(), _createBlock(Currency, {
                              key: 0,
                              price: row.price
                            }, null, 8, ["price"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["icon-color", "label"]))
    : _createCommentVNode("", true)
}
}

})