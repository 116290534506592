import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ITextareaFieldMixin} from "../Interfaces";
import TextElementConstructor from "~/cabinet/vue/interface/form/elements/constructor/TextElementConstructor.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "TextareaElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let _modelValue = _useModel<ITextareaFieldMixin>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    upload: false
}));

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(TextElementConstructor, {
    "model-value": _unref(model),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Возможность прикрепить файл" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: _unref(model).upload,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).upload) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})