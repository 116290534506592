import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

import {IUslugaAccess} from "~/cabinet/vue/client/sender/send/Interfaces";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";


const __default__ = {
    name: "UslugaAccessCard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    access: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      (_ctx.access.isBanned)
        ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
            key: 0,
            icon: "lock",
            "icon-color": "red",
            value: "Услуга заблокирована",
            "gray-label": false
          }, {
            label: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Свяжитесь с нами по телефону или с помощью раздела "),
              _createElementVNode("a", {
                target: "_blank",
                href: "/pm"
              }, "Техподдержка", -1),
              _createTextVNode(", чтобы узнать почему это произошло и как разрешить данную проблему. ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.access.isDisable)
        ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
            key: 1,
            icon: "toggle-off",
            "icon-color": "yellow",
            value: "Услуга отключена",
            "gray-label": false
          }, {
            label: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Свяжитесь с нами по телефону или с помощью раздела "),
              _createElementVNode("a", {
                target: "_blank",
                href: "/pm"
              }, "Техподдержка", -1),
              _createTextVNode(", чтобы узнать почему это произошло и как разрешить данную проблему. ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.access.isTarifNull)
        ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
            key: 2,
            icon: "coins",
            "icon-color": "purple",
            value: "У вас не настроен тариф",
            "gray-label": false
          }, {
            label: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Свяжитесь с нами по телефону или с помощью раздела "),
              _createElementVNode("a", {
                target: "_blank",
                href: "/pm"
              }, "Техподдержка", -1),
              _createTextVNode(", чтобы узнать почему это произошло и как разрешить данную проблему. ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})