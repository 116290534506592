import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Currency from "~/cabinet/vue/interface/Currency.vue";
import {computed} from "vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {useTarifPriceProps} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/useTarifPrice";
import {ScalePagerTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerTarifPrice";


const __default__ = {
    name: "PagerScalePriceTableCell"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useTarifPriceProps<ScalePagerTarifPrice>()
},
  setup(__props) {

let props = __props;

let pricesAsArray = computed(() => ObjectHelper.arrayFromDictionary(props.modelValue.prices));
let minSum = computed(() => Math.min(...pricesAsArray.value));
let maxSum = computed(() => Math.max(...pricesAsArray.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(Currency, {
      "currency-id": _unref(props).modelValue.currencyId,
      sum: _unref(maxSum),
      "short-descr": ""
    }, null, 8, ["currency-id", "sum"]),
    _cache[0] || (_cache[0] = _createTextVNode(" → ")),
    _createVNode(Currency, {
      "currency-id": _unref(props).modelValue.currencyId,
      sum: _unref(minSum),
      "short-descr": ""
    }, null, 8, ["currency-id", "sum"])
  ]))
}
}

})