import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import GradientPickerHelper from "~/core-ui/vue/ui/gradient-picker/GradientPickerHelper";
import {BackgroundColor} from "~/core-ui/vue/ui/gradient-picker/Interface";


const __default__ = {
    name: "ColorPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    color: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    style: _normalizeStyle({
            background: _unref(GradientPickerHelper).getCssBackground(_ctx.color)
        }),
    class: "preview"
  }, null, 4))
}
}

})