import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import {computed} from 'vue';
import {FILTER_OPERATION_ARRAY_CONTAINS_ALL} from "~/cabinet/vue/interface/filter/Operations";
import TagInterface from "~/cabinet/vue/interface/form/tags/TagInterface";
import FilterableContentWrapperInterface from "~/cabinet/vue/interface/data/FilterableContentWrapperInterface";


const __default__ = {
    name: "Tags"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    tags: { default: () => ([]) },
    type: { default: "info" },
    effect: {},
    filterableContentWrapper: {},
    filterId: {},
    maximum: {},
    withBorder: { type: Boolean },
    possibleTags: {},
    clickable: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let isFilterable = computed(() => {
    return !!(props.filterableContentWrapper && props.filterId);
});

function onClick(event: MouseEvent, tag: string) {
    if (isFilterable.value) {
        event.stopPropagation();
        props.filterableContentWrapper.addFilterValue({
            id: props.filterId,
            operation: FILTER_OPERATION_ARRAY_CONTAINS_ALL,
            value: tag
        });
    }
    emits("click", tag);
}

let computedTags = computed(() => {
    return props.tags.map(tag => {
        if (typeof tag == "string") {
            if (props.possibleTags) {
                let item = props.possibleTags.find(item => item.tag == tag);
                if (item) {
                    tag = item;
                }
            }
            if (typeof tag == "string") {
                tag = {
                    tag,
                    color: null,
                    cc: null
                };
            }
        }
        return tag;
    });
});

function getTagStyle(tag: TagInterface) {
    if (tag.color) {
        if (props.effect == "plain") {
            return {
                background: "transparent",
                color: tag.color,
                borderColor: tag.color
            }
        } else {
            return {
                background: tag.color,
                color: "#fff"
            }
        }
    }
    return null;
}

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'tags': true,
            'with-border': _ctx.withBorder
        })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedTags), (tag, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
        (!_ctx.maximum || key <= _ctx.maximum)
          ? (_openBlock(), _createBlock(_component_el_tooltip, {
              key: 0,
              disabled: !_unref(isFilterable),
              content: "Кликните, чтобы отфильтровать по этому тэгу"
            }, {
              default: _withCtx(() => [
                (_ctx.maximum && key == _ctx.maximum)
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 0,
                      type: _ctx.type,
                      effect: _ctx.effect,
                      size: "small",
                      class: _normalizeClass({
                        'mr-1': true,
                        tag: true
                    })
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" +" + _toDisplayString(_unref(computedTags).length - _ctx.maximum), 1)
                      ]),
                      _: 1
                    }, 8, ["type", "effect"]))
                  : (!_ctx.maximum || key < _ctx.maximum)
                    ? _withDirectives((_openBlock(), _createBlock(_component_el_tag, {
                        key: 1,
                        type: _ctx.type,
                        effect: _ctx.effect,
                        style: _normalizeStyle(getTagStyle(tag)),
                        size: "small",
                        class: _normalizeClass({
                        'mr-1': true,
                        clickable: _unref(isFilterable) || _ctx.clickable,
                        'tag': true
                    })
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tag.tag), 1)
                        ]),
                        _: 2
                      }, 1032, ["type", "effect", "style", "class"])), [
                        [_directive_on_native, (e) => onClick(e, tag.tag), "click"]
                      ])
                    : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["disabled"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 2))
}
}

})