import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ref, watch, computed} from 'vue';


const __default__ = {
    name: "TarifPriceInput"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    modelValue: {},
    currency: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;
let localValue = ref<string | number>();

watch(computed(() => props.modelValue), () => {
    localValue.value = props.modelValue;
});

localValue.value = props.modelValue;

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    modelValue: _unref(localValue),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(localValue) ? (localValue).value = $event : localValue = $event)),
    type: "number",
    step: "0.01",
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('update:modelValue', _unref(localValue) != null ? (_unref(localValue) < 0 ? 0 : parseFloat(_unref(localValue))) : null)))
  }, _createSlots({
    default: _withCtx(() => [
      (_ctx.$slots.prepend)
        ? _renderSlot(_ctx.$slots, "prepend", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.currency)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.currency), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue"]))
}
}

})