import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


const __default__ = {
    name: "HelpBlock"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    warning: { type: Boolean },
    danger: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({help: true, 'warning-help': _ctx.warning, 'danger-help':_ctx.danger})
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})