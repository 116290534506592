import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = { class: "mt-2" }

import Align from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Align.vue";
import Href from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Href.vue";
import IImageBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/image/IImageBlockValues";
import HtmlConstructorImage from "~/cabinet/vue/interface/htmlConstructor/blocks/common/HtmlConstructorImage.vue";
import IHtmlConstructorImageLoadEventPayload
    from "~/cabinet/vue/interface/htmlConstructor/blocks/common/IHtmlConstructorImageLoadEventPayload";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "ImageForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockForm(props);

let _modelValue = _useModel<IImageBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

function onImgLoad(size: IHtmlConstructorImageLoadEventPayload) {
    let prevRatio = model.value.fullWidth / model.value.fullHeight;
    let fullWidth = size.width;
    let fullHeight = size.height;
    let ratio = fullWidth / fullHeight;

    if (ratio != prevRatio || fullWidth < model.value.fullWidth) {
        model.value.height = fullHeight;
        model.value.width = fullWidth;
    }
    model.value.fullWidth = fullWidth;
    model.value.fullHeight = fullHeight;
}

return (_ctx: any,_cache: any) => {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(HtmlConstructorImage, {
        modelValue: _unref(model).src,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).src) = $event)),
        onSize: onImgLoad
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_card, { class: "transparent-dashed-card" }, {
        default: _withCtx(() => [
          (_unref(model).src)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: "Размеры"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 10 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input_number, {
                            controls: false,
                            class: "w-100 text-center",
                            disabled: _unref(model).widthAuto || _unref(model).width100Percent,
                            "model-value": parseInt(_unref(model).width),
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(model).width = parseInt($event)))
                          }, null, 8, ["disabled", "model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        class: "text-center",
                        span: 4
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" × ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 10 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input_number, {
                            controls: false,
                            class: "w-100 text-center",
                            disabled: _unref(model).widthAuto || _unref(model).width100Percent,
                            "model-value": parseInt(_unref(model).height),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_unref(model).height = parseInt($event)))
                          }, null, 8, ["disabled", "model-value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(model).widthAuto,
                      "onUpdate:modelValue": [
                        _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).widthAuto) = $event)),
                        _cache[4] || (_cache[4] = ($event: any) => (_unref(model).width100Percent = false))
                      ],
                      class: "mr-1"
                    }, null, 8, ["modelValue"]),
                    _cache[10] || (_cache[10] = _createTextVNode(" Автоматическая ширина "))
                  ]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(model).width100Percent,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).width100Percent) = $event)),
                        _cache[6] || (_cache[6] = ($event: any) => (_unref(model).widthAuto = false))
                      ],
                      class: "mr-1"
                    }, null, 8, ["modelValue"]),
                    _cache[11] || (_cache[11] = _createTextVNode(" Растянуть на всю ширину "))
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(Align, {
        modelValue: _unref(model).align,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).align) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(Href, {
        modelValue: _unref(model).href,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).href) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})