import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {computed} from 'vue';
import {IFilter, IFiltersData, IOperation} from "~/cabinet/vue/interface/filter/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import MyFilterRow from "~/cabinet/vue/interface/filter/MyFilterRow.vue";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";


const __default__ = {
    name: "MyFilterRows"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    filter: {},
    filtersData: {},
    modelValue: {},
    showFilterDescr: { type: Boolean, default: true }
  },
  emits: ["operations-for-add", "operation-changed", "add-operation", "delete-operation", "delete-filter"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let usedOperationCount = computed(() => {
    let result = 0;
    for (let operationId in props.modelValue) {
        result++;
    }
    return result;
});

function onInput(operationId: string, value: any) {
    props.modelValue[operationId] = value;
}

let canDeleteFilter = computed(() => {
    return !props.filtersData.isStatic || selectedOperations.value.length > 1;
});

function operationById(operationId: string): IOperation | null {
    return MyFilterHelper.getOperations(props.filtersData)[operationId];
}

let selectedOperations = computed(() => {
    let result: string[] = [];
    for (let operationId in props.modelValue) {
        result.push(operationId);
    }
    return result;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (values, operationId, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: operationId }, [
        (operationById(operationId))
          ? (_openBlock(), _createBlock(MyFilterRow, {
              key: 0,
              filter: _ctx.filter,
              operation: operationById(operationId),
              "selected-operations": _unref(selectedOperations),
              "filters-data": _ctx.filtersData,
              "can-delete-filter": _unref(canDeleteFilter),
              class: _normalizeClass([index > 0 ? 'same-filter-id-as-previous' : 'different-filter-id-from-previous']),
              "show-add-operation-button": _unref(usedOperationCount) === index + 1,
              "model-value": _ctx.modelValue[operationId],
              "show-filter-descr": _ctx.showFilterDescr,
              "onUpdate:modelValue": ($event: any) => (onInput(operationId, $event)),
              onDelete: ($event: any) => (_unref(selectedOperations).length > 1 ? _unref(emits)('delete-operation', operationId) : _unref(emits)('delete-filter')),
              onOperationsForAdd: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('operations-for-add', $event))),
              onOperationChanged: ($event: any) => (_unref(emits)('operation-changed', { newOperation: $event, oldOperation: operationId })),
              onAddOperation: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('add-operation', $event)))
            }, null, 8, ["filter", "operation", "selected-operations", "filters-data", "can-delete-filter", "class", "show-add-operation-button", "model-value", "show-filter-descr", "onUpdate:modelValue", "onDelete", "onOperationChanged"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})