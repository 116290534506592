import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    computed,
    inject,
    provide,
    Ref,
    ref,
    watch,
    onActivated,
    onDeactivated
} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import {onBeforeRouteUpdate, RouteLocationMatched, useRoute} from "vue-router";
import {IsRouteActivatedKey, RouteNameInjectionKey} from "~/core-ui/vue/router/constants";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import MyRouterViewComponent from "~/core-ui/vue/router/MyRouterViewComponent.vue";


import {ComputedRef, InjectionKey} from "vue";

const RouteLevelInjectionKey = Symbol() as InjectionKey<ComputedRef<number>>;
const __default__ = {
    name: "MyRouterView"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const COMPONENT_NAME = "MyRouterView";


let route = useRoute();
let level = inject(RouteLevelInjectionKey, computed(() => 1));
let currentRoute = computed<RouteLocationMatched>(() => route.matched[level.value]);
let currentRouteKey = computed(() => {
    let route = currentRoute.value;
    let result = null;
    if (typeof route?.name == "string") {
        result = route.name as string;
    }
    return result;
});
provide(RouteLevelInjectionKey, computed(() => level.value + 1));
provide(RouteNameInjectionKey, computed(() => currentRoute.value?.name));
let isActivated = ref(true);
provide(IsRouteActivatedKey, computed(() => isActivated.value));

let keepAliveComponents: Ref<string[]> = ref<string[]>([COMPONENT_NAME]);
let lastPath = ref<Dictionary<{ fullPath: string, params: string }>>({});

onBeforeRouteUpdate((to, from, next) => {
    let redirect = lastPath.value[to.name as string];
    if (redirect && redirect.fullPath != to.fullPath && redirect.fullPath != from.fullPath) {
        if (redirect.params == JSON.stringify(to.params)) {
            return next({
                path: redirect.fullPath
            });
        }
    }
    next();
});

function onCurrentRouteUpdate() {
    let keepAlive = (currentRoute.value?.meta as IRouteMeta)?.keepAlive === true;
    if (keepAlive) {
        let component = currentRoute.value?.components?.default;
        if (component) {
            let componentName = component.name;
            if (componentName) {
                if (!keepAliveComponents.value.includes(componentName)) {
                    keepAliveComponents.value.push(componentName);
                }
            }
        }
    }
}

onActivated(() => isActivated.value = true);
onDeactivated(() => isActivated.value = false);

watch(currentRoute, onCurrentRouteUpdate);

onCurrentRouteUpdate();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    default: _withCtx(({Component}) => [
      _createVNode(MyRouterViewComponent, {
        "route-component": Component,
        "route-name": _unref(currentRouteKey),
        level: _unref(level)
      }, null, 8, ["route-component", "route-name", "level"])
    ]),
    _: 1
  }, 16))
}
}

})