import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import Random from "~/ts/library/Random";
import Events from "~/ts/library/Events";
import {computed, inject, ref} from "vue";
import {addDisposableEvent} from "~/ts/vuePlugins/useDisposableEvent";
import {FileManagerRootInjectionKey} from "~/cabinet/vue/interface/form/FileManagerRootInjection";


const __default__ = {
    name: "FileManager"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    directory: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let model = _useModel<string>(__props, "modelValue");

let fileManagerRootInjection = inject(FileManagerRootInjectionKey, null);

let showDialog = ref(false);
let instanceId = ref(Random.uid());

addDisposableEvent(Events.addEventListener("message", window, (event: MessageEvent) => {
    if (event.data.sender === 'responsivefilemanager') {
        if (event.data.field_id) {
            let fieldID = event.data.field_id;
            if (fieldID == instanceId.value) {
                model.value = event.data.url;
                close();
            }
        }
    }
}));

let directoryParam = computed(() => {
    let directory = props.directory ?? fileManagerRootInjection?.value;
    if (directory) {
        return `&root=${encodeURIComponent(directory)}`;
    }
    return null;
})

function open() {
    showDialog.value = true
}

function close() {
    showDialog.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", { onClick: open }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_unref(showDialog))
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          fullscreen: "",
          modelValue: _unref(showDialog),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(showDialog) ? (showDialog).value = $event : showDialog = $event)),
          title: "Файловый менеджер",
          class: "no-padding full-height-body overflow-hidden"
        }, {
          default: _withCtx(() => [
            _createElementVNode("iframe", {
              class: "iframe",
              src: `/filemanager/dialog.php?type=1&field_id=${_unref(instanceId)}&crossdomain=1&lang=ru${_unref(directoryParam)}`
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})