import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

import ContentEditable from "~/cabinet/vue/interface/form/ContentEditable.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import {
    useContentDisplay,
} from "~/cabinet/vue/interface/htmlConstructor/constructorType/useContentDisplay";
import {computed} from "vue";
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";

interface IStyle {
    mainBackground: string,
    mainBackgroundImage?: string,
    mainBackgroundRepeat?: string,
    mainBorderColor: string,
    mainBorderWidth: number,
    footerTextColor: string,
    footerTextFont: string,
    footerTextSize: number
}

interface IEmailConstructorAdditionalParams {
    textAfterContent: string
}

const __default__ = {
    name: "EmailContentDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let model = _useModel<IHtmlConstructorEditorModel<any>>(__props, "modelValue")
let {htmlConstructorEditor} = useContentDisplay();

let width = computed(() => {
    return `${htmlConstructorEditor.params.type.maxContentWidth}px`;
});

let style = computed<IStyle>(() => {
    return htmlConstructorEditor.model.value.style;
});


let footerStyle = computed(() => {
    return style.value ? {
        width: width.value,
        fontSize: `${style.value.footerTextSize}px`,
        color: style.value.footerTextColor,
        fontFamily: style.value.footerTextFont
    } : null;
});

let rootStyle = computed(() => {
    return style.value ? {
        backgroundColor: style.value.mainBackground,
        backgroundImage: `url('${style.value.mainBackgroundImage}')`,
        backgroundRepeat: style.value.mainBackgroundRepeat
    } : null
});

let innerStyle = computed(() => {
    return style.value ? {
        width: width.value,
        border: `${style.value.mainBorderWidth}px solid ${style.value.mainBorderColor}`
    } : null
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "email-content pb-5 pt-5",
    style: _normalizeStyle(_unref(rootStyle))
  }, [
    _createElementVNode("div", {
      class: "inner",
      style: _normalizeStyle(_unref(innerStyle))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4),
    _createElementVNode("div", {
      class: "footer",
      style: _normalizeStyle(_unref(footerStyle))
    }, [
      (_unref(model).additionalTypeParams)
        ? (_openBlock(), _createBlock(ContentEditable, {
            key: 0,
            modelValue: _unref(model).additionalTypeParams.textAfterContent,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).additionalTypeParams.textAfterContent) = $event)),
            multiline: ""
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(MyForm, {
        form: _unref(htmlConstructorEditor).params.type.additionalParams.form,
        modelValue: _unref(model).additionalTypeParams,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).additionalTypeParams) = $event))
      }, null, 8, ["form", "modelValue"]), [
        [_vShow, false]
      ])
    ], 4)
  ], 4))
}
}

})