import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

import {
    EventAsActionDescription,
    IEventAsDescriptionFrontendParamsEvent
} from "~/cabinet/ts/data/events/ActionDescription";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import MyFilter from "~/cabinet/vue/interface/filter/MyFilter.vue";
import {FiltersCollection} from "~/cabinet/vue/interface/filter/Interfaces";


const __default__ = {
    name: "EventAsActionBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<EventAsActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;


let _modelValue = _useModel<getActionDescriptionModelType<EventAsActionDescription>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    authPrimaryId: null,
    platformId: null,
    eventDescriptionId: null,
    eventIdentifiers: [],
    eventFilters: {}
}));


function onSelectEvent(value: string) {
    let m = model.value;
    let event: IEventAsDescriptionFrontendParamsEvent = JSON.parse(value);
    m.authPrimaryId = event.authPrimaryId;
    m.platformId = event.platformId;
    m.eventDescriptionId = event.eventDescriptionId;
    m.eventIdentifiers.splice(0, m.eventIdentifiers.length);
    m.eventFilters = {};
    for (let item of event.eventIdentifiers) {
        m.eventIdentifiers.push(item.id);
    }

}

function getValueByEvent(event: IEventAsDescriptionFrontendParamsEvent): string {
    return JSON.stringify(event);
}

let selectedEvent = computed(() => {
    return props.actionDescription.frontendParams.events.find(item => {
        return item.eventDescriptionId == model.value.eventDescriptionId && item.platformId == model.value.platformId && item.authPrimaryId == model.value.authPrimaryId;
    });
});

function onEventIdentifierToggle(id: string, value: boolean) {
    toggleArrayElement(model.value.eventIdentifiers, id, value);
}

let filtersRequest = computed(() => {
    let platformId = model.value.platformId;
    let authPrimaryId = model.value.authPrimaryId;
    let eventDescriptionId = model.value.eventDescriptionId;
    if (platformId && authPrimaryId && eventDescriptionId) {
        return new ReactiveRequest<{
            filtersCollection: FiltersCollection
        }>(new LcabApiRequest({
            url: `/api/events/${platformId}/${authPrimaryId}/${eventDescriptionId}/getEventParamsByAuth`
        }));
    }
    return null;
});


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      "label-position": "left",
      "label-width": "240px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "Выберите событие, которое вы ожидаете",
          required: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              class: "w-100",
              "model-value": _unref(selectedEvent) ? getValueByEvent(_unref(selectedEvent)) : null,
              "onUpdate:modelValue": onSelectEvent
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(props).actionDescription.frontendParams.events, (item, key) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: key,
                    label: item.descr,
                    value: getValueByEvent(item)
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["model-value"])
          ]),
          _: 1
        }),
        (_unref(selectedEvent))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_el_form_item, { label: "Выберите критерии, по которым события должны совпасть" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedEvent).eventIdentifiers, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id
                    }, [
                      _createVNode(_component_el_switch, {
                        class: "mr-1",
                        "model-value": _unref(model).eventIdentifiers.indexOf(item.id) > -1,
                        "onUpdate:modelValue": ($event: any) => (onEventIdentifierToggle(item.id, $event))
                      }, null, 8, ["model-value", "onUpdate:modelValue"]),
                      _createTextVNode(" " + _toDisplayString(item.descr), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              (_unref(filtersRequest))
                ? (_openBlock(), _createBlock(ReactiveRequestLoader, {
                    key: 0,
                    request: _unref(filtersRequest)
                  }, {
                    default: _withCtx(() => [
                      (_unref(filtersRequest).data)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(filtersRequest).data.filtersCollection, (filtersCollectionItem) => {
                            return (_openBlock(), _createBlock(_component_el_form_item, {
                              key: filtersCollectionItem.id,
                              label: filtersCollectionItem.descr
                            }, {
                              default: _withCtx(() => [
                                _createVNode(MyFilter, {
                                  modelValue: _unref(model).eventFilters[filtersCollectionItem.id],
                                  "onUpdate:modelValue": ($event: any) => ((_unref(model).eventFilters[filtersCollectionItem.id]) = $event),
                                  "filters-data": filtersCollectionItem.filters
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "filters-data"])
                              ]),
                              _: 2
                            }, 1032, ["label"]))
                          }), 128))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["request"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})