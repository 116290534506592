import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

import CabinetCaptchaElement from "~/cabinet/vue/interface/form/elements/CabinetCaptchaElement.vue";
import {ref} from "vue";
import {SIMPLE_CABINET_CAPTCHA_FIELD} from "~/cabinet/vue/interface/form/elements/Interfaces";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";



const __default__ = {
    name: "RemindDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  },
  emits: ["update:visible"],
  setup(__props) {

let visible = _useModel<boolean>(__props, "visible");

let remindDialog = ref<{
    displayMessageCode: boolean,
    sendDescr: string
    form: {
        captcha: string,
        login: string,
        code: string
    }
}>({
    displayMessageCode: false,
    sendDescr: null,
    form: {
        captcha: null,
        login: null,
        code: null
    }
});

async function sendRemindMessage() {
    let result = await LcabApiRequest.fetch({
        url: "/api/auth/sendRemindPasswordMessage",
        p: remindDialog.value.form
    });
    if (!result.showMessageOnError()) {
        remindDialog.value.displayMessageCode = true;
        remindDialog.value.sendDescr = result.descr;
    }
}

async function resetPassword() {
    let result = await LcabApiRequest.save({
        url: "/api/auth/resetPassword",
        p: remindDialog.value.form
    });
    if (result.isSuccess) {
        visible.value = false;
    }
}

let captchaField = SIMPLE_CABINET_CAPTCHA_FIELD;


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_unref(visible))
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
        title: "Восстановление пароля",
        modal: false,
        width: "500px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, { "label-position": "top" }, {
            default: _withCtx(() => [
              (!_unref(remindDialog).displayMessageCode)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_el_form_item, { label: "Ваш логин" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _unref(remindDialog).form.login,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(remindDialog).form.login) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, { label: "Введите код с картинки" }, {
                      default: _withCtx(() => [
                        _createVNode(CabinetCaptchaElement, {
                          modelValue: _unref(remindDialog).form.captcha,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(remindDialog).form.captcha) = $event)),
                          field: _unref(captchaField)
                        }, null, 8, ["modelValue", "field"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: sendRemindMessage
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Получить проверочный код ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_el_form_item, {
                      class: "text",
                      label: "Введите код подтверждения"
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_unref(remindDialog).sendDescr), 1),
                        _cache[5] || (_cache[5] = _createElementVNode("p", null, " Введите его в поле ниже. ", -1))
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _unref(remindDialog).form.code,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(remindDialog).form.code) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: resetPassword
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" Сбросить пароль ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})