import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "d-flex" }

import {IOption} from "~/cabinet/vue/interface/form/elements/Interfaces";
import MyInputList from "~/cabinet/vue/interface/form/MyInputList.vue";
import {computed, ref, watch} from "vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyIconSelector from "~/cabinet/vue/interface/form/MyIconSelector.vue";
import {ICON_LIGHT} from "~/chat/ts/data/ISiteParams";
import IMyIconSelectorModel from "~/cabinet/vue/interface/form/IMyIconSelectorModel";


const __default__ = {
    name: "OptionsConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    modelValue: { default: () => [] as IOption[] },
    hideDefault: { type: Boolean },
    multiple: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = ref<IOption[]>();

function updateModel() {
    let value = JSON.stringify(props.modelValue);
    if (JSON.stringify(model.value) != value) {
        model.value = JSON.parse(value);
    }
}

watch(computed(() => props.modelValue), () => {
    updateModel();
    checkDefault();
}, {deep: true})

watch(model, () => {
    let _model = JSON.stringify(model.value);
    if (_model !== JSON.stringify(props.modelValue)) {
        emits("update:modelValue", JSON.parse(_model));
    }
}, {deep: true})


function checkDefault() {
    if (!props.multiple && !props.hideDefault) {
        let hasOneDefault = false;
        for (let option of model.value) {
            if (option.default) {
                if (hasOneDefault) {
                    option.default = false;
                } else {
                    hasOneDefault = true;
                }
            }
        }
    }
}

function onIconSelect(e: IMyIconSelectorModel, index: number) {
    model.value[index].icon = e ? e.iconName : null;
    if (e) {
        model.value[index].iconStyle = e.style;
    }
}

function onDefaultInput(index: number, value: boolean) {
    if (!props.multiple && value) {
        for (let option of model.value) {
            option.default = false;
        }
    }
    model.value[index].default = value;
}


function getDefaultValue(): IOption {
    return {
        value: null,
        descr: null,
        default: false,
        icon: null,
        iconColor: "#3498db",
        iconStyle: ICON_LIGHT
    };
}


function setNewInputValue(index: number, value: string) {
    model.value[index].value = model.value[index].descr = value;
}


updateModel();
emits("update:modelValue", props.modelValue);
checkDefault();


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(MyInputList, {
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      "new-item-value-getter": getDefaultValue
    }, {
      default: _withCtx(({index}) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_input, {
            "model-value": _unref(model)[index].value,
            "onUpdate:modelValue": ($event: any) => (setNewInputValue(index, $event)),
            placeholder: "Значение, предлагаемое на выбор"
          }, null, 8, ["model-value", "onUpdate:modelValue"]),
          _createVNode(MyIconSelector, {
            clearable: "",
            onInput: ($event: any) => (onIconSelect($event, index))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, { class: "ml-2" }, {
                default: _withCtx(() => [
                  (!_unref(model)[index].icon)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" Без иконки ")
                      ], 64))
                    : (_openBlock(), _createBlock(FontAwesomeIcon, {
                        key: 1,
                        icon: _unref(model)[index].icon,
                        "icon-style": _unref(model)[index].iconStyle,
                        style: _normalizeStyle({
                                    color: _unref(model)[index].iconColor ? _unref(model)[index].iconColor : null
                                })
                      }, null, 8, ["icon", "icon-style", "style"]))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onInput"]),
          (_unref(model)[index].icon)
            ? (_openBlock(), _createBlock(_component_el_color_picker, {
                key: 0,
                class: "ml-1",
                modelValue: _unref(model)[index].iconColor,
                "onUpdate:modelValue": ($event: any) => ((_unref(model)[index].iconColor) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            : _createCommentVNode("", true),
          (!_ctx.hideDefault)
            ? (_openBlock(), _createBlock(_component_el_checkbox, {
                key: 1,
                "model-value": _unref(model)[index].default,
                "onUpdate:modelValue": ($event: any) => (onDefaultInput(index, $event)),
                label: index,
                class: "ml-3"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" По умолчанию ")
                ])),
                _: 2
              }, 1032, ["model-value", "onUpdate:modelValue", "label"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})