import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_4 = { class: "icons" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "mt-3" }

import {computed, ref, watch} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyIconSelectorHelper from "~/cabinet/vue/interface/form/MyIconSelectorHelper";
import Delay from "~/ts/library/Delay";
import {ICON_LIGHT, ICON_REGULAR, ICON_SOLID} from "~/chat/ts/data/ISiteParams";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import IMyIconSelectorModel from "~/cabinet/vue/interface/form/IMyIconSelectorModel";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";


let iconsCache: NodeJS.Dict<IconFromServer[]>;

const __default__ = {
    name: "MyIconSelector"
}

interface IconFromServer {
    "class": string,
    symbol: string,
    descr: string,
    terms: string[]
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    clearable: { type: Boolean }
  },
  emits: ["input"],
  setup(__props: any, { emit: __emit }) {



let emits = __emit;

let file = ref<HTMLInputElement>();

let iconsDialogVisible = ref(false);
let filteredCategories = ref<string[]>();
let search = ref<string>();
let iconStyle = ref(ICON_LIGHT);
let searchQueryForFilter = ref<string>();

let icons = computed(() => {
    let result = iconsCache;
    if (searchQueryForFilter.value) {
        let searchResult: Dictionary<IconFromServer> = {};
        let searchResultArray: IconFromServer[] = [];
        for (let category in result) {
            if (result.hasOwnProperty(category)) {
                let icons = result[category].filter(icon => {
                    for (let term of icon.terms) {
                        if (term.indexOf(searchQueryForFilter.value) > -1) {
                            return true;
                        }
                    }
                    return false;
                });
                for (let icon of icons) {
                    if (!searchResult[icon.class]) {
                        searchResult[icon.class] = icon;
                        searchResultArray.push(icon);
                    }
                }
            }
        }
        if (searchResultArray.length) {
            result = {"Результаты поиска": searchResultArray};
        } else {
            result = {};
        }

    }
    return result;
});

watch(search, async () => {
    try {
        await Delay.make(250, "iconDialogSearch", true);
        searchQueryForFilter.value = search.value ? search.value.toLowerCase() : null;
    } catch (e) {

    }
});

async function emitInput(value: IconFromServer) {
    let result = await MyIconSelectorHelper.getIconSelectModelByClass(value.class, iconStyle.value);
    if (result) {
        emits("input", result);
        iconsDialogVisible.value = false;
    }
}

function emitImg() {
    let result: IMyIconSelectorModel = {
        iconName: imgIcon.value,
        style: "img",
        svg: {
            content: "",
            height: 1,
            width: 1
        }
    }
    emits("input", result);
    iconsDialogVisible.value = false;
}

async function openDialog() {
    if (!iconsCache) {
        let result = await LcabApiRequest.fetch({
            url: "/api/ui/iconsSelector/getIcons",
        });
        if (!result.showMessageOnError()) {
            iconsCache = result.getData("icons");
        }
    }
    if (iconsCache) {
        iconsDialogVisible.value = true;
    }
}

let iconStyles = computed<{ value: string, label: string }[]>(() => {
    return [
        {value: ICON_LIGHT, label: "Тонкие иконки"},
        {value: ICON_REGULAR, label: "Обычные иконки"},
        {value: ICON_SOLID, label: "Жирные иконки"}
    ]
});

let imgIcon = ref<string>(null);

function readFile(e: Event) {
    let target: HTMLInputElement = e.target as HTMLInputElement;
    if (target.files.length) {
        let file = target.files[0];

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let src = reader.result as string;
            if (src.length < 100000) {
                imgIcon.value = src as string;
            } else {
                LcabApiResult.showError('Максимальный размер иконки - 60kb');
            }
        };
        reader.onerror = function () {
            LcabApiResult.showError(reader.error.message);
        };

    }
    /*
        let file = input.files[0];

        let reader = new FileReader();

        reader.readAsText(file);

        reader.onload = function () {
            console.log(reader.result);
        };

        reader.onerror = function () {
            console.log(reader.error);
        };*/

}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: openDialog
        }, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          onClick: openDialog,
          link: ""
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Выбрать иконку ")
          ])),
          _: 1
        })),
    (_unref(iconsDialogVisible))
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 2,
          modelValue: _unref(iconsDialogVisible),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(iconsDialogVisible) ? (iconsDialogVisible).value = $event : iconsDialogVisible = $event)),
          "append-to-body": "",
          title: "Выбрать иконку"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tabs, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, { label: "Выбрать из набора" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_el_input, {
                        modelValue: _unref(search),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
                        class: "w-100",
                        placeholder: "Поиск по иконкам"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_select, {
                        modelValue: _unref(iconStyle),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(iconStyle) ? (iconStyle).value = $event : iconStyle = $event)),
                        class: "w-100"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(iconStyles), (style) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: style.value,
                              label: style.label,
                              value: style.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    (_ctx.clearable)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_el_button, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => {_unref(emits)('input', null); _isRef(iconsDialogVisible) //@ts-ignore
 ? iconsDialogVisible.value = false : iconsDialogVisible = false;}),
                            type: "danger",
                            plain: ""
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("Убрать иконку")
                            ])),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_form, {
                      "label-position": "top",
                      class: "mt-4"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(icons), (items, category) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: category }, [
                            (_unref(searchQueryForFilter) || !_unref(filteredCategories) || !_unref(filteredCategories).length || _unref(filteredCategories).includes(category))
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 0,
                                  label: category
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_4, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (icon) => {
                                        return _withDirectives((_openBlock(), _createBlock(FontAwesomeIcon, {
                                          key: icon['class'],
                                          icon: icon['class'],
                                          "icon-style": _unref(iconStyle)
                                        }, null, 8, ["icon", "icon-style"])), [
                                          [_directive_on_native, () => emitInput(icon), "click"]
                                        ])
                                      }), 128))
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["label"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_tab_pane, { label: "Свое изображение" }, {
                  default: _withCtx(() => [
                    (!_unref(imgIcon))
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(file).click()))
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Загрузите изображение ")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("input", {
                      class: "d-none",
                      ref_key: "file",
                      ref: file,
                      type: "file",
                      onChange: readFile,
                      accept: "image/png, image/gif, image/svg+xml"
                    }, null, 544),
                    (_unref(imgIcon))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _cache[12] || (_cache[12] = _createElementVNode("h5", { class: "mb-3" }, "Загруженное изображение", -1)),
                          _createVNode(FontAwesomeIcon, {
                            icon: _unref(imgIcon),
                            "icon-style": "img",
                            style: {"font-size":"5em"},
                            class: "img-preview"
                          }, null, 8, ["icon"]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (emitImg()))
                            }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode("Использовать эту иконку")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_el_button, {
                              type: "primary",
                              plain: "",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(file).click()))
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode(" Загрузить другое изображение ")
                              ])),
                              _: 1
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})