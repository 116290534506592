import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import {computed, ref} from "vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {BlogArticleInterface} from "~/cabinet/vue/client/blog/BlogArticleInterface";
import ArticleEditDialog from "~/cabinet/vue/dealer/blog/ArticleEditDialog.vue";


const __default__ = {
    name: "BlogRepostButton"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    article: {}
  },
  setup(__props: any) {

let props = __props;

let hasAccess = computed(() => {
    return AccountStore.isDealer && AccountStore.access.value.checkAccessForDealerModifyContent()
});

let dialog = ref<{
    visible: boolean
}>()

function repost() {
    dialog.value = {
        visible: true
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_unref(hasAccess) && !_ctx.article.isForAll)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: repost
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Перепостить ")
          ])),
          _: 1
        }),
        (_unref(dialog) && _unref(dialog).visible)
          ? (_openBlock(), _createBlock(ArticleEditDialog, {
              key: 0,
              "repost-id": _ctx.article.id,
              visible: _unref(dialog).visible,
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_unref(dialog).visible) = $event))
            }, null, 8, ["repost-id", "visible"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})