import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_4 = { key: 0 }

import {SenderCouponModelInterface} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import RegisterList from "~/cabinet/vue/client/sender/coupon/registers/RegisterList.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import HelpIcon from "~/cabinet/vue/interface/form/HelpIcon.vue";
import {SenderCreateFormParamsInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";


const __default__ = {
    name: "SenderCoupon"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    params: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {


let props = __props;

let _modelValue = _useModel<SenderCouponModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    enabled: false,
    registerId: null,
    policyId: props.params.couponPolicyList[0].id
}));

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          modelValue: _unref(model).enabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
          class: "mr-2"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createTextVNode(" Сгенерировать промокод ")),
        _createVNode(HelpIcon, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Если включить, то вы можете подставить в текст сообщения промокод для акции, созданной в разделе промоакций ")
          ])),
          _: 1
        })
      ])
    ]),
    (_unref(model).enabled && _unref(model).registerId)
      ? (_openBlock(), _createBlock(HelpBlock, {
          key: 0,
          warning: ""
        }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createTextVNode(" Не забудьте добавить в текст отправляемого сообщение параметр ")),
            _createElementVNode("span", _hoisted_2, "[" + _toDisplayString(_ctx.params.couponCodeContentVariable) + "]", 1),
            _cache[6] || (_cache[6] = _createTextVNode(" для того, чтобы промокод был отправлен клиенту. "))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(model).enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(RegisterList, {
            modelValue: _unref(model).registerId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).registerId) = $event)),
            "model-is-used": "",
            title: "Выберите промо-акцию"
          }, null, 8, ["modelValue"]),
          (_unref(model).registerId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_el_select, {
                  modelValue: _unref(model).policyId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).policyId) = $event)),
                  class: "w-100"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.params.couponPolicyList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.id,
                        value: item.id,
                        label: item.descr
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})