import axios from "axios";
import {App, reactive} from "vue";
import {LoadingService} from "~/core-ui/ts/InitElemeUI";
import {__} from "~/ts/library/Translate";
import Random from "~/ts/library/Random";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import {AbstractApiResult} from "~/core-ui/ts/request/AbstractApiResult";
import Dictionary from "~/ts/library/Dictionary";
import {LoadingInstance} from "element-plus/es/components/loading/src/loading";
import {showMessage} from "~/ts/vuePlugins/ShowMessagePlugin";

interface ApiRequestConfig {
    url: string;
    p?: any;
    silent?: boolean;
    silentError?: boolean
    loading?: boolean;
    saving?: boolean;
    vue?: App;
    headers?: Dictionary<string>
}

export {ApiRequestConfig};

export default abstract class AbstractApiRequest<Result extends AbstractApiResult<Data, any>, Data> {
    private _options: ApiRequestConfig;
    public dataType?: AbstractEntityBuilder<Data>;

    constructor(options: ApiRequestConfig, dataType?: AbstractEntityBuilder<Data>) {
        if (!options.p) {
            options.p = {};
        }
        options.p = reactive(options.p);
        this._options = options;
        this.dataType = dataType;
    }


    protected abstract createApiResultByResponse(response: any, dataType?: AbstractEntityBuilder<Data>): Result;

    private static fullscreenLoadingCount = 0;

    get options() {
        return this._options;
    }

    protected addHeader(key: string, value: string) {
        let options = this.options;
        if (!options.headers) {
            options.headers = {};
        }
        options.headers[key] = value;
        return this;
    }

    private onRequestEnd(options: ApiRequestConfig, loadingInstance?: LoadingInstance) {
        if (!options.silent) {
            if (loadingInstance) {
                AbstractApiRequest.fullscreenLoadingCount--;
                if (!AbstractApiRequest.fullscreenLoadingCount) {
                    loadingInstance.close();
                }
            }
        }
    }

    protected getUrl(path: string): string {
        return path;
    }

    public async send<T>(): Promise<Result> {
        let options = this.options;
        let loadingInstance: LoadingInstance;

        if (options.vue) {
            options.silent = true;
        }
        if (!options.silent) {
            AbstractApiRequest.fullscreenLoadingCount++;
            loadingInstance = LoadingService({
                fullscreen: true,
                lock: true
            });
        }

        let originalResponse: string = null;

        let body = JSON.stringify(options.p ? options.p : {});
        let error: Error = new Error();

        try {
            let headers = {
                'Content-Type': 'application/json',
                'X-Request-Id': Random.uid(50),
                'X-Csrf-Token': CsrfToken.get(),
                ...(options.headers ? options.headers : {})
            };

            let result = await axios.post(this.getUrl(options.url), body, {
                responseType: "text",
                headers,
                transformResponse: function (data) {
                    originalResponse = data;
                    try {
                        data = JSON.parse(data);
                    } catch (e) {
                        data = null;
                    }
                    return data;
                },
                validateStatus: code => this.validateStatus(code)
            });
            this.onRequestEnd(options, loadingInstance);
            if (result.data == null) {
                if (!options.silentError) {
                    this.showErrorMessage(options, {
                        data: result.data,
                        originalResponse,
                        status: result.status,
                        statusText: result.statusText,
                        request: result.request,
                        headers: result.headers,
                        config: result.config
                    });
                }
            } else {
                return this.createApiResultByResponse(result.data, this.dataType);
            }
        } catch (e) {
            error = e;
            if (!options.silentError) {
                if (e.response && e.response.data && e.response.data.descr && e.response.status == 403) {
                    this.showErrorMessage(options, null, e.response.data.descr, false);
                } else {
                    this.showErrorMessage(options, {error, originalResponse});
                }
            }
            this.onRequestEnd(options, loadingInstance);
        }
        throw error;
    }

    protected validateStatus(status: number) {
        return status >= 200 && status < 300;
    }

    protected logError(options: ApiRequestConfig, result: any, message: string = null) {

    }

    private static allowShowError = true;

    private async showErrorMessage(options: ApiRequestConfig, result: any, message: string = null, log: boolean = true) {
        if (AbstractApiRequest.allowShowError) {
            AbstractApiRequest.allowShowError = false;
            try {
                if (log) {
                    this.logError(options, result, message);
                }
                if (!message) {
                    message = __("Ошибка при получении данных от сервера! Пожалуйста, сообщите об этом администрации!");
                    message += `\n\nUTC: ${(new Date()).toUTCString()}\nURL: ${this.getUrl(options.url)}`;
                }
                if (options.vue) {
                    await showMessage({
                        type: "error",
                        message
                    });
                }
            } catch (e) {
                console.error(e);
            }
            AbstractApiRequest.allowShowError = true;
        }
    }

    async save() {
        this.options.saving = true;
        let result = await this.send();
        result.showMessage();
        return result;
    }
}

