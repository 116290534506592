import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_2 = { class: "table m-0" }
const _hoisted_3 = { style: {"min-width":"200px"} }
const _hoisted_4 = {
  class: "text-right",
  style: {"width":"400px"}
}
const _hoisted_5 = { class: "d-flex align-items-center float-right" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_8 = {
  key: 1,
  class: "mt-1"
}

import {computed, getCurrentInstance, ref} from 'vue';
import ReactiveRequestLoader from "~/cabinet/vue/interface/data/ReactiveRequestLoader.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ReactiveFilterableListRequest from "~/core-ui/ts/request/ReactiveFilterableListRequest";
import {CouponRegister} from "~/cabinet/vue/client/sender/coupon/registers/CouponInterface";
import AbstractEntity from "~/ts/library/AbstractEntity";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import DateHelper from "~/ts/library/Date";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import Delay from "~/ts/library/Delay";
import UslugaMapper from "~/cabinet/ts/data/usluga/Uslugi";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import SenderRouteBuilder from "~/cabinet/ts/routes/SenderRouteBuilder";
import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import {DEFAULT_SENDER_FORM_ID} from "~/cabinet/vue/client/sender/send/Interfaces";
import ElConfirm from '~/core-ui/vue/ui/ElConfirm';
import {SenderFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";
import {useRouter} from "vue-router";
import MyDateTimePicker from "~/core-ui/vue/ui/MyDateTimePicker.vue";



import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const __default__ = {
    name: "RegisterList",
    components: {
        SenderForm: useRawAsyncComponent(() => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/send/SenderForm.vue')),
        SenderReportTasks: useRawAsyncComponent(() => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/report/tasks/SenderReportTasks.vue')),
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    title: {},
    modelIsUsed: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const STOP_TYPE_INDEFINITELY = "indefinitely";
const STOP_TYPE_DATE_TIME = "dateTime";
const STOP_TYPE_AFTER_DAYS = "afterDays";

let props = __props;

let model = _useModel<string>(__props, "modelValue");


let editDialog = ref<{ visible: boolean, model: CouponRegister, contentVariables: ContentVariableInterface[] }>();

let reportDialog = ref<{
    visible: boolean,
    register: CouponRegister,
    uslugaId: string
}>();

let sendDialog = ref<{
    visible: boolean,
    model: SenderFormModelInterface<any>;
    uslugaId: string,
    formId: string
}>();

let request = computed(() => {
    let result = (new ReactiveFilterableListRequest(new LcabApiRequest({
        url: `/api/sender/promocodes/actions/getList`
    }, CouponRegister)));
    result.make();
    return result;
});

let instance = getCurrentInstance().proxy;

let selectable = computed(() => !!props.modelIsUsed);

async function edit(id?: string) {
    let result = await LcabApiRequest.fetch({
        url: `/api/sender/promocodes/actions/getEditDialog`,
        p: {
            id
        }
    }, EditResponse);
    if (!result.showMessageOnError()) {
        editDialog.value = {
            visible: true,
            model: result.data.model,
            contentVariables: result.data.contentVariables
        };
    }
}

let selectedRegister = computed<CouponRegister>(() => {
    return request.value.data && model.value ? request.value.data.items.find(item => item.id == model.value) : null;
});

async function save() {
    let result = await LcabApiRequest.save({
        url: `/api/sender/promocodes/actions/saveRegister`,
        p: editDialog.value.model
    });
    if (result.isSuccess) {
        if (result.data.id) {
            edit(result.data.id);
        }
        request.value.make();
    }
}

let stopTypeId = computed<string>({
    get: () => {
        if (editDialog.value) {
            let model = editDialog.value.model;
            if (model.stopSchemeIndefinitely) {
                return STOP_TYPE_INDEFINITELY;
            } else if (model.stopSchemeDateTime) {
                return STOP_TYPE_DATE_TIME;
            } else if (typeof model.stopSchemeAfterDays == "number") {
                return STOP_TYPE_AFTER_DAYS;
            }
        }
        return null;
    },
    set: (value: string) => {
        let model = editDialog.value.model;
        model.stopSchemeIndefinitely = false;
        model.stopSchemeDateTime = null;
        model.stopSchemeAfterDays = null;
        if (value == STOP_TYPE_INDEFINITELY) {
            model.stopSchemeIndefinitely = true;
        } else if (value == STOP_TYPE_AFTER_DAYS) {
            model.stopSchemeAfterDays = 7;
        } else if (value == STOP_TYPE_DATE_TIME) {
            let date = new Date();
            date.setDate(date.getDate() + 31);
            model.stopSchemeDateTime = (new DateHelper(date)).toMysqlFormat();
        }
    }
});

const stopTypes = [
    {id: STOP_TYPE_INDEFINITELY, descr: "До ручного отключения акции"},
    {id: STOP_TYPE_AFTER_DAYS, descr: "Несколько дней после отправки"},
    {id: STOP_TYPE_DATE_TIME, descr: "До заданного дня"}
];


async function remove(id: string) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить эту акцию?");
        let result = await LcabApiRequest.save({
            url: `/api/sender/promocodes/actions/removeRegister/${id}`
        });
        if (result.isSuccess) {
            request.value.make();
        }
    } catch (e) {

    }
}

async function onToggleEnabled(register: CouponRegister, value: boolean) {
    try {
        await Delay.make(1000, `${register.id}_toggleEnabled`, true);
        (await LcabApiRequest.fetch({
            silent: true,
            url: `/api/sender/promocodes/actions/toggleEnabled/${register.id}`,
            p: {
                enabled: value
            }
        })).showMessageOnError();
    } catch (e) {

    }
}

let router = useRouter();

function getUslugiList(routeNameGetter: (uslugaId: string) => string) {
    let result: SenderUsluga[] = [];
    for (let usluga of UslugaMapper.senderByWeight()) {
        let routeName = routeNameGetter(usluga.id);
        let route = router.resolve({
            name: routeName
        });
        if (route) {
            if (RouteAccessChecker.hasAccess(route)) {
                result.push(usluga);
            }
        }
    }
    return result;
}

let senderUslugiForSend = computed(() => {
    return getUslugiList(uslugaId => SenderRouteBuilder.getSendCreateRouteName(uslugaId));
});

let senderUslugiForReport = computed(() => {
    return getUslugiList(uslugaId => SenderRouteBuilder.getReportRouteNameByUsluga(uslugaId));
});

function showReportDialog(uslugaId: string, register: CouponRegister) {
    reportDialog.value = {
        uslugaId,
        register,
        visible: true
    }
}

function showSendDialog(uslugaId: string, register: CouponRegister) {
    sendDialog.value = {
        uslugaId,
        model: {
            settings: {
                coupon: {
                    enabled: true,
                    registerId: register.id
                }
            }
        } as SenderFormModelInterface<any>,
        formId: DEFAULT_SENDER_FORM_ID,
        visible: true
    }
}

class EditResponse extends AbstractEntity {
    model: CouponRegister;
    contentVariables: ContentVariableInterface[];
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_sender_report_tasks = _resolveComponent("sender-report-tasks")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_sender_form = _resolveComponent("sender-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ReactiveRequestLoader, { request: _unref(request) }, {
      default: _withCtx(() => [
        (_unref(request).data)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_unref(model))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[16] || (_cache[16] = _createTextVNode(" Выбранная акция: ")),
                    (_unref(selectedRegister))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("a", {
                            class: "link-dashed",
                            href: "#",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (edit(_unref(selectedRegister).id)), ["prevent"]))
                          }, _toDisplayString(_unref(selectedRegister).descr), 1),
                          _cache[15] || (_cache[15] = _createTextVNode(" / ")),
                          _createVNode(_component_el_button, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = null : model = null)),
                            link: ""
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode("Выбрать другую ")
                            ])),
                            _: 1
                          })
                        ], 64))
                      : (_openBlock(), _createElementBlock("span", _hoisted_1, "не найдена"))
                  ], 64))
                : (_openBlock(), _createBlock(_component_el_card, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(ElCardHeader, {
                        "button-col-span": 12,
                        title: _ctx.title ? _ctx.title : 'Список промо-акций'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            type: "primary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (edit(null)))
                          }, {
                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode(" Создать акцию ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(ElCardSectionWithTable, null, {
                        default: _withCtx(() => [
                          _createElementVNode("table", _hoisted_2, [
                            _createElementVNode("tbody", null, [
                              (_unref(request).data.items.length)
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(request).data.items, (item) => {
                                    return (_openBlock(), _createElementBlock("tr", {
                                      key: item.id
                                    }, [
                                      _createElementVNode("td", _hoisted_3, [
                                        (!_unref(selectable))
                                          ? (_openBlock(), _createBlock(_component_el_switch, {
                                              key: 0,
                                              class: "mr-2",
                                              modelValue: item.isEnabled,
                                              "onUpdate:modelValue": [($event: any) => ((item.isEnabled) = $event), ($event: any) => (onToggleEnabled(item, $event))]
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                          : _createCommentVNode("", true),
                                        _createTextVNode(" " + _toDisplayString(item.descr), 1)
                                      ]),
                                      _createElementVNode("td", _hoisted_4, [
                                        _createElementVNode("div", _hoisted_5, [
                                          (!_unref(selectable))
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                (_unref(senderUslugiForSend).length && item.isEnabled)
                                                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                                                      key: 0,
                                                      onCommand: ($event: any) => (showSendDialog($event, item)),
                                                      trigger: "click"
                                                    }, {
                                                      dropdown: _withCtx(() => [
                                                        _createVNode(_component_el_dropdown_menu, null, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(senderUslugiForSend), (usluga) => {
                                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                                key: usluga.id,
                                                                command: usluga.id
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(usluga.descr), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["command"]))
                                                            }), 128))
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_button, null, {
                                                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                            _createTextVNode(" Создать рассылку ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["onCommand"]))
                                                  : _createCommentVNode("", true),
                                                (_unref(senderUslugiForReport).length)
                                                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                                                      key: 1,
                                                      onCommand: ($event: any) => (showReportDialog($event, item)),
                                                      trigger: "click"
                                                    }, {
                                                      dropdown: _withCtx(() => [
                                                        _createVNode(_component_el_dropdown_menu, null, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(senderUslugiForReport), (usluga) => {
                                                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                                                key: usluga.id,
                                                                command: usluga.id
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(usluga.descr), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["command"]))
                                                            }), 128))
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_button, { class: "ml-1" }, {
                                                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                            _createTextVNode(" Отчёты ")
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["onCommand"]))
                                                  : _createCommentVNode("", true)
                                              ], 64))
                                            : _createCommentVNode("", true),
                                          _createVNode(_component_el_button_group, { class: "ml-1" }, {
                                            default: _withCtx(() => [
                                              (_unref(selectable))
                                                ? (_openBlock(), _createBlock(_component_el_button, {
                                                    key: 0,
                                                    disabled: !item.isEnabled,
                                                    onClick: ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = item.id : model = item.id)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (item.isEnabled)
                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                            _createTextVNode(" Выбрать ")
                                                          ], 64))
                                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                            _createTextVNode(" Отключено ")
                                                          ], 64))
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["disabled", "onClick"]))
                                                : _createCommentVNode("", true),
                                              _createVNode(_component_el_button, {
                                                onClick: ($event: any) => (edit(item.id))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(FontAwesomeIcon, { icon: "pencil" })
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]),
                                              (!_unref(selectable))
                                                ? (_openBlock(), _createBlock(_component_el_button, {
                                                    key: 1,
                                                    onClick: ($event: any) => (remove(item.id))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(FontAwesomeIcon, { icon: "times" })
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "clearfix" }, null, -1))
                                      ])
                                    ]))
                                  }), 128))
                                : (_openBlock(), _createElementBlock("tr", _hoisted_6, _cache[21] || (_cache[21] = [
                                    _createElementVNode("td", null, "Пока не создано ни одной акции", -1)
                                  ])))
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["request"]),
    (_unref(editDialog) && _unref(editDialog).visible)
      ? (_openBlock(), _createBlock(MyDialog, {
          key: 0,
          visible: _unref(editDialog).visible,
          "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_unref(editDialog).visible) = $event)),
          title: _unref(editDialog).model.id ? 'Редактирование акции' : 'Создание акции',
          "button-text": "Сохранить акцию",
          form: {
                labelPosition: 'left',
                labelWidth: '180px',
                model: _unref(editDialog).model
            },
          onApply: save
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Название акции",
              prop: "descr",
              rules: _unref(requiredRule)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _unref(editDialog).model.descr,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(editDialog).model.descr) = $event)),
                  placeholder: "Название будет видно только вашим сотрудникам"
                }, null, 8, ["modelValue"]),
                _createVNode(HelpBlock, null, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode(" Это название будет видно только сотрудникам вашей компании, служит для того, чтобы можно было быстро различать акции друг от друга ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["rules"]),
            _createVNode(_component_el_form_item, { label: "Начало акции" }, {
              default: _withCtx(() => [
                _createVNode(MyDateTimePicker, {
                  class: "w-100",
                  modelValue: _unref(editDialog).model.dateTimeStart,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(editDialog).model.dateTimeStart) = $event)),
                  placeholder: "В момент рассылки"
                }, null, 8, ["modelValue"]),
                _createVNode(HelpBlock, null, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Если оставить пустым, то акция начнется в момент отправки промо-кода")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Срок действия промо-кода" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: "w-100",
                  modelValue: _unref(stopTypeId),
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(stopTypeId) ? (stopTypeId).value = $event : stopTypeId = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(stopTypes, (item) => {
                      return _createVNode(_component_el_option, {
                        key: item.id,
                        value: item.id,
                        label: item.descr
                      }, null, 8, ["value", "label"])
                    }), 64))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_unref(editDialog).model.stopSchemeDateTime)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(MyDateTimePicker, {
                        modelValue: _unref(editDialog).model.stopSchemeDateTime,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(editDialog).model.stopSchemeDateTime) = $event)),
                        class: "w-100"
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(editDialog).model.stopSchemeAfterDays)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_unref(editDialog).model.stopSchemeAfterDays)
                        ? (_openBlock(), _createBlock(_component_el_input_number, {
                            key: 0,
                            modelValue: _unref(editDialog).model.stopSchemeAfterDays,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(editDialog).model.stopSchemeAfterDays) = $event)),
                            min: 1
                          }, null, 8, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(editDialog).model.stopSchemeIndefinitely)
                  ? (_openBlock(), _createBlock(HelpBlock, { key: 2 }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode(" По истечении этого срока все СМС-купоны станут неактивными, и их нельзя будет использовать ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Длина кода",
              prop: "couponCodeLength"
            }, {
              default: _withCtx(() => [
                (_unref(editDialog).model.couponCodeLength)
                  ? (_openBlock(), _createBlock(_component_el_input_number, {
                      key: 0,
                      modelValue: _unref(editDialog).model.couponCodeLength,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(editDialog).model.couponCodeLength) = $event)),
                      min: 4,
                      max: 15
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                _createVNode(HelpBlock, null, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode(" Выберите число от 4 до 15. Это длина уникального кода, который будет отправляться вашим клиентам в качестве промокода. ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { "label-width": "0px" }, {
              default: _withCtx(() => [
                _createVNode(TextInputWithVariables, {
                  modelValue: _unref(editDialog).model.instruction,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(editDialog).model.instruction) = $event)),
                  "left-span": 12,
                  "content-variables": _unref(editDialog).contentVariables
                }, {
                  left: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Инструкция для менеджера")
                  ])),
                  _: 1
                }, 8, ["modelValue", "content-variables"]),
                _createVNode(HelpBlock, null, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createElementVNode("p", null, " Эта инструкция будет видна менеджеру после того, как он проверит полученный от клиента промокод и тот окажется верным. ", -1),
                    _createElementVNode("p", null, " Расскажите менеджеру, что он должен выдать этому клиенту. ", -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible", "title", "form"]))
      : _createCommentVNode("", true),
    (_unref(reportDialog) && _unref(reportDialog).visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 1,
          modelValue: _unref(reportDialog).visible,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(reportDialog).visible) = $event)),
          title: "Отчёты по рассылке",
          class: "right w-70"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sender_report_tasks, {
              "usluga-id": _unref(reportDialog).uslugaId,
              tags: _unref(reportDialog).register.tags
            }, null, 8, ["usluga-id", "tags"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_unref(sendDialog) && _unref(sendDialog).visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 2,
          modelValue: _unref(sendDialog).visible,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(sendDialog).visible) = $event)),
          title: "Создание рассылки",
          class: "right w-70"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sender_form, {
              modelValue: _unref(sendDialog).model,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(sendDialog).model) = $event)),
              "usluga-id": _unref(sendDialog).uslugaId,
              "form-id": _unref(sendDialog).formId
            }, null, 8, ["modelValue", "usluga-id", "form-id"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})