import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import MyTimeSelectorInSeconds from "~/cabinet/vue/interface/form/MyTimeSelectorInSeconds.vue";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    DelayActionDescription,
} from "~/cabinet/ts/data/events/ActionDescription";


const __default__ = {
    name: "DelayBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DelayActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {



let _modelValue = _useModel<getActionDescriptionModelType<DelayActionDescription>>(__props, "modelValue");
let model = useObjectModel(
    _modelValue,
    () => ({
        delay: 10
    })
);

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form_item, { label: "Задержка" }, {
      default: _withCtx(() => [
        _createVNode(MyTimeSelectorInSeconds, {
          modelValue: _unref(model).delay,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).delay) = $event)),
          min: 1
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(HelpBlock, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("p", null, "Задержка помогает на время приостановить исполнение алгоритма для конкретного получателя.", -1),
        _createElementVNode("p", null, "Подключите этот блок к любому выходу предыдущего блока, а к выходу блока \"Задержка\" подключите следующий блок, который должен будет исполниться спустя заданное время задержки.", -1)
      ])),
      _: 1
    })
  ]))
}
}

})