import {App} from "vue";
import {Router} from "vue-router";

export default class CoreUIAppStorage {
    private static app: App;
    private static router: Router;

    public static setApp(app: App, router: Router) {
        this.app = app;
        this.router = router;
    }

    public static getApp(): App {
        return this.app;
    }

    public static getRouter(): Router {
        return this.router;
    }
}