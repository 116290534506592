import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "m-0 d-inline-block"
}
const _hoisted_2 = {
  key: 0,
  class: "d-md-none d-block mt-3"
}

import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import {MassActionsSelectionModelInterface} from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import MassActionsRowCheckbox from "~/cabinet/vue/interface/data/MassActionsRowCheckbox.vue";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "ElCardHeader"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    buttonColSpan: { default: 0 },
    title: { default: "" },
    selectionModel: {},
    selectionLabel: {}
  },
  setup(__props: any) {

let props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(ElCardSection, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { class: "d-flex align-items-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: _unref(isMobileWindowSize) ? 24 : (24 - _ctx.buttonColSpan)
          }, {
            default: _withCtx(() => [
              (_ctx.selectionModel)
                ? (_openBlock(), _createBlock(MassActionsRowCheckbox, {
                    key: 0,
                    class: "checkbox",
                    "selection-label": _ctx.selectionLabel,
                    "selection-model": _ctx.selectionModel
                  }, null, 8, ["selection-label", "selection-model"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "left"),
              (_ctx.title || _ctx.$slots.title != null)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_1, [
                    _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                    _renderSlot(_ctx.$slots, "title")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["span"]),
          (!_unref(isMobileWindowSize) && _ctx.buttonColSpan)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: _ctx.buttonColSpan,
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["span"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }),
      (_ctx.buttonColSpan && _unref(isMobileWindowSize))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})