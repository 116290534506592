import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import Align from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Align.vue";
import Href from "~/cabinet/vue/interface/htmlConstructor/blocks/common/Href.vue";
import {
    HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE,
    HTML_CONSTRUCTOR_HEADER_TYPE_TEXT,
    HTML_CONSTRUCTOR_HEADER_TYPES,
    IHeaderBlockValues
} from "~/cabinet/vue/interface/htmlConstructor/blocks/header/IHeaderBlockValues";
import HtmlConstructorImage from "~/cabinet/vue/interface/htmlConstructor/blocks/common/HtmlConstructorImage.vue";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "HeaderForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockForm(props);

let _modelValue = _useModel<IHeaderBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

const types = HTML_CONSTRUCTOR_HEADER_TYPES;

let isText = computed(() => {
    return model.value.headerType == HTML_CONSTRUCTOR_HEADER_TYPE_TEXT;
});

let isImage = computed(() => {
    return model.value.headerType == HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE;
});


return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-position": "top" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Тип шапки" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _unref(model).headerType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).headerType) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(types), (descr, id) => {
                return (_openBlock(), _createBlock(_component_el_radio, {
                  key: id,
                  value: id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(descr), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      (_unref(isText))
        ? (_openBlock(), _createBlock(Align, {
            key: 0,
            modelValue: _unref(model).align,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).align) = $event))
          }, null, 8, ["modelValue"]))
        : (_unref(isImage))
          ? (_openBlock(), _createBlock(HtmlConstructorImage, {
              key: 1,
              modelValue: _unref(model).src,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).src) = $event))
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true),
      (_unref(isText) || _unref(isImage))
        ? (_openBlock(), _createBlock(Href, {
            key: 2,
            modelValue: _unref(model).href,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(model).href) = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})