import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }

import {ref} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import MyRemoteDataTable from "~/cabinet/vue/interface/data/MyRemoteDataTable.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import ColorPreview from "~/cabinet/vue/client/online/config/design/tabs/ColorPreview.vue";
import MyRemoteDataTableInterface from "~/cabinet/vue/interface/data/MyRemoteDataTableInterface";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import {IHtmlConstructorType} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";

interface IHtmlConstructorStyleTemplate {
    id: number
    descr: string,
    isCommon: boolean,
    style: Dictionary<any>
}

const __default__ = {
    name: "HtmlConstructorStyleTemplates"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    type: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let model = _useModel<any>(__props, "modelValue");

let table = ref<MyRemoteDataTableInterface>();
let data = ref<{ items: IHtmlConstructorStyleTemplate[] }>();
let saveDialog = ref<{
    visible: boolean,
    model: {
        isNew: boolean,
        newDescr: string,
        oldId: number
    }
}>();

function onRowClick(row: IHtmlConstructorStyleTemplate) {
    model.value = {
        ...ObjectHelper.jsonClone(model.value),
        ...ObjectHelper.jsonClone(row.style)
    };
}

async function removeStyle(row: IHtmlConstructorStyleTemplate) {
    try {
        await ElConfirm(`Вы уверены, что хотите удалить стиль ${row.descr}?`);
        let result = await LcabApiRequest.save({
            url: `/api/htmlConstructor/${props.type.id}/removeStyleTemplate/${row.id}`
        });
        if (result.isSuccess) {
            reload();
        }
    } catch (e) {
    }
}

function saveStyle() {
    saveDialog.value = {
        visible: true,
        model: {
            isNew: true,
            newDescr: "Новый стиль",
            oldId: null
        }
    };
}

async function makeSave() {
    let result = await LcabApiRequest.save({
        url: `/api/htmlConstructor/${props.type.id}/saveStyleTemplate`,
        p: {
            ...saveDialog.value.model,
            style: model.value
        }
    });
    if (result.isSuccess) {
        saveDialog.value = null;
        reload();
    }
}

function reload() {
    table.value?.reload();
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        class: "w-100",
        round: "",
        type: "success",
        onClick: saveStyle
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Сохранить текущий стиль ")
        ])),
        _: 1
      })
    ]),
    _createVNode(MyRemoteDataTable, {
      ref_key: "table",
      ref: table,
      "data-url": `/api/htmlConstructor/${_ctx.type.id}/getStyleTemplates`,
      data: _unref(data),
      "onUpdate:data": _cache[0] || (_cache[0] = ($event: any) => (_isRef(data) ? (data).value = $event : data = $event)),
      "show-header": false,
      "row-key": "id",
      onRowClick: onRowClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, null, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.descr), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "right",
          width: "120px"
        }, {
          default: _withCtx(({row}) => [
            _createVNode(ColorPreview, {
              color: row.style.bodyH1Color
            }, null, 8, ["color"]),
            _createVNode(ColorPreview, {
              color: row.style.bodyButtonBackground
            }, null, 8, ["color"]),
            _createVNode(ColorPreview, {
              color: row.style.headerBackground
            }, null, 8, ["color"]),
            (!row.isCommon)
              ? (_openBlock(), _createBlock(MyIconButton, {
                  key: 0,
                  delete: "",
                  onClick: ($event: any) => (removeStyle(row))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data-url", "data"]),
    (_unref(saveDialog) && _unref(saveDialog).visible)
      ? (_openBlock(), _createBlock(MyDialog, {
          key: 0,
          form: {
                    labelPosition: 'top',
                    model: _unref(saveDialog).model
                },
          visible: _unref(saveDialog).visible,
          "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_unref(saveDialog).visible) = $event)),
          title: "Сохранение стилей",
          onApply: makeSave
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _unref(saveDialog).model.isNew,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(saveDialog).model.isNew) = $event)),
                  "active-text": "Создание нового стиля",
                  "inactive-text": "Пересохранение старого стиля"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_unref(saveDialog).model.isNew)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  rules: _unref(requiredRule),
                  label: "Введите название нового стиля",
                  prop: "newDescr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(saveDialog).model.newDescr,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(saveDialog).model.newDescr) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rules"]))
              : (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  rules: _unref(requiredRule),
                  label: "Выберите изменяемый шаблон",
                  prop: "oldId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _unref(saveDialog).model.oldId,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(saveDialog).model.oldId) = $event)),
                      class: "w-100"
                    }, {
                      default: _withCtx(() => [
                        (_unref(data) && _unref(data).items)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(data).items, (item) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: item.id
                              }, [
                                (!item.isCommon)
                                  ? (_openBlock(), _createBlock(_component_el_option, {
                                      key: 0,
                                      label: item.descr,
                                      value: item.id
                                    }, null, 8, ["label", "value"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rules"]))
          ]),
          _: 1
        }, 8, ["form", "visible"]))
      : _createCommentVNode("", true)
  ]))
}
}

})