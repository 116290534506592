import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createSlots as _createSlots, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mt-3 font-size-mini" }
const _hoisted_4 = { class: "text-red" }
const _hoisted_5 = ["innerHTML"]

import RecipientErrorsSection from "~/cabinet/vue/client/sender/send/create/confirmation/RecipientErrorsSection.vue";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import {__} from "~/ts/library/Translate";
import Currency from "~/cabinet/vue/interface/Currency.vue";
import PriceSection from "~/cabinet/vue/client/sender/send/create/confirmation/PriceSection.vue";
import AnotherSourcesSection from "~/cabinet/vue/client/sender/send/create/confirmation/AnotherSourcesSection.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import {ClientPayRoutesBuilder} from "~/cabinet/ts/routes/ClientPayRoutesBuilder";
import {
    useSenderCreateConfirmationInfo,
    useSenderCreateConfirmationInfoProps
} from "~/cabinet/vue/client/sender/send/create/confirmation/useSenderCreateConfirmationInfo";
import {computed} from "vue";
import {RouteLocationNamedRaw} from "vue-router";
import {localeDateTimeString, mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";
import {SenderContentFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "SenderCreateConfirmationInfo"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useSenderCreateConfirmationInfoProps(),
    tag: {
        type: String
    },
    showSourceWarningBlock: {
        type: Boolean
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {
    senderResult,
    uslugaId,
    isSuccess,
    isDone,
    senderResultDescr
} = useSenderCreateConfirmationInfo(props);

let _modelValue = _useModel<SenderContentFormModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let timeWarning = computed(() => {
    return "В настройках рассылки стоит ограничение на время отправки сообщений с %1$s по %2$s. Из-за этого ваше сообщение не будет отправлено вовремя.";
});

let moderationTime = computed(() => {
    return __('Модераторы занимаются одобрением рассылок %1$s с %2$s до %3$s %4$s по Московскому времени.', '<span class="text-red">', '05:00', '00:00', '</span>');
});

let needToPayLocation = computed<RouteLocationNamedRaw>(() => {
    let location: RouteLocationNamedRaw;
    if (senderResult.value.isNoMoney && senderResult.value.pay) {
        let pay = senderResult.value.pay;
        let sum = pay.needToPayForSameTarifScale ? pay.needToPayForSameTarifScale.sum : pay.needToPay.sum;
        location = ClientPayRoutesBuilder.getPayLocation(sum);
    }
    return location;
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag ? __props.tag : 'el-card'), null, {
    default: _withCtx(() => [
      (!_ctx.result)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(ElCardHeader, {
              title: _unref(__)('Подождите, идет загрузка')
            }, null, 8, ["title"]),
            _createVNode(ElCardSection, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                  plain: "",
                  round: "",
                  type: "info",
                  onClick: _ctx.back
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(__)('Отмена')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "onClick"])
              ]),
              _: 1
            })
          ], 64))
        : (_unref(isDone))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_unref(isSuccess))
                ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
                    key: 0,
                    value: _unref(senderResultDescr),
                    icon: "exclamation-triangle",
                    "icon-color": "red",
                    label: "Ошибка создания рассылки"
                  }, _createSlots({ _: 2 }, [
                    (_unref(senderResult).isNoMoney && _unref(senderResult).price && _unref(senderResult).pay)
                      ? {
                          name: "append",
                          fn: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createElementVNode("p", null, [
                                _cache[0] || (_cache[0] = _createTextVNode(" Рассылка стоит ")),
                                _createVNode(Currency, {
                                  price: _unref(senderResult).price,
                                  class: "font-weight-bold"
                                }, null, 8, ["price"]),
                                _cache[1] || (_cache[1] = _createTextVNode(" и вам не хватает ")),
                                _createVNode(Currency, {
                                  price: _unref(senderResult).pay.needToPay,
                                  class: "font-weight-bold"
                                }, null, 8, ["price"])
                              ]),
                              (_unref(senderResult).pay.needToPayForSameTarifScale)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                                    _cache[2] || (_cache[2] = _createTextVNode(" Но для того, чтобы остаться на текущем тарифе, вам нужно доплатить ")),
                                    _createVNode(Currency, {
                                      class: "font-weight-bold",
                                      price: _unref(senderResult).pay.needToPayForSameTarifScale
                                    }, null, 8, ["price"]),
                                    _cache[3] || (_cache[3] = _createTextVNode(" . Иначе тариф будет пересчитан, и вам снова может не хватить денег на рассылку. "))
                                  ]))
                                : _createCommentVNode("", true),
                              _renderSlot(_ctx.$slots, "need-to-pay-append")
                            ])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["value"]))
                : (_unref(senderResult).warningText)
                  ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
                      key: 1,
                      icon: "exclamation-triangle",
                      "icon-color": "orange",
                      value: _unref(senderResult).warningText,
                      label: "Внимание, возможны проблемы"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "prepend"),
              _createVNode(ElCardSectionWithIcon, {
                label: _unref(__)('Количество получателей'),
                value: _unref(senderResult).recipients.count,
                icon: "users",
                "icon-color": _unref(isSuccess) ? 'blue' : null,
                light: ""
              }, null, 8, ["label", "value", "icon-color"]),
              _createVNode(RecipientErrorsSection, { "sender-result": _unref(senderResult) }, null, 8, ["sender-result"]),
              _createVNode(ElCardSectionWithIcon, {
                "icon-color": _unref(isSuccess) ? 'orange' : null,
                label: _unref(__)('Дата отправки'),
                value: _unref(localeDateTimeString)(_unref(senderResult).dateTimeSend),
                icon: "clock",
                light: ""
              }, _createSlots({ _: 2 }, [
                (!_unref(senderResult).isInTimeRange)
                  ? {
                      name: "append",
                      fn: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("b", _hoisted_4, _toDisplayString(_unref(__)('Внимание')) + "!", 1),
                          _createTextVNode(" " + _toDisplayString(_unref(__)(_unref(timeWarning), _unref(senderResult).timeRange.start, _unref(senderResult).timeRange.stop)), 1)
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["icon-color", "label", "value"]),
              _createVNode(PriceSection, {
                "is-success": _unref(isSuccess),
                "sender-result": _unref(senderResult)
              }, null, 8, ["is-success", "sender-result"]),
              _renderSlot(_ctx.$slots, "after-price-section"),
              _createVNode(AnotherSourcesSection, {
                "is-success": _unref(isSuccess),
                "sender-result": _unref(senderResult),
                "show-source-warning-block": __props.showSourceWarningBlock
              }, null, 8, ["is-success", "sender-result", "show-source-warning-block"]),
              (_unref(senderResult).details)
                ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
                    key: 2,
                    "icon-color": _unref(isSuccess) ? 'purple' : null,
                    label: _unref(__)('Реквизиты'),
                    value: _unref(senderResult).details.descr,
                    icon: "file-alt",
                    light: ""
                  }, null, 8, ["icon-color", "label", "value"]))
                : _createCommentVNode("", true),
              (_unref(senderResult).isModerate)
                ? (_openBlock(), _createBlock(ElCardSectionWithIcon, {
                    key: 3,
                    "icon-color": _unref(isSuccess) ? 'red' : null,
                    icon: "exclamation-triangle",
                    light: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_unref(__)('Ваши сообщения будут проверяться модератором и могут быть отклонены.')), 1),
                      _createElementVNode("p", { innerHTML: _unref(moderationTime) }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  }, 8, ["icon-color"]))
                : _createCommentVNode("", true),
              _createVNode(ElCardSection, { class: "sticky -bottom" }, {
                default: _withCtx(() => [
                  (_unref(isSuccess))
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                        onClick: _ctx.send,
                        round: "",
                        type: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(__)('Продолжить отправку')), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "onClick"]))
                    : (_unref(needToPayLocation))
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: _unref(needToPayLocation),
                          target: "_blank"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                              round: "",
                              type: "success"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(__)('Пополнить баланс')), 1)
                              ]),
                              _: 1
                            }, 8, ["class"])
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                  _createVNode(_component_el_button, {
                    class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
                    plain: "",
                    round: "",
                    type: "info",
                    onClick: _ctx.back
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(__)('Отмена')), 1)
                    ]),
                    _: 1
                  }, 8, ["class", "onClick"])
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})