import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {inject} from 'vue';
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {HtmlConstructorEditorInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "Href"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

let model = _useModel<string>(__props, "modelValue");

let htmlConstructorEditor = inject(HtmlConstructorEditorInject);

return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: "Ссылка" }, {
    default: _withCtx(() => [
      _createVNode(TextInputWithVariables, {
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
        multiline: false,
        placeholder: "Например: https://google.com",
        "content-variables": _unref(htmlConstructorEditor).params.contentVariables
      }, null, 8, ["modelValue", "content-variables"])
    ]),
    _: 1
  }))
}
}

})