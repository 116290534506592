import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

import {computed, getCurrentInstance} from 'vue';
import LcabWindowHelper from "~/ts/library/LcabWindowHelper";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "ElCardSectionSearch"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    placeholder: {},
    prependSpan: { default: 0 },
    appendSpan: { default: 0 },
    showButton: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["enter"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<string>(__props, "modelValue");

let instance = getCurrentInstance().proxy;

let span = computed(() => {
    let result = 24 - buttonSpan.value;
    if (instance.$slots.prepend) {
        result -= props.prependSpan;
    }
    if (instance.$slots.append) {
        result -= props.appendSpan;
    }
    return result;
});

let buttonSpan = computed(() => {
    return props.showButton ? (LcabWindowHelper.isMobile() ? 4 : 3) : 0;
});


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(ElCardSection, { class: "search-section" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          (_ctx.$slots.prepend)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: _ctx.prependSpan
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "prepend")
                ]),
                _: 3
              }, 8, ["span"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_col, { span: _unref(span) }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_el_input, {
                placeholder: _ctx.placeholder,
                modelValue: _unref(model),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                clearable: ""
              }, null, 8, ["placeholder", "modelValue"]), [
                [
                  _directive_on_native,
                  () => _unref(emits)('enter'),
                  "keyup",
                  { enter: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["span"]),
          (_ctx.$slots.append)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 1,
                span: _ctx.appendSpan
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "append")
                ]),
                _: 3
              }, 8, ["span"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_col, {
            span: _unref(buttonSpan),
            style: _normalizeStyle(_ctx.showButton ? {visibility: 'visible', 'opacity': 1 } : {visibility:'hidden', opacity: 0})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "w-100 search-button",
                type: "success",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('enter')))
              }, {
                default: _withCtx(() => [
                  _createVNode(FontAwesomeIcon, {
                    icon: "search",
                    light: ""
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["span", "style"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})