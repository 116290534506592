import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import IContentsBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/IContentsBlockValues";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "ContentsBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {} = useBlockForm(props);

let _modelValue = _useModel<IContentsBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})