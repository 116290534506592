import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-2" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "mt-2" }

import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import MyUploadFileList from "~/cabinet/vue/interface/data/MyUploadFileList.vue";
import CabinetUploadHelper from "~/cabinet/ts/service/CabinetUploadHelper";
import Dictionary from "~/ts/library/Dictionary";
import getClientDocumentUrl from "~/cabinet/vue/client/documents/getClientDocumentUrl";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {computed, ref} from "vue";


const __default__ = {
    name: "ScanDialog"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    documentTypeId: {},
    documentId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["reload"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit

let visible = _useModel<boolean>(__props, "visible");

let dialogData = ref<any>();
let scanList = ref<Dictionary<string>>();

(async () => {
    let result = await LcabApiRequest.fetch({
        url: `/api/documents/scan/${props.documentTypeId}/${props.documentId}/getDialog`
    });
    if (!result.showMessageOnError()) {
        dialogData.value = result.data;
    } else {
        visible.value = false;
    }
})();

let downloadUrl = computed(() => {
    return getClientDocumentUrl(props.documentTypeId, props.documentId);
});

let action = computed(() => {
    return (new CabinetUploadHelper())
        .setLongStore()
        .setTag(`document-scan-${props.documentTypeId}-${props.documentId}`)
        .getUrl();
});

let fileIds = computed(() => {
    let result = [];
    if (scanList.value) {
        for (let key in scanList.value) {
            if (scanList.value.hasOwnProperty(key)) {
                result.push(key);
            }
        }
    }
    return result;
});

async function onApply() {
    let result = await LcabApiRequest.save({
        url: `/api/documents/scan/${props.documentTypeId}/${props.documentId}/saveScan`,
        p: {
            fileIds: fileIds.value
        }
    });
    if (result.isSuccess) {
        emits("reload");
        visible.value = false;
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_unref(dialogData) && _unref(visible))
    ? (_openBlock(), _createBlock(_component_el_form, {
        key: 0,
        size: "medium"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              (_unref(dialogData))
                ? (_openBlock(), _createBlock(MyDialog, {
                    key: 0,
                    visible: _unref(visible),
                    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
                    title: "Загрузить отсканированный подписанный документ",
                    onApply: onApply,
                    "apply-disabled": !_unref(fileIds).length
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_card, null, {
                        default: _withCtx(() => [
                          _createVNode(ElCardSectionWithIcon, { "gray-label": false }, {
                            icon: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createElementVNode("div", { class: "text-center" }, " 1 ", -1)
                            ])),
                            label: _withCtx(() => [
                              _cache[4] || (_cache[4] = _createElementVNode("p", null, "Скачайте и распечатайте документ, если Вы этого ещё не сделали", -1)),
                              _createElementVNode("p", _hoisted_1, [
                                _createElementVNode("a", {
                                  href: _unref(downloadUrl),
                                  target: "_blank"
                                }, [
                                  _createVNode(_component_el_button, null, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode("Скачать документ")
                                    ])),
                                    _: 1
                                  })
                                ], 8, _hoisted_2)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(ElCardSectionWithIcon, { "gray-label": false }, {
                            icon: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createElementVNode("div", { class: "text-center" }, "2", -1)
                            ])),
                            label: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Поставьте подпись и печать в обозначенных местах ")
                            ])),
                            _: 1
                          }),
                          _createVNode(ElCardSectionWithIcon, { "gray-label": false }, {
                            icon: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createElementVNode("div", { class: "text-center" }, "3", -1)
                            ])),
                            label: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" Загрузите файлы со сканами всех страниц документа ")
                            ])),
                            append: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(MyUploadFileList, {
                                  modelValue: _unref(scanList),
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(scanList) ? (scanList).value = $event : scanList = $event)),
                                  action: _unref(action),
                                  "input-is-listening": ""
                                }, null, 8, ["modelValue", "action"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["visible", "apply-disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})