import Events from "~/ts/library/Events";
import {internationalUrlIfNeeded} from "~/core-ui/ts/Constants";
import {EXCEPT_LINK_PROTOCOL, LINK_REGEXP} from "~/core-ui/vue/ui/CoreUiHtmlifyHelper";
import {computed, ExtractPropTypes, getCurrentInstance, h, inject, onMounted, watch} from "vue";
import emojify from "~/core-ui/vue/ui/emojify";
import {LightboxInjection} from "~/core-ui/ts/LightboxInjection";
import StringHelper from "~/ts/library/StringHelper";
import CoreUiHtmlifySnippetEventInterface from "~/core-ui/vue/ui/CoreUiHtmlifySnippetEventInterface";
import onHtmlifyClick from "~/core-ui/vue/ui/OnHtmlifyClick";
import {ElNotification} from "element-plus";
import {__} from "~/ts/library/Translate";

let tempDiv: HTMLDivElement = document.createElement("div");

let linkRegexp = LINK_REGEXP;
let scriptRegexp = /<script(.*?)?(src=["'](.*)["'])?>((.|\s)*?)<\/script>/gim;
const decodeTextarea = document.createElement("textarea");

export function useCoreUiHtmlify(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useCoreUiHtmlifyProps>>>,
    params?: {
        getSnippetId?: (link: string) => string,
        additionalLinkify?: (html: string) => string
    }
) {
    //TODO: проверить работоспособность
    /*
    defineEmits<{
        (e: "update:image-only", value: boolean): void,
        (e: "height-increase", value: number): void,
        (e: "snippet", value: CoreUiHtmlifySnippetEventInterface): void
    }>();
     */
    watch(computed(() => props.value), () => {
        emitImageOnly(false);
        parse();
    })

    onMounted(() => parse());

    function parse() {
        try {
            if (props.value != null) {
                let container = getContainer();
                if (container) {
                    let html = props.value;
                    if (props.bbcode) {
                        html = StringHelper.bbcodeToHtml(html).split(/<br\s?\/?>/i).join("\n");
                    } else if (props.escape) {
                        html = escapeHtml(html);
                    }


                    if (props.links) {
                        html = linkify(html);
                    }

                    container.innerHTML = html;
                    if (props.emoji) {
                        html = emojify(container);
                    }

                    setImagesOnLoad();
                    fix(container);

                    if (!props.escape) {
                        evalScript(html);
                    }

                }
            }
        } catch (e) {
            alert(e);
        }
    }

    let instance = getCurrentInstance().proxy;

    function evalScript(html: string) {
        let scriptMatch: any;
        while (scriptMatch = scriptRegexp.exec(html)) {
            if (scriptMatch[3]) {
                try {
                    let newScript = document.createElement("script");
                    newScript.src = scriptMatch[3];
                    instance.$el.appendChild(newScript);
                } catch (e) {
                    console.error("Error while insert script in tab", scriptMatch[3], e);
                }
            }
            if (scriptMatch[4]) {
                (function (code: string) {
                    try {
                        eval(scriptMatch[4]);
                    } catch (e) {
                        console.error("Error in eval inline tab script", code, e);
                    }
                })(scriptMatch[4]);
            }
        }
    }

    function getContainer(): HTMLElement | null {
        return instance.$refs.content as HTMLElement;
    }

    function escapeHtml(html: string): string {
        tempDiv.textContent = html;
        html = tempDiv.innerHTML;
        tempDiv.textContent = "";
        return html;
    }


    function setImagesOnLoad() {
        let container = getContainer();
        if (container) {
            let images = container.getElementsByTagName("img");
            for (let i = 0; i < images.length; i++) {
                setImageOnLoad(images[i]);
            }
        }
    }

    function setImageOnLoad(img: HTMLImageElement) {
        Events.once("load", img, () => {
            heightIncrease(img);
        });
    }

    function emitImageOnly(value: boolean) {
        instance.$emit("update:image-only", value);
    }

    function heightIncrease(element: HTMLElement) {
        instance.$emit("height-increase", element.offsetHeight);
    }

    function linkify(html: string) {
        html = html.replace(linkRegexp, link => {
            var decodedLink = htmlDecode(link);
            let a = document.createElement("a");
            a.target = "_blank";
            a.innerText = decodedLink;
            a.href = decodedLink;
            if (EXCEPT_LINK_PROTOCOL.includes(a.protocol?.toLowerCase())) {
                return link;
            }


            if (props.snippet && params?.getSnippetId) {
                let id = params.getSnippetId(decodedLink);
                if (id) {
                    a.id = id;
                }
            }


            return a.outerHTML;
        });

        if (params?.additionalLinkify) {
            html = params.additionalLinkify(html);
        }

        return html;
    }

    let lightbox = inject(LightboxInjection, null);

    function onSnippet(link: string, snippetId: string, payload: CometOperatorApplication.SnippetCometMessageOpenApiModel) {
        let eventPayload: CoreUiHtmlifySnippetEventInterface = {
            url: link,
            snippet: payload
        }
        instance.$emit("snippet", eventPayload);

        let a = document.getElementById(snippetId) as HTMLLinkElement;
        if (a) {
            let snippet: HTMLElement;
            let isImage = payload.type == "image";
            if (payload.snipurl) {
                payload.snipurl = internationalUrlIfNeeded(payload.snipurl)
            }

            if (isImage) {
                let img = document.createElement("img");
                img.src = payload.snipurl;
                snippet = document.createElement("a");
                snippet.setAttribute("tabindex", "");


                snippet.appendChild(img);
                //setImageOnLoad(img);
                if (lightbox) {
                    Events.addEventListener("click", img, function (e: MouseEvent) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation()
                        lightbox(link);
                    });
                    /*
                    snippet.addEventListener("click", function (e) {
                        debugger;
                        e.preventDefault();
                        lightbox(link);
                    });*/
                }
            }
            if (snippet) {
                let div = document.createElement("div");
                div.setAttribute("class", "snippet-wrapper");
                div.appendChild(snippet);
                a.parentElement.replaceChild(div, a);
                //a.replaceWith(div);
                heightIncrease(div);
            }
            if (isImage) {
                let container = getContainer();
                if (container) {
                    if (!container.innerText.trim().length) {
                        emitImageOnly(true);
                    }
                }
            }
        }
    }

    function htmlDecode(text: string) {
        decodeTextarea.innerHTML = text;
        return decodeTextarea.innerText;
    }

    function fix(element: HTMLElement) {
        let style = element.style;
        for (let i = 0; i < style.length; i++) {
            let prop = style[i];
            style.setProperty(prop, style.getPropertyValue(prop), "important");
        }

        if (element.children) {
            for (let i = 0; i < element.children.length; i++) {
                let child = element.children.item(i);
                if (child.nodeType == 1) {
                    fix(child as HTMLElement);
                }
            }
        }
    }

    const render = () => props.value ? h(props.tag,
        {
            class: ['htmlify', props.escape && props.nl2br ? 'pre' : null],
            ref: "content",
            attrs: {
                contenteditable: props.contenteditable ? 'true' : 'false'
            },
            on: {
                click: (e: MouseEvent) => {
                    onHtmlifyClick(e, {
                        onCopyError: () => {
                            ElNotification.error({
                                title: __('Не удалось скопировать в буфер обмена'),
                                duration: 2000,
                                message: null
                            });
                        },
                        onCopySuccess: () => {
                            ElNotification.success({
                                title: __('Скопировано в буфер обмена'),
                                duration: 2000,
                                message: null
                            });
                        }
                    })
                }
            }
        }) : null
    return {
        onSnippet,
        render
    }
}

export function useCoreUiHtmlifyProps() {
    return {
        tag: {
            type: String,
            default: "span"
        },
        value: {
            type: String
        },
        emoji: {
            type: Boolean
        },
        links: {
            type: Boolean
        },
        escape: {
            type: Boolean,
            default: true
        },
        bbcode: {
            type: Boolean,
            default: false
        },
        nl2br: {
            type: Boolean,
            default: false
        },
        contenteditable: {
            type: Boolean
        },
        snippet: {
            type: Boolean
        }
    }
}