import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";


const __default__ = {
    name: "MyFilterHidden"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    ...useFilterComponentProps()
},
  setup(__props) {

let props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(_ctx.component.filter.placeholder), 1))
}
}

})