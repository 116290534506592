import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

import {computed, onMounted, ref, watch} from 'vue';
import {
    ISenderFormContact,
    ISenderFormGroup,
    SenderFormParamsInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import SenderReceiverPreview from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverPreview.vue";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import ReceiverGroupIcon from "~/cabinet/vue/client/sender/send/receiverSelect/ReceiverGroupIcon.vue";
import MyTransitionSlideX from "~/cabinet/vue/interface/transition/MyTransitionSlideX.vue";
import MyFormItemLabelLink from "~/cabinet/vue/interface/form/MyFormItemLabelLink.vue";
import {__} from "~/ts/library/Translate";
import Delay from "~/ts/library/Delay";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {
    PHONEBOOK_COLUMN_NAME_EMAIL,
    PHONEBOOK_COLUMN_NAME_FIRSTNAME,
    PHONEBOOK_COLUMN_NAME_LASTNAME,
    PHONEBOOK_COLUMN_NAME_MIDDLENAME,
    PHONEBOOK_COLUMN_NAME_PHONE
} from "~/cabinet/vue/client/phonebook/contacts/constants";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {SenderFormReceiversInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";


const __default__ = {
    name: "SenderReceiversPreview"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    recipients: {},
    uslugaId: {},
    groups: {},
    contacts: {},
    form: {},
    showGroups: { type: Boolean, default: true },
    showContacts: { type: Boolean, default: true }
  },
  setup(__props: any) {

let props = __props;

let recipientsCount = ref<{
    groups: Dictionary<number>,
    excludedContacts: number[]
}>();

async function recountRecipients() {
    try {
        await Delay.make(1000, "SenderReceiversPreviewRecount", true);
        let result = await LcabApiRequest.fetch({
            url: `/api/sender/${props.uslugaId}/${props.form.id}/getRecountReceivers`,
            p: {recipients: props.recipients},
            silent: true
        });
        if (result.isSuccess) {
            recipientsCount.value = result.getData();
        }
    } catch (e) {

    }
}

watch(computed(() => props.recipients), () => recountRecipients(), {deep: true});

onMounted(() => {
    recountRecipients();
});

let groupsCount = computed(() => {
    return props.recipients && props.recipients.groups ? props.recipients.groups.length : 0;
});

let contactsCount = computed(() => {
    return props.recipients && props.recipients.contacts ? props.recipients.contacts.length : 0;
});

let receiverIdField = computed(() => {
    return SenderReceiverSelectHelper.getReceiverIdFieldByUslugaId(props.uslugaId);
});

let groupsDictionary = computed(() => {
    return props.groups ? ObjectHelper.dictionaryFromArray(props.groups, "id") : {};
});

let rows = computed(() => {
    let result: any[] = [];
    if (groupsCount.value && props.showGroups) {
        result.push({
            id: "groups",
            count: groupsCount.value,
            originalItems: props.recipients.groups,
            items: props.recipients.groups.map(groupId => {
                let group: ISenderFormGroup = props.groups ? props.groups.find(group => group.id == groupId) : null;
                if (!group) {
                    group = {
                        id: groupId,
                        descr: `Группа #${groupId}`,
                        color: 'gray',
                        count: 0,
                        isHidden: false,
                        isStop: false
                    };
                }
                return group;
            }),
            descr: __("Группы")
        });
    }
    if (contactsCount.value && props.showContacts) {
        result.push({
            id: "contacts",
            count: contactsCount.value,
            originalItems: props.recipients.contacts,
            items: props.recipients.contacts.map(contactId => {
                let contact: ISenderFormContact = props.contacts.find(contact => contact.id == contactId);
                if (!contact) {
                    contact = {
                        id: contactId,
                        groupId: 0,
                        [PHONEBOOK_COLUMN_NAME_LASTNAME]: `Контакт #${contactId}`,
                        [PHONEBOOK_COLUMN_NAME_FIRSTNAME]: "",
                        [PHONEBOOK_COLUMN_NAME_MIDDLENAME]: "",
                        [PHONEBOOK_COLUMN_NAME_EMAIL]: "",
                        [PHONEBOOK_COLUMN_NAME_PHONE]: ""
                    };
                }
                return contact;
            }),
            descr: __("Контакты")
        });
    }
    return result;
});

function contactDescr(contact: ISenderFormContact): string {
    let result = (contact as any)[receiverIdField.value];
    if (!result) {
        result = "";
    }
    let fullName = SenderReceiverSelectHelper.getFullNameByContact(contact);
    if (fullName != null) {
        if (result && result.length) {
            result += ", ";
        }
        result += fullName;
    }
    return result;
}

function isContactExcluded(id: number): boolean {
    return recipientsCount.value && recipientsCount.value.excludedContacts.indexOf(id) > -1;
}

function getGroupCount(id: number): string | null | number {
    let result;
    let group = groupsDictionary.value[id];
    if (group) {
        let filteredCount = recipientsCount.value ? recipientsCount.value.groups[id] : null;
        result = filteredCount != null && filteredCount != group.count ? __("%1$s из %2$s", filteredCount, group.count) : group.count;
    }
    return result;
}


return (_ctx: any,_cache: any) => {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(MyTransitionSlideX, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rows), (row, index) => {
        return (_openBlock(), _createBlock(_component_el_form_item, {
          key: row.id,
          class: _normalizeClass(['receiver-preview', index < _unref(rows).length - 1 ? 'without-border' : ''])
        }, {
          label: _withCtx(() => [
            _createVNode(MyFormItemLabelLink, {
              onClick: ($event: any) => (row.originalItems.splice(0, row.count))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(__)('Очистить')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createTextVNode(" " + _toDisplayString(row.descr), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(MyTransitionSlideX, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.items, (item, index) => {
                  return (_openBlock(), _createBlock(SenderReceiverPreview, {
                    key: item.id,
                    count: row.id === 'groups' ? getGroupCount(item.id) : null,
                    descr: row.id === 'groups' ? item.descr : contactDescr(item),
                    "line-through": row.id === 'contacts' && isContactExcluded(item.id),
                    type: row.id,
                    onDelete: ($event: any) => (row.originalItems.splice(index, 1))
                  }, {
                    icon: _withCtx(() => [
                      (row.id === 'groups')
                        ? (_openBlock(), _createBlock(ReceiverGroupIcon, {
                            key: 0,
                            group: item
                          }, null, 8, ["group"]))
                        : (_openBlock(), _createBlock(FontAwesomeIcon, {
                            key: 1,
                            color: "muted",
                            icon: "user",
                            light: ""
                          }))
                    ]),
                    _: 2
                  }, 1032, ["count", "descr", "line-through", "type", "onDelete"]))
                }), 128))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})