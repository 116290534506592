import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass({
        'saved-block': true
    })
}
const _hoisted_2 = {
  key: 0,
  class: "message text-muted"
}
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "message error"
}
const _hoisted_5 = {
  key: 1,
  class: "position-relative"
}
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 1,
  class: "mt-4"
}

import {computed, ref, watch} from "vue";
import ISavedBlockValues from "./ISavedBlockValues";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import HtmlConstructorBlocksList from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorBlocksList.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import SavedBlockForm from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/SavedBlockForm.vue";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";


const __default__ = {
    name: "SavedBlockDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["remove-block", "replace"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

let props = __props;

let {htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<ISavedBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let emits = __emit;


let insertAsLink = ref<boolean>();


watch(model, () => initBlock(), {deep: true})

function remove() {
    emits("remove-block");
}

let block = computed(() => {
    let blocks = htmlConstructorEditor.savedBlocks.value
    if (model.value.blockId && htmlConstructorEditor.savedBlocks.value) {
        return blocks.find(item => item.id == model.value.blockId);
    }
    return null;
});

let error = computed(() => {
    return !block.value && htmlConstructorEditor.savedBlocks.value;
});

async function getSavedBlocks() {
    await htmlConstructorEditor.getSavedBlocks(false, true);
    return htmlConstructorEditor.savedBlocks.value;
}

async function initBlock() {
    let blocks = await getSavedBlocks();
    if (!blocks.length && model.value.displayStartupDialog) {
        model.value.displayStartupDialog = false;
        await (new LcabApiResult({
            code: 0,
            descr: "У вас пока нет сохраненных блоков",
            data: {}
        })).showMessage();
        remove();
        return;
    }
    //если insertAsLink !== null и false, то сразу после инициализации нужно подменить этот блок копией
    if (insertAsLink.value === false && block.value) {
        replace();
    }

}

function replace() {
    emits("replace", ObjectHelper.jsonClone(block.value.block));
}

function showSelectBlockDialog(asLink: boolean) {
    insertAsLink.value = asLink;
}

function onBlockSelect() {
    delete model.value.displayStartupDialog;
}

initBlock()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(model).blockId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Выберите сохраненный блок для подстановки в конструктор "))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_unref(block))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!_unref(error))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(MyLoader, { big: "" })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[4] || (_cache[4] = [
                      _createElementVNode("p", null, "Не удалось найти вставленный блок.", -1),
                      _createElementVNode("p", null, "Возможно, он был удалён.", -1)
                    ])))
              ], 64))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(HtmlConstructorBlocksList, {
                  class: "blocks-list",
                  style: {"pointer-events":"none"},
                  "blocks-list-editable": false,
                  "model-value": [_unref(block).block]
                }, null, 8, ["model-value"]),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "absolute-full-size" }, null, -1))
              ]))
        ], 64)),
    (_unref(model).displayStartupDialog)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 2,
          "model-value": _unref(model).displayStartupDialog,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (delete _unref(model).displayStartupDialog)),
          "show-close": false,
          title: "Вставка сохраненного блока",
          width: "500px"
        }, {
          default: _withCtx(() => [
            (_unref(insertAsLink) === undefined)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_button, {
                        class: "w-100",
                        type: "primary",
                        round: "",
                        plain: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (showSelectBlockDialog(true)))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, { icon: "link" })
                        ]),
                        default: _withCtx(() => [
                          _cache[6] || (_cache[6] = _createTextVNode(" Вставить как ссылку "))
                        ]),
                        _: 1
                      })
                    ]),
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-size-micro mt-2" }, [
                      _createTextVNode(" Удобно, если вам нужно вставить один и тот же блок во множество шаблонов."),
                      _createElementVNode("br"),
                      _createTextVNode(" Если вы в будущем отредактируете блок, вставленный по ссылке, он изменится во всех шаблонах, где был вставлен. ")
                    ], -1))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_button, {
                        class: "w-100",
                        type: "primary",
                        round: "",
                        plain: "",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (showSelectBlockDialog(false)))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(FontAwesomeIcon, { icon: "copy" })
                        ]),
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createTextVNode(" Вставить как копию "))
                        ]),
                        _: 1
                      }),
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "font-size-micro mt-2" }, [
                        _createTextVNode(" Будет создана локальная копия вставленного блока."),
                        _createElementVNode("br"),
                        _createTextVNode(" Если вы измените этот блок, это не будет отражено в других шаблонах. ")
                      ], -1))
                    ])
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                class: "w-100",
                type: "danger",
                round: "",
                plain: "",
                onClick: remove
              }, {
                icon: _withCtx(() => [
                  _createVNode(FontAwesomeIcon, { icon: "times" })
                ]),
                default: _withCtx(() => [
                  _cache[10] || (_cache[10] = _createTextVNode(" Отменить вставку "))
                ]),
                _: 1
              })
            ]),
            (_unref(insertAsLink) !== undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(SavedBlockForm, {
                    modelValue: _unref(model),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
                    onSelect: onBlockSelect
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model-value"]))
      : _createCommentVNode("", true)
  ]))
}
}

})