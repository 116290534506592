import Dictionary from "~/ts/library/Dictionary";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import {RouteLocationNamedRaw, RouteLocationNormalizedLoadedGeneric} from "vue-router";
import {useMyRouter} from "~/core-ui/ts/router/useMyRouter";


export default class MenuItem {
    public descr: string;
    public icon?: string;
    public iconColor?: string;
    private childrenList: MenuChildrenListItem[];
    public routeName: string;
    private routeParamsList: Dictionary<any>;
    protected _badge?: MenuBadge;
    protected delimiter: boolean = false;
    protected title: boolean = false;
    protected linkAddress: string | (() => string) = null;
    public mutedOnBadgeValue: any;
    private isMutedOnBadgeValueUsed: boolean = false;
    private replaceRouteNameByCurrent = false;
    private routeNameReplacements: Dictionary<string> = null;
    public selected: boolean = false;
    public onClick: () => void;
    private _originalIcon: string;
    private _originalDescr: string;
    private blank: boolean = false;

    public get isSelectable(): boolean {
        return true;
    }


    public setTargetBlank(value: boolean = true) {
        this.blank = value;
        return this;
    }

    public isTargetBlank() {
        return this.blank;
    }

    public get children(): MenuItem[] {
        let result = [];
        for (let item of this.childrenList) {
            if (typeof item == "function") {
                result.push(...item());
            } else {
                result.push(item);
            }
        }
        return result;
    }

    replaceIcon(icon: string) {
        if (!this._originalIcon) {
            this._originalIcon = this.icon;
        }
        this.icon = icon;
        return this;
    }

    replaceDescr(descr: string) {
        if (!this._originalDescr) {
            this._originalDescr = this.descr;
        }
        this.descr = descr;
        return this;
    }

    get originalDescr() {
        return this._originalDescr ? this._originalDescr : this.descr;
    }

    get originalIcon() {
        return this._originalIcon ? this._originalIcon : this.icon;
    }

    setReplaceRouteNameByCurrent(routeNameReplacements?: Dictionary<string>) {
        this.replaceRouteNameByCurrent = true;
        this.routeNameReplacements = routeNameReplacements;
        return this;
    }


    get isVisible(): boolean {
        let result = false;
        let params = this.routeParams;
        let resolve = LcabLayoutStore.router.value.resolve({
            name: this.routeName,
            params: params ? params : undefined
        });
        if (resolve) {
            result = RouteAccessChecker.hasAccess(resolve);
        }
        return result;

    }

    constructor(routeName: string | RouteLocationNamedRaw, descr?: string, icon?: string) {
        this.childrenList = [];
        this.descr = descr ? descr : "";
        if (routeName) {
            if (typeof routeName == "object") {
                this.setRouteParamByLocation(routeName);
            } else {
                this.routeName = routeName;
            }
        }
        if (icon) {
            this.setIcon(icon);
        }
    }

    public setOnClick(callback: () => void) {
        this.onClick = callback;
        return this;
    }

    get isDelimiter() {
        return this.delimiter;
    }

    get isTitle() {
        return this.title;
    }

    get isHref(): boolean {
        return this.linkAddress != null;
    }

    get href(): string | null {
        if (this.linkAddress) {
            if (typeof this.linkAddress == "function") {
                return this.linkAddress();
            } else {
                return this.linkAddress;
            }
        } else {
            return null;
        }
    }

    public setIcon(icon: string, colorClass: string = null) {
        this.icon = icon;
        this.iconColor = colorClass;
        return this;
    }

    public setSelected(value: boolean) {
        this.selected = value;
        return this;
    }

    public addChildrenItem(item: MenuChildrenListItem) {
        this.childrenList.push(item);
        return this;
    }

    public setChildrenItems(items: MenuChildrenListItem[]) {
        this.childrenList = items;
        return this;
    }

    public get routeParams() {
        let result = null;
        if (this.routeParamsList) {
            result = {...this.routeParamsList};
            for (let key in result) {
                if (result.hasOwnProperty(key)) {
                    if (typeof result[key] == "function") {
                        result[key] = result[key]();
                    }
                }
            }
        }
        return result;
    }

    public addRouteParam(key: string, value: any | (() => any)) {
        if (this.routeParamsList == null) {
            this.routeParamsList = {};
        }
        this.routeParamsList[key] = value;
        return this;
    }

    public setRouteParamByLocation(location: RouteLocationNamedRaw) {
        this.routeName = location.name as string;
        let params = location.params;
        if (params) {
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    this.addRouteParam(key, params[key]);
                }
            }
        }
        return this;
    }

    public setBadge(badge: MenuBadge) {
        this._badge = badge;
        return this;
    }

    public get badge(): MenuBadge | null {
        return this._badge;
    }

    public get badgesList(): MenuBadge[] {
        let result = [];
        if (this.badge) {
            result.push(this.badge);
        } else {
            for (let item of this.children) {
                result.push(...item.badgesList);
            }
        }
        return result;
    }

    public get hasSelfBadge(): boolean {
        return !!this.badge;
    }

    get isMuted() {
        if (this.badge && this.isMutedOnBadgeValueUsed) {
            if (this.mutedOnBadgeValue === this.badgeValue) {
                return true;
            }
        }
        return false;
    }

    public setMutedOnBadgeValue(badgeValue: any) {
        this.mutedOnBadgeValue = badgeValue;
        this.isMutedOnBadgeValueUsed = true;
        return this;
    }

    public get badgeValue(): number | string | null {
        let result = null;
        if (this.badge) {
            result = this.badge.value;
        } else {
            for (let item of this.visibleChildrens) {
                if (item.badge && !item.badge.isUsedInParent) {
                    continue;
                }

                let value = item.badgeValue;
                if (typeof value == "number") {
                    if (result == null) {
                        result = 0;
                    }
                    result += value;
                }
            }
        }
        return result;
    }

    public get badgeType(): string | null {
        let badge = this.badge;
        if (badge) {
            return badge.type;
        }

        let childrensWithBadge = this.visibleChildrensWithBadge;
        return childrensWithBadge.length ? childrensWithBadge[0].badgeType : null;
    }

    public get isBadgeHidden(): boolean {
        let badge = this.badge;
        if (badge) {
            return badge.isHidden;
        }

        return !this.visibleChildrensWithBadge.find(item => !item.isBadgeHidden);
    }

    private get visibleChildrens() {
        return this.children.filter(item => item.isVisible);
    }

    private get visibleChildrensWithBadge() {
        return this.visibleChildrens.filter(item => !!item.badge);
    }


    public isMatchedToRoute($route: RouteLocationNormalizedLoadedGeneric): boolean {
        return !!$route.matched.find(routeRecord => {
            let result = false;
            if (routeRecord.name == this.routeName) {
                result = true;

                let routeParams = this.routeParams;
                if (routeParams) {
                    for (let key in routeParams) {
                        if (routeParams.hasOwnProperty(key)) {
                            if (routeRecord.path.indexOf(`:${key}`) > -1) {
                                if (routeParams.hasOwnProperty(key)) {
                                    if ($route.params[key] != routeParams[key]) {
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return result;
        })

    }

    getRawLocation() {
        if (this.routeName) {
            if (this.routeParams) {
                throw new Error("Не поддерживается getRawLocation c routeParams");
            }
            return {
                name: this.routeName
            }
        }
        return null;
    }

    getRouterLocation(route: RouteLocationNormalizedLoadedGeneric): RouteLocationNamedRaw {
        let routeName = this.routeName;

        let params = {...route.params};
        let menuRouteParams = this.routeParams;
        if (menuRouteParams) {
            params = {...params, ...menuRouteParams};
        }

        if (this.replaceRouteNameByCurrent) {
            routeName = route.name as string;
            if (this.routeNameReplacements && this.routeNameReplacements[routeName]) {
                routeName = this.routeNameReplacements[routeName];
            }
        }

        return useMyRouter().prepareLocationBeforeRouterPush({
            name: routeName,
            params
        });
    }

}

export type MenuChildrenListItem = MenuItem | (() => MenuItem[]);

export interface SelectMenuItemEventPayloadInterface {
    item: MenuItem,
    level: number
}