import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center lh-1" }
const _hoisted_2 = { class: "ml-3" }
const _hoisted_3 = { class: "d-flex align-items-center lh-1" }
const _hoisted_4 = { class: "ml-3" }

import ElCardSectionWithTable from "~/cabinet/vue/interface/card/ElCardSectionWithTable.vue";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyRemoteDataTable from "~/cabinet/vue/interface/data/MyRemoteDataTable.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import MyDialog from "~/cabinet/vue/interface/dialog/MyDialog.vue";
import {computed, ref} from "vue";
import TagInterface from "~/cabinet/vue/interface/form/tags/TagInterface";
import ReactiveFilterableListRequest from "~/core-ui/ts/request/ReactiveFilterableListRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import {predefineColors} from "~/core-ui/ts/CoreUiHelpersMixin";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "TagsConfigDialog"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    tagTypeId: {}
  }, {
    "visible": { type: Boolean },
    "visibleModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["update"], ["update:visible"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let isVisible = _useModel<boolean>(__props, "visible");
function close() {
    isVisible.value = false;
}

let tagConfigRequest = computed(() => {
    return new ReactiveFilterableListRequest(new LcabApiRequest({
        url: `/api/ui/tag/${props.tagTypeId}/getListForConfigDialog`
    }));
});

let changedList = computed<TagInterface[]>(() => {
    return (tagConfigRequest.value.data as any).changedList;
});


async function saveTag(tag: TagInterface) {
    let result = await LcabApiRequest.save({
        url: `/api/ui/tag/${props.tagTypeId}/saveTag`,
        p: tag
    });
    if (result.isSuccess) {
        addTagDialog.value = null;
        if (!tag.cc) {
            tagConfigRequest.value.make();
        } else {
            toggleArrayElement(changedList.value, tag, false);
        }
        emits("update");
    }
}

async function deleteTag(tag: TagInterface) {
    try {
        await ElConfirm("Точно удаляем этот тэг?");
        let result = await LcabApiRequest.save({
            url: `/api/ui/tag/${props.tagTypeId}/${tag.cc}/deleteTag`,
        });
        if (result.isSuccess) {
            tagConfigRequest.value.make();
            emits("update");
        }
    } catch (e) {

    }
}


let addTagDialog = ref<{
    visible: boolean,
    model: TagInterface;
}>();

function showAddTagDialog() {
    addTagDialog.value = {
        visible: true,
        model: {
            tag: null,
            color: null,
            cc: null
        }
    };
}

function r(tag: TagInterface) {
    return tag;
}


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_my_color_picker = _resolveComponent("my-color-picker")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      class: "right body-background full-height-body",
      title: "Управление тэгами",
      modelValue: _unref(isVisible),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      "append-to-body": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_card, null, {
          default: _withCtx(() => [
            _createVNode(ElCardSection, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: showAddTagDialog
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Добавить тэг ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(ElCardSectionWithTable, null, {
              default: _withCtx(() => [
                _createVNode(MyRemoteDataTable, {
                  request: _unref(tagConfigRequest),
                  "row-key": "cc"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, { label: "Тэг" }, {
                      default: _withCtx(({row}) => [
                        _createTextVNode(_toDisplayString(r(row).tag), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "Цвет",
                      width: "100px"
                    }, {
                      default: _withCtx(({row}) => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_my_color_picker, {
                              modelValue: r(row).color,
                              "onUpdate:modelValue": [($event: any) => ((r(row).color) = $event), ($event: any) => (_unref(toggleArrayElement)(_unref(changedList), row, true))],
                              predefine: _unref(predefineColors),
                              class: "h-auto"
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "predefine"])
                          ]),
                          _createElementVNode("div", _hoisted_2, [
                            (r(row).color)
                              ? (_openBlock(), _createBlock(MyIconButton, {
                                  key: 0,
                                  delete: "",
                                  onClick: ($event: any) => {r(row).color = null; _unref(toggleArrayElement)(_unref(changedList), row, true);}
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      align: "right",
                      width: "150px"
                    }, {
                      default: _withCtx(({row}) => [
                        _createVNode(_component_el_button_group, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              disabled: !_unref(changedList).includes(row),
                              type: _unref(changedList).includes(row) ? 'primary' : null,
                              onClick: ($event: any) => (saveTag(row))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(FontAwesomeIcon, { icon: "save" })
                              ]),
                              _: 2
                            }, 1032, ["disabled", "type", "onClick"]),
                            _createVNode(_component_el_button, {
                              onClick: ($event: any) => (deleteTag(row))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(FontAwesomeIcon, { icon: "times" })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["request"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_unref(addTagDialog) && _unref(addTagDialog).visible)
      ? (_openBlock(), _createBlock(MyDialog, {
          key: 0,
          visible: _unref(addTagDialog).visible,
          "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_unref(addTagDialog).visible) = $event)),
          form: {
                    model: _unref(addTagDialog).model,
                    labelPosition: 'left',
                    labelWidth: '150px'
                },
          title: "Добавление тэга",
          onApply: _cache[5] || (_cache[5] = ($event: any) => (saveTag(_unref(addTagDialog).model)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Тэг" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _unref(addTagDialog).model.tag,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(addTagDialog).model.tag) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Цвет" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_my_color_picker, {
                      modelValue: _unref(addTagDialog).model.color,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(addTagDialog).model.color) = $event)),
                      class: "h-auto",
                      predefine: _unref(predefineColors)
                    }, null, 8, ["modelValue", "predefine"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_unref(addTagDialog).model.color)
                      ? (_openBlock(), _createBlock(MyIconButton, {
                          key: 0,
                          delete: "",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(addTagDialog).model.color = null))
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible", "form"]))
      : _createCommentVNode("", true)
  ]))
}
}

})