import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import UslugaMapper from "~/cabinet/ts/data/usluga/Uslugi";
import DateHelper from "~/ts/library/Date";
import {DateTimeSendValueInterface} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import {dateTimeFromToday} from "~/ts/vuePlugins/DateTimePlugin";
import {__} from "~/ts/library/Translate";
import MyDateTimePicker from "~/core-ui/vue/ui/MyDateTimePicker.vue";


const __default__ = {
    name: "RegisterDateTimeSend"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<DateTimeSendValueInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => {
    let date = (new Date());
    date.setDate(date.getDate() + 1);
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    return {
        enabled: 0,
        dateTime: (new DateHelper(date)).toMysqlFormat(),
        regionalTime: 0
    };
})

let timezoneSelectable = computed(() => {
    let result = false;
    if (props.uslugaId) {
        let usluga = UslugaMapper.find(props.uslugaId) as SenderUsluga;
        if (usluga) {
            result = usluga.isRegionalTimeAvailable;
        }
    }
    return result;
});


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        modelValue: _unref(model).enabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).enabled) = $event)),
        class: "mr-2"
      }, null, 8, ["modelValue"]),
      _createTextVNode(" " + _toDisplayString(_unref(__)(_unref(model).enabled ? 'Дата начала рассылки' : 'Отправить позже')), 1)
    ]),
    (_unref(model).enabled)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          class: "mt-2",
          gutter: 8
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { md: 12 }, {
              default: _withCtx(() => [
                _createVNode(MyDateTimePicker, {
                  modelValue: _unref(model).dateTime,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).dateTime) = $event)),
                  class: "w-100",
                  "first-day-of-week": 1,
                  "disabled-date": _unref(dateTimeFromToday),
                  "default-time": new Date('2000-01-01 12:00:00'),
                  clearable: false
                }, null, 8, ["modelValue", "disabled-date", "default-time"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { md: 12 }, {
              default: _withCtx(() => [
                (_unref(timezoneSelectable))
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      modelValue: _unref(model).regionalTime,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).regionalTime) = $event)),
                      modelModifiers: { number: true },
                      class: "w-100"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          value: 0,
                          label: _unref(__)('По вашему местному времени')
                        }, null, 8, ["label"]),
                        _createVNode(_component_el_option, {
                          value: 1,
                          label: _unref(__)('По местному времени получателей')
                        }, null, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})