import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import BadgeManager from "~/cabinet/vue/interface/menu/BadgeManager";
import MenuBadgeClass from "~/cabinet/vue/interface/menu/MenuBadge";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MenuBadgeDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    menuItem: {},
    menuBadge: {},
    badgeType: {},
    tag: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

let badgeKeys = ref<string[]>([]);

let computedBadges = computed(() => {
    let result = [];
    if (props.menuItem) {
        result.push(...props.menuItem.badgesList);
    } else if (props.menuBadge) {
        result.push(props.menuBadge);
    }
    return result;
});

let hasIcon = computed(() => {
    return badge.value && badge.value.icon;
});

onMounted(() => {
    //badgeKeys.value = [];
    for (let badge of computedBadges.value) {
        badgeKeys.value.push(BadgeManager.getKey(badge));
        BadgeManager.add(badge);
    }
});

onBeforeUnmount(() => {
    for (let key of badgeKeys.value) {
        BadgeManager.removeByKey(key);
    }
});

let badge = computed(() => {
    if (props.menuItem) {
        return props.menuItem.badge;
    } else if (props.menuBadge) {
        return props.menuBadge;
    }
    return null;
});

let badgeValue = computed(() => {
    let value;
    if (props.menuItem) {
        value = props.menuItem.badgeValue;
    } else if (props.menuBadge) {
        value = props.menuBadge.value;
    }
    if (value === 0 || value === "") {
        value = null;
    }
    return value;
});

let type = computed(() => {
    return props.menuItem ? (props.menuItem.badgeType ?? 'danger') : (props.menuBadge && props.menuBadge.type ? props.menuBadge.type : props.badgeType)
});

let isBadgeHidden = computed(() => {
    return props.menuItem?.isBadgeHidden === true || !badgeValue.value;
})

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_badge = _resolveComponent("el-badge")!

  return (_unref(badgeValue) || !_unref(hasIcon))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.tag)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!_unref(isBadgeHidden))
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    type: _unref(type),
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(badgeValue)), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_el_badge, {
              key: 1,
              value: _unref(badgeValue),
              hidden: _unref(isBadgeHidden),
              type: _unref(type)
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["value", "hidden", "type"]))
      ], 64))
    : (_unref(hasIcon))
      ? (_openBlock(), _createBlock(FontAwesomeIcon, {
          key: 1,
          class: "float-right ml-2 icon",
          icon: _unref(badge).icon,
          style: _normalizeStyle({color: _unref(badge).iconColor})
        }, null, 8, ["icon", "style"]))
      : _createCommentVNode("", true)
}
}

})