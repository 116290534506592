import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, mergeProps as _mergeProps, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { key: 0 }

import {FIELD_TYPE_SELECT, IField, IFieldWithOptions} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {
    FORM_ELEMENT_TYPE_DICTIONARY,
    IFormElementType
} from "~/cabinet/vue/interface/form/elements/FormElementCollection";
import MyIconSelector from "~/cabinet/vue/interface/form/MyIconSelector.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import IMyIconSelectorModel from "~/cabinet/vue/interface/form/IMyIconSelectorModel";
import {ICON_LIGHT} from "~/chat/ts/data/ISiteParams";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, inject, ref} from "vue";
import Random from "~/ts/library/Random";
import {MY_FORM_CONSTRUCTOR_ALL_ELEMENTS_INJECT} from "~/cabinet/vue/interface/form/elements/constructor/Interface";
import HelpBlock from "~/cabinet/vue/interface/HelpBlock.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import HtmlInputWithVariables from "~/cabinet/vue/interface/tinymce/HtmlInputWithVariables.vue";
import {requiredRule} from "~/core-ui/ts/CoreUiHelpersMixin";


const __default__ = {
    name: "MyFormElementConstructor"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    elementTypes: {},
    showDisplayConditionConfig: { type: Boolean },
    elementNameSameAsDescr: { type: Boolean },
    elementNameRandomize: { type: Boolean },
    duplicateNameError: { type: Boolean },
    nameDisabled: { type: Boolean },
    canBeRanamed: { type: Boolean },
    card: { type: Boolean, default: true },
    form: { type: Boolean, default: true }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;


let _modelValue = _useModel<IField>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    icon: null,
    iconStyle: ICON_LIGHT,
    name: null,
    htmlBeforeInput: null,
    htmlUnderInput: null,
    helpUnderInput: null,
    defaultValue: null,
    displayCondition: null,
    required: false,
    descr: null,
    type: null,
    optional: null,
    isHtmlBeforeInputEnabled: false
}));

let renameAllowed = ref(false);

let displayConditionEnabled = ref(!!model.value?.displayCondition);

function onDisplayConditionEnabledChange(value: boolean) {
    model.value.displayCondition = value ? {
        fieldName: null,
        values: []
    } : null;
}

function onIconSelect(value: IMyIconSelectorModel) {
    model.value.icon = value ? value.iconName : null;
    model.value.iconStyle = value ? value.style : null;
}

function onDescrInput(descr: string) {
    if (props.elementNameSameAsDescr) {
        model.value.name = descr;
    }
}

if (!model.value.name && props.elementNameRandomize) {
    model.value.name = Random.uid();
}

let type = computed(() => {
    let result = FORM_ELEMENT_TYPE_DICTIONARY[model.value.type];
    if (result && props.elementTypes.find(item => result.id == item.id)) {
        return result;
    }
    return null;
});

let allFormElements = inject(MY_FORM_CONSTRUCTOR_ALL_ELEMENTS_INJECT, null);

let availableDisplayConditionElements = computed<IFieldWithOptions[]>(() => {
    if (props.showDisplayConditionConfig) {
        return allFormElements?.value
            .filter(field => field.type == FIELD_TYPE_SELECT)
            .filter(field => field.name && field.name != model.value?.name)
            .filter(field => (field as IFieldWithOptions).options.length) as any ?? [];
    } else {
        return [];
    }
});

let displayConditionField = computed(() => availableDisplayConditionElements.value?.find(item => item.name == model.value.displayCondition?.fieldName));

async function allowRename() {
    try {
        await ElConfirm("Если вы переименуете поле, то потеряется связка этого поля с уже сохраненными данными ваших клиентов, настройками ботов, фильтров, сообщений и прочим. Переименование настоятельно не рекомендуется. Вы точно хотите переименовать его?");
        renameAllowed.value = true;
        model.value.previousName = model.value.name;
    } catch (e) {

    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.card ? 'el-card' : 'div'), { class: "mb-4" }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.form ? 'el-form' : 'div'), {
        "label-width": "220px",
        "label-position": "left",
        model: _unref(model)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "Тип поля",
            prop: "type",
            rules: _unref(requiredRule)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _unref(model).type,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).type) = $event)),
                class: "w-100",
                placeholder: "Выберите тип поля"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementTypes, (type) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: type.id,
                      label: type.descr,
                      value: type.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["rules"]),
          (_unref(model).type)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_form_item, {
                  label: "Заголовок",
                  rules: _unref(requiredRule),
                  prop: "descr",
                  error: _ctx.elementNameSameAsDescr && _ctx.duplicateNameError ? 'Это имя используется дважды' : null
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(model).descr,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).descr) = $event)),
                        onDescrInput
                      ],
                      placeholder: "Будет виден пользователю"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["rules", "error"]),
                _createVNode(_component_el_form_item, { label: "Иконка" }, {
                  default: _withCtx(() => [
                    (_unref(model).icon)
                      ? (_openBlock(), _createBlock(FontAwesomeIcon, {
                          key: 0,
                          icon: _unref(model).icon,
                          "icon-style": _unref(model).iconStyle,
                          class: "mr-2"
                        }, null, 8, ["icon", "icon-style"]))
                      : _createCommentVNode("", true),
                    _createVNode(MyIconSelector, {
                      onInput: onIconSelect,
                      clearable: ""
                    })
                  ]),
                  _: 1
                }),
                (!_ctx.elementNameSameAsDescr && !_ctx.elementNameRandomize)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      label: "Системное имя поля (для API)",
                      error: _ctx.duplicateNameError ? 'Это имя используется дважды' : null
                    }, {
                      label: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-fill" }, " Системное имя поля (для API) ", -1)),
                          (_ctx.nameDisabled && !_unref(renameAllowed) && _ctx.canBeRanamed)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createElementVNode("a", {
                                  class: "link-dashed",
                                  tabindex: "",
                                  onClick: allowRename,
                                  textContent: 'Переименовать'
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _unref(model).name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(model).name) = $event)),
                          disabled: _ctx.nameDisabled && !_unref(renameAllowed)
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["error"]))
                  : _createCommentVNode("", true),
                (_unref(type) && _unref(type).constructorComponent)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(type).constructorComponent), _mergeProps({ key: 1 }, _unref(type).constructorComponentProps, {
                      "model-value": _unref(model),
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(model) //@ts-ignore
 ? model.value = $event : model = $event))
                    }), null, 16, ["model-value"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_form_item, { prop: "htmlBeforeInput" }, {
                  label: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: _unref(model).isHtmlBeforeInputEnabled,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(model).isHtmlBeforeInputEnabled) = $event)),
                      class: "mr-2"
                    }, null, 8, ["modelValue"]),
                    _cache[10] || (_cache[10] = _createTextVNode(" HTML над полем "))
                  ]),
                  default: _withCtx(() => [
                    (_unref(model).isHtmlBeforeInputEnabled)
                      ? (_openBlock(), _createBlock(HtmlInputWithVariables, {
                          key: 0,
                          modelValue: _unref(model).htmlBeforeInput,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(model).htmlBeforeInput) = $event)),
                          inline: false,
                          height: 400,
                          placeholder: "Введите текст"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_unref(availableDisplayConditionElements).length)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 2,
                      class: "mt-4"
                    }, {
                      label: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: _unref(displayConditionEnabled),
                          "onUpdate:modelValue": [
                            _cache[6] || (_cache[6] = ($event: any) => (_isRef(displayConditionEnabled) ? (displayConditionEnabled).value = $event : displayConditionEnabled = $event)),
                            onDisplayConditionEnabledChange
                          ],
                          class: "mr-2"
                        }, null, 8, ["modelValue"]),
                        _cache[11] || (_cache[11] = _createTextVNode(" Условие отображения "))
                      ]),
                      default: _withCtx(() => [
                        (_unref(displayConditionEnabled) && _unref(model).displayCondition)
                          ? (_openBlock(), _createBlock(_component_el_form, {
                              key: 0,
                              "label-position": "top"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, { label: "Зависит от поля:" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_select, {
                                      modelValue: _unref(model).displayCondition.fieldName,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(model).displayCondition.fieldName) = $event)),
                                      class: "w-100",
                                      placeholder: "Выберите поле"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableDisplayConditionElements), (item) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: item.name,
                                            label: item.descr,
                                            value: item.name
                                          }, null, 8, ["label", "value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                (_unref(displayConditionField))
                                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                                      key: 0,
                                      label: "Укажите необходимые значения:"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_select, {
                                          modelValue: _unref(model).displayCondition.values,
                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(model).displayCondition.values) = $event)),
                                          class: "w-100",
                                          multiple: ""
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(displayConditionField).options, (item) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: item.value,
                                                label: item.descr ? item.descr : item.value,
                                                value: item.value
                                              }, null, 8, ["label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"]),
                                        _createVNode(HelpBlock, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Это поле отобразится только тогда, когда пользователь выберет в поле " + _toDisplayString(_unref(displayConditionField).descr) + " одно из заданных вами значений. ", 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "form-end")
        ]),
        _: 3
      }, 8, ["model"]))
    ]),
    _: 3
  }))
}
}

})