import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }

import {
    getActionDescriptionModelType,
    useActionBlockFormProps
} from "~/cabinet/vue/client/events/action/types/useActionBlockForm";
import {computed, nextTick, ref} from "vue";
import {
    DataProviderEntitySaveActionDescription,
    DataProviderLinkEntityTypeInterface
} from "~/cabinet/ts/data/events/ActionDescription";
import {
    DataProviderEntitySaveActionLinkInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionParamInterface";
import DataProviderEntitySaveActionTabPane
    from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionTabPane.vue";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import MyForm from "~/cabinet/vue/interface/form/elements/MyForm.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import Random from "~/ts/library/Random";


import {defineComponent} from 'vue';

const __default__ = defineComponent({
    name: "DataProviderEntitySaveActionBlockForm"
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useActionBlockFormProps<DataProviderEntitySaveActionDescription>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let model = _useModel<getActionDescriptionModelType<DataProviderEntitySaveActionDescription>>(__props, "modelValue");

const mainTabName = "main";
const addTabName = "add";

let tabId = ref<string>(mainTabName);

let frontendParams = props.actionDescription.frontendParams;

function getTabDescrByLink(link: DataProviderEntitySaveActionLinkInterface): string {
    let result = getLinkTypeById(link.entityTypeId);
    return result ? result.descr : `Неизвестный тип (${link.entityTypeId})`;
}

function getLinkTypeById(id: string) {
    return frontendParams.linkEntityTypes.find(item => item.id == id);
}

async function onTabRemove(tab: string) {
    try {
        await ElConfirm("Вы уверены, что хотите удалить эту связанную сущность?");
        model.value.links.splice(parseInt(tab), 1);
        tabId.value = mainTabName;
    } catch (e) {

    }
}

function prepareLinkValue(link: DataProviderEntitySaveActionLinkInterface) {
    let linkType = frontendParams.linkEntityTypes.find(item => item.id == link.entityTypeId);
    link.value = {
        ...getActionDescriptionByLink(link).getDefaultModelValues(linkType),
        ...link.value
    }
}

for (let link of model.value.links) {
    prepareLinkValue(link);
}

function getActionDescriptionByLink(link: DataProviderEntitySaveActionLinkInterface) {
    let linkType = getLinkTypeById(link.entityTypeId);
    return props.eventParamsByAuth.actions.find(action => action.id == linkType.actionDescriptionId) as DataProviderEntitySaveActionDescription;
}

function getLinksByLinkType(linkType: DataProviderLinkEntityTypeInterface): DataProviderEntitySaveActionLinkInterface[] {
    return model.value.links.filter(link => link.entityTypeId == linkType.id);
}

let linkTypesAvailableToAdd = computed(() => {
    return frontendParams.linkEntityTypes
        .filter(link => {
            return !link.maximumLinksCount || getLinksByLinkType(link).length < link.maximumLinksCount;
        });
});

function isLinkRemovable(link: DataProviderEntitySaveActionLinkInterface) {
    let result = true;
    let linkType = getLinkTypeById(link.entityTypeId);
    let minimumCount = linkType?.minimumLinksCount;
    if (minimumCount > 0) {
        result = model.value.links
            .filter(item => item.entityTypeId == link.entityTypeId)
            .length > minimumCount;
    }
    return result;
}

function addLink(linkType: DataProviderLinkEntityTypeInterface) {
    let newLink: DataProviderEntitySaveActionLinkInterface = {
        id: Random.uid(),
        entityTypeId: linkType.id,
        value: null,
        additionalConfigFormValues: {}
    };
    prepareLinkValue(newLink);
    model.value.links.push(newLink);
    nextTick(() => {
        tabId.value = (model.value.links.length - 1).toString();
    });
}


return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_unref(frontendParams).linkEntityTypes.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_tabs, {
          modelValue: _unref(tabId),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(tabId) ? (tabId).value = $event : tabId = $event)),
          class: "without-overflow",
          onTabRemove: onTabRemove
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: _unref(frontendParams).entityTypeDescr,
              name: mainTabName
            }, {
              default: _withCtx(() => [
                _createVNode(DataProviderEntitySaveActionTabPane, _normalizeProps(_guardReactiveProps(_unref(props))), null, 16)
              ]),
              _: 1
            }, 8, ["label"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(model).links, (link, key) => {
              return (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: key,
                name: key.toString(),
                closable: isLinkRemovable(link),
                label: getTabDescrByLink(link),
                lazy: ""
              }, {
                default: _withCtx(() => [
                  (getLinkTypeById(link.entityTypeId) && getLinkTypeById(link.entityTypeId).additionalConfigForm)
                    ? (_openBlock(), _createBlock(_component_el_card, {
                        key: 0,
                        class: "mb-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(ElCardHeader, { title: "Дополнительные настройки связи" }),
                          _createVNode(ElCardSection, null, {
                            default: _withCtx(() => [
                              _createVNode(MyForm, {
                                form: getLinkTypeById(link.entityTypeId).additionalConfigForm,
                                modelValue: link.additionalConfigFormValues,
                                "onUpdate:modelValue": ($event: any) => ((link.additionalConfigFormValues) = $event)
                              }, null, 8, ["form", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(DataProviderEntitySaveActionTabPane, _mergeProps({ ref_for: true }, _unref(props), {
                    modelValue: link.value,
                    "onUpdate:modelValue": ($event: any) => ((link.value) = $event),
                    "action-description": getActionDescriptionByLink(link),
                    "link-type": getLinkTypeById(link.entityTypeId)
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "action-description", "link-type"])
                ]),
                _: 2
              }, 1032, ["name", "closable", "label"]))
            }), 128)),
            (_unref(linkTypesAvailableToAdd).length)
              ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: 0,
                  label: "Добавить связанную сущность",
                  name: addTabName
                }, {
                  label: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, {
                      icon: "plus",
                      class: "mr-1"
                    }),
                    _cache[1] || (_cache[1] = _createTextVNode(" Добавить связанную сущность "))
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_card, { class: "transparent-dashed-card" }, {
                      default: _withCtx(() => [
                        _cache[2] || (_cache[2] = _createElementVNode("p", null, "Вы можете одновременно сохранить ещё одну сущность и связать ее с первой сохраняемой сущностью.", -1)),
                        _cache[3] || (_cache[3] = _createElementVNode("p", null, "Выберите тип добавляемой сущности:", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(linkTypesAvailableToAdd), (linkType) => {
                          return (_openBlock(), _createBlock(_component_el_button, {
                            key: linkType.id,
                            class: "d-block w-100 mb-3 ml-0 mr-0",
                            round: "",
                            plain: "",
                            type: "primary",
                            onClick: ($event: any) => (addLink(linkType))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(linkType.descr), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : (_openBlock(), _createBlock(DataProviderEntitySaveActionTabPane, _normalizeProps(_mergeProps({ key: 1 }, _unref(props))), null, 16))
}
}

})