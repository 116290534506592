import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  class: "group-title"
}
const _hoisted_3 = ["onClick"]

import {computed, nextTick, ref} from 'vue';
import {IFilter, IFilterGroup, IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import {__} from "~/ts/library/Translate";
import ObjectHelper from "~/ts/library/ObjectHelper";
import CascaderHelper, {CascadeOptionWithDescrArray, CascaderOptionInterface} from "~/core-ui/ts/CascaderHelper";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import Dictionary from "~/ts/library/Dictionary";
import {mobileFullWidthButtonClass} from "~/core-ui/ts/CoreUiHelpersMixin";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyFilterAdd"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    filtersData: {},
    availableFilters: {},
    buttonText: { default: __("Добавить фильтр") }
  },
  emits: ["add-filter"],
  setup(__props: any, { emit: __emit }) {

let props = __props;


let emits = __emit;

let tree = ref<{
    setCurrentKey: (key?: string) => void
}>();

let searchInput = ref<HTMLTextAreaElement>();
let searchQuery = ref<string>();

function filtersOfGroup(groupId?: string): IFilter[] {
    let result = [];
    let filters = props.availableFilters;
    for (let i = 0; i < filters.length; i++) {
        let filter: IFilter = filters[i];
        if (!groupId || filter.group == groupId) {
            result.push(filter);
        }
    }
    return result;
}

let needDisplayGroup = computed(() => {
    return ObjectHelper.arrayFromDictionary(props.filtersData.groups).length > 1;
});

let groups = computed(() => {
    let result = [];
    let groups = props.filtersData.groups;
    for (let i in props.filtersData.groups) {
        if (props.filtersData.groups.hasOwnProperty(i)) {
            let group = groups[i];
            let filters = filtersOfGroup(group.id);
            if (filters.length) {
                result.push({
                    group: group,
                    filters: filters
                });
            }
        }
    }
    return result;
});

let dialogVisible = ref(false);

function showDialog() {
    dialogVisible.value = true;
    nextTick(() => searchInput.value?.focus());
}

function findChildGroup(group: IFilterGroup): IFilterGroup {
    for (let item of groups.value) {
        if (item.group.descrArray.length > group.descrArray.length) {
            let good = true;
            for (let i = 0; i < group.descrArray.length; i++) {
                if (item.group.descrArray[i] !== group.descrArray[i]) {
                    good = false;
                    break;
                }
            }
            if (good) {
                return item.group;
            }
        }
    }
    return null;
}

let currentTreeNode = ref<CascadeOptionWithDescrArray>();

let cascaderOptions = computed(() => {
    let cascaderItems: CascaderOptionInterface[] = [];
    for (let group of groups.value) {
        if (!findChildGroup(group.group)) {
            cascaderItems.push({
                id: group.group.id,
                descr: group.group.descrArray
            });
        }
        /*
        for (let filter of group.filters) {
            cascaderItems.push({
                id: filter.id,
                descr: [...group.group.descrArray, filter.descr]
            });
        }*/
    }
    return CascaderHelper.createOptions(cascaderItems);
});

let groupIndexList = computed(() => {
    let groupDescrList: string[] = [];
    fillGroupIndexList(groupDescrList, cascaderOptions.value);
    let result: Dictionary<number> = {};
    for (let group of ObjectHelper.arrayFromDictionary(props.filtersData.groups)) {
        result[group.id] = groupDescrList.indexOf(group.descrArray.join("/"));
    }
    return result;
});

function fillGroupIndexList(result: string[], groups: CascadeOptionWithDescrArray[]) {
    for (let group of groups) {
        result.push(group.descrArray.join("/"));
        if (group.children) {
            fillGroupIndexList(result, group.children);
        }
    }
}


let filtersToDisplay = computed(() => {
    let filters = props.availableFilters;
    let level = 0;
    if (searchQuery.value?.trim().length > 1) {
        let searchQueryLowerCase = searchQuery.value.trim().toLocaleLowerCase();
        filters = filters.filter(filter => {
            let groupDescr = props.filtersData.groups[filter.group]?.descrArray ?? [];
            return (filter.descr + " " + groupDescr.join(" ")).toLocaleLowerCase().includes(searchQueryLowerCase);
        })
    } else if (currentTreeNode.value) {
        level = currentTreeNode.value.descrArray.length;
        let descrArray = currentTreeNode.value.descrArray;
        filters = filters.filter(filter => {
            let groupId = filter.group;
            if (groupId) {
                let group = props.filtersData.groups[groupId];
                if (group) {
                    for (let i = 0; i < descrArray.length; i++) {
                        if (group.descrArray[i] !== descrArray[i]) {
                            return false;
                        }
                    }
                    return true;
                }
            }
            return false;
        });
    }

    if (filters.length == 0 && currentTreeNode.value) {
        setTimeout(() => {
            resetCurrentTreeNode();
        }, 50);
    }

    let result: Dictionary<{ groupId: string, groupDescr: string, filters: IFilter[] }> = {};
    for (let filter of filters) {
        if (!result[filter.group]) {
            let groupDescr = props.filtersData.groups[filter.group]?.descrArray.slice(level) ?? [];
            if (!groupDescr.length && currentTreeNode) {
                groupDescr = currentTreeNode.value.descrArray.slice(level - 1);
            }
            result[filter.group] = {
                groupId: filter.group,
                filters: [],
                groupDescr: groupDescr.join(" / ")
            };
        }
        result[filter.group].filters.push(filter);
    }
    return ObjectHelper.arrayFromDictionary(result).sort((a, b) => groupIndexList.value[a.groupId] < groupIndexList.value[b.groupId] ? -1 : 1);
});

function resetCurrentTreeNode() {
    currentTreeNode.value = null;
    nextTick(() => {
        tree.value?.setCurrentKey(null);
    });
}

function onCurrentTreeNodeChange(value: any) {
    if (currentTreeNode.value === value) {
        resetCurrentTreeNode();
        value = null;
    }
    if (value) {
        searchQuery.value = null;
    }
    currentTreeNode.value = value;
}

function addFilter(filter: IFilter) {
    dialogVisible.value = false;
    emits('add-filter', filter);
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_unref(groups).length < 0)
    ? (_openBlock(), _createBlock(_component_el_dropdown, {
        key: 0,
        class: "w-100 w-md-auto",
        trigger: "click",
        placement: "bottom-start",
        onCommand: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('add-filter', $event)))
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groups), (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_unref(needDisplayGroup))
                    ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                        key: 0,
                        disabled: "",
                        divided: index > 0 ? true : null
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.group.descr), 1)
                        ]),
                        _: 2
                      }, 1032, ["divided"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.filters, (filter) => {
                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: filter.id,
                      command: filter
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(filter.descr), 1)
                      ]),
                      _: 2
                    }, 1032, ["command"]))
                  }), 128))
                ], 64))
              }), 256))
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
            type: "primary",
            plain: "",
            round: ""
          }, {
            icon: _withCtx(() => [
              _createVNode(FontAwesomeIcon, { icon: "plus" })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          class: _normalizeClass(_unref(mobileFullWidthButtonClass)),
          type: "primary",
          plain: "",
          round: "",
          onClick: showDialog
        }, {
          icon: _withCtx(() => [
            _createVNode(FontAwesomeIcon, { icon: "plus" })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        (_unref(dialogVisible))
          ? (_openBlock(), _createBlock(_component_el_dialog, {
              key: 0,
              modelValue: _unref(dialogVisible),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(dialogVisible) ? (dialogVisible).value = $event : dialogVisible = $event)),
              title: "Выберите фильтр",
              width: _unref(groups).length > 1 ? '900px' : '650px',
              "close-on-click-modal": "",
              "close-on-press-escape": "",
              class: "transparent"
            }, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
                  default: _withCtx(() => [
                    (_unref(groups).length > 1)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 0,
                          xs: 0,
                          span: 8
                        }, {
                          default: _withCtx(() => [
                            _withDirectives((_openBlock(), _createBlock(_component_el_card, {
                              class: "overflow-hidden",
                              shadow: "never"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(ElCardHeader, { title: "Категории фильтров" }),
                                _createVNode(ElCardSection, { padding: false }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_tree, {
                                      ref_key: "tree",
                                      ref: tree,
                                      class: "group-tree",
                                      accordion: "",
                                      "check-on-click-node": "",
                                      "node-key": "descrArrayMd5",
                                      "current-node-key": _unref(currentTreeNode) ? _unref(currentTreeNode).descrArrayMd5 : undefined,
                                      "auto-expand-parent": "",
                                      "default-expanded-keys": _unref(currentTreeNode) ? [_unref(currentTreeNode).descrArrayMd5] : [],
                                      "highlight-current": "",
                                      data: _unref(cascaderOptions),
                                      onCurrentChange: onCurrentTreeNodeChange
                                    }, null, 8, ["current-node-key", "default-expanded-keys", "data"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })), [
                              [
                                _directive_on_native,
                                void 0,
                                "click",
                                { stop: true }
                              ]
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_col, {
                      span: 24,
                      md: _unref(groups).length > 1 ? 16 : 24
                    }, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(_component_el_card, {
                          class: "overflow-hidden",
                          shadow: "never"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(ElCardSection, { padding: false }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: _unref(searchQuery),
                                  "onUpdate:modelValue": [
                                    _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchQuery) ? (searchQuery).value = $event : searchQuery = $event)),
                                    _cache[2] || (_cache[2] = ($event: any) => (resetCurrentTreeNode()))
                                  ],
                                  ref_key: "searchInput",
                                  ref: searchInput,
                                  class: "search-input",
                                  placeholder: "Поиск..."
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filtersToDisplay), (group) => {
                              return (_openBlock(), _createBlock(ElCardSection, {
                                key: group.groupId,
                                padding: false
                              }, {
                                default: _withCtx(() => [
                                  (group.groupDescr.length)
                                    ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(group.groupDescr), 1))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.filters, (filter) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: filter.id,
                                        class: "filter-select",
                                        onClick: ($event: any) => (addFilter(filter))
                                      }, _toDisplayString(filter.descr), 9, _hoisted_3))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            (!_unref(filtersToDisplay).length)
                              ? (_openBlock(), _createBlock(ElCardSection, { key: 0 }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createElementVNode("div", { class: "text-muted" }, "Фильтры не найдены", -1)
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })), [
                          [
                            _directive_on_native,
                            void 0,
                            "click",
                            { stop: true }
                          ]
                        ])
                      ]),
                      _: 1
                    }, 8, ["md"])
                  ]),
                  _: 1
                })), [
                  [_directive_on_native, () => {_isRef(dialogVisible) //@ts-ignore
 ? dialogVisible.value = false : dialogVisible = false;}, "click"]
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "width"]))
          : _createCommentVNode("", true)
      ]))
}
}

})