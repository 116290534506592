import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]

import {computed, watch} from 'vue';
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import CabinetUploadHelper from "~/cabinet/ts/service/CabinetUploadHelper";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";


const __default__ = {
    name: "MyUploadFileList"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    action: {},
    readonly: { type: Boolean },
    inputIsListening: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<Dictionary<string> | string[]>(__props, "modelValue")


function handleUploadSuccess(response: any, file: any/*, fileList: any*/) {
    let result = CabinetUploadHelper.getIdWithNameFromFileList([file]);
    let value = filesDictionary.value ? {...filesDictionary.value} : {};
    model.value = {...value, ...result};
}

function onDelete(fileId: string) {
    let copy = ObjectHelper.jsonClone(model.value) as any;
    delete copy[fileId];
    model.value = copy;
}

function getUrl(fileId: string) {
    return CabinetUploadHelper.getUrlById(fileId);
}

let isArray = computed(() => {
    return Array.isArray(model.value);
});

let filesDictionary = computed(() => {
    let result: Dictionary<string>;
    if (isArray.value) {
        result = toDictionary(model.value as string[]);
    } else {
        result = model.value as Dictionary<string>;
    }
    return result;
});

function toDictionary(value: string[]) {
    let result: Dictionary<string> = {};
    for (let i = 0; i < value.length; i++) {
        result[value[i]] = "Файл #" + (i + 1);
    }
    return result;
}

watch(filesDictionary, () => {
    if (model.value != filesDictionary.value) {
        model.value = filesDictionary.value;
    }
}, {deep: true});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.action && !_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_el_upload, {
          key: 0,
          action: _ctx.action,
          multiple: "",
          class: "d-inline-block",
          "on-success": handleUploadSuccess,
          "show-file-list": false
        }, {
          default: _withCtx(() => [
            (!_ctx.$slots.default)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "primary"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Загрузить файлы ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["action"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filesDictionary), (name, fileId) => {
      return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.inputIsListening && !_ctx.readonly)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              link: "",
              onClick: ($event: any) => (onDelete(fileId))
            }, {
              default: _withCtx(() => [
                _createVNode(FontAwesomeIcon, {
                  icon: "times",
                  light: ""
                })
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          href: getUrl(fileId),
          target: "_blank"
        }, _toDisplayString(name), 9, _hoisted_1)
      ]))
    }), 256))
  ]))
}
}

})