import {App, Component, createApp} from "vue";
import {Router} from "vue-router";
import "~/ts/library/Date";
import * as moment from "~/node_modules/moment";
import InitElemeUI from "./InitElemeUI";
import InitMyComponents from "./InitMyComponents";
import CoreUIAppStorage from "~/core-ui/ts/CoreUIAppStorage";
import {vOnNative} from "~/ts/vuePlugins/vOnNative";


require('moment/locale/ru');


moment.locale("ru");


export default class CoreUI {
    private component: Component;
    private router: Router;

    constructor(component: Component, router: Router) {
        this.component = component;
        this.router = router;
    }

    createApp(beforeHook?: () => void, beforeMount?: (app: App) => void): App {
        if (beforeHook) {
            beforeHook();
        }

        let app = createApp({
            components: {
                App: this.component
            },
            router: this.router
        });
        CoreUIAppStorage.setApp(app, this.router);
        app
            .use(this.router)
            .directive('on-native', vOnNative);
        if (beforeMount) {
            beforeMount(app);
        }

        InitElemeUI.make(app);
        InitMyComponents.make(app);

        app.mount('#app');
        return app;
    }

}