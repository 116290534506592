import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]

import {IFileManagerField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import FileManager from "~/cabinet/vue/interface/form/FileManager.vue";
import {
    FormElementExposeInterface,
    useFormElement,
    useFormElementProps
} from "~/cabinet/vue/interface/form/elements/useFormElement";


const __default__ = {
    name: "FilemanagerElement"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useFormElementProps<IFileManagerField>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {

let props = __props;

let model = _useModel<any>(__props, "modelValue");

let formElement = useFormElement(model, props);

__expose<FormElementExposeInterface>(formElement.expose);

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(model))
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          ref: "img",
          src: _unref(model),
          class: "img"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.readonly)
      ? (_openBlock(), _createBlock(FileManager, {
          key: 1,
          modelValue: _unref(model),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
          class: "mt-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.field.buttonText), 1)
              ]),
              _: 1
            }),
            (_unref(model) && _ctx.field.clearable)
              ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  link: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(FontAwesomeIcon, { icon: "times" })
                  ]),
                  _: 1
                })), [
                  [
                    _directive_on_native,
                    () => {_isRef(model) //@ts-ignore
 ? model.value = null : model = null;},
                    "click",
                    { stop: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})