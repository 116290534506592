import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "root" }

import ITextFloatImageBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/ITextFloatImageBlockValues";
import TextDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/text/TextDisplay.vue";
import ImageDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageDisplay.vue";
import {HTML_BLOCK_ALIGN_RIGHT} from "~/cabinet/vue/interface/htmlConstructor/Constants";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "TextFloatImageDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["click"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let {parentStructureStyles} = useBlockDisplay(props);

let _modelValue = _useModel<ITextFloatImageBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let isRight = computed(() => {
    return model.value.align == HTML_BLOCK_ALIGN_RIGHT;
});

let floatClass = computed(() => {
    return isRight.value ? "float-right" : "float-left";
});

let margin = computed(() => {
    let structureStyle = parentStructureStyles.value;
    if (structureStyle) {
        let padding = `${structureStyle.bodyTextSize}px`;
        return isRight.value ? `0 0 ${padding} ${padding}` : `0 ${padding} ${padding} 0`;
    }
    return null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ImageDisplay, {
      class: _normalizeClass(['div', 'float', _unref(floatClass)]),
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(emits)('click'))),
      styles: _ctx.styles,
      style: _normalizeStyle({
                margin: _unref(margin)
            })
    }, null, 8, ["class", "modelValue", "styles", "style"]),
    _createVNode(TextDisplay, {
      modelValue: _unref(model),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
      class: "div block-sibling",
      styles: _ctx.styles,
      style: _normalizeStyle({
                minHeight: `${_unref(model).height}px`
            }),
      "input-style": {
                minHeight: `${_unref(model).height}px`,
                overflow: 'visible',
                paddingTop: `0 !important`,
                paddingBottom: `0 !important`
            }
    }, null, 8, ["modelValue", "styles", "style", "input-style"])
  ]))
}
}

})