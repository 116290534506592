import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import {computed} from 'vue';
import ElCardSectionWithIcon from "~/cabinet/vue/interface/card/ElCardSectionWithIcon.vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import AbstractEntity from "~/ts/library/AbstractEntity";
import {DOC_DETAILS_ROUTE, DOC_ROUTE} from "~/cabinet/ts/routes/ClientRoutesBuilder";
import {RouteLocationNamedRaw, useRoute} from "vue-router";


const __default__ = {
    name: "RejectedDetailsAlertCard"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

let request = computed(() => {
    return new ReactiveRequest(new LcabApiRequest({
        url: `/api/documents/details/getRejectedDetails`
    }, GetRejectedDetailsResponse))
});

let detailsLocation = computed<RouteLocationNamedRaw>(() => {
    return {
        name: DOC_DETAILS_ROUTE
    };
});

let route = useRoute();

let isShow = computed(() => {
    return !route.matched.find(item => item.name == DOC_ROUTE);
});


class GetRejectedDetailsResponse extends AbstractEntity {
    details: {
        id: string;
        descr: string;
        errorDescr: string;
    }[]
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_unref(isShow) && _unref(request).data && _unref(request).data.details)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(request).data.details, (item) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            key: item.id
          }, {
            default: _withCtx(() => [
              _createVNode(ElCardSectionWithIcon, {
                icon: "file-alt",
                "icon-color": "red",
                value: `Реквизиты ${item.descr} не прошли проверку`,
                "gray-label": false
              }, {
                label: _withCtx(() => [
                  _createElementVNode("p", null, "Комментарий менеджера: " + _toDisplayString(item.errorDescr), 1),
                  _createElementVNode("p", null, [
                    _cache[0] || (_cache[0] = _createTextVNode(" Исправьте ошибку в реквизитах или удалите их в разделе ")),
                    _createVNode(_component_router_link, {
                      to: _unref(detailsLocation),
                      textContent: 'Документы'
                    }, null, 8, ["to"]),
                    _cache[1] || (_cache[1] = _createTextVNode(", чтобы эта ошибка исчезла. "))
                  ])
                ]),
                _: 2
              }, 1032, ["value"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})