import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IH2BlockValues, {IH2Style} from "~/cabinet/vue/interface/htmlConstructor/blocks/h2/IH2BlockValues";
import TextInputWithVariables from "~/cabinet/vue/interface/tinymce/TextInputWithVariables.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";


const __default__ = {
    name: "H2Display"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps<IH2Style>()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {htmlConstructorEditor} = useBlockDisplay(props);

let _modelValue = _useModel<IH2BlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TextInputWithVariables, {
    modelValue: _unref(model).title,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).title) = $event)),
    placeholder: "Подзаголовок",
    style: _normalizeStyle({
            textAlign: _unref(model).align,
            fontSize: `${_ctx.styles.bodyH2Size}px`,
            fontFamily: _ctx.styles.bodyH2Font,
            color: _ctx.styles.bodyH2Color,
            fontWeight: _ctx.styles.bodyH2Weight
        }),
    inline: "",
    "editor-class-list": ['pt-1', 'pb-1'],
    "content-variables": _unref(htmlConstructorEditor).params.contentVariables
  }, null, 8, ["modelValue", "style", "content-variables"]))
}
}

})