import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}

import ISavedBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/ISavedBlockValues";
import ISavedBlock from "~/cabinet/vue/interface/htmlConstructor/ISavedBlock";
import MyLoader from "~/cabinet/vue/interface/data/MyLoader.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import Delay from "~/ts/library/Delay";
import HtmlConstructorEditor from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorEditor.vue";
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import HtmlConstructorHelper from "~/cabinet/vue/interface/htmlConstructor/HtmlConstructorHelper";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";
import {useBlockForm, useBlockFormProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, ref} from "vue";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";

interface IRow {
    id: string,
    savedBlock: ISavedBlock,
    blockType: AbstractBlockType<any, any>
}

const __default__ = {
    name: "SavedBlockForm"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockFormProps()
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["select"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

let props = __props;

let emits = __emit;

let {htmlConstructorEditor} = useBlockForm(props);

let _modelValue = _useModel<ISavedBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);


let searchModel = ref<string>();
let search = ref<string>();

let editSavedBlockDialog = ref<{
    visible: boolean,
    savedBlock: ISavedBlock,
    model: IHtmlConstructorEditorModel<any>
}>();


async function onSearchModelInput() {
    try {
        await Delay.make(100, "searchSavedBlock", true);
        search.value = searchModel.value ? searchModel.value.toLowerCase() : null;
    } catch (e) {

    }
}

function onRowClick(row: IRow) {
    model.value.blockId = row.savedBlock.id;
    emits("select");
}

function selectAnother() {
    model.value.blockId = null;
}

let blocksFiltered = computed(() => {
    if (blocks.value) {
        let blockTypes = htmlConstructorEditor.params.blockTypesDictionary;
        return blocks.value
            .map(item => ({
                id: item.id,
                savedBlock: item,
                blockType: blockTypes[item.block.type]
            }))
            .filter(item => item.blockType);
    } else {
        return [];
    }
});

let blocksToDisplay = computed(() => {
    let result = blocksFiltered.value;
    if (search.value) {
        result = result.filter(item => {
            if (!item.savedBlock.descrLowerCase) {
                item.savedBlock.descrLowerCase = item.savedBlock.descr.toLowerCase();
            }
            return item.savedBlock.descrLowerCase.indexOf(search.value) > -1;
        })
    }
    return result;
});

let selectedBlock = computed(() => {
    if (model.value.blockId && blocksFiltered.value.length) {
        return blocksFiltered.value.find(item => item.id == model.value.blockId);
    }
    return null;
});

let blocks = computed(() => {
    return htmlConstructorEditor.savedBlocks.value;
});

async function reload(force: boolean = false) {
    await htmlConstructorEditor.getSavedBlocks(force);
}

async function remove(block: ISavedBlock) {
    try {
        await ElConfirm(`Вы уверены, что хотите удалить блок "${block.descr}"?`);
        let result = await LcabApiRequest.save({
            url: `/api/htmlConstructor/${htmlConstructorEditor.params.type.id}/removeSavedBlock/${block.id}`
        });
        if (result.isSuccess) {
            htmlConstructorEditor.removeSavedBlock(block);
        }
    } catch (e) {

    }
}

function editSavedBlock(savedBlock: ISavedBlock) {
    editSavedBlockDialog.value = {
        visible: true,
        savedBlock,
        model: {
            style: null,
            additionalTypeParams: null,
            ...ObjectHelper.jsonClone(htmlConstructorEditor.model.value),
            blocks: [ObjectHelper.jsonClone(savedBlock.block)],
        }
    };
}

async function saveEditedBlock() {
    let blockList = editSavedBlockDialog.value.model.blocks;
    let block = blockList[0];
    if (blockList.length > 1) {
        block = HtmlConstructorHelper.wrapBlocksToStructureBlock(blockList);
    } else if (blockList.length == 0) {
        LcabApiResult.showError("Добавьте хотя бы один блок");
        return;
    }
    let savedBlock = await htmlConstructorEditor.saveBlock(block, editSavedBlockDialog.value.savedBlock.id, editSavedBlockDialog.value.savedBlock.descr);
    if (savedBlock) {
        editSavedBlockDialog.value = null;
        htmlConstructorEditor.getSavedBlocks(true, true);
    }
}

reload();


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_unref(blocks) != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(model).blockId)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_unref(selectedBlock))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[6] || (_cache[6] = _createElementVNode("p", null, "Выбранный блок:", -1)),
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(selectedBlock).savedBlock.descr), 1),
                    _createElementVNode("p", null, [
                      _createVNode(_component_el_button, {
                        type: "primary",
                        plain: "",
                        onClick: selectAnother,
                        class: "d-block w-100 m-0"
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Выбрать другой блок ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_button, {
                        type: "primary",
                        plain: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (editSavedBlock(_unref(selectedBlock).savedBlock))),
                        class: "d-block w-100 m-0 mt-2"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Редактировать этот блок ")
                        ])),
                        _: 1
                      })
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-danger" }, " Выбранный блок не найден. Возможно, он был удалён. ", -1)),
                    _createElementVNode("p", null, [
                      _createVNode(_component_el_button, {
                        type: "danger",
                        plain: "",
                        onClick: selectAnother
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Выбрать другой блок ")
                        ])),
                        _: 1
                      })
                    ])
                  ], 64))
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_unref(blocksFiltered).length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_el_input, {
                      modelValue: _unref(searchModel),
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchModel) ? (searchModel).value = $event : searchModel = $event)),
                        onSearchModelInput
                      ],
                      placeholder: "Поиск"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(FontAwesomeIcon, { icon: "search" })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_table, {
                data: _unref(blocksToDisplay),
                "row-key": "id",
                "show-header": false,
                class: "cursor-pointer",
                size: "small",
                onRowClick: onRowClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, null, {
                    default: _withCtx(({row}) => [
                      _createVNode(FontAwesomeIcon, {
                        class: "mr-1",
                        color: row.savedBlock.id === _unref(model).blockId ? 'primary' : null,
                        icon: row.savedBlock.id === _unref(model).blockId ? 'check' : row.blockType.icon
                      }, null, 8, ["color", "icon"]),
                      _createTextVNode(" " + _toDisplayString(row.savedBlock.descr), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "90px",
                    align: "right"
                  }, {
                    default: _withCtx(({row}) => [
                      (row.savedBlock.id === _unref(model).blockId)
                        ? (_openBlock(), _createBlock(MyIconButton, {
                            key: 0,
                            edit: "",
                            onClick: ($event: any) => (editSavedBlock(row.savedBlock))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _withDirectives(_createVNode(MyIconButton, { delete: "" }, null, 512), [
                        [
                          _directive_on_native,
                          () => remove(row.savedBlock),
                          "click",
                          { stop: true }
                        ]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ], 64)),
        (_unref(editSavedBlockDialog) && _unref(editSavedBlockDialog).visible)
          ? (_openBlock(), _createBlock(_component_el_dialog, {
              key: 2,
              modelValue: _unref(editSavedBlockDialog).visible,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(editSavedBlockDialog).visible) = $event)),
              title: "Редактирование сохраненного блока",
              fullscreen: "",
              class: "full-height-body no-padding relative-body",
              "show-close": false
            }, {
              footer: _withCtx(() => [
                _createVNode(_component_el_row, { class: "text-left" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { class: "text-md-right" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(editSavedBlockDialog) //@ts-ignore
 ? editSavedBlockDialog.value = null : editSavedBlockDialog = null))
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Отмена ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_el_button, {
                          type: "primary",
                          onClick: saveEditedBlock
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Сохранить блок ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(HtmlConstructorEditor, {
                  params: _unref(htmlConstructorEditor).params,
                  "model-value": _unref(editSavedBlockDialog).model,
                  "hide-style-edit": ""
                }, null, 8, ["params", "model-value"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(MyLoader, { big: "" })
      ]))
}
}

})