import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import Random from "~/ts/library/Random";
import StructureDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureDisplay.vue";
import IStructureInjectInterface
    from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureInjectInterface";
import {IStructureStyles} from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import {HTML_BLOCK_TYPE_STRUCTURE} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed, inject, provide} from "vue";
import IHtmlConstructorContent from "~/cabinet/vue/interface/htmlConstructor/IHtmlConstructorContent";
import {
    HtmlConstructorContentInject,
    HtmlConstructorEditorInject,
    ParentStructureInject
} from "~/cabinet/vue/interface/htmlConstructor/Constants";


const __default__ = {
    name: "HtmlConstructorContent"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    editable: { type: Boolean },
    width: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {

let props = __props;


let _modelValue = _useModel<IHtmlConstructorEditorModel<any>>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    blocks: [],
    style: {} as any,
    additionalTypeParams: {}
}));

let htmlConstructorEditor = inject(HtmlConstructorEditorInject);



let contentPreviewWidth = computed(() => {
    let result = props.width ? props.width : htmlConstructorEditor.params.type.defaultContentWidth;
    if (typeof result == "number") {
        result = `${result}px`;
    }
    return result;
});

let structureBlockType = computed(() => {
    return htmlConstructorEditor.params.blockTypesDictionary[HTML_BLOCK_TYPE_STRUCTURE];
});

let contentComponent = computed(() => {
    return htmlConstructorEditor.params.typeObject.contentDisplayComponent;
});

let structureStyles = computed<IStructureStyles>(() => {
    let style = model.value.style;
    return style ? style : null;
});

let instanceId = computed(() => {
    return Random.uid();
});

let exposedObject: IStructureInjectInterface & IHtmlConstructorContent = {
    get structureStyles() {
        return structureStyles.value;
    },

    get editable() {
        return props.editable
    }
};


__expose<IStructureInjectInterface | IHtmlConstructorContent>(exposedObject);
provide(HtmlConstructorContentInject, exposedObject);
provide(ParentStructureInject, exposedObject);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(contentComponent)), {
    modelValue: _unref(model),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event)),
    class: "root"
  }, {
    default: _withCtx(() => [
      _createVNode(StructureDisplay, {
        class: "html-constructor-content",
        style: _normalizeStyle({
                width: _unref(contentPreviewWidth)
            }),
        "block-type": _unref(structureBlockType),
        styles: {},
        "model-value": {
                blockLists: [_unref(model).blocks],
                columns: '100'
            },
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(model).blocks = $event.blockLists[0]))
      }, null, 8, ["style", "block-type", "model-value"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})