import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyRouterView from "~/core-ui/vue/router/MyRouterView.vue";

const __default__ = {
    name: "Layout"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    class: "lcabLayout",
    direction: "vertical"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, { direction: "horizontal" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, { class: "cabinet-controller" }, {
                default: _withCtx(() => [
                  _createVNode(MyRouterView)
                ]),
                _: 1
              }),
              _createVNode(_component_el_footer)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})