import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_2 = { class: "flex-fill" }

import UploadImage from "~/cabinet/vue/interface/form/UploadImage.vue";
import OperatorCabinetAvatar from "~/cabinet/vue/client/online/config/operators/OperatorCabinetAvatar.vue";
import CabinetUploadHelper from "~/cabinet/ts/service/CabinetUploadHelper";


const __default__ = {
    name: "OperatorAvatarConfig"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    isNew: { type: Boolean, default: false },
    format: { default: "png" }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let model = _useModel<string>(__props, "modelValue");

const uploadAction = (new CabinetUploadHelper())
    .setTag("newOperatorAvatar")
    .getUrl();

function getAvatarUrl(avatar: string, isNew: boolean) {
    return isNew ? `data:image/${props.format};base64,${avatar}` : avatar;
}

function onNewAvatar(value: string) {
    model.value = value;
}

const avatarImgSize = 200;

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_unref(model))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(model))
          ? (_openBlock(), _createBlock(OperatorCabinetAvatar, {
              key: 0,
              "avatar-url": getAvatarUrl(_unref(model), _ctx.isNew),
              class: "mr-2"
            }, null, 8, ["avatar-url"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onNewAvatar(null)))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Удалить аватар ")
            ])),
            _: 1
          })
        ])
      ]))
    : (_openBlock(), _createBlock(UploadImage, {
        key: 1,
        action: _unref(uploadAction),
        "onUpdate:modelValue": onNewAvatar,
        crop: "",
        format: _ctx.format,
        "aspect-ratio": 1,
        "min-width": avatarImgSize,
        "min-height": avatarImgSize,
        "max-width": avatarImgSize,
        "max-height": avatarImgSize,
        "preview-width": 60
      }, null, 8, ["action", "format"]))
}
}

})