import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import MyRouterView from "~/core-ui/vue/router/MyRouterView.vue";
import {MARKETOLOG_DASHBOARD_ROUTE} from "~/cabinet/ts/routes/Constants";
import DealerRoutesBuilder from "~/cabinet/ts/routes/DealerRoutesBuilder";

const __default__ = {
    name: "Layout"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    class: "lcabLayout",
    direction: "vertical"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, { direction: "horizontal" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, { class: "cabinet-controller" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, {
                                to: {name: _unref(MARKETOLOG_DASHBOARD_ROUTE)},
                                textContent: 'Сводка'
                              }, null, 8, ["to"]),
                              _cache[0] || (_cache[0] = _createTextVNode(" / ")),
                              _createVNode(_component_router_link, {
                                to: {name: _unref(DealerRoutesBuilder).getBlogArticlesRouteConfig(true).name},
                                textContent: 'Статьи блога'
                              }, null, 8, ["to"]),
                              _cache[1] || (_cache[1] = _createTextVNode(" / ")),
                              _createVNode(_component_router_link, {
                                to: {name: _unref(DealerRoutesBuilder).getBlogCategoriesRouteConfig(true).name},
                                textContent: 'Категории блога'
                              }, null, 8, ["to"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(MyRouterView)
                ]),
                _: 1
              }),
              _createVNode(_component_el_footer)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})