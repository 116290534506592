import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import {
    useContentDisplay
} from "~/cabinet/vue/interface/htmlConstructor/constructorType/useContentDisplay";
import {computed} from "vue";
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";

interface IStyle {
    mainBackground: string;
    mainBackgroundImage?: string;
    mainBackgroundRepeat?: string;
    mainBorderColor: string;
    mainBorderWidth: number;
}


interface IKnowledgeBaseHtmlConstructorAdditionalParams {
}

const __default__ = {
    name: "KnowledgeBaseHtmlContentDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

_useModel<IHtmlConstructorEditorModel<IKnowledgeBaseHtmlConstructorAdditionalParams>>(__props, "modelValue");
let {htmlConstructorEditor} = useContentDisplay();


let style = computed<IStyle>(() => {
    return htmlConstructorEditor.model.value.style;
});

let rootStyle = computed(() => {
    return style.value ? {
        backgroundColor: style.value.mainBackground,
        backgroundImage: `url('${style.value.mainBackgroundImage}')`,
        backgroundRepeat: style.value.mainBackgroundRepeat
    } : null
});

let innerStyle = computed(() => {
    return style.value ? {
        '.knowledge-base-html-content > .inner > div': {
            border: `${style.value.mainBorderWidth}px solid ${style.value.mainBorderColor}`
        }
    } : null
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "knowledge-base-html-content pb-5 pt-5",
    style: _normalizeStyle(_unref(rootStyle))
  }, [
    _createElementVNode("div", {
      class: "inner",
      style: _normalizeStyle(_unref(innerStyle))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4),
    _createVNode(MyStyle, {
      id: "knowledge-base-html-content",
      css: _unref(innerStyle)
    }, null, 8, ["css"])
  ], 4))
}
}

})