import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import HtmlInputWithVariables from "~/cabinet/vue/interface/tinymce/HtmlInputWithVariables.vue";
import ITextBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/text/ITextBlockValues";
import MyStyle from "~/cabinet/vue/interface/MyStyle.vue";
import {useBlockDisplay, useBlockDisplayProps} from "~/cabinet/vue/interface/htmlConstructor/blocks/useBlockDisplay";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "TextDisplay"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    ...useBlockDisplayProps(),
    inputStyle: {
        type: Object
    }
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

let props = __props;

let {parentStructure, instanceId, htmlConstructorEditor} = useBlockDisplay(props);
let _modelValue = _useModel<ITextBlockValues>(__props, "modelValue");
let model = useObjectModel(_modelValue);

let css = computed(() => {
    let style = parentStructure.structureStyles;
    if (style) {
        return {
            '*': {
                'font-family': style.bodyTextFont,
                'font-size': `${style.bodyTextSize}px`,
                'color': style.bodyTextColor
            },
            'a': {
                'color': style.bodyLinkColor
            }
        }
    } else {
        return {};
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", { id: _unref(instanceId) }, [
    _createVNode(HtmlInputWithVariables, {
      class: "pt-0 pb-0",
      modelValue: _unref(model).text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).text) = $event)),
      placeholder: "Введите текст",
      "editor-class-list": ['pt-3', 'pb-3'],
      style: _normalizeStyle(__props.inputStyle),
      "content-variables": _unref(htmlConstructorEditor).params.contentVariables
    }, null, 8, ["modelValue", "style", "content-variables"]),
    (_unref(instanceId))
      ? (_openBlock(), _createBlock(MyStyle, {
          key: 0,
          id: `${_unref(instanceId)}_style`,
          css: _unref(css),
          important: false,
          "root-selector": `#${_unref(instanceId)}`
        }, null, 8, ["id", "css", "root-selector"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})