import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {computed, ref, watch} from 'vue';
import {ISenderFormContact, ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import Delay from "~/ts/library/Delay";
import ContactsTable from "~/cabinet/vue/client/sender/send/receiverSelect/ContactsTable.vue";
import ElCardSectionSearch from "~/cabinet/vue/interface/card/ElCardSectionSearch.vue";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import {isMobileWindowSize} from "~/core-ui/ts/CoreUiHelpersMixin";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "SenderReceiverSelectContacts"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    uslugaId: {},
    contactbookDataSourceId: {},
    contacts: {},
    group: {},
    visible: { type: Boolean }
  }, {
    "modelValue": {
    default: []
},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["changed", "close-group"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let model = _useModel<number[]>(__props, "modelValue");

let searchString = ref("");
let searchStringInParams = ref("");
let isChanged = ref(false);

watch(model, () => {
    isChanged.value = true;
    emits('changed');
}, {deep: true})

watch(computed(() => props.visible), async () => {
    await Delay.make(1000);
    isChanged.value = false;
});

let params = computed(() => {
    return {
        search: searchStringInParams.value,
        groupId: props.group?.id
    };
});

function setSearchString() {
    searchStringInParams.value = searchString.value;
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(ElCardHeader, {
        "button-col-span": _unref(isMobileWindowSize) ? 9 : 6,
        title: _ctx.group ? _ctx.group.descr: '...'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: _unref(isChanged) ? 'primary' : null,
            round: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('close-group')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(isChanged) ? _unref(__)('Применить') : _unref(__)('Назад')), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ]),
        _: 1
      }, 8, ["button-col-span", "title"]),
      _createVNode(ElCardSectionSearch, {
        modelValue: _unref(searchString),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchString) ? (searchString).value = $event : searchString = $event)),
        placeholder: _unref(__)('Поиск в этой группе'),
        "show-button": _unref(searchString).length > 0,
        onEnter: setSearchString
      }, null, 8, ["modelValue", "placeholder", "show-button"]),
      _createVNode(ContactsTable, {
        "contactbook-data-source-id": _ctx.contactbookDataSourceId,
        contacts: _ctx.contacts,
        disabled: !_ctx.group,
        params: _unref(params),
        uslugaId: _ctx.uslugaId,
        modelValue: _unref(model),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(model) ? (model).value = $event : model = $event))
      }, null, 8, ["contactbook-data-source-id", "contacts", "disabled", "params", "uslugaId", "modelValue"])
    ]),
    _: 1
  }))
}
}

})