import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

import {__} from "~/ts/library/Translate";
import IMyTimeSelectorValue, {
    MY_TIME_SELECTOR_MULTIPLY_DAYS,
    MY_TIME_SELECTOR_MULTIPLY_HOURS,
    MY_TIME_SELECTOR_MULTIPLY_MINUTES,
    MY_TIME_SELECTOR_MULTIPLY_SECONDS
} from "~/cabinet/vue/interface/form/IMyTimeSelectorValue";
import StringHelper from "~/ts/library/StringHelper";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";


const __default__ = {
    name: "MyTimeSelector"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    fullWidth: { type: Boolean }
  }, {
    "modelValue": {required: true},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let props = __props;

let _modelValue = _useModel<IMyTimeSelectorValue>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    value: 1,
    multiply: 60
}))


let multiplyList = computed(() => {
    let value = model.value.value;
    return [
        {
            value: MY_TIME_SELECTOR_MULTIPLY_SECONDS,
            descr: StringHelper.getSclon(value, __("секунда"), __("секунды"), __("секунд"))
        },
        {
            value: MY_TIME_SELECTOR_MULTIPLY_MINUTES,
            descr: StringHelper.getSclon(value, __("минута"), __("минуты"), __("минут"))
        },
        {
            value: MY_TIME_SELECTOR_MULTIPLY_HOURS,
            descr: StringHelper.getSclon(value, __("час"), __("часа"), __("часов"))
        },
        {
            value: MY_TIME_SELECTOR_MULTIPLY_DAYS,
            descr: StringHelper.getSclon(value, __("день"), __("дня"), __("дней"))
        }
    ]
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.fullWidth ? 'd-flex' : 'd-inline-block')
  }, [
    _createVNode(_component_el_input, {
      modelValue: _unref(model).value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).value) = $event)),
      modelModifiers: { number: true },
      style: {"max-width":"6em"},
      class: "mr-1"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_el_select, {
      modelValue: _unref(model).multiply,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(model).multiply) = $event)),
      class: _normalizeClass(_ctx.fullWidth ? 'flex-fill' : null),
      style: _normalizeStyle(_ctx.fullWidth ? null : `width: 8em`)
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(multiplyList), (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            value: item.value,
            label: item.descr
          }, null, 8, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "class", "style"])
  ], 2))
}
}

})