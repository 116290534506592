import {DirectiveBinding, ObjectDirective} from "vue";
import Dictionary from "~/ts/library/Dictionary";


type ExtendedHTMLElement = HTMLElement & {
    vOnNativeEventList: {
        type: string,
        callback: (event: Event) => void
    }[]
}

type Modifiers =
    "stop"
    | "stopImmediate"
    | "capture"
    | "once"
    | "passive"
    | "prevent"
    | "enter"
    | "up"
    | "down"
    | "right"
    | "left";
let keys: Dictionary<Modifiers> = {
    "Enter": "enter",
    "ArrowUp": "up",
    "ArrowDown": "down",
    "ArrowRight": "right",
    "ArrowLeft": "left"
}

let counter = 0;

function bind(eventName: string, el: ExtendedHTMLElement, binding: DirectiveBinding<any, Modifiers>) {
    let callback = (event: Event) => {
        try {
            let keysToCheck: string[] = [];
            for (let key in keys) {
                if (keys.hasOwnProperty(key)) {
                    if (binding.modifiers[keys[key]]) {
                        keysToCheck.push(key);
                    }
                }
            }
            if (keysToCheck.length > 0) {
                if (!keysToCheck.includes((event as KeyboardEvent).key)) {
                    return;
                }
            }

            if (binding.modifiers.prevent) {
                event.preventDefault();
            }
            if (binding.modifiers.stop) {
                event.stopPropagation();
            }
            if (binding.modifiers.stopImmediate) {
                event.stopImmediatePropagation();
            }
            if (typeof binding.value == "function") {
                binding.value(event);
            }
        } catch (e) {
            console.error(e);
        }
    };
    if (!el.vOnNativeEventList) {
        el.vOnNativeEventList = [];
    }
    el.vOnNativeEventList.push({
        type: eventName,
        callback
    });

    el.addEventListener(eventName, callback, {
        capture: !!binding.modifiers.capture,
        once: !!binding.modifiers.once,
        passive: !!binding.modifiers.passive
    });
}

function unbind(el: ExtendedHTMLElement, eventName: string, binding: DirectiveBinding) {
    if (el.vOnNativeEventList) {
        for (let item of el.vOnNativeEventList) {
            el.removeEventListener(item.type, item.callback);
        }
        el.vOnNativeEventList.splice(0, el.vOnNativeEventList.length);
        delete el.vOnNativeEventList;
    }
}


export const vOnNative: ObjectDirective<any, any, Modifiers> = {
    mounted: (el, binding) => {
        bind(binding.arg, el, binding);
    },
    /*
    updated: (el, binding) => {
        bind(binding.arg, el, binding);
    },*/
    beforeUnmount: (el, binding) => {
        unbind(el, binding.arg, binding);
    }
};

