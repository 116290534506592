import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

import {
    MassActionsHeadCheckboxExposeInterface,
    MassActionsSelectionModelInterface
} from "~/cabinet/vue/interface/data/MyRemoteDataInterface";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {computed} from "vue";
import {__} from "~/ts/library/Translate";


const __default__ = {
    name: "MassActionsHeadCheckbox"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    allItemsCount: { default: 0 }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["command"], ["update:modelValue"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

let props = __props;

let emits = __emit;

let _modelValue = _useModel<MassActionsSelectionModelInterface>(__props, "modelValue");
let model = useObjectModel(_modelValue, () => ({
    list: [],
    all: false
}));

let selectionCount = computed(() => {
    return model.value.all ? props.allItemsCount : model.value.list.length;
});

const RESET_SELECTION_COMMAND = "resetSelection";

function resetSelectionModel() {
    model.value.all = false;
    model.value.list.splice(0);
}

function onCommand(command: any) {
    if (command == RESET_SELECTION_COMMAND) {
        resetSelectionModel();
    } else {
        emits('command', command);
    }
}

__expose<MassActionsHeadCheckboxExposeInterface>({
    selectionCount
})

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _directive_on_native = _resolveDirective("on-native")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    trigger: "click",
    class: "my-table-selection-dropdown",
    onCommand: onCommand
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, { disabled: "" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(__)("Выбрано")) + ": " + _toDisplayString(_unref(selectionCount)), 1)
            ]),
            _: 1
          }),
          (_unref(selectionCount) > 0)
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: 0,
                command: RESET_SELECTION_COMMAND
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(__)("Отменить выбор")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_dropdown_item, { divided: "" }),
          _renderSlot(_ctx.$slots, "selection-dropdown", {
            selected: _unref(selectionCount) > 0
          })
        ]),
        _: 3
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_button, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_el_checkbox, {
            class: "mr-1",
            modelValue: _unref(model).all,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(model).all) = $event))
          }, null, 8, ["modelValue"]), [
            [
              _directive_on_native,
              void 0,
              "click",
              { stop: true }
            ]
          ]),
          _createVNode(FontAwesomeIcon, { icon: "chevron-down" })
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}
}

})