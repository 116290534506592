import DateHelper from "~/ts/library/Date";
import {__} from "~/ts/library/Translate";
import WindowHelper from "~/ts/library/WindowHelper";
import {LCAB_LAYOUT_BREAKPOINT} from "~/ts/library/LcabWindowHelper";
import {computed} from "vue";

export const isMobileWindowSize = computed(() => {
    return WindowHelper.getSize().width < LCAB_LAYOUT_BREAKPOINT;
})

export const requiredRule = [{required: true, message: __("Обязательное поле")}];

export function getTextColorClass(color: string | null): string {
    return color ? "text-" + color : "";
}

export function getBackgroundColorClass(color: string|null): string {
    return color ? "bg-" + color : "";
}

export const mobileFullWidthButtonClass = "mobile-full-width-button";

export function getFaviconUrl(host: string) {
    return `https://www.google.com/s2/favicons?sz=64&domain=${host}`;
}

export const predefineColors: string[] =  [
    "#c4183c",
    "#007bff",
    "#F8B347",
    "#8175c7",
    "#17c671",
    "#f8d347",
    "#bfc2cd",
    "#41cac0",
    "#00a8b3",
    "#7087A3"
];

export function roundCountryImage(countryId: string) {
    return `/cabinet/assets/images/flags/${countryId}.png`;
}

export function localeDateTimeString(date: string | Date | number, withSeconds: boolean = false) {
    return (new DateHelper(date)).toLocaleDateTimeString(withSeconds);
}

export function localeTimeString(date: string | Date) {
    return (new DateHelper(date)).toMysqlFormatTime();
}

export function mysqlTimeString(date: string | Date) {
    return (new DateHelper(date)).toMysqlFormatTime();
}

export function localeDateString(date: string | Date, wordMonth: boolean = false) {
    return (new DateHelper(date)).toLocaleDateString(wordMonth);
}

export function secondsFormatToClosestFloor(seconds: number) {
    return DateHelper.secondsFormatToClosestFloor(seconds);
}

export function secondsFormat (seconds: number) {
    return DateHelper.secondsFormat(seconds);
}

export function substrLong(text: string, length: number, end: string = "..."): string {
    text = text.trim();
    if (text.length > length) {
        text = text.substr(0, length) + end;
    }
    return text;
}