import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flipper",
  ref: "flipper"
}

import {computed, nextTick, ref, watch} from 'vue';
import Delay from "~/ts/library/Delay";


const __default__ = {
    name: "MyFlipContainer"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    modelValue: { type: Boolean },
    type: { type: Boolean },
    vertical: { type: Boolean }
  },
  setup(__props: any) {

let props = __props;

//отключаем для сафари
let isPerspectiveAvailable = computed(() => {
    return !(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
});

let container = ref<HTMLElement>();

watch(computed(() => props.modelValue), () => {
    hover.value = props.modelValue;
    if (isPerspectiveAvailable.value) {
        nextTick(async () => {
            height.value = container.value.offsetHeight;
            try {
                await Delay.make(1000, "my-flip-container-height");
                height.value = 0;
            } catch (e) {

            }
        });
    }
})

let height = ref(0);
let hover = ref(props.modelValue);

let style = computed(() => {
    if (isPerspectiveAvailable.value) {
        let perspective = height.value * 7;
        return perspective ? {
            "-webkit-perspective": perspective,
            "-moz-perspective": perspective,
            "-ms-perspective": perspective,
            "perspective": perspective
        } : {};
    } else {
        return null;
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flip-container', _unref(hover) ? 'hover' : 'not-hover', _ctx.vertical ? 'vertical' : '']),
    ref_key: "container",
    ref: container,
    style: _normalizeStyle(_unref(style))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "front", {}, () => [
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "Отсутствует слот front", -1))
      ]),
      _renderSlot(_ctx.$slots, "back", {}, () => [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, "Отсутствует слот back", -1))
      ])
    ], 512)
  ], 6))
}
}

})