import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-filter-preview-operation" }

import {computed, ref} from 'vue';
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import {IFilter, IFiltersData, IOperation} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterPreviewTagTemplate from "~/cabinet/vue/interface/filter/MyFilterPreviewTagTemplate.vue";
import {__sprintf} from "~/ts/library/Translate";


import {useRawAsyncComponent} from "~/core-ui/ts/useRawComponent";

const __default__ = {
    name: "MyFilterPreviewTag",
    components: {
        InputWithVariablesPreview: useRawAsyncComponent(() => import(/* webpackChunkName: "client-misc" */ '~/cabinet/vue/interface/tinymce/InputWithVariablesPreview.vue'))
    }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    editable: { type: Boolean },
    values: {},
    operation: {},
    filter: {},
    filtersData: {}
  },
  emits: ["close", "new-value"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let value = ref("...");

function hasVisibleFilters() {
    return MyFilterHelper.hasVisibleFilters(props.filter, props.operation);
}

let watcher = computed(() => {
    if (props.filter || props.operation || props.values) {
        makeValue()
    }
    return "";
});

async function makeValue() {
    value.value = await valuesToString();
    emits("new-value");
}

async function valuesToString() {
    let result = [];
    let promises = [];
    let filters = MyFilterHelper.getFilterInputs(props.filter, props.operation);

    let values = [];

    for (let i = 0; i < props.values.length; i++) {

        let convertToArray = filters.length === 1 || !Array.isArray(props.values[i]);

        let value = convertToArray ? [props.values[i]] : props.values[i];
        values.push(value);
        for (let j = 0; j < value.length; j++) {
            let filter = filters[j];
            if (filter != null) {
                promises.push(MyFilterHelper.prepareValue(filter, [value[j]]));
            } else {
                promises.push(new Promise(resolve => resolve([""])));
                //sprintfVariables.push("");
                //console.warn("Количество значений не соответствует количеству инпутов", filters, value, i);
            }
        }
    }

    let promiseResult: any = await Promise.all(promises);
    let j = 0;
    for (let i = 0; i < values.length; i++) {
        let value = values[i];
        let sprintfVariables: string[] = [];

        for (let i = 0; i < value.length; i++) {
            sprintfVariables.splice(i, 0, ...promiseResult[j]);
            j++;
        }
        result.push(__sprintf(props.operation.valueSprintfTemplate, ...sprintfVariables));
    }

    return result.join(", ");
}

return (_ctx: any,_cache: any) => {
  const _component_input_with_variables_preview = _resolveComponent("input-with-variables-preview")!

  return (_openBlock(), _createBlock(MyFilterPreviewTagTemplate, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(emits)('close'))),
    editable: _ctx.editable && !_ctx.filter.required
  }, {
    default: _withCtx(() => [
      (!_ctx.filter.descrHidden)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.filter.descrForPreview ? _ctx.filter.descrForPreview : _ctx.filter.descr) + ": ", 1)
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.filter.multiple && _ctx.values.length > 1 ? _ctx.operation.multipleShortDescr : _ctx.operation.shortDescr), 1),
      hasVisibleFilters
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.filtersData.contentVariables && _unref(value).indexOf('[') > -1)
              ? (_openBlock(), _createBlock(_component_input_with_variables_preview, {
                  key: 0,
                  text: _unref(value),
                  variables: _ctx.filtersData.contentVariables
                }, null, 8, ["text", "variables"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_unref(value)), 1)
                ], 64)),
            _createTextVNode(" " + _toDisplayString(_unref(watcher)), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["editable"]))
}
}

})