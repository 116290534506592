import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-danger mt-1 font-size-small"
}

import {ref, watch} from 'vue';
import MyTimeSelector from "~/cabinet/vue/interface/form/MyTimeSelector.vue";
import IMyTimeSelectorValue, {
    MY_TIME_SELECTOR_MULTIPLY_DAYS,
    MY_TIME_SELECTOR_MULTIPLY_HOURS,
    MY_TIME_SELECTOR_MULTIPLY_MINUTES,
    MY_TIME_SELECTOR_MULTIPLY_SECONDS
} from "~/cabinet/vue/interface/form/IMyTimeSelectorValue";


const __default__ = {
    name: "MyTimeSelectorInSeconds"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: /*@__PURE__*/_mergeModels({
    min: { default: 0 },
    max: {}
  }, {
    "modelValue": {
    default: 1
},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

let multiplyTypes = [
    MY_TIME_SELECTOR_MULTIPLY_DAYS,
    MY_TIME_SELECTOR_MULTIPLY_HOURS,
    MY_TIME_SELECTOR_MULTIPLY_MINUTES,
    MY_TIME_SELECTOR_MULTIPLY_SECONDS
];

let props = __props;

let model = _useModel<number>(__props, "modelValue");

let errorLabel = ref<string>();
let timeSelectorModel = ref<IMyTimeSelectorValue>();
let lastEmitValue = ref<number>();

watch(model, () => {
    let valueInModel = timeSelectorModel.value;
    if ((!valueInModel || model.value != valueInModel.multiply * valueInModel.value) && model.value != lastEmitValue.value) {
        makeModel();
    }
});

function makeModel() {
    timeSelectorModel.value = getNewSelectorModel(model.value);
}

function getNewSelectorModel(value: number): IMyTimeSelectorValue {
    let result: IMyTimeSelectorValue;
    for (let multiply of multiplyTypes) {
        if (value % multiply == 0) {
            result = {value: value / multiply, multiply};
            break;
        }
    }
    return result;
}

watch(timeSelectorModel, () => onInput(), {deep: true})

function onInput() {
    let value = timeSelectorModel.value;
    if (value) {
        let seconds = value.value * value.multiply;
        let result = seconds;
        let error: string;
        if (seconds < props.min) {
            error = "Минимум";
            result = props.min;
        }
        if (props.max && seconds > props.max) {
            error = "Максимум";
            result = props.max;
        }
        if (error) {
            let newSelectorModel = getNewSelectorModel(result);
            error += `: ${newSelectorModel.value} `;
            if (newSelectorModel.multiply == MY_TIME_SELECTOR_MULTIPLY_DAYS) {
                error += "дн."
            } else if (newSelectorModel.multiply == MY_TIME_SELECTOR_MULTIPLY_HOURS) {
                error += "ч."
            } else if (newSelectorModel.multiply == MY_TIME_SELECTOR_MULTIPLY_MINUTES) {
                error += "мин."
            } else if (newSelectorModel.multiply == MY_TIME_SELECTOR_MULTIPLY_SECONDS) {
                error += "сек."
            }
        }
        errorLabel.value = error;
        lastEmitValue.value = result;
        model.value = result;
    }

}

makeModel();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(MyTimeSelector, {
      "model-value": _unref(timeSelectorModel),
      "onUpdate:modelValue": onInput
    }, null, 8, ["model-value"]),
    (_unref(errorLabel))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(errorLabel)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})