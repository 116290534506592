import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ref} from 'vue';
import DomHelper from "~/ts/library/DomHelper";


const __default__ = {
    name: "PointerEventContainer"
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    tagName: { default: "div" }
  },
  emits: ["drag-start", "drag", "drag-stop", "click"],
  setup(__props: any, { emit: __emit }) {

let props = __props;

let emits = __emit;

let dragActive = ref(false);
let wasDrag = ref(false);
const hasPointerEventSupport = 'PointerEvent' in window;

function onDragStart(e: PointerEvent, isPointer: boolean) {
    //Отключили срабатывание на select из-за хуйни в сафари - там не срабатывает onmouseup на select
    if ((!isPointer && hasPointerEventSupport) || e.button != 0 || (e.target as HTMLElement).tagName == "SELECT") {
        return;
    }

    wasDrag.value = false;

    var el = e.target as HTMLElement;
    DomHelper.setPointerCapture(el as HTMLElement, e.pointerId);
    dragActive.value = true;
    emits("drag-start", e);
    e.stopPropagation();
}

function onDragStop(e: PointerEvent, isPointer: boolean) {
    if (!isPointer && hasPointerEventSupport) {
        return;
    }
    if (dragActive.value) {
        dragActive.value = false;
        if (!wasDrag.value) {
            if (e.button == 0) {
                emits("click", e);
            }
        }
        DomHelper.releasePointerCapturer(e.target as HTMLElement, e.pointerId);
        emits("drag-stop", e);
        e.stopPropagation();
    }
}

function onDrag(e: PointerEvent, isPointer: boolean) {
    if (!isPointer && hasPointerEventSupport) {
        return;
    }
    if (dragActive.value) {
        wasDrag.value = true;
        emits("drag", e);
        e.stopPropagation();
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tagName), {
    onMousedown: onDragStart,
    onMousemove: onDrag,
    onMouseup: onDragStop,
    onPointerdown: _cache[0] || (_cache[0] = ($event: any) => (onDragStart($event, true))),
    onPointermove: _cache[1] || (_cache[1] = ($event: any) => (onDrag($event, true))),
    onPointerup: _cache[2] || (_cache[2] = ($event: any) => (onDragStop($event, true)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 32))
}
}

})