import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import CodeDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeDisplay.vue";
import CodeForm from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeForm.vue";
import ICodeBlockValues, {
    ICodeBlockParamsForEditor
} from "~/cabinet/vue/interface/htmlConstructor/blocks/code/ICodeBlockValues";
import {RawComponent, useRawComponent} from "~/core-ui/ts/useRawComponent";


export default class CodeBlockType extends AbstractBlockType<ICodeBlockValues, ICodeBlockParamsForEditor> {
    get displayComponent(): RawComponent {
        return useRawComponent(CodeDisplay);
    }

    get formComponent(): RawComponent {
        return useRawComponent(CodeForm);
    }

    get isBlockWithoutPadding(): boolean {
        return true;
    }

    get toolbarPosition(): HtmlConstructorBlockToolbarPosition {
        return HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM;
    }
}
